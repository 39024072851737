
import { useSelector } from "react-redux"
import { FieldMap } from "../../../../type";

const FieldDropdown = (props: any) => {
    //ref: graphcreator, metricsmanager
    //accepts an array of filters

    let fieldData: FieldMap[] = useSelector((state: any) => state.fieldmapping)
    let divname = "dropdown_fldddown"
    let value = props.value
    let includecategory = props.includecategory;
    let className = props.className;
    let filterfields = props.filterfield ? props.filterfield : [];
    let filtervalues = props.filtervalue ? props.filtervalue : [];

    //convert to array if not already
    if (typeof (filterfields) !== "object") {
        filterfields = [filterfields]
        filtervalues = [filtervalues]
    }
    if (props.divname) {
        divname = props.divname
    }

    return (
        <select id={divname} onChange={props.change} value={value} className={className}>
            <option key='-1' id={""}>
                Select Field
            </option>
            {
                fieldData.map((fld: FieldMap, i: number) => {
                    if (CheckFilters(fld, filterfields, filtervalues) === true) {
                        if (includecategory) {
                            return (
                                <option key={i} id={fld.Key} data-name={fld.FieldName} data-model={fld.ModelCategory} data-attr={fld.AttrName}>
                                    {fld.ModelCategory} - {fld.FieldName}
                                </option>
                            )
                        } else {
                            return (
                                <option key={i} id={fld.Key} data-name={fld.FieldName} data-model={fld.ModelCategory} data-attr={fld.AttrName}>
                                    {fld.FieldName}
                                </option>
                            )
                        }
                    }
                })
            }
        </select>
    )
};
export default FieldDropdown;

const CheckFilters = (fld: any, filterfields: any[], filtervalues: any[]): boolean => {
    let check: boolean = true //if no criteria, true

    filterfields.forEach((flt: any, i: number) => {
        if (fld[flt] !== filtervalues[i]) {
            check = false
        }
    })

    return (check)
}