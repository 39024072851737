import { useState, useEffect, ChangeEvent, useMemo, useContext } from "react";
import { FieldMap, GroupNumericRule, GroupSet } from "../../../../type";
import { GroupNumericRuleInit } from "../../../../InitTypes";
import { Modal } from "react-bootstrap";
import { StartupContext } from "../../../../App";
import GroupFieldNumUpload from "../../../../data/Fields/GroupFieldNum_Upload";


const GroupSetsNumberMgr = (props: any) => {

    let groupfield: FieldMap = props.groupfield
    let groupnumrulesInit: GroupNumericRule[] = props.groupnumrulesInit

    let { config, schema, user, dispatcher, paths, clickstyle } = useContext(StartupContext)

    let [gsnumrules, setGsNumRules] = useState<GroupNumericRule[]>(groupnumrulesInit)
    let [selnumrule, setSelNumRule] = useState<GroupNumericRule>(GroupNumericRuleInit[0])

    let [params, setParams] = useState<any>({
        refresh: 0, fldrefresh: 0, decimals: 0,
        issavevisible: false, ruleequal: "",
        ruletype: "range", rulewarning: "", setrule: false
    });
    let keylowerbound = -100000000
    let keyupperbound = 100000000


    useEffect(() => {
        setParams({ ...params, rulewarning: "", setrule: false, ruletype: "" })
    }, [selnumrule.id])

    useEffect(() => {
        setSelNumRule({ ...GroupNumericRuleInit[0], id: selnumrule.id })
    }, [params.ruletype])

    useEffect(() => {
        setGsNumRules(groupnumrulesInit);
    }, [groupnumrulesInit])

    // console.log(params)
    // console.log(selnumrule)
    //console.log(gsnumrules)

    //numrule logic - check, then set rule
    useEffect(() => {
        if (params.checkrule) {
            if (!CheckRuleOverlap(gsnumrules, selnumrule)) {

                if (selnumrule.GreaterThanThresh > selnumrule.LessThanThresh) {
                    setParams({ ...params, rulewarning: "improper bounds", setrule: false, checkrule: false })
                } else {
                    if (selnumrule.LessThanThresh === 0 || selnumrule.GreaterThanThresh === 0) {
                        setParams({ ...params, rulewarning: "missing lte/gte values", setrule: false, checkrule: false })
                    } else {
                        if (selnumrule.EqualTo === 0) {
                            setParams({ ...params, rulewarning: "missing eq value", setrule: false, checkrule: false })
                        } else {
                            setParams({ ...params, rulewarning: "", setrule: true, checkrule: false })

                        }
                    }
                }
            }
            else {
                setParams({ ...params, rulewarning: "Overlaps with existing rule", setrule: false, checkrule: false })
            }
        }
    }, [params.checkrule])


    useEffect(() => {
        if (params.setrule) {
            //using groupset name as a placeholder for completed rules
            gsnumrules[selnumrule.id] = { ...selnumrule, GroupSetName: "Set" }
            setGsNumRules(gsnumrules)
            setParams({ ...params, setrule: false })
        }
    }, [params.setrule])

    const ClickSave = () => {
        //GenericBackendCall(groupfield.GroupFieldKey, schema, paths.groupsets.groupsetpost, user, config, gsnumrules, "")
        GroupFieldNumUpload(groupfield.Key, gsnumrules, schema, user, config, "")
    }

    const ClickSetRule = () => {

        //configure based on rule type
        let gt = selnumrule.GreaterThanThresh
        let lt = selnumrule.LessThanThresh
        let eq = selnumrule.EqualTo
        let banding = selnumrule.BandingName
        if (params.ruletype === "orless") {
            gt = keylowerbound
        } else if (params.ruletype === "orgreater") {
            lt = keyupperbound
        } else if (params.ruletype === "single") {
            gt = eq
            lt = eq
        }

        if (params.ruleequal === "lte") {
            eq = lt
        } else if (params.ruleequal === "gte") {
            eq = gt
        }
        if (banding === "") {
            banding = CheckValue(gt) + " to " + CheckValue(lt)
        }
        setSelNumRule({
            ...selnumrule,
            BandingName: banding, EqualTo: eq, GreaterThanThresh: gt, LessThanThresh: lt
        })
        setParams({ ...params, checkrule: true, rulewarning: "" })

    };

    const CheckRuleOverlap = (nrules: GroupNumericRule[], selrule: GroupNumericRule) => {
        let overlap: boolean = false
        nrules.forEach((rule: GroupNumericRule) => {
            if (rule.id !== selrule.id && rule.GroupSetName !== "") {
                if (rule.GreaterThanThresh <= selrule.LessThanThresh && rule.LessThanThresh >= selrule.GreaterThanThresh) {
                    //rule should exist entirely below selrule
                    //console.log(rule.id, "fails below")
                    overlap = true
                } else if (rule.LessThanThresh >= selrule.GreaterThanThresh && rule.GreaterThanThresh <= selrule.LessThanThresh) {
                    //rule should exist entirely above selrule
                    //console.log(rule.id, "fails above")
                    overlap = true
                }
            }
        })
        return (overlap)
    };


    const AddGrpNRule = () => {
        gsnumrules.push({ ...GroupNumericRuleInit[0], NumericType: "decimal", id: gsnumrules.length })
        setGsNumRules(gsnumrules)
        setParams({ ...params, refresh: params.refresh + 1 })
    };

    const RoundFn = (val: number) => {
        return Math.round(val * (Math.pow(10, params.decimals))) / Math.pow(10, params.decimals)
    }

    const CheckValue = (val: number) => {
        if (Math.round(val) === Math.round(keyupperbound)) {
            return "Max"
        } else if (Math.round(val) === Math.round(keylowerbound)) {
            return "Min"
        } else {
            return RoundFn(val)
        }
    };

    const NumberInput = (props: any) => {
        let changefn = props.change;
        let value = props.value
        return (
            <input type="number" value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => { changefn(e) }} />
        )
    };


    const Num1 = (e: ChangeEvent<HTMLInputElement>) => {
        return setParams({ ...params, decimals: +e.target.value })
    }
    const Num2 = (e: ChangeEvent<HTMLInputElement>) => {
        return setSelNumRule({ ...selnumrule, GreaterThanThresh: RoundFn(+e.target.value) })
    }
    const Num3 = (e: ChangeEvent<HTMLInputElement>) => {
        return setSelNumRule({ ...selnumrule, LessThanThresh: RoundFn(+e.target.value) })
    }
    const Num4 = (e: ChangeEvent<HTMLInputElement>) => {
        return setSelNumRule({ ...selnumrule, EqualTo: RoundFn(+e.target.value) })
    }

    const GroupNumRangeDiv = () => {
        return (
            <div className="ndt_innerbox" style={{ height: "450px", width: "40%" }}>
                <button className="ndt_btn1" onClick={_ => { AddGrpNRule() }}>Add Rule</button>
                <NumberInput change={Num1} value={params.decimals} />
                <div id="gsnm_rulebox" className="ndt_subinner">
                    {gsnumrules.map((nrule: GroupNumericRule, i: number) => {
                        let nstyle = clickstyle.itmSelected
                        if (nrule.id === selnumrule.id) { nstyle = clickstyle.itmUnselected }
                        return (<div key={i} style={nstyle} onClick={_ => setSelNumRule(nrule)}>
                            <div>GT:{CheckValue(nrule.GreaterThanThresh)}</div>
                            <div>E:{CheckValue(nrule.EqualTo)}</div>
                            <div>LT:{CheckValue(nrule.LessThanThresh)}</div>
                            <div>Name (optional):{nrule.BandingName}</div>
                        </div>)
                    })}
                </div>
            </div>
        )
    }
    const FieldNumRangeDiv = () => {
        return (<div className="ndt_innerbox" style={{ height: "450px", width: "30%" }}>
            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, ruletype: e.target.selectedOptions[0].attributes[0].value }) }}>
                <option value="">Select Rule Type</option>
                <option value="orless">Or Less Than</option>
                <option value="range">Range</option>
                <option value="orgreater">Or Greater</option>
                <option value="single">Single Value</option>
            </select>
            <div>
                {params.ruletype !== "orless" && params.ruletype !== "single" ?
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "300px" }}>Greater Than (Lower Boundary)</div>
                        <NumberInput change={Num2} value={selnumrule.GreaterThanThresh} />
                    </div> : <div>Or Less</div>
                }
            </div>
            <div>

                <div style={{ display: "flex" }}>
                    <div style={{ width: "300px" }}>Equal To</div>
                    {params.ruletype === "single" ?
                        <NumberInput change={Num4} value={selnumrule.EqualTo} />
                        : <div>
                            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, ruleequal: e.target.selectedOptions[0].attributes[0].value }) }}>
                                <option value="">Select:</option>
                                <option value="lte">Less Than or Equal</option>
                                <option value="gte">Greater Than or Equal</option>
                            </select>
                        </div>}
                </div>

            </div>
            <div>
                {params.ruletype !== "orgreater" && params.ruletype !== "single" ?
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "300px" }}>Less Than (Upper Boundary)</div>
                        <NumberInput change={Num3} value={selnumrule.LessThanThresh} />
                    </div> : <div>Or Greater</div>
                }
            </div>
            <div>
                <div style={{ width: "300px" }}>Banding Name</div>
                <input type="text" className="gsm_numinput" value={selnumrule.BandingName} onChange={(e: ChangeEvent<HTMLInputElement>) => { setSelNumRule({ ...selnumrule, BandingName: e.target.value }) }} />
            </div>
            <button onClick={_ => { ClickSetRule() }}>Set Rule</button>
            <div>{params.rulewarning}</div>
        </div>)
    }

    let fldnumrngMemo = useMemo(() => { return FieldNumRangeDiv() }, [selnumrule, params.ruletype, params.ruleequal, params.checkrule, params.setrule, params.rulewarning])

    return (<div>
        <div style={{ display: "flex" }}>
            <GroupNumRangeDiv />
            {fldnumrngMemo}
        </div>
        <button className="ndt_btn2" onClick={_ => { setParams({ ...params, issavevisible: true }) }}>
            Save Grouping</button>

        <Modal show={params.issavevisible}>
            <div>Do you want to overwrite?</div>
            <button onClick={_ => ClickSave()} className="ndt_btn2" style={{ width: "200px", margin: "15px" }}>Save</button>
            <button className="closemodalbtn" onClick={_ => setParams({ ...params, issavevisible: false })}>Close</button>
        </Modal>

    </div >)
};
export default GroupSetsNumberMgr;