import { ChangeEvent, useState, useContext, MouseEvent, useEffect } from "react";
import { EmpModel, ModelBatch, Schema, Workspace, UserPermssion, User } from "../../../type";
import SchemaUpload from "../../../data/Schema/Schema_Upload";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { StartupContext } from "../../../App";
import SchemaDelete from "../../../data/Schema/Schema_Delete";

import SchemaImport from "../../../data/Schema/Schema_Import";
import { schemaActionCreator, schemadeleteActionCreator } from "../../../reducers/schema_actions";
import { WorkspaceInit } from "../../../InitTypes";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { SwatchesPicker } from "react-color";
import GetUserPermission from "../../../functions/QueryFunctions/GetUserPermission";

import "./css/SchemaManage.css"
import "./css/SchemaManage_cascade.css"
import GetTextColor from "../../../functions/GetTextColor";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import { Link } from "react-router-dom";

const SchemaManage = () => {

    const { config, dispatcher, user, paths, clickstyle } = useContext(StartupContext)
    const locdispatcher = useDispatch()

    interface params_type {
        name: string; color: string; selschemakey: string; selschemaname: string;
        iscolorvisible: boolean; isdelmodalvisible: boolean; iswsdelvisible: boolean; viewdetails: boolean;
        isdelbatchvisible: boolean; refresh: number; isnewvisible: boolean;
        copyflds: boolean; copytbls: boolean; copymetrs: boolean;
        fromschema: string; alertmsg: string;
    }

    let schemas = useSelector((state: any) => state.schemas)//need this for this page
    if (schemas.length > 0) {
        //let schema1s = ObjectSort(schemas, "SchemaName") //not working
    }
    const [selSchema, setSelSchema] = useState<Schema | null>(null)
    const [selWorkspace, setSelWorkspace] = useState<Workspace | null>(null)
    const [keyparams, setKeyParams] = useState<params_type>({
        name: '', color: "#ffffff", selschemakey: '', selschemaname: '', iscolorvisible: false,
        isdelmodalvisible: false, iswsdelvisible: false, viewdetails: false, isdelbatchvisible: false,
        isnewvisible: false, copyflds: false, copytbls: false, copymetrs: false, refresh: 0, fromschema: '',
        alertmsg: ''
    })
    const [models, setModels] = useState<ModelBatch[]>([])
    const [primaryWS, setPrimaryWS] = useState<Workspace | null>(null)
    const [selModelBatch, setSelModelBatch] = useState<ModelBatch | null>(null)
    const [userperm, setUserPerm] = useState<UserPermssion | null>(null)

    let selWSStyle = { backgroundColor: "lightblue", color: "black" }

    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        if (selSchema) {
            GetUserPermission(selSchema, user, setUserPerm)
            let mdls: ModelBatch[] = []
            selSchema.models.forEach((mdl: ModelBatch) => {
                if (!mdl.WorkspaceName) {
                    mdls.push(mdl)
                }
            })
            setModels(mdls)
            setSelWorkspace(null)
            setPrimaryWS({ ...WorkspaceInit[0], models: mdls })
        } else {
            setModels([])
        }
    }, [selSchema])

    useEffect(() => {
        if (selWorkspace) { setModels(selWorkspace.models) } else {
            if (selSchema) { setModels(selSchema.models) }
        }
    }, [selWorkspace])

    //---backend calls---------
    const ClickUpload = () => {
        let copyvals = { fields: keyparams.copyflds, tables: keyparams.copytbls, metrics: keyparams.copymetrs }
        SchemaUpload("", keyparams.name, keyparams.fromschema, keyparams.color, copyvals, false, user, config, "sch_divuplnew")
        let promise = SchemaImport("", "", user, config, dispatcher, true);
        promise.then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) });
    }

    const SDelete = () => {
        if (selSchema) { SchemaDelete(selSchema.SchemaKey, user, config, dispatcher, "sch_divdel").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) }) }
        //SchemaImport(config, dispatcher)
    }
    const WDelete = () => {
        if (selSchema && selWorkspace) {
            GenericBackendCall(selWorkspace.WorkKey, selSchema.SchemaKey, paths.generics.genericdelete, user, config,
                { model: "Workspace", field: "WorkKey", workspacekey: selWorkspace.WorkKey }, "sch_divdelws").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }
    const BatchDelete = () => {
        if (selModelBatch && selSchema) {
            let models = selModelBatch.models.map((em: EmpModel) => { return em.ModelKey })

            GenericBackendCall(selModelBatch.BatchKey, selSchema.SchemaKey, paths.generics.genericdelete, user, config,
                { model: "ModelBatch", field: "BatchKey", modelkeys: models }, "sch_divdelbatch").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }
    //--------------------------

    const SchemaDropdown2 = () => {
        return (<select id="schema_ddown" value={String(keyparams.selschemakey)} className="schematool" onChange={(e: ChangeEvent<HTMLSelectElement>) => SelectSchema(e)}>
            <option key={""} id={""}>Select from Available Schemas</option>
            {schemas.map((sch: Schema) => {
                return (<option key={sch.SchemaKey} id={sch.SchemaKey}>
                    {sch.SchemaName}
                </option>)
            })}
        </select>)
    };


    const SelectSchema = (e: ChangeEvent<HTMLSelectElement>) => {
        let stkey = e.target.selectedOptions[0].id;

        schemas.forEach((sch: Schema) => {
            if (sch.SchemaKey === stkey) {
                setKeyParams({ ...keyparams, selschemakey: sch.SchemaKey, selschemaname: sch.SchemaName })
            }
        })
    };

    useEffect(() => {
        if (keyparams.viewdetails) {
            setSelSchema(null)
            GenericBackendCall("", keyparams.selschemakey, paths.schema.schemaimport, user, config, { detail: "true", schemakeys: [keyparams.selschemakey] }).then(d => {
                if (d.length === 1) {
                    setSelSchema(d[0])
                    setKeyParams({ ...keyparams, alertmsg: "" })
                } else {
                    setKeyParams({ ...keyparams, viewdetails: false, alertmsg: "Select a schema first" })
                }
            })
        }
    }, [keyparams.viewdetails])

    const ChangeColor = (e: any) => {
        setKeyParams({ ...keyparams, ["color"]: e.hex })
    };

    const ClickActivate = () => {
        //select a schema to guide imports/uploads
        //if (selkey !== "0") {
        locdispatcher(schemadeleteActionCreator([]))
        let found = false
        console.log(selSchema)
        schemas.forEach((sch: Schema) => {
            if (sch.SchemaKey === keyparams.selschemakey) {
                locdispatcher(schemaActionCreator(sch))
                //clear out state data because schema changed?
                found = true
            }
        })

        //}
        if (found) {
            let elem = document.getElementById("sch_activdiv")
            if (elem) { elem.innerHTML = "Activated!" }
        }
    }

    const ClickWorkspace = (ws: Workspace | null) => {
        if (ws) {
            setSelWorkspace(null)
        } else {
            setSelWorkspace(ws)
        }
    }
    const ClickBatch = (mdl: ModelBatch) => {
        console.log(mdl)
        if (selModelBatch && selModelBatch.BatchKey === mdl.BatchKey) {
            setSelModelBatch(null)
        } else {
            setSelModelBatch(mdl)
        }
    }

    return (<div id="schemacanvas">
        <div id="schema_header">Schema Manager</div>
        {user.email !== "guest@guest.com" ?
            <div id="schema_newbox">
                <div>
                    <input id="schema_inputtxt" className="schematool" type="text" placeholder="new schema name"
                        onChange={e => setKeyParams({ ...keyparams, name: e.target.value })}></input>
                </div>
                {keyparams.name !== "GuestSchema" ?
                    <button id="schema_btnnew" className="schemabutton" onClick={_ => setKeyParams({ ...keyparams, isnewvisible: true })}>New Schema</button>
                    : <div>Name is reserved!</div>}
            </div>
            : <div style={{ fontSize: "22px" }}>Guest Login</div>}

        <div id="schema_selectbox">
            <div style={{ display: "flex" }}>
                <SchemaDropdown2 />
                <div style={{ color: "white", margin: "6px" }}>{keyparams.selschemaname}</div>
            </div>
            <div>
                <button id="schema_btnsel" className="schemabutton" onClick={_ => setKeyParams({ ...keyparams, viewdetails: true })}>
                    Explore This Schema
                </button>
                <button className="ndt_btn3" onClick={_ => { ClickActivate() }}>Activate</button>

            </div>

        </div>

        <div className="schemacascadecontainer">
            <div className="sch_frame"></div>
            <div className="sch_frame"></div>
            <div className="sch_frame"></div>
        </div>

        <div id="schema_infocountbox" style={{ color: "#888888" }}>
            {keyparams.viewdetails ?
                <div>
                    {selSchema ?
                        <div>
                            <div className="ndt_title3" style={{ color: "white" }}>{selSchema.SchemaName}</div>
                            <div style={{ fontSize: "20px" }}>Schema Counts:</div>
                            <div>
                                <div>Workspaces: {selSchema.workspacecount + 1}</div>
                                <div>Employee Uploads: {selSchema.modelcount}</div>
                                <div>Dashboard: {selSchema.dashcount}</div>
                                <div>Queries: {selSchema.querycount}</div>
                            </div>
                        </div> : <div>Loading...</div>}
                </div> : <div></div>}

        </div>

        <div id="schema_modeldetails">

            {keyparams.viewdetails && selSchema ?
                <div>
                    <div id="schema_modeldetailsheader">Models</div>
                    <div>
                        <div style={{ color: "#919191" }}>Workspaces</div>
                        <div className="schema_wsitem" style={selWorkspace ? {} : selWSStyle}
                            onClick={(_) => { ClickWorkspace(primaryWS) }}>
                            Workspace: Primary ({primaryWS?.models.length})
                        </div>

                        <div style={{ maxHeight: "170px", overflowY: "scroll" }}>
                            {selSchema?.workspaces.map((ws: Workspace, i: number) => {
                                return (<div key={i} className="schema_wsitem"
                                    style={selWorkspace?.WorkKey === ws.WorkKey ? selWSStyle : {}}
                                    onClick={(_) => { setSelWorkspace(ws) }}>
                                    Workspace: {ws.WorkspaceName} ({ws.models.length})
                                </div>)
                            })}
                        </div>
                    </div>
                    <div>
                        {selWorkspace ? <button className="ndt_btn2" style={{ color: "#919191", fontWeight: "bold", border: "1px soild white", cursor: "pointer" }}
                            onClick={_ => { setKeyParams({ ...keyparams, iswsdelvisible: true }) }}>
                            Delete Workspace {selWorkspace.WorkspaceName}
                        </button> : <div></div>}
                    </div>
                    <div id="schema_modeldetailbox">
                        <div style={{ color: "#919191", fontSize: "18px" }}>Employee Uploads</div>
                        {models.map((mdl: ModelBatch, i: number) => {
                            return (<div key={i} id={mdl.BatchName} className="schema_modelentry"
                                style={mdl.BatchKey === selModelBatch?.BatchKey ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                onClick={(_) => ClickBatch(mdl)}>
                                <div>Name: {mdl.BatchName}</div>
                                <div>Create Date: {mdl.CreateDate}</div>
                                <div>Models: {mdl.models.length}</div>
                            </div>)
                        })}
                        <div style={{ height: "35px" }}>
                            {selModelBatch ?
                                <button className="ndt_btn2" onClick={_ => { setKeyParams({ ...keyparams, isdelbatchvisible: true }) }}>
                                    Delete Upload?</button>
                                : <div></div>}
                        </div>
                    </div>
                </div> : <div></div>}
            {selSchema ?
                <div style={{ marginTop: "10px" }}>
                    {keyparams.viewdetails ?
                        <div style={{ display: "flex" }}>
                            <button className="ndt_btn3" onClick={_ => { ClickActivate() }}>Activate Schema</button>
                            {(userperm && userperm.Permission === "creator") || user.level === "Admin" ?
                                <button className="ndt_btn2" style={{ height: "35px", marginLeft: "5px" }} onClick={_ => { setKeyParams({ ...keyparams, isdelmodalvisible: true }) }}> Delete Schema</button>
                                : <div></div>}
                            <Link to={"/status/" + selSchema.SchemaKey} ><button className="ndt_btn1" style={{ height: "35px", marginLeft: "5px" }}> More Details</button></Link>

                        </div>
                        : <br />}
                    <div id="sch_activdiv" style={{ margin: "4px" }}></div>
                </div> : <div></div>}

        </div>

        {/* design*/}
        <div id="pyramid">
        </div>
        <div className="pbody">
            <div className="ppyramid">
                <div className="pbase"></div>
                <div className="pside pone"></div>
                <div className="pside ptwo"></div>
                <div className="pside pthree"></div>
                <div className="pside pfour"></div>
            </div>
        </div>



        {/* modal*/}
        <Modal show={keyparams.isdelmodalvisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Are you sure you want to delete this schema and all associated models?</div>
                    <button className="ndt_btn1" style={{ width: "70%", minWidth: "300px", overflow: "hidden" }} onClick={_ => SDelete()}> Yes, delete the Schema {selSchema?.SchemaName}</button>
                    <div id="sch_divdel"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelmodalvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.iswsdelvisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Are you sure you want to delete this workspace and all associated models?</div>
                    <button className="ndt_btn1" style={{ width: "50%", minWidth: "350px", overflow: "hidden" }}
                        onClick={_ => WDelete()}> Yes, delete the workspace {selWorkspace?.WorkspaceName}</button>
                    <div id="sch_divdelws"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, iswsdelvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>


        <Modal show={keyparams.isdelbatchvisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Are you sure you want to delete this upload?</div>
                    <button className="ndt_btn1" style={{ width: "50%", minWidth: "350px", overflow: "hidden" }}
                        onClick={_ => BatchDelete()}> Yes, delete the upload {selModelBatch?.BatchName}</button>
                    <div id="sch_divdelbatch"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelbatchvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isnewvisible}>
            <div className="ndt_modal">
                <div style={{ fontSize: "22px" }}>Create New Schema</div>
                <div style={{ fontSize: "18px", margin: "10px" }}>Name: {keyparams.name}</div>
                <div style={{ margin: "10px" }}>
                    <div>Copy from Schema: {GetObjectField(keyparams.fromschema, schemas, "SchemaKey", "SchemaName")}</div>
                    <GenericDropdown
                        data={schemas}
                        keycol="SchemaKey"
                        namecol="SchemaName"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, schemas, keyparams, setKeyParams, "fromschema", "SchemaKey", "") }}
                        value={keyparams.fromschema}
                        className="ndt_dropdown"
                    />
                    <div style={{ display: "flex" }}>
                        <button className="ndt_btn1 schmgr_btn" style={keyparams.copyflds ? clickstyle.itmSelected : clickstyle.itmUnselected}
                            onClick={_ => { setKeyParams({ ...keyparams, copyflds: !keyparams.copyflds }) }}>
                            Fields</button>
                        <button className="ndt_btn1 schmgr_btn" style={keyparams.copytbls ? clickstyle.itmSelected : clickstyle.itmUnselected}
                            onClick={_ => { setKeyParams({ ...keyparams, copytbls: !keyparams.copytbls }) }}>
                            Tables</button>
                        <button className="ndt_btn1 schmgr_btn" style={keyparams.copymetrs ? clickstyle.itmSelected : clickstyle.itmUnselected}
                            onClick={_ => { setKeyParams({ ...keyparams, copymetrs: !keyparams.copymetrs }) }}>
                            Metrics</button>
                    </div>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "20px" }}>
                    <div style={{ background: keyparams.color, color: GetTextColor(keyparams.color) }}>Selected Color</div>
                    <div style={{ padding: "10px" }}>
                        <SwatchesPicker onChangeComplete={(e: any) => ChangeColor(e)} />
                    </div>
                </div>
                <button className="ndt_btn1" style={{ width: "200px", marginLeft: "15px" }} onClick={_ => ClickUpload()}>Create</button>
                <div id="sch_divuplnew"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isnewvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal >

    </div >)
};
export default SchemaManage;