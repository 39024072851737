import axios from "axios";

const ColorModelImport = async (modelkey: string, schema: string, config: any, dispatcher: any, store: boolean) => {

    let data: any[] = []
    let dataArray = new FormData();
    dataArray.append("schemakey", schema);
    dataArray.append("modelkey", modelkey);


    try {
        let res = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/api/colorsets/colormodelview`,
            dataArray, config);
        if (res.data.length > 0) {
            data = res.data
        }
    } catch (error) {
        console.log(error)
    }
    return (data)
};
export default ColorModelImport;