import {
    BasicQuery, ChartSeries, Dashboard, DashboardFilter, DashboardPage, FieldMap, FilterField,
    KPIString, Metric, queryfiltervaluestate, QueryModel, QueryRunDate, QueryVisuals, queryvisualstate,
    QueryColor, KPI, DashRunDate, BenchModel, Benchmarkset, Key_Name
} from "../../../type";
import { useState, useEffect, useContext, useMemo, ChangeEvent } from "react";
import { StartupContext } from "../../../App";
import CreateChart from "../../../charts/CreateChart";
import GetQueryRunDateKey from "../../../functions/QueryFunctions/GetQueryRunDateKey";
import ChartIcon from "../../../charts/ChartIcon";
import { Link } from "react-router-dom";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { BasicQueryInit, QueryModelInit } from "../../../InitTypes";
import { queryvisInit } from "../../../InitTypes";
import FieldsImport from "../../../data/Fields/Fields_Import";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import MultiQueryFilterValues from "../../../functions/QueryFunctions/MultiQueryFilterValues";
import CheckObjectKey from "../../../functions/Object Functions/CheckObjectKey";
import GetObjectfromArray from "../../../functions/Array Functions/GetObjectfromArray";
import { useParams } from "react-router-dom";
import ExcelExport from "../../../functions/ExcelExport";
import "./css/DashboardView.css";
import { Modal } from "react-bootstrap";
import FilterDataMultiple from "../../../functions/Filter Functions/FilterDataMultiple";
import ConvertChartTypetoShow from "../../../functions/QueryFunctions/ConvertChartTypetoShow";
import StringAfterDelimeter from "../../../functions/String Functions/StringAfterDelimeter";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import ConvertKPIString from "../../../functions/QueryFunctions/kpistrings/ConvertKPIString";
import ProduceKPIStringText from "../../../functions/QueryFunctions/kpistrings/ProduceKPIStringText";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import ConvertBackendDates from "../../../functions/Date Functions/ConvertBackendDates";
import ConvertBackendArryString from "../../../functions/String Functions/ConvertBackendArryString";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import GetFieldName from "../../../functions/GetFieldName";
import DateString from "../../../functions/Date Functions/DateString";
import ChartSample from "../../../charts/sample/ChartSample";
import IsInObject from "../../../functions/Object Functions/IsInObject";
import QueryVisualsPrepare from "../../../functions/QueryFunctions/QueryVisualsPrepare";
import TableSample from "../../../charts/sample/TableSample";
import ObjectSort from "../../../functions/ObjectSort";
import ConvertArrayStringstoNumber from "../../../functions/Array Functions/ConvertArrayStringstoNumber";
import ArraySlice from "../../../functions/Array Functions/ArraySlice";
import SampleChartDelete from "../../../charts/sample/SampleChartDelete";


const DashboardView = () => {

    let { url } = useParams()

    interface dash_state {
        sidebaractive: boolean;
        selquery: string;
        selqueryint: number; //queryvisuals int
        selvalue: string;
        isexportvisible: boolean;
        asofdate: Date | null;
        refresh: number;
        reload: number;
        isinspectvisible: boolean;
        inspectType: string;
        isoptionsvisible: boolean;
        qryinfoint: number;
        isinfovisible: boolean;
    }

    const { config, dispatcher, schema, user, paths, clickstyle } = useContext(StartupContext)
    const context = useContext(StartupContext)

    const [dashboardFull, setDashboardFull] = useState<Dashboard | null>(null)
    const [fields, setFields] = useState<FieldMap[]>([])
    const [loadingMsg, setLoadingMsg] = useState<string>("...loading")
    const [queries, setQueries] = useState<QueryModel[]>([])
    const [filterValueData, setFilterValueData] = useState<any>({})
    const [benchmodels, setBenchmodels] = useState<BenchModel[]>([])
    const height = +window.outerHeight
    const width = +window.outerWidth

    const sidebarOpen = { width: '400px', position: 'absolute' }
    const sidebarClosed = { width: '20px', position: 'absolute' }
    const [sidebarStyle, setSidebarStyle] = useState<any>(sidebarClosed)
    const [dashstate, setDashState] = useState<dash_state>({
        sidebaractive: false, selquery: "", selqueryint: 0, selvalue: "", isexportvisible: false,
        asofdate: null, refresh: 0, reload: 0, isinspectvisible: false, inspectType: '', qryinfoint: -1, isinfovisible: false,
        isoptionsvisible: false
    })
    const [loadeddash, setLoadedDash] = useState<boolean>(false)
    const [loadedmetrics, setLoadedMetrics] = useState<boolean>(false)
    const [loadedkpis, setLoadedKPIs] = useState<boolean>(false)
    const [loadedinitqueries, setLoadedInitQueries] = useState<boolean>(false)
    const [loadedqueries, setLoadedQueries] = useState<boolean>(false)
    const [loadedfields, setLoadedFields] = useState<boolean>(false)
    const [loadedrundates, setLoadedRunDates] = useState<boolean>(false)
    const [loadedvisstates, setLoadedVisStates] = useState<boolean>(false)
    const [loadedbenchmodels, setLoadedBenchmodels] = useState<boolean>(false)
    const [loadedbenchmarks, setLoadedBenchmarks] = useState<boolean>(false)
    const [appliedbenchSets, setAppliedBenchSets] = useState<Benchmarkset[]>([])
    const [loadedall, setLoadedAll] = useState<boolean>(false)

    const [inspdata, setInspData] = useState<any[]>([])
    const [inspDataNote, setInspDataNote] = useState<string>("")
    const [runDates, setRunDates] = useState<QueryRunDate[]>([])

    const [pageNo, setPageNo] = useState<number>(1)
    const [vis_states, setVis_States] = useState<queryvisualstate[]>(queryvisInit)
    //const [views, setViews] = useState<string[]>([])
    const [rundatekeys, setRunDateKeys] = useState<string[]>([]) //matches qryvis order, not querymodel
    const [metrics, setMetrics] = useState<Metric[]>([])
    const [kpistrings, setKPIstrings] = useState<KPIString[]>([])
    const [kpis, setKPIs] = useState<KPI[]>([])
    const [selBasicRow, setSelBasicRow] = useState<BasicQuery | null>(null)
    //const [parseKSTRs, setParseKSTRs] = useState<parsed_kpistr[]>([])
    const [qryintmap, setQryIntMap] = useState<any[]>([])

    useEffect(() => { //fields
        FieldsImport("", schema, config, dispatcher, false).then((d: FieldMap[]) => {
            setFields(d)
            setLoadedFields(true)
        })
    }, [])

    useEffect(() => { //metrics
        GenericBackendCall("", schema, paths.query.metricview, user, config, {}, "").then((md: Metric[]) => {
            setMetrics(md)
            setLoadedMetrics(true)
        })
    }, [])

    useEffect(() => {//kpis
        if (dashboardFull) {
            //kpi strings
            let kpikeys: string[] = []
            dashboardFull.kpistrings.forEach((ks: KPIString) => {
                ks.KPIs.forEach((k: string) => {
                    kpikeys.push(k)
                })
            })
            let newstrings: KPIString[] = []
            GenericBackendCall("", schema, paths.kpi.kpiview, user, config, { modelkeys: kpikeys, full: true }, "", "", true).then(d => {
                dashboardFull.kpistrings.forEach((ks: KPIString) => {
                    let newtxt = ConvertKPIString(ks.Text, d, dashstate.asofdate, false, false)
                    let arrystrs = ConvertKPIString(ks.Text, d, dashstate.asofdate, true, true)

                    if (typeof (newtxt) === "string" && typeof (arrystrs) !== "string") {
                        newstrings.push({ ...ks, Text: newtxt, HiddenText: ks.Text, parsed: arrystrs })
                    }
                })
                setKPIstrings(newstrings)
            })

            //kpi name import
            GenericBackendCall("", schema, paths.kpi.kpiview, user, config, { nameonly: 'true' }).then(d => {
                setKPIs(d)
                setLoadedKPIs(true)
            })
        }
    }, [dashboardFull])

    useEffect(() => {//dashboard
        if (url) {
            GenericBackendCall("", schema, paths.dashboard.dashview, user, config, { url: url }).then((dash: Dashboard | null) => {
                if (dash) {
                    dash.rundates = ConvertBackendDates(dash.rundates)
                    dash.rundates = ObjectSort(dash.rundates, "AsofDate", true)
                }
                setDashboardFull(dash)
                if (dash && dash.visuals) {
                    setLoadedDash(true)
                } else {
                    setLoadingMsg("loaded nothing")
                }
            })
        }
    }, [])


    useEffect(() => { //queries
        if (dashboardFull) {
            let querykeys: string[] = []

            dashboardFull.visuals.forEach((qv: QueryVisuals) => {
                querykeys.push(qv.ModelName)
            })
            setLoadedInitQueries(false)
            GenericBackendCall("", schema, paths.query.queryview, user, config, { querykeys: querykeys, type: "names" })
                .then((qrys: QueryModel[]) => {
                    let newqrys = qrys.map((qm: QueryModel) => {
                        return ({ ...qm, Metrics: ConvertBackendArryString(qm.MetricOrder) })
                    })
                    console.log(newqrys)
                    setQueries(newqrys)
                    setLoadedQueries(true)
                    setLoadedInitQueries(true)
                })
        }
    }, [dashboardFull, dashstate.asofdate])

    useEffect(() => { //benchmodels
        if (loadedqueries) {
            let qkeys: string[] = []
            queries.forEach((qry: QueryModel) => {
                qkeys.push(qry.ModelKey)
            })
            GenericBackendCall("", schema, paths.benchmark.benchmodelview, user, config, { querykeys: qkeys }).then(bm => {
                setBenchmodels(bm)
                setLoadedBenchmodels(true)
            })
        }
    }, [loadedqueries])

    useEffect(() => {
        if (loadedrundates) {
            let ckeys: string[] = []
            let multidates: Date[] = []
            dashboardFull?.visuals.forEach((qv: QueryVisuals, i: number) => {
                qv.chartseries.forEach((cs: ChartSeries) => {
                    if (cs.BenchmarkModelName !== "" && cs.BenchmarkMode !== "None") {
                        let qry1: QueryModel = GetObjectField(qv.ModelName, queries, "ModelKey", "")
                        let asofdate = GetObjectField(rundatekeys[i], qry1.rundates, "DateKey", "AsofDate")
                        ckeys.push(cs.BenchmarkModelName)
                        multidates.push(asofdate)
                    }
                })
            })

            GenericBackendCall("", schema, paths.benchmark.benchmarksetview, user, config, { modelkeys: ckeys, multi: multidates }).then(d => {
                setAppliedBenchSets(d)
                setLoadedBenchmarks(true)
            })
        }
    }, [loadedrundates])

    useEffect(() => {
        if (dashstate.inspectType !== "") {
            Insp_Click(dashstate.inspectType)
        }
    }, [dashstate.inspectType])

    useEffect(() => {
        if (loadedinitqueries) {
            QueryReload()
        }
    }, [dashboardFull, loadedinitqueries])

    useEffect(() => {
        if (dashboardFull && loadedmetrics && loadedfields && loadedqueries && loadedrundates) {
            //vis states init
            let tmpstates: queryvisualstate[] = [];
            dashboardFull.visuals.forEach((qv: QueryVisuals) => {
                let st = queryvisInit[0]
                queries.forEach((q: QueryModel) => {
                    let fldkeys = q.filterfields.map((f: FilterField) => { return f.FieldName })
                    if (qv.ModelName === q.ModelKey) {
                        st = { ...st, chartkey: qv.VisKey, querykey: q.ModelKey, queryname: qv.VisName, filterfields: fldkeys }
                    }
                })
                //preset metric name - for table horiz
                let selmetrname = ''
                if (qv.ShowDefault !== "") {
                    metrics.forEach((m: Metric) => {
                        if (m.MetricKey === qv.ShowDefault) {
                            selmetrname = m.MetricName
                        }
                    })
                }
                st = { ...st, show: ConvertChartTypetoShow(qv.ChartType), selectedmetricname: selmetrname, clickevent: "dashboard" }
                tmpstates.push(st)
            })
            dashboardFull.kpistrings.forEach((ks: KPIString) => {
                tmpstates.push({ ...queryvisInit[0], chartkey: ks.Key, querykey: ks.Key, queryname: ks.Name, show: "KPIString" })
            })

            //filter values

            let fltvals = MultiQueryFilterValues(queries, dashboardFull.filters, rundatekeys, fields)
            setFilterValueData(fltvals)

            setVis_States(tmpstates)
            setLoadedVisStates(true)
        }
    }, [dashboardFull, metrics, fields, loadedmetrics, loadedfields, loadedqueries, loadedvisstates, loadedrundates])

    useEffect(() => {
        if (loadeddash && loadedfields && loadedmetrics && loadedkpis && loadedqueries && loadedrundates &&
            loadedvisstates && loadedbenchmodels && loadedbenchmarks) {
            setLoadedAll(true)
        }
    }, [loadeddash, loadedfields, loadedkpis, loadedqueries, loadedmetrics, loadedrundates, loadedvisstates, loadedbenchmodels, loadedbenchmarks])

    useEffect(() => {
        if (loadedall) {
            PopulateCharts()
        }
    }, [loadingMsg, filterValueData, vis_states, pageNo, loadedall, dashstate.reload])

    useEffect(() => {
        if (loadedall && queries.length > 0) {

            let fltfldarry: string[][] = []
            let fltvalarry: string[][] = []

            //convert
            console.log(filterValueData)

            vis_states.forEach((vs: queryvisualstate) => {
                let newflts: string[] = []
                let newvals: string[] = []
                Object.keys(filterValueData).map((k: string) => {
                    let qfvs: queryfiltervaluestate = filterValueData[k]
                    queries.forEach((qry: QueryModel) => {
                        if (qry.ModelKey === vs.querykey) {
                            if (IsInObject(qry.filterfields, k, "FieldName")) {
                                newflts.push(k)
                                newvals.push(qfvs.Selected)
                            }
                        }
                    })
                })
                fltfldarry.push(newflts)
                fltvalarry.push(newvals)
            })

            let newvisstate: queryvisualstate[] = vis_states.map((vs: queryvisualstate, i: number) => {
                return { ...vs, filterfields: fltfldarry[i], filtervals: fltvalarry[i] }
            })

            setVis_States(newvisstate)
        }
    }, [filterValueData])

    useEffect(() => {
        vis_states.forEach((vs: queryvisualstate) => {
            let elem = document.getElementById("chart" + vs.chartkey)
            if (elem) {
                if (vs.filtervals.length === 0 || (vs.filtervals.length === 1 && vs.filtervals[0] === "")) {
                    elem.style.boxShadow = ""
                } else {
                    elem.style.boxShadow = "0px 0px 15px red,inset 0px 0px 5px red"
                }
            }
        })
    }, [pageNo, vis_states])

    const QueryReload = () => {
        if (dashboardFull) { //rundates
            console.log('rerun queries')
            let otherdata = {}
            let querykeys: string[] = []
            dashboardFull.visuals.forEach((qv: QueryVisuals) => {
                querykeys.push(qv.ModelName)
            })
            //test replace queries import with rundates direct import

            //run dates
            let qryrundatekeys: string[] = []
            let qrymaps: any[] = []
            let rdates: QueryRunDate[] = []
            dashboardFull.visuals.map((qv: QueryVisuals, i: number) => {
                queries.forEach((qry: QueryModel, j: number) => {
                    if (qv.ModelName === qry.ModelKey) {
                        let rdkey = GetQueryRunDateKey(qv, qry.rundates, dashstate.asofdate)
                        qryrundatekeys.push(rdkey)

                        qrymaps.push({ QueryInt: j, VisualsInt: i })
                        let rd = GetObjectField(rdkey, qry.rundates, "DateKey", "")
                        rdates.push({ ...rd, basicquery: [] })
                    }
                })
            })
            setQryIntMap(qrymaps)
            setRunDateKeys(qryrundatekeys)
            setRunDates(rdates)

            otherdata = { how: "keys", rdkeys: qryrundatekeys }

            GenericBackendCall("", schema, paths.query.rundatesview, user, config, otherdata).then(rd => { //rundates
                let rdkeys: string[] = []
                queries.forEach((qry: QueryModel) => {
                    qry.rundates = []
                    rd.forEach((r: QueryRunDate) => {
                        if (r.ModelName === qry.ModelKey) {
                            qry.rundates.push(r)
                            rdkeys.push(r.DateKey)
                        }
                    })
                })
                console.log(queries)
                setQueries(queries)
                setLoadedRunDates(true)
                setDashState({ ...dashstate, reload: dashstate.reload + 1 })
            })
        }
    }

    const OpenSidebar = () => {
        setSidebarStyle(sidebarOpen)
        setDashState({ ...dashstate, sidebaractive: true })
    }
    const CloseSidebar = () => {
        setSidebarStyle(sidebarClosed)
        setDashState({ ...dashstate, sidebaractive: false })
    }


    const ChangeFilter = (e: ChangeEvent<HTMLSelectElement>, fld: string) => {
        let selval = e.target.selectedOptions[0].attributes[1].value
        setFilterValueData({ ...filterValueData, [fld]: { ...filterValueData[fld], Selected: selval } })
    }

    const ClearFilters = () => {
        let newfltvals: any = {}
        Object(filterValueData).keys.forEach((k: string) => {
            newfltvals[k] = { ...filterValueData[k], Selected: "" }
        })
        setFilterValueData(newfltvals)
    }

    const FileExport = (includemeta: boolean) => {
        let qrydata: any[][] = []
        let sheetnames: string[] = []
        let metadata: any[] = []
        dashboardFull?.visuals.forEach((qv: QueryVisuals, i: number) => {
            queries.forEach((qry: QueryModel) => {
                if (qv.ModelName === qry.ModelKey) {
                    let updqry = { ...qry, XPos: qv.XPos, YPos: qv.YPos, Height: qv.Height, Width: qv.Width, Page: qv.PageNo }
                    qry.rundates.forEach((rd: QueryRunDate) => {
                        rundatekeys.forEach((rdstr: string) => {
                            if (rd.DateKey === rdstr) {
                                let fltdata = FilterDataMultiple(rd.basicquery,
                                    ["Complexity", "FilterLevel", "FilterValue1", "FilterValue2"],
                                    ["Simple", "All", "", ""],
                                    "and")
                                qrydata.push(fltdata)
                                sheetnames.push(qry.ModelName + "_" + i);

                                [1, 2, 3, 4].forEach((a: number) => {
                                    if (qry.Metrics.length >= a) {
                                        //rather than parse name
                                        let metrname = GetObjectField(qry.Metrics[a - 1], metrics, "MetricKey", "MetricName")
                                        updqry = { ...updqry, ["Metric" + String(a)]: metrname }
                                    } else {
                                        updqry = { ...updqry, ["Metric" + String(a)]: "" }
                                    }
                                })

                                qv.colors.forEach((c: QueryColor, i: number) => {
                                    updqry = { ...updqry, ["Color" + String(i + 1)]: c.Color }
                                })

                                metadata.push(updqry)
                            }
                        })
                    })
                }
            })
        })
        if (includemeta) {
            qrydata.push(metadata)
            sheetnames.push("qry_meta")

            let kpisheetdata: any[] = []
            kpistrings.forEach((ks: KPIString) => {
                kpisheetdata.push(ks)
            })
            qrydata.push(kpisheetdata)
            sheetnames.push("Strings")

            let pgsheetdata: any[] = []
            dashboardFull?.pages.forEach((pg: DashboardPage) => {
                pgsheetdata.push(pg)
            })
            qrydata.push(pgsheetdata)
            sheetnames.push("Pages")

        }
        ExcelExport({ csvDataArry: qrydata, sheetNames: sheetnames, filename: dashboardFull?.Name + "_data" })
    };

    const ClickKPI = (kpistr: KPIString, kpikey: string) => {
        vis_states.forEach((vs: queryvisualstate) => {
            if (vs.querykey === kpistr.Key) {
                vs.selChartElemMetricNo = -1
                vs.selectedmetric = ""
                kpistr.KPIs.forEach((k: string, n: number) => {
                    if (k === kpikey) {
                        vs.selChartElemMetricNo = n
                        vs.selectedmetric = k
                    }
                })
            }
        })
        setVis_States(vis_states)
        setDashState({ ...dashstate, refresh: dashstate.refresh + 1 })
    }

    const Click_Compare = () => {
        if (dashboardFull && dashstate.asofdate) {
            GenericBackendCall(dashboardFull.Key, schema, paths.answers.defaultdashboard, user, config, { asofdate: dashstate.asofdate, runkeys: rundatekeys, type: 'Variance' },
                "dv_optdiv", "Complete").then(d => {
                    //just manually re-color all the bars/etc? 
                    let data = SerializeDataFrame(d)
                })
        } else {
            let elem = document.getElementById("dv_optdiv")
            if (elem) { elem.innerHTML = "Select a run date for this dashboard" }
        }
    }

    const ClickRunFactorAll = () => {
        if (dashboardFull && dashstate.asofdate) {

            GenericBackendCall(dashboardFull.Key, schema, paths.answers.defaultdashboard, user, config, { asofdate: dashstate.asofdate, runkeys: rundatekeys, type: 'FactorAll' },
                "dv_divfactorall", "Complete").then(d => {
                    //just manually re-color all the bars/etc? 
                    let data = SerializeDataFrame(d)
                    console.log(data)
                })
        } else {
            let elem = document.getElementById("dv_divfactorall")
            if (elem) { elem.innerHTML = "Select a dashboard date first" }
        }
    }

    const DashboardLayout = () => {
        //make sure queryvis is the same index as vis_state
        if (dashboardFull?.visuals) {
            return (<div>
                {dashboardFull?.visuals.map((qgraph: QueryVisuals, i: number) => {
                    if (qgraph.PageNo === pageNo) {
                        let position = {
                            'marginLeft': String(+qgraph.XPos * width + "px"), 'marginTop': String(+qgraph.YPos * height + "px"),
                            'height': String(+qgraph.Height * height / 1000 + "px"), 'width': String(+qgraph.Width * width / 1000 + "px"),
                            'border': "3px solid black", backgroundColor: "white", boxShadow: "-1px -1px 3px #999999, 3px 3px 6px #919191"
                        }

                        let metrs = qgraph.chartseries.map((cs: ChartSeries) => {
                            return { Key: cs.MetricName, Name: StringAfterDelimeter(cs.MetricName, "__") }
                        })
                        return (<div
                            key={qgraph.VisKey}
                            className="livegraph_itm"
                            style={position}
                            id={"chart" + qgraph.VisKey}
                        >
                            <div style={{ display: 'flex' }}>
                                <i id={"info-circle" + qgraph.VisKey}
                                    className="fa fa-info"
                                    style={{ "fontSize": 16, "cursor": "pointer", float: "right" }}
                                    onClick={_ => { setDashState({ ...dashstate, qryinfoint: i, isinfovisible: true }) }}
                                />
                                <div style={{ width: "95%" }}></div>
                                <ChartIcon
                                    iconID={"icon" + qgraph.VisKey}
                                    vis_states={vis_states}
                                    setVis_States={setVis_States}
                                    int={i}
                                    charttype={qgraph.ChartType}
                                    metrics={metrs}
                                />
                            </div>
                            <div ></div>
                        </div>)
                    } else {
                        return (<div key={i}></div>)
                    }
                })}
            </div>)
        } else {
            return (<div>No Visuals</div>)
        }
    }

    let dashlayoutMemo = useMemo(() => { return DashboardLayout() }, [dashboardFull, vis_states, pageNo, loadedall])

    const GetKPIStringLength = (kpistr: KPIString) => {
        let spl = kpistr.Text.split("#nl#")
        let maxlen = 0
        spl.forEach((s: string) => {
            if (s.length > maxlen) {
                maxlen = s.length
            }
        })
        return ([spl.length, maxlen])
    }

    const KPILayout = () => {
        return (<div>
            {kpistrings.map((ks: KPIString, i: number) => {
                if (ks.PageNo === pageNo) {

                    let thisvs: queryvisualstate = GetObjectfromArray(vis_states, ks.Key, "querykey")
                    let [stringrows, stringlen] = GetKPIStringLength(ks)

                    let heightsize = (+ks.Height * height / 1000) / (2 * stringrows)
                    let widthsize = (+ks.Width * width / 1000) / (1 * stringlen)
                    let area = Math.max(18, Math.min(80, heightsize, widthsize))

                    let position = {
                        'marginLeft': String(Math.round(+ks.XPos * width) + "px"), 'marginTop': String(Math.round(+ks.YPos * height) + "px"),
                        'height': String(+ks.Height * height / 1000 + "px"), 'width': String(+ks.Width * width / 1000 + "px"),
                        'border': "3px solid black", backgroundColor: "white",
                        'fontSize': Math.round(area) + 'px', boxShadow: "-1px -1px 3px #999999, 3px 3px 6px #919191"
                    }


                    return (<div
                        key={ks.Key}
                        className="livegraph_itm"
                        style={position}
                        id={"#chart" + ks.Key}
                    >
                        <div style={{ display: 'flex' }}>

                            <div style={{ width: "95%", padding: '10px' }}>{ProduceKPIStringText(ks, thisvs.selChartElemMetricNo)}</div>

                        </div>
                    </div>)
                } else {
                    return (<div key={i}></div>)
                }
            })}
        </div>)
    }
    let kpilayoutMemo = useMemo(() => { return KPILayout() }, [dashboardFull, vis_states, pageNo, dashstate.refresh, loadedall, kpistrings])

    const PopulateCharts = () => {
        //each query visual, find color model, query
        if (dashboardFull && dashboardFull.visuals) {
            dashboardFull.visuals.map((qryvis: QueryVisuals, i: number) => {
                qryvis = QueryVisualsPrepare(qryvis, null)
                if (qryvis.PageNo === pageNo) {
                    let clrmodel = null;
                    if (qryvis.ColorModelName !== null) {
                        let colpromise = GenericBackendCall(qryvis.ColorModelName, schema, paths.colorsets.colormodelview, user, config, "")
                        colpromise.then(c => {
                            clrmodel = c[0]
                        })
                    }

                    let qgraph: QueryModel = QueryModelInit[0];

                    queries.forEach((q: QueryModel, n: number) => {
                        if (q.ModelKey === qryvis.ModelName) {
                            qgraph = q;
                        }
                    })
                    if (qgraph.ModelKey !== "") {
                        let xaxisname = GetFieldName(fields, qgraph.XFieldName)
                        qgraph.XFieldName = xaxisname;
                        //just default to using primary for now
                        if (vis_states.length > i) {
                            CreateChart(
                                qgraph, qryvis, clrmodel, rundatekeys[i], fields, metrics, benchmodels, appliedbenchSets, "#chart" + qryvis.VisKey,
                                [+qryvis.Width * width / 1000 - 50, +qryvis.Height * height / 1000 - 50], "icon" + qryvis.VisKey,
                                vis_states, setVis_States, i, context
                            )
                        }
                    }
                }
            })
        }
        return (<div></div>)
    }
    const MakeSampleChart = (data: any[]) => {
        const randcolors = ["#4872ba", "#de49a9"]
        //haven't bothered to comb for elem chartseries to get specific formatting
        ChartSample("Line", "#dvchartvalues",
            data, [],
            ["AsofDate", ["Value"], ""], ["As of Date", "Value"],
            "Discrete", randcolors,
            [700, 500], "On", false, { format: "", rounding: 2, clr: "white", fntsize: '8px', lineclr: "white", secondarylineclr: "blue" }
        )
    }


    const MakeSampleTable = (data: any[]) => {
        data = ObjectSort(ConvertArrayStringstoNumber(data), "Difference", true)
        let dataslice = ArraySlice(data, 4)
        TableSample(dataslice, "#dvdivtbl")
    }

    const Filters = () => {
        if (dashboardFull && dashboardFull.filters) {
            return (
                <div style={{ display: "flex" }}>
                    {dashboardFull.filters.map((flt: DashboardFilter, i: number) => {
                        if (CheckObjectKey(filterValueData, flt.FieldName)) {
                            return (<div key={i}>
                                <GenericDropdown
                                    data={filterValueData[flt.FieldName].Values}
                                    keycol={""}
                                    className='ndt_dropdown dashddownflt'
                                    promptstring={"Select " + GetFieldName(fields, flt.FieldName)}
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeFilter(e, flt.FieldName) }}
                                />
                            </div>)
                        }
                    })}
                </div>
            )
        } else {
            return (<div></div>)
        }
    }

    const filtersMemo = useMemo(() => { return Filters() }, [dashboardFull, vis_states, filterValueData, loadedall])

    const SelectedValue = () => {

        return (
            <div style={{ height: "500px" }}>
                <GenericDropdown
                    data={vis_states}
                    keycol="queryname"
                    namecol="queryname"
                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                        setDashState({
                            ...dashstate,
                            selquery: e.target.selectedOptions[0].attributes[1].value,
                            selqueryint: +e.target.selectedOptions[0].attributes[0].value
                        })
                    }}
                    promptstring="Select Graph"
                />

                {vis_states.map((state: queryvisualstate, i: number) => {

                    if (dashstate.selquery === state.queryname && i === dashstate.selqueryint) {
                        //query
                        let selElem = null
                        if (state.selChartElemIndex !== "") {
                            selElem = document.getElementById(state.selChartElemIndex)
                        }
                        if (selElem) {
                            //let dataID = selElem?.attributes[2].value
                            // if (dashboardFull) {
                            //     let data = GetQueryDatabyID(dashboardFull.visuals[i], queries, rundates[i], +dataID)
                            // }
                            return (
                                <div key={i}>
                                    <div>{state.queryname}</div>
                                    <div>{selElem.id}</div>
                                    Investigate

                                </div>
                            )
                        } else if (state.show === "KPIString") {
                            //kpi
                            let kpistr: KPIString = GetObjectfromArray(kpistrings, state.querykey, "Key")
                            return (<div key={i} style={{ marginTop: "10px" }}>
                                <div style={{ letterSpacing: ".05em", fontSize: "18px", marginLeft: "10px" }}>Select KPI</div>
                                <div key={-1} className="dash_sdbr_kpiitem" style={{ backgroundColor: "#333333" }} onClick={_ => { ClickKPI(kpistr, "") }}>
                                    Clear
                                </div>
                                {kpistr.KPIs.map((kpikey: string, m: number) => {
                                    return (
                                        <div key={m} className="dash_sdbr_kpiitem" onClick={_ => { ClickKPI(kpistr, kpikey) }}>
                                            {GetObjectField(kpikey, kpis, "Key", "KPIName")}
                                        </div>
                                    )
                                })}
                                <div>
                                    {vis_states[i].selChartElemMetricNo >= 0 ? <div>
                                        Found KPI
                                        {vis_states[i].selectedmetric}
                                    </div> : <div></div>}
                                </div>
                            </div>)
                        } else {
                            return (<div key={i}>No Chart Value Selected</div>)
                        }
                    }
                })}
            </div >
        )
    }

    const QueryInfoData = () => {
        let visname = dashboardFull?.visuals[dashstate.qryinfoint].VisName
        let chttitle = dashboardFull?.visuals[dashstate.qryinfoint].ChartTitle

        let qint = GetObjectField(String(dashstate.qryinfoint), qryintmap, "VisualsInt", "QueryInt")
        console.log(queries[qint].XFieldName, queries[qint].GroupFieldName)
        let xfield = GetFieldName(fields, queries[qint].XFieldName)
        let groupfield = GetFieldName(fields, queries[qint].GroupFieldName)
        let qryname = queries[qint].ModelName
        let asofdate = GetObjectField(rundatekeys[qint], queries[qint].rundates, "DateKey", "AsofDate")

        return (<div>
            <div>Chart: {chttitle}</div>
            <div>As of Date: {DateString(asofdate)}</div>
            <div>Visuals: {visname}</div>
            <div>Query Name: {qryname}</div>
            <div>X Axis: {xfield}</div>
            <div>Grouping: {groupfield}</div>
        </div>)
    }

    let selValueMemo = useMemo(() => { return SelectedValue() }, [dashstate, vis_states])



    //-----data inspect-----
    const ClickInspect = () => {
        let elem = document.getElementById("dsh_insbtn")
        if (elem) {
            setDashState({ ...dashstate, selvalue: elem.attributes[1].value, isinspectvisible: true })
            GenericBackendCall("", schema, paths.generics.genericfilterview, user, config, { modelkeys: [elem.attributes[1].value], filterfield: "id", model: "BasicQuery" }).then((d: BasicQuery[]) => {
                let rowdata = SerializeDataFrame(d)
                if (rowdata.length === 1) {
                    let br: BasicQuery[] = SerializeDataFrame(d)
                    setSelBasicRow(br[0])

                }
            })
        }
    }

    const Insp_Click = (val: string) => {
        if (selBasicRow) {

            GenericBackendCall("", schema, paths.answers.default, user, config,
                { type: val, valueid: selBasicRow.id, metricno: selBasicRow.MetricNo }, "").then(d => {
                    console.log(d)
                    if (d.length > 0 && typeof (d[0]) == "string") {
                        setInspData([])
                        setInspDataNote('No Insights available!')
                    } else {
                        let returndata = SerializeDataFrame(d[0])
                        console.log(returndata)

                        SampleChartDelete("#dsamplechart")

                        if (returndata.length > 0) {

                            if (val === "Value History") {
                                MakeSampleChart(returndata)
                            } else {



                            }

                            if (val === "Forecast") {
                                MakeSampleTable([{ "A": 1 }])
                            } else if (val === "Factor Analysis") {
                                MakeSampleTable(returndata)
                            }
                        }

                        setInspData(returndata)
                        setInspDataNote("")
                    }
                })
        }
    }

    return (<div >
        {dashboardFull && loadedall ? <div>
            <div id="dashheader">
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "10px" }}>
                        <div style={{ fontSize: '26px', width: "400px", marginLeft: "5px", letterSpacing: ".1em" }}>{dashboardFull.Name}</div>
                        <div style={{ display: "flex" }}>
                            <GenericDropdown
                                data={dashboardFull.rundates}
                                keycol="DateKey"
                                namecol="AsofDate"
                                promptstring="Select As of Date"
                                includeDefault={true}
                                defaultstring={"None - Manual"}
                                divID="dv_asofdate_ddown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                    ChangeParamSelectorbyIndex(e, dashboardFull.rundates, dashstate, setDashState, "asofdate", "AsofDate", null)

                                    setLoadedQueries(false)
                                    setLoadedRunDates(false)
                                }}
                                className="ndt_dropdown"
                            />
                            <div style={{ marginTop: "0px" }}>
                                <Link className="nav-link active" aria-current="page" to={"/dashboard/" + dashboardFull.Key + "/" + dashboardFull.UrlString + "/notes"}>
                                    <button style={{ width: "200px", backgroundColor: '#444444', color: "white", padding: '1px', borderRadius: '5px' }}>Notes</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <div id="dsh_selectoptdiv" style={{ marginTop: '40px', minWidth: "250px" }}>
                        <button className="ndt_btn1" onClick={_ => { setDashState({ ...dashstate, isoptionsvisible: true }) }}>Dashboard Options</button>
                        <button id="dsh_insbtn" data-value="" className="ndt_btn1"
                            onClick={_ => { ClickInspect() }}>Inspect</button>

                    </div>
                </div>
                <div id="dsh_selpagediv" style={{ minWidth: "500px", height: "55px", display: "flex", marginLeft: "100px", marginTop: "20px", overflowX: "scroll", overflowY: "hidden" }}>
                    {dashboardFull.pages.map((pg: DashboardPage, i: number) => {
                        if (pg.PageName !== "") {
                            return (<button key={i}
                                style={pageNo === pg.PageNo ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                className="dsh_pg"
                                onClick={_ => { setPageNo(pg.PageNo) }}>
                                {pg.PageName}
                            </button>)
                        }
                    })}
                </div>

            </div>
            <div style={{ display: "flex", height: "1000px" }}>
                <div id="livedashboard" style={{ marginLeft: "20px" }}>
                    {(dashboardFull.visuals.length + dashboardFull.kpistrings.length) > 0 ?
                        <div>
                            {dashlayoutMemo}
                            {kpilayoutMemo}
                        </div>
                        : <div style={{ margin: "20px", fontSize: "20px" }}>
                            <div>No Models assigned to this Dashboard!</div>
                            Add them <Link to="/dashboard/managegraph" >here</Link>
                        </div>}
                </div>
                <div id="dashsidebar" style={sidebarStyle} >
                    <div style={{ border: "10px solid black", height: "100%", width: "0px" }} onClick={_ => { OpenSidebar() }}></div>
                    <div id="dashsidebar_main">
                        <div className="ndt_title4" style={{ marginBottom: "5px" }}>Sidebar</div>
                        <button style={{ width: '250px', height: "33px", borderRadius: "10px", padding: '3px', marginBottom: "5px" }} onClick={_ => { CloseSidebar() }}>Close</button>
                        <div style={{ margin: '10px' }}>
                            <div>Filter</div>
                            <button className="dv_sidebtn" onClick={_ => { ClearFilters() }}>Clear All</button>
                            {filtersMemo}
                        </div>
                        <div style={{ margin: '10px' }}>
                            <div> Factor Analysis All KPI</div>

                            <button className="dv_sidebtn" onClick={_ => { ClickRunFactorAll() }}>Run</button>
                            <div id="dv_divfactorall"></div>
                        </div>
                        <div>
                            {selValueMemo}
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={dashstate.isexportvisible}>
                <div className="ndt_modal">
                    <div>
                        Export Queries to excel?
                        <button className="ndt_btn1" onClick={_ => { FileExport(false) }}>Export Data</button>
                        <button className="ndt_btn1" onClick={_ => { FileExport(true) }}>Export Full</button>

                        <button className="closemodalbtn" onClick={_ => setDashState({ ...dashstate, isexportvisible: false })}>Close</button>
                    </div>
                </div>
            </Modal>
            <Modal show={dashstate.isoptionsvisible}>
                <div className="ndt_modal">
                    <button className="ndt_btn1" onClick={_ => { setDashState({ ...dashstate, isexportvisible: true }) }}>Export</button>
                    <button className="ndt_btn1" onClick={_ => { QueryReload() }}>Reload</button>
                    <button className="ndt_btn1" onClick={_ => { Click_Compare() }}>Compare vs Previous</button>
                    <div id="dv_optdiv"></div>
                    <button className="closemodalbtn" onClick={_ => setDashState({ ...dashstate, isoptionsvisible: false })}>Close</button>

                </div>
            </Modal>
            {/*info */}
            <Modal show={dashstate.isinfovisible}>
                <div className="ndt_modal">
                    <QueryInfoData />
                    <button className="closemodalbtn" onClick={_ => setDashState({ ...dashstate, isinfovisible: false })}>Close</button>

                </div>
            </Modal>

            <Modal show={dashstate.isinspectvisible} dialogClassName="dv_modalwide">
                <div className="ndt_modal">

                    {selBasicRow ?
                        <div>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginRight: "10px" }}>Variable: {DateString(selBasicRow.Variable)}</div>
                                <div style={{ marginRight: "10px" }}>Grouping: {DateString(selBasicRow.Grouping)}</div>
                                <div style={{ marginRight: "10px" }}>Value: {selBasicRow.Value}</div>
                                <div style={{ marginRight: "10px" }}>Date: {DateString(GetObjectField(selBasicRow.QueryDate, runDates, "DateKey", "AsofDate"))}</div>

                            </div>
                            <div style={{ display: "flex" }}>
                                <button className="ndt_btn1" style={dashstate.inspectType === "Value History" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => setDashState({ ...dashstate, inspectType: "Value History" })}>Value History</button>
                                <button className="ndt_btn1" style={dashstate.inspectType === "Forecast" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => setDashState({ ...dashstate, inspectType: "Forecast" })}>Forecast</button>
                                <button className="ndt_btn1" style={dashstate.inspectType === "Factor Analysis" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => setDashState({ ...dashstate, inspectType: "Factor Analysis" })}>Factor Analysis</button>
                            </div>
                            <div id="dvchartvalues" style={{ height: '500px', padding: "10px" }}>
                                {(() => {
                                    switch (dashstate.inspectType) {
                                        case "Value History": return <div ></div>;
                                        case "Forecast": return <div id="divtbl" style={{ height: "300px" }}>Forecast</div>;
                                        case "Factor Analysis": return <div id="dvdivtbl" style={{ height: "300px" }}>
                                            <div>Factor Analysis</div>
                                            <div style={{ marginTop: '50px' }}>{inspDataNote}</div>
                                        </div>;
                                        default: return <div></div>;
                                    }
                                })()}
                            </div>
                        </div>
                        : <div>No data selected!</div>}
                    <button className="closemodalbtn" onClick={_ => { setDashState({ ...dashstate, isinspectvisible: false, inspectType: '' }); setInspDataNote("") }}>Close</button>
                </div>
            </Modal>

        </div>
            : <div> {loadingMsg}</div>}
    </div>)
};

export default DashboardView;



const GetQueryDatabyID = (qryvisuals: QueryVisuals, queries: QueryModel[], rundatekey: string, dataID: number) => {
    let foundrow: BasicQuery = BasicQueryInit[0]
    let qry: QueryModel = GetObjectfromArray(queries, qryvisuals.ModelName, "ModelKey")

    qry.rundates.forEach((rd: QueryRunDate) => {
        if (rd.DateKey === rundatekey) {
            rd.basicquery.forEach((rw: BasicQuery) => {
                if (rw.id === dataID) {
                    foundrow = rw
                }
            })
        }
    })
    return (foundrow)
}
