import { useEffect, useState, useContext } from 'react';
import './css/CustomTable.css'
import { Modal } from 'react-bootstrap';
import { MouseEvent } from "react";
import IsInVector from "../IsInVector";
import EnumerateSelectedRow from "./EnumerateSelectedRow";
import { FieldMap, Key_Name } from "../../type";
import GetFieldName from '../GetFieldName';
import { StartupContext } from '../../App';
import DateString from '../Date Functions/DateString';
import CheckIsDate from '../Date Functions/CheckIsDate';


const CustomTable = (props: any) => {
    //ref: tablemgr, fieldsmanager, datatable, datamanager, employeedatatable, querymodeldataeditor

    let data: any[] = props.data;
    let keycol: string = props.keycol;
    let colsHide: string[] = props.colsHide;
    let optionalchoices: any[] = props.optionalchoices; //for dropdowns when editing instead of text
    let subModelObj: any[] = props.subModelObj;
    let adddel_fn: any = props.adddel_fn;
    let delete_fn: any = props.delete_fn;
    let editable: any = props.editable;
    let changestate: any = props.changestate;
    let backendstring: string = props.backendstring;
    let classname: string = props.classname;
    let mainDiv: string = props.mainDiv;
    let rowcount: number = props.rowcount;
    let modelcategory: string = props.modelcategory;

    let fields: FieldMap[] = props.fields.map((fld: FieldMap) => {
        return { ...fld, HeaderName: fld.AttrName + "_" + fld.ModelCategory }
    });

    let hardcodedfields: Key_Name[] = props.hardcodedfields;
    let [width, height] = props.shape;

    let editCol = true;
    if (!editable) {
        editCol = false;
    }
    let { config, schema } = useContext(StartupContext)

    const [pageNo, setPageNo] = useState<number>(1)
    const [maxPg, setMaxPg] = useState<number>(1)

    let [isModalVisible, setIsModalVisible] = useState(false);
    let [colsShow, setColsShow] = useState<Key_Name[]>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);

    useEffect(() => {
        let maxno: number = Math.round(data.length / rowcount + .5)
        setMaxPg(maxno)

    }, [data])

    useEffect(() => {
        changestate(1)
        //console.log("changestate")
    }, [])

    useEffect(() => {
        //will run once for each row because of dependency
        if (data.length > 0) {
            let cols: Key_Name[] = []
            let headerdata: string[] = Object.keys(data[0])
            headerdata.map((key: string, i: number) => {
                if (modelcategory === "FieldMapping") {
                    if (!IsInVector(colsHide, key)) {
                        cols.push({ Key: key, Name: key })
                    }
                } else {
                    if (!IsInVector(colsHide, key) && GetFieldName(fields, key, "AttrName", "FieldName") !== "") {
                        cols.push({ Key: key, Name: GetFieldName(fields, key, "AttrName", "FieldName") })
                    }
                }
            })
            hardcodedfields.forEach((kn: Key_Name) => {
                cols.push(kn)
            })
            setColsShow(cols)
        }

    }, [data])

    const ChangePage = (direction: Boolean) => {
        let nextstep = pageNo + ((+direction - .5) * 2) * 1
        if (nextstep <= maxPg && nextstep > 0) {
            setPageNo(nextstep)
        }
    };

    const OpenEditor = (e: MouseEvent<HTMLDivElement>) => {
        let idno = e.currentTarget.attributes[1].value
        setSelectedRow(data[+idno])
        setIsModalVisible(true)
    };

    const isDisplayed = (i: number) => {
        if ((i >= rowcount * (pageNo - 1)) && (i < rowcount * (pageNo))) {
            return ("table-row");
        } else {
            return ("none");
        }
    }

    return (<div id={mainDiv}>

        <div className="paginationdiv">
            {pageNo > 1 ? <div className='paginatebtn' onClick={_ => ChangePage(false)}> {'<'} Prev  </div> : <div></div>}
            Current Page: {pageNo} / {maxPg}
            {pageNo < maxPg ? <div className='paginatebtn' onClick={_ => ChangePage(true)}>  Next {'>'}</div> : <div></div>}
        </div>

        <div className={classname} >
            {colsShow.length > 0 ?
                <div className="table-container" style={{ overflow: "scroll", width: width, maxWidth: width, maxHeight: height }}>
                    <table className="custtable"  >

                        <thead id="dguh">
                            <tr>
                                {editCol && <th>Edit</th>}
                                {colsShow.map((col: Key_Name, i: number) => {
                                    return (
                                        <th key={i} className="custtbl_headercell">
                                            {col.Name}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>

                        <tbody>

                            {data.map((rw: any, i: number) => {
                                return (<tr key={"a" + i} id={"a" + i} style={{ display: isDisplayed(i), height: '40px' }}>
                                    {editCol && <td className="custtbl_cell celledit" id={String(i)} onClick={(e: MouseEvent<HTMLDivElement>) => OpenEditor(e)}> ( )</td>}
                                    {colsShow.map((col: Key_Name, i2: number) => {
                                        if (CheckIsDate(rw[col.Key])) {
                                            return (
                                                <td key={i + "_" + i2} className="custtbl_cell" >
                                                    {DateString(rw[col.Key], "%Y-%m-%d")}
                                                </td>
                                            )
                                        } else if (typeof rw[col.Key] === 'object' && rw[col.Key] !== null) {
                                            return (
                                                <td key={i + "_" + i2} className="custtbl_cell" >
                                                    Arry [{String(rw[col.Key].length)}]
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td key={i + "_" + i2} className="custtbl_cell" >
                                                    {String(rw[col.Key])}
                                                </td>
                                            )
                                        }
                                    })}
                                </tr>)
                            })}

                        </tbody>

                    </table>
                </div>
                : <div>No Data</div>}
            <Modal show={isModalVisible} >
                <div id="modal_enumName">Edit Data</div>
                <EnumerateSelectedRow
                    data={selectedRow}
                    config={config}
                    colsHide={colsHide}
                    keycol={keycol}
                    optionalchoices={optionalchoices}
                    subModelObj={subModelObj}
                    adddel_fn={adddel_fn}
                    delete_fn={delete_fn}
                    editable={editable}
                    backendstring={backendstring}
                    modelcategory={modelcategory}
                    schema={schema}
                />
                <button onClick={_ => { setIsModalVisible(false) }}>Close</button>
            </Modal>
        </div>
    </div>
    )

};
export default CustomTable;