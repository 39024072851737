
import { ChartSeries, QueryModel, QueryVisuals, queryvisualstate } from "../type";
import CreateSVG from "./newfunctions/CreateSVG";
import CreateAxes from "./newfunctions/CreateAxes";
import CreateBars from "./newfunctions/CreateBars";
import UniqueArrayValues from "../functions/UniqueArrayValues";
import CreateLines from "./newfunctions/CreateLines";
import CreateLabels from "./newfunctions/CreateLabels";
import SVGMouseFunctions from "./SVGMouseFunctions";
import CreateBarsHorizontal from "./newfunctions/CreateBarsHorizontal";
import FilterData from "../functions/FilterData";
//import CreateTitle from "./newfunctions/CreateTitle";
import AddBarAttrs from "./newfunctions/AddBarAttrs";

interface valformat {
    format: string; //blank or pct
    rounding: number;
}

const ChartPrimary = (querymodel: QueryModel, qryvis: QueryVisuals, simpledata: any[], detaildata: any[], svgid: string,
    metrickeys: string[], formats: valformat[], colors: any[], colorScale: any, secColorScale: any, size: any[], vis_states: queryvisualstate[], setVis_State: any | null, stateIdx: number,
    context: any) => {
    //console.log(querymodel)
    //console.log(colors)
    //console.log(qryvis)
    //delete previous


    let xfield = "Variable"
    let yfield = "ValueCalc"
    let groupfield: any = "Grouping"
    let selIdx = -1
    let selMetricName = ''
    let labeltype = qryvis.LabelType

    if (vis_states.length === 1) {
        selMetricName = vis_states[0].selectedmetricname
    } else {
        selMetricName = vis_states[stateIdx].selectedmetricname
        selIdx = 0
    }

    let groups = UniqueArrayValues(simpledata, groupfield)
    let xgroups = UniqueArrayValues(simpledata, xfield)

    if (!querymodel.GroupFieldName) { //&& querymodel.Metrics.length !== 1
        groupfield = null
    }

    querymodel.Metrics.forEach((metr: string, i: number) => {
        if (metr === vis_states[stateIdx].selectedmetric) {
            selIdx = i
        }
    })

    let datasets: any[][] = querymodel.Metrics.map((_: string, i: number) => {
        return FilterData(simpledata, "MetricNo", String(i))
    })
    let detaildatasets: any[][] = querymodel.Metrics.map((_: string, i: number) => {
        return FilterData(detaildata, "MetricNo", String(i))
    })


    let barscount = 0
    let classlist: string[] = []
    let benchmarklist: string[] = []
    let graphslist = qryvis.chartseries.map((cs: ChartSeries) => {
        if ((cs.ChartType === "Bar" || cs.ChartType === "BarHorizontal") && !cs.IsHidden) {
            barscount += 1
        }
        benchmarklist.push(cs.BenchmarkMode)

        classlist.push(cs.MetricName + "_series")
        return (cs.ChartType.replace("Horizontal", ""))
    })
    if (barscount === 0) { barscount = 1 }

    let direction = "vertical"
    if (qryvis.ChartType === "BarHorizontal") { direction = "horizontal" }

    //step 1: create svg
    var margin = { top: 20, right: 10, bottom: 30, left: 55 },
        width = size[0] - margin.left - margin.right,
        height = size[1] - margin.top - margin.bottom;


    let svg: any = CreateSVG(svgid, margin, width, height)

    //add title
    svg.append("text")
        .text(qryvis.ChartTitle)
        .attr("x", (width / 2))
        .attr("font-weight", "bold")
        .attr("font-size", "24px")
        .attr("text-anchor", "middle")


    //step 2: create x, y axis, colorscale
    let [, xaxisfn, yaxisfns] = CreateAxes(svg, datasets, qryvis.ChartType, graphslist, xfield, yfield, groupfield,
        width, height, margin, selIdx, selMetricName, direction)

    //attr fn - highlight
    function CreateAttrs(vis_state: queryvisualstate, n: number) {
        let op = 1
        if (vis_state.highlight === "series") {
            //console.log(vis_state)
        }
        if (vis_state.highlight === "series" && vis_state.selectedmetric !== "" && vis_state.selectedmetric !== querymodel.Metrics[n]) {
            op = .2
        }

        return { opacity: op, reload: vis_state.reload, labelcolor: qryvis.LabelColor }
    }

    if (qryvis.chartseries.length > 0) {
        let bar_n = -1; //start w 0
        //write bars first, lines second

        [0, 1].forEach((g) => { //write lines last
            datasets.forEach((df: any[], n: number) => {

                let attributes = CreateAttrs(vis_states[stateIdx], n)
                let elems: any | null = null;

                if ((g === 0 && graphslist[n] !== "Line") || (g === 1 && graphslist[n] === "Line")) {
                    if (!qryvis.chartseries[n].IsHidden) {
                        let rawmetrno = qryvis.chartseries[n].MetricOrder
                        if (graphslist[n] !== "Line") { bar_n += 1 }

                        if (graphslist[n] === "Bar") {
                            if (direction === "horizontal") {
                                elems = CreateBarsHorizontal(svg, classlist[n], df, detaildatasets[n], xfield, yfield, groups, xgroups,
                                    groupfield, xaxisfn, yaxisfns[n], false,
                                    colorScale, secColorScale, qryvis.ColorGradientType, width,
                                    barscount, bar_n, rawmetrno, attributes, vis_states, setVis_State, stateIdx)
                            } else {
                                elems = CreateBars(svg, classlist[n], df, detaildatasets[n], xfield, yfield, groups, xgroups,
                                    groupfield, xaxisfn, yaxisfns[n], false,
                                    colorScale, secColorScale, qryvis.ColorGradientType, height,
                                    barscount, bar_n, rawmetrno, attributes, vis_states, setVis_State, stateIdx)
                            }
                        } else if (graphslist[n] === "Line") {
                            //incorporate hidden series 
                            elems = CreateLines(svg, classlist[n], df, detaildatasets[n],
                                xfield, yfield, groups, xgroups, false,
                                groupfield, xaxisfn, yaxisfns[n], colorScale, secColorScale, qryvis.ColorGradientType,
                                height, barscount, rawmetrno, rawmetrno, attributes, vis_states, setVis_State, stateIdx)
                        }


                        if (elems) {
                            AddBarAttrs(elems, graphslist[n], metrickeys[rawmetrno], barscount, xaxisfn, groups, groupfield, yfield, colorScale, qryvis.ColorGradientType, attributes, vis_states, stateIdx)
                            //only place state is being updated right now
                            SVGMouseFunctions(elems, vis_states, setVis_State, stateIdx, graphslist[n], df, detaildatasets[n], context)
                        }
                        if (qryvis.LabelsOnOff !== "Off") {
                            CreateLabels(svg, graphslist[n], df, xaxisfn, yaxisfns[n], xfield, yfield, xgroups, groupfield,
                                barscount, bar_n, formats, attributes, direction, qryvis.LabelsOnOff, labeltype)
                        }
                        if (benchmarklist[n] !== "None") {
                            elems = CreateLines(svg, classlist[n], df, detaildatasets[n],
                                xfield, yfield, groups, xgroups, true,
                                groupfield, xaxisfn, yaxisfns[n], colorScale, secColorScale, qryvis.ColorGradientType,
                                height, barscount, rawmetrno, rawmetrno, attributes, vis_states, setVis_State, stateIdx)
                        }
                    }
                }
            })
        })
    }

};
export default ChartPrimary;

