import axios from "axios";
import { QueryModelInit } from "../../InitTypes";
import { QueryModel } from "../../type";

const QueryModelImport = async (modelkey: string, schemakey: string, config: any, dispatcher: any, store: boolean) => {

    let querydata: QueryModel = QueryModelInit[0]

    try {
        let res = await axios.post<QueryModel>(`${process.env.REACT_APP_API_URL}/api/query/singlemodelview`,
            { 'schemakey': schemakey, 'modelkey': modelkey }, config);

        querydata = res.data
        console.log(querydata);
    } catch (error) {
        console.log(error);
    }
    return (querydata)
};
export default QueryModelImport;