import * as d3 from 'd3';
import DateRemoveTimestamp from '../../functions/Date Functions/DateRemoveTimestamp';
import PivotArray from '../../functions/PivotArray';
import DateString from '../../functions/Date Functions/DateString';

const CreateAxes = (svg, datasets, charttype, graphslist, xfield, yfield, groupfield, width, height, margin, selIdx, selMetricName, direction="vertical") =>{

    let usewidth = width;
    let useheight = height;
    if (direction==="horizontal"){
        usewidth = height
        useheight = width
    } 
    
    var x = d3.scaleBand()
                .range([0, usewidth])
                .padding(0.1);

    //get all possible values for datasets, cumulative (4.21.24)
    let xdomain = []
    datasets.forEach((ds)=>{
        ds.forEach((d)=>{
            let val = DateRemoveTimestamp(d[xfield])
            if (xdomain.indexOf(val)===-1){
                xdomain.push(val)
            }
        })
    })
    let xdomainstr = xdomain.map((str1)=>{return DateString(str1,"%Y-%m-%d")})
    xdomainstr = xdomainstr.sort()

    // x.domain(datasets[0].map(function(d) { 
    //     return DateString(DateRemoveTimestamp(d[xfield])); }))
    x.domain(xdomainstr)

    function createY(df,i){
        let arr = PivotArray(df, xfield,"Grouping",yfield)

        if (graphslist[i]!=="Line" || charttype==="Bar"){
            arr = PivotArray(df, xfield,"",yfield)
        } 
        var ydims = [useheight, 0]
        if (direction==="horizontal"){ ydims = [0, useheight]}
        
        var y = d3.scaleLinear().range(ydims)
        let dev = d3.deviation(df,function(d) {return +d[yfield]; })

        if (!dev){dev=1} //if error (single value)

        let mina=0
        if (!groupfield) {
            mina = d3.max([0,d3.min(df,function(d) {  return +d[yfield]; }) - dev ]); 
            mina = Math.max(0,Math.round(mina-.5)); //round down to nearest whole
        }

        let ymax = d3.max(arr, function(d) { 
            if (+d['Sum']===0){
                return 1
            } else {
                
                //console.log((+d["Sum"] + .2 * dev)*1.05)
                return (+d["Sum"] + .2 * dev)*1.05; 
            }
        })
        //move lines down
        if (graphslist[i]==="Line" && charttype==="Combo"){
            ymax = ymax + (ymax-mina)*1.25   
        }

        y.domain([mina, ymax]);

        return(y)

    };

    let yaxes = datasets.map((df,i)=>{
        return createY(df,i)
    })


    function SwitchAxis(scale,dim){
        if ((dim==="x" && direction==="vertical") || (dim==="y" &&direction==="horizontal")){
           return d3.axisBottom(scale)
        } else {
            return d3.axisLeft(scale)
        }
    }

    // add the x Axis
    let gy = svg.append("g")
    let gx = svg.append("g")
    function SwitchSVG(dim){
        if ((dim==="x" && direction==="vertical") || (dim==="y" &&direction==="horizontal")){
            return gy
         } else {
            return gx
         } 
    }

    let itmX = SwitchSVG('x')
    let itmY = SwitchSVG('y')


    let xaxis = itmX
            .attr("color","black")
            .call(SwitchAxis(x,'x'))

            
    if (direction==="vertical"){xaxis.attr("transform", "translate(0," + useheight + ")")}

    
    // xaxis.append('text')
    //     .attr("id","xaxislabel")
    //     .attr('transform',(d)=> {
    //         if (direction==="vertical"){
    //             return 'translate('+ (usewidth/2) + "," + (useheight+margin.top + 20) + ")"
    //         } else {
    //             return "translate(0,0)"
    //         }
    //     })
    //     .style("text-anchor","middle")
    //     .attr("color","black")
        //.text(d=>{console.log(d); return String(d).substring(0,10)})
        //.call(Textwrap,width/xgroups.length-5,.2)

    yaxes.forEach((y,i)=>{
        if (i===selIdx || yaxes.length===1){
            // add the y Axis
            itmY.append("g")
                .attr("class","yaxis")
                .attr("color","black")
                .call(SwitchAxis(y,"y"));
            
                
            itmY.append('text')
                .attr("id", 'yaxislabel')
                .attr("class","y label")
                .attr("transform","rotate(-90)")
                .attr("y",0-margin.left - 4)
                .attr("x",0-(useheight/2))
                .attr("dy", '.75em')
                .style("text-anchor","middle")
                .attr("color","black")
                .text(selMetricName)
        }
    })


    return([svg,x,yaxes])
};
export default CreateAxes