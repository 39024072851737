import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./css/logout.css"
import "./css/logout_cascade.css"
import LogoutAuth from './functions/LogoutAuth';
import { useContext } from 'react';
import { StartupContext } from '../../../App';


const Logout = () => {
	const { config, schema, dispatcher, user, paths } = useContext(StartupContext)

	let history = useNavigate();

	//react-redux functions
	//const user = useSelector((state: State) => state.user );
	const [msg, setMsg] = useState("Click to Log Out")


	const logoutfn = () => {
		LogoutAuth(dispatcher, schema, user, config, "You have been successfully logged out!", setMsg);

		//history('/') //causing an error
		//window.location.reload()

	}

	const redirecthome = () => {
		history("/home")
	}

	return (
		<div>
			<div className="lo_cascadecontainer">
				<div className="lo_frame"></div>
				<div className="lo_frame"></div>
				<div className="lo_frame"></div>
			</div>
			<div className="logoutContainer" >

				<div id="logoutbox" >
					<div id="logintitle"> Log Out </div>

					<div id="formbox" >
						{user.id !== "-1" ?
							<button className="logout-button" onClick={_ => logoutfn()}>Log out</button>
							: <button className="logout-button" onClick={_ => redirecthome()} style={{ backgroundColor: "rgb(40,70,250)" }}>Return to Home Page</button>}
					</div>
					<div>{msg}</div>
				</div>

			</div>
		</div >
	)

};

export default Logout;