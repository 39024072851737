import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserAccount } from "../type";
import { UserAccountInit } from '../InitTypes';

/////////
const userStateInit: User = {
	id: "-1",
	email: '',
	password: '',
	authtoken: '',
	refreshtoken: '',
	isAuthenticated: false,
	level: '',
	defaultschema: ''
};


export const userSlice = createSlice({
	name: 'user_action',
	initialState: userStateInit,
	reducers: {
		login: (state, { payload }: PayloadAction<{
			id: string, email: string, password: string, authtoken: string,
			refreshtoken: string, isAuthenticated: boolean, level: string, defaultschema: string
		}>) => {
			//uses primitive values (null, string, int, etc.), so can't mutate state directly
			//just return completely new state
			return {
				id: payload.id,
				email: payload.email,
				password: payload.password,
				authtoken: payload.authtoken,
				refreshtoken: payload.refreshtoken,
				isAuthenticated: payload.isAuthenticated,
				level: payload.level,
				defaultschema: payload.defaultschema,
			}
		},
		logout: (state, _: PayloadAction<{}>) => {
			//uses primitive values (null, string, int, etc.), so can't mutate state directly
			//just return completely new state
			return {
				id: "-1",
				email: '',
				password: '',
				authtoken: '',
				refreshtoken: '',
				isAuthenticated: false,
				level: '',
				defaultschema: ''
			}
		}
	}
});

export const allUsersSlice = createSlice({
	name: 'usersall_action',
	initialState: UserAccountInit,
	reducers: {
		usersalldata: {
			reducer: (state, { payload }: PayloadAction<UserAccount>) => {
				state.push(payload);
			},
			prepare: ({ id, email, group, level, defaultschema }: UserAccount) => ({
				payload: {
					id,
					email,
					group,
					level,
					defaultschema
				}
			})
		},
		usersall_delete: (state, _: PayloadAction<{}>) => {
			state.splice(0, state.length);
		},
	}
});


//---manually export action creators
export const {
	login: loginActionCreator,
	logout: logoutActionCreator
} = userSlice.actions;

export const {
	usersalldata: usersAllActionCreator,
	usersall_delete: usersAllDeleteActionCreator
} = allUsersSlice.actions;