
import { KPI, BasicQuery, QueryRunDate, QueryModel } from "../../type";
import { BasicQueryInit } from "../../InitTypes";



const IdentifyKPIElement = (classname: string, kpi: KPI, rundatekey: string, query: QueryModel) => {
    //ref: querymodelviewinspector, datasetwalkthrough

    let ekey = kpi.EncodedKey
    let correctRow: BasicQuery = BasicQueryInit[0]
    query.rundates.forEach((rd: QueryRunDate) => {
        if (rd.DateKey === rundatekey) {

            rd.basicquery.forEach((bq: BasicQuery) => {
                if (bq.EncodedKey === ekey) {
                    correctRow = bq
                }
            })
        }
    })

    let e_coll = document.getElementsByClassName(classname)

    let foundelem: any | null = null

    Array.from(e_coll).forEach((e: Element) => {
        let lmnt: any = document.getElementById(e.id)
        if (lmnt?.attributes['data-id'].value === correctRow['EncodedKey'] &&
            lmnt?.attributes['data-metrickey'].value === kpi.MetricKey) {
            foundelem = e
        }
    })
    return foundelem;
};

export default IdentifyKPIElement;