import axios from "axios";
import { schemadeleteActionCreator } from "../../reducers/schema_actions";
import { empstatedeleteActionCreator } from "../../reducers/employee_actions";
import { User } from "../../type";

const SchemaDelete = async (schemakey: string, user: User, config: any, dispatcher: any, elementstring: string) => {

    let dataArray = new FormData();
    dataArray.append('schemakey', schemakey);

    let respdata = ""
    let elem = document.getElementById(elementstring);

    if (elem) { elem.innerHTML = "Loading..." };

    try {
        let schema_resp = await axios.post<any>(
            `${process.env.REACT_APP_API_URL}/api/schema/schemadelete`,
            dataArray, config,
        )
        dispatcher(schemadeleteActionCreator([]))
        dispatcher(empstatedeleteActionCreator([]))

        respdata = schema_resp.data //should only be one


    } catch (error) {
        console.log(error)
    }

    if (elem) { elem.innerHTML = String(respdata) }

    return respdata
};
export default SchemaDelete;