
const GetObjectfromArray = (arry: any[], keystring: string, onfield: string) => {

    let returnobj: any = []
    arry.forEach((el: any) => {
        if (el[onfield] === keystring) {
            returnobj = el
        }
    })
    return returnobj

};
export default GetObjectfromArray;