import DateString from "../../../../functions/Date Functions/DateString";
import { QueryModel, QueryRunDate, QueryVisuals } from "../../../../type";

const RunDatesDropdown = (props: any) => {

    let qrymdl: QueryModel = props.querymodel
    let change: any = props.change;

    return (<select onChange={change}>
        <option value={"Primary"}>Use Primary</option>
        {qrymdl.rundates.map((rd: QueryRunDate) => {
            return (<option value={rd.DateKey} key={rd.DateKey}>{DateString(rd.AsofDate)}</option>)
        })}
    </select>)
};
export default RunDatesDropdown;