import IsInVector from "../IsInVector";
import IsInObject from "../Object Functions/IsInObject";

const ArraySliceValue = (arry: any[], newvalue: any, onfield: string = "", addonly = false): any[] => {
    //ref: LeaderViewManager, GraphCreator


    let newarry = arry
    let isnewval = true;
    let idx: any = -1;

    if (onfield === "") {
        if (IsInVector(arry, newvalue)) {
            isnewval = false
            idx = IsInVector(arry, newvalue, true)
        }
    } else {
        if (IsInObject(arry, newvalue[onfield], onfield)) {
            isnewval = false
            idx = IsInObject(arry, newvalue, onfield, true)
        }
    }

    if (isnewval) {
        newarry.push(newvalue)
    } else if (!addonly) {
        newarry.splice(idx, 1)
    }

    return newarry
};
export default ArraySliceValue