
import AxiosUpload from '../AxiosUpload';
import { User } from '../../type';

const Schema_Upload = (schemakey: string, schemaname: string, fromschema: string, color: string, copyvalues: any, isproduction: boolean, user: User, config: any, elementstring: string) => {

    let elem = document.getElementById(elementstring)
    if (elem) { elem.innerHTML = "Uploading..." }

    console.log('clicked data upload')

    let dataArray = new FormData();

    dataArray.append("schemakey", schemakey);
    dataArray.append("schemaname", schemaname);
    dataArray.append("fromschema", fromschema);

    dataArray.append("color", color);
    dataArray.append("userid", user.id);
    dataArray.append("isproduction", String(isproduction));
    dataArray.append("copyfields", String(copyvalues.fields));
    dataArray.append("copytables", String(copyvalues.tables));
    dataArray.append("copymetrics", String(copyvalues.metrics));


    //axios call
    AxiosUpload(dataArray, "api/schema/schemapost", config, elem, "")
    return ("Success")
};
export default Schema_Upload;