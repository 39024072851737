import { FieldMap, Header } from "../../../../type"


interface samplefld {
    Value: string;
}

//returns [fieldkey, fieldname, (sample) matched name, iskeyfield, isnewfield, blank (for 'if updated')]
const MatchFieldLists = (samplefields: samplefld[], fields: FieldMap[]) => {
    let foundkey: any
    let returnarr: any[] = []
    let match: any

    let y = fields.map((f: FieldMap) => {
        foundkey = false;
        let headers = f.Headers;
        samplefields.forEach(s => {
            if (f.HeaderName === s.Value && f.HeaderName !== "nan") {
                foundkey = true
                match = [f.Key, f.FieldName, s.Value, Field_IsKey(f), Field_BoolString(f.IsNewField), ""]
            } else {
                headers.forEach((head: Header) => {
                    if (head.HeaderName === s.Value) {
                        foundkey = true
                        match = [f.Key, f.FieldName, s.Value, Field_IsKey(f), Field_BoolString(f.IsNewField), '']
                    }
                })
            }
        })
        if (foundkey) {
            returnarr.push(match)
        } else {
            if (f.HeaderName !== "nan") { returnarr.push([f.Key, f.FieldName, "", Field_IsKey(f), Field_BoolString(f.IsNewField), ""]) }
        }

    });

    let obj = Object.assign({}, returnarr)
    return (obj)
};

export default MatchFieldLists;


function Field_IsKey(fld: FieldMap) {
    if (fld.FieldCategory === "Primary") {
        return "True"
    } else {
        return "False"
    }
}

function Field_BoolString(boolval: Boolean) {
    if (boolval) {
        return "True"
    } else {
        return "False"
    }
}