import { useState, useEffect, ChangeEvent, useMemo, useContext } from "react";
import { FieldMap, GroupDateRule } from "../../../../type";
import { GroupDateRuleInit } from "../../../../InitTypes";
import { Modal } from "react-bootstrap";
import { StartupContext } from "../../../../App";
import DatePicker from "react-date-picker";
import DateString from "../../../../functions/Date Functions/DateString";
import GroupFieldDateUpload from "../../../../data/Fields/GroupFieldDate_Upload";

const GroupSetsDateMgr = (props: any) => {

    let groupfield: FieldMap = props.groupfield
    let groupdaterulesInit: GroupDateRule[] = props.groupdaterulesInit


    let { config, schema, user, dispatcher, paths, clickstyle } = useContext(StartupContext)

    let [gsdaterules, setGsDateRules] = useState<GroupDateRule[]>(groupdaterulesInit)
    let [seldaterule, setSeldateRule] = useState<GroupDateRule>(GroupDateRuleInit[0])

    let [params, setParams] = useState<any>({
        refresh: 0, fldrefresh: 0,
        issavevisible: false, ruleequal: "",
        ruletype: "range", rulewarning: "", setrule: false, isdeletevisible: false
    });
    let keylowerbound = new Date("1900-01-01")
    let keyupperbound = new Date("2300-01-01")


    useEffect(() => {
        setParams({ ...params, rulewarning: "", setrule: false, ruletype: "" })
    }, [seldaterule.id])

    useEffect(() => {
        setSeldateRule({ ...GroupDateRuleInit[0], id: seldaterule.id })
    }, [params.ruletype])

    useEffect(() => {
        setGsDateRules(groupdaterulesInit);
    }, [groupdaterulesInit])

    // console.log(params)
    // console.log(seldaterule)
    //console.log(gsdaterules)

    //daterule logic - check, then set rule
    useEffect(() => {
        if (params.checkrule) {
            if (!CheckRuleOverlap(gsdaterules, seldaterule)) {

                if (seldaterule.GreaterThanThresh > seldaterule.LessThanThresh) {
                    setParams({ ...params, rulewarning: "improper bounds", setrule: false, checkrule: false })
                } else {
                    if (seldaterule.LessThanThresh === new Date() || seldaterule.GreaterThanThresh === new Date()) {
                        setParams({ ...params, rulewarning: "missing lte/gte values", setrule: false, checkrule: false })
                    } else {
                        if (seldaterule.EqualTo === new Date()) {
                            setParams({ ...params, rulewarning: "missing eq value", setrule: false, checkrule: false })
                        } else {
                            setParams({ ...params, rulewarning: "", setrule: true, checkrule: false })

                        }
                    }
                }
            }
            else {
                setParams({ ...params, rulewarning: "Overlaps with existing rule", setrule: false, checkrule: false })
            }
        }
    }, [params.checkrule])


    useEffect(() => {
        if (params.setrule) {
            //using groupset name as a placeholder for completed rules
            gsdaterules[seldaterule.id] = { ...seldaterule, GroupSetName: "Set" }
            setGsDateRules(gsdaterules)
            setParams({ ...params, setrule: false })
        }
    }, [params.setrule])

    const ClickSave = () => {
        //GenericBackendCall(groupfield.GroupFieldKey, schema, paths.groupsets.groupsetpost, user, config, gsdaterules, "")
        GroupFieldDateUpload(groupfield.Key, gsdaterules, schema, user, config, "gsdm_divsave")
    }
    const ClickDelete = () => {
        //GenericBackendCall(groupfield.GroupFieldKey, schema, paths.groupsets.groupsetpost, user, config, gsdaterules, "")
        //GroupFieldDateUpload(groupfield.Key, gsdaterules, schema, user, config, "gsdm_divsave")
        //GenericBackendCall("", schema, paths.fields.fielddelete, user, config, {}, "mgf_divdel")

    }

    const ClickSetRule = () => {

        //configure based on rule type
        let gt = seldaterule.GreaterThanThresh
        let lt = seldaterule.LessThanThresh
        let eq = seldaterule.EqualTo
        let banding = seldaterule.BandingName
        if (params.ruletype === "orless") {
            gt = keylowerbound
        } else if (params.ruletype === "orgreater") {
            lt = keyupperbound
        } else if (params.ruletype === "single") {
            gt = eq
            lt = eq
        }

        if (params.ruleequal === "lte") {
            eq = lt
        } else if (params.ruleequal === "gte") {
            eq = gt
        }
        if (banding === "") {
            banding = CheckValue(gt) + " to " + CheckValue(lt)
        }
        setSeldateRule({
            ...seldaterule,
            BandingName: banding, EqualTo: eq, GreaterThanThresh: gt, LessThanThresh: lt
        })
        setParams({ ...params, checkrule: true, rulewarning: "" })

    };

    const CheckRuleOverlap = (nrules: GroupDateRule[], selrule: GroupDateRule) => {
        let overlap: boolean = false
        nrules.forEach((rule: GroupDateRule) => {
            if (rule.id !== selrule.id && rule.GroupSetName !== "") {
                if (rule.GreaterThanThresh <= selrule.LessThanThresh && rule.LessThanThresh >= selrule.GreaterThanThresh) {
                    //rule should exist entirely below selrule
                    overlap = true
                } else if (rule.LessThanThresh >= selrule.GreaterThanThresh && rule.GreaterThanThresh <= selrule.LessThanThresh) {
                    //rule should exist entirely above selrule
                    overlap = true
                }
            }
        })
        return (overlap)
    };


    const AddGrpNRule = () => {
        gsdaterules.push({ ...GroupDateRuleInit[0], id: gsdaterules.length })
        setGsDateRules(gsdaterules)
        setParams({ ...params, refresh: params.refresh + 1 })
    };


    const CheckValue = (val: Date) => {
        if (val === keyupperbound) {
            return "Max"
        } else if (val === keylowerbound) {
            return "Min"
        } else {
            return DateString(val)
        }
    };


    const GroupdateRangeDiv = () => {
        return (
            <div className="ndt_innerbox" style={{ height: "470px", width: "40%" }}>
                <button className="ndt_btn1" onClick={_ => { AddGrpNRule() }}>Add Rule</button>
                <div id="gsnm_rulebox" className="ndt_subinner">
                    {gsdaterules.map((nrule: GroupDateRule, i: number) => {
                        let nstyle = clickstyle.itmUnselected
                        if (nrule.id === seldaterule.id) { nstyle = clickstyle.itmSelected }
                        return (<div key={i} style={nstyle} className="mgf_grpitm" onClick={_ => setSeldateRule(nrule)}>
                            <div>GT:{CheckValue(nrule.GreaterThanThresh)}</div>
                            <div>E:{CheckValue(nrule.EqualTo)}</div>
                            <div>LT:{CheckValue(nrule.LessThanThresh)}</div>
                            <div>Name (optional):{nrule.BandingName}</div>
                        </div>)
                    })}
                </div>
            </div>
        )
    }
    const FielddateRangeDiv = () => {
        return (<div className="ndt_innerbox" style={{ height: "450px", width: "30%" }}>
            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, ruletype: e.target.selectedOptions[0].attributes[0].value }) }}>
                <option value="">Select Rule Type</option>
                <option value="orless">Or Less Than</option>
                <option value="range">Range</option>
                <option value="orgreater">Or Greater</option>
                <option value="single">Single Value</option>
            </select>
            <div>
                {params.ruletype !== "orless" && params.ruletype !== "single" ?
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "300px" }}>Greater Than (Lower Boundary)</div>
                        <div style={{ width: '165px', backgroundColor: "white", color: "black" }}>
                            <DatePicker onChange={(e: any) => { setSeldateRule({ ...seldaterule, GreaterThanThresh: e }) }} value={seldaterule.GreaterThanThresh} />
                        </div>
                    </div> : <div>Or Less</div>
                }
            </div>
            <div>

                <div style={{ display: "flex" }}>
                    <div style={{ width: "300px" }}>Equal To</div>
                    {params.ruletype === "single" ?
                        <div style={{ width: '165px', backgroundColor: "white", color: "black" }}>
                            <DatePicker onChange={(e: any) => { setSeldateRule({ ...seldaterule, EqualTo: e }) }} value={seldaterule.EqualTo} />
                        </div>
                        : <div>
                            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, ruleequal: e.target.selectedOptions[0].attributes[0].value }) }}>
                                <option value="">Select:</option>
                                <option value="lte">Less Than or Equal</option>
                                <option value="gte">Greater Than or Equal</option>
                            </select>
                        </div>}
                </div>

            </div>
            <div>
                {params.ruletype !== "orgreater" && params.ruletype !== "single" ?
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "300px" }}>Less Than (Upper Boundary)</div>
                        <div style={{ width: '165px', backgroundColor: "white", color: "black" }}>
                            <DatePicker onChange={(e: any) => { setSeldateRule({ ...seldaterule, LessThanThresh: e }) }} value={seldaterule.LessThanThresh} />
                        </div>
                    </div> : <div>Or Greater</div>
                }
            </div>
            <div>
                <div style={{ width: "300px" }}>Banding Name</div>
                <input type="text" className="gsm_dateinput" value={seldaterule.BandingName} onChange={(e: ChangeEvent<HTMLInputElement>) => { setSeldateRule({ ...seldaterule, BandingName: e.target.value }) }} />
            </div>
            <button className="ndt_btn1" onClick={_ => { ClickSetRule() }}>Set Rule</button>
            <div>{params.rulewarning}</div>
        </div>)
    }

    let flddaterngMemo = useMemo(() => { return FielddateRangeDiv() }, [seldaterule, params.ruletype, params.ruleequal, params.checkrule, params.setrule, params.rulewarning])

    return (<div>
        <div style={{ display: "flex" }}>
            <GroupdateRangeDiv />
            {flddaterngMemo}
        </div>
        <button className="ndt_btn1" onClick={_ => { setParams({ ...params, issavevisible: true }) }}>
            Save Grouping</button>

        <Modal show={params.issavevisible}>
            <div>Do you want to overwrite?</div>
            <button onClick={_ => ClickSave()} className="ndt_btn1" style={{ width: "200px", margin: "15px" }}>Save</button>
            <div id="gsdm_divsave"></div>
            <button className="closemodalbtn" onClick={_ => setParams({ ...params, issavevisible: false })}>Close</button>
        </Modal>


        <Modal show={params.isdeletevisible}>
            <div>Do you want to delete?</div>
            <button onClick={_ => ClickDelete()} className="ndt_btn2" style={{ width: "200px", margin: "15px" }}>Delete</button>
            <div id="mgf_divdel"></div>
            <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdeletevisible: false })}>Close</button>
        </Modal>

    </div >)
};
export default GroupSetsDateMgr;