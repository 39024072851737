import ChangeElementCollectionAttr from "../../../functions/ElementSelect/ChangeElementCollectionAttr";
import AddElement from "./AddElement";

interface Hovertype {
    fieldname: string;
    values: string[];
}

function TooltipDetail(data: Hovertype[]) {

    let selectedColor = "red"
    let btnclassname = "tooltipelem_fldbtn"
    let fldelemclassname = "tooltipbar_elem1"

    let popupelem = document.createElement('div');
    popupelem.id = "bargraph_elempopup"
    popupelem.style.cssText = 'width:400px;height:450px;position:absolute;';
    popupelem.style.top = '500px'
    popupelem.style.left = '500px'

    let headerelem = document.createElement('div');
    headerelem.innerHTML = 'Toggle Detail';

    popupelem.appendChild(headerelem)


    const ChangeClick = (e: any) => {
        let fldnm = e.srcElement.innerHTML
        //e.srcElement.style.backgroundColor = selectedColor

        let el_coll = document.getElementsByClassName(fldelemclassname)
        Array.from(el_coll).map(elem => elem.remove())

        ChangeElementCollectionAttr("ttbtn_" + fldnm, "backgroundColor", btnclassname, "red", "white", false)

        data.forEach(ht => {
            if (ht.fieldname === fldnm) {
                let childelem = AddElement("div", ht.fieldname, fldelemclassname, "", "")
                childelem.style.cssText = "font-weight:bold"
                ht.values.forEach(val => {
                    let valelem = AddElement("div", val, fldelemclassname, "", "")
                    childelem.appendChild(valelem)
                })
                subwindowelem.appendChild(childelem)
            }
        })

    }

    const ClickClosed = (e: any) => {

        popupelem.remove()
    }

    const LoadElements = async () => {
        data.forEach(ht => {
            let fldbtn = AddElement("button", ht.fieldname, btnclassname, "", ChangeClick)
            fldbtn.id = "ttbtn_" + ht.fieldname
            popupelem.appendChild(fldbtn)
        });
    }


    LoadElements()


    let subwindowelem = document.createElement('div');
    subwindowelem.style.cssText = 'width:100%;height:340px;overflow-y:scroll';
    popupelem.appendChild(subwindowelem)
    console.log(popupelem)

    let btnelem = AddElement("button", "Close", "tooltipelem_btnclose", "", ClickClosed)
    popupelem.appendChild(btnelem)

    window.addEventListener("beforeunload", (ev) => {
        console.log("eventlistener")
        ev.preventDefault();
        popupelem.remove()
    });

    return (popupelem)
};
export default TooltipDetail;

