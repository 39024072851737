
import FieldsImport from './data/Fields/Fields_Import';
//import DashboardImport from './data/Query/Dashboard_Import';
import SchemaImport from './data/Schema/Schema_Import';
import TableGroupsImport from './data/Tables/TableGroups_Import';
import UserImport from './data/Users/User_Import';
import { PathList, User } from './type';
//import { useState } from 'react';


const OnLoad = (schema: string, user: User, paths: PathList, config: any, dispatcher: any) => {
    let newdata: any[] = [];
    let tablegroups: any = {};

    //when i save to a promise, this causes re-running of consts
    let x = FieldsImport("*", schema, config, dispatcher, true)
    x.then(data => newdata = data)
    let y = SchemaImport("", "", user, config, dispatcher, true)
    y.then(_ => {
        UserImport(config, dispatcher, true)
    })
    let z = TableGroupsImport('', schema, paths.tables.connectionnetwork, user, config, dispatcher, true)
    return ([newdata, tablegroups])

};
export default OnLoad;