import { KPIString } from "../../../type";

const ProduceKPIStringText = (kpistr: KPIString, clickedIdx: number = -1) => {
    //ref:dashboardview

    //each line can have multiple txt/kpi pairs

    let a: number = 0
    return (<div>
        {kpistr.parsed.map((line: string[][], i: number) => {
            return (<div key={i} style={{ display: "flex" }}>

                {line.map((txtpair: string[], x: number) => {
                    if (txtpair[1] !== "") { a += 1 }
                    let styling = {}
                    if (clickedIdx === a) {
                        styling = { color: "blue", fontWeight: "bold" }
                    }
                    return (<div key={"k" + String(i) + "-" + String(x)}>
                        {txtpair[0]} {DivType(txtpair[1], kpistr.FormatWeight, styling)}
                    </div>)
                })}

            </div>)


        })}
    </div>)
};
// let styling = {}
// if (clickedIdx === i) {
//     styling = { color: "blue", fontWeight: "bold" }
// }
// return (<div key={i} style={{ display: "flex" }}>
//     {SplitText(txt, kpistr, i, styling)} {DivType(kpistr.parsed.kpis[i], kpistr.FormatWeight, styling)}
// </div>)
// } else {
// return (<div key={i} style={{ display: "flex" }}>
//     {SplitText(txt, kpistr, i, {})}
// </div>)
// }

export default ProduceKPIStringText;



function DivType(str: string, fmt: string, styling: any = {}) {
    if (fmt === "bold") {
        return (<b style={styling}>{str}</b>)
    } else if (fmt === "italics") {
        return (<i style={styling}>{str}</i>)
    } else {
        return (<a style={styling}>{str}</a>)
    }
}