import AxiosUpload from '../AxiosUpload';
//import DashboardNameImport from './DashboardName_Import';
import { User } from '../../type';


const DashboardUpload = async (schemakey: string, modelname: string, user: User, config: any, dispatcher: any, elementstring: string) => {

    let dataArray = new FormData();

    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("dashboardname", modelname);
    dataArray.append("schemakey", schemakey);
    dataArray.append("userid", user.id);


    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/dashboard/dashpost", config, elem, "");

    //DashboardNameImport("*", schemakey, config, dispatcher, true); //re-save state so new dashboards get working links

    return ("Ok")
};

export default DashboardUpload;