import * as d3 from 'd3';
import DateRemoveTimestamp from '../../functions/Date Functions/DateRemoveTimestamp';
import DateString from '../../functions/Date Functions/DateString';
import ChartGetColor from './ChartGetColor';
import AddGradienttoSVGDefs from './AddGradienttoSVGDefs';


const CreateLines = (svg, classStr, data, detaildata, xfield, yfield, groups, xgroups, isbenchmark,
    groupfield, xaxisfn, yaxisfn, colorScale, secColorScale, colorgradienttype, 
    height, barscount, bar_n, metrno, attributes, vis_state, setVis_State, stateIdx) => {

    //let nvals = data.length
    //let wait_period = 500/nvals
    let linegrpfld = groupfield
    if (!groupfield){
        linegrpfld = "Grouping"
    }   

    //groups: to stack lines
    let grpdata = function(data,grp){
        let gdata = [];
        data.forEach(d=>{
            if (d[linegrpfld]===grp){
                gdata.push(d)
            }
        })
        return(gdata)
    }

    //calculate radius, accounting for large plots
    let radius = 8
    if (isbenchmark){
        yfield = "Benchmark"
        radius = 5
    } 
    let newradius = radius / Math.pow(data.length/Math.max(1,grpdata.length),.3)
    radius = Math.max(Math.min(radius, newradius),2)


    if (colorgradienttype==="Gradient"){
        AddGradienttoSVGDefs(svg, metrno, colorScale(metrno), secColorScale(metrno))
    }

    const GetX = (d) =>{
        return xaxisfn(DateString(DateRemoveTimestamp(d[xfield]),"%Y-%m-%d")) + xaxisfn.bandwidth()/2 
    }
    
    svg.data(data).enter()
    let line = d3.line()
                    .x(function(d){ return GetX(d)})
                    .y(function(d){  return yaxisfn(d[yfield])});


    const Colorize2 = (d) =>{
        if (isbenchmark){
            return ChartGetColor(d, d['EncodedKey'], secColorScale, metrno, groups, groupfield, colorgradienttype, vis_state, stateIdx)
        } else {
            return ChartGetColor(d, d['EncodedKey'], colorScale, metrno, groups, groupfield, colorgradienttype, vis_state, stateIdx)
        }
        
    }

    //attrs order: id, class, data-id

    groups.map((grp,i) => {

        svg.append("path")
            .attr("class",classStr + "_line ndtchartelem")
            .attr("stroke-width",3.5)
            .attr("d",line(grpdata(data,grp)))
            .attr("fill","none")
           // .attr("stroke",(d)=>{return Colorize2(d,i)})
            .attr("stroke",(d)=>{return Colorize2(d)})

        svg.selectAll("rect1")
            .data(data)
            .enter().append("circle")
            .attr("class", classStr + "_point ndtchartelem")
            .attr("cx", function(d) { return GetX(d) })
            .attr("width",10)
            .attr("height", function(_) { return 10; })
            .attr("cy", function(d) { return yaxisfn(d[yfield])})
            .attr("r",radius)
    })

    let x = svg.selectAll("." +  classStr + "_line")    
    let y = svg.selectAll("path")
    let z = svg.selectAll("." + classStr + "_point")

    return(z)
};
export default CreateLines;