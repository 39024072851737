import { ChangeEvent, useState, useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { StartupContext } from '../../../App';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import { JDAIMessage, DescModel, JDAIMessageItem, QueryModel, queryvisualstate, QueryVisuals, Metric } from '../../../type';
import { QueryModelInit, queryvisInit } from '../../../InitTypes';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import ChartConst from '../../../charts/ChartConst';
import "./css/CategoryModelAI.css"
import QueryVisualsPrepare from '../../../functions/QueryFunctions/QueryVisualsPrepare';
import ObjectSort from '../../../functions/ObjectSort';
import ChangeParamSelectorbyIndex from '../../../functions/ElementSelect/ChangeParamSelectorbyIndex';
import GetObjectField from '../../../functions/Object Functions/GetObjectField';
import GetObjectfromArray from '../../../functions/Array Functions/GetObjectfromArray';


const CategoryModelAI = () => {

    let { config, dispatcher, schema, user, paths } = useContext(StartupContext)
    interface paramtype {
        modelkey: string;
        selrundatekey: string;
        querymodelkey: string;
        file: any;
        refresh: number;
        isuplvisible: boolean;
        isdelvisible: boolean;
        isassignvisible: boolean;
        rerun: number;
    }
    const [keyparams, setKeyParams] = useState<paramtype>({
        modelkey: '', selrundatekey: '', file: '', querymodelkey: '', refresh: 0, rerun: 0,
        isuplvisible: false, isdelvisible: false, isassignvisible: false
    })

    let [jdaimsgs, setJDAImsgs] = useState<JDAIMessage[]>([])
    let [selJDAImsg, setSelJDAImsg] = useState<JDAIMessage | null>(null)
    let [jdmodels, setJDModels] = useState<DescModel[]>([])
    let [selJDModel, setSelJDModel] = useState<DescModel | null>(null)
    let [query, setQuery] = useState<QueryModel>(QueryModelInit[0])
    let [vis_states, setVis_States] = useState<queryvisualstate[]>(queryvisInit)
    let [selqryvis, setSelqryvis] = useState<QueryVisuals | null>(null)
    let [metrics, setMetrics] = useState<Metric[]>([])
    let [querymodels, setQueryModels] = useState<QueryModel[]>([])


    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        ImportAndSetStateGeneric(setJDModels, '', schema, paths.jobdescription.descmodelview, user, config, { type: "Name" })
        ImportAndSetStateGeneric(setMetrics, "", schema, paths.query.metricview, user, config, {})
        GenericBackendCall("", schema, paths.query.queryview, user, config, { type: "names", permissions: ['manage', 'read/write', 'creator'] }).then(d => {
            setQueryModels(ObjectSort(d, "DateCreated", true))
            console.log(d)
        })
    }, [])

    useEffect(() => {
        if (selJDModel) {
            GenericBackendCall(selJDModel.ModelKey, schema, paths.jobdescription.jdaiview, user, config, {}).then(d => {
                setJDAImsgs(d);
            })
            setKeyParams({ ...keyparams, modelkey: selJDModel.ModelKey })
        }
    }, [selJDModel?.ModelKey])

    useEffect(() => {
        if (selJDAImsg && selJDAImsg?.QueryModelRelatedKey !== "") {
            setQuery(GetObjectfromArray(querymodels, selJDAImsg.QueryModelRelatedKey, "ModelKey"))
            GenericBackendCall(selJDAImsg.QueryModelRelatedKey, schema, paths.query.queryvisualsview, user, config, { IsPrimary: 'y' }).then(d => {
                if (selJDAImsg) {
                    GenericBackendCall(selJDAImsg.QueryModelRelatedKey, schema, paths.query.rundatesview, user, config, { how: 'primary' }).then(rd => {
                        if (d.length === 1 && rd.length === 1) {
                            setSelqryvis({ ...d[0], RunDateKey: rd })
                            setKeyParams({ ...keyparams, selrundatekey: rd[0].DateKey })
                        }
                    })
                }
            })

        }
    }, [selJDAImsg?.QueryModelRelatedKey])


    useEffect(() => {
        if (selqryvis) {
            QueryVisualsPrepare(selqryvis, setSelqryvis)
        }
    }, [selqryvis?.VisKey])

    useEffect(() => {
        //GenericBackendCall(keyparams.selrundatekey, schema, paths.benchmark.benchmarksetview, user, config, { querykeys: selqryvis })
    }, [keyparams.selrundatekey])

    //------------------------------------------
    const ClickUpload = () => {
        GenericBackendCall('', schema, paths.jobdescription.jdaipost, user, config,
            { file: keyparams.file }, "cmai_divupl").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
    }

    const ClickAssign = () => {
        if (selJDAImsg) {
            GenericBackendCall(selJDAImsg.JDAIKey, schema, paths.jobdescription.jdaiassignmodel, user, config,
                { querymodelkey: keyparams.querymodelkey }, "cmai_divasn").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }

    const ClickDelete = () => {
        let mdlkey = "*"
        if (selJDModel) {
            mdlkey = selJDModel.ModelKey
        }
        GenericBackendCall(mdlkey, schema, paths.generics.genericdelete, user, config,
            { model: "JDAIMessage", field: "DescModelName" }, "cmai_divdel").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
    }


    return (<div className="ndt_canvas">

        <div className="ndt_header ndt_innerbox">
            <div className="ndt_title3">Category Model - AI</div>
            <div className="ndt_gridbox">

                Model: <GenericDropdown
                    data={jdmodels}
                    keycol="ModelKey"
                    namecol="ModelName"
                    promptstring="Select Model"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, jdmodels, setSelJDModel, "", null) }}
                    includeDefault={true}
                    className="ndt_dropdown"
                    defaultstring="All Unassigned"
                />
                <div style={{ display: 'flex', marginTop: "5px" }}>
                    {selJDModel ? <div>

                        <div style={{ display: "flex" }}>
                            <div style={{ marginRight: "15px" }}>Import supporting data (optional):</div>
                            <input id="cmai_input" type="file" onChange={(e: ChangeEvent<HTMLInputElement>) => { if (e.target.files) { setKeyParams({ ...keyparams, file: e.target.files[0] }) } }} />
                            {keyparams.file !== "" ?
                                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, isuplvisible: true }) }}>Upload</button> : <div></div>}
                        </div>
                    </div>
                        : <div></div>}
                    {jdaimsgs.length > 0 ?
                        <button className='ndt_btn2' style={{ marginLeft: "30px" }} onClick={_ => { setKeyParams({ ...keyparams, isdelvisible: true }) }}>Delete</button>
                        : <div></div>}
                </div>
            </div>
        </div>

        <div className='ndt_gridbox' style={{ display: 'flex' }}>
            <div className='ndt_innerbox' style={{ width: '600px' }}>
                <div style={{ fontSize: "19px" }}>Questions</div>
                <div style={{ height: "300px", overflowY: 'scroll' }}>
                    {jdaimsgs.map((msg: JDAIMessage, i: number) => {
                        let usestyle = {}

                        if (msg.JDAIKey === selJDAImsg?.JDAIKey) {
                            usestyle = { background: "blue" }
                        }
                        return (<div key={i} className='cmai_quesitem' style={usestyle}
                            onClick={_ => { setSelJDAImsg(msg) }}>
                            {msg.InputMessage}</div>)

                    })}
                </div>
                <div style={{ display: "flex", height: "30px" }}>
                    <div >Assign Query Model</div>
                    <GenericDropdown
                        data={querymodels}
                        keycol="ModelKey"
                        namecol="ModelName"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, querymodels, keyparams, setKeyParams, "querymodelkey", "ModelKey", "") }}
                        promptstring="Select Model"
                        includeDefault={true}
                        defaultstring="(blank)"
                    />
                    {selJDAImsg ?
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "5px" }}>{GetObjectField(selJDAImsg?.DescModelName, querymodels, "ModelKey", "ModelName")}</div>
                            <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, isassignvisible: true }) }}>Assign</button>
                        </div>
                        : <div></div>}
                </div>
            </div>

            <div className='ndt_innerbox'>
                <div style={{ fontSize: "19px" }}>Chat Answers</div>
                <div style={{ height: "300px", width: '800px', overflowY: 'scroll' }}>
                    {jdaimsgs.map((msg: JDAIMessage, i: number) => {
                        if (msg.JDAIKey === selJDAImsg?.JDAIKey) {

                            return (<div key={i}>
                                {msg.items.map((itm: JDAIMessageItem, j: number) => {
                                    if (itm.Text !== "nan") {
                                        return (<div key={j} className='cmai_ansitem'>{itm.Text}</div>)
                                    }
                                })}
                            </div>)
                        }
                    })}
                </div>
            </div>
        </div>

        <div className='ndt_gridbox'>
            <div>
                <ChartConst query={query} setQuery={setQuery} svgid="ttisros" vis_state={vis_states}
                    setVis_State={setVis_States} qryvis={selqryvis} stateIdx={0} style={{ "backgroundColor": "white" }}
                    rundatekey={keyparams.selrundatekey}
                    rerun={keyparams.rerun}
                    metrics={metrics}
                    size={[500, 500]}
                />
            </div>
        </div>

        <Modal show={keyparams.isuplvisible}>
            <div className='ndt_modal'>
                <div className='ndt_modaltitle'>Upload AI JD Answers</div>
                <button className="ndt_btn1" onClick={_ => { ClickUpload() }}>Upload</button>

                <div id="cmai_divupl"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isuplvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdelvisible}>
            <div className='ndt_modal'>
                <div className='ndt_modaltitle'>Delete AI JD Answers</div>
                <button className="ndt_btn2" onClick={_ => { ClickDelete() }}>Delete</button>

                <div id="cmai_divdel"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isassignvisible}>
            <div className='ndt_modal'>
                <div className='ndt_modaltitle'>Assign Query Model {GetObjectField(String(selJDAImsg?.DescModelName), querymodels, "ModelKey", "ModelName")}</div>
                <button className="ndt_btn1" onClick={_ => { ClickAssign() }}>Assign</button>

                <div id="cmai_divasn"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isassignvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

    </div>)
};
export default CategoryModelAI;