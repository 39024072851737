
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExcelExport = ({csvDataArry, sheetNames,filename}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    if(sheetNames.length>0){


        let sheetdata = {}
        csvDataArry.forEach((data,i)=>{
            sheetdata[sheetNames[i]] = XLSX.utils.json_to_sheet(data)
        })

        const wb = { Sheets: sheetdata, SheetNames: sheetNames };
        
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, filename + fileExtension);
        console.log("exported")
    }
}

export default ExcelExport;
