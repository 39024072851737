//import * as d3 from 'd3';
import DateRemoveTimestamp from '../../functions/Date Functions/DateRemoveTimestamp';
import GetStackedValues from './GetStackedValues';
import DateString from '../../functions/Date Functions/DateString';
import AddGradienttoSVGDefs from './AddGradienttoSVGDefs';



const CreateBars = (svg, classStr, data, detaildata, xfield, yfield, groups, xgroups, groupfield, xaxisfn, yaxisfn, isbenchmark,
    colorScale, secColorScale, colorgradienttype, height, barscount, bar_n, metrno, attributes, vis_state, setVis_State, stateIdx) => {

    let [valuefn, cumvaluefn] = GetStackedValues(data, xfield, yfield, xgroups, groupfield, isbenchmark)

    let barwidth = xaxisfn.bandwidth()/barscount;

    let g = svg.append('g')

    if (colorgradienttype==="Gradient"){
        AddGradienttoSVGDefs(svg, metrno, colorScale(metrno), secColorScale(metrno))
    }

    //attrs order: id, class, data-id
    let bars = g.selectAll("rect1")
        .data(data)
        .enter().append("rect")
        .attr('data-id1',function(d){return d['id']})
        .attr("class", classStr + "_bar ndtchartelem")
        .attr("x", function(d) { 
            return xaxisfn(DateString(DateRemoveTimestamp(d[xfield]),"%Y-%m-%d"))+ barwidth*(bar_n); })
        .attr("height",function(d){
            let hval = valuefn(d)
                return height - yaxisfn(hval); 
        })

       


        if (!attributes.reload){
            bars.attr("y", function(_) { return yaxisfn(0) }) //was mina
                .attr("height", function(_) { return Math.max(0,height - yaxisfn(0)); }) //was mina
    
            bars
            .transition()
                .duration(400)
                .attr("y",function(d) { 
                    let cval = cumvaluefn(d)
                    return yaxisfn(cval); 
                })
                .attr("height",function(d) { 
                    let hval = valuefn(d)
                    return height - yaxisfn(hval); 
                })   

        } else {
            bars.attr("y",function(d) { 
                    let cval = cumvaluefn(d)
                    return yaxisfn(cval); 
                })
                .attr("height",function(d) { 
                        let hval = valuefn(d)
                        return height - yaxisfn(hval)
                }) 

        }

         return(bars)
};
export default CreateBars;