import * as d3 from 'd3';


const HierarchyData = (data, childfield, parentfield) => {

    function sumfunction(group) {
        return d3.sum(group, function(d) {
            return 1;
        });
    }
    
    function doRollup(data1, childfield,parentfield) {
        let groups = d3.rollup(data1, sumfunction,
                               function(d) { return d[parentfield]; },
                               function(d) { return d[childfield]; }
                              );
        console.log(groups)
        
        let childrenAccessorFn = ([ key, value ]) => value.size && Array.from(value)

        let root = d3.hierarchy([null, groups], childrenAccessorFn)
                                        .sum(([key, value]) => value)
                                        .sort((a, b) => b.value - a.value)
        
        return(root)
    }

    //run
    let hierarchydata = doRollup(data, childfield, parentfield);

    return(hierarchydata)
};

export default HierarchyData;