

function ConvertBackendDates(obj: any[]) {
    for (const prop in obj) {
        if (typeof obj[prop] === 'object' && obj[prop] !== null) {
            ConvertBackendDates(obj[prop]); // recursive call to handle nested objects
        } else if (typeof obj[prop] === 'string' && /\d{4}-\d{2}-\d{2}/.test(obj[prop])) {
            let x = new Date(obj[prop])
            obj[prop] = ConvertTimeZone(x); // convert the string to a Date object
        }
    }
    return obj;
};

export default ConvertBackendDates;

const ConvertTimeZone = (date: Date) => {
    let time = date.setTime(date.getTime() + (24 - date.getHours()) * 60 * 60 * 1000);
    return new Date(time)
}
