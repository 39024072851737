
import { useContext, useState, useEffect, ChangeEvent } from "react";
import { StartupContext } from "../../App";
import "./css/UserPage.css"
import { QueryModel, Schedule, User, UserAccount, UserPermssion, UserReports } from "../../type";
import ImportAndSetStateGeneric from "../../functions/Import/ImportAndSetStateGeneric";
import GenericBackendCall from "../../functions/Import/GenericBackendCall";
import SerializeDataFrame from "../../functions/Import/SerializeDataFrame";
import GetObjectField from "../../functions/Object Functions/GetObjectField";
import IsInVector from "../../functions/IsInVector";
import ConvertBackendDates from "../../functions/Date Functions/ConvertBackendDates";
import { Modal } from "react-bootstrap";
import IsInObject from "../../functions/Object Functions/IsInObject";
import { Link } from "react-router-dom";


const UserPage = () => {

    let { config, schema, user, paths } = useContext(StartupContext)

    interface paramtype {
        loaded: boolean; repuserid: string; repuseremail: string; adduser: boolean;
        isrepvisible: boolean;
    }

    let [params, setParams] = useState<paramtype>({ loaded: false, repuserid: '', repuseremail: '', adduser: false, isrepvisible: false })
    let [perms, setPerms] = useState<UserPermssion[]>([])
    let [dashnames, setDashNames] = useState<any[] | null>(null)
    let [schemanames, setSchemaNames] = useState<any[] | null>(null)
    let [querynames, setQueryNames] = useState<any[] | null>(null)
    let [allusers, setAllUsers] = useState<UserAccount[]>([])
    let [reportingUsers, setReportingUsers] = useState<UserReports[]>([])
    let [dataUserOwned, setDataUserOwned] = useState<any[]>([])
    let [dataShared, setDataShared] = useState<any[]>([])

    let [schedules, setSchedules] = useState<Schedule[]>([])

    useEffect(() => {
        ImportAndSetStateGeneric(setPerms, "*", schema, paths.permissions.permview, user, config, { 'modelcategory': '*' })
    }, [])

    const ImportNames = (model: string, keyfield: string, namefield: string, setfn: any) => {
        let permission = "permission"
        let prm = GenericBackendCall("", "", paths.generics.generickeynamemap, user, config,
            { model, keyfield, namefield })
        prm.then(d => { setfn(SerializeDataFrame(d)) })
    }
    useEffect(() => {
        ImportNames("Dashboard", "Key", "Name", setDashNames)
        ImportNames("QueryModel", "ModelKey", "ModelName", setQueryNames)
        ImportNames("Schema", "SchemaKey", "SchemaName", setSchemaNames)
    }, [])

    useEffect(() => {
        ImportAndSetStateGeneric(setAllUsers, "", schema, paths.accounts.usersview, user, config, {})
        ImportAndSetStateGeneric(setReportingUsers, "", schema, paths.accounts.userreportsview, user, config, {})
    }, [])

    useEffect(() => {
        if (IsInObject(reportingUsers, params.repuserid, "EmpUserID")) {
            setParams({ ...params, adduser: false })
        } else {
            setParams({ ...params, adduser: true })
        }
    }, [params.repuserid, reportingUsers])


    useEffect(() => {
        if (dashnames && querynames && schemanames && !params.loaded) {
            setParams({ ...params, loaded: true })
        }
    }, [dashnames, querynames, schemanames, params.loaded])
    useEffect(() => {
        Schedules()
    }, [querynames])

    const Schedules = () => {
        let mkeys: any = {}
        querynames?.forEach((qn: any, i: number) => {
            mkeys[i] = qn.ModelKey
        })

        GenericBackendCall("", schema, paths.generics.genericfilterview, user, config,
            {
                model: "QueryModel", filterfield: "ModelKey", filtervalue: mkeys,
                relatedmodel: "Schedule", relatedname: "querymodel"
            }).then(d => {
                setSchedules(ConvertBackendDates(SerializeDataFrame(d)))
            })
    }
    // useEffect(() => {
    //     let allschedules = schedules.map((sch: Schedule) => {
    //         let lst = CalculateDateSequence(sch.AnchorDate, sch.Period, 5, sch.IPeriod, true)
    //         return (lst)
    //     })
    // }, [schedules])

    const GetPermData = (type: string, ismanage: boolean) => {
        let returnprms: any[] = []
        perms.forEach((up: UserPermssion) => {
            if ((["manage", "creator"].indexOf(up.Permission) > 0 && ismanage) || (up.Permission !== "manage" && !ismanage)) {
                if (type === "dashboard" && dashnames && up.DashboardPrm) {
                    returnprms.push([GetObjectField(up.DashboardPrm, dashnames, "Key", "Name"), up.Permission, "/dashboard/" + GetObjectField(up.DashboardPrm, dashnames, "Key", "UrlString")])
                } else if (type === "query" && querynames && up.QueryModelPrm) {
                    returnprms.push([GetObjectField(up.QueryModelPrm, querynames, "ModelKey", "ModelName"), up.Permission, "/graph/" + up.QueryModelPrm])
                } else if (type === "schema" && schemanames && up.SchemaPrm) {
                    returnprms.push([GetObjectField(up.SchemaPrm, schemanames, "SchemaKey", "SchemaName"), up.Permission, "/schema"])
                }
            }
        })
        return returnprms
    }

    const ClickUserRepSet = () => {
        GenericBackendCall("", schema, paths.accounts.userassignreport, user, config, { "empuser_id": params.repuserid }, "upg_adddiv")
    }

    const ClickRmvRep = () => {
        GenericBackendCall("", schema, paths.accounts.userremovereport, user, config, { "empuser_id": params.repuserid }, "upg_rmvdiv")
    }


    const UserItems = (props: any) => {
        let ismanage: boolean = props.ismanage
        let data: any[] = props.data
        let setData: any = props.setData
        return (
            <div>
                <div style={{ display: "flex" }}>
                    <button className="ndt_btn1" onClick={_ => { setData(GetPermData("schema", ismanage)) }}>Schemas</button>
                    <button className="ndt_btn1" onClick={_ => { setData(GetPermData("dashboard", ismanage)) }}>Dashboards</button>
                    <button className="ndt_btn1" onClick={_ => { setData(GetPermData("query", ismanage)) }}>Queries</button>
                </div>
                <div style={{ overflowY: "scroll", height: "280px", border: "1px solid grey", padding: '3px', marginTop: "4px" }}>
                    {data.map((d: any, i: number) => {
                        return (<div className="usrpg_itm" key={i}>
                            {d[0]}
                            ({d[1]})
                            {<Link to={data[i][2]}></Link>}
                        </div>)
                    })}
                </div>
            </div>
        )
    }

    const ManagerView = () => {
        return (<div>
            My Team
            <div>Add User</div>
            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setParams({
                    ...params,
                    repuserid: e.target.selectedOptions[0].attributes[0].value,
                    repuseremail: e.target.selectedOptions[0].attributes[1].value,
                })
            }}>
                <option value="" data-value="">Users:</option>
                {allusers.map((usr: UserAccount, i: number) => {
                    if (!IsInVector(reportingUsers, usr.email) && usr.level !== "Analyst") {
                        return (<option key={i} value={usr.id} data-value={usr.email}>{usr.email}</option>)
                    }
                })}
            </select>
            <br />
            {params.repuserid !== "" && params.adduser ?
                <button className="ndt_btn1" onClick={_ => ClickUserRepSet()}> Add User {params.repuseremail}</button>
                : <div> </div>}
            <br />
            <div id="upg_adddiv"></div>
            <div>My Users</div>
            <div>
                {reportingUsers.map((rep: UserReports, i: number) => {
                    return (<div key={i} className="upg_userrep" onClick={_ => { setParams({ ...params, repuserid: rep.EmpUserID, repuseremail: rep.EmployeeEmail, isrepvisible: true }) }}>
                        {rep.EmployeeEmail}
                    </div>)
                })}
            </div>

        </div >)
    }

    return (<div id="upg_canvas">
        <div id="upg_header">UserPage</div>

        <div id="upg_body">
            <div id="upg_userstat" className="upg_box">
                {user.email}
                <div>My Objects</div>
                <UserItems ismanage={true} data={dataUserOwned} setData={setDataUserOwned} />
            </div>
            <div id="upg_userstat" className="upg_box">
                {user.email}

                <div>Shared with me</div>
                <UserItems ismanage={false} data={dataShared} setData={setDataShared} />
            </div>

            <div id="upg_userstat" className="upg_box">
                <ManagerView />
            </div>

            <div id="upg_userstat" className="upg_box">
                Upcoming Scheduled Refreshes

            </div>
        </div>
        <Modal show={params.isrepvisible}>
            <div>
                <div>Remove Reporting User?</div>
                <div>{params.repuseremail}</div>
                <button onClick={_ => ClickRmvRep()}>Remove</button>
                <div id="upg_rmvdiv"></div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isrepvisible: false })}>Close</button>
            </div>
        </Modal>
    </div>)
};

export default UserPage