import { FormEvent, ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginActionCreator } from '../../../reducers/user_actions';
import axios from 'axios';
import "./css/signup.css"
import UserImport from '../../../data/Users/User_Import';


const SignUp = () => {

	//react-redux functions
	const dispatcher = useDispatch();
	//const user = useSelector((state: State) => state.user );


	const [formData, setFormData] = useState({
		id: "",
		email: '',
		password: '',
		name: '',
		re_password: '',
		accesslevel: '',
		isAuthenticated: false,
		level: ''
	});

	const { id, email, name, password, re_password } = formData;
	const [msg, setMsg] = useState<string>("")

	const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		Signupauth();

	};

	const Signupauth = () => {
		//authentication - move to separate file
		const config = {
			headers: {
				'Content-Type': 'application/json',
			}
		};
		const body = JSON.stringify({ id, email, name, password, re_password });
		console.log(body);

		try {
			axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/signup/`, body, config)
				.then(_ => {

				});
			setMsg("User Created!")
		} catch (err) {
			setMsg(String(err))
			console.log(err)
		}

	}

	const ChangeAccess = (e: ChangeEvent<HTMLSelectElement>) => {
		if (e.target.selectedOptions[0].attributes[0].value !== "") {
			setFormData({ ...formData, accesslevel: e.target.selectedOptions[0].attributes[0].value })
		}
	}

	return (
		<div>
			<div className="signupContainer" >

				<div id="signupbox" >
					<div id="signuptitle"> Sign Up! </div>
					<div id="signformbox" >

						<form className='auth-form' onSubmit={e => onSubmit(e)}>
							<div className='auth__form__group'>
								<input
									className='sign-input'
									type='email'
									placeholder='Email'
									name='email'
									value={email}
									onChange={e => onChange(e)}
									required
								/>
							</div>
							<div className='auth-form'>
								<input
									className='sign-input'
									type='password'
									placeholder='Password'
									name='password'
									value={password}
									onChange={e => onChange(e)}
									minLength={6}
								/>
								<input
									className='sign-input'
									type='password'
									placeholder='Retype Password'
									name='re_password'
									value={re_password}
									onChange={e => onChange(e)}
									minLength={6}
								/>
								<div style={{ marginLeft: '25%', marginTop: "10px", display: "flex", color: "white", fontSize: '18px' }}>
									<div style={{ marginRight: "10px" }}>Access Level</div>

									<select onChange={(e: ChangeEvent<HTMLSelectElement>) => { ChangeAccess(e) }}>
										<option value="">Select User Level:</option>
										<option value="Analyst">Analyst</option>
										<option value="Manager">Manager</option>
										<option value="Leader">Leader</option>
									</select>
								</div>
							</div>
							<button className='signup-button'>Create User</button>
						</form>
						<div style={{ color: "white" }}>{msg}</div>
					</div>
				</div>

			</div>
		</div>
	)

};

export default SignUp;