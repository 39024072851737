import { ChangeEvent } from "react";


export const ChangeSelectorbyIndex = (e: ChangeEvent<HTMLSelectElement>, data: any[], setfunction: any,
    setonfield: string, returndefault: any | null = "") => {

    let val = +e.target.selectedOptions[0].attributes[0].value

    if (val >= 0) {
        if (setonfield !== "") {
            setfunction(data[val][setonfield])
        } else {
            setfunction(data[val])
        }
    } else if (val === -1) {
        setfunction(returndefault)
    }
    return (data[val])

};
export default ChangeSelectorbyIndex;