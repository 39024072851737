import { useState, ChangeEvent, FormEvent, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { useSelector } from "react-redux";
import { StartupContext } from "../../../App";
import { AllValues, FieldMap, ValueCount } from "../../../type";
import GetUniquesFromObjectAttribute from "../../../functions/GetUniquesFromObjectAttribute";
import GetFieldName from "../../../functions/GetFieldName";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import FilterData from "../../../functions/FilterData";

import "./css/ValueTracker.css"
import DateString from "../../../functions/Date Functions/DateString";


const ValueTracker = () => {

    let { schema, config, dispatcher, paths, user } = useContext(StartupContext)
    const fields: FieldMap[] = useSelector((state: any) => state.fieldmapping);

    interface params_type {
        refresh: number;
        selModelCategory: string; selFieldKey: string; selFieldName: string;
    }

    const [allvalues, setAllValues] = useState<AllValues[]>([])
    const [modelcats, setModelCats] = useState<any[]>([])
    const [valuefields, setValueFields] = useState<any[]>([])
    const [selValueFlds, setSelValueFlds] = useState<AllValues[] | null>(null)
    const [selValue, setSelValue] = useState<AllValues | null>(null)
    const [keyparams, setKeyParams] = useState<params_type>({
        refresh: 0, selModelCategory: "", selFieldKey: '', selFieldName: ''
    })


    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams])

    useEffect(() => {
        GenericBackendCall("", schema, paths.fields.allvaluesview, user, config, {}).then(d => {
            let uflds = GetUniquesFromObjectAttribute(d, "FieldName")
            let vf = uflds.map((arr: any[]) => {
                return { FieldKey: arr[0], FieldName: GetFieldName(fields, arr[0]), ModelCategory: GetFieldName(fields, arr[0], "Key", "ModelCategory"), Count: arr[1] }
            })
            let mcats = GetUniquesFromObjectAttribute(vf, "ModelCategory")
            let mc = mcats.map((arr: any[]) => {
                return { ModelCategory: arr[0], Count: arr[1] }
            })
            setAllValues(d)
            setModelCats(mc)
            setValueFields(vf)
        })
    }, [])

    useEffect(() => {
        if (keyparams.selFieldKey !== "") {
            let flt = FilterData(allvalues, "FieldName", keyparams.selFieldKey)
            setSelValueFlds(flt)
            setKeyParams({ ...keyparams, selFieldName: GetFieldName(fields, keyparams.selFieldKey) })
        }
    }, [keyparams.selFieldKey])



    return (<div className="ndt_canvas">
        <div className="ndt_title2">Value Tracker</div>

        <GenericDropdown
            data={modelcats}
            keycol="ModelCategory"
            namecol="ModelCategory"
            promptstring="Select Table"
            className="ndt_dropdown"
            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, modelcats, keyparams, setKeyParams, "selModelCategory", "ModelCategory") }}
        />

        <GenericDropdown
            data={valuefields}
            keycol="FieldKey"
            namecol="FieldName"
            className="ndt_dropdown"
            promptstring="Select Field"
            filterfield="ModelCategory"
            filtervalue={keyparams.selModelCategory}
            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, valuefields, keyparams, setKeyParams, "selFieldKey", "FieldKey") }}
        />
        {selValueFlds ?
            <div className="ndt_gridbox">
                <div className="ndt_innerbox" >
                    <div className="ndt_title4">Values - {keyparams.selFieldName}</div>
                    <div id="vtx_grid">
                        <div className="ndt_subinner" style={{ height: "500px", overflowY: "scroll" }}>
                            {selValueFlds.map((av: AllValues, i: number) => {
                                return (<div key={i} className="ndt_item"
                                    onClick={_ => { setSelValue(av) }}>
                                    {av.Value}
                                </div>)
                            })}
                        </div>
                        <div className="ndt_subinner">
                            {selValue ?
                                <div>
                                    <div style={{ fontSize: "20px", letterSpacing: ".05em" }}>Selected Value: {selValue.Value}</div>
                                    {selValue.values.map((v: ValueCount, i: number) => {
                                        return (<div key={i} className="vtx_valitm" style={{ display: "flex" }}>
                                            <div style={{ width: "50%" }}>On Date: {DateString(v.AsofDate)}</div>
                                            <div style={{ width: "50%" }}>Count: {v.Count}</div>
                                        </div>)
                                    })}
                                </div> : <div></div>}
                        </div>
                    </div>
                </div>
            </div> : <div></div>}
    </div>)
};
export default ValueTracker;