
function ObjectSort(data,field,descending=false) {
    //ref: chartsample, querymodelview, getuniquesfromobjectattribute, queryvisualsseriesorder, tablemgr, kpimanager

    let desc = 1
    if (descending){
        desc = -1
    }
    if (data && data.length>0){
        data.sort((a, b) => {
            let fa = a[field],
                fb = b[field]
            if (typeof(a[field])==="text"){
                fa = a[field].toLowerCase();
                fb = b[field].toLowerCase();           
            }

            if (fa < fb) {
                return -1 * desc;
            }
            if (fa > fb) {
                return 1 * desc;
            }
            return 0;
        });
        return(data)
    } else {
        return data
    }
  }

  export default ObjectSort;

