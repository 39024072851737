
const StringToBoolean = (str1: string): boolean => {

    let retval: boolean = false
    if (str1 === "true" || str1 === "True") {
        retval = true
    } else if (str1 === "false" || str1 === "False") { //redundant 
        retval = false
    }
    return retval
};
export default StringToBoolean;