
import { FilterModel } from "../../../../type";

const FilterModelDropdown = (props: any) => {
    //import filtermodels, create dropdown
    //ref: employeedatatable, 

    let fModels = props.fModels;
    let change = props.change;
    let className = props.className;

    return (
        <select id="filtmodeldropdown" onChange={change} className={className}>
            <option key={-2} value={""}>Select Filter Model</option>
            {fModels.length > 0 ?
                fModels.map((fmdl: FilterModel, i: number) => {
                    return (<option key={i} value={fmdl.ModelKey}>
                        {fmdl.ModelName}
                    </option>)
                }
                )
                : <option key={-1} value={"null"}>None Available</option>
            }
        </select>
    )
};
export default FilterModelDropdown;