

function IsInObjectWhere(ObjArr,val,onfield,obj_filterfield, filterval, returnIndex=false){

	var x= 0;
	var contained;
	var indx = -1;
	
	//loop through
	ObjArr.forEach((obj,i) => {
		if(String(obj[onfield]) === String(val) && String(obj[obj_filterfield])===String(filterval)){
			x = x + 1;
			indx = i;
		} else {
			x = x + 0
		}
	//console.log("IsInVector: " + ind + " vs " + val + "..." + x)
	})
	
	
	if (returnIndex){ 
		contained = indx 
	} else {
		if (x>0){ contained = true } else { contained = false}
	}

	return(contained)
};

export default IsInObjectWhere;