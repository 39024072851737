import IsInVector from "../IsInVector";


const GetUniquesFromArrayMultiField = (arr: any[], keys: string[], ignoreblanks: boolean = false) => {


    let uniques: any[] = []
    let uniquevals: string[] = []
    arr.forEach((d: any) => {
        let uval = ""
        keys.forEach(key => {
            uval = uval + "|" + String(d[key])
        })
        if (!IsInVector(uniquevals, uval)) {
            let y: any = {}
            let continuerun = true
            keys.forEach(key => {
                y[key] = d[key]
                if (d[key] === "" && ignoreblanks === true) {
                    continuerun = false
                }
            })

            if (continuerun === true) {
                uniques.push(y)
                uniquevals.push(uval)
            }
        }
    })
    return uniques
}
export default GetUniquesFromArrayMultiField;