import { useState, useContext, useEffect, MouseEvent, useMemo } from "react";
import DashboardUpload from "../../../data/Dashboard/Dashboard_Upload";
import { StartupContext } from "../../../App";
import { ChangeEvent } from "react";
import { DashRunDate, KPI, Dashboard, DashboardFilter, DashboardPage, FieldMap, FilterField, KPIString, QueryModel, QueryVisuals, Schedule, User, UserPermssion } from "../../../type";
import DashboardUpdate from "../../../data/Dashboard/Dashboard_Update";
import Draggable from "react-draggable";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import IsInVector from "../../../functions/IsInVector";
import FieldsImport from "../../../data/Fields/Fields_Import";
import GetObjectfromArray from "../../../functions/Array Functions/GetObjectfromArray";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import RemovefromArray from "../../../functions/Array Functions/RemovefromArray";
import IsInObject from "../../../functions/Object Functions/IsInObject";
import { QueryModelInit } from "../../../InitTypes";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import DateString from "../../../functions/Date Functions/DateString";
import RunDatesDropdown from "../graphs/subfunctions/RunDatesDropdown";
import { Modal } from "react-bootstrap";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import CalculateSchedule from "../../../functions/Date Functions/CalculateSchedule";
import MatchSchedtoRunDates from "../../../functions/Date Functions/MatchSchedtoRunDates";
import QueryModelRerun from "../../../data/Query/QueryModel_Rerun";
import CalculateStickyDate from "../../../functions/Date Functions/CalculateStickyDate";
import GetUserPermission from "../../../functions/QueryFunctions/GetUserPermission";
import "./css/DashboardsManage.css"
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import DateMatch from "../../../functions/Date Functions/DateMatch";
import { useParams } from "react-router-dom";
import GetFieldName from "../../../functions/GetFieldName";
import StyleMainSelect from "../../../styling/StyleMainSelect";
import { Link } from "react-router-dom";


const DashboardsManager = () => {

    interface scheddate {
        AsofDate: Date;
        Found: boolean;
    }
    let { id } = useParams()


    const { config, dispatcher, schema, user, paths } = useContext(StartupContext)
    const [newmodelName, setNewModelName] = useState<string>("")
    const [dashboards, setDashboards] = useState<Dashboard[]>([])
    const [selDashboard, setSelDashboard] = useState<Dashboard | null>(null)
    const [qryvisuals, setQueryVisuals] = useState<QueryVisuals[]>([])
    const [queries, setQueries] = useState<QueryModel[]>([])
    const [kpistrings, setKPIStrings] = useState<KPIString[]>([])
    const [schedules, setSchedules] = useState<Schedule[]>([])
    const [schedDates, setSchedDates] = useState<scheddate[]>([])
    const [fields, setFields] = useState<FieldMap[]>([])
    const [filtFields, setFiltFields] = useState<FieldMap[]>([])
    const [savetypes, setSaveTypes] = useState<any>({ pages: false, rundates: false, positions: false, filters: false, schedule: false })
    const [pages, setPages] = useState<any>({ 1: "Page1", 2: "", 3: "", 4: "", 5: "", 6: "", 7: "", 8: "" })
    const [filterPosns, setFilterPosns] = useState<DashboardFilter[]>([])
    const [userperm, setUserPerm] = useState<UserPermssion | null>(null)
    let [keyparams, setKeyParams] = useState({
        selIdx: -1, pagecount: 1, activepg: 1, refresh: 0, pgnameIdx: 0, selschedule: '',
        viewleft: 'graphs', filtFld: '', viewright: "pages", selscheddate: { AsofDate: new Date(), Found: false },
        issavevisible: false, isscheddatevisible: false, ispgvisible: false, isdeletevisible: false, added: false,
        isseldashboard: false
    })

    let highlightcolor: string = "linear-gradient(55deg, #ffff66 26%, #999966 100%)"
    let defaultcolor: string = "linear-gradient(-145deg, rgba(2,0,36,1) 50%, rgba(14,14,92,1) 100%)"

    let btnSelStyle = { opacity: "1", boxShadow: "0px 0px 3px #000000" }
    let btnUnSelStyle = { opacity: ".45", boxShadow: "" }
    let optSelStyle = { backgroundColor: "blue", boxShadow: "0px 0px 5px yellow" }

    let mqstyleOff = { backgroundColor: "black", color: "white" }
    let mqstyleOn = { backgroundColor: "yellow", color: "black" }

    //let [dims, setDims] = useState<any>({ "increment": 60, "Height": 300, "Width": 300 })
    let posnArry: any[] = [];


    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams])

    useEffect(() => {
        ImportAndSetStateGeneric(setDashboards, "", schema, paths.dashboard.dashnameview, user, config, { permissions: ["manage", "creator"] })
        FieldsImport("", schema, config, dispatcher, false).then(d => { setFields(d) })
        ImportAndSetStateGeneric(setSchedules, "", schema, paths.schedule.scheduleview, user, config, {})
    }, [])

    useEffect(() => {
        if (id) {
            dashboards.forEach((dash: Dashboard) => {
                if (dash.Key === id) {
                    setSelDashboard(dash)

                }
            })
        }
    }, [dashboards])

    useEffect(() => {
        if (!IsInObject(filterPosns, keyparams.filtFld, "FieldName")) {
            filterPosns.push({ DashboardName: '', FieldName: keyparams.filtFld, IsTangible: false, Height: 0, Width: 0, PageNo: 0 })
            setFilterPosns(filterPosns)
        }

        setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 }) //field box is not updating without keyparams being updated
    }, [keyparams.filtFld])

    useEffect(() => {
        if (selDashboard && selDashboard.ScheduleName) {
            setKeyParams({ ...keyparams, selschedule: selDashboard.ScheduleName, isseldashboard: true })
        }
    }, [selDashboard?.ScheduleName])

    useEffect(() => {
        if (keyparams.selschedule !== "" && selDashboard && selDashboard.ScheduleName === null) {
            setSaveTypes({ ...savetypes, schedule: true })
        }

    }, [keyparams.selschedule, selDashboard])

    useEffect(() => {
        if (selDashboard) {
            GetUserPermission(selDashboard, user, setUserPerm)

            //set visuals
            setQueryVisuals(selDashboard.visuals)

            //set pages
            selDashboard.pages.forEach((pg: DashboardPage) => {
                pages[pg.PageNo] = pg.PageName
            })
            setPages(pages)

            //set graphs
            console.log(selDashboard)
            let querykeys: string[] = selDashboard.visuals.map((qvis: QueryVisuals) => {
                return (qvis.ModelName)
            })
            console.log(querykeys)

            let qrypromise = ImportAndSetStateGeneric(setQueries, "", schema, paths.query.queryview, user, config, { querykeys: querykeys, type: "basic" })
            qrypromise.then((qset: QueryModel[]) => {
                let flds: string[] = []
                let fldmap: FieldMap[] = []
                qset.map((q: QueryModel) => {
                    q.filterfields.map((f: FilterField) => {
                        if (f.FieldName !== "" && !IsInVector(flds, f.FieldName)) {
                            flds.push(f.FieldName);
                            fldmap.push(GetObjectfromArray(fields, f.FieldName, "Key"))
                        }
                    })

                })
                setFiltFields(fldmap)
            })

            //set filters
            setFilterPosns(selDashboard.filters)

            //set kpistrings
            setKPIStrings(selDashboard.kpistrings)

            setKeyParams({ ...keyparams, isseldashboard: true })

        } else {
            setKeyParams({ ...keyparams, isseldashboard: false })
        };

    }, [selDashboard?.Key])

    useEffect(() => {
        if (selDashboard) {
            GenericBackendCall("", schema, paths.schedule.scheduleview, user, config, { schedkeys: [keyparams.selschedule] }).then(d => {
                console.log(d)
                if (d.length == 1) {
                    let sdates = CalculateSchedule(d[0], 5, -4)
                    setSchedDates(MatchSchedtoRunDates(sdates, selDashboard.rundates, true))
                }
            })
        }
    }, [keyparams.selschedule])

    //-----backend
    const UploadDash = () => {
        if (newmodelName !== "") {
            DashboardUpload(schema, newmodelName, user, config, dispatcher, "dashupload1_div").then(_ => { setKeyParams({ ...keyparams, added: true, refresh: 99 }) })
        } else {
            let elem = document.getElementById("dashupload1_div")
            if (elem) {
                elem.innerHTML = "Name can't be blank"
            }
        }
    };

    const DeleteDash = () => {
        if (selDashboard) {
            GenericBackendCall(selDashboard.Key, schema, paths.dashboard.dashdelete, user, config, {}, "dxb_divdel")
                .then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    };
    const SaveDashboard = () => {
        if (selDashboard) {
            DashboardUpdate(selDashboard.Key, qryvisuals, kpistrings, savetypes, filterPosns, pages,
                keyparams.selschedule, config, "dxb_uploaddiv").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }
    const ClickedRunDash = () => {
        if (selDashboard) {
            let elem = document.getElementById("dxb_divrunall")
            let aod: Date = keyparams.selscheddate.AsofDate
            if (elem) { elem.innerHTML = "Running..." }
            //console.log(ConvertBackendDates(d))

            let qmlist: string[] = []
            queries.forEach((qm: QueryModel, i: number) => {
                qmlist.push(qm.ModelKey)
            })
            let kpilist: string[] = []
            kpistrings.forEach((kstr: KPIString) => {
                kstr.KPIs.forEach((kpi: string) => {
                    if (kpilist.indexOf(kpi) === -1) { kpilist.push(kpi) }
                })
            })
            GenericBackendCall(selDashboard.Key, schema, paths.dashboard.dashrundatepost, user, config, { asofdate: aod }).then(d => {

                GenericBackendCall("", schema, paths.generics.genericfilterview, user, config, { model: "KPI", filterfield: "Key", modelkeys: kpilist }).then(d => {
                    let kpidata = SerializeDataFrame(d)
                    kpidata.forEach((k: KPI) => {
                        if (qmlist.indexOf(k.ModelName) === -1) { qmlist.push(k.ModelName) }
                    })
                    let schedulekey = selDashboard.ScheduleName
                    GenericBackendCall("", schema, paths.generics.genericfilterview, user, config,
                        { model: "QueryModel", filterfield: "ModelKey", modelkeys: qmlist }).then(d => {
                            let newqueries = SerializeDataFrame(d)
                            newqueries.forEach((qm: QueryModel, i: number) => {
                                let eod: Date = CalculateStickyDate(aod, qm.StickyDateRef, qm.Period, qm.IPeriod, qm.OffsetDays, qm.IncludeToDate, false)
                                QueryModelRerun(qm.ModelKey, schema, "", aod, eod, [schedulekey], "dashboard " + DateString(aod), user, config, "").then(_ => {
                                    if (elem) { elem.innerHTML = "Completed Query " + String(i + 1) + " of " + newqueries.length }
                                })
                            })
                        })
                })
            })
        }
    }
    const ClickedDeleteRunDash = () => {
        if (selDashboard) {
            let rundatekey = ""
            selDashboard.rundates.forEach((drd: DashRunDate) => {
                if (DateMatch(drd.AsofDate, keyparams.selscheddate.AsofDate)) {
                    rundatekey = drd.DateKey
                }
            })
            if (rundatekey !== "") {
                GenericBackendCall(rundatekey, schema, paths.generics.genericdelete, user, config,
                    { model: "DashRunDates", field: "DateKey", asofdate: keyparams.selscheddate.AsofDate }, "dxb_divrunall").then(d => {
                        setKeyParams({ ...keyparams, refresh: 99 })
                    })
            } else {
                let elem = document.getElementById("dxb_divrunall")
                if (elem) { elem.innerHTML = "Error finding specified run date" }
            }
        }
    }

    //-------------


    const ClickedGraph = (e: MouseEvent<HTMLDivElement>, n: number, mclass: string) => {

        let elem = e.currentTarget
        let id = elem.attributes[2].value
        //let mdls = FilterData(selDashboard?.visuals, "ModelKey", id)
        console.log(id, n)
        let secondelem = document.getElementById("dmq_" + id)
        if (secondelem) {
            secondelem.style.background = highlightcolor
            secondelem.style.color = defaultcolor
        }
        setKeyParams({ ...keyparams, selIdx: n, viewleft: mclass })

    };

    const ClickedMiniQuery = (e: MouseEvent<HTMLDivElement>, n: number, mclass: string) => {
        if (mclass === "graphs") {
            if (qryvisuals[n].PageNo < 1) {
                qryvisuals[n].PageNo = keyparams.activepg
                setQueryVisuals(qryvisuals)
            }
        } else if (mclass === "kpis") {
            if (kpistrings[n].PageNo < 1) {
                kpistrings[n].PageNo = keyparams.activepg
                setKPIStrings(kpistrings)
            }
        }
        setKeyParams({ ...keyparams, selIdx: n })
    };



    const RemovefromPage = (n: number, type: string) => {
        if (type === "graphs") {
            qryvisuals[n].PageNo = 0
            setQueryVisuals(qryvisuals)
        } else if (type === "kpis") {
            kpistrings[n].PageNo = 0
            setKPIStrings(kpistrings)
        }
    }
    const RemoveFilter = () => {
        if (keyparams.selIdx >= 0) {
            let newarry = RemovefromArray(filterPosns, filterPosns[keyparams.selIdx].FieldName, "FieldName")
            setFilterPosns(newarry)
        }
    }


    const DimInput = (dimstr: string) => {
        return (
            <input type="number" max={12} min={1} style={{ height: "30px" }} onChange={e => ResizeDiv(+e.target.value * 100, dimstr)} />
        )
    }

    let inputmemo_height = useMemo(() => { return DimInput("Height") }, [qryvisuals, keyparams.selIdx, keyparams.viewleft])
    let inputmemo_width = useMemo(() => { return DimInput("Width") }, [qryvisuals, keyparams.selIdx, keyparams.viewleft])

    const ResizeDiv = (value: number, dim: string) => {
        if (keyparams.viewleft === "graphs") {
            if (dim === "Width") {
                qryvisuals[keyparams.selIdx].Width = value
            } else {
                qryvisuals[keyparams.selIdx].Height = value
            }
            setQueryVisuals(qryvisuals)
        } else if (keyparams.viewleft === "kpis") {
            if (dim === "Width") {
                kpistrings[keyparams.selIdx].Width = value
            } else {
                kpistrings[keyparams.selIdx].Height = value
            }
            setKPIStrings(kpistrings)
        }
    }

    const ClickChangePages = (increment: number) => {
        if (increment > 0) {
            setPages({ ...pages, [keyparams.pagecount + 1]: "Page" + String(keyparams.pagecount + 1) })
            setKeyParams({ ...keyparams, pagecount: keyparams.pagecount + 1 })
        } else {
            qryvisuals.forEach((qry: QueryVisuals) => {
                if (qry.PageNo == keyparams.pagecount) {
                    qry.PageNo = 0
                }
            })
            setQueryVisuals(qryvisuals)
            setPages({ ...pages, [keyparams.pagecount]: "" })
            setKeyParams({ ...keyparams, pagecount: keyparams.pagecount - 1 })

        }
        setSaveTypes({ ...savetypes, pages: true })
    }

    const DragStart = (i: number, mclass: string) => {
        setKeyParams({ ...keyparams, selIdx: i, viewleft: mclass })
    }

    const DragStop = (e: any, mclass: string) => {

        if (keyparams.selIdx >= 0) {
            let parent = document.getElementById("dxb_graphs")
            if (parent) {
                let pxy = parent.getBoundingClientRect()

                if (mclass === "graphs") {
                    let elem = document.getElementById("dgraph_" + qryvisuals[keyparams.selIdx].VisKey)
                    if (!elem) { return "" }
                    let xy = elem.getBoundingClientRect()
                    qryvisuals[keyparams.selIdx].XPos = (xy.x - pxy.x) / 1000
                    qryvisuals[keyparams.selIdx].YPos = (xy.y - pxy.y) / 1000
                    setQueryVisuals(qryvisuals)
                } else if (mclass === "kpis") {
                    let elem = document.getElementById("dgraph_" + kpistrings[keyparams.selIdx].Key)
                    if (!elem) { return "" }
                    let xy = elem.getBoundingClientRect()
                    kpistrings[keyparams.selIdx].XPos = (xy.x - pxy.x) / 1000
                    kpistrings[keyparams.selIdx].YPos = (xy.y - pxy.y) / 1000
                    setKPIStrings(kpistrings)
                }

            }
        }

        setSaveTypes({ ...savetypes, positions: true })
    }


    //toggle views
    const View_Queries = () => {
        return (
            <div id="dxb_miniquery_container">
                {selDashboard?.visuals.map((qgraph: QueryVisuals, i: number) => {
                    let mqstyle = mqstyleOff
                    if (i === keyparams.selIdx && keyparams.viewleft === "graphs") { mqstyle = mqstyleOn }
                    return (<div key={i} id={"dmq_" + qgraph.VisKey}
                        className="dxb_miniquery" data-name={qgraph.VisKey}
                        style={mqstyle}
                        onClick={(e: MouseEvent<HTMLDivElement>) => ClickedMiniQuery(e, i, "graphs")}>
                        <div>{qgraph.VisName}</div>
                        <div>Pg: {qgraph.PageNo}</div>
                    </div>)

                })}
                <div>
                    <Link to={"/query/managegraph"}><div style={{ color: "white", padding: '2px', margin: "5px" }}>Add Graphs</div></Link>

                </div>
            </div>
        )
    }

    const View_Filters = () => {
        return (
            <div>
                <div style={{ fontSize: "22px" }}>Create New Filter</div>
                <div>Add Dropdown:</div>
                <GenericDropdown
                    data={filtFields}
                    keycol="Key"
                    namecol="FieldName"
                    promptstring="Select Field"
                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                        ChangeParamSelectorbyIndex(e, filtFields, keyparams, setKeyParams, "filtFld", "Key", "")
                        setSaveTypes({ ...savetypes, filters: true })
                    }}
                />
                <div>
                    {filterPosns.map((flt: DashboardFilter, i: number) => {
                        let usecolor = defaultcolor
                        if (keyparams.selIdx === i && keyparams.viewleft === "filters") { usecolor = highlightcolor }
                        return (
                            <div key={i} style={{ background: usecolor }} className="dxb_filteritem"
                                onClick={_ => { setKeyParams({ ...keyparams, selIdx: i }) }}
                            >Filter:{GetFieldName(fields, flt.FieldName)}</div>
                        )
                    })}
                </div>
                <br />
                {keyparams.selIdx >= 0 ?
                    <div>
                        <div>Remove Selected?</div>
                        <button className="ndt_btn1" onClick={_ => RemoveFilter()}>Remove</button>
                    </div>
                    : <div></div>}

                <div id="dxb_divfilt"></div>
            </div>
        )
    }

    const View_KPIs = () => {
        return (
            <div>
                {kpistrings.map((ks: KPIString, i: number) => {
                    let mqstyle = mqstyleOff
                    if (i === keyparams.selIdx && keyparams.viewleft === "kpis") { mqstyle = mqstyleOn }
                    return (<div key={i}
                        className="dxb_miniquery"
                        style={mqstyle}
                        onClick={(e: MouseEvent<HTMLDivElement>) => ClickedMiniQuery(e, i, "kpis")}>
                        {ks.Name}
                    </div>)
                })}
            </div>
        )
    }

    const View_Buttons = () => {
        return (
            <div>

            </div>
        )
    }

    const NewDateSelected = (e: ChangeEvent<HTMLSelectElement>, qryvis: QueryVisuals, qry: QueryModel, i: number) => {
        let newval = String(e.target.selectedOptions[0].attributes[0].value)
        qryvisuals[i].RunDateKey = newval
        setQueryVisuals(qryvisuals)
        setSaveTypes({ ...savetypes, rundates: true })
    }

    const GetMatchingQuery = (qgraph: QueryVisuals) => {
        let matchingquery: QueryModel = QueryModelInit[0]
        queries.forEach((qry: QueryModel) => {
            if (qry.ModelKey === qgraph.ModelName) {
                matchingquery = qry
            }
        })
        return (matchingquery)
    }

    const ResetScheduleChoice = () => {
        setSaveTypes({ ...savetypes, schedule: false })
        setKeyParams({ ...keyparams, selschedule: "" })
        setSchedDates([])
    }


    const GetRunDatefromQuery = (qgraph: QueryVisuals, matchingquery: QueryModel) => {

        let setdate = ""
        if (qgraph.RunDateKey === "Primary") {
            setdate = DateString(GetObjectField("true", matchingquery.rundates, "IsPrimary", "AsofDate"))
        } else {
            setdate = DateString(GetObjectField(qgraph.RunDateKey, matchingquery.rundates, "DateKey", "AsofDate"))
        }
        return (setdate)
    }

    const Show_Pages = () => {
        return (
            <div style={{ width: "500px" }}>
                <div>Pages: {keyparams.activepg}</div>
                <div>
                    <button onClick={_ => ClickChangePages(1)}>Add Page</button>
                    <button onClick={_ => ClickChangePages(-1)}>Remove Page</button>
                    <button onClick={_ => { setKeyParams({ ...keyparams, ispgvisible: true }) }}>Rename Page</button>
                </div>
                <div>
                    {Object.keys(pages).map((k: string) => {
                        if (pages[k] !== "") {
                            let pstyle = { backgroundColor: "#444444", border: '', boxShadow: "" }
                            if (+k === keyparams.activepg) { pstyle = { backgroundColor: "#999999", border: "2px solid lightblue", boxShadow: "3px 1px 8px yellow" } }
                            return (<div style={pstyle} key={k} className="dm_page" onClick={_ => setKeyParams({ ...keyparams, activepg: +k })}>{pages[k]}</div>)
                        }
                    })}
                </div>
            </div>
        )
    }

    const Show_RunDates = () => {
        return (
            <div style={{ width: "500px" }}>
                <div>
                    Run Dates
                    <div>
                        {qryvisuals.map((qvis: QueryVisuals, i: number) => {
                            let matchingquery = GetMatchingQuery(qvis)
                            let setdate = GetRunDatefromQuery(qvis, matchingquery)
                            return (<div key={i}>
                                {qvis.VisName}: {setdate}
                                <RunDatesDropdown
                                    querymodel={matchingquery}
                                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                                        NewDateSelected(e, qvis, matchingquery, i)
                                    }}
                                />
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        )
    };

    const Show_Schedule = () => {
        return (<div>
            {selDashboard && selDashboard.ScheduleName ?
                <div>Assigned: {GetObjectField(selDashboard?.ScheduleName, schedules, "ScheduleKey", "ScheduleName")} </div>
                : <div>None Assigned
                </div>}
            <GenericDropdown
                data={schedules}
                keycol="ScheduleKey"
                namecol="ScheduleName"
                promptstring="Change Schedule"
                divID="scm_scheddown"
                change={(e: ChangeEvent<HTMLSelectElement>) => {
                    ChangeParamSelectorbyIndex(e, schedules, keyparams, setKeyParams, "selschedule", "ScheduleKey")
                }}
                includeDefault={true}
                defaultstring="None"
            />
            <div>Selected:</div>
            {GetObjectField(keyparams.selschedule, schedules, "ScheduleKey", "ScheduleName")}
            <div>
                <div>Dates:</div>
                {schedDates.map((sd: scheddate, i: number) => {
                    return (<div key={i}
                        className="dxb_sdate"
                        onClick={_ => { setKeyParams({ ...keyparams, selscheddate: sd, isscheddatevisible: true }) }}
                    >
                        {DateString(sd.AsofDate)} {sd.Found ? " - Run" : ""}
                    </div>
                    )
                })}
            </div>
            <button className="ndt_btn1" onClick={_ => { ResetScheduleChoice() }}>Clear Selection</button>
            {selDashboard && selDashboard.ScheduleName ? <div></div> : <div>Click "Save" to assign this schedule</div>}
        </div>)
    }


    return (
        <div id="dxb_canvas1" className="ndt_canvas">
            <StyleMainSelect base={<GenericDropdown
                data={dashboards}
                keycol="Key"
                namecol="Name"
                change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, dashboards, setSelDashboard, "", null)}
                className="ndt_dropdown"
                promptstring="Select Dashboard or Create New"
                defaultstring="Create New Dashboard"
                includeDefault={true}
            />}
                delete={
                    <button type="submit" className="ndt_btn2" style={{ marginLeft: "10px" }} onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: true })}>Delete Dashboard</button>
                }
                deleteCondition={selDashboard && userperm && userperm.Permission === "creator"}
                highlight={!keyparams.isseldashboard}
            />
            <div className="ndt_header ndt_gridbox" style={{ marginBottom: "10px" }}>
                <h2 id="dxb_mainheader">Manage Dashboards</h2>
            </div>
            <div style={{ display: "flex", height: "55px" }}>


                {selDashboard && userperm && userperm.Permission === "creator" ?
                    <div style={{ display: "flex" }}>


                        <button className="dxb_savebtn" style={{ marginLeft: "15px", marginTop: '10px' }}
                            onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}>Save Dashboard</button>

                        <div id="dxb_resize">
                            <div>
                                <div className="dxb_res_tag"> Height</div>
                                {inputmemo_height}
                            </div>
                            <div>
                                <div className="dxb_res_tag">Width</div>
                                {inputmemo_width}
                                <button type="submit" className="ndt_btn1" onClick={_ => {
                                    setSaveTypes({ ...savetypes, positions: true })
                                    setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
                                }}>Set</button>
                            </div>

                        </div>

                    </div>
                    : <div></div>}
            </div>

            {selDashboard ?
                <div id="dxb_composer">
                    <div id="dxb_header">

                    </div>
                    <div id="dxb_graphsparent">

                        {/* modals */}
                        <div className="dxb_sidepane" style={{ width: '250px' }}>
                            <div style={{ fontSize: "18px" }}>Showing: {keyparams.viewleft}</div>
                            <select className="ndt_dropdown" value={keyparams.viewleft} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setKeyParams({ ...keyparams, viewleft: e.target.selectedOptions[0].attributes[0].value }) }}>
                                <option value="graphs">Graphs</option>
                                <option value="kpis">KPI String</option>
                                <option value="filters">Filters</option>
                                <option value="buttons">Buttons</option>
                            </select>
                            {(() => {
                                switch (keyparams.viewleft) {
                                    case "graphs": return <View_Queries />;
                                    case "kpis": return <View_KPIs />;

                                    case "filters": return <View_Filters />;
                                    case "buttons": return <View_Buttons />;
                                    default: return <div></div>;
                                }
                            })()}

                        </div>

                        <div id="dxb_graphs" style={{ height: "800px", width: '1200px' }}>
                            {qryvisuals.map((qgraph: QueryVisuals, i: number) => {
                                let matchingquery = GetMatchingQuery(qgraph)
                                let setdate = GetRunDatefromQuery(qgraph, matchingquery)

                                let position = {
                                    'height': String(qgraph.Height + "px"), 'width': String(qgraph.Width + "px"),
                                    "background": defaultcolor, "color": "white", "opacity": 1,
                                    "transform": "translate(" + Math.round(qgraph.XPos * 1000) + "px, " + Math.round(qgraph.YPos * 1000) + "px)"
                                }
                                if (qgraph.PageNo !== keyparams.activepg) {
                                    position.opacity = 0
                                }
                                if (i === keyparams.selIdx && keyparams.viewleft === "graphs") {
                                    position.background = highlightcolor
                                    position.color = "black"
                                }
                                posnArry.push([qgraph.XPos, qgraph.YPos])
                                if (qgraph.PageNo === keyparams.activepg) {
                                    return (
                                        <Draggable key={i}
                                            grid={[50, 50]}
                                            onStart={(e) => { DragStart(i, "graphs") }}
                                            onStop={(e) => { DragStop(e, "graphs") }}
                                            bounds={{ left: 0, top: 0, bottom: 800 - qgraph.Height, right: 1200 - qgraph.Width }}
                                        >
                                            <div id={"dgraph_" + qgraph.VisKey}
                                                key={i}
                                                className="dbmg_graph"
                                                data-name={qgraph.VisKey}
                                                style={position}
                                                onClick={(e: MouseEvent<HTMLDivElement>) => ClickedGraph(e, i, "graphs")}
                                            >
                                                <div>Graph {i}</div>
                                                <div>{GetObjectField(qgraph.ModelName, queries, "ModelKey", "ModelName")}</div>
                                                <div>Assigned Run Date: {setdate}</div>
                                                <RunDatesDropdown
                                                    querymodel={matchingquery}
                                                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                                                        NewDateSelected(e, qgraph, matchingquery, i)
                                                    }}
                                                />
                                                <button className="dxb_rmvbtn" onClick={_ => RemovefromPage(i, "graphs")}>Remove</button>
                                            </div>
                                        </Draggable>
                                    )
                                }
                            })
                            }
                            {kpistrings.map((kstr: KPIString, i: number) => {

                                let position = {
                                    //     'left': String(x) + "px", 'top': String(y) + "px",
                                    'height': String(kstr.Height + "px"), 'width': String(kstr.Width + "px"),
                                    "background": defaultcolor, color: "white", "opacity": 1,
                                }
                                if (kstr.PageNo !== keyparams.activepg) {
                                    position.opacity = 0
                                }
                                if (i === keyparams.selIdx && keyparams.viewleft === "kpis") {
                                    position.background = highlightcolor
                                    position.color = "black"
                                }
                                posnArry.push([kstr.XPos, kstr.YPos])
                                if (kstr.PageNo === keyparams.activepg) {
                                    return (
                                        <Draggable key={i}
                                            grid={[50, 50]}
                                            onStart={(_) => { DragStart(i, "kpis") }}
                                            onStop={(e) => { DragStop(e, "kpis") }}
                                            bounds={{ left: 0, top: 0, bottom: 800 - kstr.Height, right: 1200 - kstr.Width }}
                                        >
                                            <div id={"dgraph_" + kstr.Key}
                                                key={i}
                                                className="dbmg_graph"
                                                data-name={kstr.Key}
                                                style={position}
                                                onClick={(e: MouseEvent<HTMLDivElement>) => ClickedGraph(e, i, "kpis")}
                                            >
                                                <div>String {i}</div>
                                                <div>{kstr.Name}</div>

                                                <button className="dxb_rmvbtn" onClick={_ => RemovefromPage(i, "kpis")}>Remove</button>
                                            </div>
                                        </Draggable>
                                    )
                                }
                            })
                            }
                        </div>
                        <div className="dxb_sidepane">
                            <div style={{ width: "400px", display: "flex" }}>
                                <button className="ndt_btn1" style={keyparams.viewright === "pages" ? optSelStyle : {}} onClick={_ => setKeyParams({ ...keyparams, viewright: "pages" })}>Pages</button>
                                <button className="ndt_btn1" style={keyparams.viewright === "rundates" ? optSelStyle : {}} onClick={_ => setKeyParams({ ...keyparams, viewright: "rundates" })}>RunDates</button>
                                <button className="ndt_btn1" style={keyparams.viewright === "schedule" ? optSelStyle : {}} onClick={_ => setKeyParams({ ...keyparams, viewright: "schedule" })}>Schedule</button>
                            </div>
                            {(() => {
                                switch (keyparams.viewright) {
                                    case "pages": return <Show_Pages />;
                                    case "rundates": return <Show_RunDates />;
                                    case "schedule": return <Show_Schedule />;
                                    default: return <div></div>;
                                }
                            })()}
                        </div>
                    </div>

                </div>

                :

                <div id="dxb_circle">
                    <div id="dxb_newdash">
                        <div style={{ color: "white", fontSize: "18px", letterSpacing: ".05em" }}>Add A New Dashboard</div>
                        <input type="text" name="dashmodelname" onChange={e => setNewModelName(e.target.value)} />
                        {keyparams.added ? <button style={{ borderRadius: "5px", height: "35px" }} onClick={_ => setKeyParams({ ...keyparams, refresh: 100 })}> Confirm/Refresh </button> :
                            <button className="ndt_btn1" onClick={_ => UploadDash()}>Add Dashboard</button>}

                        <div id="dashupload1_div" style={{ color: "white" }}> </div>
                    </div>
                </div>
            }
            <Modal show={keyparams.issavevisible}>
                <div className="ndt_modal">
                    <div>Save Changed Dashboard Elements</div>
                    <div style={savetypes.positions ? btnSelStyle : btnUnSelStyle} className="dxb_svitm">Layout</div>
                    <div style={savetypes.pages ? btnSelStyle : btnUnSelStyle} className="dxb_svitm">Pages</div>
                    <div style={savetypes.filters ? btnSelStyle : btnUnSelStyle} className="dxb_svitm">Filters</div>
                    <div style={savetypes.rundates ? btnSelStyle : btnUnSelStyle} className="dxb_svitm">RunDates</div>
                    <div style={savetypes.schedule ? btnSelStyle : btnUnSelStyle} className="dxb_svitm">Schedule</div>

                    <button onClick={_ => SaveDashboard()}>Save with Changes to these elements</button>

                    <div id="dxb_uploaddiv" style={{ marginLeft: '10px' }}></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            <Modal show={keyparams.isscheddatevisible}>
                <div className="ndt_modal">
                    <div style={{ height: '100px' }}>
                        {keyparams.selscheddate.Found && selDashboard ? <div>
                            Queries run as of {DateString(GetObjectField(String(keyparams.selscheddate.AsofDate), selDashboard?.rundates, "AsofDate", "RunDate"))}. Rerun?
                        </div> :
                            <div>
                                Run Queries as of {DateString(keyparams.selscheddate.AsofDate)}?
                            </div>}
                        <button className="ndt_btn1" onClick={_ => ClickedRunDash()}>Run</button>
                        <button className="ndt_btn2" onClick={_ => ClickedDeleteRunDash()}>Delete</button>
                        <div id="dxb_divrunall"></div>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isscheddatevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            <Modal show={keyparams.isdeletevisible}>
                <div className="ndt_modal">
                    <div style={{ fontSize: "18px" }}>Delete dashboard {selDashboard?.Name}?</div>
                    <button className="ndt_btn2" onClick={_ => { DeleteDash() }}>Delete</button>
                    <div id="dxb_divdel"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>


        </div >
    );
};
export default DashboardsManager;



// function CheckNearbyPositions(graph: QueryVisuals, posnarry: any[]) {
//     let returndiff = 0;
//     posnarry.forEach((posn: any) => {
//         if (Math.abs(+graph.XPos - posn[0]) < 10 && Math.abs(+graph.YPos - posn[1]) < 10) {
//             returndiff += 10;
//         }
//     })
//     return returndiff;
// }

// function GetCoordsfromTransform(xformstring: string): string[] {
//     let e_len = xformstring.length
//     let tposn = xformstring.search("px")
//     let str1 = xformstring.substring(tposn + 2, e_len)
//     let e_len2 = str1.length

//     return [xformstring.substring(10, tposn), str1.substring(2, e_len2 - 3)]
// }

