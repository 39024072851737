import GetHardcodedTables from "../../context/GetHardcodedTables";
import { TableModel } from "../../type";

const ModelCategoryDropdown = (props: any) => {
    //ref: graphcreator, rulesetsmanager, fieldsmanager, networkdatamanage

    let change = props.change
    let className = props.className
    let usetables = props.usetables
    let includesecondary = props.includesecondary

    let alltables = GetHardcodedTables(false)
    if (includesecondary) {
        alltables = GetHardcodedTables(true) //include secondary tables
    }

    if (usetables) {
        alltables = usetables
    } else {

        if (props.tables) {
            props.tables.forEach((t: TableModel) => {
                alltables.push(t.ModelName)
            })
        }
    }


    return (
        <select className={className} onChange={change}>
            <option value="">Select Table:</option>
            {alltables.map((tbl: string, i: number) => {
                return (<option key={i} value={tbl}>{tbl}</option>)
            })}
        </select>
    )
};
export default ModelCategoryDropdown;