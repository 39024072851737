import axios from 'axios';
import DatatoFormArray from '../../functions/Import/DatatoFormArray';
//import {User} from "../../type"
import { empdeleteActionCreator, empstateDataActionCreator, empstatedeleteActionCreator } from '../../reducers/employee_actions';
import { Emp_State } from '../../type'

const EmployeeStateSliceImport = async (data: any, schemakey: string, setstate: boolean, config: any, dispatcher: any) => {
	let empstatedata: any = [];
	let statedata: Emp_State[] = [];

	let dataArray = DatatoFormArray(data);
	dataArray.append("schemakey", schemakey);

	try {
		empstatedata = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/api/employee/dataslice`, dataArray, config)

		if (setstate) {
			dispatcher(empstatedeleteActionCreator({}))
			dispatcher(empdeleteActionCreator({}))
		}
		empstatedata.data.forEach((state: Emp_State) => {
			if (setstate) {
				dispatcher(empstateDataActionCreator(state))
			}
			statedata.push(state);
		})
	} catch (err) {
		console.log(err)
	}

	//console.log(statedata);
	return (statedata);
};
export default EmployeeStateSliceImport;