import axios from "axios";
import { filterModelActionCreator, filterModelDeleteActionCreator } from "../../reducers/filterset_actions";
import { FilterModel } from "../../type";


const FilterModelImport = async (modelkey: string, schemakey: string, config: any, dispatcher: any, store: boolean) => {
    let fltdata: FilterModel[] = []
    //EmployeeDataTable, GenericDataTable, Filtersetsmanage
    try {
        let res = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/api/filtersets/filtermodelview`, { 'schemakey': schemakey }, config);

        fltdata = res.data
        if (store) {
            dispatcher(filterModelDeleteActionCreator({}))
            fltdata.forEach((flt: FilterModel) => {
                dispatcher(filterModelActionCreator(flt))
            })
        }
    } catch (error) {
        console.log(error)
    }
    return (fltdata)
};
export default FilterModelImport