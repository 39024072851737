import { useState, ChangeEvent, useEffect, useContext, MouseEvent, useMemo } from 'react'
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import { ColorModel, ColorSet } from '../../../type';
import { ColorModelInit } from '../../../InitTypes';
import ImportAndSetState from '../../../functions/Import/ImportAndSetState';
import ColorModelImport from '../../../data/ColorModel/ColorModel_Import';
import { StartupContext } from '../../../App';
import ColorModelUpload from '../../../data/ColorModel/ColorModel_Upload';
import ColorSetImport from '../../../data/ColorModel/ColorSet_Import';
import { useSelector } from 'react-redux';
import GetFieldName from '../../../functions/GetFieldName';
import ColorSetReattach from '../../../data/ColorModel/ColorSet_ReAttach';
import SetObjectfromKey from '../../../functions/SetFunctions/SetObjectfromKey';
import { Modal } from 'react-bootstrap';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import { Link } from 'react-router-dom';
import "./css/ColorModelManage.css"

const ColorModelManage = () => {

    let { config, dispatcher, schema, appcolors, paths, user } = useContext(StartupContext)
    const fields = useSelector((state: any) => state.fieldmapping)

    interface keyparamstype {
        assigncheck: boolean;
        assign_unassign: string;
        to_from: string;
        isdeletevisible: boolean;
        issavevisible: boolean;
        refresh: number;
    }

    let [colMdls, setColMdls] = useState<ColorModel[]>([])
    let [selMdl, setSelMdl] = useState<ColorModel>(ColorModelInit[0])
    let [assigntoMdl, setAssigntoMdl] = useState<ColorModel | null>(null)
    let [colSets, setColSets] = useState<ColorSet[]>([])
    let [selColSet, setSelColSet] = useState<ColorSet | null>(null)
    let [keyparams, setKeyParams] = useState<keyparamstype>({
        assigncheck: false, assign_unassign: "", to_from: "", isdeletevisible: false, issavevisible: false, refresh: 0
    })

    const defaultStyle = { 'backgroundColor': appcolors.coloraltprimary, color: "white", border: "1px solid white" }
    const selectedStyle = { 'backgroundColor': appcolors.colorhighlight, color: "black", border: "1px solid black" }
    let [divStyles, setDivStyles] = useState<any[]>([])

    const viewtypes = [
        { A: 'assign', B: 'Assign' },
        { A: 'copy', B: 'Copy' },
        { A: 'delete', B: "Delete" },
        { A: 'rename', B: "Rename" },
        { A: 'new', B: "New" }
    ]
    const viewbtndefault = { backgroundColor: "rgb(20,50,100)", opacity: "1", color: "white", cursor: "pointer" }
    const viewbtnselected = { backgroundColor: "rgb(40,40,40)", opacity: "1", color: "pink", cursor: "pointer" }
    const viewbtninactive = { backgroundColor: "rgb(20,50,100)", opacity: ".5", color: "white", cursor: "default" }

    let viewstylesinit = Array.apply(null, Array(viewtypes.length)).map(function (_) { return viewbtndefault; })
    const [ViewButtonStyle, setViewButtonStyle] = useState<any[]>(viewstylesinit)
    const [view, setView] = useState<string>("") //dashboard || dates

    useEffect(() => {
        ImportAndSetState(ColorModelImport, setColMdls, "*", schema, config, dispatcher, false)
        ImportAndSetState(ColorSetImport, setColSets, "*", schema, config, dispatcher, false);
        setViewButtonStyle(viewstylesinit);
    }, [])

    useEffect(() => {
        let initstyles = Array.apply(null, Array(colSets.length)).map(function (_) { return defaultStyle })
        setDivStyles(initstyles);
    }, [selMdl.ModelKey])

    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        if (selColSet && assigntoMdl) {

            let found = false;
            assigntoMdl.colorsets.forEach((cset: ColorSet) => {
                if (cset.FieldName === selColSet?.FieldName && selColSet.ColorModelName !== assigntoMdl?.ModelKey) {
                    found = true;
                }
            });

            if (selColSet.ColorModelName === assigntoMdl.ModelKey) {
                setKeyParams({ ...keyparams, assign_unassign: "Unassign", to_from: " from ", assigncheck: !found })
            } else {
                setKeyParams({ ...keyparams, assign_unassign: "Assign", to_from: " to ", assigncheck: !found })
            }
        }

    }, [assigntoMdl, selColSet, view])


    useEffect(() => {
        //assign active/inactive btn logic
        if (selColSet) {
            //if (selMdl.ModelKey !== "") {
            setViewButtonStyle([viewbtndefault, viewbtndefault, viewbtndefault, viewbtndefault, viewbtndefault])
            // } else {
            //     setViewButtonStyle([viewbtninactive, viewbtndefault, viewbtndefault, viewbtndefault, viewbtndefault])
            // }
        } else if (selMdl.ModelKey !== "") {
            setViewButtonStyle([viewbtninactive, viewbtninactive, viewbtndefault, viewbtndefault, viewbtndefault])
        } else {
            setViewButtonStyle([viewbtninactive, viewbtninactive, viewbtninactive, viewbtninactive, viewbtndefault])
        }
    }, [view, selColSet, selMdl])


    const ClickedView = (vw: any, i: number) => {
        if (vw.A !== view && ViewButtonStyle[i].opacity === "1") {
            setView(vw.A)
            let newstyles = ViewButtonStyle
            newstyles[i] = viewbtnselected
            setViewButtonStyle(newstyles)
        }
    }

    const ClickColorSet = (e: MouseEvent<HTMLDivElement>, i: number) => {
        let moddedstyles = Array.apply(null, Array(colSets)).map(function (_) { return defaultStyle })
        moddedstyles[i] = selectedStyle
        setDivStyles(moddedstyles)
        SetObjectfromKey(e.currentTarget.attributes[0].value, colSets, "SetKey", setSelColSet, true, null)
    };

    //backend calls
    const ClickSave = () => {
        setKeyParams({ ...keyparams, refresh: 99 })
        ColorModelUpload(selMdl.ModelName, schema, config, dispatcher, "cmm_savediv")
    }

    const ClickAssign = () => {
        if (selColSet && assigntoMdl) {
            console.log("uploading...")
            ColorSetReattach(schema, assigntoMdl.ModelKey, selColSet?.SetKey, keyparams.assign_unassign, config, "cmm_assigndiv")
        }
    }

    const ClickDelete = () => {
        GenericBackendCall(selMdl.ModelKey, schema, paths.colorsets.colormodeldelete, user, config, {}, "cmm_deldiv")
    };

    const ClickCopy = () => {
        if (selColSet) { GenericBackendCall(selColSet.SetKey, schema, paths.colorsets.colorsetduplicate, user, config, {}, "cmm_deldiv") }
    };
    const ClickRename = () => {
        if (selMdl.ModelKey !== "") {
            GenericBackendCall(selMdl.ModelKey, schema, paths.generics.genericupdatevalue, user, config,
                { model: "ColorModel", field: "ModelKey", changefield: "ModelName", newvalue: selMdl.ModelName }, "cmm_rendiv")
        }
    };


    //---views
    //assign
    const ViewAssign = () => {
        if (selColSet) {
            return (<div className="cmm_optn">
                <div><GenericDropdown
                    data={colMdls}
                    change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, colMdls, setAssigntoMdl, "", null)}
                    keycol="Key"
                    namecol="ModelName"
                    divID="cmm_csddown"
                    className=""
                    promptstring="Select Color Model"
                />
                    {assigntoMdl && keyparams.assigncheck ?
                        <div>
                            <div>{keyparams.assign_unassign} {selColSet.SetName} {keyparams.to_from} {assigntoMdl.ModelName}?</div>
                            <button className="ndt_btn1" onClick={_ => { ClickAssign() }}> {keyparams.assign_unassign} </button>
                            <div id="cmm_assigndiv"></div>
                        </div>
                        : <div>{keyparams.assigncheck ? "" : "Cannot assign another Set with the same field to this model"}</div>}
                </div>
            </div>)
        } else {
            return (<div></div>)
        }
    }
    const viewassignmemo = useMemo(() => ViewAssign(), [assigntoMdl, selColSet, view, keyparams.assigncheck, keyparams.assign_unassign]);


    const ViewCopy = () => {
        if (selColSet) {
            return (<div className="cmm_optn">
                Copy Color Set {selColSet.SetName}?
                <button className="ndt_btn2" onClick={_ => { ClickCopy() }}>Copy</button>
                <div id="cmm_divcopy"></div>
            </div>)
        } else {
            return (<div></div>)
        }
    }

    //new
    const ModelNameInput = () => {
        return (
            <div>
                <form>
                    <input type="text" value={selMdl.ModelName} style={{ height: "35px" }}
                        onChange={e => setSelMdl({ ...selMdl, ModelName: e.target.value })} />
                </form>
            </div>
        )
    }
    const inputmemo = useMemo(() => ModelNameInput(), [selMdl.ModelName]);

    const ViewNew = () => {
        return (<div className="cmm_optn">
            <div style={{ marginTop: '5px', marginRight: "10px", fontSize: "18px" }}>New Model Name</div>
            <div>{inputmemo}</div>
            {selMdl.ModelName !== "" ?
                <div style={{ width: "200px", height: "65px", overflow: "hidden" }}>
                    <button className="ndt_btn2" onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}> Save New Model</button>
                </div>
                : <div></div>}
        </div>)
    }
    const viewnewmemo = useMemo(() => ViewNew(), [selMdl.ModelName]);

    //delete
    const ViewDelete = () => {
        if (selMdl.ModelKey !== "") {
            return (<div className="cmm_optn">
                <div style={{ margin: "10px" }}>Delete this Model?</div>
                <button className="ndt_btn2" style={{ margin: "15px" }} onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: true })}>Delete</button>
            </div>)
        } else {
            return (<div></div>)
        }
    }

    //rename
    const ViewRename = () => {
        if (selMdl.ModelKey !== "") {
            return (<div className="cmm_optn">
                <div>Rename to:</div>
                <input type="text" value={selMdl.ModelName}
                    onChange={e => { setSelMdl({ ...selMdl, ModelName: e.target.value }) }} />
                <br />
                <button className='ndt_btn2' onClick={_ => ClickRename()}>Rename</button>
                <div id="cmm_rendiv"></div>
            </div>)
        } else {
            return (<div></div>)
        }
    }
    const viewrenamememo = useMemo(() => ViewRename(), [selMdl.ModelName]);

    //main div
    return (<div id="cmm_canvas" className="ndt_canvas">
        <div id="cmm_upper" className="ndt_gridbox">
            <div id="cmm_header">Color Model Manager</div>

            <div style={{ display: "flex", fontSize: "20px" }}>
                <div style={{ marginTop: '15px', display: "flex" }}>

                    <div id="cmm_viewbox">
                        <div style={{ marginRight: "10px" }}>Action:</div>
                        {
                            viewtypes.map((vw: any, i: number) => {
                                return (
                                    <button key={i} className="qm_viewbtn"
                                        style={vw.A === view ? viewbtnselected : ViewButtonStyle[i]}
                                        onClick={_ => ClickedView(vw, i)}>{vw.B}</button>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{ marginLeft: "20%" }}>
                    <div style={{ width: "220px", height: "45px", paddingLeft: '5px', backgroundColor: "rgb(100,10,40)", border: "1px solid white", borderRadius: '5px' }}>
                        <Link className="nav-link active" aria-current="page" style={{ color: "white", fontSize: "18px" }} to="/colorsets/editor" >Create/Edit ColorSets</Link>
                    </div>
                </div>

            </div>
            <div>

            </div>
        </div>

        <div id="cmm_body">

            <div id="cmm_modelbox">
                <div>
                    {selMdl.ModelKey !== "_" && selMdl.ModelName !== "" ?
                        <div id="cmm_existmodeldiv">
                            Existing Model Check
                            <div>
                                <div>Model: {selMdl.ModelName}</div>
                                ColorSets:
                                {selMdl.colorsets.map((cset: ColorSet, i: number) => {
                                    return (
                                        <div
                                            className="cmm_selmdlSetdiv"
                                            key={i}
                                        >
                                            {cset.SetName}
                                            {cset.FieldName}
                                        </div>
                                    )
                                })}
                                <div>
                                    <Link className="nav-link active" aria-current="page" style={{ margin: "3px" }} to="/colorsets/editor" >Create New</Link>
                                </div>
                            </div>
                        </div>
                        : <div></div>}
                </div>
            </div>
            <div id="cmm_varbox" style={{ gridRow: "1/1", gridColumn: "2/2" }}>
                {(() => {
                    switch (view) {
                        case "assign": return <div>
                            {viewassignmemo}
                        </div>
                        case "copy": return <div >
                            <ViewCopy />
                        </div>
                        case "delete": return <div>
                            <ViewDelete />
                        </div>
                        case "new": return <div >
                            {viewnewmemo}
                        </div>
                        case "rename": return <div>
                            {viewrenamememo}
                        </div>
                        default: return <div></div>
                    }
                })()}
            </div>

            <div id="cmm_assignbox" className='ndt_gridbox'>
                <div>Model:</div>
                <GenericDropdown
                    data={colMdls}
                    change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, colMdls, setSelMdl, "", { ...ColorModelInit[0], ModelKey: "" })}
                    keycol="Key"
                    namecol="ModelName"
                    divID="mcs_csddown"
                    className=""
                    promptstring="Select Model"
                    includeDefault={true}
                    defaultstring="All Unassigned"
                    value={selMdl.ModelName}
                />
                <div>Color Set: {selColSet?.SetName}</div>
                <div id="cmm_modelselbox">
                    {colSets.map((cset: ColorSet, i: number) => {

                        if (cset.ColorModelName === selMdl.ModelKey || (selMdl.ModelKey === "" && !cset.ColorModelName)) {
                            let fldname = GetFieldName(fields, cset.FieldName, "Key", "FieldName")
                            return (<div
                                data-name={cset.SetKey}
                                id={"cmm_set_" + cset.SetKey}
                                className="cmm_setdiv"
                                key={i}
                                style={divStyles[i]}
                                onClick={(e: MouseEvent<HTMLDivElement>) => ClickColorSet(e, i)}
                            >
                                {cset.SetName} - {fldname}
                            </div>)
                        }
                    })}
                </div>

            </div>
        </div>

        <Modal show={keyparams.isdeletevisible}>
            <div className="ndt_modal">
                Delete Color Model {selMdl.ModelName}?
                <div style={{ display: "flex" }}>
                    <button className="ndt_btn1" style={{ margin: "10px", width: "200px" }} onClick={_ => ClickDelete()}>Delete</button>
                    <div style={{ margin: "10px" }} id="cmm_deldiv"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.issavevisible}>
            <div className="ndt_modal">
                Save Color Model {selMdl.ModelName}?
                <div style={{ display: "flex" }}>
                    <button className="ndt_btn1" style={{ margin: "10px", width: "200px" }} onClick={_ => ClickSave()}>Save</button>
                    <div style={{ margin: "10px" }} id="cmm_deldiv"></div>
                </div>
                <div id="cmm_savediv" style={{ color: "white" }}></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

    </div>)
};
export default ColorModelManage;