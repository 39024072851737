import { Metric } from "../../type";

const CheckMetricFormat = (metr: Metric) => {
    //kpimanager
    if (metr.ReportTypeDenominator !== "None" && metr.ReportTypeDenominator !== "") {
        return { format: 'pct', rounding: 2 }

    } else {
        return { format: '', rounding: 0 }
    }
};
export default CheckMetricFormat;