  
function AddGradienttoSVGDefs(svg, metrno, color1, color2){

    const gradient = svg.append("defs")
                        .append("linearGradient")
                        .attr("id", "myGradient"+ String(metrno))
                        .attr("x1", "30%") // Starting x-coordinate of the gradient
                        .attr("x2", "100%") // Ending x-coordinate of the gradient
                        .attr("y1", "30%") // Starting y-coordinate of the gradient
                        .attr("y2", "100%"); // Ending y-coordinate of the gradient
                    
                    // Add gradient stops
                    gradient.append("stop")
                        .attr("offset", "0%")
                        .attr("stop-color", color1); // Start color
                    
                    gradient.append("stop")
                        .attr("offset", "100%")
                        .attr("stop-color", color2); // End color
        


  };
  export default AddGradienttoSVGDefs;
