import GenericBackendCall from "./GenericBackendCall";
import { User } from "../../type";
import ConvertBackendDates from "../Date Functions/ConvertBackendDates";
import SerializeDataFrame from "./SerializeDataFrame";


const ImportAndSetStateGeneric = async (stateSet: any, modelkey: string, schema: string, urlstring: string,
    user: User, config: any, otherdata: any = {}, serialize: boolean = false) => {

    let promise: Promise<any>;

    promise = GenericBackendCall(modelkey, schema, urlstring, user, config, otherdata)

    promise.then((d: any) => {
        //changed to convertdates 6.18.23
        //console.log(d)
        let data = d;
        if (serialize) {
            data = SerializeDataFrame(d)
        }
        stateSet(ConvertBackendDates(data))
    });
    return (promise)
};
export default ImportAndSetStateGeneric
