import { useSelector } from 'react-redux';
import { User } from '../type';

const GetAuthConfigHeaders = () => {
    const userstate: User = useSelector((state: any) => state.user);

    const configheaders = {
        headers: {
            'Authorization': `Bearer ${userstate.authtoken}`,
            'Content-Type': "application/json",
        }
    };
    // console.log(userstate.authtoken)

    return (configheaders)
};
export default GetAuthConfigHeaders;