
const ConvertArraytoObject = (arry: any[][], objfields: string[]) => {

    let retobj: any[] = arry.map((v: any[]) => {
        let newrow: any = {}
        objfields.forEach((f: string, i: number) => {
            newrow[f] = v[i]
        })
        return newrow
    })
    return retobj;
};
export default ConvertArraytoObject;