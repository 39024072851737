import HierarchyData from "../../../functions/HierarchyData";
import DeptTreeViewer from "./DeptTreeViewer";

import { Emp_State } from "../../../type";
import { useSelector } from "react-redux";

const PreDeptTreeViewer = () => {

    const empstate:Emp_State[] = useSelector((state:any) => state.employee_full);
    let hdataset:any = []

        hdataset = HierarchyData(empstate,"EmpID","ReportsToID")
        console.log(hdataset)


    return(<DeptTreeViewer data={hdataset}/>)
};
export default PreDeptTreeViewer;