
const GetStatusInit = () => {

    interface ststype {
        ModelCategory: string;
        ModelName: string; //literal model backend name, to match backend ChangeCounter 
        ItemDesc: string;
        NextStep: string;
        Number: number;
        IsComplete: boolean;
        IsOptional: boolean;
        Link: string;
        KeyField: string;
        NameField: string;
    }

    return [
        {
            ModelCategory: "Employees", ModelName: "ModelBatch", ItemDesc: "Employee data imported", NextStep: 'Start importing employee data',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/employee/upload', KeyField: "BatchKey", NameField: "BatchName"
        },
        {
            ModelCategory: "Department", ModelName: "DepartmentModel", ItemDesc: "Employee data imported", NextStep: 'Import department mappings for your employee data',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/datamanager/department', KeyField: "ModelKey", NameField: "ModelName"
        },
        {
            ModelCategory: "JobTitle", ModelName: "JobTitleModel", ItemDesc: "Employee data imported", NextStep: 'Import job title mappings for your employee data',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/datamanager/jobtitle', KeyField: "ModelKey", NameField: "ModelName"
        },
        {
            ModelCategory: "Positions", ModelName: "PositionModel", ItemDesc: "Employee data imported", NextStep: 'Import positional data for your employee data',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/datamanager/position', KeyField: "ModelKey", NameField: "ModelName"
        },
        {
            ModelCategory: "Tables", ModelName: "TableModel", ItemDesc: "Additional tables created", NextStep: 'Create a table using secondary data',
            Number: 0, IsComplete: false, IsOptional: true, Link: '/tables/manager', KeyField: "ModelKey", NameField: "ModelCategory"
        },
        {
            ModelCategory: "Metrics", ModelName: "Metric", ItemDesc: "Metrics created", NextStep: 'Create metric values like Department Headcount or Average Salary to run data queries',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/metrics/manager', KeyField: "MetricKey", NameField: "MetricName"
        },
        {
            ModelCategory: "Rulemodels", ModelName: "RuleModel", ItemDesc: "Data rule models created", NextStep: 'Create a rule model to handle data exceptions',
            Number: 0, IsComplete: false, IsOptional: true, Link: '/rulesets/manager', KeyField: "ModelKey", NameField: "ModelName"
        },
        {
            ModelCategory: "Filtermodels", ModelName: "FilterModel", ItemDesc: "Data filtering models created", NextStep: 'Create a model to shortcut data filtering',
            Number: 0, IsComplete: false, IsOptional: true, Link: '/filtersets/manager', KeyField: "ModelKey", NameField: "ModelName"
        },
        {
            ModelCategory: "Queries", ModelName: "QueryModel", ItemDesc: "Data queries created", NextStep: 'Create a data summary for a graph or table',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/query/managegraph', KeyField: "ModelKey", NameField: "ModelName"
        },
        {
            ModelCategory: "Schedules", ModelName: "Schedule", ItemDesc: "Schedules created", NextStep: 'Create a schedule for running data queries or dashboards',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/schedules/manager', KeyField: "SchemaKey", NameField: "SchemaName"
        },
        {
            ModelCategory: "KPIs", ModelName: "KPI", ItemDesc: "KPIs created", NextStep: 'Create a KPI to track progress and trends',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/kpis/manager', KeyField: "Key", NameField: "KPIName"
        },
        {
            ModelCategory: "Dashboards", ModelName: "Dashboard", ItemDesc: "Dashboards created", NextStep: 'Cast multiple query views into a single report. Schedule to update the report automatically',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/dashboard/manager', KeyField: "Key", NameField: "Name"
        },
        {
            ModelCategory: "Job Descriptions", ModelName: "DescModel", ItemDesc: "Job Descriptions imported", NextStep: 'Import job descriptions data to create a skills model',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/jobdescription/manager', KeyField: "ModelKey", NameField: "ModelName"
        },
        {
            ModelCategory: "Workspaces", ModelName: "Workspace", ItemDesc: "Workspaces created", NextStep: 'Create a workspace for drafting data scenarios',
            Number: 0, IsComplete: false, IsOptional: false, Link: '/scenario/manager', KeyField: "ModelKey", NameField: "ModelName"
        },
    ]
};
export default GetStatusInit;