
import { useContext, useEffect, useState, ChangeEvent } from 'react'
import { StartupContext } from '../../../../App';
import { useParams } from 'react-router-dom';
import { LeaderModel, KPI, KPIValue, KPIFilter, BasicQuery } from '../../../../type';
import ImportAndSetStateGeneric from '../../../../functions/Import/ImportAndSetStateGeneric';
import GenericDropdown from '../../../../functions/Dropdown_Generic';
import ChangeParamSelectorbyIndex from '../../../../functions/ElementSelect/ChangeParamSelectorbyIndex';
import ChangeSelectorbyIndex from '../../../../functions/ElementSelect/ChangeSelectorbyIndex';
import GenericBackendCall from '../../../../functions/Import/GenericBackendCall';
import IsInObject from '../../../../functions/Object Functions/IsInObject';
import DateString from '../../../../functions/Date Functions/DateString';
import ObjectSort from '../../../../functions/ObjectSort';
import FilterData from '../../../../functions/FilterData';
import ChartSample from '../../../../charts/sample/ChartSample';
import GetObjectField from '../../../../functions/Object Functions/GetObjectField';
import SerializeDataFrame from '../../../../functions/Import/SerializeDataFrame';
import BackendDataisError from '../../../../functions/QueryFunctions/BackendDataisError';
import FilterDataMultiple from '../../../../functions/Filter Functions/FilterDataMultiple';
import ConvertArrayStringstoNumber from '../../../../functions/Array Functions/ConvertArrayStringstoNumber';
import ArraySlice from '../../../../functions/Array Functions/ArraySlice';
import TableSample from '../../../../charts/sample/TableSample';
import DeletePrior from '../../../../charts/newfunctions/DeletePrior';
import GetNextValue from '../../../../functions/GetNextValue';
import GetMinMaxMean from '../../../../functions/GetMinMaxMean';
import GetObjectValue from '../../../../functions/Object Functions/GetObjectValue';

import "./css/LeaderView.css"

const LeaderView = () => {


    interface comparetype {
        MinValue: number;
        MaxValue: number;
        MeanValue: number;
        LastValue: number;
        LastDate: Date;
        Found: boolean;
    }

    let { id } = useParams()
    const { config, dispatcher, schema, user, paths, allusers, clickstyle } = useContext(StartupContext)
    const [selModel, setSelModel] = useState<LeaderModel | null>(null)
    const [leadermodels, setLeaderModels] = useState<LeaderModel[]>([])
    const [keyparams, setKeyParams] = useState({
        seluser: '', asofdate: '', selkval: '',
        selkpikey: '', selKPIfltkey: '', answer: "Value History", comparefield: "LastValue", comparefld_text: "Last Value"
    })
    const [kpis, setKPIs] = useState<KPI[]>([])
    const [kpifilters, setKPIFilters] = useState<KPIFilter[]>([])
    const [dates, setDates] = useState<any[]>([])
    const [selKVals, setSelKVals] = useState<KPIValue[]>([])
    const [fltqryDetail, setFltQryDetail] = useState<BasicQuery[]>([])
    const [comparevals, setCompareVals] = useState<comparetype[]>([])

    useEffect(() => {
        if (id) { setKeyParams({ ...keyparams, seluser: id }) }
    }, [id])

    useEffect(() => {
        if (keyparams.seluser !== "") {
            ImportAndSetStateGeneric(setLeaderModels, "", schema, paths.leader.leaderview, user, config, { leader: keyparams.seluser })
        }
    }, [keyparams.seluser])

    useEffect(() => {
        if (selModel) {
            GenericBackendCall("", schema, paths.kpi.kpiview, user, config, { modelkeys: selModel.KPIs, full: 'true' }).then(d => {
                let fulldata = d
                console.log(d)

                let kflts: any[] = []
                let alldates: any[] = []
                fulldata.forEach((kpi: KPI) => {
                    kpi.values.forEach((kv: KPIValue) => {
                        if (!IsInObject(alldates, kv.AsofDate, "Date")) {
                            alldates.push({ Key: alldates.length, Name: DateString(kv.AsofDate), Date: kv.AsofDate })
                        }
                    })
                })

                selModel.KPIFilters.forEach((kf: string) => {
                    fulldata.forEach((kpi: KPI) => {
                        let kint = IsInObject(kpi.kpiflts, kf, "Key", true)
                        if (typeof (kint) === "number" && kint > -1) {
                            kflts.push(kpi.kpiflts[kint])
                            kpi.kpiflts[kint].values.forEach((kv: KPIValue) => {
                                if (!IsInObject(alldates, kv.AsofDate, "Date")) {
                                    alldates.push({ Key: alldates.length, Name: DateString(kv.AsofDate), Date: kv.AsofDate })
                                }
                            })
                        }
                    })
                })
                let encfkeys = kflts.map((kpi: KPIFilter) => {
                    return kpi.EncodedFltKey
                })
                //get details of each kflt through basicquery paramss
                GenericBackendCall("", schema, paths.generics.genericfilterview, user, config, { model: "BasicQuery", modelkeys: encfkeys, filterfield: "EncodedFltKey" }).then(b => {
                    console.log(SerializeDataFrame(b))
                    setFltQryDetail(SerializeDataFrame(b))
                })
                setKPIFilters(kflts)
                setDates(ObjectSort(alldates, "Date", true))
                setKPIs(d)
            })
        }
    }, [selModel])



    useEffect(() => {
        let filtvals: KPIValue[] = []
        let compvals: comparetype[] = []

        if (kpifilters.length === 0) {
            kpis.forEach((kpi: KPI) => {
                let flt = FilterData(kpi.values, "AsofDate", keyparams.asofdate)
                if (flt.length === 1) {
                    filtvals.push(flt[0])
                }
                let idx = GetNextValue(kpi.values, "AsofDate", keyparams.asofdate, "Date", true)
                let [vmin, vmax, vmean] = GetMinMaxMean(kpi.values, "Value")
                if (idx > -1) {
                    compvals.push({ MinValue: vmin, MaxValue: vmax, MeanValue: vmean, LastValue: kpi.values[idx].Value, LastDate: kpi.values[idx].AsofDate, Found: true })
                } else {
                    compvals.push({ MinValue: 0, MaxValue: 0, MeanValue: 0, LastValue: 0, LastDate: new Date(), Found: false })
                }
            })
        } else {
            kpifilters.forEach((kv: KPIFilter) => {
                let flt = FilterData(kv.values, "AsofDate", keyparams.asofdate)
                if (flt.length === 1) {
                    filtvals.push(flt[0])
                }
                let idx = GetNextValue(kv.values, "AsofDate", keyparams.asofdate, "Date", true)
                let [vmin, vmax, vmean] = GetMinMaxMean(kv.values, "Value")
                if (idx > -1) {
                    compvals.push({ MinValue: vmin, MaxValue: vmax, MeanValue: vmean, LastValue: kv.values[idx].Value, LastDate: kv.values[idx].AsofDate, Found: true })
                } else {
                    compvals.push({ MinValue: 0, MaxValue: 0, MeanValue: 0, LastValue: 0, LastDate: new Date(), Found: false })
                }
            })
        }

        setSelKVals(filtvals)
        setCompareVals(compvals)
        setKeyParams({ ...keyparams, selKPIfltkey: "" })
    }, [keyparams.asofdate])



    useEffect(() => {
        if (keyparams.selKPIfltkey !== "") {
            GenericBackendCall("", schema, paths.answers.default, user, config,
                { type: keyparams.answer, kpikey: keyparams.selkpikey, kpifltkey: keyparams.selKPIfltkey, asofdate: keyparams.asofdate }, "").then(d => {
                    let returndata = SerializeDataFrame(d)
                    let charttype = "Chart"
                    if (keyparams.answer === "Factor Analysis") {
                        charttype = "Table"
                    }
                    let truthval = BackendDataisError(d)
                    if (!truthval) {
                        console.log(returndata)
                        ChangeChart(returndata, charttype)
                    }
                })
        }
    }, [keyparams.selKPIfltkey, keyparams.selkpikey, keyparams.answer])

    const ChangeChart = (data: any[], type: string) => {
        DeletePrior("#lv_divchart")
        if (type === "Chart") {
            const randcolors = ["#4872ba", "#de49a9"]
            ChartSample("Line", "#lv_divchart",
                data, [],
                ["AsofDate", ["Value"], ""], ["As of Date", "Value"],
                "Discrete", randcolors,
                [700, 500], "On", false, { format: "", rounding: 2, clr: "black", fntsize: '8px', lineclr: "black", secondarylineclr: "blue" }
            )
        } else {
            data = ObjectSort(ConvertArrayStringstoNumber(data), "Difference", true)
            console.log(data)
            if (data.length > 0) {
                let dataslice = ArraySlice(data, 5)
                TableSample(dataslice, "#lv_divchart")
            }
        }

    }



    return (<div>
        <div className='ndt_title2'>Leader View</div>
        <GenericDropdown
            data={allusers}
            keycol="id"
            namecol="email"
            promptstring="Select User:"
            className="ndt_dropdown"
            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, allusers, keyparams, setKeyParams, "seluser", "id") }}
        />
        <div style={{ display: "flex" }}>
            <div className="ldvw_box" style={{ width: '50%', minWidth: "500px" }}>
                {keyparams.seluser !== "" ? <div>
                    <GenericDropdown
                        data={leadermodels}
                        keycol="ModelKey"
                        namecol="ModelName"
                        promptstring="Select View:"
                        className="ndt_dropdown"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, leadermodels, setSelModel, "") }}
                        includeDefault={false}
                    />
                </div>
                    : <div></div>}
                {selModel ?
                    <div >
                        <GenericDropdown
                            data={dates}
                            keycol="Key"
                            namecol="Name"
                            promptstring="Select As of Date:"
                            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, dates, keyparams, setKeyParams, "asofdate", "Date") }}
                        />
                        <select style={{ marginLeft: "10px" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setKeyParams({ ...keyparams, comparefield: e.target.selectedOptions[0].attributes[0].value, comparefld_text: e.target.selectedOptions[0].attributes[1].value })
                        }}>
                            <option value="">Select Compare Type:</option>
                            <option value="LastValue" data-prefix="Last Value"> Last Value</option>
                            <option value="MinValue" data-prefix="Min Value"> Min Value</option>
                            <option value="MeanValue" data-prefix="Mean Value"> Mean Value</option>
                            <option value="MaxValue" data-prefix="Max Value"> Max Value</option>
                        </select>
                    </div>
                    : <div></div>}


            </div>
            <div style={{ marginLeft: "150px", borderRadius: "10px 10px 0px 0px", borderTop: "2px solid grey", borderLeft: "2px solid grey", borderRight: "2px solid grey" }}>
                {selModel && selKVals.length > 0 ?
                    <div style={{ display: "flex" }}>
                        <button className='ldvw_optbtn' onClick={_ => setKeyParams({ ...keyparams, answer: "Value History" })}>Value History</button>
                        <button className='ldvw_optbtn' onClick={_ => setKeyParams({ ...keyparams, answer: "Trend" })}>Trend</button>
                        <button className='ldvw_optbtn' onClick={_ => setKeyParams({ ...keyparams, answer: "Factor Analysis" })}>Factor Analysis</button>
                    </div> : <div></div>}
            </div>
        </div>

        <div id="ldvw_grid">
            <div >
                {selModel ?
                    <div>
                        <div className="ldvw_box" style={{ marginTop: '30px', height: "500px" }}>
                            {selKVals.map((val: KPIValue, n: number) => {
                                let flt = FilterDataMultiple(fltqryDetail, ["EncodedFltKey"], [val.EncodedFltKey], "and", false, true)
                                let filter1 = ""
                                let filter2 = ""
                                if (flt.length > 0) {
                                    filter1 = flt[0].FilterValue1
                                    if (flt[0].FilterValue2 !== "") {
                                        filter2 = ", " + flt[0].FilterValue2
                                    }
                                }
                                let ucolor = "";
                                if (keyparams.selkpikey === val.KPIName) {
                                    ucolor = "rgb(220,240,190)"
                                }
                                let othval = Math.round(GetObjectValue(comparevals[n], keyparams.comparefield) * 100) / 100
                                let diff = Math.round((val.Value - othval) * 100) / 100
                                return (<div key={n}
                                    className='ldvw_valitm'
                                    style={{ height: "100px", background: ucolor }}
                                    onClick={_ => { setKeyParams({ ...keyparams, selkval: String(val.KPIFltName), selkpikey: val.KPIName, selKPIfltkey: String(val.KPIFltName) }) }}
                                >
                                    <div style={{ display: "flex", width: '80%' }}>
                                        <div className='ldvw_valsubitm' style={{ fontSize: "19px", fontWeight: "bold" }}>{GetObjectField(val.KPIName, kpis, "Key", "KPIName")}:</div>
                                        <div className='ldvw_valsubitm'>{filter1}</div>
                                        <div className='ldvw_valsubitm'>{filter2}</div>
                                    </div>
                                    <div style={{ marginLeft: "5px", display: "flex" }}>
                                        <div>{keyparams.comparefld_text}: {othval}</div>
                                        {keyparams.comparefield === "LastValue" ? <div style={{ marginLeft: "5px" }}> ({DateString(comparevals[n].LastDate)})</div> : <div></div>}

                                    </div>
                                    <div style={{ fontStyle: "italic" }}>
                                        Difference: {diff}
                                        <div className='ldvw_valsubitm ldvw_right' style={{ fontSize: "26px", fontStyle: "normal" }}>{parseFloat(String(val.Value))}</div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div> : <div></div>}
            </div>
            <div>
                <div id="ldvw_chartwrap" style={{ border: "2px solid grey", width: '720px' }}>
                    <div id="lv_divchart" style={{ borderRadius: "10px" }}></div>
                </div>
            </div>

        </div>
    </div>)
};
export default LeaderView;