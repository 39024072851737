import GetUniquesFromObjectAttribute from "../../functions/GetUniquesFromObjectAttribute";
import IsInObject from "../../functions/Object Functions/IsInObject";
import { BasicQuery } from "../../type";


interface Hovertype {
    fieldname: string;
    values: string[];
}


const HoverData = (entry: BasicQuery, detaildata: BasicQuery[]) => {
    let filtdata: any[] = [];
    let detailflds: any[] = GetUniquesFromObjectAttribute(detaildata, "DetailFieldName")

    let newdetailflds: Hovertype[] = detailflds.map(df => {
        return { fieldname: df[0], values: [] }
    })

    detaildata.forEach((dt: BasicQuery) => {
        if (dt.Grouping === entry.Grouping && dt.Variable === entry.Variable) {
            filtdata.push([dt.DetailFieldName, dt.DetailValue])
            let idx: any = IsInObject(detailflds, dt.DetailFieldName, "0", true)
            newdetailflds[idx].values.push(dt.DetailValue)
        }
    })


    return (newdetailflds)

};
export default HoverData