

const ChangeElementCollectionAttr = (matchid: string, changeattribute: string, elemclassname: string, selvalue: string,
    defaultvalue: string, allow_multiselect: boolean): HTMLElement | null => {

    //done by element id
    let matchedelem: HTMLElement | null = null;

    let e_coll = document.getElementsByClassName(elemclassname)
    Array.from(e_coll).forEach((e: Element) => {
        let lmnt: HTMLElement | null = document.getElementById(e.id)
        if (lmnt) {
            if (lmnt.id === matchid) {
                ChooseAttribute(changeattribute, lmnt, selvalue)
                matchedelem = lmnt

            } else {
                if (!allow_multiselect) {
                    ChooseAttribute(changeattribute, lmnt, defaultvalue)
                }
            }
        }
    })
    return (matchedelem)
};

export default ChangeElementCollectionAttr;


const ChooseAttribute = (changeattr: string, lmnt: HTMLElement, changeto: string) => {
    if (changeattr === "backgroundColor") {
        lmnt.style.backgroundColor = changeto
    } else if (changeattr === "color") {
        lmnt.style.color = changeto
    } else if (changeattr === "border") {
        lmnt.style.border = changeto
    }
}