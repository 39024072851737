import IsInVector from "../IsInVector";

const FilterDataMultiple = (data, onFields, filterValues, and_or="and", except=false, acceptblankfilters=false) => {

	//onfield can be an array for nested fields
	//filtervalues is an array for each ONFIELD
    //all = "*" or ""
    
	let returndata = []

    //convert to arrays if not already
	if (typeof(onFields)!=="object"){
		onFields=[onFields]
	}	
    if (typeof(filterValues)!=="object"){
		filterValues=[filterValues]
	}
    console.log(filterValues)

    
    let newfiltervals = []
    filterValues.forEach(f=>{
        if (typeof(f)!=="object"){
            newfiltervals.push([f])
        } else{
            newfiltervals.push(f)
        }
    })

	function checkagainst(dataval, i){
		if (except){
			if (!IsInVector(newfiltervals[i],dataval,false)){
				return true
			}
		} else {
			if (IsInVector(newfiltervals[i],dataval,false)){
				return true
			}
		}
        return false
	};

	data.forEach(d => {
        //multiple fields to check
        let allchecks = []
        onFields.forEach((f,i)=>{
            
            if (!acceptblankfilters && (newfiltervals[i]=="*" || newfiltervals[i]=="")){
                allchecks.push(true)
            } else{
               allchecks.push(checkagainst(d[f],i))
            }
        })

        let valid = true;
        if (and_or==="and"){
            //AND: all must be true
            allchecks.forEach((ac)=>{
                if (!ac){
                    valid = false
                }
            })
        } else {
            //OR: one can be true
            valid = false;
            allchecks.forEach((ac)=>{
                if (ac){
                    valid = true
                }
            })
        }
        if (valid){
            returndata.push(d)
        }
	})
    
	return(returndata)
};

export default FilterDataMultiple;