import { useContext, useEffect, useState, ChangeEvent, useMemo } from "react";
import { StartupContext } from "../../../App";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import FilterData from "../../../functions/FilterData";
import { FieldMap, GroupNumericRule, GroupSet, GroupSetValue, GroupDateRule, TableModel } from "../../../type";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import FieldDropdown from "../fields/subfunctions/FieldDropdown";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { FieldMapInit, GroupSetInit } from "../../../InitTypes";
import { Modal } from "react-bootstrap";
import RemovefromArray from "../../../functions/Array Functions/RemovefromArray";
import FieldsImport from "../../../data/Fields/Fields_Import";
import GetFieldName from "../../../functions/GetFieldName";
import GroupFieldUpload from "../../../data/Fields/GroupField_Upload";
import IsInObject from "../../../functions/Object Functions/IsInObject";
import GetUniquesFromObjectAttribute from "../../../functions/GetUniquesFromObjectAttribute";
import GroupSetsNumberMgr from "./subfunctions/GroupSetsNumberMgr";
import GroupSetsDateMgr from "./subfunctions/GroupSetsDateMgr";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import ModelCategoryDropdown from "../../../functions/Dropdowns/ModelCategoryDropdown";
//import { useParams } from "react-router-dom";

import "./css/GroupSetsManager.css"
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import StringAfterDelimeter from "../../../functions/String Functions/StringAfterDelimeter";


interface grouping {
    id: number;
    Group: string;
    Values: string[];
}

const GroupSetsManager = () => {

    //let { id } = useParams()

    let { config, schema, user, dispatcher, paths, appcolors, clickstyle } = useContext(StartupContext)

    let [fields, setFields] = useState<FieldMap[]>([])
    let [grpflds, setGrpFlds] = useState<FieldMap[]>([])
    let [selGrpFld, setSelGrpFld] = useState<FieldMap | null>(FieldMapInit[0])
    let [fldVals, setFldVals] = useState<GroupSetValue[]>([])
    let [tblMdls, setTblMdls] = useState<TableModel[]>([])
    let [grpVals, setGrpVals] = useState<grouping[]>([])
    let [selgrpval, setSelGrpval] = useState<grouping | null>(null)
    let [groupnumrules, setGroupNumRules] = useState<GroupNumericRule[]>([])
    let [groupdaterules, setGroupDateRules] = useState<GroupDateRule[]>([])

    let [scrollposition, setScrollPosition] = useState<number>(0)

    let [initstyles, setInitStyles] = useState<any[]>([])
    let [valstyles, setValStyles] = useState<any[]>([])
    let selstyle = clickstyle.itmSelected
    let unselstyle = clickstyle.itmUnselected
    let selvalstyle = { backgroundColor: "blue" }
    let unselvalstyle = { backgroundColor: "" }

    let [gsetparams, setGSetParams] = useState<any>({ handleothervalues: '', othergroupname: '', lastupdatedate: new Date() })
    interface paraminit {
        refresh: number; fldrefresh: number; newgfldname: string;
        onfldkey: string, onfldname: string; onfldtype: string; onfldattr: string;
        modelcategory: string; newgroupname: string, oldgroupname: string; selgroupIdx: number;
        isrenamevisible: boolean; isdeletevisible: boolean; issavevisible: boolean; ishandlevisible: boolean;
        ruletype: string; rulewarning: string; setrule: boolean; datatype: string;
    }
    let [params, setParams] = useState<paraminit>({
        refresh: 0, fldrefresh: 0, newgfldname: "",
        onfldkey: "", onfldname: "", onfldtype: "", onfldattr: "",
        modelcategory: "", newgroupname: "", oldgroupname: "", selgroupIdx: -1,
        isrenamevisible: false, isdeletevisible: false, issavevisible: false, ishandlevisible: false,
        ruletype: "range", rulewarning: "", setrule: false, datatype: ""
    });

    useEffect(() => {
        FieldsImport("*", schema, config, dispatcher, false).then(d => { setFields(d) })
        ImportAndSetStateGeneric(setTblMdls, "*", schema, paths.tables.tablemodelview, user, config, {})

    }, [])

    useEffect(() => {
        setSelGrpFld(FieldMapInit[0])
    }, [params.datatype])

    useEffect(() => {
        if (selGrpFld) {
            setParams({
                ...params, onfldkey: selGrpFld.OnField, modelcategory: selGrpFld?.ModelCategory,
                onfldname: StringAfterDelimeter(selGrpFld.OnField, "__"),
                onfldattr: GetFieldName(fields, selGrpFld.OnField, "Key", "AttrName"),
            })
        }
    }, [selGrpFld])

    useEffect(() => {
        if (selGrpFld) {
            let newvalpromise = GenericBackendCall("*", schema, paths.fields.allvaluesview, user, config,
                { date: gsetparams.lastupdatedate, fieldkey: selGrpFld.Key })
            newvalpromise.then(d => console.log(d))
        }
    }, [selGrpFld?.Key])

    useEffect(() => {
        setGrpFlds(FilterData(fields, "FieldCategory", "Grouping"))
    }, [fields])

    useEffect(() => {
        setInitStyles(CreateArray(unselvalstyle, fldVals.length))
    }, [params.fldrefresh])

    useEffect(() => {
        //save group name when group vals changes
        setParams({ ...params, oldgroupname: params.newgroupname })
    }, [selgrpval])

    useEffect(() => {
        setValStyles(CreateArray(unselvalstyle, fldVals.length))
        setParams({ ...params, refresh: params.refresh + 1 })
    }, [initstyles, selgrpval?.id])

    useEffect(() => {
        //import unique values from existing set
        if (params.onfldkey !== "") {
            let promise = GenericBackendCall("", schema, paths.generics.genericuniques, user, config, { flddata: params.onfldkey }, "")
            promise.then(d => {
                let fvals = SerializeDataFrame(d, [params.onfldattr]);
                if (selGrpFld) {
                    let importpromise = GenericBackendCall(selGrpFld.GroupFieldKey, schema, paths.groupsets.groupsetvalueview, user, config, {})
                    importpromise.then((importdata: GroupSet) => {
                        console.log(importdata)
                        let fulldata = importdata.values

                        setGSetParams({ handleothervalues: importdata.HandleOtherValues, othergroupname: importdata.OtherGroupName, lastupdatedate: importdata.LastUpdatedDate })

                        //create premade groupvals
                        let groups = GetUniquesFromObjectAttribute(fulldata, "Groupvalue", true)
                        let grpvals = groups.map((g: string, i: number) => ({ id: i, "Group": g, "Values": [] }))
                        fulldata.forEach((x: GroupSetValue) => {
                            let idx = groups.indexOf(x.Groupvalue)
                            grpvals[idx].Values.push(x.Value)
                        })

                        //merge premade fvals (merging old w/ new value list)
                        fvals.map((f: any) => {
                            if (!IsInObject(importdata.values, f[params.onfldattr], "Value")) {
                                fulldata.push({ id: 0, GroupSetName: "", Groupvalue: "", Value: f[params.onfldattr] })
                            }
                        })
                        setGrpVals(grpvals)
                        setFldVals(fulldata)

                        setGroupNumRules(importdata.numericrules)
                        setGroupDateRules(importdata.daterules)
                    })
                }
            })
            setParams({ ...params, fldrefresh: params.fldrefresh + 1 })
        }
    }, [params.onfldkey])

    //-----backend
    const NewFieldSave = () => {

        let gspromise = GenericBackendCall(params.onfldkey, schema, paths.groupsets.groupsetpost, user, config, { uploadvalues: String(false) })

        gspromise.then(d => {
            console.log(d)
            let newfld: FieldMap = {
                ...FieldMapInit[0], Key: '', SchemaName: schema, AttrName: '',
                "FieldCategory": "Grouping", "ModelCategory": params.modelcategory,
                "HeaderName": params.newgfldname, "FieldName": params.newgfldname,
                "ModelName": "_groupedfield", "TrackChanges": false, "OnField": params.onfldkey,
                "GroupFieldKey": d.SetKey, "DataType": params.datatype,
            }

            GenericBackendCall("", schema, paths.fields.fieldpostnew, user, config, newfld, "mgf_divsavenew").then(d => {

            })
        })
    };

    const DeleteField = () => {
        if (selGrpFld) {
            GenericBackendCall(selGrpFld.Key, schema, paths.fields.fielddelete, user, config, {}, "mgf_divdel")
        }
    };

    const AddGrpVal = () => {
        grpVals.push({ id: grpVals.length, "Group": "Grouping" + grpVals.length, "Values": [] })
        setGrpVals(grpVals)
        setParams({ ...params, refresh: params.refresh + 1 })
    };



    const SelectValue = (flditm: GroupSetValue, i: number) => {
        if (selgrpval) { //do nothing if no grouping is selected
            let vs = valstyles
            let newallvals = fldVals
            let newgvals = selgrpval.Values
            if (valstyles[i].backgroundColor === selvalstyle.backgroundColor) {
                //unselect
                vs[i] = unselvalstyle
                newallvals[i].Groupvalue = ""

                newgvals = RemovefromArray(selgrpval.Values, flditm.Value, "value")
            } else {
                //select
                vs[i] = selvalstyle
                newallvals[i].Groupvalue = selgrpval.Group

                newgvals.push(flditm.Value)


            }
            setFldVals(newallvals)
            setValStyles(vs)
            setSelGrpval({ ...selgrpval, Values: newgvals })

            setParams({ ...params, refresh: params.refresh + 1 })
        }
    };

    const SelectGroup = (grp: grouping, i: number) => {
        setSelGrpval(grp)
        setParams({ ...params, oldgroupname: grp.Group, newgroupname: grp.Group, refresh: params.refresh + 1, selgroupIdx: i })
    };


    const ClickRename = () => {
        if (selgrpval) {
            if (params.oldgroupname !== "") {
                let newfldvals = fldVals
                fldVals.map((fld: GroupSetValue, i: number) => {
                    if (fld.Groupvalue === params.oldgroupname) {
                        newfldvals[i].Groupvalue = params.newgroupname
                    }
                })
                setFldVals(newfldvals)
            }
            setSelGrpval({ ...selgrpval, Group: params.newgroupname })
            let newgrps = grpVals
            newgrps[selgrpval.id].Group = params.newgroupname
            setGrpVals(newgrps)

        }
    }

    const ClickFldDiv = (fld: GroupSetValue, i: number) => {
        if (fld.Groupvalue === selgrpval?.Group || fld.Groupvalue === "") {
            SelectValue(fld, i)
        } else {
            console.log("cannot overwrite")
        }
    }

    const ClickSaveSet = () => {
        if (selGrpFld) {
            console.log(selGrpFld)
            let grpset: GroupSet = {
                ...GroupSetInit[0],
                FieldName: selGrpFld.Key,
                HandleOtherValues: gsetparams.handleothervalues,
                OtherGroupName: gsetparams.othergroupname,
                values: fldVals
            }
            GroupFieldUpload(grpset, schema, user, config, "gsm_divsave")
        }
    }

    const ClickRemove = () => {
        setSelGrpval(null)
        grpVals.splice(params.selgroupIdx, 1)
        setParams({ ...params, selgroupIdx: -1 })
    }

    //jsx
    const GroupValuesDiv = () => {
        return (
            <div className="ndt_innerbox" id="mgf_grpbox">

                <div style={{ fontSize: "20px", marginLeft: "10px", marginTop: "3px", marginRight: "10px" }}>Grouping Values</div>
                <div style={{ display: "flex" }}>
                    <button className="ndt_btn1" onClick={_ => AddGrpVal()}>Add Grouping</button>
                    {selgrpval ? <div>
                        <button className="ndt_btn1" onClick={_ => setParams({ ...params, isrenamevisible: true })}>Rename Group</button>
                        <button className="ndt_btn1" onClick={_ => ClickRemove()}>Remove Group</button>
                    </div>
                        : <div></div>}
                </div>

                {grpVals.map((grp: grouping, i: number) => {

                    let valstring = grp.Values.toString()
                    return (<div key={i} onClick={_ => SelectGroup(grp, i)}
                        className="mgf_grpitm"
                        style={selgrpval?.id === grp.id ? selstyle : unselstyle}
                    >
                        <div style={{ display: 'flex' }}>
                            Group: {grp.Group}
                        </div>
                        <div style={{ height: "60px" }}>{valstring}</div>
                    </div>)
                })
                }
            </div >
        )
    };

    const FieldValuesDiv = () => {
        return (
            <div className="ndt_innerbox" style={{ minWidth: '500px', width: '70%' }} >
                <div style={{ fontSize: '21px', letterSpacing: "1px" }}>Field Values: {params.onfldname}</div>
                <div style={{ border: "1px solid grey", width: "100%" }}>
                    <div className="mgf_flditm" style={{ fontSize: '18px', border: '1px solid grey' }}>
                        <div style={{ width: '50%' }}> Value </div>
                        <div style={{ marginLeft: '10px', width: '40%' }}> Assigned To </div>
                    </div>
                    <div id="gsm_fvalbox"
                    >
                        {fldVals.map((fld: GroupSetValue, i: number) => {
                            return (<div key={i}
                                className="mgf_flditm"
                                onClick={_ => ClickFldDiv(fld, i)}
                                style={valstyles[i]}
                            >
                                <div style={{ width: '50%' }}> {fld.Value}</div>
                                <div style={{ marginLeft: '10px', width: '40%' }}>{fld.Groupvalue}</div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        )
    };

    const ChangeE = (e: ChangeEvent<HTMLSelectElement>) => {
        setGSetParams({ ...gsetparams, handleothervalues: e.target.selectedOptions[0].attributes[0].value })
    }

    const HandleMissing = () => {
        let num_missing = 0
        fldVals.forEach((val: GroupSetValue) => {
            if (val.Groupvalue === "") {
                num_missing += 1
            }
        })
        return (<div>
            <div>Handle Missing Items ({num_missing})</div>
            <select value={gsetparams.handleothervalues}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    ChangeE(e)
                }}>
                <option value="None">Do Nothing</option>
                <option value="OtherGroup">Group remaining into "Other" category</option>
                <option value="PushEach">Push each remaining into own category</option>
            </select>
            {gsetparams.handleothervalues === "OtherGroup" ?
                <div>Name this group
                    <input type="text" value={gsetparams.othergroupname}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setGSetParams({ ...gsetparams, othergroupname: e.target.value })
                        }} />
                </div>
                : <div></div>}
        </div >)
    }

    let handlemissingMemo = useMemo(() => { return HandleMissing() }, [gsetparams.handleothervalues, gsetparams.othergroupname])

    return (
        <div className="ndt_canvas">
            <div className="ndt_title2">Manage Grouped Fields</div>
            <div className="ndt_gridbox">
                <div className="ndt_title3">Grouped Fields</div>
                <div id="mgf_grptypdiv" style={{ width: "140px" }}>
                    <select title="Select Grouping" className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, datatype: e.target.selectedOptions[0].attributes[0].value }) }}>
                        <option>Select Grouping:</option>
                        <option value="Text">Text</option>
                        <option value="Numeric">Numeric</option>
                        <option value="Date">Dates</option>
                    </select>
                </div>

                <GenericDropdown
                    data={grpflds}
                    keycol="Key"
                    namecol="FieldName"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) =>
                        ChangeSelectorbyIndex(e, grpflds, setSelGrpFld, "", null)}
                    promptstring="Select Grouped Field:"
                    divID="mgf_fld_ddown"
                    includeDefault={true}
                    defaultstring="Create new grouping"
                    filterfield="DataType"
                    filtervalue={params.datatype}
                />

            </div>
            {selGrpFld ?
                <div>
                    {selGrpFld.AttrName !== "" ? <div className="ndt_gridbox">
                        <div className="ndt_title3">Current Grouping</div>

                        <div style={{ display: "flex" }}>
                            <div style={{ width: '40%' }}>
                                <div>Grouping: {selGrpFld.FieldName}</div>
                                <div>On: {params.modelcategory} - {params.onfldname}</div>
                            </div>
                            <div className="ndt_subinner">
                                <div>Handle Remaining Values:</div>
                                <div style={{ width: '250px', border: '1px solid white', padding: '3px', backgroundColor: appcolors.colorprimarybright }}>
                                    {(() => {
                                        switch (gsetparams.handleothervalues) {
                                            case "OtherGroup": return <div>Currently: Group {gsetparams.othergroupname}</div>
                                            case "PushEach": return <div>Currently: Pass Unique Values</div>
                                            case "None": return <div>Currently: Do Nothing</div>
                                            default: return <div>Currently: Do Nothing</div>;
                                        }
                                    })()}
                                </div>
                                {<button className="ndt_btn1" onClick={_ => setParams({ ...params, ishandlevisible: true })}>Change</button>}
                            </div>
                        </div>
                        <div style={{ height: '550px' }}>

                            {params.datatype === "Text" ? <div>
                                <div style={{ display: "flex" }}>
                                    <GroupValuesDiv />
                                    <FieldValuesDiv />
                                </div>

                                <button className="ndt_btn1" onClick={_ => { setParams({ ...params, issavevisible: true }) }}>Save Grouping</button>
                                <button className="ndt_btn2" onClick={_ => setParams({ ...params, isdeletevisible: true })}>Delete</button>

                            </div> : <div>
                                {params.datatype === "Numeric" ?
                                    <GroupSetsNumberMgr groupfield={selGrpFld} groupnumrulesInit={groupnumrules} />
                                    : <GroupSetsDateMgr groupfield={selGrpFld} groupdaterulesInit={groupdaterules} />}
                            </div>}
                        </div>

                    </div> : <div></div>}
                </div>
                :
                <div className="ndt_gridbox">
                    <div style={{ fontSize: "22px", display: "flex" }}>New {params.datatype} Grouping</div>

                    <div id="mgf_selgrpfldnew" style={{ display: "flex", width: "200px" }}>
                        <ModelCategoryDropdown
                            change={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, modelcategory: e.target.selectedOptions[0].attributes[0].value }) }}
                            tables={tblMdls}
                            className="ndt_dropdown" />
                        <FieldDropdown
                            value={params.onfldkey}
                            className="ndt_dropdown"
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                setParams({
                                    ...params,
                                    onfldkey: e.target.selectedOptions[0].attributes[0].value,
                                    onfldname: e.target.selectedOptions[0].attributes[1].value,
                                    //modelcategory: e.target.selectedOptions[0].attributes[2].value,
                                    onfldattr: e.target.selectedOptions[0].attributes[3].value
                                })
                            }}
                            filterfield={["DataType", "ModelCategory"]}
                            filtervalue={[params.datatype, params.modelcategory]}
                            includecategory={false}
                        />
                        <div style={{ fontSize: "20px", marginLeft: "10px" }}>{params.onfldname}</div>
                    </div>

                    <div style={{ "marginTop": "10px" }}>
                        <div>Field Name</div>
                        <input id="mgf_newgrpname" type="text" onChange={e => setParams({ ...params, newgfldname: e.target.value })} />
                    </div>

                    {params.newgfldname !== "" && params.onfldkey !== "" ?
                        <div style={{ margin: '5px' }}>
                            <button id="mgf_newfldsavebtn" className="ndt_btn1" onClick={_ => NewFieldSave()}>Set New Field</button>
                            <div id="mgf_divsavenew"></div>
                        </div> : <div></div>}
                </div>
            }

            <Modal show={params.isdeletevisible}>
                <div className="ndt_modal">
                    <div>Delete Field {selGrpFld?.FieldName}?</div>
                    <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { DeleteField() }}>Delete</button>
                    <div id="mgf_divdel"></div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdeletevisible: false })}>Close</button>
                </div>
            </Modal>

            <Modal show={params.isrenamevisible}>
                <div className="ndt_modal">
                    <div>Rename Group {selgrpval?.Group}?</div>
                    <input type="text" placeholder={selgrpval?.Group} onChange={(e: ChangeEvent<HTMLInputElement>) => setParams({ ...params, newgroupname: e.target.value })} />
                    <button onClick={_ => ClickRename()}>Submit</button>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, isrenamevisible: false })}>Close</button>
                </div>
            </Modal>

            <Modal show={params.issavevisible}>
                <div className="ndt_modal">
                    <div>Do you want to save {selGrpFld?.FieldName}?</div>
                    <button onClick={_ => ClickSaveSet()} className="ndt_btn1" style={{ width: "200px", margin: "15px" }}>Save</button>
                    <div id="gsm_divsave"></div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, issavevisible: false })}>Close</button>
                </div>
            </Modal>

            <Modal show={params.ishandlevisible}>
                <div className="ndt_modal">
                    {handlemissingMemo}
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, ishandlevisible: false })}>Close</button>
                </div>
            </Modal>

        </div>
    )
};
export default GroupSetsManager;


function CreateArray(value: any, n: number): any[] {
    return Array.apply(null, Array(n)).map(function (x, i) { return value; })
}