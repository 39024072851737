import { ChangeEvent, useState } from "react";
import { FilterModel, FilterSet, FilterValue, Metric, QueryModel, queryparams } from "../../../type";
import { FilterModelInit, FilterSetInit, FilterValueInit, MetricInit, QueryModelInit, queryparamsInit } from "../../../InitTypes";
import DateString from "../../../functions/Date Functions/DateString";
import GetObjectValue from "../../../functions/Object Functions/GetObjectValue";

const QueryAuto = () => {

    let [queryModel, setQueryModel] = useState<QueryModel>(QueryModelInit[0])
    let [metric, setMetric] = useState<Metric>(MetricInit[0])
    let [filterModel, setFilterModel] = useState<FilterModel>(FilterModelInit[0])

    let showflds = ["XFieldName", "GroupFieldName", "Period", "NPeriods", "IPeriod"]
    let showmetrflds = ["ReportTypeNumerator", "Measurement"]


    const ParseText = (txt: string) => {
        txt = String(txt + " ").toLowerCase().replace(",", " ").replace(".", " ").replace("  ", " ")

        let [metr_rptval,] = GetTextWord(txt, ["count of "])
        let [qry_xfld,] = GetTextWord(txt, ["by "])
        let [qry_gfld,] = GetTextWord(txt, ["group by"])
        let [flt_fld1, fltidx1] = GetTextWord(txt, ["where", 'xxx'])
        let [flt_val1,] = GetTextWord(txt, [""])
        let [flt_fld2,] = GetTextWord(txt, ["and"])
        let [flt_val2,] = GetTextWord(txt, ["and"], fltidx1)


        setQueryModel({ ...queryModel, XFieldName: qry_xfld, GroupFieldName: qry_gfld })
        setMetric({ ...metric, ReportTypeNumerator: metr_rptval })

        let fltmdl: FilterModel = FilterModelInit[0]
        let fval1: FilterValue = { ...FilterValueInit[0], Value: flt_val1 }
        let flt1: FilterSet = { ...FilterSetInit[0], Field: flt_fld1, values: [fval1] }



    }



    return (
        <div className="ndt_canvas">
            <div className="ndt_title3">QueryAuto</div>

            <div className="ndt_gridbox">
                <div>Prompt:</div>
                <textarea style={{ width: "50%" }} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { ParseText(e.target.value) }} />
            </div>

            <div className="ndt_gridbox">
                <div className="ndt_innerbox">
                    {showflds.map((d: string, i: number) => {
                        return (<div key={i + "a"}>{d}: {DateString(GetObjectValue(queryModel, d))}</div>)
                    })}
                    {showmetrflds.map((d: string, i: number) => {
                        return (<div key={i + "b"}>{d}: {DateString(GetObjectValue(metric, d))}</div>)
                    })}
                </div>
            </div>
        </div>)
};
export default QueryAuto

const GetTextWord = (txt: string, keystrings: string[], after: number = 0, print: boolean = false): [string, number] => {
    //filter after keyword
    txt = txt.substring(after, txt.length)

    let [from_l, from_r] = WordOptionsCheck(txt, keystrings);
    if (print) {
        console.log("from", from_l, from_r)
    }
    if (from_l >= 0) {

        let next = txt.substring(from_r + 1, txt.length).search(" ") + from_r + 1
        if (print) {
            console.log("next", next)
        }
        return [txt.substring(from_r, next), from_r]
    } else {
        return ["_", -1]
    }
}

const WordOptionsCheck = (txt: string, keystrings: string[]) => {
    let nearest = 10000
    let selstring = ""
    keystrings.map((kstring: string) => {
        let sval = txt.search(kstring)
        if (sval < nearest) {
            nearest = sval
            selstring = kstring
        }
    })
    let from_l = 0
    if (nearest < 10000) { from_l = nearest }
    let from_r = from_l + String(selstring).length

    return [from_l, from_r]
}