import { QueryModel } from '../../../../type';

const QueryModelDropdown = (props: any) => {

	const Models: QueryModel[] = props.models;

	return (
		<select id="dropdown_qrymodels" onChange={props.change} >
			<option key='1' value={0} data-name={"none"}>
				Select Model Name
			</option>


			{Models ?
				Models.map((d, i) => {
					return (
						<option value={i} data-name={d.ModelKey} key={"r" + i} >
							{d.ModelName}
						</option>
					)
				})

				: <option key='-1' value={'-1'} data-name={"none"}>
					None - Make a new chart!
				</option>}
		</select>
	)
};
export default QueryModelDropdown;