
import { useState, FormEvent, ChangeEvent, useContext, useEffect } from "react";
import { FieldMap, TableModel } from "../../../type";
import CustomTable from "../../../functions/EditableTable/CustomTable";
import FilterData from "../../../functions/FilterData";
import FieldsUploadNew from "../../../data/Fields/Fields_UploadNew";
import FieldsImport from "../../../data/Fields/Fields_Import";
import HeadersUpload from "../../../data/Fields/Headers_Upload";
import { StartupContext } from "../../../App";
import ModelCategoryDropdown from "../../../functions/Dropdowns/ModelCategoryDropdown";
import FieldsRemoveAddtl from "../../../data/Fields/Fields_RemoveAddtl";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import { FieldMapInit } from "../../../InitTypes";
import { useParams } from "react-router-dom";
import StringToBoolean from "../../../functions/String Functions/StringToBoolean";
import ObjectSort from "../../../functions/ObjectSort";
import { Link } from "react-router-dom";
import GetHardcodedTables from "../../../context/GetHardcodedTables";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import "./css/FieldsManager.css"

const FieldsManager = () => {

    const { id } = useParams()

    let { config, dispatcher, schema, paths, user } = useContext(StartupContext)
    let fmInit: FieldMap = {
        ...FieldMapInit[0], SchemaName: schema,
        FieldCategory: "Attribute", DataType: "Text", TrackChanges: false
    }


    let [fldFormData, setFldFormData] = useState<FieldMap>(fmInit);
    let [keyparams, setKeyParams] = useState<any>({ tblkey: '' })
    let [refresh, setRefresh] = useState<number>(0)
    let [_, setChangeState] = useState<number>(0)
    let [fieldsFilt, setFieldsFilt] = useState<FieldMap[]>([])
    let [fields, setFields] = useState<FieldMap[]>([])
    let [tbls, setTbls] = useState<TableModel[]>([])
    let [colshide, setColsHide] = useState<string[]>([])
    let [primaryTbls, setPrimaryTbls] = useState<string[]>([])

    const onFormChange = (e: ChangeEvent<HTMLInputElement>, onfield: string): void => {
        setFldFormData({ ...fldFormData, [onfield]: e.target.value });
    };

    useEffect(() => {
        if (user.level !== "admin") {
            setColsHide(['GroupFieldKey', 'IsTableModel', 'ConditionNote'])// 'IsNewField',
        }
        setPrimaryTbls(GetHardcodedTables(true))
    }, [])

    useEffect(() => {
        if (id) {
            setFldFormData({ ...fldFormData, ModelCategory: id })
        }
    }, [id])


    useEffect(() => {
        console.log('refresh')

        FieldsImport("*", schema, config, dispatcher, true).then(d => { setFields(ObjectSort(d, "DateChanged")) })
        ImportAndSetStateGeneric(setTbls, "", schema, paths.tables.tablemodelview, user, config, {})
    }, [refresh])

    const SubmitForm = () => {
        //e.preventDefault();
        //field category is attribute
        let promise = FieldsUploadNew(fldFormData, schema, config, "fldadd_div")
        promise.then(_ => {
            setRefresh(refresh + 1)
            setFldFormData({ ...fmInit, ModelCategory: fldFormData.ModelCategory })
        })

    };

    useEffect(() => {
        let fltflds = FilterData(fields, "ModelCategory", fldFormData.ModelCategory)
        setFieldsFilt(fltflds)
        let tblkey = GetObjectField(fldFormData.ModelCategory, tbls, "ModelName", "ModelKey")
        setKeyParams({ ...keyparams, tblkey: tblkey })
    }, [fldFormData.ModelCategory, fields])

    console.log()

    return (
        <div className="ndt_canvas">
            <div className="ndt_gridbox ndt_header" style={{ marginBottom: '20px' }}>
                <div className="ndt_title2">Fields by Table</div>
                <div id="fv_modelddown" style={{ display: "flex" }}>
                    <ModelCategoryDropdown className="fv_mcddown"
                        tables={tbls}
                        includesecondary={false}
                        change={(e: ChangeEvent<HTMLSelectElement>) => setFldFormData({ ...fldFormData, ModelCategory: e.target.value })} />

                </div>
            </div>
            <div id="fv_newfldbox" className="ndt_gridbox" >
                <div className="ndt_title4" style={{ marginBottom: '10px' }}>Add New field to {fldFormData.ModelCategory}</div>
                <div>
                    <div id="fv_newfldform">
                        <div style={{ width: "250px" }}>
                            <div>Field Name (Fixed)</div> <input type="text" style={{ height: "32px" }} value={fldFormData.FieldName} onChange={e => onFormChange(e, "FieldName")} />
                        </div>
                        <div style={{ width: "250px" }}>
                            <div>Header Name</div> <input type="text" style={{ height: "32px" }} value={fldFormData.HeaderName} onChange={e => onFormChange(e, "HeaderName")} />
                        </div>
                        <div style={{ width: "200px" }}>
                            <div >Data Type</div>
                            <select style={{ height: "31px" }} value={fldFormData.DataType} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setFldFormData({ ...fldFormData, DataType: e.target.selectedOptions[0].attributes[0].value }) }} >
                                <option value="Text">Text</option>
                                <option value="Numeric">Numeric</option>
                                <option value="Date">Date</option>
                            </select>
                        </div>
                        <div style={{ width: "200px" }}>
                            <div>Track field value changes?</div>
                            <select style={{ height: "31px" }} value={String(fldFormData.TrackChanges)} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setFldFormData({ ...fldFormData, TrackChanges: StringToBoolean(e.target.selectedOptions[0].attributes[0].value) }) }} >
                                <option value={"False"}>Do Not Track</option>
                                <option value={"True"}>Track</option>
                            </select>
                        </div>
                        <div style={{ marginTop: "20px", fontSize: "20px" }}>
                            {keyparams.tblkey !== "" ? <Link id="tmgr_fldopt" to={"/tables/manager/" + keyparams.tblkey}
                                className="tmgr_sidebtn"
                                style={{ color: "lightblue" }}>Check Table</Link>
                                : <div></div>
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <button className="ndt_btn1" onClick={_ => { SubmitForm() }}> Add New Field</button>
                    </div>

                </div>
                <div id="fldadd_div"></div>
            </div>
            <div className="ndt_gridbox" style={{ height: '620px', overflowX: "scroll" }}>
                <CustomTable
                    data={fieldsFilt}
                    colsHide={colshide}
                    rowcount={10}
                    modelcategory="FieldMapping"
                    keycol="Key"
                    hardcodedfields={[]}
                    optionalchoices={[['DataType', 'Text'], ['DataType', 'Numeric'], ['DataType', 'Date'],
                    ['TrackChanges', "True"], ['TrackChanges', "False"]]}
                    subModelObj={["HeaderNames", "id", "HeaderName", "Key"]}
                    adddel_fn={HeadersUpload}
                    delete_fn={FieldsRemoveAddtl}
                    changestate={setChangeState}
                    editable={{
                        "Key": false, "ModelName": false, "ModelCategory": false, "AttrName": false, "FieldName": false, "HeaderName": true,
                        "Datatype": true, "FieldCategory": false, "TrackChanges": true, "Headers": true
                    }}
                    fields={[]}
                    backendstring={paths.fields.fieldpostchange}
                    shape={[1600, 600]}
                />
            </div>
            <br />

        </div>
    )
};

export default FieldsManager;
