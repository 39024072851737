import { useState, useEffect, useContext, ChangeEvent, useMemo } from "react";
import { AllValues, FieldMap, Key_Name } from "../../../type";
import DatePicker from "react-date-picker";
import CustomTable from "../../../functions/EditableTable/CustomTable";
import { StartupContext } from "../../../App";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import DateSort from "../../../functions/DateSort.js";
import ImportAndSetState from "../../../functions/Import/ImportAndSetState";
//import DatePicker from "react-datepicker";
import FieldsUploadSample from "../../../data/Fields/Fields_UploadSample";
import FieldsImport from "../../../data/Fields/Fields_Import";
import MatchFieldLists from "../fields/subfunctions/MatchFieldLists";
import DateString from "../../../functions/Date Functions/DateString";
import { Modal } from "react-bootstrap";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import FilterData from "../../../functions/FilterData";
import DataMgrProps from "./subfunctions/DataMgrProps";
import { useParams } from "react-router-dom";
import StringAfterDelimeter from "../../../functions/String Functions/StringAfterDelimeter";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import GetUniquesFromObjectAttribute from "../../../functions/GetUniquesFromObjectAttribute";
import ObjectSort from "../../../functions/ObjectSort";
import GetFieldName from "../../../functions/GetFieldName";

import "./css/DataManager.css";


const DataManager = () => {

    const { modelcategory } = useParams()


    interface mgrprops {
        modelcategory: string;
        fldIDattr: string;
        datefield: string;
        keycol: string;
        editablefields: any;
        pathmdl: string;
        pathdata: string;
        pathattrs: string;
        pathdatachange: string;
        pathdelete: string;
        hardcodedfields: any[];
        UploadFunction: any;
    }
    const { config, dispatcher, paths, schema, user } = useContext(StartupContext)

    const [mprops,] = useState<mgrprops>(DataMgrProps(modelcategory))


    interface samplefld {
        Value: string;
    }
    interface paramInit {
        viewState: string;
        showTable: boolean;
        modelname: string;
        datamsg: string;
        loaded: number;
        isImported: boolean;
        dfile: any | null;
        historycount: number;
        isdelvisible: boolean;
        isuplvisible: boolean;
        headerchange: boolean;
        rememberheaders: boolean;
        asofDate: Date;
        refresh: number;
        includeBOT: boolean;
        newvaluefld: string;
        batchupload: boolean;
        batchfieldheader: string;
    }
    const [params, setParams] = useState<paramInit>({
        asofDate: new Date(), viewState: "",
        showTable: false,
        modelname: '',
        datamsg: '', loaded: 0,
        isImported: false, dfile: null,
        historycount: 0, isdelvisible: false, isuplvisible: false,
        headerchange: false, rememberheaders: false, refresh: 0, includeBOT: false,
        newvaluefld: '', batchupload: false, batchfieldheader: ''
    })

    //const Fields: FieldMap[] = useSelector((state: any) => state.fieldmapping);
    let [fields, setFields] = useState<FieldMap[]>([]);
    let [allFields, setAllFields] = useState<FieldMap[]>([]);

    const [data, setdata] = useState<any[] | null>(null)
    const [attrData, setAttrData] = useState<any[]>([])
    const [selDataID, setselDataID] = useState<string>("-")
    const [mdlData, setMdlData] = useState<any[]>([])
    const [selMdl, setSelMdl] = useState<any | null>(null)
    const [_, setChangeState] = useState<any>(0)
    const [sampleFields, setSampleFields] = useState<samplefld[]>([])
    const [headersList, setHeadersList] = useState<any>({ 0: "", 1: "" })
    const [modelname, setModelName] = useState<string>("") //params model name is getting wiped out on upload
    const [rerun, setRerun] = useState<boolean>(false)

    const [newvalues, setNewValues] = useState<AllValues[]>([])
    const [newvalueFields, setNewValueFields] = useState<Key_Name[]>([])


    let styleOn = { backgroundColor: "darkblue", boxShadow: "0px 0px 8px yellow" }
    let styleOff = { backgroundColor: "black", boxShadow: "" }

    useEffect(() => {
        if (data) {
            window.location.reload()
        }
    }, [modelcategory])

    //all data - high level
    useEffect(() => {
        if (mprops.modelcategory) {
            GenericBackendCall("", schema, mprops.pathmdl, user, config, {}, '').then(d => { setMdlData(d) })
            GenericBackendCall("", schema, mprops.pathdata, user, config, {}, '').then(d => {
                setdata(ObjectSort(d, mprops.fldIDattr))

            })
            ImportAndSetState(FieldsImport, setAllFields, "", schema, config, dispatcher, false)
        }
    }, [mprops.modelcategory])

    useEffect(() => {
        let mcatflds = FilterData(allFields, "ModelCategory", mprops.modelcategory)
        setFields(mcatflds)
    }, [allFields])

    useEffect(() => {
        if (params.refresh >= 100) {
            window.location.reload()
        }
    }, [params.refresh])

    useEffect(() => {
        if (selMdl) {
            let promise = ImportAndSetStateGeneric(setNewValues, selMdl.ModelKey, schema, paths.fields.allvaluesview, user, config, {})
            promise.then(d => {
                //set unique fields
                let fldkeys = GetUniquesFromObjectAttribute(d, "FieldName", true)
                let fldkeynames = fldkeys.map((f: string) => {
                    return { Key: f, Name: GetFieldName(fields, f) }
                })
                setNewValueFields(fldkeynames)
            })
        }
    }, [selMdl])


    //data - vertical or horizontal slice
    useEffect(() => {
        if (mprops.modelcategory) {
            //going to run twice, occasionally
            let attrpromise = GenericBackendCall(selDataID, schema, mprops.pathattrs, user, config, { asofdate: params.asofDate }, "")
            attrpromise.then((d: any) => {
                console.log(d)
                setAttrData(DateSort(d, mprops.datefield));
                if (d.length > 0) {
                    setParams({ ...params, "showTable": true })
                    setRerun(false)
                } else {
                    if (rerun) {
                        setParams({
                            ...params, datamsg: "No data available!"
                        })
                        setRerun(false)
                    }
                }
            })
        }
    }, [selDataID, rerun])

    useEffect(() => {
        if (mprops.modelcategory && params.isImported) {
            let fldsfilt = FilterData(fields, "FieldCategory", ["Grouping"], true)
            setHeadersList(MatchFieldLists(sampleFields, fldsfilt))
        }
    }, [params.isImported])

    useEffect(() => {
        setParams({ ...params, historycount: mdlData.length })
    }, [mdlData])

    useEffect(() => {
        if (params.modelname !== "") {
            setModelName(params.modelname)
        }
    }, [params.modelname])


    const FileLoad = () => {
        let elem: any = document.getElementById("dt_fileinput")
        let dfile = elem.attributes[0].ownerElement?.files[0]
        setParams({ ...params, dfile: dfile, loaded: params.loaded + 1, isImported: false })
    }

    const ClickUpload = () => {
        if (params.dfile) {
            let upl1 = mprops.UploadFunction(params.dfile, schema, modelname, params.asofDate, headersList,
                params.rememberheaders, params.includeBOT, params.batchfieldheader, config, "dt_upldiv");
            upl1.then((_: any) => {
                setHeadersList({})
                setParams({ ...params, dfile: {}, isImported: false, loaded: 0 })
            })
        }
    };

    const ImportClicked = () => {
        setselDataID("");
        setRerun(true);
    };

    const FileSubmit = () => {
        if (params.dfile) {
            let uploadpromise = FieldsUploadSample(params.dfile, schema, config, user, "checkupload_div")
            uploadpromise.then((sd: any) => {
                setSampleFields(sd.data)
                setParams({ ...params, isImported: true, refresh: 99 })
            })
        }
    };

    const IsTable = () => {
        if (params.showTable && attrData.length > 0) {
            let headers: String[] = [];
            Object.keys(attrData[0]).forEach(key => {
                headers.push(StringAfterDelimeter(key, "__"))
            });
            return (<div>
                <CustomTable data={attrData}
                    keycol={mprops.keycol}
                    rowcount={10}
                    optionalchoices={[]}
                    colsHide={[]}
                    changestate={setChangeState}
                    modelcategory={mprops.modelcategory}
                    backendstring={mprops.pathdatachange}
                    hardcodedfields={mprops.hardcodedfields}
                    editable={mprops.editablefields}
                    shape={[1100, 600]}
                    fields={fields}
                />
            </div>)
        } else {
            return (<div>{params.datamsg}</div>)
        }
    };

    const fileinputmemo = useMemo(() => {
        return (<input type="file" id="dt_fileinput" name="reviewFile" onChange={_ => FileLoad()} />)
    }, [params.modelname, params.viewState, params.loaded])

    // const textinputmemo = useMemo(() => {
    //     return (<input type="text" id="dptmdl_input" value={params.modelname} onChange={(e) => setParams({ ...params, "modelname": e.target.value })} />)
    // }, [params.modelname, params.viewState])

    // const dateinputmemo = useMemo(() => {
    //     return (<DatePicker className="datepick_class" value={params.asofDate} onChange={(e: any) => setParams({ ...params, asofDate: new Date(e) })} />)
    // }, [params.asofDate, params.viewState, params.dfile])

    const View_Upload = () => {
        return (
            <div id="dmgroptn_upload">
                <div className="ndt_title3">Import Data</div>

                <div id="submitruleform" style={{ 'marginBottom': '20px', 'textAlign': 'left' }}>
                    <div id="dptupload_form">
                        <div style={{ overflowX: "hidden", display: "block" }} className="dt_uploadline">
                            <div style={{ display: "flex" }}>
                                <div style={{ marginRight: "10px" }}>{mprops.modelcategory} File</div>
                                {fileinputmemo}
                            </div>
                            <div>
                                <br />
                                {params.loaded > 0 ?
                                    <button className="ndt_btn1" onClick={_ => FileSubmit()}>Upload</button>
                                    : <div></div>}
                            </div>
                        </div>

                    </div>
                </div>
                <div id="dataupl_div"></div>
            </div >
        )
    };

    const viewuploadmemo = useMemo(() => { return View_Upload() }, [
        params.modelname, params.asofDate, params.dfile, params.viewState, params.includeBOT, params.loaded
    ])

    const View_SingleDataID = () => {
        if (data) {
            return (
                <div>
                    <div className="ndt_title3">Single {mprops.modelcategory} History</div>
                    <div id="dmgrtbl_single_header">Select {mprops.modelcategory} to view detail</div>
                    <GenericDropdown data={data} keycol={mprops.fldIDattr} namecol={mprops.fldIDattr}
                        divID="div_dmgrreturn" promptstring={"Select " + mprops.modelcategory}
                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, data, setselDataID, mprops.fldIDattr)} />
                </div>
            )
        } else {
            return (<div></div>)
        }
    };

    const View_Dateslice = () => {
        //setting sel id changes the useeffect to import current data
        return (
            <div id="dmgroptn_dates">
                <div className="ndt_title3">{mprops.modelcategory}: By Date</div>
                <br />
                <div>
                    <DatePicker className="datepick_class" value={params.asofDate} onChange={(e: any) => setParams({ ...params, asofDate: new Date(e) })} />
                </div>

                <button style={{ marginTop: "10px" }} className="ndt_btn1" onClick={_ => { ImportClicked() }}>Import Data</button>
            </div >
        )
    };


    const ViewUpper_UplHist = () => {
        return (<div>
            {selMdl ?
                <div>
                    <div style={{ fontSize: "22px" }}>UploadSet {DateString(selMdl.AsofDate)}: {selMdl.ModelName}</div>
                    <button className="ndt_btn1" onClick={_ => { setParams({ ...params, isdelvisible: true }) }}>Delete This Data</button>
                </div>
                : <div></div>}
        </div>)
    }

    const SwitchOption = (view: string) => {
        return (
            <div id="dmgrtbl_across" className="ndt_gridbox" style={{ overflow: "visible" }}>
                {(() => {
                    switch (view) {
                        case "upload": return <div>{viewuploadmemo}</div>;
                        case "uploadhistory": return <ViewUpper_UplHist />;
                        case "singledmgr": return <View_SingleDataID />;
                        case "dateslice": return <View_Dateslice />;
                        default: return <div>No view selected</div>;
                    }
                })()}
            </div>
        )
    };
    const switchmemo = useMemo(() => SwitchOption(params.viewState), [
        selMdl, params.viewState, params.modelname, params.asofDate, params.loaded, params.isImported, params.includeBOT
    ]);

    const ChangeHeader = (e: ChangeEvent<HTMLSelectElement>, i: number) => {
        let val = e.target.selectedOptions[0].attributes[0].value
        let newlist = [headersList[i][0], headersList[i][1], val, "True", "Y"];
        if (val = "") {
            newlist = [headersList[i][0], headersList[i][1], val, "True", ""]
        }
        setHeadersList({ ...headersList, [i]: newlist })
        if (!params.headerchange) { setParams({ ...params, headerchange: true }) }
    };
    const BatchFieldSelect = () => {
        return (
            <select onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setParams({ ...params, batchfieldheader: e.target.selectedOptions[0].attributes[0].value })}>
                <option value="" key={-1}>
                    Select Column
                </option>
                {sampleFields.map((val: samplefld, i: number) => {
                    return (<option value={val.Value} key={i + "sample"} >
                        {val.Value}
                    </option>)
                })}
            </select>
        )
    }

    const HeaderDdown = (props: any) => {
        let val: number = props.val;

        return (
            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => ChangeHeader(e, val)}>
                <option value="" key={-1}>
                    Select Column
                </option>
                {sampleFields.map((val: samplefld, i: number) => {
                    return (<option value={val.Value} key={i + "sample"} >
                        {val.Value}
                    </option>)
                })}
            </select>
        )
    };

    const ManageUpload = () => {
        return (<div>
            {params.dfile && params.isImported ? <div>
                <div id="">Confirm Fields for Upload - Model: {modelname}</div>
                <div>
                    <div key={-1} className="dt_head">
                        <div className="dt_headitem">Field</div>
                        <div className="dt_headitem"> File header</div>
                        <div className="dt_headitem">Is Required Field? </div>
                        <div className="dt_headitem"> Manual Select </div>
                    </div>

                    {Object.keys(headersList).map((k: any, i: number) => {
                        let bstyle = { backgroundColor: "" }
                        if (headersList[k][2] === "") {
                            bstyle = { backgroundColor: "blue" }
                        }
                        return (
                            <div key={i} data-value={headersList[k][1]} id={"tfld_" + i} style={bstyle} className="dt_field">
                                <div className="dt_fielditem"  > {headersList[k][1]} </div>
                                <div className="dt_fielditem" > {headersList[k][2]} </div>
                                <div className="dt_fielditem" > {headersList[k][3]} </div>
                                <div id={"tflddwn_" + i} className={i > 0 ? "dt_fielditem" : "dt_headitem"} > <HeaderDdown val={i} /> </div>
                            </div>
                        )
                    })}
                    <div style={{ display: "flex", marginTop: '15px' }}>
                        <button className="ndt_btn1" onClick={_ => setParams({ ...params, isuplvisible: true })}  > Submit Data</button>
                        <div style={{ marginLeft: '300px' }}>{params.headerchange ?
                            <button style={params.rememberheaders ? styleOn : styleOff} id="tmgr_hdrbtn" className="ndt_btn2" onClick={_ => { setParams({ ...params, rememberheaders: !params.rememberheaders }) }}>
                                {params.rememberheaders ? "Headers will save" : "Remember headers?"}
                            </button>
                            : <div></div>}
                        </div>
                    </div>
                </div>
            </div>
                : <div></div>}


        </div>)
    }

    const ClickDelete = () => {
        if (selMdl) {
            GenericBackendCall(selMdl.ModelKey, schema, mprops.pathdelete, user, config, {}, "dmgr_divdelete").then(_ => setParams({ ...params, refresh: 99 }))
        }
    }

    const UploadHistory = () => {
        return (<div>
            <div className="ndt_title3">Upload History </div>
            <div id="dmgr_uplhistmain">
                <div>
                    {mdlData.map((upl: any, i: number) => {
                        let uplstyle = { backgroundColor: "black" }
                        if (upl.ModelKey === selMdl?.ModelKey) {
                            uplstyle = { backgroundColor: "blue" }
                        }
                        return (<div key={i} className="tmgr_uplitm"
                            style={uplstyle}
                            onClick={_ => setSelMdl(upl)}>
                            Date: {DateString(upl.AsofDate)}
                        </div>)
                    })}
                </div>
                <div style={{ marginLeft: "100px" }}>
                    {selMdl ? <div>
                        <div style={{ display: "flex", overflowX: 'scroll' }}>
                            {newvalueFields.map((kn: Key_Name, i: number) => {
                                return (<div className="ndt_item" style={{ width: '200px' }} key={i}
                                    onClick={_ => { setParams({ ...params, newvaluefld: kn.Key }) }}
                                >
                                    {kn.Name}
                                </div>)
                            })}
                        </div>
                        <div>New Values: {params.newvaluefld !== "" ? StringAfterDelimeter(params.newvaluefld, "__") : ""}</div>

                        <div style={{ overflowY: "scroll", height: "550px", width: "400px", border: "1px solid black", boxShadow: "0px 0px 3px white" }}>
                            {newvalues.map((nv: AllValues, i: number) => {
                                if (nv.FieldName === params.newvaluefld) {
                                    return (<div key={i} className="dmgr_newvalitm">{nv.Value}</div>)
                                }
                            })}
                        </div>
                    </div> : <div></div>}
                </div>
            </div>
        </div>)
    }

    return (

        <div>
            {mprops.modelcategory !== "" ? <div>

                <div id="dmgrtable_canvas" className="ndt_canvas">
                    <div id="dmgrtbl_sidebar" className="ndt_gridbox">
                        <div className="ndt_title2">{mprops.modelcategory} Manager</div>
                        <div className="dmgrtbl_sidebtn" onClick={_ => setParams({ ...params, viewState: "upload" })}>Upload</div>
                        <div className="tmgr_sidebtn" onClick={_ => setParams({ ...params, viewState: "uploadhistory" })}>Upload History ({params.historycount})</div>
                        <div className="dmgrtbl_sidebtn" onClick={_ => setParams({ ...params, viewState: "singledmgr" })}>View Single {mprops.modelcategory} History</div>
                        <div className="dmgrtbl_sidebtn" onClick={_ => setParams({ ...params, viewState: "dateslice" })}>{mprops.modelcategory} by Date</div>
                    </div>

                    {switchmemo}

                    <div id="1" className="ndt_gridbox">
                        {(() => {
                            switch (params.viewState) {
                                case "upload": return <ManageUpload />;
                                case "uploadhistory": return <UploadHistory />;
                                case "singledmgr": return <IsTable />;
                                case "dateslice": return <IsTable />;
                                default: return <div></div>;
                            }
                        })()}

                    </div>

                    {/* delete */}
                    <Modal show={params.isdelvisible}>
                        <div className="ndt_modal">
                            <div>WARNING: Delete table {DateString(selMdl?.AsofDate)}?</div>
                            <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { ClickDelete() }}>Delete</button>
                            <div id="dmgr_divdelete"></div>
                            <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdelvisible: false, refresh: params.refresh + 1 })}>Close</button>
                        </div>
                    </Modal>

                    <Modal show={params.isuplvisible}>
                        <div className="ndt_modal" style={{ padding: "10px" }}>
                            <div style={{ marginRight: '10px' }}>Set Model Name</div>
                            <input type="text" id="dptmdl_input" value={params.modelname} onChange={(e) => setParams({ ...params, "modelname": e.target.value })} />

                            <div style={{ display: "flex" }}>
                                <button className="ndt_btn1" style={params.batchupload ? styleOff : styleOn} onClick={_ => setParams({ ...params, batchupload: false })}>Single Date Upload</button>
                                <button className="ndt_btn1" style={params.batchupload ? styleOn : styleOff} onClick={_ => setParams({ ...params, batchupload: true })}>Batch Upload</button>
                            </div>
                            {params.batchupload ? <div>
                                <div>Set Batch Field: {params.batchfieldheader}</div>
                                <BatchFieldSelect />
                            </div> :
                                <div>
                                    <div>Set As of Date</div>
                                    <div style={{ color: "white", display: "flex" }} className="dt_uploadline">
                                        <DatePicker className="datepick_class" value={params.asofDate} onChange={(e: any) => setParams({ ...params, asofDate: new Date(e) })} />
                                        <button id="dmgr_botbtn" style={params.includeBOT ? styleOn : styleOff} onClick={_ => { setParams({ ...params, includeBOT: !params.includeBOT }) }}>Also Set from Beginning of Time</button>
                                    </div>
                                </div>
                            }

                            <div style={{ display: "flex", marginTop: "5px" }}>
                                {params.modelname !== "" ?
                                    <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { ClickUpload() }}>Upload</button>
                                    : <div></div>}

                            </div>
                            <div id="dt_upldiv" style={{ height: "30px", margin: "10px" }}></div>
                            <button className="closemodalbtn" onClick={_ => setParams({ ...params, isuplvisible: false, refresh: params.refresh + 1 })}>Close</button>
                        </div>
                    </Modal>


                </div>
            </div > : <div></div>}
        </div >
    )
};

export default DataManager;
