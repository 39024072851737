import { ColorValue, ColorSet } from '../../type';
import AxiosUpload from '../AxiosUpload';


const ColorSetUpload = async (colordata: ColorValue[], schemakey: string, modelkey: string, modelname: string,
    fieldkey: string, othergroupcolor: string, config: any, elementstring: string) => {

    let dataArray = new FormData();
    const newdata: string[] = []

    colordata.forEach((cv: ColorValue) => {
        newdata.push(String(cv.ValueName).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`))
        newdata.push(cv.Color)
        newdata.push(cv.SecondaryColor)

    });

    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("modelname", modelname);
    dataArray.append("modelkey", modelkey);
    dataArray.append("schemakey", schemakey);
    dataArray.append("fieldkey", fieldkey);

    dataArray.append("colorvalues", String(newdata));
    dataArray.append("othergroupcolor", othergroupcolor);


    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/colorsets/colorsetpost", config, elem, "")

    return ("Ok")
};

export default ColorSetUpload;