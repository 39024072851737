
const IsArray = (myvar) =>{
    //ref: UniqueArrayValues

    
    if(Object.prototype.toString.call(myvar) === '[object Array]') {
        return true
    } else {
        return false
    }
};
export default IsArray