import { PathList } from "../type";

const AllPaths = (): PathList => {

    let pths: PathList = {
        'accounts': {
            'signup': 'api/accounts/signup/',
            'usersview': 'api/accounts/usersview',
            'userreportsview': 'api/accounts/userreportsview',
            'userassignreport': 'api/accounts/userassignreport',
            'userremovereport': 'api/accounts/userremovereport',
            'validatetokens': 'api/accounts/validatetokens',
        },
        'ai': {
            'aiinterfacepost': 'api/ai/aiinterfacepost',
            'aibranchview': 'api/ai/aibranchview',
            'aibranchpost': 'api/ai/aibranchpost',
            'aimodelview': 'api/ai/aimodelview',
            'aimodelrepost': 'api/ai/aimodelrepost',
            'aipostsingle': 'api/ai/aipostsingle'
        },
        'answers': {
            'default': 'api/answers/default',
            'defaultdashboard': 'api/answers/defaultdashboard',
            'analysis': 'api/answers/analysis',
            'fitmodelpost': 'api/answers/fitmodelpost',
            'fitmodelview': 'api/answers/fitmodelview',
            'fitmodelgenerate': 'api/answers/fitmodelgenerate',
        },
        'application': {
            'applicantview': 'api/application/applicantview',
            'applicationview': 'api/application/applicationview',
            'applicationpost': 'api/application/applicationpost',
            'jobpostingview': 'api/application/jobpostingview',
            'jobpostingpost': 'api/application/jobpostingpost',
            'jobpostingrequirementspost': 'api/application/jobpostingrequirementspost',
            'qualificationspost': 'api/application/qualificationspost',
            'resumepost': 'api/application/resumepost',
        },
        'benchmark': {
            'benchmodelview': 'api/benchmark/benchmodelview',
            'benchmarksetview': 'api/benchmark/benchmarksetview',
            'benchmarkmodelpost': 'api/benchmark/benchmarkmodelpost',
            'benchmarksetpost': 'api/benchmark/benchmarksetpost',
        },
        'colorsets': {
            'colorsetview': 'api/colorsets/colorsetview',
            'colorsetpost': 'api/colorsets/colorsetpost',
            'colorsetdelete': 'api/colorsets/colorsetdelete',
            'colorsetduplicate': 'api/colorsets/colorsetduplicate',
            'colorsetrename': 'api/colorsets/colorsetrename',
            'colormodelview': 'api/colorsets/colormodelview',
            'colormodelpost': 'api/colorsets/colormodelpost',
            'colormodeldelete': 'api/colorsets/colormodeldelete',
            'reattachcolorset': 'api/colorsets/reattachcolorset',
        },
        'dashboard': {
            'dashview': 'api/dashboard/dashview',
            'dashnameview': 'api/dashboard/dashnameview',
            'dashpost': 'api/dashboard/dashpost',
            'dashdelete': 'api/dashboard/dashdelete',
            'dashupdate': 'api/dashboard/dashupdate',
            'dashnotesview': 'api/dashboard/dashnotesview',
            'dashnotespost': 'api/dashboard/dashnotespost',
            'dashrundatepost': 'api/dashboard/dashrundatepost'
        },
        'department': {
            'deptmodelview': 'api/department/deptmodelview',
            'deptmodeldelete': 'api/department/deptmodeldelete',
            'deptview': 'api/department/deptview',
            'deptattrview': 'api/department/deptattrview',
            'deptpost': 'api/department/deptpost',
            'deptfilt': 'api/department/deptfilt',
        },
        'employee': {
            'dataslice': 'api/employee/dataslice',
            'datamodel': 'api/employee/datamodel',
            'datapost': 'api/employee/datapost',
            'empmodelview': 'api/employee/empmodelview',
            'empmodeldelete': 'api/employee/empmodeldelete',
            'singleemp': 'api/employee/singleemp',
            'emprow': 'api/employee/emprow',
            'empaudit': 'api/employee/empaudit',
            'xfrfieldpost': 'api/employee/xfrfieldpost',
        },
        'fields': {
            'fieldretrieve': 'api/fields/fieldretrieve',
            'fieldpostall': 'api/fields/fieldpostall',
            'fieldpostchange': 'api/fields/fieldpostchange',
            'fieldpostnew': 'api/fields/fieldpostnew',
            'fielddelete': 'api/fields/fielddelete',
            'fieldremoveaddtl': 'api/fields/fieldremoveaddtl',
            'headerupload': 'api/fields/headerupload',
            'sample': 'api/fields/sample',
            'allvaluesview': 'api/fields/allvaluesview',
            'allvaluesrunhistory': 'api/fields/allvaluesrunhistory',
        },
        'filtersets': {
            'filtermodelview': 'api/filtersets/filtermodelview',
            'filtersetsview': 'api/filtersets/filtersetsview',
            'filtermodelupload': 'api/filtersets/filtermodelupload',
            'filtermodeldelete': 'api/filtersets/filtermodeldelete',
        },
        'generics': {
            'genericdelete': 'api/generics/genericdelete',
            'genericupdatevalue': "api/generics/genericupdatevalue",
            'genericuniques': 'api/generics/genericuniques',
            'genericexample': 'api/generics/genericexample',
            'generickeynamemap': 'api/generics/generickeynamemap',
            'genericfilterview': "api/generics/genericfilterview",
            'datachange': "api/generics/datachange",
            'dataexcelread': "api/generics/dataexcelread",
            'genericdataview': "api/generics/genericdataview",
            'genericdateinfo': "api/generics/genericdateinfo",
            'genericmodelnames': "api/generics/genericmodelnames",
            'genericrename': "api/generics/genericrename",
        },
        'groupsets': {
            'groupsetsview': "api/groupsets/groupsetsview",
            'groupsetpost': "api/groupsets/groupsetpost",
            'groupsetvalueview': "api/groupsets/groupsetvalueview",
        },
        'jobtitle': {
            'jobtitlemodelview': 'api/jobtitle/jobtitlemodelview',
            'jobtitlemodeldelete': 'api/jobtitle/jobtitlemodeldelete',
            'jobtitleview': 'api/jobtitle/jobtitleview',
            'jobtitleattrview': 'api/jobtitle/jobtitleattrview',
            'jobtitlepost': 'api/jobtitle/jobtitlepost',
            'jobtitlefilt': 'api/jobtitle/jobtitlefilt',
        },
        'jobdescription': {
            'jobdescview': 'api/jobdescription/jobdescview',
            'jobskillview': 'api/jobdescription/jobskillview',
            'jobskillpost': 'api/jobdescription/jobskillpost',
            'descmodelview': 'api/jobdescription/descmodelview',
            'descmodelpost': 'api/jobdescription/descmodelpost',
            'jobcareerview': 'api/jobdescription/jobcareerview',
            'skillpipelineview': 'api/jobdescription/skillpipelineview',
            'skillcatvalueview': 'api/jobdescription/skillcatvalueview',
            'skillemployeeview': 'api/jobdescription/skillemployeeview',
            'skillcategoryview': 'api/jobdescription/skillcategoryview',
            'jdaiview': 'api/jobdescription/jdaiview',
            'jdaipost': 'api/jobdescription/jdaipost',
            'jdaiassignmodel': 'api/jobdescription/jdaiassignmodel',
            'skillsnapshot': 'api/jobdescription/skillsnapshot',

        },
        'kpi': {
            "kpipost": 'api/kpi/kpipost',
            "kpiview": 'api/kpi/kpiview',
            "kpivalueretrieve": 'api/kpi/kpivalueretrieve',
            "kpistringpost": 'api/kpi/kpistringpost',
            "kpistringview": 'api/kpi/kpistringview',
            'kpibenchmarkpost': 'api/kpi/kpibenchmarkpost',
        },
        'leader': {
            'leaderview': 'api/leader/leaderview',
            'leaderpost': 'api/leader/leaderpost',
        },
        'position': {
            'positionmodelview': 'api/position/positionmodelview',
            'positionmodeldelete': 'api/position/positionmodeldelete',
            'positionview': 'api/position/positionview',
            'positionattrview': 'api/position/positionattrview',
            'positionpost': 'api/position/positionpost',
            'positionfilt': 'api/position/positionfilt',
        },
        'permissions': {
            'permview': 'api/permissions/permview',
            'permdelete': 'api/permissions/permdelete',
            'permpost': 'api/permissions/permpost',
        },
        'query': {
            'basicpost': 'api/query/basicpost',
            'basicpostmulti': 'api/query/basicpostmulti',
            'basicquerycheckmodels': 'api/query/basicquerycheckmodels',
            'queryview': 'api/query/queryview',
            'queryvisualsview': 'api/query/queryvisualsview',
            'queryvisualspost': 'api/query/queryvisualspost',
            'chartseriespost': 'api/query/chartseriespost',
            'basicqueryview': 'api/query/basicqueryview',
            'querydelete': 'api/query/querydelete',
            'queryduplicate': 'api/query/queryduplicate',
            'queryrename': 'api/query/queryrename',
            'reattachquerymodel': 'api/query/reattachquerymodel',
            'detailpost': 'api/query/detailpost',
            'detailview': 'api/query/detailview',
            'detaildelete': 'api/query/detaildelete',
            'rundatedelete': 'api/query/rundatedelete',
            'rundatesetprimary': 'api/query/rundatesetprimary',
            'rundatesview': 'api/query/rundatesview',
            "metricview": 'api/query/metricview',
            "metricpost": 'api/query/metricpost',
            "metricdelete": 'api/query/metricdelete',
            "basicqueryuniquevalues": 'api/query/basicqueryuniquevalues',
        },
        'rulesets': {
            'rulemodelpost': 'api/rulesets/rulemodelpost',
            'rulepost': 'api/rulesets/rulepost',
            'rulemodelview': 'api/rulesets/rulemodelview',
            'rulesetsview': 'api/rulesets/rulesetsview',
            'rulesingle': 'api/rulesets/rulesingle',
            'rulemodeldelete': 'api/rulesets/rulemodeldelete',
            'ruledelete': 'api/rulesets/ruledelete',
            'renamepost': 'api/rulesets/renamepost',
        },
        'scenario': {
            "scenarioview": 'api/scenario/scenarioview',
            "scenariopost": 'api/scenario/scenariopost',
            "scenariopathpost": 'api/scenario/scenariopathpost',
            "scenariopathview": 'api/scenario/scenariopathview',
            "scenariodataview": 'api/scenario/scenariodataview',
            "scenariopathedit": 'api/scenario/scenariopathedit',


        },
        'schedule': {
            "scheduleview": 'api/schedule/scheduleview',
            "schedulepost": 'api/schedule/schedulepost',
            "assignschedule": 'api/schedule/assignschedule',
        },
        'schema': {
            'schemaimport': 'api/schema/schemaimport',
            'schemapost': 'api/schema/schemapost',
            'schemadelete': 'api/schema/schemadelete',
            'workspaceview': "api/schema/workspaceview",
            'changesview': "api/schema/changesview",
        },
        'simulation': {
            'simulatepost': 'api/simulation/simulatepost',
            'generatetrends': 'api/simulation/generatetrends',
        },
        'sna': {
            'netmodelview': 'api/sna/netmodelview',
            'netmodelupload': 'api/sna/netmodelupload',
            'netmodelcreaterun': 'api/sna/netmodelcreaterun',
            'netmodelrunview': 'api/sna/netmodelrunview'
        },
        'tables': {
            'tablemodelview': "api/tables/tablemodelview",
            'tablemodelpost': "api/tables/tablemodelpost",
            'tablemodeldelete': "api/tables/tablemodeldelete",
            'tablemodelchange': "api/tables/tablemodelchange",
            'tableuploadview': "api/tables/tableuploadview",
            'tabledataview': "api/tables/tabledataview",
            'tabledatapost': "api/tables/tabledatapost",
            'tabledataunique': "api/tables/tabledataunique",
            'connectionview': 'api/tables/connectionview',
            'connectionpost': 'api/tables/connectionpost',
            'connectioncheck': 'api/tables/connectioncheck',
            'connectionsetview': 'api/tables/connectionsetview',
            'connectionnetwork': 'api/tables/connectionnetwork',
            'connectionmodelview': 'api/tables/connectionmodelview',
            'connectionmodelpost': 'api/tables/connectionmodelpost',
            'checktablemodels': 'api/tables/checktablemodels',

        },
        'tracker': {
            'trackerimport': "api/tracker/trackerimport",
            'trackerdelete': "api/tracker/trackerdelete",
            'trackerupdate': "api/tracker/trackerupdate",
        },
        'walkthrough': {
            'walkuploadstep': "api/walkthrough/walkuploadstep",
            'walkthroughdemoview': "api/walkthrough/walkthroughdemoview",
            'walkthroughdataexample': "api/walkthrough/walkthroughdataexample"
        }

    }

    return (pths)
};
export default AllPaths;