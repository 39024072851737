const GetHardcodedTables = (includestatictbls: boolean) => {
    //ref: app, modelcategorydropdown, connectionmanager

    //has customfields
    let primarytbls = [
        "EmployeeState", "Department", "JobTitle", "Position",
    ]

    //no custom fields    
    let secondarytbls = [
        "JobDescription", "JobSkill", "SkillCatValue", "SkillCategory",
        "JobPosting", "JobPostingRequirement", "Application", "ApplicantSkill"
    ]

    if (includestatictbls) {
        secondarytbls.forEach((tbl: string) => {
            primarytbls.push(tbl)
        })
    }


    //difference: primarytbls.filter(item => !secondarytbls.includes(item));
    return primarytbls
}
export default GetHardcodedTables