import ChartSample from "./ChartSample";
import { queryparams } from "../../type";


const SampleGraph = (params: queryparams, svgid: string, size: number[]) => {

    function GetRand() { return Math.floor(Math.random() * 4) }

    let rands = []
    let grp = 1;

    if (params.groupfield !== "") { grp = 2 }
    let i: number;
    for (i = 0; i < +params.nperiods * grp; i += 1) {
        rands.push(GetRand())
    }

    let sorteddata: any[] = []
    sorteddata = rands.map((r: number, i: number) => {
        let var1: string = "A"
        if (Math.round(i / 2) > i / 2 && grp === 2) { var1 = "B" }
        return ({ "Datevar": Math.round(i / grp), "Variable": var1, "Value": Math.round(r) + 1 })
    })

    ChartSample(
        params.chartType,
        svgid,
        sorteddata,
        [],
        ["Datevar", ["Value"], "Variable",],
        [params.xfieldname, "Count"],
        params.colorGradientType,
        params.colors,
        size,
        params.labelsonoff,
        false
    )


};

export default SampleGraph;