import { useContext, useEffect, useState, ChangeEvent } from "react";
import { StartupContext } from "../../../App";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import { DescModel, Emp_State, JobSkill, SkillCatValue, SkillCategory } from "../../../type";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import FilterData from "../../../functions/FilterData";
import "./css/JobSkillPipeline.css"
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import GetUniquesFromObjectAttribute from "../../../functions/GetUniquesFromObjectAttribute";
import GetRandomHexadecimal from "../../../functions/GetRandomHexadecimal";
import GetTextColor from "../../../functions/GetTextColor";
import ObjectSort from "../../../functions/ObjectSort";


const JobSkillRoster = (props: any) => {

    const selJDModel: null | DescModel = props.seljdmodel
    const jobdesc = props.jobdesc
    const jobskills = props.jobskills
    const roster = props.roster
    const jdmodels = props.jdmodels
    const jobtitles = ObjectSort(props.jobtitles, "JobCode")

    interface seltype {
        jcs_rpt: any[];
        skills: JobSkill[];
        skills_rpt: JobSkill[];
        skcats: any[];
        skcats_rpt: any[];
        categories: any[];
    }

    let { config, dispatcher, schema, user, paths } = useContext(StartupContext)
    let [keyparams, setKeyParams] = useState({ seljobcode: '', seljobtitle: '', seljobcodeto: '', seljobtitleto: '' })
    let [seldata, setSelData] = useState<seltype>({ skills: [], skcats: [], jcs_rpt: [], skills_rpt: [], skcats_rpt: [], categories: [] })

    //let [selJDModel, setSelJDModel] = useState<DescModel | null>(null)
    let [skillcatvals, setSkillCatVals] = useState<SkillCatValue[]>([])
    let [skillcatvalsrpt, setSkillCatValsRpt] = useState<SkillCatValue[]>([])

    useEffect(() => {
        if (selJDModel) { ImportSkillCatVal(keyparams.seljobcode, setSkillCatVals) }
    }, [selJDModel?.ModelKey, keyparams.seljobcode])

    useEffect(() => {
        if (selJDModel) { ImportSkillCatVal(keyparams.seljobcodeto, setSkillCatValsRpt) }
    }, [selJDModel?.ModelKey, keyparams.seljobcodeto])

    const ImportSkillCatVal = (jobcode: string, setfn: any) => {
        if (selJDModel) {
            ImportAndSetStateGeneric(setfn, selJDModel.ModelKey, schema, paths.jobdescription.skillcatvalueview, user, config,
                { jobcodes: [jobcode] })
        }
    }

    //actions
    useEffect(() => {
        if (keyparams.seljobcode !== "") {
            // jobdesc.forEach((jd: JobDescription) => {
            //     if (jd.JobCode === keyparams.seljobcode) {
            //         console.log(jd)
            //     }
            // })
            let skills = FilterData(jobskills, "JobCode", keyparams.seljobcode)

            let curremps = FilterData(roster, "JobCode", keyparams.seljobcode)
            let reportsto = curremps.map((c: Emp_State) => {
                return c.ReportsToID
            })

            let nextemps = FilterData(roster, "EmpID", reportsto)
            let uniquecodes: any[] = []
            let nextjcs: any[] = []
            nextemps.forEach((emp: Emp_State) => {
                if (uniquecodes.indexOf(emp.JobCode) === -1) {
                    nextjcs.push({ JobCode: emp.JobCode, JobTitle: GetObjectField(emp.JobCode, jobtitles, "JobCode", "JobTitle") })
                }
            })

            setKeyParams({ ...keyparams, seljobtitle: GetObjectField(keyparams.seljobcode, jobtitles, "JobCode", "JobTitle") })
            setSelData({ ...seldata, skills: skills, jcs_rpt: nextjcs, categories: [] })
        }
    }, [keyparams.seljobcode])

    useEffect(() => {
        if (keyparams.seljobcodeto !== "") {

            let skills_rpt = FilterData(jobskills, "JobCode", keyparams.seljobcodeto)
            setKeyParams({ ...keyparams, seljobtitleto: GetObjectField(keyparams.seljobcodeto, jobtitles, "JobCode", "JobTitle") })
            setSelData({ ...seldata, skills_rpt: skills_rpt, categories: [] })
        }
    }, [keyparams.seljobcodeto, skillcatvalsrpt])

    useEffect(() => {
        if (selJDModel && skillcatvals.length > 0 && skillcatvalsrpt.length > 0 && seldata.categories.length == 0) {
            let fromcategories = GetUniquesFromObjectAttribute(skillcatvals, "Category", true)
            let tocategories = GetUniquesFromObjectAttribute(skillcatvalsrpt, "Category", true)
            let categoriesmatch: any[] = []

            fromcategories.forEach((catF: string) => {
                tocategories.forEach((catT: string) => {
                    if (catF === catT) {
                        categoriesmatch.push({ Category: catT, Color: GetRandomHexadecimal(6) })
                    }
                })
            })
            setSelData({ ...seldata, categories: categoriesmatch })
        }
    }, [selJDModel, skillcatvals, skillcatvalsrpt])


    return (<div style={{ marginTop: "10px" }}>
        <div className="ndt_title4" style={{ margin: '5px' }}>Roster</div>


        <div id="jsp_main">
            <div className="jsp_block ndt_gridbox">
                <GenericDropdown
                    data={jobtitles}
                    keycol="JobCode"
                    namecol={["JobCode", "JobTitle"]}
                    promptstring="Select Job Title"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, jobtitles, keyparams, setKeyParams, "seljobcode", "JobCode", "") }}
                />
                {keyparams.seljobcode}: {keyparams.seljobtitle}


                <div style={{ height: "500px", overflowY: "scroll" }}>
                    {seldata.skills.map((sk: JobSkill, i: number) => {

                        let cat = GetObjectField(sk.Key, skillcatvals, "JobSkillName", "Category")
                        let color = GetObjectField(cat, seldata.categories, "Category", "Color")
                        if (color === "") { color = "#000000" }
                        return (<div key={i} className="jsp_skl" >
                            <div className="jsp_sklcat">{cat}</div>
                            <div className="jsp_skldesc" style={{ backgroundColor: color, color: GetTextColor(color) }}>{sk.Skill}</div>
                        </div>)
                    })}
                </div>
            </div>

            <div className="jsp_block ndt_gridbox">
                <GenericDropdown
                    data={seldata.jcs_rpt}
                    keycol="JobCode"
                    namecol={["JobCode", "JobTitle"]}
                    promptstring="Select Manager Title"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, seldata.jcs_rpt, keyparams, setKeyParams, "seljobcodeto", "JobCode", "") }}
                />
                {keyparams.seljobcodeto}: {keyparams.seljobtitleto}


                <div style={{ height: "500px", overflowY: "scroll" }}>
                    {seldata.skills_rpt.map((sk: JobSkill, i: number) => {
                        let cat = GetObjectField(sk.Key, skillcatvalsrpt, "JobSkillName", "Category")
                        let color = GetObjectField(cat, seldata.categories, "Category", "Color")
                        if (color === "") { color = "#000000" }
                        let skl1 = sk.Skill
                        if (sk.Skill.length > 145) {
                            skl1 = sk.Skill.slice(0, 145) + "..."
                        }

                        return (<div key={i} className="jsp_skl">
                            <div className="jsp_sklcat">{cat}</div>
                            <div className="jsp_skldesc" style={{ backgroundColor: color, color: GetTextColor(color) }}>{skl1}</div>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    </div>
    )
};
export default JobSkillRoster;