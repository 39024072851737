import * as d3 from 'd3';
import "./css/CreateTableD3.css";
import DateString from '../../functions/Date Functions/DateString';
import HexToRgba from '../../functions/Color Functions/HexToRgba';
import GetTextColor from '../../functions/GetTextColor';
import RgbaToHex from '../../functions/Color Functions/RgbaToHex';
import FormatLabel from './FormatLabel';


const CreateTableD3 = (data, svgid, headernames, metrickeys, benchmarkidxs, metricidxmap, formats, size, type,  colorScale, secColorScale, charttitle, 
    pagination, horiz_metrno) => {
    //metricno: 0-indexed metrics, benchmarks added after

    let num = Math.round(size[1]/30 - 6.5)
    let page = 1
    let maxpage = Math.round(data.length/num - .5)

    let data_reducedcol = data

    let data_reduced = []

 
    function ResetDataPage(){
        data_reduced = []
        data_reducedcol.forEach((rw,i)=>{
            if (i >= (page-1) * num && i < num * (page)) {
                data_reduced.push(rw)
            }
        })

        if (type==="Table"){ //last row
            let lp = [["prev page",''], ["page:" +page + "/" + maxpage,''], ["next page",'']]
            metrickeys.forEach((_,i)=>{
                if (i>0){
                    lp.push(["",""])
                }
            })
            //let lastrow = Object.assign({}, ...cols.map((h,i)=> ({ [h]: lp[i]})))
            //data_reduced.push(lastrow)
        }
    }

    let colorkeys = metrickeys
    if (type==="TableHorizontal"){ 
        colorkeys = []
        headernames.forEach((h)=>{if (h[1]===""){colorkeys.push(h[0])}})
    }


    const Exists = (obj,str) =>{
        try {
            let xxx = obj[str]
            return(true)
        } catch (error) {
            return(false)
        }
    }

    function calculateMean(objects, fieldName) {
        let sum = 0;
        let count = 0;
      
        for (const obj of objects) {
          if (obj.hasOwnProperty(fieldName)) {
            const fieldValue = +obj[fieldName][0];
              sum += fieldValue;
              count++;
          }
        }
        return sum / count;
      }

    let conditionalscales = colorkeys.map((m,i)=>{
        if (data.length>0){
            let val1 = HexToRgba(colorScale(i))   
            let val2 = HexToRgba(secColorScale(i))  
            let mean1 = calculateMean(data,m)
            let minx = d3.min(data,function(d) {  if (Exists(d[m],0)){return +d[m][0]} else {return mean1}; }); 
            let maxx = d3.max(data,function(d) { if (Exists(d[m],0)){return +d[m][0]} else {return mean1}; }); 
            
            let scale1 = d3.scaleSequential()
                        .interpolator(d3.interpolateRgbBasis([val1, val2])) // Use interpolateRgbBasis to interpolate between colors
                        .domain([minx, maxx]);
            return(scale1)
        } else{
            return(null)
        }
    })


    const GetColor = (d) =>{
        let x = -1;
        if (d.value[0]===""){
            return("white")
        }
        colorkeys.forEach((k,j)=>{
            if (k===d['column']){
            x = j
        }})
        if (x>=0){
            return conditionalscales[x](d.value[0]) 
        } else {
            return "white"
        }
    
    }



    const ConvertValue = (val, col, idval, metricno) =>{
        let retval = val
        if (idval!==""){
            if (col==="Variable" || col==="Grouping"){
                retval = DateString(val)
            } else {
                retval = FormatLabel(val,formats[metricno])
                
                if (String(retval)==="NaN"){retval = ""}
            }
        }
        return(retval)
    }

    function GetMetrNo(i){
        
        if (type==="TableHorizontal"){
            return horiz_metrno
        } else {
            let retval = -1
            metricidxmap.forEach((mm)=>{
                if (i===mm.Key){
                    retval = mm.Name
                }
            })
            return retval
        }
    }


    const Colorize = (d) =>{
        let color = GetColor(d)
        if (color){
            let newcolor = RgbaToHex(color)
            return GetTextColor(newcolor)
        } else{
            return "black"
        }
    }


    function MakeTable(){
        const svgrmv = d3.select(`${svgid}`);
        svgrmv.select("table").remove();
        ResetDataPage()


        const table = d3.select(svgid)
                    .append('table')
                    .style('color','black')
                    .attr("width",size[0])
                    .attr("height",size[1] - 110);  

        //add title
        if (charttitle!==""){
            table.append('thead')
                .attr("x", (size[0] / 2))
                .attr("font-weight", "bold")
                .attr("font-size", "24px")
                .attr("text-anchor", "middle")
                .text(charttitle)
                
        }

        const thead = table.append('thead');

        const tbody = table.append('tbody');

        // Add table headers
        let headers_single = headernames.map(h=>{ return h[1]})
        thead.append('tr')
                .selectAll('th')
                .data(headers_single)
                .enter()
                .append('th')
                .attr("class","dtcellclass")
                .classed("dtcellclass",true)
                .text(d => d)
               

        // Add table rows and cells
        const rows = tbody.selectAll('tr')
                            .data(data_reduced)
                            .enter()
                            .append('tr')
                            .attr("height",'15px')
                            .classed("dtrowclass",true);

    
        const cells = rows.selectAll('td')
                            .data(function (row) {
                                return headernames.map(function (h) {
                                    return { column: h[0], value: row[h[0]] };
                                });
                            })
                            .enter()
                            .append('td')
                            .text((d,i) => {
                                return ConvertValue(d.value[0], headernames[i][0], d.value[1], GetMetrNo(i) )})
                            .classed("dtcellclass ndtchartelem",true)
                            .attr("selected",0)
                            .attr('id',(d)=>{  return d.value[1]})
                            .attr("data-id",(d)=>{return(d.value[2])})
                            .attr("data-value",(d)=>{return(d.value[0])})
                            .attr("data-metricno",(d,i)=>{return GetMetrNo(i)+1})
                            .attr('data-metrickey', (_,i)=>{if (GetMetrNo(i)>=0){return metrickeys[GetMetrNo(i)]} else {return ""}})
                            .attr("basecolor",(d)=>{return GetColor(d)})
                            .attr("stroke",(_)=>{return "white"})
                            .style("fill", (_)=>{return "white"})
                            .style("background", (d)=>{return GetColor(d)})
                            .style("color",(d)=>{return Colorize(d)})
                                
                            // .on("click",(_,d)=>{
                            //     if(d.value==="next page" && page < maxpage){
                            //         page = page+1
                            //         MakeTable()
                            //     } else if(d.value==="prev page" && page>1) {
                            //         page = page - 1
                            //         MakeTable()
                            //     } else {
                            //         console.log(d.value)
                            //     }
                            // });

                            if (maxpage>1){
                                let lastrow = table.append("tr");
                                lastrow.append("td").attr("class","tbl_rwpgbtn").text("Prev Page").on("click",(_,d)=>{
                                    if (page>1){
                                        page = page - 1
                                        MakeTable()
                                    }
                                });
                                lastrow.append("td").style("text-align","center").text(String(page) + "/" + String(maxpage))

                                // Add the second column to the new row
                                lastrow.append("td").attr("class","tbl_rwpgbtn").text("Next Page").on("click",(_,d)=>{
                                    if (page<maxpage){
                                        page = page + 1
                                        MakeTable()
                                    }
                                });
                            }
            return cells
     }

    
    let elems = MakeTable()

    return elems;
    
};
export default CreateTableD3
