import { Emp_State } from '../../../../type';
import ReturnValfromType from '../../../../functions/ReturnValfromType';
import GetObjectValue from '../../../../functions/Object Functions/GetObjectValue';

const Employee_Dropdown = (props: any) => {

	let Employees: Emp_State[] = props.Employees;
	let change = props.change
	let keyfield: string = props.keyfield
	let textfield: string = props.textfield
	let classname: string = props.classname
	let Items: any = [];

	return (
		<select id="dropdown_models" onChange={change} className={classname}>
			<option key='1'  >
				Select Item Name
			</option>
			{
				Employees.map((d: Emp_State, i: number) => {
					if (Items.indexOf(GetObjectValue(d, keyfield)) === -1) {
						Items.push(GetObjectValue(d, keyfield));
						return (
							<option key={"r" + i} value={i} data-val={d.EmpID} data-name={ReturnValfromType(d, keyfield)}>
								{ReturnValfromType(d, textfield)}
							</option>
						)
					}
				})
			}
		</select>
	)
};
export default Employee_Dropdown;