import { Pagemap } from "../type";

const TutorialPageMap = () => {


    let gc1 = { Div: "gc_mini1", Batch: 0, Name: "Report Basics", Text: "This contains the metric and date parameters", Task: "None" }
    let gc1a = { Div: "gc_b1Metr", Batch: 0, Name: "1a: Metrics", Text: "Select the report metrics (up to 4)", Task: "None" }
    let gc1b = { Div: "gc_b1AOD", Batch: 0, Name: "1b: As of Date", Text: "Select the As of Date (end date if date range)", Task: "None" }
    let gc1c = { Div: "gc_b1date", Batch: 0, Name: "1c: Timeframe", Text: "Select the date range/date period for the query to cover", Task: "None" }
    let gc2 = { Div: "gc_mini2", Batch: 1, Name: "Filters", Text: "This contains the filtering parameters", Task: "None" }
    let gc2a = { Div: "gc_b2flt", Batch: 0, Name: "2a: Filters", Text: "Select a Ruleset model (data cleanse) and filter model (data filtering)", Task: "None" }
    let gc3 = { Div: "gc_mini3", Batch: 2, Name: "Connections", Text: "This contains the report connection types (usually left default)", Task: "None" }
    let gc3a = { Div: "gc_b3conn", Batch: 2, Name: "3a: Report Connections", Text: "Select how tables should be joined, by active date", Task: "None" }
    let gc4 = { Div: "gc_mini4", Batch: 3, Name: "Grouping", Text: "This contains the grouping parameters", Task: "None" }
    let gc4a = { Div: "gc_b4xaxis", Batch: 3, Name: "Grouping", Text: "Select the X axis field (automatically 'date' if periodic report)", Task: "None" }
    let gc4b = { Div: "gc_b4grp", Batch: 3, Name: "Grouping", Text: "Select how the data should be grouped (if at all)", Task: "None" }
    let gc4c = { Div: "gc_b4band", Batch: 3, Name: "Grouping", Text: "Select the threshold for binning small groups", Task: "None" }


    let gm1 = { Div: "gm_qrylist_body", Batch: 0, Name: "Query List", Text: "Select a query here", Task: "None" }
    let gm2 = { Div: "gm_viewdivheader", Batch: 0, Name: "Actions", Text: "Take an action with the selected query", Task: "None" }

    let qry1 = { Div: "qmv_optsched", Batch: 0, Name: "Scheduler", Text: "Apply or run Schedules to the query", Task: "None" }
    let qry2 = { Div: "qmvs_schedddown", Batch: 0, Name: "Select Schedule", Text: "Select from existing schedules", Task: "None" }
    let qry3 = { Div: "qmvs_schedaddddown", Batch: 0, Name: "Apply Schedule", Text: "Add a schedule to this query", Task: "None" }
    let qry4 = { Div: "qmv_optvisual", Batch: 1, Name: "Visual Editor", Text: "Create or edit graphs and tables based on this query", Task: "None" }
    let qry5 = { Div: "qmvv_selddown", Batch: 1, Name: "Select Visualset", Text: "Select a visual set to edit or create one", Task: "None" }
    let qry6 = { Div: "qmvv_saveopt", Batch: 1, Name: "Save", Text: "Save visual set when complete", Task: "None" }
    let qry7 = { Div: "qmv_optruns", Batch: 2, Name: "Compare Runs", Text: "View or run ad hocs", Task: "None" }
    let qry8 = { Div: "qmvr_existrunbox", Batch: 2, Name: "Select Run", Text: "Select ", Task: "None" }
    let qry9 = { Div: "qmvr_vissetbox", Batch: 2, Name: "Select Visuals", Text: "Select a saved visual set to view", Task: "None" }
    let qry10 = { Div: "qmv_optinsp", Batch: 3, Name: "Inspector", Text: "Check KPIs for this query", Task: "None" }
    let qry11 = { Div: "qmvi_kpisel", Batch: 3, Name: "Select Options", Text: "Select existing KPI or create one", Task: "None" }




    let db1 = { Div: "dv_asofdate_ddown", Batch: 0, Name: "Select Date", Text: "Select the effective date of the dashboard", Task: "None" }
    let db2 = { Div: "dsh_selectoptdiv", Batch: 0, Name: "Select Options", Text: "Open options and inspect selected elements", Task: "None" }
    let db3 = { Div: "dsh_selpagediv", Batch: 0, Name: "Select Page", Text: "Toggle Dashboard Page", Task: "None" }
    let eu1 = { Div: "empupl_card1", Batch: 0, Name: "Report Type", Text: "Step 1: Select Report to Upload", Task: "None" }

    let emv1 = { Div: "emv_dateoptdiv", Batch: 0, Name: "Filter Models", Text: "Filter by date or simulation type", Task: "None" }
    let emv2 = { Div: "emv_mdldown", Batch: 0, Name: "Select Model Batch", Text: "Select from existing upload sets", Task: "None" }
    let emv3 = { Div: "emv_modelbox", Batch: 0, Name: "Select Model Batch", Text: "Select from existing upload sets", Task: "None" }
    let emv4 = { Div: "emv_valuetypeoptn", Batch: 0, Name: "Select Data Type", Text: "Select uploaded data or new values only", Task: "None" }
    let emv5 = { Div: "emv_fldlist", Batch: 0, Name: "Select Field", Text: "Select field from upload", Task: "None" }
    let emv6 = { Div: "emv_fldinfosub", Batch: 0, Name: "Select Item", Text: "Click an item to view details", Task: "None" }



    let edt1 = { Div: "edt_paramsdiv", Batch: 0, Name: "Table Parameters", Text: "Select Table Filter Parameters", Task: "None" }
    let edt2 = { Div: "edt_popbtn", Batch: 0, Name: "Populate", Text: "Click Populate to Show Data", Task: "None" }
    let edt3 = { Div: "edt_exportbtn", Batch: 0, Name: "Export", Text: "Click Export to download", Task: "None" }

    let ed1 = { Div: "empdet_idseldiv", Batch: 0, Name: "Select Empl", Text: "Select an Employee", Task: "None" }
    let ed2 = { Div: "empdet_histbox", Batch: 0, Name: "Select Emp Date", Text: "Select As of Date to View Detail", Task: "None" }
    let ed3 = { Div: "empdiv_careersel", Batch: 0, Name: "Select Career Model", Text: "Select from loaded Career Path Models", Task: "None" }
    let ed4 = { Div: "empdet_currposbox", Batch: 0, Name: "Check Attributes", Text: "Click each category to access additional details", Task: "None" }

    let jm1 = { Div: "jsm_inputskl", Batch: 0, Name: "Job Skills Import", Text: "Import an Excel file of job skills", Task: "None" }
    let jm2 = { Div: "jsm_inputcat", Batch: 0, Name: "Job Categories Import", Text: "Import an Excel file of job categories", Task: "None" }

    let jn1 = { Div: "jsm_inputcat", Batch: 0, Name: "Skills Network Model", Text: "Select an existing model to view", Task: "None" }

    let wf1 = { Div: "empsim_optrun", Batch: 0, Name: "Simulation Type", Text: "Base simulation on random dataset or existing data", Task: "None" }
    let wf2 = { Div: "empsim_datebox", Batch: 0, Name: "Select Dates", Text: "Create date slices for simulated data", Task: "None" }

    let kp1 = { Div: "kpim_seldiv", Batch: 0, Name: "Select KPI", Text: "Choose an existing KPI to investigate", Task: "None" }
    let kp2 = { Div: "kpim_selsched", Batch: 0, Name: "Select Schedule", Text: "Choose a schedule for this KPI", Task: "None" }
    let kp3 = { Div: "kpim_optinit", Batch: 0, Name: "Initialize", Text: "Start by creating a trend", Task: "None" }
    let kp4 = { Div: "kpim_analyzediv", Batch: 0, Name: "Analyze Trend", Text: "Click Analyze Trend to begin", Task: "None" }

    let ks1 = { Div: "ksm_seldiv", Batch: 0, Name: "Create New", Text: "Create a New KPI String", Task: "None" }
    let ks2 = { Div: "ksm_txtarea0", Batch: 0, Name: "Add Text", Text: "Add text to String", Task: "None" }
    let ks3 = { Div: "ksm_divchg0", Batch: 0, Name: "Add Text", Text: "Add text to String", Task: "None" }
    let ks4 = { Div: "ksim_refreshbtn", Batch: 0, Name: "Refresh", Text: "Click refresh to update total string", Task: "None" }

    let fd1 = { Div: "fv_modelddown", Batch: 0, Name: "Select Table", Text: "Select a Table to manage fields", Task: "None" }
    let fd2 = { Div: "fv_newfldbox", Batch: 0, Name: "Add Field", Text: "Input a new field using parameters displayed", Task: "None" }

    let tb1 = { Div: "tmgr_tableddown", Batch: 0, Name: "Create New Table", Text: "Select Add A New Table", Task: "None" }
    let tb2 = { Div: "tmgr_newtblnamediv", Batch: 0, Name: "Create Name", Text: "Type the name of this new table", Task: "None" }
    let tb3 = { Div: "tmgr_newtblfeaturesdiv", Batch: 0, Name: "Change Features", Text: "Edit the features of this table", Task: "None" }
    let tb4 = { Div: "tmgr_newtblsave", Batch: 0, Name: "Save Table", Text: "Click to save this table", Task: "None" }
    let tb5 = { Div: "tmgr_tableddown", Batch: 1, Name: "Manage Existing Table", Text: "Select Existing Table from dropdown", Task: "None" }
    let tb6 = { Div: "tmgr_mgopt", Batch: 1, Name: "Manage Table", Text: "Manage the table attributes here", Task: "None" }
    let tb7 = { Div: "tmgr_fldopt", Batch: 1, Name: "Add Table Fields", Text: "Add fields to this table before uploading", Task: "None" }
    let tb8 = { Div: "tmgr_uplopt", Batch: 1, Name: "Upload Data", Text: "Upload excel data here", Task: "None" }


    let rs1 = { Div: "rm_rulemdlddown", Batch: 0, Name: "Select Model", Text: "Select Create New Model", Task: "None" }
    let rs2 = { Div: "rm_newmdlnamediv", Batch: 0, Name: "Create Name", Text: "Give this new model a name", Task: "None" }
    let rs3 = { Div: "rm_addmdlbtn", Batch: 0, Name: "Add Model", Text: "Save this model by clicking 'add model' ", Task: "None" }
    let rs4 = { Div: "rm_rulemdlddown", Batch: 1, Name: "Select Model", Text: "Select an existing rule model", Task: "None" }
    let rs5 = { Div: "rsm_newrulediv", Batch: 1, Name: "Create New Rule", Text: "Click to create a new rule", Task: "None" }
    let rs6 = { Div: "rsm_ruleheader", Batch: 1, Name: "Edit New Rule", Text: "Adjust the properties of the new rule, tables, name, etc", Task: "None" }
    let rs7 = { Div: "rsm_castrulebtn", Batch: 1, Name: "Cast Rule", Text: "Cast this rule to the model when complete", Task: "None" }

    let tc1 = { Div: "conn_tblselddown", Batch: 0, Name: "Select Model", Text: "Select the Default model", Task: "None" }
    let tc2 = { Div: "conn_tblselbox", Batch: 0, Name: "Select Tables", Text: "Select tables to connect or check connection", Task: "None" }
    let tc3 = { Div: "conn_cboxcheck", Batch: 0, Name: "Check Fit", Text: "Check table joins using these options", Task: "None" }

    let sc1 = { Div: "scm_scheddown", Batch: 0, Name: "Create Schedule", Text: "Select Create a new schedule", Task: "None" }
    let sc2 = { Div: "sch_setparamsbox1", Batch: 0, Name: "Set Relative Date", Text: "Adjust the schedule options to set a relative date", Task: "None" }
    let sc3 = { Div: "sch_setparamsbox2", Batch: 0, Name: "Set Recurrence", Text: "Adjust the schedule options to create a series of dates", Task: "None" }
    let sc4 = { Div: "sch_savebtn", Batch: 0, Name: "Save Schedule", Text: "Save once completed", Task: "None" }

    let cs1 = { Div: "mcs_csddown", Batch: 0, Name: "Create Colorset", Text: "Select Create new color set", Task: "None" }
    let cs2 = { Div: "cse_newsetbox", Batch: 0, Name: "Select Field", Text: "Select a field for this color set", Task: "None" }
    let cs3 = { Div: "cse_itmlist", Batch: 0, Name: "Edit value colors", Text: "Select colors for each value", Task: "None" }

    let dm1 = { Div: "dmgrtbl_sidebar", Batch: 0, Name: "Manage Values", Text: "View Options to the left", Task: "None" }

    let lm1 = { Div: "lvm_selmdldiv", Batch: 0, Name: "Create New View", Text: "Select create new view", Task: "None" }
    let lm2 = { Div: "lvm_selkpidiv", Batch: 0, Name: "Select KPI", Text: "Select a KPI to add", Task: "None" }

    let sd1 = { Div: "ndm_modeldiv", Batch: 0, Name: "Create new model", Text: "Select option to create a new model", Task: "None" }
    let sd2 = { Div: "ndm_attrbox", Batch: 0, Name: "Add Attributes", Text: "Select attribute fields to group by", Task: "None" }
    let sd3 = { Div: "ndm_savebtn", Batch: 0, Name: "Save model", Text: "Save model when complete", Task: "None" }


    let bm1 = { Div: "bmm_selqrydiv", Batch: 0, Name: "Select Query", Text: "Select a query to begin", Task: "None" }
    let bm2 = { Div: "bmm_selmetrdiv", Batch: 0, Name: "Select Metric", Text: "Select a metric on this query", Task: "None" }
    let bm3 = { Div: "bmm_selmdldiv", Batch: 0, Name: "Create new model", Text: "Create a new benchmark model", Task: "None" }
    let bm4 = { Div: "bmm_step1btn", Batch: 0, Name: "Set fields", Text: "Select the fields that determine benchmarks", Task: "None" }

    let gf1 = { Div: "mgf_grptypdiv", Batch: 0, Name: "Select field type", Text: "Select field data type", Task: "None" }
    let gf2 = { Div: "mgf_fld_ddown", Batch: 0, Name: "Create new field", Text: "Select Create new grouping", Task: "None" }
    let gf3 = { Div: "mgf_selgrpfldnew", Batch: 0, Name: "Select field", Text: "Select field for this data", Task: "None" }
    let gf4 = { Div: "mgf_newgrpname", Batch: 0, Name: "Select field", Text: "Select Create new grouping", Task: "None" }
    let gf5 = { Div: "mgf_newfldsavebtn", Batch: 0, Name: "Save", Text: "Save this new grouping", Task: "None" }
    let gf6 = { Div: "mgf_grptypdiv", Batch: 0, Name: "Select field type", Text: "Select field data type", Task: "None" }
    let gf7 = { Div: "mgf_fld_ddown", Batch: 0, Name: "Select field", Text: "Select existing grouping", Task: "None" }
    let gf8 = { Div: "mgf_fld_ddown", Batch: 0, Name: "Select field", Text: "Select Create new grouping", Task: "None" }

    let jp1 = { Div: "jpv_ddown", Batch: 0, Name: "Select job posting", Text: "Select an Existing Job Posting", Task: "None" }
    let jp2 = { Div: "jpvoptbtnview", Batch: 0, Name: "Click View", Text: "Click View to see details", Task: "None" }
    let jp3 = { Div: "jpv_detailoptbox", Batch: 0, Name: "Select Detail Type", Text: "Click a detail type to view", Task: "None" }
    let jp4 = { Div: "jpv_applbox", Batch: 0, Name: "Select Applicant", Text: "Select an applicant to view skills/resume", Task: "None" }
    let jp5 = { Div: "jpv_applskillddown", Batch: 0, Name: "Select Resume", Text: "Select a resume to see fitness details", Task: "None" }

    let ai1 = { Div: "ai_mainddown", Batch: 0, Name: "Select Model Category", Text: "Select category of model", Task: "None" }
    let ai2 = { Div: "aim_modelddown", Batch: 0, Name: "Select AI Model", Text: "Select specific model for this category", Task: "None" }
    let ai3 = { Div: "aim_chatddown", Batch: 0, Name: "Select Chat", Text: "Select a chat if available", Task: "None" }
    let ai4 = { Div: "aim_newchatbtn", Batch: 0, Name: "Create New Chat", Text: "Or create a new chat", Task: "None" }


    let exportval: Pagemap[] = [
        {
            Path: '/query/creategraph', BatchCount: 4, Name: "Create Graph", Detail: {
                Text: "Create a Query using Prompts", Steps:
                    [gc1, gc1a, gc1b, gc1c, gc2, gc2a, gc3, gc3a, gc4, gc4a, gc4b, gc4c]
            }
        },
        { Path: '/query/managegraph', BatchCount: 1, Name: "Manage Graph", Detail: { Text: "Manage created Queries", Steps: [gm1, gm2] } },
        {
            Path: '/graph/', BatchCount: 4, Name: "Graph", Detail: {
                Text: "Perform tasks related to this query",
                Steps: [qry1, qry2, qry3, qry4, qry5, qry6, qry7, qry8, qry9, qry10, qry11]
            }
        },
        { Path: '/employee/upload', BatchCount: 1, Name: "Employee Upload", Detail: { Text: "Import demographic data from excel", Steps: [eu1] } },
        {
            Path: '/employee/modelviewer', BatchCount: 1, Name: "Employee Model Viewer", Detail: {
                Text: "View imported demographic data", Steps: [
                    emv1, emv2, emv3, emv4, emv5, emv6]
            }
        },
        { Path: '/employee/table', BatchCount: 1, Name: "Employee Table Viewer", Detail: { Text: "View Tabular Employee Data", Steps: [edt1, edt2, edt3] } },
        { Path: '/employee/detail', BatchCount: 1, Name: "Employee Detail View", Detail: { Text: "View Employee-Specific Detail", Steps: [ed1, ed2, ed3, ed4] } },

        { Path: '/dashboard/manager', BatchCount: 1, Name: "Dashboard Manager", Detail: { Text: "Create a dashboard from saved graphs/tables", Steps: [] } },
        { Path: '/dashboard/select', BatchCount: 1, Name: "Dashboard Select", Detail: { Text: "Select a created dashboard", Steps: [] } },
        { Path: '/dashboard/live', BatchCount: 1, Name: "Live Dashboard", Detail: { Text: "Live dashboard:", Steps: [db1, db2, db3] } },

        { Path: '/jobdescription/manager', BatchCount: 1, Name: "Job Desc Manager", Detail: { Text: "Import Job Skills, Models", Steps: [jm1, jm2] } },
        { Path: '/jobdescription/netmodel', BatchCount: 1, Name: "Job Desc Network", Detail: { Text: "Network Viewer for Job Skills Model", Steps: [jn1] } },
        { Path: '/jobdescription/skills', BatchCount: 1, Name: "Job Skills Viewer", Detail: { Text: "View Job Skills by Job", Steps: [] } },

        { Path: '/analytics/simulation', BatchCount: 1, Name: "Workforce Simulator", Detail: { Text: "Create Workforce forecasted data", Steps: [wf1, wf2] } },

        { Path: '/kpis/manager', BatchCount: 1, Name: "KPI Manager", Detail: { Text: "Manage Created KPIs", Steps: [kp1, kp2, kp3, kp4] } },
        { Path: '/kpis/stringmanager', BatchCount: 1, Name: "KPI String Manager", Detail: { Text: "Create KPI Strings for Dashboard", Steps: [ks1, ks2, ks3, ks4] } },

        { Path: '/fields/viewer', BatchCount: 1, Name: "Table Fields Manager", Detail: { Text: "Manage Fields for Data Model Tables", Steps: [fd1, fd2] } },

        {
            Path: '/tables/manager', BatchCount: 2, Name: "Table Manager", Detail: {
                Text: "Manage Secondary Tables", Steps:
                    [tb1, tb2, tb3, tb4, tb5, tb6, tb7, tb8]
            }
        },
        { Path: '/tables/connections', BatchCount: 1, Name: "Table Connections Mgr", Detail: { Text: "Manage Table Connection Models", Steps: [tc1, tc2, tc3] } },
        { Path: '/datamanager/department', BatchCount: 1, Name: "Department Manager", Detail: { Text: "Manage the Department table", Steps: [dm1] } },
        { Path: '/datamanager/position', BatchCount: 1, Name: "Position Manager", Detail: { Text: "Manage the Position table", Steps: [dm1] } },
        { Path: '/datamanager/jobtitle', BatchCount: 1, Name: "Job Title Manager", Detail: { Text: "Manage the Job Title table", Steps: [dm1] } },


        { Path: '/schedules/manager', BatchCount: 1, Name: "Schedule Manager", Detail: { Text: "Create a new schedule or manage existing", Steps: [sc1, sc2, sc3, sc4] } },

        { Path: '/colorsets/manager', BatchCount: 1, Name: "Colorsets Manager", Detail: { Text: "Create or manage color models", Steps: [] } },
        { Path: '/colorsets/editor', BatchCount: 1, Name: "Colorsets Editor", Detail: { Text: "Create or manage color sets", Steps: [cs1, cs2, cs3] } },
        { Path: '/leader/manager', BatchCount: 1, Name: "Leader View Manager", Detail: { Text: "Create or manage Leader Views", Steps: [lm1, lm2] } },
        { Path: '/sna/view', BatchCount: 1, Name: "Network Viewer", Detail: { Text: "View a created network model", Steps: [] } },
        { Path: '/sna/data', BatchCount: 1, Name: "Network Data Manager", Detail: { Text: "Create a network model", Steps: [sd1, sd2, sd3] } },

        { Path: '/benchmark/manager', BatchCount: 1, Name: "Benchmark Manager", Detail: { Text: "Upload benchmarks to a query model", Steps: [bm1, bm2, bm3, bm4] } },
        {
            Path: '/groupsets/manager', BatchCount: 1, Name: "Groupsets Manager", Detail: {
                Text: "Create or manage Grouped fields", Steps: [
                    gf1, gf2, gf3, gf4, gf5, gf6, gf7, gf8]
            }
        },

        {
            Path: '/rulesets/manager', BatchCount: 1, Name: "Rulesets Manager", Detail: {
                Text: "Create Rules to clean data",
                Steps: [rs1, rs2, rs3, rs4, rs5, rs6, rs7]
            }
        },
        { Path: '/jobposting/viewer', BatchCount: 1, Name: "Job Posting Manager", Detail: { Text: "View and Upload Job Posting Data", Steps: [jp1, jp2, jp3, jp4, jp5] } },

        { Path: '/ai/model', BatchCount: 1, Name: "AI Model Manager", Detail: { Text: "Interact with AI model on datasets", Steps: [ai1, ai2, ai3, ai4] } },

    ]
    return exportval
};
export default TutorialPageMap 