import AxiosUpload from '../AxiosUpload';


const ColorSetReattach = async (schemakey: string, modelkey: string, colsetkey: string,
    assign_unassign: string, config: any, elementstring: string) => {

    let dataArray = new FormData();


    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("schemakey", schemakey);
    dataArray.append("modelkey", modelkey);
    dataArray.append("setkey", colsetkey);
    dataArray.append("assign_unassign", assign_unassign)

    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/colorsets/reattachcolorset", config, elem, "");

    return ("Ok")
};

export default ColorSetReattach;