import { QueryModel, QueryRunDate, QueryVisuals, queryvisualstate, Metric, KPI, BasicQuery, FieldMap, FilterField } from "../../../type";
import { ChangeEvent, useContext, useEffect, useState, useMemo } from "react";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import { StartupContext } from "../../../App";
import ChartConst from "../../../charts/ChartConst";
import { BasicQueryInit, KPIInit, queryvisInit } from "../../../InitTypes";
import "./css/QueryModelViewInspector.css"
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import DateString from "../../../functions/Date Functions/DateString";
import ConvertChartTypetoShow from "../../../functions/QueryFunctions/ConvertChartTypetoShow";
import { Modal } from "react-bootstrap";
import GetQueryRunDateKey from "../../../functions/QueryFunctions/GetQueryRunDateKey";
import ExcelExport from "../../../functions/ExcelExport";
import QueryFilterValues from "../../../functions/QueryFunctions/QueryFilterValues";
import GetFieldName from "../../../functions/GetFieldName";
import CheckObjectKey from "../../../functions/Object Functions/CheckObjectKey";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import RunDateUpdate from "../../../functions/QueryFunctions/RunDateUpdate";
import QueryVisualsPrepare from "../../../functions/QueryFunctions/QueryVisualsPrepare";
import { Link } from "react-router-dom";
import IdentifyKPIElement from "../../../functions/KPI Functions/IdentifyKPIElement";


const QueryModelViewInspector = (props: any) => {

    let query: QueryModel = props.query
    let fields: FieldMap[] = props.fields
    let setQuery: any = props.setQuery

    interface paramstype {
        inspect: boolean; msg: string; selrundatekey: string; selrundateAsOf: Date;
        issavekpivisible: boolean; isdelkpivisible: boolean; refresh: number; selrow: any;
        kpiname: string; kpinote: string; rerun: number;
    }

    const { config, schema, user, paths } = useContext(StartupContext)
    const [vis_state, setVis_State] = useState<queryvisualstate[]>([{ ...queryvisInit[0], clickevent: "n/a" }])
    const [queryvis, setQueryVis] = useState<QueryVisuals | null>(null)
    const [qryvisall, setQryvisAll] = useState<QueryVisuals[]>([])
    const [keyparams, setKeyParams] = useState<paramstype>({
        inspect: false, msg: "", selrundatekey: '', selrundateAsOf: new Date(),
        issavekpivisible: false, isdelkpivisible: false, refresh: 0, selrow: {}, kpiname: '', kpinote: '', rerun: 0
    })
    const [selBasicRow, setSelBasicRow] = useState<any | null>(null)
    const [metrics, setMetrics] = useState<Metric[]>([])
    const [kpis, setKPIs] = useState<KPI[]>([])
    const [selKPI, setSelKPI] = useState<KPI | null>(null)
    const [kpielem, setKPIelem] = useState<any | null>(null)
    let [filterValueData, setFilterValueData] = useState<any>({})


    // const [colorModels, setColorModels] = useState<ColorModel[]>([])
    // const [qryvisall, setQryvisAll] = useState<QueryVisuals[]>([])
    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        ImportAndSetStateGeneric(setMetrics, "", schema, paths.query.metricview, user, config, {})
        ImportAndSetStateGeneric(setKPIs, query.ModelKey, schema, paths.kpi.kpiview, user, config, {})
        ImportAndSetStateGeneric(setQryvisAll, query.ModelKey, schema, paths.query.queryvisualsview, user, config, {})
    }, []);


    useEffect(() => {
        RunDateUpdate(keyparams.selrundatekey, schema, query, setQuery, config)

        setFilterValueData(QueryFilterValues(query, keyparams.selrundatekey))
        if (keyparams.selrundatekey !== "") {
            query.rundates.forEach((rd: QueryRunDate) => {
                if (rd.DateKey === keyparams.selrundatekey) {
                    setKeyParams({ ...keyparams, selrundateAsOf: rd.AsofDate })
                }
            })
        }
    }, [keyparams.selrundatekey])

    useEffect(() => {
        setKeyParams({ ...keyparams, rerun: keyparams.rerun + 1 })
    }, [query])

    useEffect(() => {
        let flds = Object.keys(filterValueData).map((k: string) => { return (k) })
        let vals = Object.keys(filterValueData).map((k: string) => { return (filterValueData[k].Selected) })
        if (queryvis) {
            let tmpstate = { ...vis_state[0], filterfields: flds, filtervals: vals, show: ConvertChartTypetoShow(queryvis.ChartType) }
            setVis_State([tmpstate])
        }
    }, [filterValueData, queryvis?.VisKey])

    useEffect(() => {
        if (qryvisall.length > 0) {
            qryvisall.forEach((qv: QueryVisuals) => {
                if (qv.VisName === "Default") {
                    QueryVisualsPrepare(qv, setQueryVis)
                }
            })
        }
    }, [qryvisall])


    useEffect(() => {
        if (queryvis) {
            let oldevent = vis_state[0].clickevent
            let newvis_state = { ...queryvisInit[0], clickevent: oldevent, show: ConvertChartTypetoShow(queryvis.ChartType) }
            setVis_State([newvis_state])
            setKeyParams({ ...keyparams, selrundatekey: GetQueryRunDateKey(queryvis, query.rundates) })
        }
    }, [queryvis?.VisKey])



    useEffect(() => {
        //find matching element, given a selected KPI
        if (selKPI && keyparams.selrundatekey !== "") {

            let foundelem = IdentifyKPIElement("ndtchartelem", selKPI, keyparams.selrundatekey, query)
            let newstate: queryvisualstate[] = queryvisInit

            if (foundelem) {
                newstate = [{
                    ...vis_state[0],
                    clickevent: "",
                    selEncKey: foundelem.attributes['data-id'].value,
                    selChartElemIndex: foundelem.attributes.id.value,
                    selChartElemMetricNo: foundelem.attributes['data-metricno'].value,
                    selectedmetric: foundelem.attributes['data-metrickey'].value
                }]
            }

            if (kpielem) {
                let basecolor = kpielem.attributes['basecolor'].value
                kpielem.style.backgroundColor = basecolor
                kpielem.style.stroke = basecolor
                kpielem.style.fill = basecolor
            }
            setVis_State(newstate)
            setKPIelem(foundelem)
        }
        //}, [selKPI, queryvis, keyparams.selrundatekey])
    }, [selKPI, query, keyparams.selrundatekey])


    //backend calls------------
    const ClickInspect = () => {
        if (vis_state[0].selChartElemIndex !== "") {
            setKeyParams({ ...keyparams, inspect: true, msg: '' })
            let bpromise = GenericBackendCall("", schema, paths.generics.genericfilterview, user, config,
                { model: "BasicQuery", filterfield: "id", filtervalue: vis_state[0].selChartElemIndex })
            bpromise.then(d => { setSelBasicRow(SerializeDataFrame(d)[0]) })
            setSelKPI(KPIInit[0])
        } else {
            setKeyParams({ ...keyparams, msg: "No Value Selected" })
        }
    };

    const ClickElem = (val: string) => {
        vis_state[0].inspect = val;
        setVis_State(vis_state);
        GenericBackendCall("", schema, paths.answers.default, user, config,
            { type: val, valueid: vis_state[0].selChartElemIndex, metricno: vis_state[0].selChartElemMetricNo }, "tte_divnote").then(d => {
                let returndata = SerializeDataFrame(d)
                setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
            })
    };

    const ClickSaveKPI = () => {
        if (+vis_state[0].selChartElemMetricNo >= 0) {
            GenericBackendCall("", schema, paths.kpi.kpipost, user, config, {
                valueid: vis_state[0].selChartElemIndex, name: keyparams.kpiname, selmetric: vis_state[0].selectedmetric,
                selmetricno: vis_state[0].selChartElemMetricNo, note: keyparams.kpinote, workspacekey: ''
            }, "qmvi_divsave").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        } else {
            let elem = document.getElementById("qmvi_divsave")
            if (elem) { elem.innerHTML = "Nothing Selected" }
        }
    };


    const ClickDeleteKPI = () => {
        if (selKPI) {
            GenericBackendCall(selKPI.Key, schema, paths.generics.genericdelete, user, config, { model: "KPI", field: "Key" }, "qmvi_divdel").then(_ => {
                setKeyParams({ ...keyparams, refresh: 99 })
            })
        }
    };

    const ClickExport = (exportall: boolean) => {
        let params: any = { model: "BasicQuery", filtervalue: keyparams.selrundatekey, filterfield: "QueryDate" }
        let filename: string = "querydata_" + query.ModelName + "_" + DateString(keyparams.selrundateAsOf)
        if (exportall) {
            params = { model: "BasicQuery", filtervalue: query.ModelKey, filterfield: "ModelName" }
            filename = "querydata_" + query.ModelName + "_all"
        }
        GenericBackendCall("", schema, paths.generics.genericfilterview, user, config, params).then(d => {
            let data = SerializeDataFrame(d)
            ExcelExport({ csvDataArry: [data], sheetNames: ["dataset"], filename: filename })
        })
    }

    const ChangeFilter = (e: ChangeEvent<HTMLSelectElement>, fld: string) => {
        let selval = e.target.selectedOptions[0].attributes[1].value
        setFilterValueData({ ...filterValueData, [fld]: { ...filterValueData[fld], Selected: selval } })
    }
    ///---------------------

    let chartconstmemo = useMemo(() => {
        return <ChartConst query={query} setQuery={setQuery} svgid="ttisros" vis_state={vis_state}
            setVis_State={setVis_State} qryvis={queryvis} stateIdx={0} style={{ "backgroundColor": "white" }}
            rundatekey={keyparams.selrundatekey}
            metrics={metrics}
            size={[650, 650]}
            rerun={keyparams.rerun}
        />
    }, [query, queryvis, keyparams.selrundatekey, vis_state, metrics])

    const ChangeQueryVis = (e: ChangeEvent<HTMLSelectElement>) => {
        let val = +e.target.selectedOptions[0].attributes[0].value
        if (val >= 0) {
            setQueryVis(QueryVisualsPrepare(qryvisall[val], null))
        }
    }

    return (<div className="ndt_canvas" id="qmvi_canvas">
        <div>
            <div className="ndt_title2" id="qmvi_header">Chart Investigate</div>
            <div id="qmvi_selbox" className="ndt_gridbox">
                {keyparams.selrundatekey !== "" ?
                    <div>Run Date: {DateString(keyparams.selrundateAsOf)}</div> :
                    <div></div>}
                <GenericDropdown
                    data={qryvisall}
                    keycol="VisKey"
                    namecol="VisName"
                    promptstring="Select Visual Set"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeQueryVis(e) }}
                />
                <GenericDropdown
                    data={query.rundates}
                    promptstring="Select Run"
                    keycol="DateKey"
                    namecol="AsofDate"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, query.rundates, keyparams, setKeyParams, "selrundatekey", "DateKey", "") }}
                />
                <GenericDropdown
                    data={kpis}
                    keycol="Key"
                    namecol="KPIName"
                    promptstring="Select Existing KPI"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, kpis, setSelKPI, "", KPIInit[0]) }}
                    includeDefault={true}
                    divID="qmvi_kpisel"
                    defaultstring="None - add new"
                />

                {queryvis ?
                    <div>
                        <div className="ndt_innerbox" style={{ height: "200px" }}>
                            {selKPI ?
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <button className="ndt_btn3" style={{ width: '200px' }} onClick={_ => { ClickInspect() }}>Inspect Selected</button>

                                        <div >
                                            {selKPI.EncodedKey != "" ?
                                                <button className="ndt_btn2" style={{ width: '200px' }} onClick={_ => { setKeyParams({ ...keyparams, isdelkpivisible: true }) }}>Delete KPI</button>
                                                : <div>
                                                    {vis_state[0].selEncKey !== "" ?
                                                        <button className="ndt_btn3" style={{ width: '200px' }} onClick={_ => { setKeyParams({ ...keyparams, issavekpivisible: true }) }}>Save KPI</button>
                                                        : <div></div>}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {selBasicRow ?
                                        <div className="ndt_subinner" style={{ fontSize: "15px" }}>
                                            <button className="ndt_btn1" onClick={_ => { ClickElem("Value History") }}>Value History</button>
                                            <button className="ndt_btn1" onClick={_ => { ClickElem("Forecast") }}>Forecast</button>
                                            <button className="ndt_btn1" onClick={_ => { ClickElem("Factor Analysis") }}>Factor Analysis</button>
                                            <button className="ndt_btn1" onClick={_ => { ClickElem("Values Overview") }}>Values Overview</button>
                                            <button className="ndt_btn1" ><Link to={"/kpis/manager/" + selKPI.Key}>KPI Manager</Link></button>
                                            <div>
                                                <div>Metric: {GetObjectField(query.Metrics[selBasicRow.MetricNo], metrics, "MetricKey", "MetricName")}</div>
                                                <div>Var: {String(selBasicRow.Variable)}</div>
                                                {query.GroupFieldName ? <div>Grp: {String(selBasicRow.Grouping)}</div> : <div></div>}
                                                <div>N: {String(selBasicRow.N)}</div>
                                            </div>

                                        </div>
                                        : <div>{keyparams.msg}</div>}

                                </div> : <div></div>}
                        </div>
                    </div>
                    : <div></div>}
            </div>
        </div>

        <div style={{ marginTop: "50px" }}>
            {queryvis ?
                <div>{chartconstmemo}</div>
                : <div>Select a visualset first</div>}
        </div>
        <div className="ndt_innerbox" style={{ height: "200px" }}>
            <div style={{ fontSize: "18px" }}>Filters:</div>
            <div style={{ height: "100px" }}>
                {query.filterfields.map((flt: FilterField, i: number) => {
                    if (CheckObjectKey(filterValueData, flt.FieldName)) {
                        return (
                            <div key={i}>
                                <GenericDropdown
                                    data={filterValueData[flt.FieldName].Values}
                                    keycol={""}
                                    promptstring={"Select " + GetFieldName(fields, flt.FieldName, "Key", "FieldName")}
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeFilter(e, flt.FieldName) }}
                                />
                            </div>
                        )
                    }
                })}
            </div>
            <div style={{ position: "relative" }}>
                <div>Export Run to Excel</div>
                <button className="ndt_btn1" onClick={_ => { ClickExport(false) }}>Export</button>
                <button className="ndt_btn1" onClick={_ => { ClickExport(true) }}>ExportAll</button>
            </div>
        </div>


        <Modal show={keyparams.issavekpivisible}>
            <div className="ndt_modal">
                {vis_state[0].selChartElemIndex !== "" ?
                    <div>
                        <div>Save KPI</div>
                        <div>Name</div>
                        <input type="text" value={keyparams.kpiname} onChange={e => setKeyParams({ ...keyparams, kpiname: e.target.value })} />

                        <div>Note</div>
                        <input type="text" value={keyparams.kpinote} onChange={e => setKeyParams({ ...keyparams, kpinote: e.target.value })} />

                        <button className="ndt_btn1" style={{ width: "150px", margin: '10px' }} onClick={_ => { ClickSaveKPI() }}>Save</button>
                        <div id="qmvi_divsave"></div>
                    </div> : <div>None Selected</div>}
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavekpivisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdelkpivisible}>
            <div className="ndt_modal">
                <div>Delete KPI {selKPI?.KPIName}</div>
                <button className="ndt_btn2" style={{ width: "150px", margin: '10px' }} onClick={_ => { ClickDeleteKPI() }}>Delete</button>
                <div id="qmvi_divdel"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelkpivisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>
    </div>)
};

export default QueryModelViewInspector