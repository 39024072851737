
function ListSort(data,ascending=true) {

    data.sort((a, b) => {
        let fa = a.toLowerCase(),
            fb = b.toLowerCase();
    
        if (fa < fb) {
            if (ascending){
                return -1;
            } else {
                return 1;
            }
        }
        if (fa > fb) {
            if (ascending){
                return 1;
            } else {
                return -1;
            }
        }
        return 0;
    });
    
    return(data)
  }

  export default ListSort;
