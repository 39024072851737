

import * as d3 from 'd3';

const ChartDelete = (svgid) => {
    //ref: createchart, samplechart, datasetwalkthrough
    const svgrmv = d3.select(`${svgid}`);

    
    svgrmv.select("svg").remove();
    svgrmv.select("table").remove();
};
export default ChartDelete;
