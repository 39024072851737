import { KPI, KPIFilter } from "../../../type"
import KSFormatSingle from "./KSFormatSingle";
import GetKPIValue from "./GetKPIValue";


const ConvertKPIString = (kpistring: string, ukpis: KPI[], asofdate: Date | null = null, returnparse: boolean = false, withnewlines: boolean = false) => {
    //called by: kpistringmanager (not newlines), dashboardview (newlines)

    let fixedstrings: any[] = [];

    if (withnewlines) {
        let supersplit = kpistring.split("#nl#")
        let splitstrings: string[][] = supersplit.map((s: string) => {
            return s.split("}]")
        })
        fixedstrings = ConvertAllStrwithLines(splitstrings, ukpis, asofdate)
    } else {
        let splitstrings: string[] = kpistring.split("}]")
        fixedstrings = ConvertAllStr(splitstrings, ukpis, asofdate)
    }



    if (!returnparse) {
        let finalstring = "";
        fixedstrings.forEach((str1: any[]) => {
            finalstring = finalstring + str1[0] + str1[1]
        })
        return finalstring

    } else {
        if (withnewlines) {
            return fixedstrings
        } else {
            let txtstrs: string[] = [];
            let kpistrs: string[] = [];
            fixedstrings.forEach((str_arry: any[]) => {
                txtstrs.push(EncodeString(str_arry[0]))
                kpistrs.push(str_arry[1])
            })
            return [txtstrs, kpistrs]
        }
    }

};

export default ConvertKPIString;


const ConvertAllStr = (splitstrings: string[], ukpis: KPI[], asofdate: Date | null) => {

    return splitstrings.map((str1: string) => {
        let txtpair = StringKPIMatch(str1, ukpis, asofdate)
        return txtpair
    })
}

const ConvertAllStrwithLines = (splitstrings: string[][], ukpis: KPI[], asofdate: Date | null) => {

    let allsplit: string[][][] = []
    splitstrings.forEach((strs: string[]) => {
        let linestrings: string[][] = []
        strs.forEach((str1: string) => {
            let txtpair = StringKPIMatch(str1, ukpis, asofdate)
            linestrings.push(txtpair)
        })
        allsplit.push(linestrings)
    })

    return allsplit
}

export const StringKPIMatch = (str1: string, ukpis: KPI[], asofdate: Date | null) => {

    let stxt = ""
    let kpifmt = ""
    if (str1 !== "") {
        stxt = str1.substring(0, str1.indexOf("["))
        let skey = str1.substring(str1.indexOf("[") + 1, str1.indexOf("<"))

        let encfltkey = str1.substring(str1.indexOf("<") + 1, str1.indexOf(">"))
        let fmt = str1.substring(str1.indexOf("{") + 1, str1.length)
        let found = false
        ukpis.forEach((kpi: KPI) => {
            if (kpi.Key === skey) {
                found = true
                let kpival = GetKPIValue(kpi, encfltkey, asofdate)
                kpifmt = KSFormatSingle(fmt, kpival) //new formatted string (kpi)
            }
        })
        if (!found) {
            stxt = str1
        }
        stxt = EncodeString(stxt)
    }
    return [stxt, kpifmt]
}


export const EncodeString = (str1: any) => {
    if (typeof (str1) == "string") {
        return str1.replace(/\n/g, '#nl#')
    } else {
        return str1
    }

}