import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { createContext, useEffect, useState, useContext } from 'react';
import OnLoad from './OnLoad';
import { useDispatch, useSelector } from 'react-redux';
import GetAuthConfigHeaders from './context/GetAuthConfigHeaders';
import EmployeeModelViewer from './pages/data/employees/EmployeeModelViewer';
import { AuthProvider } from './context/AuthContext';
import { User, UserAccount, PathList, Schema, clickstyle, OtherValues } from './type';
//import GetFieldMapping from './functions/GetFieldMapping';
import { FieldMap } from './type';

import Navbar from './pages/Navbar';
import Home from './pages/home';
import TitlePage from './pages/TitlePage';
import Login from './pages/user/login/Login';
import Logout from './pages/user/login/Logout';
import SignUp from './pages/user/login/SignUp';

import SingleEmployeePage from './pages/data/employees/SingleEmployeePage';
import GraphEditor from './pages/data/graphs/GraphEditor';
import RulesetsManager from './pages/data/rulesets/RulesetsManager';
import FieldsManager from './pages/data/fields/FieldsManager';
import AdminOptions from './pages/user/AdminOptions';
import ModelUploader from './pages/data/employees/ModelUploader';
import PreDeptTreeViewer from './pages/data/employees/PreDeptTreeViewer';
import DeptViewer from './pages/data/employees/DeptViewer';
import EmployeeDataTable from "./pages/data/employees/EmployeeDataTable"
import SchemaManage from './pages/data/schema/SchemaManage';
import GetSchema from './functions/GetSchema';
import FilterSetsManage from './pages/data/filtersets/FiltersetsManage';
import DashboardsManager from './pages/data/dashboards/DashboardsManager';
import DashboardView from './pages/data/dashboards/DashboardView';
import DashboardNotesPage from './pages/data/dashboards/DashboardNotesPage';
import DashboardSelect from './pages/data/dashboards/DashboardSelect';
import QueryAuto from './pages/data/graphs/QueryAuto';
import GraphCreator from './pages/data/graphs/GraphCreator';
import PermissionsManager from './pages/user/permissions/PermissionsManager';
import SetsTracker from './pages/data/fields/SetsTracker';


import { AppColorsInit, FieldMapInit, UserInit, UserAccountInit } from './InitTypes';
import GraphManager from './pages/data/graphs/GraphManager';
import QueryModelView from './pages/data/graphs/QueryModelView';

import ThreeTest from './three/ThreeTest';
import ThreeParticles from './three/ThreeParticles';

import NetworkPage from './pages/sna/networkgraph/NetworkPage';
import NetworkDataManage from './pages/sna/networkgraph/NetworkDataManage';

import ColorSetEditor from './pages/data/colorsets/ColorSetEditor';
import ColorModelManage from './pages/data/colorsets/ColorModelManage';

import "./App.css"
import UserPage from './pages/user/UserPage';
import AllPaths from './context/AllPaths';
import TrackerPage from './pages/user/tracker/TrackerPage';
import "./modernstyling.css"
import GroupSetsManager from './pages/data/groupsets/GroupSetsManager';
import MetricsManager from './pages/data/graphs/MetricsManager';
import TableMgr from './pages/data/tables/TableMgr';
import ConnectionManager from './pages/data/tables/ConnectionManager';
import ScheduleManager from './pages/data/schedules/ScheduleManager';
import EmployeeAuditView from './pages/data/employees/EmployeeAuditView';
import DataManager from './pages/data/tables/DataManager';
import SimulateEmployee from './pages/analytics/simulation/SimulateEmployee';
import GetWorkspace from './functions/GetWorkspace';
import BenchmarkManager from './pages/data/graphs/BenchmarkManager';
import GenericBackendCall from './functions/Import/GenericBackendCall';
import KPIStringManager from './pages/data/graphs/kpis/KPIStringManager';
import KPIManager from './pages/data/graphs/kpis/KPIManager';
import LogoutAuth from './pages/user/login/functions/LogoutAuth';
import GetClickStyles from './functions/GetClickStyles';
import ValueTracker from './pages/data/fields/ValueTracker';
import LeaderViewManager from './pages/data/dashboards/leader/LeaderViewManager';
import LeaderView from './pages/data/dashboards/leader/LeaderView';
import JobSkillManager from './pages/data/jobdescriptions/JobSkillManager';
import JobDescNetGraph from './pages/data/jobdescriptions/JobDescNetGraph';
import JobDescriptionBase from './pages/data/jobdescriptions/JobDescriptionsBase';
import StaffingModelManager from './pages/data/staffing/StaffingModelManager';
import LoginAuth from './pages/user/login/functions/LoginAuth';
import OpenAI from './pages/analytics/ai/OpenAI';
import EmployeeAtAGlance from './pages/data/employees/EmployeeAtAGlance';
import EmployeeDetail from './pages/data/employees/EmployeeDetail';
import TutorialDiv from './pages/TutorialDiv';
import JobPostingView from './pages/analytics/postings/JobPostingView';
import CategoryModelAI from './pages/data/jobdescriptions/CategoryModelAI';
import AIModelManager from './pages/analytics/ai/AIModelManager';
import StatusChecker from './pages/StatusChecker';
import DatasetWalkthrough from './pages/data/DatasetWalkthrough';
import ScenarioBuilder from './pages/analytics/scenario/ScenarioBuilder';
import GenericDataTable from './pages/data/tables/GenericDataTable';
import AIQueryPrompt from './pages/analytics/ai/AIQueryPrompt';
import HelpPage from './pages/HelpPage';

//fields can be imported now directly from backend without saving to state
export const StartupContext = createContext({
	config: "", dispatcher: "", schema: "",
	appcolors: AppColorsInit, fieldmapping: FieldMapInit,
	user: UserInit, allusers: UserAccountInit, paths: AllPaths(), clickstyle: GetClickStyles()
})



function App() {

	let dispatcher: any = useDispatch();
	let [config, setConfig] = useState<any>(GetAuthConfigHeaders());
	let schema: string = GetSchema();
	let workspace: string = GetWorkspace();

	let appcolors: any = AppColorsInit;
	let user: User = useSelector((state: any) => { return state.user })
	let allusers: UserAccount[] = useSelector((state: any) => { return state.usersAll })
	let othervalues: OtherValues = useSelector((state: any) => state.othervalues)

	// const [dashboards, setDashboards] = useState<Dashboard[]>([]);
	// const [queries, setQueries] = useState<QueryModel[]>([]);
	const [fieldmapping,] = useState<FieldMap[]>([]);
	const [paths,] = useState<PathList>(AllPaths());
	const [clickstyle,] = useState<clickstyle>(GetClickStyles())
	const [msg, setMsg] = useState<string>("")


	useEffect(() => {
		let [flddata, tblgrps] = OnLoad(schema, user, paths, config, dispatcher)

	}, [user, schema])

	//console.log(config)

	const CheckTokens = () => {
		if (location.pathname !== "/login" && location.pathname !== "/") { //prevent checking right after login (credentials aren't settled)
			//console.log(user.refreshtoken) //
			GenericBackendCall("", schema, paths.accounts.validatetokens, user, config, { refreshtoken: user.refreshtoken }).then(d => {
				if (location.pathname !== "/login" && location.pathname !== "/") {
					//check token is valid
					if (d[0] && d[1] == "Refresh") {
						let newconfig = LoginAuth(user, dispatcher)
						setConfig(newconfig) //need to update config to logged-in auth token
					}
					//console.log("Checkvalid", String(d), "id:", user.id)

					if (!d[0] && user.id !== "-1") {
						//console.log("attempt logout")
						LogoutAuth(dispatcher, schema, user, config, "Expired", setMsg)
					}
				}
			})
		}
	}

	// useLocation hook gives access to the current location
	const location = useLocation();

	// useEffect runs whenever the component mounts or the location changes
	useEffect(() => {
		if (location.pathname !== "/home") {
			CheckTokens();
		}
	}, [location]);


	return (
		<div className="App">
			<Navbar />
			<div>{othervalues && othervalues.tutorial ? <TutorialDiv /> : <div></div>}</div>
			<AuthProvider >
				<StartupContext.Provider value={{
					config, dispatcher, schema, fieldmapping, appcolors, user, allusers, paths, clickstyle
				}}>
					<Routes>

						<Route path='/home' element={<Home />} />
						<Route path='/login' element={<Login />} />
						<Route path='/logout' element={<Logout />} />
						<Route path='/signup' element={<SignUp />} />
						<Route path='/' element={<TitlePage />} />
						<Route path='/dashboard/permissions' element={<RequireAuth><PermissionsManager /></RequireAuth>} />

						<Route path='/fields/viewer' element={<RequireAuth><FieldsManager /></RequireAuth>} />
						<Route path='/fields/viewer/:id' element={<RequireAuth><FieldsManager /></RequireAuth>} />

						<Route path='/adminoptions' element={<RequireAuthAdmin><AdminOptions /></RequireAuthAdmin>} />

						<Route path='/groupsets/manager' element={<RequireAuth><GroupSetsManager /></RequireAuth>} />
						<Route path='/groupsets/manager/:id' element={<RequireAuth><GroupSetsManager /></RequireAuth>} />


						<Route path='/employee/upload' element={<RequireAuth><ModelUploader /></RequireAuth>} />
						<Route path='/employee/modelviewer' element={<RequireAuth><EmployeeModelViewer /></RequireAuth>} />
						<Route path='/employee/modelviewer/:id' element={<RequireAuth><EmployeeModelViewer /></RequireAuth>} />
						<Route path='/employee/viewer' element={<RequireAuth><SingleEmployeePage /></RequireAuth>} />
						<Route path='/employee/deptview' element={<RequireAuth><DeptViewer /></RequireAuth>} />
						<Route path='/employee/depttree' element={<RequireAuth><PreDeptTreeViewer /></RequireAuth>} />
						<Route path='/employee/ataglance' element={<RequireAuth><EmployeeAtAGlance /></RequireAuth>} />
						<Route path='/employee/detail' element={<RequireAuth><EmployeeDetail /></RequireAuth>} />

						<Route path='/rulesets/manager' element={<RequireAuth><RulesetsManager /></RequireAuth>} />
						<Route path='/rulesets/manager/:id' element={<RequireAuth><RulesetsManager /></RequireAuth>} />

						<Route path='/datamanager/:modelcategory' element={<RequireAuth><DataManager /></RequireAuth>} />


						<Route path='/schema' element={<RequireAuth><SchemaManage /></RequireAuth>} />

						<Route path='/filtersets/manager' element={<RequireAuth><FilterSetsManage /></RequireAuth>} />
						<Route path='/filtersets/manager/:id' element={<RequireAuth><FilterSetsManage /></RequireAuth>} />

						<Route path='/employee/table' element={<RequireAuth><EmployeeDataTable /></RequireAuth>} />
						<Route path='/employee/audit' element={<RequireAuth><EmployeeAuditView /></RequireAuth>} />
						<Route path='/analytics/simulation' element={<RequireAuth><SimulateEmployee /></RequireAuth>} />


						<Route path='/dashboard/select' element={<RequireAuth><DashboardSelect /></RequireAuth>} />
						<Route path='/dashboard/manager' element={<RequireAuth><DashboardsManager /></RequireAuth>} />
						<Route path='/dashboard/manager/:id' element={<RequireAuth><DashboardsManager /></RequireAuth>} />


						<Route path='/query/creategraph' element={<RequireAuth><GraphCreator /></RequireAuth>} />
						<Route path='/query/creategraph/auto' element={<RequireAuth><QueryAuto /></RequireAuth>} />
						<Route path='/query/editgraph' element={<RequireAuth><GraphEditor /></RequireAuth>} />
						<Route path='/query/managegraph' element={<RequireAuth><GraphManager /></RequireAuth>} />

						<Route path='/three' element={<RequireAuth><ThreeTest /></RequireAuth>} />
						<Route path='/three/particles' element={<RequireAuth><ThreeParticles /></RequireAuth>} />

						<Route path='/sna/view' element={<RequireAuth><NetworkPage /></RequireAuth>} />
						<Route path='/sna/data' element={<RequireAuth><NetworkDataManage /></RequireAuth>} />

						<Route path='/benchmark/manager' element={<RequireAuth><BenchmarkManager /></RequireAuth>} />



						<Route path='/colorsets/manager' element={<RequireAuth><ColorModelManage /></RequireAuth>} />

						<Route path='/colorsets/editor' element={<RequireAuth><ColorSetEditor /></RequireAuth>} />
						<Route path='/colorsets/editor/:id' element={<RequireAuth><ColorSetEditor /></RequireAuth>} />

						<Route path='/metrics/manager' element={<RequireAuth><MetricsManager /></RequireAuth>} />

						<Route path='/users/mywork' element={<RequireAuth><UserPage /></RequireAuth>} />

						<Route path='/tracker' element={<RequireAuth><TrackerPage /></RequireAuth>} />
						<Route path='/setstracker' element={<RequireAuth><SetsTracker /></RequireAuth>} />

						<Route path='/tables/manager' element={<RequireAuth><TableMgr /></RequireAuth>} />
						<Route path='/tables/manager/:id' element={<RequireAuth><TableMgr /></RequireAuth>} />

						<Route path='/tables/connections' element={<RequireAuth><ConnectionManager /></RequireAuth>} />
						<Route path='/tables/data' element={<RequireAuth><GenericDataTable /></RequireAuth>} />

						<Route path={'/schedules/manager'} element={<RequireAuth><ScheduleManager /></RequireAuth>} />
						<Route path={'/schedules/manager/:id'} element={<RequireAuth><ScheduleManager /></RequireAuth>} />


						<Route path={'/graph/:id'} element={<RequireAuth><QueryModelView /></RequireAuth>} />
						<Route path={'/graph/:id/schedule'} element={<RequireAuth><QueryModelView /></RequireAuth>} />
						<Route path={'/graph/:id/visuals'} element={<RequireAuth><QueryModelView /></RequireAuth>} />
						<Route path={'/graph/:id/runs'} element={<RequireAuth><QueryModelView /></RequireAuth>} />
						<Route path={'/graph/:id/inspector'} element={<RequireAuth><QueryModelView /></RequireAuth>} />
						<Route path={'/graph/:id/dataeditor'} element={<RequireAuth><QueryModelView /></RequireAuth>} />
						<Route path={'/graph/:id/3dviewer'} element={<RequireAuth><QueryModelView /></RequireAuth>} />


						<Route path={'/dashboard/live/:url'} element={<RequireAuth><DashboardView /></RequireAuth>} />
						<Route path={'/dashboard/live/:url/notes'} element={<RequireAuth><DashboardNotesPage /></RequireAuth>} />

						<Route path='/kpis/stringmanager' element={<RequireAuth><KPIStringManager /></RequireAuth>} />
						<Route path='/kpis/manager' element={<RequireAuth><KPIManager /></RequireAuth>} />
						<Route path='/kpis/manager/:id' element={<RequireAuth><KPIManager /></RequireAuth>} />

						<Route path='/leader/manager' element={<RequireAuth><LeaderViewManager /></RequireAuth>} />
						<Route path='/leader/view' element={<RequireAuth><LeaderView /></RequireAuth>} />
						<Route path='/leader/view/:id' element={<RequireAuth><LeaderView /></RequireAuth>} />


						<Route path='/valuetracker' element={<RequireAuth><ValueTracker /></RequireAuth>} />

						<Route path='/jobdescription/manager' element={<RequireAuth><JobSkillManager /></RequireAuth>} />
						<Route path='/jobdescription/netmodel' element={<RequireAuth><JobDescNetGraph /></RequireAuth>} />
						<Route path='/jobdescription/' element={<RequireAuth><JobDescriptionBase /></RequireAuth>} />

						<Route path='/staffing/manager' element={<RequireAuth><StaffingModelManager /></RequireAuth>} />
						<Route path='/ai/' element={<RequireAuth><OpenAI /></RequireAuth>} />

						<Route path='/jobposting/viewer' element={<RequireAuth><JobPostingView /></RequireAuth>} />

						<Route path='/jobdescription/categorymodelai' element={<RequireAuth><CategoryModelAI /></RequireAuth>} />

						<Route path='/ai/model' element={<RequireAuth><AIModelManager /></RequireAuth>} />
						<Route path='/ai/model/:id' element={<RequireAuth><AIModelManager /></RequireAuth>} />

						<Route path='/status' element={<RequireAuth><StatusChecker /></RequireAuth>} />
						<Route path='/status/:id' element={<RequireAuth><StatusChecker /></RequireAuth>} />

						<Route path='/walkthrough' element={<RequireAuth><DatasetWalkthrough /></RequireAuth>} />
						<Route path='/walkthrough/:id' element={<RequireAuth><DatasetWalkthrough /></RequireAuth>} />

						<Route path='/scenario/manager' element={<RequireAuth><ScenarioBuilder /></RequireAuth>} />
						<Route path='/scenario/manager/:id' element={<RequireAuth><ScenarioBuilder /></RequireAuth>} />

						<Route path='/ai/query' element={<RequireAuth><AIQueryPrompt /></RequireAuth>} />

						<Route path='/help' element={<RequireAuth><HelpPage /></RequireAuth>} />

					</Routes>
				</StartupContext.Provider>
			</AuthProvider>

		</div>
	);
}

export default App;

function RequireAuthAdmin({ children }: any): any {
	let user: User = useSelector((state: any) => state.user);

	return user.isAuthenticated === true && user.level === "Admin"
		? children
		: <Navigate to="/login" />
}

function RequireAuth({ children }: any): any {
	let [user, schema] = useSelector((state: any) => [state.user, state.selectedSchema]);

	return user.isAuthenticated === true
		? children
		: <Navigate to="/login" />
}