import { useEffect, useState, useContext, ChangeEvent } from "react"
import "./css/StatusChecker.css"
import GenericBackendCall from "../functions/Import/GenericBackendCall";
import { StartupContext } from "../App";
import { ChangeCounter, Key_Name, Schema } from "../type";
import GetObjectValue from "../functions/Object Functions/GetObjectValue";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SerializeDataFrame from "../functions/Import/SerializeDataFrame";
import { useParams } from "react-router-dom";
import StyleMainSelect from "../styling/StyleMainSelect";
import GenericDropdown from "../functions/Dropdown_Generic";
import ImportAndSetStateGeneric from "../functions/Import/ImportAndSetStateGeneric";
import ChangeSelectorbyIndex from "../functions/ElementSelect/ChangeSelectorbyIndex";
import GetStatusInit from "./data/schema/GetStatusInit";


const StatusChecker = () => {

    const { config, schema, dispatcher, user, paths, clickstyle } = useContext(StartupContext)
    let history = useNavigate()
    let { id } = useParams()


    interface ststype {
        ModelCategory: string;
        ModelName: string; //literal model backend name, to match backend ChangeCounter 
        ItemDesc: string;
        NextStep: string;
        Number: number;
        IsComplete: boolean;
        IsOptional: boolean;
        Link: string;
        KeyField: string;
        NameField: string;
    }
    interface paramtype {
        isshowdetail: boolean;
        backgroundstring: string;
        activestepnum: number;
        tempnote: string;
    }
    const [changeCounters, setChangeCounters] = useState<ChangeCounter[]>([])

    let [schemas, setSchemas] = useState<Schema[]>([])
    let activeSchema = useSelector((state: any) => state.selectedSchema)//need this for this page
    let [selSchema, setSelSchema] = useState<Schema | null>(null)

    let [statuses, setStatuses] = useState<ststype[]>(GetStatusInit())
    let [keyparams, setKeyParams] = useState<paramtype>({ isshowdetail: false, backgroundstring: '', activestepnum: 0, tempnote: '' })
    let [selststype, setSelststype] = useState<ststype | null>(null)
    let [modelkeynames, setModelKeyNames] = useState<Key_Name[]>([])


    useEffect(() => {
        if (id && id !== "") {
            GenericBackendCall("", id, paths.schema.schemaimport, user, config, { schemakeys: [id] }).then(d => {
                console.log(d)
                if (d.length === 1) {
                    setSelSchema(d[0])
                } else {
                    setSelSchema(activeSchema)
                }
            })
        } else {
            setSelSchema(activeSchema)
        }
    }, [id])

    useEffect(() => {
        ImportAndSetStateGeneric(setSchemas, "", "*", paths.schema.schemaimport, user, config, {})
    }, [])

    useEffect(() => {
        if (selSchema) {
            GenericBackendCall("", selSchema?.SchemaKey, paths.schema.changesview, user, config, {}).then(d => {
                console.log(d)
                setChangeCounters(d)
            })
            let bckgrdstr = String('linear-gradient(70deg, #110b1a 40%, ' + selSchema.BaseColor + ' 100%)')
            setKeyParams({ ...keyparams, backgroundstring: bckgrdstr })
        }
    }, [selSchema])

    useEffect(() => {
        if (selststype) {
            GenericBackendCall("", schema, paths.generics.generickeynamemap, user, config, { keyfield: selststype.KeyField, namefield: selststype.NameField, model: selststype.ModelName }).then(d => {
                setModelKeyNames(SerializeDataFrame(d))
                //setKeyParams({ ...keyparams, isshowdetail: true });
            })
        }
    }, [selststype])

    useEffect(() => {
        if (changeCounters) {
            let totalcomplete = 0;
            let activestepnum = -1;
            let newsts = statuses.map((sts: ststype, i: number) => {
                let cnt = 0;
                let complete = false;

                changeCounters.forEach((cc: ChangeCounter) => {
                    if (sts.ModelName === cc.ModelName) {
                        cnt = cc.ModelCount
                        if (cnt > 0) {
                            complete = true
                            totalcomplete += 1
                        }
                    }
                })
                if (!complete && activestepnum === -1 && !sts.IsOptional) {
                    activestepnum = i
                }
                return ({ ...sts, Number: cnt, IsComplete: complete })
            })

            setStatuses(newsts)
            const progressBar = document.getElementById('sts_progressbar');
            if (progressBar) {
                progressBar.style.width = String(Math.round((totalcomplete + 1) / statuses.length * window.innerWidth)) + "px"
            }
            setKeyParams({ ...keyparams, activestepnum: activestepnum })
        }
    }, [changeCounters])


    return (<div className="ndt_canvas">

        <StyleMainSelect
            base={<GenericDropdown
                data={schemas}
                keycol="SchemaKey"
                namecol="SchemaName"
                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, schemas, setSelSchema, "", activeSchema) }}
                promptstring="Change Schema"
                includeDefault={false}
                className="ndt_dropdown"
                value={selSchema}
            />
            }
            delete={<div></div>}
            highlight={false}
        />
        {selSchema ?
            <div >

                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <div className="ndt_header ndt_gridbox" style={{ display: "flex" }}>
                        <div className="ndt_title2">Status Checker</div>
                        <div style={{ background: "", marginLeft: "125px", marginTop: "12px", fontSize: "20px" }}>
                            Selected Schema: {selSchema.SchemaName}
                        </div>
                    </div>
                    <div className="ndt_gridbox" style={{ marginTop: "30px" }}>
                        <div style={{ marginLeft: "20px", fontSize: "18px", letterSpacing: ".05em" }}>Completion:</div>
                        <div className="sts_statusbar">
                            <div className="progress" id="sts_progressbar"></div>
                        </div>
                    </div>
                </div>

                <div className="ndt_gridbox" style={{ display: 'flex' }}>
                    <div className="ndt_innerbox" style={{ height: "700px", overflowY: "scroll" }}>
                        <div style={{ fontSize: "20px" }}>Data Models Completed:</div>
                        <div style={{ width: "400px" }}>
                            {statuses.map((sts: ststype, i: number) => {
                                let usestyle = {}
                                let useclass = ""
                                let note = ""
                                if (sts.IsComplete) {
                                    usestyle = { background: "rgb(100,200,100)" }
                                }
                                if (i === keyparams.activestepnum) {
                                    useclass = " sts_activeitm"
                                    note = "-->"
                                }
                                return (<div style={{ display: "flex" }} key={i}>
                                    <div style={{ width: "40px", paddingTop: "15px", paddingLeft: "15px" }}>{note}</div>
                                    <div style={usestyle}
                                        className={"sts_itm ndt_item" + useclass}
                                        onMouseEnter={(_) => { setKeyParams({ ...keyparams, tempnote: sts.NextStep }) }}
                                        onMouseOut={(_) => { setKeyParams({ ...keyparams, tempnote: "" }) }}

                                        onClick={_ => { setKeyParams({ ...keyparams, isshowdetail: true }); setSelststype(sts); }}>

                                        <div style={{ width: "180px", marginRight: "10px", marginLeft: "10px" }}>{sts.ModelCategory}:</div>
                                        <div>{sts.Number}</div>
                                    </div></div>)
                            })}
                        </div>
                    </div>

                    <div style={{ margin: '100px', fontSize: "18px" }}>
                        {keyparams.tempnote === "" && keyparams.activestepnum >= 0 ?
                            <div> Next Step: {statuses[keyparams.activestepnum].NextStep}</div> :
                            <div> {keyparams.tempnote}</div>
                        }

                    </div>
                </div>
            </div>

            : <div></div>}
        <Modal show={keyparams.isshowdetail}>
            {selststype ?
                <div className="ndt_modal">

                    <div className="ndt_modaltitle" style={{ margin: "10px" }}> - {selststype.ModelName} Detail - </div>
                    <div style={{ fontSize: "18px" }}>Models:</div>
                    <div style={{ height: "150px", overflowY: "scroll", background: "rgb(10,10,10)" }}>
                        {modelkeynames.map((kn: Key_Name, i: number) => {
                            return (<div key={i} className="stschk_mdlitm">{GetObjectValue(kn, selststype.NameField)}</div>)
                        })}
                    </div>
                    <div style={{ margin: "10px" }}>
                        <button className="ndt_btn1" onClick={_ => { history(selststype.Link) }}>Navigate to {selststype?.ModelCategory} Manager</button>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isshowdetail: false })}>Close</button>
                </div> : <div></div>}
        </Modal>

    </div>)
}
export default StatusChecker