
import { Schedule } from "../../../type"
import { ChangeEvent, useState, useEffect, useContext } from "react"
import DateString from "../../../functions/Date Functions/DateString"
import { Modal } from 'react-bootstrap'
import { StartupContext } from "../../../App"
import CalculateStickyDate from "../../../functions/Date Functions/CalculateStickyDate"
import { ScheduleInit } from "../../../InitTypes"
import GenericBackendCall from "../../../functions/Import/GenericBackendCall"
import GenericDropdown from "../../../functions/Dropdown_Generic"
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex"
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric"
import { useParams } from "react-router-dom"
import SetObjectfromKey from "../../../functions/SetFunctions/SetObjectfromKey"
import CalculateSchedule from "../../../functions/Date Functions/CalculateSchedule"
import "./css/ScheduleManager.css"
import SchedulePeriodDropdown from "../../../functions/QueryFunctions/SchedulePeriodDropdown"
import StyleMainSelect from "../../../styling/StyleMainSelect"

const ScheduleManager = () => {

    let { id } = useParams()


    const { config, schema, user, paths } = useContext(StartupContext)

    interface paramstype {
        asofdate: Date;
        rundatetext: string;
        issavevisible: boolean;
        isdelvisible: boolean;
        stickydatesize: number;
        periodname: string;
        schedulename: string;
        refresh: number;
    }

    let [keyparams, setKeyParams] = useState<paramstype>({
        asofdate: new Date(), rundatetext: '', issavevisible: false, isdelvisible: false,
        periodname: '', schedulename: '', stickydatesize: 0, refresh: 0
    })
    let [schedules, setSchedules] = useState<Schedule[]>([])
    let [schedule, setSchedule] = useState<Schedule | null>(null)
    let [datehistory, setDateHistory] = useState<any[]>([])

    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        ImportAndSetStateGeneric(setSchedules, "", schema, paths.schedule.scheduleview, user, config, {})
    }, [])

    useEffect(() => {
        if (id) { SetObjectfromKey(id, schedules, "ScheduleKey", setSchedule, false) }
    }, [schedules])

    useEffect(() => {
        if (schedule) { setKeyParams({ ...keyparams, asofdate: schedule.AnchorDate }) }
    }, [schedule?.AnchorDate])

    useEffect(() => {
        //calculated asofdate
        if (schedule) {
            let size = 0
            let pname = ""
            if (schedule.Period === "Weekly") {
                size = 7; pname = "weeks";
            } else if (schedule.Period === "Daily") {
                size = 1; pname = "days";
            } else if (schedule.Period === "Monthly") {
                size = 31; pname = "months";
            } else if (schedule.Period === "Yearly") {
                size = 365; pname = "years";
            }

            let newasofdate = CalculateStickyDate(schedule.AnchorDate, schedule.StickyDateRef, schedule.Period, schedule.IPeriod, schedule.OffsetDays, true)

            setKeyParams({ ...keyparams, asofdate: newasofdate, stickydatesize: size, periodname: pname })

            if (schedule.IPeriod > 0 && schedule.StickyDateRef !== "") {
                let alldates = CalculateSchedule(schedule, 5)
                setDateHistory(alldates)
            }
        }
    }, [schedule])


    const ClickSave = () => {
        let schedulename = ""
        if (keyparams.schedulename !== "") { schedulename = keyparams.schedulename }
        GenericBackendCall('', schema, paths.schedule.schedulepost, user, config, { ...schedule, ScheduleName: schedulename, AnchorDate: keyparams.asofdate }, "scm_divsave").then(_ => {
            setKeyParams({ ...keyparams, refresh: 99 })
        })
    }

    const ClickDelete = () => {
        if (schedule) {
            GenericBackendCall(schedule.ScheduleKey, schema, paths.generics.genericdelete, user, config,
                { model: "Schedule", field: "ScheduleKey" }, "scm_divdel")
        }
    }


    return (<div className="ndt_canvas">
        <div className="ndt_title2">Scheduler</div>
        <StyleMainSelect base={<GenericDropdown
            data={schedules}
            keycol="ScheduleKey"
            namecol="ScheduleName"
            promptstring="Select Schedule or Create New"
            includeDefault={true}
            defaultstring="Create a new schedule"
            divID="scm_scheddown"
            className="ndt_dropdown"
            value={schedule?.ScheduleName}
            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, schedules, setSchedule, "", ScheduleInit[0]) }}
        />}
            delete={
                <button className="ndt_btn2" style={{ marginLeft: "10px" }} onClick={_ => { setKeyParams({ ...keyparams, isdelvisible: true }) }}>Delete</button>
            }
            deleteCondition={schedule && schedule?.ScheduleKey !== ""}
            highlight={schedule ? false : true}
        />


        {schedule ? <div className="ndt_gridbox" id="sch_main">
            <div className="ndt_innerbox">
                <div id="sch_setparamsbox1" className="sch_block">
                    <div style={{ fontSize: '22px' }}>Schedule</div>
                    <div style={{ display: "flex" }}>
                        <div className="sch_name">Sticky to:</div>
                        <select value={schedule.StickyDateRef} onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            if (schedule && e.target.selectedOptions[0].attributes[0].value !== "") { setSchedule({ ...schedule, StickyDateRef: e.target.selectedOptions[0].attributes[0].value }) }
                        }}>
                            <option value="">Select:</option>
                            <option value="None">None</option>
                            <option value="EOY">End of Year</option>
                            <option value="EOM">End of Month</option>
                            <option value="EOW">End of Week (Sunday)</option>
                        </select>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className="sch_name">Offset N Days</div>
                        <input type="number" name="n_periods" min={-10} max={150} value={schedule.OffsetDays}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => { if (schedule) { setSchedule({ ...schedule, OffsetDays: +e.target.value }) } }} />
                    </div>

                    <div style={{ display: "flex" }}>
                        <div className="sch_name">Next Date: </div>
                        {DateString(keyparams.asofdate)}
                    </div>
                </div>

                <div id="sch_setparamsbox2" className="sch_block">
                    <div style={{ fontSize: '22px' }}>Recurrence</div>

                    <div style={{ display: "flex" }}>
                        <div className="sch_name">Period:</div>
                        <SchedulePeriodDropdown
                            schedule={schedule}
                            setSchedule={setSchedule}
                            className="ndt_dropdown"
                        />
                    </div>
                    {schedule.Period !== "" ?
                        <div style={{ display: "flex" }}>
                            <div className="sch_name">Number of {keyparams.periodname}:</div>
                            <input style={{ width: "100px" }} value={schedule.IPeriod} type="number" min={1} onChange={(e: ChangeEvent<HTMLInputElement>) => { if (schedule) { setSchedule({ ...schedule, IPeriod: +e.target.value }) } }} />
                        </div>
                        : <div></div>}
                </div>

                <div>
                    <div style={{ fontSize: '22px' }}>Schedule Custom Name:</div>

                    <div style={{ display: "flex" }}>
                        <div className="sch_name">Name:</div>
                        <input type="text" value={keyparams.schedulename} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, schedulename: e.target.value }) }} />
                    </div>
                </div>

                <div className="sch_block">
                    {schedule.Period !== "" && schedule.StickyDateRef !== "" && schedule.IPeriod ?
                        <div style={{ display: "flex" }}>
                            <div>
                                <div style={{ fontSize: '20px' }}>Set Schedule</div>
                                <button id="sch_savebtn" className="ndt_btn1" style={{ width: "90px" }} onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}>Save</button>
                            </div>
                        </div>
                        : <div></div>}
                </div>
            </div>

            <div className="ndt_innerbox">
                <div style={{ fontSize: "22px" }}>Scheduled Dates:</div>
                {datehistory.map((d: any, i: number) => {
                    return (<div key={i} className="qmvs_scheditm" style={{ display: "flex", cursor: 'default', width: "400px" }}>
                        <div style={{ width: "120px" }}>{DateString(d[1])}</div>
                    </div>)
                })}
            </div>

        </div> :

            <div></div>
        }

        <Modal show={keyparams.issavevisible}>
            <div className="ndt_modal">
                <div className="ndt_modaltitle">Save Schedule {keyparams.periodname} ({schedule?.IPeriod})?</div>
                <button className="ndt_btn1" style={{ width: "200px", margin: "10px" }} onClick={_ => ClickSave()}>Save</button>
                <div id="scm_divsave"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>


        <Modal show={keyparams.isdelvisible}>
            <div className="ndt_modal">
                <div className="ndt_modaltitle">Delete Schedule {schedule?.ScheduleName}?</div>
                <button className="ndt_btn1" style={{ width: "200px", margin: "10px" }}
                    onClick={_ => ClickDelete()}>Delete</button>
                <div id="scm_divdel"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelvisible: false })}>Close</button>
            </div>
        </Modal>


    </div >)
}
export default ScheduleManager