import IsInVector from "../IsInVector";
import { useState, FormEvent, ChangeEvent, MouseEvent } from "react";
import { useSelector } from 'react-redux';
import GetFieldName from '../GetFieldName';
import { FieldMap } from "../../type";
import "./css/EnumerateSelectedRow.css";
import FilterData from "../FilterData";
import GenericBackendCallTable from "../Import/GenericBackendCallTable";

const EnumerateSelectedRow = (props: any) => {

    //only fit for one submodel currently
    //console.log(props.data)
    //add_del fns: Headers_Upload, 

    let selectedRow = props.data;
    let config = props.config;
    let keycol = props.keycol;
    let colsHide = props.colsHide;
    let optionalchoices: any[] = props.optionalchoices; //for dropdowns when editing instead of text
    let subModelObj = props.subModelObj; //modelname, key to show, key field (for deletion/insertion), key of parent model
    let adddel_fn = props.adddel_fn;
    let delete_fn = props.delete_fn;
    let editable = props.editable; //which attributes can be edited
    let backendstring = props.backendstring;
    let modelcategory = props.modelcategory;
    let schema = props.schema;

    const Fields: FieldMap[] = useSelector((state: any) => state.fieldmapping);
    const [formData, setFormData] = useState<any>({})
    const [selmodelItm, setSelModelItm] = useState<any>({})
    const [addSubName, setAddSubName] = useState<string>()

    const SubmitEntry = (e: FormEvent<HTMLFormElement>) => {
        //submit
        e.preventDefault()
        let newarry: any[] = [];
        if (formData) {
            GenericBackendCallTable(formData,
                selectedRow[keycol],
                schema,
                modelcategory,
                keycol,
                backendstring,
                "success",
                config, "chgrowstatus")
        }
    };

    const onDdownChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        if (e.target.selectedOptions[0].value !== "Other Options:") {
            setFormData({ ...formData, [e.target.name]: e.target.selectedOptions[0].value });
        }
    };

    const OnSubObjChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        let int: number = +e.target.selectedOptions[0].attributes[0].value
        if (int >= 0) {
            let objkey: string = e.target.selectedOptions[0].attributes[1].value
            setSelModelItm(selectedRow[objkey][int])
        } else {
            setSelModelItm({})
        }
    };


    //submodel fns
    const onSubModelInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setAddSubName(e.target.value);
    };
    const DelRowfromData = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (selmodelItm) {
            adddel_fn(selmodelItm, schema, subModelObj[0], "False", "enumchange_div", config) //upload const must have these fields
        }
    };
    const AddRowtoData = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        let smitm = { [subModelObj[2]]: addSubName, [subModelObj[3]]: selectedRow[subModelObj[3]] }
        console.log(subModelObj)
        console.log(addSubName)
        adddel_fn(smitm, schema, subModelObj[0], "True", "enumchange_div", config) //upload const must have these fields
    };


    return (
        <div id="tblEnum1">
            <form id="submitform" key={'f1'} onSubmit={(e: FormEvent<HTMLFormElement>) => SubmitEntry(e)} >
                {Object.keys(selectedRow).map((k: string, i: number) => {
                    if (IsInVector(colsHide, k) === false) {
                        let colname = GetFieldName(Fields, k) //instead of half-backend attrname
                        let options: string[] = []
                        if (colname === "") { colname = k } //for fieldmapping only? (no field mapping of fieldmapping cols)
                        if (optionalchoices.length > 0) {
                            options = FilterData(optionalchoices, 0, k)
                        }
                        if (options.length === 0) { //regular text input
                            if (typeof selectedRow[k] !== "object") {
                                if (editable[k] !== true) { //must be specified as true
                                    return (
                                        <div className="rowform_group" key={"s" + i} id={'erow' + i}>
                                            <div className="rowform_name">{colname}: </div>
                                            <div>{String(selectedRow[k])}</div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="rowform_group" key={"s" + i} id={'erow' + i}>
                                            <div className="rowform_name">{colname}: </div>
                                            <input type="text" className="selRowFormElement"
                                                id={"input_" + k}
                                                name={k}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                                placeholder={String(selectedRow[k])} />
                                        </div>
                                    )
                                }
                            }
                        } else { //dropdown - option
                            return (
                                <div className="rowform_group" key={"s" + i} id={'erow' + i}>
                                    <div className="rowform_name">{colname}: </div>
                                    <div> Currently: {selectedRow[k]} </div>
                                    <select className="enum_typeddown" name={k} onChange={(e: ChangeEvent<HTMLSelectElement>) => onDdownChange(e)}>
                                        <option key={"0**"}>Other Options:</option>
                                        {options.map(opt => {
                                            return (<option key={opt[1]}>
                                                {opt[1]}
                                            </option>)
                                        })}
                                    </select>
                                </div>
                            )
                        }
                    }
                })}
                <button className="editsubmitbtn" type="submit"> Submit Edit</button>
                <div id="chgrowstatus"></div>
            </form>

            <div>
                {Object.keys(selectedRow).map((k: string, i: number) => {
                    if (IsInVector(colsHide, k) === false) {
                        let colname = GetFieldName(Fields, k)
                        if (colname === "") { colname = k }
                        if (typeof selectedRow[k] === "object" && selectedRow[k]) { //sub model object
                            return (
                                <form key={"f1"}>
                                    <div className={'submodelx'} key={"sa" + i}>
                                        <input type="text"
                                            name={'a'}
                                            onChange={e => onSubModelInputChange(e)}
                                        />
                                        <button type="submit" onClick={(e: FormEvent<HTMLButtonElement>) => AddRowtoData(e)}>Add Row</button>
                                    </div>

                                    <div className={'submodelx'} key={"sd" + i}>
                                        <select className={"submodelsel"} onChange={(e: ChangeEvent<HTMLSelectElement>) => OnSubObjChange(e)}>
                                            <option key={-1} data-id={-1} data-objkey={'xx'}>Select Header:</option>

                                            {selectedRow[k].map((opt: any, s: number) => {

                                                return (
                                                    <option key={s} data-id={s} data-objkey={k}>
                                                        {opt[subModelObj[2]]}
                                                    </option>
                                                )
                                            })}

                                        </select>
                                        <button type="submit" onClick={(e: FormEvent<HTMLButtonElement>) => DelRowfromData(e)}>Delete Row</button>

                                    </div>
                                </form>
                            )
                        }
                    }
                })}
                {delete_fn ? <div>
                    <button id="enum_rmvbtn" onClick={(e: MouseEvent<HTMLButtonElement>) => { delete_fn(selectedRow[keycol], config, "enumchange_div") }}>
                        Remove this Entry?
                    </button>
                </div> : <div></div>}
                <div id="enumchange_div"></div>
            </div>
        </div>
    )
};
export default EnumerateSelectedRow;