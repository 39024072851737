import ChartGetColor from "./ChartGetColor";


const AddBarAttrs = (elems, charttype, metrickey, barscount, xaxisfn, groups, groupfield, yfield, colorScale, colorgradienttype, attributes, vis_state, stateIdx) =>{
    
    
    let barwidth = xaxisfn.bandwidth()/barscount;
    
    const Colorize = (d) =>{
        let color = ChartGetColor(d, d['EncodedKey'], colorScale, d['MetricNo'], groups, groupfield, colorgradienttype, vis_state, stateIdx)
        return color
    }
    
    
    elems
    .attr("id", function(d){return d['id']})
    .attr("data-id",function(d){return d['EncodedKey']})
    .attr("data-value",(d)=>{return(d[yfield])})
    .attr("data-metricno",(d)=>{return d['MetricNo']})
    .attr("data-metrickey",(_)=>{return metrickey})
    .attr("basecolor",(d)=>{return Colorize(d)})
    .attr("stroke",(_)=>{return "grey"})
    .attr("width", barwidth)
    .attr("opacity",attributes['opacity'])
    .attr("selected",0)
    .attr("box-shadow","0px 0px 5px blue")
    .style("fill", (d)=>{return Colorize(d)})
    
}
export default AddBarAttrs;