import { User } from "../../type";
import AxiosUpload from "../AxiosUpload";

const FieldsUploadSample = async (fieldsFile: any, schemakey: string, config: any, user: User, elementstring: string) => {
    //ref tablemgr, modeluploader, datasetwalkthrough

    let dataArray = new FormData();
    dataArray.append("file", fieldsFile);
    dataArray.append("schemakey", schemakey);
    dataArray.append("userid", user.id);


    let elem: HTMLElement | null = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    let promise = AxiosUpload(dataArray, "api/fields/sample", config, elem, "")
    return (promise)
};

export default FieldsUploadSample;
