    
function HexToRgba(hex, asarry=false) {
    //ref: charttable?, networkgraph2D (asarry=true),
    if(hex.search("rgb")!==0){
        // Remove the "#" character if present
        if (hex===""){
            return "rgba(0,0,0)"
        }
        hex = hex.replace(/^#/, '');
        
        // Ensure that the input is a valid hex color code
        if (!/^(?:[0-9a-fA-F]{3}){1,2}$/.test(hex)) {
            throw new Error("Invalid hexadecimal color code");
        }
        
        // If the hex code is 3 characters long, expand it to 6 characters
        if (hex.length === 3) {
            hex = hex
            .split('')
            .map(char => char + char)
            .join('');
        }
        
        // Parse the hex code to RGB values
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);
        const a = .5
        if (asarry){
            return [r,g,b,a]
        } else {
            return "rgba(" + String(r) + "," + String(g) + "," + String(b) + "," + String(a) + ")"
        }
    } else {
        return hex
    }
};

export default HexToRgba;