
import ListSort from "./ListSort";
import ObjectSort from "./ObjectSort";

//filtering function
function GetUniquesFromObjectAttribute(data, byField,aslist=false){
    //scenariobuilder,
    
	let uniques = []	
    let counts = []

    let result = [];
        data.forEach(d => {
            if (uniques.indexOf(String(d[byField]))=== -1){
                uniques.push(String(d[byField]));
                counts.push(1)
            } else {
                counts[uniques.indexOf(String(d[byField]))] += 1
            }
        });

        uniques.forEach((u,i) => {
            if (aslist){
                result.push(u)
            } else {
                result.push([u,counts[i]])
            }
        });

        if (!aslist){
		    result = ObjectSort(result,0)
        } else {
            result = ListSort(result)
        }
		return(result)
}
	
export default GetUniquesFromObjectAttribute	