import { ChangeEvent, useState, useEffect, useContext } from "react";
import { Dashboard, KPIString, QueryModel, Schema, User, UserAccount, UserPermssion } from "../../../type";
import { StartupContext } from "../../../App";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import { UserInit, UserPermissionInit } from "../../../InitTypes";
import GetUserEmail from "../../../functions/User Functions/GetUserEmail";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import "./css/PermissionsManager.css"
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import { Modal } from "react-bootstrap";
import GetObjectValue from "../../../functions/Object Functions/GetObjectValue";

const PermissionsManager = () => {
    interface keynamecat {
        Key: string; Name: string; ModelCategory: string; Schema: string;
    }

    interface paraminit {
        modelkey: string; modelcategory: string, modeldata: keynamecat[],
        prmfield: string; keyfld: string; refresh: number; warning: string; isdeletevisible: boolean; issavevisible: boolean;
    }


    let { config, dispatcher, schema, appcolors, user, allusers, paths } = useContext(StartupContext)
    const [selModel, setSelModel] = useState<keynamecat>({ Key: "", Name: "", ModelCategory: "", Schema: '' })
    const [dashboards, setDashboards] = useState<Dashboard[] | null>(null)
    const [schemas, setSchemas] = useState<Schema[] | null>(null)
    const [querys, setQueries] = useState<QueryModel[] | null>(null)
    const [kpistrings, setKPIStrings] = useState<KPIString[] | null>(null)


    let [params, setParams] = useState<paraminit>({
        modelkey: '', modelcategory: "", modeldata: [], prmfield: "", keyfld: '', refresh: 0,
        isdeletevisible: false, issavevisible: false, warning: ''
    })
    const [userpermsAll, setUserPermsAll] = useState<any[]>([])
    const [userperms, setUserPerms] = useState<any[]>([])

    const [selPerm, setSelPerm] = useState<UserPermssion>(UserPermissionInit[0])


    useEffect(() => {
        if (params.refresh >= 100) { window.location.reload() }
    }, [params])

    useEffect(() => {
        ImportAndSetStateGeneric(setDashboards, "*", schema, paths.dashboard.dashnameview, user, config, { permissions: ["*"] })
        ImportAndSetStateGeneric(setSchemas, "*", schema, paths.schema.schemaimport, user, config, { permissions: ["*"] })
        ImportAndSetStateGeneric(setQueries, "*", schema, paths.query.queryview, user, config, { type: "names", permissions: ["*"] })
        ImportAndSetStateGeneric(setKPIStrings, "*", schema, paths.kpi.kpistringview, user, config, { permissions: ["*"] })
    }, []);

    useEffect(() => {
        let userX = { ...user, id: "" }
        let userallpromise = GenericBackendCall("", schema, paths.permissions.permview, userX, config, { modelcategory: params.modelcategory })
        userallpromise.then(d => {
            setUserPermsAll(d);
            //only models where this user is a manager (all if admin)
            if (user.level === "Admin") {
                setUserPerms(d)
            } else {
                let userpromise = GenericBackendCall("", schema, paths.permissions.permview, user, config, { permissiontypes: ['creator', "manage"], user: user.id })
                userpromise.then(d => { setUserPerms(d) })
            }
        })
    }, [])

    useEffect(() => {
        if (userperms.length > 0 && dashboards && schemas && querys && kpistrings) {
            let keynames: keynamecat[] = []
            let uniquekeys: string[] = []
            userperms.forEach((prm: UserPermssion) => {
                if (String(prm.User) === String(user.id)) {
                    if (prm.DashboardPrm && params.modelcategory === "Dashboard") {
                        if (uniquekeys.indexOf(prm.DashboardPrm) === -1) {
                            keynames.push({ Key: prm.DashboardPrm, Name: GetObjectField(prm.DashboardPrm, dashboards, "Key", "Name"), ModelCategory: "Dashboard", Schema: GetObjectField(prm.DashboardPrm, dashboards, "Key", "SchemaName") })
                            uniquekeys.push(prm.DashboardPrm)
                        }
                    } else if (prm.SchemaPrm !== null && params.modelcategory === "Schema") {
                        if (uniquekeys.indexOf(prm.SchemaPrm) === -1) {
                            keynames.push({ Key: prm.SchemaPrm, Name: GetObjectField(prm.SchemaPrm, schemas, "SchemaKey", "SchemaName"), ModelCategory: "Schema", Schema: GetObjectField(prm.DashboardPrm, schemas, "SchemaKey", "SchemaKey") })
                            uniquekeys.push(prm.SchemaPrm)
                        }
                    } else if (prm.QueryModelPrm && params.modelcategory === "QueryModel") {
                        if (uniquekeys.indexOf(prm.QueryModelPrm) === -1) {
                            keynames.push({ Key: prm.QueryModelPrm, Name: GetObjectField(prm.QueryModelPrm, querys, "ModelKey", "ModelName"), ModelCategory: "QueryModel", Schema: GetObjectField(prm.QueryModelPrm, querys, "ModelKey", "SchemaName") })
                            uniquekeys.push(prm.QueryModelPrm)
                        }
                    } else if (prm.KPIStringPrm && params.modelcategory === "KPIString") {
                        if (uniquekeys.indexOf(prm.KPIStringPrm) === -1) {
                            keynames.push({ Key: prm.KPIStringPrm, Name: GetObjectField(prm.KPIStringPrm, kpistrings, "Key", "Name"), ModelCategory: "KPIString", Schema: GetObjectField(prm.KPIStringPrm, kpistrings, "Key", "SchemaName") })
                            uniquekeys.push(prm.KPIStringPrm)
                        }
                    }
                }
            })
            console.log(keynames)
            setParams({ ...params, modeldata: keynames })
        }

    }, [params.modelcategory, userperms, dashboards, schemas, querys, kpistrings])


    useEffect(() => {
        CheckSchemaforModel()
    }, [selPerm.User])

    //------------------
    const ChangeModel = (e: ChangeEvent<HTMLSelectElement>) => {
        let mdl: keynamecat = ChangeSelectorbyIndex(e, params.modeldata, setSelModel, "")
        setSelPerm({ ...selPerm, Key: "", [params.prmfield]: mdl.Key })
        setParams({ ...params, modelkey: mdl.Key, warning: '' })

    };

    const AssignPermission = () => {
        if (selPerm) {
            GenericBackendCall(params.modelkey, schema, paths.permissions.permpost, user, config, {
                user_id: selPerm.User, prmkey: selPerm.Key, permission: selPerm.Permission, modelcategory: params.modelcategory, keyfield: params.keyfld
            }, "pm_divsubmit").then(_ => { setParams({ ...params, refresh: 99 }) })
        }
    };

    const DeletePermission = () => {
        if (selPerm) {
            let newperms = GenericBackendCall(selPerm.Key, schema, paths.permissions.permdelete, user, config,
                { parentmodel: GetObjectValue(selPerm, params.prmfield), modelcategory: params.modelcategory }, "pm_divdel")
            newperms.then(_ => { setSelPerm(UserPermissionInit[0]) }).then(_ => { setParams({ ...params, refresh: 99 }) })
        }
    };

    const CheckSchemaforModel = () => {

        let onschema: boolean = false;
        if (params.modelcategory !== "Schema") {

            userpermsAll.forEach((up: UserPermssion) => {
                if (up.SchemaPrm && up.SchemaPrm === selModel.Schema) {
                    onschema = true;
                }
            })
            if (!onschema) {
                setParams({ ...params, warning: "Model belongs to a schema not assigned to this user" })
            } else {
                setParams({ ...params, warning: "" })
            }
        }
    }

    //---managing permissions---
    const EditExistingPermission = () => {
        return (<div>
            <div style={{ fontWeight: "bold", fontSize: '22px' }}>Existing Permission:</div>
            <div className="pm_existprmitm">Model: {selModel.Name}</div>
            <div className="pm_existprmitm">User: {GetUserEmail(selPerm.User, allusers)}</div>
            <div className="pm_existprmitm" style={{ marginBottom: "10px" }}>Permission Type: {selPerm.Permission}
                {selPerm.Permission !== "creator" ? <PermDropdown /> : <div></div>}
            </div>

            <button className="pm_btn" onClick={_ => { setParams({ ...params, issavevisible: true }) }}>Save Changes</button>
            <button className="pm_btn" onClick={_ => { setParams({ ...params, isdeletevisible: true }) }}>Remove User</button>
        </div>)
    };

    const NewPermission = () => {
        return (
            <div id="pm_newperm">
                <div style={{ fontWeight: "bold" }}>New Permission:</div>

                <div className="pm_newpermitm">{params.modelcategory}: {selModel ? selModel.Name : {}}</div>
                <div className="pm_newpermitm">User Email: <UserDropdown /></div>
                <div className="pm_newpermitm">Permission Type: <PermDropdown /></div>
                {params.warning === "" ?
                    <button className="pm_btn" onClick={_ => { setParams({ ...params, issavevisible: true }) }}>Submit</button>
                    : <div>{params.warning}</div>}
            </div>)
    };

    const PermDropdown = () => {
        return (
            <select id="pm_sel1" value={selPerm.Permission} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setSelPerm({ ...selPerm, "Permission": e.target.selectedOptions[0].attributes[0].value }) }}>
                <option value=""> Select Permission </option>
                <option value="manage"> Manage</option>
                <option value="readwrite"> Read/Write</option>
                <option value="readonly"> Read Only</option>
            </select>
        )
    };

    const UserDropdown = () => {
        return (
            <select value={selPerm.User} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setSelPerm({ ...selPerm, 'User': e.target.selectedOptions[0].attributes[0].value }) }}>
                <option value={-1}>Select User:</option>
                {allusers.map((usr: UserAccount) => {
                    return (<option value={usr.id} key={usr.id}>
                        {usr.email}
                    </option>)
                })}
            </select>
        )
    }

    const ChangeModelType = (e: ChangeEvent<HTMLSelectElement>) => {
        let mcat = e.target.selectedOptions[0].attributes[0].value
        let keyfld = e.target.selectedOptions[0].attributes[1].value
        let prmfld = e.target.selectedOptions[0].attributes[2].value

        setParams({ ...params, modelcategory: mcat, modelkey: '', keyfld: keyfld, prmfield: prmfld })
        setSelModel({ Key: "", Name: "", ModelCategory: "", Schema: "" })
    }

    const ModelTypeDropdown = () => {
        return (
            <select id="pm_mdltypddown" value={params.modelcategory} onChange={(e: ChangeEvent<HTMLSelectElement>) => ChangeModelType(e)}>
                <option data-value="" data-fld="">Select:</option>
                <option data-value="Dashboard" data-keyfld='Key' data-namefld="DashboardPrm">Dashboard</option>
                <option data-value="Schema" data-keyfld='SchemaKey' data-namefld="SchemaPrm">Schema</option>
                <option data-value="QueryModel" data-keyfld='ModelKey' data-namefld="QueryModelPrm">Query Model</option>
                <option data-value="KPIString" data-keyfld='Key' data-namefld="KPIStringPrm">KPI String</option>
            </select >
        )
    }



    //----------------------
    return (
        <div id="pm_canvas" className="ndt_canvas">
            <br />
            <div id="pm_top" className="ndt_gridbox">
                <div id="pm_topbox" >
                    <div id="pm_topboxheader" className="ndt_title2">Permissions Manager</div>
                    <div id="pm_topboxheader_sub">Select Model Type</div>
                    <ModelTypeDropdown />
                </div>
            </div>
            {params.modelcategory !== "" ?
                <div id="pm_main">
                    <div id="pm_blockusr" className="ndt_gridbox">
                        <div className="ndt_title4">Manage {params.modelcategory} Permissions</div>
                        <GenericDropdown
                            data={params.modeldata}
                            keycol={"Key"}
                            namecol={"Name"}
                            divID="dpm_ddown"
                            promptstring={String("Select " + params.modelcategory + " to Edit Permissions")}
                            change={(e: ChangeEvent<HTMLSelectElement>) => ChangeModel(e)}
                            filtervalue={params.modelcategory}
                            filterfield={"ModelCategory"}
                        />

                        <div id="pm_lstusers" className="ndt_innerbox" >
                            <div >Users Enabled for {selModel.Name}</div>
                            {userpermsAll.map((up: any, i: number) => {
                                let usestyle = { backgroundColor: appcolors.coloraltprimary, color: "white" }
                                if (selPerm.Key === up.Key) { usestyle = { backgroundColor: appcolors.colorhighlight, color: "black" } }

                                if (up[params.prmfield] === selModel.Key) {
                                    let ue = String(GetUserEmail(up.User, allusers))
                                    return (
                                        <div key={i} className="pm_permexisting" style={usestyle} onClick={_ => { setSelPerm(up) }}>
                                            <div> U: {ue.substring(0, 23)}</div>
                                            <div>Type: {up.Permission}</div>
                                        </div>
                                    )
                                }
                            })}
                            <div className="pm_permexisting" style={selPerm.Key === "_" ? { backgroundColor: "yellow", color: "black" } : { backgroundColor: "lightblue", color: "black" }}
                                onClick={_ => setSelPerm({ ...selPerm, Key: "_", User: "" })}>Add New User</div>
                        </div>
                    </div>


                    <div id="pm_blockmgperms" className="ndt_gridbox">

                        <div id="pm_setperm">
                            {userpermsAll ?
                                <div id="pm_currentperms">
                                    <div id="pm_permbubble">
                                        {(() => {
                                            switch (selPerm?.Key) {
                                                case "": return <div>Select a model first</div>
                                                case "_": return <div><NewPermission /></div>
                                                default: return <EditExistingPermission />
                                            }
                                        })()}
                                    </div>
                                </div>

                                : <div></div>
                            }
                        </div>
                    </div>

                </div>
                : <div></div>}

            <Modal show={params.issavevisible}>
                <div className="ndt_modal">
                    <div style={{ fontSize: '20px' }}>Save permission for:
                        <div> {GetUserEmail(selPerm.User, allusers)}: {selModel.Name}</div>
                    </div>
                    <button className="ndt_btn1" style={{ margin: '10px', width: "120px" }} onClick={_ => AssignPermission()}>Save</button>
                    <div id="pm_divsubmit"></div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, issavevisible: false, refresh: params.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            <Modal show={params.isdeletevisible}>
                <div className="ndt_modal">
                    <div style={{ fontSize: '20px' }}>Delete permission for {selPerm.User}: {selModel.Name}</div>
                    <button className="ndt_btn2" style={{ margin: '10px', width: "120px" }} onClick={_ => DeletePermission()}>Delete</button>
                    <div id="pm_divdel"></div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdeletevisible: false, refresh: params.refresh + 1 })}>Close</button>
                </div>
            </Modal>

        </div >
    )
};
export default PermissionsManager;
