
import img1 from '../images/nodiant.png'
import "./css/TitlePage.css"
import { useNavigate } from 'react-router-dom';



const TitlePage = () => {

    let history = useNavigate();

    let heights: number[] = [21, 4, 8, 2, 16, 14, 1, 9, 20, 27, 15, 31, 8, 5, 2, 2, 5]
    let widths: number[] = [9, 15, 18, 5, 2, 5, 19, 15, 18, 4, 30, 6, 33, 25, 21, 3, 34]
    let glowpoints = Array.apply(null, Array(20)).map(function (_, i) {
        return {
            animationDelay: String(i * .1) + "s", top: String(30 * heights[i]) + "px", left: String(38 * widths[i]) + "px"
        }
    })

    let divstyle = {
        backgroundImage: `url(${img1})`, height: "300px",
        width: "300px", backgroundSize: '100%', backgroundPosition: 'center', zIndex: 1
    }

    return (<div id="titlepg_canvas" style={{ background: "black", color: "white" }}>
        <div></div>
        <div>
            <div id="titlepg_logo" style={divstyle}></div>
            <div onClick={_ => { history("/home") }}>
                <div id="titlepg_title" style={{ fontSize: '85px', letterSpacing: '.5em' }}>Nodiant</div>
                <div id="titlepg_subtitle" style={{ fontSize: '50px', letterSpacing: '.1em' }}>Enter</div>
            </div>
        </div>

        {glowpoints.map((g: any, i: number) => {
            return <div className='titlepg_glowpoint' key={i} style={g}></div>
        })}


    </div>)
};
export default TitlePage;