import { Key_Name, QueryModel, QueryRunDate, Schedule } from "../../../type"
import { ChangeEvent, useState, useEffect, useContext } from "react"
import DateString from "../../../functions/Date Functions/DateString"
import DateRemoveTimestamp from "../../../functions/Date Functions/DateRemoveTimestamp"
import { Modal } from 'react-bootstrap'
import { StartupContext } from "../../../App"
import CalculateStickyDate from "../../../functions/Date Functions/CalculateStickyDate"
import CalculateSchedule from "../../../functions/Date Functions/CalculateSchedule"
import { ScheduleInit } from "../../../InitTypes"
import GenericBackendCall from "../../../functions/Import/GenericBackendCall"
import GenericDropdown from "../../../functions/Dropdown_Generic"
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex"
import { Link } from "react-router-dom"
import "./css/QueryModelViewScheduler.css"
import QueryModelRerun from "../../../data/Query/QueryModel_Rerun"
import QueryModelRerunMulti from "../../../data/Query/QueryModel_RerunMulti"
import FilterData from "../../../functions/FilterData"
import DateDifference from "../../../functions/Date Functions/DateDifference"
import DateMatch from "../../../functions/Date Functions/DateMatch"
import IsInVector from "../../../functions/IsInVector"



const QueryModelViewScheduler = (props: any) => {

    let query: QueryModel = props.query
    let setQuery: any = props.setQuery
    let schedules: Schedule[] = props.schedules

    const { config, dispatcher, schema, user, paths, clickstyle } = useContext(StartupContext)

    interface onsched {
        ScheduleKey: string, ScheduleName: string, Dates: Date[]
    }
    interface ondate {
        AsofDate: Date;
        ScheduleKeys: string[];
        ScheduleNames: string[];
    }

    interface paramstype {
        asofdate: Date;
        enddate: Date;
        rundatetext: string;
        isrundetailvisible: boolean;
        issavevisible: boolean;
        isremovevisible: boolean;
        stickydatesize: number;
        periodname: string;
        refresh: number;
        minlimit: number;
        selectrange: boolean;
        selectminidx: number;
        selectmaxidx: number;
        ismultisavevisible: boolean;
        allonsched: onsched[];
        allondate: ondate[];
        selrdkeys: string[];
    }
    interface scheddate {
        AsofDate: Date;
        EndDate: Date;
        RunInt: number;
    }

    let [keyparams, setKeyParams] = useState<paramstype>({
        asofdate: new Date(), enddate: new Date(), rundatetext: '', issavevisible: false, isremovevisible: false,
        isrundetailvisible: false, periodname: '', stickydatesize: 1, refresh: 0, minlimit: 5, selectrange: false,
        selectminidx: -1, selectmaxidx: -1, ismultisavevisible: false, allonsched: [], allondate: [], selrdkeys: []
    })


    let [qrySchedules, setQrySchedules] = useState<Schedule[]>([])
    let [selSchedule, setSelSchedule] = useState<Schedule | null>(null)
    let [proposed, setProposed] = useState<Schedule | null>(null)
    let [scheduleDates, setScheduleDates] = useState<any[]>([])
    let [proposedDates, setProposedDates] = useState<any[]>([])
    let [rangedates, setRangeDates] = useState<Date[][]>([])



    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        if (query.Schedules.length > 0) {
            setQrySchedules(FilterData(schedules, "ScheduleKey", query.Schedules))
            //SetObjectfromKey(query.Schedules[0], schedules, "ScheduleKey", setSelSchedule, false)
        }
    }, [query, schedules])

    useEffect(() => {
        if (selSchedule) {        //calculate asofdate
            let newasofdate = CalculateStickyDate(selSchedule.AnchorDate, selSchedule.StickyDateRef, selSchedule.Period, selSchedule.IPeriod, selSchedule.OffsetDays, true, true)
            setKeyParams({ ...keyparams, asofdate: newasofdate })
        }
    }, [selSchedule?.OffsetDays, selSchedule?.StickyDateRef])

    useEffect(() => {
        if (!keyparams.selectrange) {
            setKeyParams({ ...keyparams, selectminidx: -1, selectmaxidx: -1 })
        }
    }, [keyparams.selectrange])

    useEffect(() => {
        if (keyparams.selectminidx >= 0 && keyparams.selectmaxidx >= 0) {
            let rdates: Date[][] = []
            {
                scheduleDates.map((sd: scheddate, i: number) => {
                    if (i >= keyparams.selectminidx && i <= keyparams.selectmaxidx) {
                        rdates.push([sd.AsofDate, sd.EndDate])
                    }
                })
            }
            setRangeDates(rdates)
        }
    }, [keyparams.selectminidx, keyparams.selectmaxidx])

    useEffect(() => {
        CheckAllSchedulesforDate(qrySchedules, [[keyparams.asofdate, new Date()]])
    }, [keyparams.isrundetailvisible])

    useEffect(() => {
        CheckAllSchedulesforDate(qrySchedules, rangedates)
    }, [keyparams.ismultisavevisible])


    const CheckAllSchedulesforDate = (schedules: Schedule[], dates: Date[][]) => {

        let allonscheds: onsched[] = []
        let allondates: ondate[] = []
        //date-level

        dates.forEach((rd: Date[]) => {
            let matchsched: string[] = []
            let matchschedname: string[] = []

            schedules.forEach((sch: Schedule) => {
                let datediff = Math.abs(DateDifference(sch.AnchorDate, rd[0], sch.Period)) + 3
                let alldates = CalculateSchedule(sch, Math.round(datediff + 1), -1)

                alldates.forEach((ad: Date[]) => {
                    if (DateMatch(ad[1], rd[0])) {
                        matchsched.push(sch.ScheduleKey)
                        matchschedname.push(sch.ScheduleName)
                    }
                })
            })

            allondates.push({ AsofDate: rd[0], ScheduleKeys: matchsched, ScheduleNames: matchschedname })
        })
        //schedule-level
        schedules.forEach((sch: Schedule) => {
            let matchdates: Date[] = []
            dates.forEach((rd: Date[]) => {
                let datediff = DateDifference(rd[0], sch.AnchorDate, sch.Period)
                let alldates = CalculateSchedule(sch, Math.round(datediff + 1), -1)
                alldates.forEach((ad: Date[]) => {
                    if (DateMatch(ad[1], rd[0])) {
                        matchdates.push(rd[0])
                    }
                })
            })
            allonscheds.push({ ScheduleKey: sch.ScheduleKey, ScheduleName: sch.ScheduleName, Dates: matchdates })
        })
        setKeyParams({ ...keyparams, allonsched: allonscheds, allondate: allondates })
    }

    const SetDatesfromSchedule = (sch: Schedule, setSchDates: any) => {
        //&& query.rundates.length > 0
        if (sch.IPeriod > 0 && sch.StickyDateRef !== "") {

            let alldates = CalculateSchedule(sch, keyparams.minlimit, -4)

            let sdates: scheddate[] = alldates.map((d: Date[]) => {
                let foundint = -1
                let calc_enddate = CalculateStickyDate(d[1], query.StickyDateRef, query.Period, query.IPeriod, query.OffsetDays, query.IncludeToDate, false)
                let runkeys: string[] = []
                query.rundates.map((rd: QueryRunDate, i: number) => {
                    if (DateString(DateRemoveTimestamp(rd.AsofDate)) === DateString(DateRemoveTimestamp(d[1]))) { //&& IsInVector(rd.Schedules, sch.ScheduleKey)  //why do I need the schedule to match
                        foundint = i
                        runkeys.push(rd.DateKey)
                    }
                })
                return ({ AsofDate: d[1], EndDate: calc_enddate, RunInt: foundint, RunDateKeys: runkeys })
            })
            setSchDates(sdates)
        }
    }

    useEffect(() => {
        if (selSchedule) { SetDatesfromSchedule(selSchedule, setScheduleDates) }
    }, [selSchedule, keyparams.minlimit])

    useEffect(() => {
        if (proposed) { SetDatesfromSchedule(proposed, setProposedDates) }
    }, [proposed])

    const ClickRun = () => {
        if (selSchedule) {
            let schedules: string[] = []

            keyparams.allondate.forEach((od: ondate) => {
                od.ScheduleKeys.forEach((sch: string) => {
                    if (!IsInVector(schedules, sch)) {
                        schedules.push(sch)
                    }
                })
            })
            QueryModelRerun(query.ModelKey, schema, "", keyparams.asofdate, keyparams.enddate, schedules, keyparams.rundatetext,
                user, config, "qmvs_divrun").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }
    const ClickDeleteRun = () => {
        keyparams.selrdkeys.forEach((k: string) => {
            console.log("delete run ", k)
            GenericBackendCall(k, schema, paths.query.rundatedelete, user, config, {}, "qmvs_divrun").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        })
    }

    const ClickMultiRun = () => {
        if (selSchedule) {

            let schedules: string[][] = keyparams.allondate.map((od: ondate) => {
                return od.ScheduleKeys
            })
            QueryModelRerunMulti(query.ModelKey, schema, "", rangedates, schedules, keyparams.rundatetext, user, config, "qmvs_divrun").then(_ => {
                setKeyParams({ ...keyparams, refresh: 99 })
            })
        }
    }

    const ClickAssign = () => {
        if (proposed) {
            GenericBackendCall(query.ModelKey, schema, paths.schedule.assignschedule, user, config,
                { schedulekey: proposed.ScheduleKey, asofdate: keyparams.asofdate, method: "assign" }, "qmvs_divsave").then(_ => {
                    setKeyParams({ ...keyparams, refresh: 99 })
                })
        }
    }

    const ClickRemove = () => {
        if (selSchedule) {
            GenericBackendCall(query.ModelKey, schema, paths.schedule.assignschedule, user, config,
                { schedulekey: selSchedule.ScheduleKey, method: "unassign" }, "qmvs_divrmv").then(_ => setKeyParams({ ...keyparams, refresh: 99 }))
        }
    }

    const SelectRunDate = (d: scheddate, i: number) => {
        if (keyparams.selectrange) { //multi run date
            if (keyparams.selectminidx === -1) {
                setKeyParams({ ...keyparams, selectminidx: i, selectmaxidx: i })
            } else {
                let selmin = keyparams.selectminidx
                let selmax = i
                if (selmin > selmax) {
                    setKeyParams({ ...keyparams, selectminidx: selmax, selectmaxidx: selmin })
                } else {
                    setKeyParams({ ...keyparams, selectminidx: selmin, selectmaxidx: selmax })
                }
            }
        } else { //single run date
            setKeyParams({ ...keyparams, asofdate: d.AsofDate, enddate: d.EndDate, isrundetailvisible: true, selrdkeys: scheduleDates[i].RunDateKeys })
        }

    }

    const CheckStyle = (i: number) => {
        if (i >= keyparams.selectminidx && i <= keyparams.selectmaxidx) {
            return clickstyle.itmSelected
        } else if (i == keyparams.selectminidx || i == keyparams.selectmaxidx) {
            return clickstyle.itmSelected
        } else {
            return clickstyle.itmUnselected
        }
    }

    const ScheduleRunDatesList = () => {
        return (
            <div id={"qmvs_scheddatesbox"}>
                {scheduleDates.map((d: scheddate, i: number) => {
                    return (
                        <div key={i} className="ndt_item qmvs_scheddate"
                            style={CheckStyle(i)}
                            onClick={(_) => SelectRunDate(d, i)}>
                            <div style={{ minWidth: "300px", width: '70%', display: "flex" }}>
                                {DateString(d.AsofDate)}
                                <div style={{ marginLeft: "5px", marginTop: "2px", fontSize: "16px" }}>(data thru {DateString(d.EndDate)})</div>
                            </div>
                            <div style={{ width: "100px" }}>{d.RunInt >= 0 ? "Ran" : ""}</div>
                        </div>)
                })
                }
            </div >
        )
    };


    return (<div id="qmvs_canvas">
        <div className="ndt_title3" style={{ width: "100%", fontSize: "26px" }}>Active Schedules</div>

        <div style={{ fontSize: "22px", marginTop: "5px" }}>Add Schedule</div>
        <div>
            <GenericDropdown
                data={qrySchedules}
                keycol={"ScheduleKey"}
                divID="qmvs_schedddown"
                namecol="ScheduleName"
                className="ndt_dropdown"
                promptstring="Select Schedule"
                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, qrySchedules, setSelSchedule, "") }}
            />
            {selSchedule ? <div>
                <div>{selSchedule.ScheduleName}</div>
                <div style={{ width: "300px" }}>

                </div>
                <div>
                    <button className="ndt_btn3" onClick={_ => { setKeyParams({ ...keyparams, minlimit: keyparams.minlimit + 5 }) }}>Show Earlier Schedules</button>
                    <button className="ndt_btn3" style={keyparams.selectrange ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setKeyParams({ ...keyparams, selectrange: !keyparams.selectrange }) }}>Select Range</button>
                    {keyparams.selectminidx !== keyparams.selectmaxidx && keyparams.selectrange ?
                        <button className="ndt_btn3" onClick={_ => { setKeyParams({ ...keyparams, ismultisavevisible: true }) }}>Run Selected</button> : <div></div>}
                </div>
                <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, isremovevisible: true }) }}>Remove</button>
            </div> : <div>No Query Schedule Selected</div>}
        </div>

        <div style={{ width: '100%' }}>
            <GenericDropdown
                data={schedules}
                divID="qmvs_schedaddddown"
                keycol="ScheduleKey"
                namecol="ScheduleName"
                promptstring="Select Schedule"
                className="ndt_dropdown"
                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, schedules, setProposed, "", ScheduleInit[0]) }}
            />
            <div style={{ marginRight: "10px" }}><Link to={'/schedules/manager'}>Create New</Link></div>
            {proposed ? <Link to={'/schedules/manager/' + proposed.ScheduleKey}>Go To Schedule</Link> : <div></div>}
        </div>

        <ScheduleRunDatesList />


        {proposed ? <div>
            {proposed.ScheduleKey !== selSchedule?.ScheduleKey ? <div>
                <div>
                    Proposed Schedule Dates:
                </div>
                <div>
                    {proposedDates.map((d: scheddate, i: number) => {
                        return (<div key={i} className="qmvs_scheditm" style={{ display: "flex", width: "400px" }}>
                            <div style={{ width: "120px" }}>{DateString(d.AsofDate)}</div>
                            <div style={{ width: "100px" }}>{d.RunInt >= 0 ? "Ran" : ""}</div>
                        </div>)
                    })}

                </div>
                <br />
                {proposed.Period !== "" && proposed.StickyDateRef !== "" ?
                    <div>
                        < div style={{ fontSize: '20px' }}>Set Schedule</div>
                        <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}>Assign</button>
                    </div>
                    : <div></div>}

            </div> : <div>Already assigned this schedule!</div>
            }
        </div> : <div></div>
        }

        <Modal show={keyparams.isrundetailvisible}>
            <div className="ndt_modal">
                <div className="ndt_modaltitle">Run as of Date {DateString(keyparams.asofdate)}?</div>
                <div>
                    <div>Schedules Affected:</div>
                    {keyparams.allonsched.map((os: onsched, i: number) => {
                        return (<div key={i}>{os.ScheduleName}</div>)
                    })}</div>
                <button className="ndt_btn1" style={{ width: "200px", margin: "10px" }} onClick={_ => ClickRun()}>Run</button>
                {keyparams.selrdkeys.length > 0 ?
                    <button className="ndt_btn2" onClick={_ => { ClickDeleteRun() }}>Delete Run</button>
                    : <div></div>}
                <div id="qmvs_divrun"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isrundetailvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.ismultisavevisible}>
            <div className="ndt_modal">
                <div className="ndt_modaltitle">Run Selected Dates?</div>
                <div style={{ height: "200px", overflowY: "scroll", background: "black", padding: "5px" }}>
                    {rangedates.map((rd: Date[], i: number) => {
                        return (<div key={i}>{DateString(rd[0])}</div>)
                    })}
                </div>
                <button className="ndt_btn1" style={{ width: "200px", margin: "10px" }} onClick={_ => ClickMultiRun()}>Run</button>
                <div id="qmvs_divrun" style={{ height: "30px" }}></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, ismultisavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.issavevisible}>
            <div className="ndt_modal">
                <div className="ndt_modaltitle">Assign Schedule {proposed?.ScheduleName}?</div>
                <button className="ndt_btn1" style={{ width: "200px", margin: "10px" }} onClick={_ => ClickAssign()}>Assign</button>
                <div id="qmvs_divsave"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isremovevisible}>
            <div className="ndt_modal">
                <div className="ndt_modaltitle">Unassign Schedule {keyparams.periodname} ({selSchedule?.IPeriod})?</div>
                <button className="ndt_btn1" style={{ width: "200px", margin: "10px" }} onClick={_ => ClickRemove()}>Remove Schedule</button>
                <div id="qmvs_divrmv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isremovevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

    </div >)
}
export default QueryModelViewScheduler