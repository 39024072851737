

const GetInputText = (divID: string) => {
    //ref: scenariobuilder
    let elem = document.getElementById(divID)

    if (elem) {
        return elem.innerHTML
    } else {
        return ""
    }
};
export default GetInputText;