import Employee_Dropdown from "./subfunctions/Employee_Dropdown";
import { ChangeEvent, useState, useEffect, useContext } from "react";
import FilterData from "../../../functions/FilterData";
//import HierarchyData from "../../../functions/HierarchyData";
import DeptViewerEmps from "./subfunctions/DeptViewerEmps";
import { Emp_State } from "../../../type";
import DatePicker from "react-date-picker";
import EmployeeStateSliceImport from "../../../data/Employee/EmployeeStateSlice_Import";
import { StartupContext } from "../../../App";

//import "./css/DeptViewer.css"
import "./css/DeptViewerPlus.css"
const DeptViewer = () => {

    const { config, dispatcher, schema } = useContext(StartupContext)

    const [levelsdata, setLevelsdata] = useState<any>([]);
    const [deptemps, setDeptEmps] = useState<any>([]);
    const [selDate, setSelDate] = useState<Date>(new Date());
    const [dateStr, setDateStr] = useState<string>("");
    const [deptID, setDeptID] = useState<string>("")
    const [data, setData] = useState<Emp_State[]>([])

    useEffect(() => {
        //import data, change when date changes
        let backenddata = { "asofdate": selDate }
        let EmpPromise = EmployeeStateSliceImport(backenddata, schema, false, config, dispatcher);
        EmpPromise.then(d => setData(d))
    }, [selDate, deptID])

    const EmpSelect = (e: ChangeEvent<HTMLSelectElement>) => {

        console.log("EmpSelect")
        let name = e.target.selectedOptions[0].attributes[1].value

        let DeptData = FilterData(data, "DeptID", name)

        setLevelsdata(CustomHierarchy(DeptData))
        setDeptEmps(DeptData)
        setDeptID(name)
    };

    console.log('viewer rerender')

    const CustomHierarchy = (data: Emp_State[]) => {

        let Levels = data.map(((e: Emp_State) => {
            return ([e.EmpID, [e.ReportsToID, "", ""]])
        }));

        //higher orders
        [0].forEach(i => {
            data.forEach((e: Emp_State) => {
                Levels.forEach((l: any) => {
                    if (l[1][i] === e.EmpID) {
                        l[1][i + 1] = e.ReportsToID
                    }
                })
            })
        })
        return (Levels)
    }

    const ChgDate = (date1: any) => {
        let ed: Date = new Date(String(date1))
        setSelDate(ed)
        let dates = { 'day': ed.getDate(), 'month': ed.getMonth() + 1, 'year': ed.getFullYear() }
        setDateStr(dates['month'] + "-" + dates['day'] + "-" + dates['year'])
    };

    return (
        <div className="ndt_canvas">
            <div id="dv_main">
                <div id="deptviewheaderbox" >
                    <div id="deptviewheader">
                        Department Hierarchy Viewer
                        <div id="dvheading">

                            <Employee_Dropdown Employees={data} keyfield="DeptID" textfield="DeptID"
                                change={(e: ChangeEvent<HTMLSelectElement>) => EmpSelect(e)} />
                            <div style={{ marginLeft: "20px" }}>As of Date: </div>
                            <div id="dv_date" style={{ backgroundColor: "white", color: "black" }}><DatePicker value={selDate} onChange={(e: any) => ChgDate(e)} /></div>

                        </div>
                    </div>
                </div>

                <DeptViewerEmps data={[levelsdata, deptemps, deptID]} />
            </div>

        </div>


    )
};
export default DeptViewer;
