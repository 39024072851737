
import { Connection } from '../../type';
import AxiosUpload from '../AxiosUpload';


const ConnectionSetUpload = async (connmodelkey: string, connections: Connection[], modelcategory1: string,
    modelcategory2: string, conntype: string, schema: string,
    path: string, config: any, elementstring: string) => {

    let elem: HTMLElement | null = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    let promise: Promise<any> | null = null;

    let conns = connections.map((conn: Connection) => {
        return [
            String(conn.Field1).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(conn.Field2).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
        ]
    });

    let dataArray = new FormData();

    dataArray.append("modelkey", connmodelkey);
    dataArray.append("connections", String(conns));
    dataArray.append("connectiontype", String(conntype));
    dataArray.append("schemakey", String(schema));
    dataArray.append("modelcategory1", String(modelcategory1))
    dataArray.append("modelcategory2", String(modelcategory2))


    promise = AxiosUpload(dataArray, path, config, elem, "");

    return (promise);

}
export default ConnectionSetUpload;