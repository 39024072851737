import DateRemoveTimestamp from "./DateRemoveTimestamp"


const DateMatch = (date1: Date, date2: Date): boolean => {
    //ref: querymodelviewscheduler, kpimanager,
    //console.log(String(DateRemoveTimestamp(date1)).substring(0, 15), String(DateRemoveTimestamp(date2)).substring(0, 15))

    return String(DateRemoveTimestamp(date1)).substring(0, 15) === String(DateRemoveTimestamp(date2)).substring(0, 15)
};

export default DateMatch;