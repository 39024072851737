import * as d3 from 'd3';
import UniqueArrayValues from '../../functions/UniqueArrayValues';
import BarChartStacked1 from './BarChartStacked1';
import PivotArray from '../../functions/PivotArray';
//import Textwrap from './functions/Textwrap';
import LineChartStacked1 from './LineChartStacked1';
//import LineChart1 from './chartfns/LineChart1';
import DateString from '../../functions/Date Functions/DateString';
import DateRemoveTimestamp from '../../functions/Date Functions/DateRemoveTimestamp';
import ObjectSort from '../../functions/ObjectSort';
import GetTextColor from '../../functions/GetTextColor';
import ChartDelete from '../newfunctions/ChartDelete';


function ChartSample(charttype, svgid, data, detaildata, [xcol, ycols, groupcol], axislabels, colorauto, 
	colors, size, showLabels, hoverdetail, formatoptns, sameYscale=false, includeZeros=false) {
		//ref: samplegraph, kpimanager, dashboardview, leaderview
	
		ChartDelete(svgid)
		
	data = ObjectSort(data,xcol)

	if (groupcol===""){groupcol = "ModelName"} //no grouping, use same for each datapoint
	//delete previous
	// let vardiv = document.getElementById(barid);
	// if (vardiv){
	// 	vardiv.parentNode.removeChild(vardiv);
	// }

	var margin = {top: 20, right: 10, bottom: 50, left: 50},
		width = size[0] - margin.left - margin.right,
		height = size[1] - margin.top - margin.bottom;

	let nvals = data.length
	let wait_period = 500/nvals
	
	let ycol = ycols[0]
    let xgroups = UniqueArrayValues(data,xcol)
    let groups = UniqueArrayValues(data,groupcol)
    let arrs = ycols.map((ycol)=>{return PivotArray(data,xcol,"",ycol)})
		
	let useopacity = 0
	if (showLabels==="On"){ useopacity = 1}

	let fmt = ""
	let rnd = 2
	let clr = GetTextColor(colors[0])
	let fntsize = '10px'
	let lineclr = "white"
	let secondarylineclr = "blue"
	if (formatoptns){
		fmt = formatoptns.format
		rnd = formatoptns.rounding
		clr = GetTextColor(colors[0]) //still auto
		fntsize = formatoptns.fntsize
		lineclr = formatoptns.lineclr
		secondarylineclr = formatoptns.secondarylineclr
	}

	let dev = d3.deviation(data,function(d) { return +d[ycol] });
	let minval = d3.min(data.map((d)=>{return +d[ycol]}))
	let maxval =  d3.max(data.map((d)=>{return +d[ycol]}))
	let minsd = d3.max([minval/2,  dev])
	
	if (charttype==="Bar"){
		let arr = PivotArray(data, xcol,"",ycol)
		arr.forEach((pv)=>{
			if (pv.Sum>maxval){
				maxval = pv.Sum
			}
		})
	}

	// set the ranges
    var x = d3.scaleBand()
                .range([0, width])
                .padding(0.1);
                
    x.domain(data.map(function(d) { 
        return DateString(DateRemoveTimestamp(d[xcol])); }));

	let yscales = []
	if (sameYscale){
		var y = d3.scaleLinear()
					.range([height, 0]);
			let mina = d3.max([0, minval - minsd ]);

			y.domain([mina/.9, maxval*1.1]);
			ycols.forEach((_)=>{
				yscales.push(y)
			})
			
	} else {
		ycols.forEach((_,i)=>{
			var y = d3.scaleLinear()
					.range([height, 0]);
			let mina = d3.max([0, minval - minsd ]);
			y.domain([mina, d3.max(arrs[i], function(d) { return +d["Sum"]*1.1; })]);
			yscales.push(y)
		})
	}



	var colorScales;

	if (colorauto==="Continuous"){
		try {
			colorScales = []
			colors.forEach((cl,i)=>{
				if (i/2 !== Math.round(i/2)){
					//pair off colors into separate scales
					let scl = d3.scaleSequential()
									.interpolator(d3.interpolateRgbBasis([colors[i-1], colors[i]])) // Use interpolateRgbBasis to interpolate between colors
									.domain([minval, maxval]);
					colorScales.push(scl)
				}
			})
			
		} catch(err){
			colorScales = [d3.scaleLinear("#FFFFFF")];
		}
	} else if (colorauto==="Discrete"){
		colorScales = [d3.scaleOrdinal().range(colors)];
	}


	var svg = d3.select(svgid).append("svg")
		.attr("id","dsamplechart")
		.attr("width", width + margin.left + margin.right)
		.attr("height", height + margin.top + margin.bottom)
		.append("g")
		.attr("transform","translate(" + margin.left + "," + margin.top + ")");
		//---subfunctions - particular graph
		if (charttype==="Bar"){
			BarChartStacked1(svg, data, detaildata, xcol, ycols, xgroups, groupcol, groups, colorScales, x, yscales, height, 
				0, clr, useopacity, wait_period, width, svgid, hoverdetail, includeZeros)
		}
		if (charttype==="Line"){
			//amended to include multiple y fields
			let attrs = {linecolor: lineclr, secondarylinecolor: secondarylineclr}
			LineChartStacked1(svg, data, xcol, ycols, x, yscales, groupcol, groups, colorScales, attrs, useopacity, clr, fmt, rnd, wait_period)
		}
		



		
		if (charttype!=="Bar"){
			
		}

		// add the x Axis
		let xaxis = d3.axisBottom(x);
		//var tickValues = data.map((d, i) => {if (i % textN ===0){return  d[xcol]} else {return ""}})

		//xaxis.tickValues(tickValues)

	  	svg.append("g")
		  .attr("transform", "translate(0," + height + ")")
		  .style("font-size",'8px')
		  .attr("fill",clr)
		  .call(xaxis);



		svg.append('text')
			.attr("id","xaxislabel")
			.attr('transform','translate('+ (width/2) + "," + (height+margin.top + 20) + ")" )
			.style("text-anchor","middle")
			.attr("fill",clr)
			.text(axislabels[0])
			//.call(Textwrap,width/xgroups.length-5,.2)


	  	// add the y Axis
	  	svg.append("g")
			.attr("class","y axis")
		  	.call(d3.axisLeft(yscales[0]));

		svg.append('text')
			  .attr("id", 'yaxislabel')
			  .attr("transform","rotate(-90)")
			  .attr("y",0-margin.left - 4)
			  .attr("x",0-(height/2))
			  .attr("dy", '1em')
			  .style("text-anchor","middle")
			  .attr("fill",clr)
			  .text(axislabels[1])
			  
  		
        // let colordata = []
        // groups.forEach((grp,i) => {
        //     colordata.push([grp,colorScales(i)])
        // });	  

       // return(colordata)      


} //end function

export default ChartSample;

