
import { ChangeEvent } from 'react'

const SchedulePeriodDropdown = (props: any) => {
    //ref: schedulemanager, datasetwalkthrough
    let classname = props.className;
    let schedule = props.schedule;
    let setSchedule = props.setSchedule;

    return (
        <select value={schedule.Period} className={classname} onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            if (schedule) {
                setSchedule({ ...schedule, Period: e.target.selectedOptions[0].attributes[0].value })
            }
        }}>
            <option>Select Period:</option>
            <option value="Weekly" >Weekly</option>
            <option value="Monthly" data-num="30" data-name="Months">Monthly</option>
            <option value="Daily" data-num="1" data-name="Days">Daily</option>
            <option value="Yearly" data-num="365" data-name="Years">Yearly</option>
        </select>
    )
};
export default SchedulePeriodDropdown 