import axios from "axios";
import { usersAllActionCreator, usersAllDeleteActionCreator } from "../../reducers/user_actions";


const UserImport = async (config: any, dispatcher: any, store: boolean) => {

    let data;
    let users: any[] = []
    try {
        data = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/api/accounts/usersview`, config)
        users = data.data;
    }
    catch (err) {
        console.log(err)
    }
    if (store) {
        dispatcher(usersAllDeleteActionCreator({}))
        users.forEach((usr: any) => {
            dispatcher(usersAllActionCreator(
                {
                    id: String(usr.id),
                    email: usr.email,
                    group: "",
                    level: usr.level,
                    defaultschema: usr.defaultschema
                }
            ))
        })
    }

    return (users)

};


export default UserImport;