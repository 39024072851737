import { useState, ChangeEvent, useEffect, } from 'react'
//import DataTable from "../../../functions/DataTable";

import { useContext } from "react";
import { StartupContext } from "../../../App";
import { FieldMap, FilterModel, TableModel, ConnectionModel, Workspace } from '../../../type';
import DatePicker from 'react-date-picker';
import CustomTable from '../../../functions/EditableTable/CustomTable';
import ExcelExport from '../../../functions/ExcelExport';
import ImportAndSetState from '../../../functions/Import/ImportAndSetState';
import FieldsImport from '../../../data/Fields/Fields_Import';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import ModelCategoryDropdown from '../../../functions/Dropdowns/ModelCategoryDropdown';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import RemovefromArray from '../../../functions/Array Functions/RemovefromArray';
import FieldDropdown from '../fields/subfunctions/FieldDropdown';
import IsInVector from '../../../functions/IsInVector';
import GetFieldName from '../../../functions/GetFieldName';
import ChangeParamSelectorbyIndex from '../../../functions/ElementSelect/ChangeParamSelectorbyIndex';
import GetObjectField from '../../../functions/Object Functions/GetObjectField';
import AddRemovefromArray from '../../../functions/Array Functions/AddRemovefromArray';
import TableGroupsImport from '../../../data/Tables/TableGroups_Import';
import TableGroupDropdown from '../../../functions/ConnectionModel Functions/TableGroupDropdown';
import SerializeDataFrame from '../../../functions/Import/SerializeDataFrame';
import ConvertBackendArryString from '../../../functions/String Functions/ConvertBackendArryString';


const GenericDataTable = () => {

    const { config, dispatcher, schema, paths, user } = useContext(StartupContext)

    interface paramtype {
        workspacekey: string;
        catfldkeys: string[];
        catfldnames: string[];
        catmdlsfull: string[];
        connmdlkey: string;
        connmdlname: string;
        tablegroup: string;
        seltbls: string[];
        filtKey: string;
        dateStr: string;
        nextmodelcat: string;
        asofdate: Date;
        checktablemodels: boolean;
        listdetails: any;
    }

    const [data, setData] = useState<any[]>([])
    const [filtModels, setFiltModels] = useState<FilterModel[]>([])
    const [changeState, setChangeState] = useState<any>(0)
    const [fields, setFields] = useState<FieldMap[]>([])
    const [tables, setTables] = useState<TableModel[]>([])
    const [workspaces, setWorkspaces] = useState<Workspace[]>([])
    const [keyparams, setKeyParams] = useState<paramtype>({
        workspacekey: '', catfldkeys: [], catfldnames: [], catmdlsfull: [], connmdlkey: '', connmdlname: '', tablegroup: '', seltbls: [],
        filtKey: "", dateStr: "", nextmodelcat: '', asofdate: new Date(), checktablemodels: false, listdetails: ''
    })
    let [connModels, setConnModels] = useState<ConnectionModel[]>([])
    let [tablegroups, setTablegroups] = useState<any | null>(null)


    useEffect(() => {
        ImportAndSetStateGeneric(setFiltModels, "", schema, paths.filtersets.filtermodelview, user, config, {})
        ImportAndSetStateGeneric(setTables, "*", schema, paths.tables.tablemodelview, user, config, {})
        ImportAndSetState(FieldsImport, setFields, "", schema, config, dispatcher, false)
        ImportAndSetStateGeneric(setConnModels, "", schema, paths.tables.connectionmodelview, user, config)
        ImportAndSetStateGeneric(setWorkspaces, "", schema, paths.schema.workspaceview, user, config)
    }, [])


    useEffect(() => {
        let mdlname = GetObjectField(keyparams.connmdlkey, connModels, "ModelKey", "ModelName")
        setKeyParams({ ...keyparams, connmdlname: mdlname })

        TableGroupsImport(keyparams.connmdlkey, schema, paths.tables.connectionnetwork, user, config, dispatcher, false).then(d => {
            setTablegroups({ tables: d })
        })

    }, [keyparams.connmdlkey])

    useEffect(() => {
        if (tablegroups) {
            let tbls = tablegroups.tables[keyparams.tablegroup]
            setKeyParams({ ...keyparams, seltbls: tbls })
        }
    }, [keyparams.tablegroup])

    // const GetModelCategoriesfromFields = () => {
    //     let mcats: string[] = []
    //     keyparams.catfldkeys.forEach((key: string) => {
    //         let fldcat = GetFieldName(fields, key, "Key", "ModelCategory")
    //         if (!IsInVector(mcats, fldcat)) {
    //             mcats.push(fldcat)
    //         }
    //     })
    //     return mcats
    // }

    const ClickCheckModels = () => {
        GenericBackendCall('', schema, paths.generics.genericdataview, user, config, {
            modelcats: keyparams.catmdlsfull, workspacekey: keyparams.workspacekey, fldkeys: keyparams.catfldkeys,
            connmdlkey: keyparams.connmdlkey, asofdate: keyparams.asofdate,
            filtermodelkeys: [keyparams.filtKey], rulemodelkeys: [], metrickeys: [], categoriesonly: 'true'
        }).then(d => {
            let modellists: any[] = d

            GenericBackendCall('', schema, paths.generics.genericmodelnames, user, config, { models: modellists }).then(e => {
                let modellists: any[] = e
                console.log(e)
                setKeyParams({ ...keyparams, listdetails: modellists })
            })
        })
    }

    const ChangeFModel = (e: string) => {
        if (e !== "None") {
            setKeyParams({ ...keyparams, filtKey: e })
        } else {
            setKeyParams({ ...keyparams, filtKey: "" })
        }
    }

    const UpdateData = () => {
        let importpromise = GenericBackendCall("", schema, paths.generics.genericdataview, user, config,
            {
                modelcats: keyparams.catmdlsfull, workspacekey: keyparams.workspacekey, fldkeys: keyparams.catfldkeys,
                connmdlkey: keyparams.connmdlkey, asofdate: keyparams.asofdate,
                filtermodelkeys: [keyparams.filtKey], rulemodelkeys: [], metrickeys: []
            })
        importpromise.then(d => {
            setData(SerializeDataFrame(d))
            console.log(SerializeDataFrame(d))
        })
    }


    const FilterDivs = () => {
        return (<div>
            <GenericDropdown
                data={filtModels}
                keycol="ModelKey"
                namecol="ModelName"
                change={(e: ChangeEvent<HTMLSelectElement>) => ChangeFModel(e.target.selectedOptions[0].value)}
                promptstring="Apply Filter Model"
                className="ndt_dropdown"
                includeDefault={true}
                defaultstring="None"
            />
        </div>)
    }

    const FileExport = () => {
        ExcelExport({ csvDataArry: [data], sheetNames: ["dataset"], filename: "employeedata" })
    }


    const Addcatfld = (val: string) => {
        if (!IsInVector(keyparams.catfldkeys, val)) {
            let newarrykeys = keyparams.catfldkeys
            newarrykeys.push(val)
            let newarrynames = keyparams.catfldnames
            newarrynames.push(GetFieldName(fields, val))
            setKeyParams({ ...keyparams, catfldkeys: newarrykeys, catfldnames: newarrynames })
        }
    }
    const ClickRmvCatfld = () => {
        if (keyparams.catfldkeys.length > 0) {
            let newarrykeys = RemovefromArray(keyparams.catfldkeys, keyparams.catfldkeys[keyparams.catfldkeys.length - 1], "")
            let newarrynames = RemovefromArray(keyparams.catfldnames, keyparams.catfldnames[keyparams.catfldnames.length - 1], "")
            setKeyParams({ ...keyparams, catfldkeys: newarrykeys, catfldnames: newarrynames })
        }
    }
    const AddCatMdl = (val: string) => {
        let newarry = AddRemovefromArray(keyparams.catmdlsfull, val)
        setKeyParams({ ...keyparams, catmdlsfull: newarry })
    }

    return (
        <div id="" className='ndt_canvas'>
            <div id="" className="ndt_gridbox" style={{ overflow: "visible" }}>
                <div style={{ marginLeft: "10px", display: "block" }}>
                    <div className="ndt_title2" style={{ marginLeft: "10px" }}>Table Data Viewer</div>
                    <div>
                        <GenericDropdown
                            data={workspaces}
                            change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, workspaces, keyparams, setKeyParams, "workspacekey", "WorkKey", "")}
                            keycol="WorkKey"
                            namecol="WorkspaceName"
                            className="ndt_dropdown"
                            promptstring="Select Workspace"
                            includeDefault={true}
                            defaultstring="Default"
                            value={keyparams.workspacekey}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <GenericDropdown
                                data={connModels}
                                keycol="ModelKey"
                                namecol="ModelName"
                                className="metr_ddown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, connModels, keyparams, setKeyParams, "connmdlkey", "ModelKey", "") }}
                                promptstring="Select Connection Model"
                                value={keyparams.connmdlkey}
                            />
                            <TableGroupDropdown
                                tablegroups={tablegroups}
                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setKeyParams({ ...keyparams, tablegroup: e.target.selectedOptions[0].attributes[0].value })
                                }}
                            />
                        </div>
                        {keyparams.seltbls && keyparams.seltbls.length > 0 ?
                            <div style={{ display: "flex" }}>
                                <div className='ndt_innerbox' style={{ width: '300px', padding: "10px" }}>
                                    <div style={{ fontSize: "20px" }}>Include Full Model Fields</div>

                                    <div>

                                        <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            AddCatMdl(e.target.selectedOptions[0].attributes[0].value)
                                        }}>
                                            <option value="" data-value={""}>Select Table</option>
                                            {keyparams.seltbls.map((tbl: string, i: number) => {
                                                return (<option key={i} value={tbl} data-value={tbl}>{tbl}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div style={{ height: "50px" }}>{String(keyparams.catmdlsfull)}</div>
                                    <button className='ndt_btn2' style={{ fontSize: "15px", height: "30px", margin: '3px' }} onClick={_ => { ClickRmvCatfld() }}>Remove Last</button>

                                </div>
                                <div className='ndt_innerbox' style={{ width: '300px', padding: "10px" }}>
                                    <div style={{ fontSize: "20px" }}>Include Individual Table Fields</div>
                                    <div>
                                        <div>
                                            <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                                setKeyParams({ ...keyparams, nextmodelcat: e.target.selectedOptions[0].attributes[0].value })
                                            }}>
                                                <option value="" data-value={""}>Select Table</option>
                                                {keyparams.seltbls.map((tbl: string, i: number) => {
                                                    return (<option key={i} value={tbl} data-value={tbl}>{tbl}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <FieldDropdown
                                            change={(e: ChangeEvent<HTMLSelectElement>) => Addcatfld(e.target.selectedOptions[0].attributes[0].value)}
                                            includecategory={false}
                                            filterfield={["ModelCategory"]}
                                            filtervalue={[keyparams.nextmodelcat]}
                                            className="ndt_dropdown"
                                        />
                                    </div>
                                    <div style={{ height: "50px" }}>{String(keyparams.catfldnames)} </div>
                                    <button className='ndt_btn2' style={{ fontSize: "15px", height: "30px", margin: '3px' }} onClick={_ => { ClickRmvCatfld() }}>Remove Last</button>
                                </div>

                                <div className='ndt_innerbox' style={{ overflow: "visible" }}>
                                    <div>Filter Model</div>
                                    <FilterDivs />
                                    <div style={{ marginTop: '10px' }}>
                                        <div>Date</div>
                                        <DatePicker className="datepick_class" value={keyparams.asofdate} onChange={(date: any) => setKeyParams({ ...keyparams, asofdate: date })} />
                                    </div>

                                </div>
                            </div> : <div></div>}
                    </div>
                    <button className="ndt_btn1" onClick={_ => UpdateData()}>Populate</button>
                    <button className="ndt_btn1" onClick={_ => ClickCheckModels()}>Check Models</button>
                </div>
            </div>
            <div className='ndt_gridbox'>
                {data.length > 0 ?
                    <div><CustomTable
                        data={data}
                        colsHide={["PrevDate", "NextDate"]}
                        rowcount={13}
                        keycol={"StateKey"}
                        modelcategory=""
                        changestate={setChangeState}
                        optionalchoices={[]}
                        hardcodedfields={[]}
                        editable={null}
                        backendstring={paths.generics.datachange}
                        classname="empdatatbl"
                        mainDiv="empdata_div"
                        fields={fields}
                        shape={[1400, 800]}
                    />
                        <button className="ndt_btn1" style={{ marginLeft: '40px', marginTop: '10px' }} onClick={FileExport}>Export </button>
                    </div>
                    : <div></div>}
            </div>
        </div >
    )
};
export default GenericDataTable;
