import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FieldMap } from "../type";
import { FieldMapInit } from '../InitTypes';


export const fieldmapSlice = createSlice({
	name: 'fieldnames_action',
	initialState: FieldMapInit,
	reducers: {
		fielddata: {
			reducer: (state, { payload }: PayloadAction<FieldMap>) => {
				state.push(payload);
			},
			prepare: ({ Key, SchemaName, ModelName, ModelCategory, AttrName, FieldName, HeaderName, DataType,
				FieldCategory, TrackChanges, DateChanged, OnField, GroupFieldKey, IsTableModel, IsNewField, AutoTrack, ConditionNote, Headers }: FieldMap) => ({
					payload: {
						Key: Key,
						SchemaName: SchemaName,
						ModelName: ModelName,
						ModelCategory: ModelCategory,
						AttrName: AttrName,
						FieldName: FieldName,
						HeaderName: HeaderName,
						DataType: DataType,
						FieldCategory: FieldCategory,
						TrackChanges: TrackChanges,
						AutoTrack: AutoTrack,
						DateChanged: DateChanged,
						OnField: OnField,
						GroupFieldKey: GroupFieldKey,
						IsTableModel: IsTableModel,
						IsNewField: IsNewField,
						ConditionNote: ConditionNote,
						Headers: Headers
					}
				})
		},
		field_delete: (state, _: PayloadAction<{}>) => {
			state.splice(0, state.length);
		},
	}
});

export const {
	fielddata: fieldDataActionCreator,
	field_delete: fielddeleteActionCreator,
} = fieldmapSlice.actions;
