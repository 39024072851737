
const ChartTypeDropdown = (props: any) => {

    let change = props.change
    let className = props.className

    return (
        <select className={className} onChange={change}>
            <option id="" value="">Select Chart Type</option>
            <option id="Table" value="Table">Table</option>
            <option id="TableHorizontal" value="TableHorizontal">Horizontal Table</option>
            <option id="Bar" value="Bar">Bar Chart</option>
            <option id="BarHorizontal" value="BarHorizontal">Horizontal Bar Chart</option>
            <option id="Line" value="Line">Line Chart</option>
            <option id="Combo" value="Combo">Combo</option>
            <option id="Bar3D" value="Bar3D">Bar 3D</option>
        </select>
    )
};
export default ChartTypeDropdown;

export const ChartTypeLineBarDropdown = (props: any) => {
    let change = props.change
    let className = props.className

    return (
        <select className={className} onChange={change}>
            <option id="" value="">Select Chart Type</option>
            <option id="Bar" value="Bar">Bar Chart</option>
            <option id="Line" value="Line">Line Chart</option>
        </select>
    )
};
