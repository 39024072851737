import IsInVector from "./IsInVector";

const FilterData = (data, onField, filterValues, except=false) => {
	//ref: a lot, querymodelviewscheduler, querymodelviewvisual, metricsmanager, employeedetail
	
	//onfield can be an array for nested fields
	let returndata = []	
	if (typeof(filterValues)!=="object"){
		filterValues=[filterValues]
	}	

	function checkagainst(dataval, itm){
		if (except){
			if (!IsInVector(filterValues,String(dataval),false)){
				returndata.push(itm)
			}
		} else {
			if (IsInVector(filterValues,String(dataval),false)){
				returndata.push(itm)
			}
		}
	};
	if (data){
		data.forEach(d => {
				//for nested fields, e.g., {Date: {id: '1', children:{child:"A"}}}
				if (typeof(onField) ==="object"){
					let checkval = d;
					onField.forEach((fld)=>{
						if (checkval!==null){
							checkval = checkval[fld]
						}
					})
					checkagainst(checkval,d)
				} else{
	
				//normal check
				checkagainst(d[onField],d)
			}
		})
	}
	return(returndata)
};

export default FilterData;