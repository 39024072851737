

const SetTextAreaText = (divID, val, add=false) => {
    //ref: aimodelmanager, scenariobuilder, AIQueryPrompt
    let elem = document.getElementById(divID)
    if (elem){
        if (add){
            elem.innerHTML = elem.innerHTML + " " + val
            elem.value = elem.innerHTML + " " + val
        } else {
            elem.innerHTML = val
            elem.value = val
        }
        
    }

};
export default SetTextAreaText