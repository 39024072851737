import { FormEvent, ChangeEvent, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginActionCreator } from '../../../reducers/user_actions';
import { Link } from 'react-router-dom'
import AxiosUpload from '../../../data/AxiosUpload';
import { UserInit } from '../../../InitTypes';
import { StartupContext } from '../../../App';
import { UserAccount, Schema } from '../../../type';
import { schemadeleteActionCreator, schemaActionCreator } from "../../../reducers/schema_actions"
import { useEffect } from 'react';
import GetGuestUser from '../../../functions/User Functions/GetGuestUser';
import GetGuestPW from '../../../functions/User Functions/GetGuestPW';


import "./css/login.css"
import "./css/login_cascade.css"


const Login = () => {

	let { allusers } = useContext(StartupContext)
	let schemas = useSelector((state: any) => state.schemas) //need this for this page
	let loggedinuser = useSelector((state: any) => state.user)
	let history = useNavigate();
	let dispatcher = useDispatch();

	let [loginerr, setLoginErr] = useState<string>("")
	const [formData, setFormData] = useState(UserInit);
	let { id, email, password, authtoken } = formData;
	let [guestUser, setGuestUser] = useState<UserAccount | null>(null)

	useEffect(() => {
		if (loggedinuser && loggedinuser.email != "") {
			history("/home")
		}
	}, [loggedinuser])

	useEffect(() => {
		GetGuestUser(allusers, setGuestUser)
	}, [allusers])

	const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
		//console.log('submit')
		e.preventDefault();
		login_auth(id, email, password, authtoken)

	};

	const GuestLogin = () => {
		//console.log(guestUser)
		if (guestUser) {
			login_auth(guestUser.id, "guest@guest.com", GetGuestPW(), authtoken)
		}
	}

	function OnComplete() {
		window.location.reload() //reload to reset config (app.tsx - getauthconfigheaders). I think this helps
		setLoginErr("logged in successfully")
	}

	const login_auth = (id: string, email: string, password: string, authtoken: String) => {
		//authentication - move to separate file

		const config = {
			headers: {
				'Content-Type': 'application/json',
			}
		};
		const body = JSON.stringify({ id, email, password, authtoken });

		let promise = AxiosUpload(body, "auth/jwt/create", config, null, "")
		promise.then(resp => {
			try {
				let rdata: any = resp?.data
				let accesstoken: string = rdata.access
				let refreshtoken: string = rdata.refresh
				setLoginErr("logging in...")

				//get user id from list of users
				let level = ""
				let defaultschema = ''

				allusers.forEach((u: UserAccount) => {
					if (u.email === email) {
						id = u.id
						level = u.level
						defaultschema = u.defaultschema
					}
				})


				//set user to state
				dispatcher(loginActionCreator({
					id: String(id),
					email: email,
					password: password,
					authtoken: accesstoken,
					refreshtoken: refreshtoken,
					isAuthenticated: true,
					level: level,
					defaultschema: defaultschema
				}));

				//set schema to default (for user)
				dispatcher(schemadeleteActionCreator([]))
				let found = false

				schemas.forEach((sch: Schema) => {
					if (sch.SchemaKey === defaultschema) {
						dispatcher(schemaActionCreator(sch))
						found = true
					}
				})

				//history('/home')
				setTimeout(OnComplete, 150)
			} catch (err) {
				console.log(err)
				setLoginErr("Login Error")

			}
		})

	}

	return (
		<div >
			<div className="cascadecontainer" >
				<div className="frame"></div>
				<div className="frame"></div>
				<div className="frame"></div>
			</div>
			<div className="loginContainer" style={{ minHeight: "1100px" }}>

				<div id="loginbox" >
					<div id="logintitle"> Log In </div>
					<div id="formbox" >

						<form className='auth-form' onSubmit={e => onSubmit(e)}>
							<div className='auth__form__group'>
								<input
									className='log-input'
									type='email'
									placeholder='Email'
									name='email'
									value={email}
									onChange={e => onChange(e)}
									required
								/>
							</div>
							<div className='auth-form'>
								<input
									className='log-input'
									type='password'
									placeholder='Password'
									name='password'
									value={password}
									onChange={e => onChange(e)}
									minLength={6}
								/>
							</div>
							<div id='signuplinkbox'>
								Forgot your password?
								<Link aria-current="page" to="/signup" style={{ 'marginLeft': '3px' }}>Sign Up</Link>

							</div>
							<button className='login-button' type="submit">Login</button>
							<div id='login-guest' onClick={_ => { GuestLogin() }}> - Log in as guest - </div>
							<div id="login_statuserr"> {loginerr}</div>
						</form>

					</div>

				</div>

			</div>
		</div>
	)

};

export default Login;