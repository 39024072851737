
import AxiosUpload from '../AxiosUpload';
import { User, GroupDateRule } from '../../type';
import StringReformatforBackend from '../../functions/String Functions/StringReformatforBackend';
import ConvertDateToArray from '../../functions/Date Functions/ConvertDateToArray';


const GroupFieldDateUpload = async (modelkey: string, groupdata: GroupDateRule[], schemakey: string, user: User,
    config: any, elementstring: string) => {

    let dataArray = new FormData();

    let grpdataencoded = groupdata.map((d: GroupDateRule) => {
        return ([
            ConvertDateToArray(d.GreaterThanThresh),
            ConvertDateToArray(d.EqualTo),
            ConvertDateToArray(d.LessThanThresh),
            StringReformatforBackend(d.BandingName)
        ])
    });

    console.log(groupdata)
    console.log(grpdataencoded)


    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("modelkey", modelkey); //field key
    // dataArray.append("onfieldkey", onfieldkey);
    dataArray.append("groupdata", String(grpdataencoded));
    dataArray.append("schemakey", schemakey);
    dataArray.append("uploadvalues", String(true));


    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/groupsets/groupsetpost", config, elem, "")

    return ("Ok")
};

export default GroupFieldDateUpload;