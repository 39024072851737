
const ConvertChartTypetoShow = (charttype: string) => {
    let show = ""
    if (charttype === "Table" || charttype === "TableHorizontal") {
        show = "table"
    } else if (charttype === "Bar" || charttype === "BarHorizontal" || charttype === "Line" || charttype === "Combo") {
        show = "chart"
    } else if (charttype === "Bar3D") {
        show = "chart3D"
    } else if (charttype === "Bubble") {
        show = "bubble"
    }
    return (show)
};
export default ConvertChartTypetoShow