import GetRandomHexadecimal from '../../../functions/GetRandomHexadecimal';

const NetworkGraphSetup = (netmodel, rundatekey, categoryattribute, categorytype) => {
    //ref: jobdescnetgraph, networkpage
    let categories  = [];
    let nodes = [];

    netmodel.rundates.forEach((rd)=>{
        if (rd.DateKey===rundatekey){
            nodes = rd.nodes
        }
    })

    nodes.forEach((nd) => {
        if (categoryattribute !== "") {
            if (categories.indexOf(nd[categoryattribute]) == -1) {
                categories.push(nd[categoryattribute])
            }
        }
    })
    if (categorytype==="Numeric"){
        let newcats = categories.map((c)=>{return +c})
        categories = [Math.min.apply(null, newcats),Math.max.apply(null, newcats)]
    }
    console.log(categories)
    let colors = Array.apply(null, Array(categories.length)).map(function (_) { return GetRandomHexadecimal(6); })

    return [categories, colors]
};
export default NetworkGraphSetup;
