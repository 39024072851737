import { ChangeEvent, useContext, useState, useEffect } from 'react'
import { StartupContext } from "../../../App";
import GenericDropdown from '../../../functions/Dropdown_Generic';
import { Link, NetModel, Node, TableModel, FieldMap, SNAAttrField, NetworkRunDate } from '../../../type';
import { Modal } from 'react-bootstrap';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import DatePicker from 'react-date-picker';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import "./css/NetworkDataManage.css"
import GetObjectField from '../../../functions/Object Functions/GetObjectField';
import ModelCategoryDropdown from '../../../functions/Dropdowns/ModelCategoryDropdown';
import FieldDropdown from '../../data/fields/subfunctions/FieldDropdown';
import GetObjectValue from '../../../functions/Object Functions/GetObjectValue';
import IsInVector from '../../../functions/IsInVector';
import GetFieldName from '../../../functions/GetFieldName';
import FieldsImport from '../../../data/Fields/Fields_Import';
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import { NetModelInit, NetworkRunDateInit } from '../../../InitTypes';
import DateString from '../../../functions/Date Functions/DateString';
import ChangeParamSelectorbyIndex from '../../../functions/ElementSelect/ChangeParamSelectorbyIndex';



const NetworkDataManage = () => {

    interface paramtype {
        modelname: string, isnewmodel: boolean,
        showdeletemodal: boolean, showuploadmodal: boolean; showcreaterundate: boolean;
        modelcategory: string, addfield: string, refresh: number; selmodelkey: string;
        selattr: string; selasofdate: Date;
        orgmodelvisible: boolean; orgmodelcat: string; orggrpfldkey: string; orggrpfldname: string;
        orgfldwarning: string; showrenamemodal: boolean;
    }

    let { config, dispatcher, schema, paths, user, clickstyle } = useContext(StartupContext)

    let [networks, setNetworks] = useState<NetModel[]>([])
    let [updlinks, setUpdLinks] = useState<Link[]>([])
    const [tables, setTables] = useState<TableModel[]>([])
    const [fields, setFields] = useState<FieldMap[]>([])

    let [selModel, setSelModel] = useState<NetModel | null>(null)
    let [attrfields, setAttrFields] = useState<any[]>([])
    let [attrcats, setAttrCats] = useState<any[]>([])

    let [grpfields, setGrpFields] = useState<any[]>([])
    let [grpcats, setGrpCats] = useState<any[]>([])

    let [selRunDate, setSelRunDate] = useState<NetworkRunDate | null>(null)

    let [params, setParams] = useState<paramtype>({
        modelname: "", selasofdate: new Date(), isnewmodel: true, selmodelkey: '',
        showdeletemodal: false, showuploadmodal: false, showcreaterundate: false, orgmodelvisible: false,
        modelcategory: '', addfield: '', orggrpfldkey: '', orggrpfldname: '', refresh: 0,
        selattr: '', orgmodelcat: '', orgfldwarning: '', showrenamemodal: false
    })


    useEffect(() => {
        if (params.refresh >= 100) { window.location.reload() }
    }, [params.refresh])

    useEffect(() => {
        ImportAndSetStateGeneric(setNetworks, "", schema, paths.sna.netmodelview, user, config, { type: "name" }).then(d => {
            console.log(d)
        })
        ImportAndSetStateGeneric(setTables, "*", schema, paths.tables.tablemodelview, user, config, {})
        FieldsImport("", schema, config, dispatcher, false).then(d => { setFields(d); })
    }, [])

    useEffect(() => {
        if (params.selmodelkey !== "" && params.selmodelkey !== "_") {
            GenericBackendCall(params.selmodelkey, schema, paths.sna.netmodelview, user, config, { type: "full" }).then(d => {
                if (d.length === 1) {
                    let nmodel: NetModel = d[0]
                    setSelModel(nmodel)
                    console.log(nmodel)
                    if (nmodel.rundates.length > 0) {
                        if (nmodel.attrfields) {
                            let afields = nmodel.attrfields.map((af: SNAAttrField) => {
                                return af.FieldName
                            })
                            setAttrFields(afields)
                        }
                        if (nmodel.grpfields) {
                            let gfields = nmodel.grpfields.map((af: SNAAttrField) => {
                                return af.FieldName
                            })
                            setGrpFields(gfields)
                        }

                    }
                }
            })
        }
        if (params.selmodelkey === "_") {
            setSelModel(NetModelInit[0])
        }
    }, [params.selmodelkey])

    useEffect(() => {
        if (selRunDate && selModel) {
            let rundate: NetworkRunDate = GetObjectField(selRunDate.DateKey, selModel.rundates, "DateKey", "")
            if (rundate.links) {
                let linknames: Link[] = rundate.links.map((lk: Link) => {
                    return ({ ...lk, Source: GetObjectField(lk.Source, rundate.nodes, "Key", "Name"), Target: GetObjectField(lk.Target, rundate.nodes, "Key", "Name") })
                })
                setUpdLinks(linknames)
            }
        }
    }, [selRunDate])

    useEffect(() => {
        if (selModel && selModel.ModelKey === "") {
            setAttrCats([])
            setAttrFields([])
        }
    }, [selModel?.ModelKey])


    useEffect(() => {
        if (params.orggrpfldkey !== "") {
            let name = GetFieldName(fields, params.orggrpfldkey)
            if (name === "JobCode" || name === "JobTitle") {
                setParams({ ...params, orggrpfldkey: "" })
            } else {
                setParams({ ...params, orggrpfldname: name })
            }

        }
    }, [params.orggrpfldkey])

    //----backend----
    const ClickSave = () => {
        GenericBackendCall("", schema, paths.sna.netmodelupload, user, config,
            { modeltype: "", asofdate: params.selasofdate, modelname: params.modelname, attrfields: attrfields, grpfields: grpfields },
            "ndm_divgen").then(_ => { setParams({ ...params, refresh: 99 }) })
    }

    const ClickDelete = () => {
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.generics.genericdelete, user, config,
                { model: "NetModel", field: "ModelKey" }, "ndm_deldiv").then(_ => { setParams({ ...params, refresh: 99 }) })
        }
    }

    const GenerateNetworkfromDemographics = () => {
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.sna.netmodelcreaterun, user, config,
                { asofdate: params.selasofdate }, "ndm_divgen", 'Complete').then(d => {
                    console.log(d)
                }).then(_ => { setParams({ ...params, refresh: 99 }) })
        }
    }

    const ClickDeleteRun = () => {
        if (selRunDate) {
            GenericBackendCall(selRunDate.DateKey, schema, paths.generics.genericdelete, user, config, { model: "NetworkRunDate", field: "DateKey" }, "ndm_divdelrun")
        }
    }


    //-------------------

    const AddDetailMCat = (e: ChangeEvent<HTMLSelectElement>, n: number, state: any[], setstate: any) => {
        let val = e.currentTarget.selectedOptions[0].attributes[0].value
        let newcats = state
        if (n >= state.length) {
            newcats.push(val)
        } else {
            newcats[n] = val
        }
        setstate(newcats)
        setParams({ ...params, refresh: params.refresh + 1 })
        //setTempParams({ ...tempparams, [tmpparam]: newcats })
    }

    const AddDetailField = (e: ChangeEvent<HTMLSelectElement>, n: number, state: any[], setstate: any) => {
        let val = e.currentTarget.selectedOptions[0].attributes[0].value
        console.log(val, state)
        if (!IsInVector(state, val)) {
            let tmp = state
            if (n <= tmp.length) {
                tmp[n] = val
            } else {
                tmp.push(val)
            }
            console.log(tmp)
            setstate(tmp)
            setParams({ ...params, refresh: params.refresh + 1 })
        }
    }

    const AttrsDropdown = () => {
        return (
            <div id="ndm_attrbox">{[0, 1, 2, 3].map((n: number) => {
                if (attrfields.length >= n) {
                    return (
                        <div key={n} className="gc_df_itm">
                            <div style={{ marginRight: "10px" }}>Attr Field {n + 1}:</div>
                            <ModelCategoryDropdown className="gc_gen_ddown" tables={tables}
                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                    AddDetailMCat(e, n, attrcats, setAttrCats)
                                }} />
                            <FieldDropdown
                                className="gc_gen_ddown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => AddDetailField(e, n, attrfields, setAttrFields)}
                                includecategory={true}
                                filterfield={"ModelCategory"}
                                filtervalue={attrcats[n]}
                            />
                            <div style={{ marginLeft: "10px" }}>{GetFieldName(fields, attrfields[n])}</div>
                        </div>)
                }
            })}
            </div>
        )
    }

    const GrpsDropdown = () => {
        return (
            <div id="ndm_attrbox">{[0, 1, 2, 3].map((n: number) => {
                if (grpfields.length >= n) {
                    return (
                        <div key={n} className="gc_df_itm">
                            <div style={{ marginRight: "10px" }}>Group Field {n + 1}:</div>
                            <ModelCategoryDropdown className="gc_gen_ddown" tables={tables}
                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                    AddDetailMCat(e, n, grpcats, setGrpCats)
                                }} />
                            <FieldDropdown
                                className="gc_gen_ddown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => AddDetailField(e, n, grpfields, setGrpFields)}
                                includecategory={true}
                                filterfield={"ModelCategory"}
                                filtervalue={grpcats[n]}
                            />
                            <div style={{ marginLeft: "10px" }}>{GetFieldName(fields, grpfields[n])}</div>
                        </div>)
                }
            })}
            </div>
        )
    }

    const ClickRemoveAttr = () => {
        setAttrCats(attrcats.slice(0, -1))
        setAttrFields(attrfields.slice(0, -1))
        setParams({ ...params, refresh: params.refresh + 1 })
    }

    const ClickRename = () => {
        if (selModel) {
            GenericBackendCall(selModel?.ModelKey, schema, paths.generics.genericrename, user, config,
                { model: "NetModel", field: "ModelKey", namefield: "ModelName", value: params.modelname }, "ndm_divrename").then(_ => { setParams({ ...params, refresh: 99 }) })
        }
    };

    return (<div className='ndt_canvas'>
        <div className='ndt_title2'>Network Data Manager</div>

        <div className='ndt_gridbox' style={{ 'marginTop': '20px', 'textAlign': 'left', overflow: 'auto', height: '800px' }}>
            <div>
                <div style={{ marginTop: '20px', display: "flex" }}>

                    <div style={{ fontSize: "20px" }}>Model: </div>
                    {selModel && selModel.ModelKey !== "" ?
                        <div style={{ display: "flex" }}>
                            <div style={{ fontSize: "20px" }}>{selModel?.ModelName}</div>
                            <div style={{ marginLeft: "100px" }}>
                                <button className="ndt_btn2" onClick={_ => setParams({ ...params, showdeletemodal: true })}>Delete model</button>
                                <button className="ndt_btn1" onClick={_ => setParams({ ...params, showrenamemodal: true })}>Rename model</button>

                            </div>
                        </div>
                        : <div></div>}
                </div>
                <GenericDropdown
                    data={networks}
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, networks, params, setParams, "selmodelkey", "ModelKey", "_")}
                    keycol="ModelKey"
                    namecol="ModelName"
                    divID="ndm_modeldiv"
                    promptstring="Select Net Model"
                    includeDefault={true}
                    defaultstring="Create New Model"
                />
            </div>
            <div className='ndt_gridinner' style={{ marginTop: '20px', overflow: "visible" }}>
                {selModel && selModel.ModelKey === "" ?
                    <div>
                        {/* new model */}
                        <div style={{ margin: '5px' }}>
                            <div style={{ marginRight: "10px" }}>New Model Name</div>
                            <input type="text" value={params.modelname} onChange={(e: ChangeEvent<HTMLInputElement>) => { setParams({ ...params, modelname: e.target.value }) }} />
                        </div>


                        <div style={{ marginTop: "15px", height: "200px" }}>
                            <div className='ndt_title4'>Add Grouping</div>
                            <GrpsDropdown />
                            <div style={{ display: "flex" }}>
                                <button className='ndt_btn1' onClick={_ => { ClickRemoveAttr() }}>Remove Last</button>
                            </div>
                        </div>


                        <div style={{ marginTop: "15px", height: "200px" }}>
                            <div className='ndt_title4'>Add Attributes</div>
                            <AttrsDropdown />
                            <div style={{ display: "flex" }}>
                                <button className='ndt_btn1' onClick={_ => { ClickRemoveAttr() }}>Remove Last</button>
                            </div>
                        </div>

                        <button id="ndm_savebtn" className='ndt_btn1' style={{ width: "150px" }} onClick={_ => { setParams({ ...params, showuploadmodal: true }) }}>Save Model</button>
                        <div id="ndm_divupl"></div>

                    </div> :
                    <div>

                        <div className='ndt_subinner' style={{ overflow: "visible" }}>
                            <div style={{ fontSize: "22px" }}>Selected Model</div>
                            <div>
                                <div style={{ fontSize: "18px" }}>Attribute Fields</div>
                                <div>
                                    {selModel?.attrfields.map((af: SNAAttrField, n: number) => {
                                        let iattr = "Attribute" + String(af.AttrNum)
                                        let usestyle = clickstyle.itmUnselected
                                        if (iattr === params.selattr) { usestyle = clickstyle.itmSelected }
                                        return (<div key={n} className='ndm_attrflditm' style={usestyle} onClick={_ => { setParams({ ...params, selattr: iattr }) }}>{GetFieldName(fields, af.FieldName)}</div>)
                                    })}
                                </div>

                                <div>
                                    <div style={{ fontSize: "20px" }}>Values</div>

                                    {selModel ?
                                        <div>
                                            <GenericDropdown
                                                data={selModel.rundates}
                                                keycol="DateKey"
                                                namecol="AsofDate"
                                                promptstring="Select As of Date"
                                                change={(e: ChangeEvent<HTMLSelectElement>) => { if (selModel) { ChangeSelectorbyIndex(e, selModel.rundates, setSelRunDate, "", NetworkRunDateInit[0]) } }}
                                                includeDefault={true}
                                                defaultstring="New RunDate"
                                                className="ndt_dropdown"
                                            />
                                            {selRunDate && selRunDate.DateKey === "" ?
                                                <div style={{ marginTop: "10px" }}>
                                                    New Run Date
                                                    <div style={{ marginBottom: "10px" }}>
                                                        <div style={{ marginLeft: "5px", width: '165px' }} className='datepick_class'>
                                                            <DatePicker value={params.selasofdate} onChange={(d: any) => setParams({ ...params, selasofdate: d })} />
                                                        </div>
                                                    </div>
                                                    <button className="ndt_btn1" onClick={_ => setParams({ ...params, showcreaterundate: true })}>Click to Generate</button>
                                                </div>
                                                :
                                                <div >
                                                    {/* Single Run Date */}
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ width: "50%", paddingLeft: "25px" }}>Nodes</div>
                                                        <div style={{ width: "50%", paddingLeft: "25px" }}>Links</div>
                                                    </div>
                                                    <div id="ndm_mdl_main">
                                                        <div className='ndm_mdlbox ndt_innerbox'>
                                                            {selRunDate?.nodes.map((nd: Node, i: number) => {
                                                                return (<div key={i}>
                                                                    {nd.Key} {nd.Name} {GetObjectValue(nd, params.selattr)}
                                                                </div>)
                                                            })}
                                                        </div>
                                                        <div className='ndm_mdlbox ndt_innerbox'>
                                                            {updlinks.map((lk: Link, i: number) => {
                                                                return (<div key={i}>
                                                                    {lk.Source} - {lk.Target}
                                                                </div>)
                                                            })}
                                                        </div>
                                                    </div>
                                                    {selRunDate ?
                                                        <div>
                                                            <button className='ndt_btn2' onClick={_ => { ClickDeleteRun() }}>Delete</button>
                                                            <div id="ndm_divdelrun"></div>
                                                        </div>
                                                        : <div></div>}
                                                </div>
                                            }
                                        </div>
                                        : <div>No model selected</div>}
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>
        </div>

        <Modal show={params.showuploadmodal}>
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Save Model {params.modelname}</div>
                <button className="ndt_btn1" onClick={_ => ClickSave()}>Save</button>
                <div id="ndm_upldiv"></div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, showuploadmodal: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={params.showdeletemodal} >
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Delete this Model?</div>
                <button className="ndt_btn1" onClick={_ => ClickDelete()}>Yes, delete</button>
                <div id="ndm_deldiv"></div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, showdeletemodal: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={params.showcreaterundate} >
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Create Model as of {DateString(params.selasofdate)}?</div>
                <button className="ndt_btn1" onClick={_ => GenerateNetworkfromDemographics()}>Create</button>
                <div id="ndm_divgen"></div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, showcreaterundate: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={params.showrenamemodal} >
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Rename Model?</div>
                <button className="ndt_btn1" onClick={_ => ClickRename()}>Rename</button>
                <input type="text" value={params.modelname} onChange={(e: ChangeEvent<HTMLInputElement>) => { setParams({ ...params, modelname: e.target.value }) }} />

                <div id="ndm_divrename"></div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, showrenamemodal: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>


        {/* <Modal show={params.orgmodelvisible} >
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Create Org Model as of {DateString(params.selasofdate)}?</div>
                <ModelCategoryDropdown className="ndt_dropdown" tables={tables}
                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                        setParams({ ...params, orgmodelcat: e.target.selectedOptions[0].attributes[0].value })
                    }} />
                <GenericDropdown
                    data={fields}
                    keycol="Key"
                    namecol="FieldName"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, fields, params, setParams, "orggrpfldkey", "Key", "") }}
                    promptstring="Select Grouping Field"
                    filterfield="ModelCategory"
                    filtervalue={params.orgmodelcat}
                />

                <div>{params.orggrpfldname}</div>
                <button className="ndt_btn1" onClick={_ => ClickSaveOrgMdl()}>Create</button>
                <div id="ndm_divorg"></div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, orgmodelvisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal> */}

    </div >)
};
export default NetworkDataManage