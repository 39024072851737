import DateRemoveTimestamp from "./DateRemoveTimestamp";
import DateMatch from "./DateMatch";

//seemingly useless to not include unmatched (just use rundates), but the schedule could have changed
function MatchSchedtoRunDates(scheddates: Date[][], rundates: any[], includeUnmatched: boolean) {
    //ref:dashboardsmanage,

    let matcheddates: any[] = []
    scheddates.forEach((sd: Date[]) => {
        let found = false;
        rundates.forEach((dd: any) => {
            if (!found && DateMatch(dd.AsofDate, sd[1])) {
                found = true;
                matcheddates.push({
                    AsofDate: DateRemoveTimestamp(sd[1]),
                    Found: true
                })
            }
        })
        if (!found && includeUnmatched) {
            matcheddates.push({
                AsofDate: DateRemoveTimestamp(sd[1]),
                Found: false
            })
        }
    })
    return matcheddates
};
export default MatchSchedtoRunDates;