import { QueryRunDate, QueryVisuals } from "../../type";
import DateRemoveTimestamp from "../Date Functions/DateRemoveTimestamp";
import DateString from "../Date Functions/DateString";


const GetQueryRunDateKey = (visualset: QueryVisuals, rundates: QueryRunDate[], asofdate: Date | null = null) => {
    //ref: dashboardview, querymodelviewvisual, EmployeeAtAGlance
    //Primary, MostRecent, First...etc
    let foundkey = "";
    if (asofdate) { //asofdate overrides
        rundates.forEach((qrd: QueryRunDate) => {
            if (DateString(DateRemoveTimestamp(qrd.AsofDate)) === DateString(DateRemoveTimestamp(asofdate))) {
                foundkey = qrd.DateKey
            }
        })
    } else if (visualset.RunDateKey === "Primary") {

        rundates.forEach((qrd: QueryRunDate) => {
            if (qrd.IsPrimary) {
                foundkey = qrd.DateKey
            }
        })
    } else {
        foundkey = visualset.RunDateKey
        //check if still exists? (should be handled on backend delete)
    }
    return foundkey
};
export default GetQueryRunDateKey;