function fragmentShaderPlus(){
	return `
	precision highp float;
    uniform vec2 res;
    uniform float aspect;
	uniform float testval;
	
    float mandelbrot(vec2 c){
      float alpha = 1.0;
      vec2 z = vec2(0.0 , 0.0);
      for(int i=0; i < 100; i++){  // i < max iterations
        float x_sq = z.x*z.x;
        float y_sq = z.y*z.y;
        vec2 z_sq = vec2(x_sq - y_sq +.1, testval*z.x*z.y +.5);

		z = z_sq+ c;
		
        if(x_sq + y_sq > 4.0){
          alpha = float(i)/200.0;
          break;
        }
      }
      return alpha;
    }
  void main(){ // gl_FragCoord in [0,1]
    vec2 uv = 4.0 * vec2(aspect, 1.0) * gl_FragCoord.xy / res - 2.0*vec2(aspect, 1.0);
    float s = 1.0 - mandelbrot(uv);
    vec3 coord = vec3(pow(s,.5), pow(s,.5), s);
    gl_FragColor = vec4(pow(coord,vec3(.07, .2, .2)*(s+.1)*102.5)*.5+.5, 1);
    }
	`
  }

export default fragmentShaderPlus
