import { QueryModel, queryparams } from "../../type";
import IsDateField from "./IsDateField";


//const demographicHardcoded = "Demographic"


export const DateSpread_DeterminefromQuery = (query: QueryModel) => {

    let dateSpread = "range";
    if (query.Period !== "") {
        dateSpread = "spread"
    } else {
        if (query.NPeriods === 0) {
            dateSpread = "point"
        }
    };
    return (dateSpread);
};

export const DateSpread_DeterminefromParams = (params: queryparams, overwrite: boolean) => {

    let dateSpread: string = ""
    if (overwrite) {
        if (IsDateField(params.xfieldname)) {
            dateSpread = "spread"
        } else {
            if (params.nperiods === 0) {
                dateSpread = "point"
            } else {
                dateSpread = "range"
            }
        }
    } else {
        return params.dateSpread
    }

    return (dateSpread);
};


export default ""