

//import { Link } from 'react-router-dom';
//import axios from 'axios';


import { useState } from "react";
import { useSelector } from "react-redux";
import "./css/home.css"
import { useNavigate } from "react-router-dom";


const Home = () => {

	let [clickstate, setClickState] = useState<boolean>(false)
	let schema = useSelector((state: any) => state.selectedSchema) //

	let history = useNavigate()

	return (
		<div id="app_home">
			<div >

				{!clickstate ?
					<div className="wrapper">
						<div id="box_home" onClick={_ => { setClickState(!clickstate) }}>
							<div className="box-buffer" >
								<h1 className='display-4' style={{ letterSpacing: ".1em" }}>Nodiant</h1>
								<p className='lead'>Workforce Business Analytics App</p>
								<hr className='my-4' />
								<div style={{ padding: '5px' }}>
									<div>Version 6.0.0 enhancements:</div>
									<li style={{ marginLeft: "20px" }}>AI Scenario Build (prototype)</li>
									<li style={{ marginLeft: "20px" }}>Schema Checklist</li>
								</div>
								<div style={{ marginTop: "10px", fontSize: "14px", background: "rgb(60,60,60)", marginLeft: "10px", paddingLeft: "5px" }}>New? Click to begin</div>
							</div>
						</div>
						<div className="reflection" id="reflection_home">
						</div>
					</div>
					: <div className="wrapper">
						<div id="box_otherside" onClick={_ => { setClickState(!clickstate) }}>
							{schema.SchemaKey !== "" ?
								<div style={{ margin: '100px', }}>
									<div style={{ fontSize: "22px" }}>Selected Schema: {schema.SchemaName}</div>
									< div style={{ display: "flex", marginTop: "15px" }}>

										<div style={{ fontSize: "20px" }}>Check this Schema's status:</div>
										<button style={{ borderRadius: "10px", width: "100px", marginLeft: '10px', backgroundColor: "rgb(60,60,60)", color: "white" }}
											onClick={_ => { history("/status") }}>Check</button>
									</div>
								</div>
								: <div style={{ margin: '100px', display: "flex" }}>
									<div style={{ fontSize: "22px" }}>Select a Schema first</div>
									<button style={{ borderRadius: "10px", width: "100px", marginLeft: '10px', backgroundColor: "rgb(40,40,40)", color: "white" }}
										onClick={_ => { history("/schema") }}>Go!</button>

								</div>}
						</div>
					</div>}



			</div>
		</div >
	)

};

export default Home;