
import { useContext, ChangeEvent, useEffect, useState, FormEvent } from 'react'
import { StartupContext } from '../../../App';
import FieldsImport from '../../../data/Fields/Fields_Import';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import { RenameSetInit, RenameValueInit, RuleInit, RuleModelInit } from '../../../InitTypes';
import { Exception, FieldMap, RenameSet, RenameValue, RuleModel, Ruleset, TableModel } from '../../../type';
import FieldDropdown from '../fields/subfunctions/FieldDropdown';
import GenericsUniqueImport from '../../../data/Generics/GenericsUnique_Import';
import { Modal } from 'react-bootstrap'
import IsInObjectWhere from '../../../functions/Object Functions/IsInObjectWhere';
import RemovefromArrayWhere from '../../../functions/Array Functions/RemovefromArrayWhere';
import IsInObject from '../../../functions/Object Functions/IsInObject';
import RulesetsUpload from '../../../data/Rulesets/Rulesets_Upload';
import { useParams } from "react-router-dom";
import ModelCategoryDropdown from '../../../functions/Dropdowns/ModelCategoryDropdown';
import SerializeDataFrame from '../../../functions/Import/SerializeDataFrame';
import "./css/RulesetsManagerNew.css"
import GetFieldName from '../../../functions/GetFieldName';


const RulesetsManager = () => {

    let { id } = useParams()

    let { config, dispatcher, paths, user, schema, appcolors } = useContext(StartupContext)

    let multiInit = { 0: [], 1: [] }
    let [fields, setFields] = useState<FieldMap[]>([])
    let [rulemodels, setRulemodels] = useState<RuleModel[]>([])
    let [selModel, setSelModel] = useState<RuleModel | null>(null)
    let [selRule, setSelRule] = useState<Ruleset | null>(null) //not using selrule exceptions
    let [selExceptions, setSelExceptions] = useState<any>(multiInit)
    let [fieldvalues, setFieldValues] = useState<any>(multiInit) //also use for renames (0)
    const [tables, setTables] = useState<TableModel[]>([])
    let [exceptionstrings, setExceptionStrings] = useState<any>({ excstring1: "", excstring2: "" })

    interface rvalue {
        Value: string;
        Rename: string;
    }
    interface params_type {
        modelname: string; modelcategory: string, rerun: number, refresh: number, activefldno: number;
        activefldname: string, warning: string; rtype: string;
        modelwarning: string; fieldselected: boolean; issavevisible: boolean; isdeletevisible: boolean;
        isrenamevisible: boolean; isinsertvisible: boolean; isdeletemodelvisible: boolean;
        renamefrom: string; renameto: string; insertval: string;
    }
    let [params, setParams] = useState<params_type>({
        modelname: "", modelcategory: "", rerun: 0, refresh: 0, activefldno: 0,
        activefldname: "Field1", rtype: '',
        warning: "", modelwarning: "", fieldselected: false, isdeletemodelvisible: false,
        issavevisible: false, isdeletevisible: false, isrenamevisible: false, isinsertvisible: false,
        renamefrom: '', renameto: '', insertval: ''
    })

    let selStyle = { backgroundColor: "blue", color: "white" }
    let unselStyle = { backgroundColor: "black", color: "white" }
    let btnSelStyle = { backgroundColor: appcolors.coloraltprimary, color: "white", boxShadow: "0px 0px 5px lightblue, inset 0px 0px 3px white" }
    let btnUnSelStyle = { backgroundColor: "black", color: "white", boxShadow: "" }

    useEffect(() => {
        ImportAndSetStateGeneric(setTables, "*", schema, paths.tables.tablemodelview, user, config, {})
    }, [])

    useEffect(() => {
        GenericBackendCall("*", schema, paths.rulesets.rulemodelview, user, config).then(d => {
            setRulemodels(d)
        })
        FieldsImport("*", schema, config, dispatcher, false).then(d => setFields(d))
    }, [params.rerun])

    useEffect(() => { setExceptionStrings({ excstring1: ExceptionStringWrite(1), excstring2: ExceptionStringWrite(2) }) }, [selExceptions])
    useEffect(() => { if (selRule) { ImportFieldValues(selRule.Field1, 0) } }, [selRule?.Field1])
    useEffect(() => { if (selRule) { ImportFieldValues(selRule.Field2, 1) } }, [selRule?.Field2])

    useEffect(() => {
        rulemodels.forEach((rm: RuleModel) => {
            if (rm.ModelKey === id) {
                setSelModel(rm)
            }
        })
    }, [rulemodels])

    useEffect(() => {
        if (params.refresh >= 100) { window.location.reload() }
    }, [params.refresh])

    useEffect(() => {
        if (selModel) {
            if (selModel.ruleset.length > 0 && params.modelwarning !== "") {
                setParams({ ...params, fieldselected: false, modelwarning: '' })
            } else if (selModel.ruleset.length === 0) {
                setParams({ ...params, modelwarning: 'No Rules Cast' })
            }
        }
    }, [selModel?.ruleset])

    useEffect(() => { //check model name
        if (params.modelname.toLowerCase() === "upload" && selModel?.ModelKey == "") {
            setParams({ ...params, warning: "Cannot set to reserved name" })
        } else if (params.modelname.toLowerCase() !== "upload" && params.modelname !== "") {
            setParams({ ...params, warning: '' })
        } else if (params.modelname === "") {
            setParams({ ...params, warning: 'Name cannot be blank' })
        }
    }, [params.modelname, selModel?.ModelKey])

    useEffect(() => {
        if (selRule) {
            if (selRule.RuleName === "" && params.rtype === "Rule") {
                setParams({ ...params, warning: "No model name set!" })
            } else if (selRule.RuleName !== "" || params.rtype === "Rename") {
                setParams({ ...params, warning: "" })
            }
        }
    }, [selRule?.RuleName, params.rtype])

    //fix for all situations
    useEffect(() => {
        if (params.modelcategory !== "") {
            if (selRule && selRule.RuleKey === "") {
                setSelExceptions({ 0: [], 1: [] })
                setFieldValues({ 0: [], 1: [] })
                setSelRule({ ...selRule, RuleKey: "", RuleName: "", Field1: "", Field2: "" })
            }
        }
    }, [params.modelcategory])

    useEffect(() => {
        setSelRule(null)
    }, [selModel])


    //backend calls
    const ClickSave = () => {
        if (selModel) {
            selModel.ruleset.map((rule: Ruleset) => {
                if (selModel) {
                    RulesetsUpload(selModel.ModelKey, rule, schema, user, config, "rsm_divsave")
                }
            })
            selModel.renames.map((rnm: RenameSet) => {
                if (selModel) {
                    let vals = rnm.values.map((rnv: RenameValue) => {
                        return ([rnv.id, rnv.SetName, rnv.ValueFrom, rnv.ValueTo])
                    })
                    let extdata = { SetKey: rnm.SetKey, FieldName: rnm.FieldName, values: vals }
                    GenericBackendCall(selModel?.ModelKey, schema, paths.rulesets.renamepost, user, config, extdata).then(_ => setParams({ ...params, refresh: 99 }))
                }
            })
        }
    }

    const DeleteModel = () => {
        if (selModel && !selModel.OnUpload) {
            GenericBackendCall(selModel.ModelKey, schema, paths.generics.genericdelete, user, config,
                { model: "RuleModel", field: "ModelKey" }, "rsm_divdel").then(_ => setParams({ ...params, refresh: 99 }))
        } else {
            let elem = document.getElementById("rsm_divmdldel")
            if (elem) { elem.innerHTML = "Cannot delete this model" }
        }
    }

    const DeleteRule = () => {
        if (selRule) {
            if (params.rtype === "Rule") {
                GenericBackendCall(selRule.RuleKey, schema, paths.rulesets.ruledelete, user, config, {}, "rsm_divdel").then(_ => setParams({ ...params, refresh: 99 }))
            } else if (params.rtype === "Rename") {
                GenericBackendCall(selRule.RuleKey, schema, paths.generics.genericdelete, user, config,
                    { model: "RenameSet", field: "SetKey" }, "rsm_divdel").then(_ => setParams({ ...params, refresh: 99 }))
            }
        }
    }

    const ExceptionStringWrite = (i: number) => {
        let excitms = selExceptions[i - 1].map((exc: Exception) => {
            if (exc.FieldNo === i) { return exc.ExceptionVal }
        })
        return excitms.toString()
    }

    const ImportFieldValues = (fieldkey: string, i: number) => {
        if (selRule && fieldkey && fieldkey !== "") {
            console.log(fieldkey)
            GenericsUniqueImport([fieldkey], schema, fields, config, dispatcher).then(d => {
                let ser_data = SerializeDataFrame(d, ["Fld"])
                let vals = GetValsFromFields(ser_data, "Fld")
                let rvals: rvalue[] = []

                if (params.rtype === "Rename") {
                    selModel?.renames.forEach((rnm: RenameSet) => {
                        if (rnm.FieldName === fieldkey) {
                            rvals = rnm.values.map((rv: RenameValue) => { return { Value: rv.ValueFrom, Rename: rv.ValueTo } })
                        }
                    });
                }

                vals.forEach((v: string) => {
                    if (!IsInObject(rvals, v, "Value")) {
                        rvals.push({ Value: v, Rename: "" })
                    }
                })

                setFieldValues({ ...fieldvalues, [i]: rvals });
            })
        }
    };

    const ParseExceptions = (exc: Exception[]) => {
        let exc1: Exception[] = [];
        let exc2: Exception[] = [];
        exc.forEach((e: Exception) => {
            if (e.FieldNo === 1) {
                exc1.push(e)
            } else {
                exc2.push(e)
            }
        })
        return ([exc1, exc2])
    }

    const AddModel = () => {
        let rulemdl_promise = GenericBackendCall(params.modelname, schema, paths.rulesets.rulemodelpost, user, config,
            { onupload: false }, "rm_divnewmdl")
        rulemdl_promise.then(_ => setParams({ ...params, rerun: params.refresh + 99 }))

    };

    const CastRule = () => {
        if (selModel && selRule && params.warning === "") {

            if (params.rtype === "Rule") {
                let newrules = selModel.ruleset
                let found = false
                let allexceptions: Exception[] = selExceptions[0].concat(selExceptions[1])

                setSelRule({ ...selRule, exceptions: allexceptions })

                selModel.ruleset.map((rl: Ruleset, i: number) => {
                    if (rl.RuleKey === selRule?.RuleKey) {
                        newrules[i] = selRule
                        found = true
                    }
                })
                if (!found) { newrules.push({ ...selRule, exceptions: allexceptions }) }

                setSelModel({ ...selModel, ruleset: newrules })
            } else if (params.rtype === "Rename") {

                let rnames = fieldvalues['0'].map((fv: rvalue) => { return { ...RenameValueInit[0], ValueFrom: fv.Value, ValueTo: fv.Rename } })
                let newrename: RenameSet = { ...RenameSetInit[0], SetKey: selRule.RuleKey, FieldName: selRule.Field1, values: rnames }

                let found = false;
                selModel.renames.forEach((ren: RenameSet, i: number) => {
                    if (ren.FieldName === selRule?.Field1 && selModel) {
                        selModel.renames[i] = newrename
                        found = true;
                    }
                })
                if (!found) {
                    selModel.renames.push(newrename)
                }
                setSelModel({ ...selModel, renames: selModel.renames })
            }
        }
    };

    const ClickChangeRule = (rule: Ruleset) => {
        let [exc1, exc2] = ParseExceptions(rule.exceptions)
        setSelExceptions({ 0: exc1, 1: exc2 })
        setSelRule(rule)
        setParams({ ...params, rtype: "Rule", fieldselected: true, activefldno: 0 })
    };

    const ClickChangeRename = (rename: RenameSet) => {
        setSelExceptions({ 0: [], 1: [] })
        setSelRule({ ...RuleInit[0], RuleKey: rename.SetKey, Field1: rename.FieldName })
        setParams({ ...params, rtype: "Rename" })

    };


    const ClickValueRule = (val: string, i: number) => {
        let newexceptions: Exception[] = selExceptions[i]
        if (selRule) {
            if (IsInObjectWhere(selExceptions[i], val, "ExceptionVal", "FieldNo", i + 1)) {
                newexceptions = RemovefromArrayWhere(selExceptions[i], val, "ExceptionVal", "FieldNo", String(i + 1))
            } else {
                let newEx: Exception = { Rule: selRule.RuleKey, ExceptionVal: val, FieldNo: i + 1 }
                newexceptions.push(newEx)
            }
        }
        setSelExceptions({ ...selExceptions, [i]: newexceptions })
    }

    const ClickValueRename = () => {
        let vidx = IsInObject(fieldvalues['0'], params.renamefrom, "Value", true)
        if (typeof (vidx) === "number" && vidx >= 0) {
            fieldvalues['0'][vidx].Rename = params.renameto
        } else if (typeof (vidx) === "number") {
            fieldvalues['0'].push({ Value: params.renamefrom, Rename: params.renameto })
        }
        setFieldValues(fieldvalues)

        setParams({ ...params, isrenamevisible: false, activefldno: 0 })
    }

    const ClickInsertValue = () => {
        if (!IsInObject(fieldvalues[params.activefldno], params.insertval, "Value")) {
            fieldvalues[params.activefldno].push({ Value: params.insertval, Rename: "" })
            setFieldValues(fieldvalues)
        }
    }

    const ValuesDropdown = (props: any) => {
        let int: number = +(props.int);
        let fldvals: rvalue[] = props.fldvals;

        return (<div >
            <button className="ndt_btn1" onClick={_ => { setParams({ ...params, isinsertvisible: true }) }}>Insert Value</button>
            <div id="rmn_fieldbox">
                {fldvals.map((val: rvalue, i: number) => {
                    return (<div key={i}
                        className="rmn_valitem"
                        onClick={_ => { params.rtype === "Rule" ? ClickValueRule(val.Value, int) : setParams({ ...params, renamefrom: val.Value, renameto: val.Rename, isrenamevisible: true }) }}
                        style={IsInObject(selExceptions[int], val.Value, "ExceptionVal") ? selStyle : unselStyle}
                    > <div className="rmn_subitm">
                            {val.Value}
                        </div>
                        <div className="rmn_subitm">
                            {params.rtype === "Rename" ? val.Rename : ""}
                        </div>
                    </div>)
                })}
            </div>
        </div>)
    }

    return (<div className="ndt_canvas">
        <div className="ndt_title2">Rule Model Manager</div>
        <GenericDropdown data={rulemodels}
            keycol={"ModelKey"}
            namecol={"ModelName"}
            divID="rm_rulemdlddown"
            promptstring="Select Rule Model"
            change={(e: ChangeEvent<HTMLSelectElement>) =>
                ChangeSelectorbyIndex(e, rulemodels, setSelModel, "", RuleModelInit[0])}
            includeDefault={true}
            defaultstring="Create New Model"
            className="ndt_dropdown"
        />
        <button className="ndt_btn2" onClick={_ => { setParams({ ...params, isdeletemodelvisible: true }) }}>Delete</button>
        {selModel ?
            <div className="ndt_gridbox">
                {selModel.ModelKey === "" ?
                    <div>
                        <div className="ndt_title3">Create New Model</div>
                        <div
                            style={{ 'marginBottom': '20px', 'textAlign': 'left', display: "flex" }}>
                            <div style={{ margin: '3px' }}>Add Model (name):</div>
                            <input id="rm_newmdlnamediv" type="text" name="modelname"
                                onChange={e => setParams({ ...params, modelname: e.target.value })} />
                            {params.warning === "" ?
                                <button id="rm_addmdlbtn" style={{ marginLeft: "5px" }} className="ndt_btn1" onClick={_ => AddModel()} > Add Model</button>
                                : <div>{params.warning}</div>}
                            <div id="rm_divnewmdl" style={{ margin: '3px' }}></div>
                        </div>
                    </div>

                    : <div>
                        <div className="ndt_title3">Existing Model</div>
                        <div id="rsm_existblock">
                            <div className="ndt_innerbox">
                                <div style={{ height: "500px" }}>
                                    <div className='ndt_title3'>Rules:</div>
                                    {selModel.ruleset.map((rule: Ruleset, i: number) => {
                                        return (<div className="ndt_item" key={i} onClick={_ => ClickChangeRule(rule)}>Exceptions: {rule.RuleName}</div>)
                                    })}
                                    {selModel.renames.map((rnm: RenameSet, i: number) => {
                                        return (<div className="ndt_item" key={i} onClick={_ => ClickChangeRename(rnm)}>Renames: {GetFieldName(fields, rnm.FieldName, "Key", "FieldName")}</div>)
                                    })}
                                    <div className="ndt_item"
                                        id="rsm_newrulediv"
                                        style={{ backgroundColor: "#222222" }}
                                        onClick={_ => {
                                            if (selModel) {
                                                setSelRule({ ...RuleInit[0], RuleKey: String(selModel.ruleset.length + 1) })
                                                setFieldValues(multiInit)
                                                setSelExceptions(multiInit)

                                            }
                                        }}>Create New Rule</div>

                                </div>
                                <div >
                                    <div style={{ fontSize: "20px" }}>Selected Rule:</div>
                                    {selRule ?
                                        <div className="ndt_subinner">
                                            <div>Name: {selRule.RuleName}</div>
                                            <div>Field1: {GetFieldName(fields, selRule.Field1, "Key", "FieldName")}</div>
                                            <div>Field2: {GetFieldName(fields, selRule.Field2, "Key", "FieldName")}</div>
                                            <div>Exceptions (Field1):
                                                {exceptionstrings.excstring1}
                                            </div>
                                            <div>Exceptions (Field2):
                                                {exceptionstrings.excstring2}
                                            </div>
                                        </div> : <div style={{ height: "110px" }}></div>}

                                </div>
                                {selModel ? <button className="ndt_btn1"
                                    style={params.modelwarning === "" ? { opacity: "1" } : { opacity: ".2" }}
                                    onClick={_ => { setParams({ ...params, issavevisible: true }) }}>Save Rule Model</button>
                                    : <div></div>}
                                <div>{params.modelwarning}</div>
                            </div>

                            <div className="ndt_innerbox" style={{ overflowX: "scroll" }}>


                                {selRule ? <div>
                                    <div>
                                        {/*  Ruleset header */}
                                        <div className="ndt_subinner" style={{ height: "270px" }}>
                                            <div style={{ fontSize: "22px", height: "40px" }}>Edit Rule</div>

                                            <div id="rsm_ruleheader" className="ndt_subinner" style={{ padding: '3px' }}>
                                                <div style={{ display: "flex", height: "30px", marginBottom: "10px" }}>
                                                    <button className="ndt_btn1" style={params.rtype === "Rule" ? btnSelStyle : btnUnSelStyle} onClick={_ => { setParams({ ...params, rtype: "Rule" }) }}>Exception</button>
                                                    <button className="ndt_btn1" style={params.rtype === "Rename" ? btnSelStyle : btnUnSelStyle} onClick={_ => { setParams({ ...params, rtype: "Rename" }) }}>Rename</button>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "100px", marginRight: "5px" }}>On Table:</div>
                                                    <ModelCategoryDropdown className="ndt_dropdown" tables={tables} change={(e: ChangeEvent<HTMLSelectElement>) => {
                                                        setParams({ ...params, modelcategory: e.target.selectedOptions[0].attributes[0].value })
                                                    }} />
                                                </div>
                                                <div style={{ display: "flex", height: "30px", marginBottom: "4px" }}>
                                                    <div style={{ width: "100px", marginRight: "5px" }}>On Field:</div>
                                                    <FieldDropdown
                                                        includecategory={true}
                                                        className="ndt_dropdown"
                                                        filterfield={["ModelCategory"]}
                                                        filtervalue={[params.modelcategory]}
                                                        change={(e: ChangeEvent<HTMLSelectElement>) => { if (selRule) { setParams({ ...params, fieldselected: true }); setSelRule({ ...selRule, [params.activefldname]: e.target.selectedOptions[0].id }) } }} />
                                                </div>

                                            </div>

                                            {params.fieldselected ?
                                                <div>
                                                    {(() => {
                                                        switch (params.rtype) {
                                                            case "Rule": return <div className="ndt_subinner">
                                                                <div style={{ display: "flex", marginTop: '10px' }}>
                                                                    <div style={{ marginTop: "5px", marginLeft: "10px" }}>Set Field:</div>
                                                                    <button className="ndt_btn1" style={params.activefldname === "Field1" ? btnSelStyle : btnUnSelStyle} onClick={_ => setParams({ ...params, activefldno: 0, activefldname: "Field1" })}>Field 1</button>
                                                                    <button className="ndt_btn1" style={params.activefldname === "Field2" ? btnSelStyle : btnUnSelStyle} onClick={_ => setParams({ ...params, activefldno: 1, activefldname: "Field2" })}>Field 2</button>
                                                                    {selRule ?
                                                                        <div style={{ display: "flex", marginLeft: "20px" }}>
                                                                            <button style={selRule.Operation === "And" ? btnSelStyle : btnUnSelStyle} onClick={_ => { if (selRule) { setSelRule({ ...selRule, Operation: "And" }) } }}>And</button>
                                                                            <button style={selRule.Operation === "Or" ? btnSelStyle : btnUnSelStyle} onClick={_ => { if (selRule) { setSelRule({ ...selRule, Operation: "Or" }) } }}>Or</button>
                                                                        </div> : <div></div>}
                                                                </div>
                                                                <div style={{ display: "flex", height: "30px" }}>
                                                                    <div style={{ minWidth: '100px', marginLeft: "5px", marginRight: "5px" }}>Rule Name: </div>
                                                                    <input type="text" placeholder={selRule?.RuleName} value={selRule?.RuleName} onChange={(e: ChangeEvent<HTMLInputElement>) => { if (selRule) { setSelRule({ ...selRule, RuleName: e.target.value }) } }} />
                                                                    <div style={{ color: "red" }}>{params.warning}</div>
                                                                </div>
                                                            </div>
                                                            case "Rename": return <div>
                                                                <div className="ndt_subinner">
                                                                    Rename Field Values
                                                                </div>
                                                            </div>
                                                            default: return <div></div>;
                                                        }
                                                    })()}

                                                </div>
                                                : <div></div>}

                                        </div>
                                    </div>

                                    {params.rtype !== "" ?
                                        <ValuesDropdown int={params.activefldno} fldvals={fieldvalues[String(params.activefldno)]} />
                                        : <div></div>}

                                    <div style={{ display: "flex", marginTop: "5px" }}>
                                        <button id="rsm_castrulebtn" className="ndt_btn1" onClick={_ => CastRule()} style={params.warning === "" ? { opacity: "1" } : { opacity: ".2" }}>Cast Rule</button>
                                        <button style={{ marginLeft: "20px" }} className="ndt_btn2" onClick={_ => { setParams({ ...params, isdeletevisible: true }) }}>Delete Rule</button>
                                    </div>
                                </div> :
                                    <div></div>}
                            </div>
                        </div>
                    </div>}
            </div>


            : <div></div>}

        <Modal show={params.issavevisible}>
            <div className='ndt_modal'>
                <div style={{ height: "130px", padding: "10px" }}>
                    <div style={{ fontSize: "20px" }}>Save model {selModel?.ModelName}?</div>
                    <br />
                    <button className="ndt_btn1" style={{ width: "140px" }} onClick={_ => ClickSave()}>Save</button>
                    <div id="rsm_divsave"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, issavevisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={params.isdeletevisible}>
            <div className='ndt_modal'>
                <div style={{ height: "130px", padding: '10px' }}>
                    <div style={{ fontSize: "20px" }}>Delete Rule {selRule?.RuleName}</div>
                    <br />
                    <button className="ndt_btn1" onClick={_ => DeleteRule()}>Delete </button>
                    <div id="rsm_divdel"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdeletevisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={params.isdeletemodelvisible}>
            <div className='ndt_modal'>
                <div style={{ height: "130px", padding: '10px' }}>
                    <div style={{ fontSize: "20px" }}>Delete Model {selModel?.ModelName}</div>
                    <br />
                    <button className="ndt_btn1" onClick={_ => DeleteModel()}>Delete </button>
                    <div id="rsm_divmdldel"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdeletemodelvisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={params.isrenamevisible}>
            <div className='ndt_modal'>
                <div style={{ height: "130px", padding: "10px" }}>
                    <div>Replace value {params.renamefrom} with value:</div>
                    <input type="text" name="modelname" value={params.renameto} onChange={e => setParams({ ...params, renameto: e.target.value })} />
                    <button className="ndt_btn1" style={{ width: "140px" }} onClick={_ => ClickValueRename()}>Rename</button>
                </div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isrenamevisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={params.isinsertvisible}>
            <div className='ndt_modal'>
                <div style={{ height: "130px", padding: "10px" }}>
                    <div className="ndt_title4">Insert Value</div>
                    <input type="text" name="modelname" value={params.insertval} onChange={e => setParams({ ...params, insertval: e.target.value })} />
                    <button className="ndt_btn1" style={{ width: "140px" }} onClick={_ => ClickInsertValue()}>Insert</button>
                </div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isinsertvisible: false })}>Close</button>
            </div>
        </Modal>

    </div >)
};
export default RulesetsManager;


const GetValsFromFields = (data: any[], fldname: string) => {
    let uniquevals: any[] = []
    data.forEach((d: any) => {
        uniquevals.push(d[fldname])
    })
    return (uniquevals)
}