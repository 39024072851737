import axios from 'axios';
import { schemalistActionCreator, schemalistdeleteActionCreator } from '../../reducers/schema_actions';
import { Schema, User } from '../../type';

const SchemaImport = async (default1: string, default2: string, user: User, config: any, dispatcher: any, store: boolean): Promise<any> => {
    //ref: schemamanage

    let dset: Schema[] = []

    try {
        let schema_resp = await axios.post<any>(
            `${process.env.REACT_APP_API_URL}/api/schema/schemaimport`,
            { 'userid': user.id }, config,
        )
        dispatcher(schemalistdeleteActionCreator([]))

        dset = schema_resp.data //should only be one
        if (store) {
            dset.forEach((sch: Schema) => {
                dispatcher(schemalistActionCreator(sch))
            })
        }
    } catch (error) {
        console.log(error)
    }

    return (dset)

};
export default SchemaImport;