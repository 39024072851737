import DateString from "./Date Functions/DateString";

const GenericDropdown = (props: any) => {

    const data = props.data
    const change = props.change;
    const keycol = props.keycol
    const namecol = props.namecol;
    const divID = props.divID;
    const classname = props.className ? props.className : "genericdropdown_default"
    const promptstring = props.promptstring;
    const includeDefault = props.includeDefault ? props.includeDefault : false;
    const defaultstring: string = props.defaultstring
    const value = props.value ? props.value : "";
    const filterfield = props.filterfield ? props.filterfield : "";
    const filtervalue = props.filtervalue ? props.filtervalue : "";

    let namecols = [namecol] //account for multiple name fields
    if (typeof (namecol) === "object") {
        namecols = namecol
    }



    let dataplus: any[] = [];
    Object.assign(dataplus, data); //have to duplicate or it will add to data object in parent const

    if (includeDefault && dataplus.length > 0) {
        dataplus.push(data[0]) //just add another so it iterates one more time
    } else if (includeDefault) {
        dataplus.push({ [keycol]: "", [namecol]: "" })
    }

    let maxlen: number = dataplus.length - 1
    return (
        <select id={divID} value={value} onChange={change} className={classname}>
            <option key='-1' data-index={-2} value={""} data-name={""}>
                {promptstring}
            </option>
            {dataplus ?
                dataplus.map((d: any, i: number) => {
                    if (i === maxlen && includeDefault) {
                        return (
                            <option key={"opt" + i} data-index={-1} value={""} data-name={""}>
                                {defaultstring}
                            </option>
                        )
                    } else {
                        if (filterfield === "" || d[filterfield] === filtervalue) {
                            if (keycol !== "") {
                                let useval = ''
                                namecols.forEach((str1: string) => {
                                    if (typeof (d[str1]) === "object") {
                                        useval = useval + " - " + DateString(d[str1])
                                    } else {
                                        useval = useval + " - " + d[str1]
                                    }
                                })
                                useval = useval.substring(3)
                                return (
                                    <option key={"opt" + i} data-index={i} value={d[keycol]} data-name={useval} >
                                        {useval}
                                    </option>
                                )
                            } else {
                                return (
                                    <option key={"opt" + i} data-index={i} value={d} data-name={d}>
                                        {d}
                                    </option>
                                )
                            }
                        }
                    }
                })

                : <option></option>}
        </select>
    )

};
export default GenericDropdown;

