
import AxiosUpload from '../AxiosUpload';


const TableDataUpload = async (tblfile: any, schema: string, path: string, modelkey: string, modelcategory: string,
    asofDate: Date, asofDateFrom: Date, headerslist: any, rememberheaders: boolean, filldata: boolean, batchfield: string, nearbymodels: string[], config: any, elementstring: string) => {

    let elem: HTMLElement | null = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    let dates = { 'day': asofDate.getDate(), 'month': asofDate.getMonth() + 1, 'year': asofDate.getFullYear() }
    let datesfrom = { 'day': asofDateFrom.getDate(), 'month': asofDateFrom.getMonth() + 1, 'year': asofDateFrom.getFullYear() } //datefield upload option

    let promise: Promise<any> | null = null;

    let arryheaders = Object.keys(headerslist).map((hdkey: string) => {
        return [
            String(headerslist[hdkey][0]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(headerslist[hdkey][1]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(headerslist[hdkey][2]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(headerslist[hdkey][5]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
        ]
    });

    let dataArray = new FormData();
    dataArray.append("data", tblfile);

    dataArray.append("schemakey", schema);
    dataArray.append("modelkey", modelkey);
    dataArray.append("modelcategory", modelcategory);

    dataArray.append("year", String(dates.year));
    dataArray.append("month", String(dates.month));
    dataArray.append("day", String(dates.day));

    dataArray.append("year_from", String(datesfrom.year));
    dataArray.append("month_from", String(datesfrom.month));
    dataArray.append("day_from", String(datesfrom.day));

    dataArray.append("headerslist", String(arryheaders));
    dataArray.append("rememberheaders", String(rememberheaders));
    dataArray.append("filldata", String(filldata));
    dataArray.append("batchfield", String(batchfield));
    dataArray.append("nearbymodels", String(nearbymodels));



    promise = AxiosUpload(dataArray, path, config, elem, "");

    return (promise);

}
export default TableDataUpload;