const UpdateState = (state, statefn, charttype, updatefields, updatevalues, idx) =>{

        let oldelem = document.getElementById(state[idx]["selChartElemIndex"])

        if (oldelem){
            //if (charttype=="Bar"){
                //oldelem.style.stroke = "black"
            //} else {
                oldelem.style.stroke = oldelem.attributes['basecolor'].value
            //}
            
            oldelem.style.fill = oldelem.attributes['basecolor'].value
            oldelem.style.backgroundColor = oldelem.attributes['basecolor'].value
            //console.log("set to base", oldelem.attributes['basecolor'].value)
        }
        
        updatefields.forEach((fld,i)=>{
            state[idx][fld] = updatevalues[i];
        })
        statefn(state)
        
};
export default UpdateState