import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { StartupContext } from '../../../App';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import SerializeDataFrame from '../../../functions/Import/SerializeDataFrame';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import GetUniquesFromArrayMultiField from '../../../functions/Array Functions/GetUniquesfromArrayMultiField';
import { BasicQuery, BenchModel, Benchmark, Benchmarkset, FieldMap, FilterField, Key_Name, Metric, QueryModel, QueryRunDate, KPI, KPIValue } from '../../../type';
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import { BenchModelInit, BenchmarkInit } from '../../../InitTypes';
import { Modal } from 'react-bootstrap';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import DateString from '../../../functions/Date Functions/DateString';
import ExcelExport from '../../../functions/ExcelExport';
import "./css/BenchmarkManager.css"
import ConvertBackendArryString from '../../../functions/String Functions/ConvertBackendArryString';
import FilterData from '../../../functions/FilterData';
import ChangeParamSelectorbyIndex from '../../../functions/ElementSelect/ChangeParamSelectorbyIndex';
import GetObjectField from '../../../functions/Object Functions/GetObjectField';
import GetFieldName from '../../../functions/GetFieldName';
import GetObjectValue from '../../../functions/Object Functions/GetObjectValue';
import DatePicker from 'react-date-picker';
import ObjectSort from '../../../functions/ObjectSort';



const BenchmarkManager = () => {

    interface paramstype {
        step: number; dfile: any; asofdate: Date; loaded: boolean; selMetricKey: string; refresh: number;
        qmodelGroupField: string | null; activefld: string; matchcount: number; missingcount: number; issavevisible: boolean;
        ischeckcolsvisible: boolean; issavedatevisible: boolean; isdelsetvisible: boolean; selBenchsetkey: string;
        isdelmdlvisible: boolean; rundate: Date; selrundatekey: string; note_autopopulated: string; applytorange: boolean;
        asofdate_from: Date; asofdate_to: Date; type: string; kpichange: boolean; selidx_from: number; selidx_to: number;
        autofill: boolean;
    }
    let { config, schema, user, paths, clickstyle } = useContext(StartupContext)
    let [querymodels, setQueryModels] = useState<QueryModel[]>([])
    let [selqrymdl, setSelQryMdl] = useState<QueryModel | null>(null)
    let [benchmodels, setBenchModels] = useState<BenchModel[]>([])
    let [selBenchModel, setSelBenchModel] = useState<BenchModel | null>(null)
    let [basicquery, setBasicQuery] = useState<BasicQuery[]>([])
    let [benchmark, setBenchmark] = useState<Benchmark[]>([])

    let [uplFields, setUplFields] = useState<string[]>([])
    let [uplData, setUplData] = useState<any[]>([])

    let [kpis, setKPIs] = useState<KPI[]>([])
    let [selKPI, setSelKPI] = useState<KPI | null>(null)

    let [metrics, setMetrics] = useState<Metric[]>([])
    let [qrymetrics, setQryMetrics] = useState<Metric[]>([])
    let [fields, setFields] = useState<FieldMap[]>([])
    let [fltfld, setFltFld] = useState<any>({ x: true, g: false, 0: false, 1: false })
    let [overwriteranges, setOverwriteRanges] = useState<Key_Name[]>([])

    let [keyparams, setKeyParams] = useState<paramstype>({
        step: 0, dfile: '', asofdate: new Date(), loaded: false, selMetricKey: '',
        activefld: "", qmodelGroupField: "", matchcount: 0, missingcount: 0, issavevisible: false,
        issavedatevisible: false, ischeckcolsvisible: false, isdelsetvisible: false, selBenchsetkey: '',
        refresh: 0, isdelmdlvisible: false, rundate: new Date(), selrundatekey: '', note_autopopulated: '',
        applytorange: false, asofdate_from: new Date(), asofdate_to: new Date(), type: "", kpichange: false,
        selidx_from: -1, selidx_to: -1, autofill: false
    })
    let [varmap, setVarMap] = useState<any>({})
    let [kpibenchmark, setKPIBenchmark] = useState<{ AsofDate: any, Value: number, Benchmark: number }[]>([])

    let onStyle = { backgroundColor: "blue", color: "white" }
    let offStyle = { backgroundColor: "black", color: "white" }
    let missingStyle = { backgroundColor: "red", color: "white" }

    let [btnStyles, setBtnStyles] = useState<any>({
        'VarHeader': offStyle, "GrpHeader": offStyle, "BenchHeader": offStyle, 'FilterHeader1': offStyle, 'FilterHeader2': offStyle
    })

    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams])


    useEffect(() => {
        if (selBenchModel) {
            let newstyles: any = { VarHeader: offStyle, GrpHeader: offStyle, BenchHeader: offStyle }
            if (selBenchModel.VarHeader === "") { newstyles.VarHeader = missingStyle }
            if (selBenchModel.GrpHeader === "" && keyparams.qmodelGroupField !== null) { newstyles.GrpHeader = missingStyle }
            if (selBenchModel.BenchHeader === "") { newstyles.BenchHeader = missingStyle }
            if (selBenchModel.FilterHeader1 === "" && fltfld[0]) { newstyles.FilterHeader1 = missingStyle }
            if (selBenchModel.FilterHeader2 === "" && fltfld[1]) { newstyles.FilterHeader2 = missingStyle }
            newstyles[keyparams.activefld] = onStyle
            setBtnStyles(newstyles)
        }
    }, [keyparams.activefld, selBenchModel])

    useEffect(() => {
        if (selBenchModel) {
            setBasicQuery([])
            setBenchmark([])
            setFltFld({ x: true, g: false, 0: false, 1: false })
            setKeyParams({ ...keyparams, step: 1, matchcount: 0, missingcount: 0, activefld: '' })
        }
    }, [selBenchModel?.ModelKey])

    useEffect(() => {
        ImportAndSetStateGeneric(setFields, "", schema, paths.fields.fieldretrieve, user, config)
        ImportAndSetStateGeneric(setMetrics, "", schema, paths.query.metricview, user, config, {})
    }, [])

    useEffect(() => {
        if (keyparams.type === "Query") {
            ImportAndSetStateGeneric(setQueryModels, '', schema, paths.query.queryview, user, config, { type: "base", permissions: ['manage', 'creator'] })
        } else if (keyparams.type === "KPI") {
            ImportAndSetStateGeneric(setKPIs, "", schema, paths.kpi.kpiview, user, config, { full: true })
        }
    }, [keyparams.type])

    useEffect(() => {
        if (selqrymdl) {
            let newMetrs = ConvertBackendArryString(selqrymdl.MetricOrder)
            setSelQryMdl({ ...selqrymdl, Metrics: newMetrs })
            setQryMetrics(FilterData(metrics, "MetricKey", newMetrs))
        }
    }, [selqrymdl?.ModelKey])


    useEffect(() => {
        if (selqrymdl && keyparams.selMetricKey !== "") {
            GenericBackendCall("", schema, paths.benchmark.benchmodelview, user, config,
                { querykeys: [selqrymdl.ModelKey], metrickeys: [keyparams.selMetricKey] }).then(d => {
                    setBenchModels(d)
                    setSelBenchModel(null)

                })
        }
    }, [selqrymdl, keyparams.selMetricKey])

    useEffect(() => {
        console.log(basicquery)
        if (basicquery.length > 0) {
            GetUniques()
        }
    }, [basicquery])


    useEffect(() => {
        //on benchmodel select
        if (keyparams.selMetricKey !== "" && selqrymdl) {
            selqrymdl.Metrics.forEach((m: string, i: number) => {
                if (m === keyparams.selMetricKey) {
                    if (selBenchModel) {
                        setSelBenchModel({ ...selBenchModel, MetricNo: i, MetricKey: m })
                        if (selBenchModel.ModelKey !== "_") {
                            let tvars = [false, false]
                            let xvar = false
                            let gvar = false

                            if (selBenchModel.VarHeader !== "") { xvar = true }
                            if (selBenchModel.GrpHeader !== "") { gvar = true }
                            if (selBenchModel.FilterHeader1 !== "") { tvars[0] = true }
                            if (selBenchModel.FilterHeader2 !== "") { tvars[1] = true }
                            console.log(selBenchModel)

                            setFltFld({ x: xvar, g: gvar, 0: tvars[0], 1: tvars[1] })
                            setKeyParams({ ...keyparams, step: 2 })
                        } else {
                            setKeyParams({ ...keyparams, step: 1 })
                        }

                    }
                }
            })
        }
    }, [keyparams.selMetricKey, selqrymdl, selBenchModel?.MetricKey])

    useEffect(() => {
        let overwriterng: Key_Name[] = []
        selBenchModel?.benchmarkset.forEach((bs: Benchmarkset) => {
            let pdate = new Date(bs.PrevDate)
            let cdate = new Date(bs.CurrDate)
            if (keyparams.applytorange) {
                if (bs.ApplyRange && pdate < keyparams.asofdate_from && cdate > keyparams.asofdate_from) {
                    overwriterng.push({ Key: bs.SetKey, Name: DateString(bs.PrevDate) + " - " + DateString(bs.CurrDate) })
                } else if (bs.ApplyRange && pdate < keyparams.asofdate_to && cdate > keyparams.asofdate_to) {
                    overwriterng.push({ Key: bs.SetKey, Name: DateString(bs.PrevDate) + " - " + DateString(bs.CurrDate) })
                } else if (bs.ApplyRange && pdate < keyparams.asofdate_from && pdate > keyparams.asofdate_to) {
                    overwriterng.push({ Key: bs.SetKey, Name: DateString(bs.PrevDate) + " - " + DateString(bs.CurrDate) })
                } else if (bs.ApplyRange && cdate < keyparams.asofdate_from && cdate > keyparams.asofdate_to) {
                    overwriterng.push({ Key: bs.SetKey, Name: DateString(bs.PrevDate) + " - " + DateString(bs.CurrDate) })
                } else if (!bs.ApplyRange && cdate > keyparams.asofdate_from && cdate < keyparams.asofdate_to) {
                    overwriterng.push({ Key: bs.SetKey, Name: DateString(bs.CurrDate) })
                }
            } else {
                if (bs.ApplyRange && cdate > keyparams.asofdate && pdate < keyparams.asofdate) {
                    overwriterng.push({ Key: bs.SetKey, Name: DateString(bs.PrevDate) + " - " + DateString(bs.CurrDate) })
                } else if (!bs.ApplyRange && cdate === keyparams.asofdate) {
                    overwriterng.push({ Key: bs.SetKey, Name: DateString(bs.CurrDate) })
                }
            }
        })
        setOverwriteRanges(overwriterng)

    }, [keyparams.asofdate_from, keyparams.asofdate_to])

    useEffect(() => {
        if (selKPI && keyparams.kpichange) {
            setSelKPI({ ...selKPI, values: ObjectSort(selKPI.values, "AsofDate") })
            setKeyParams({ ...keyparams, kpichange: false, autofill: selKPI.AutoFillBenchmark })
        }
    }, [keyparams.kpichange])


    const FileLoad = (divID: string) => {
        let elem: any = document.getElementById(divID)
        let dfile = elem.attributes[0].ownerElement?.files[0]
        setKeyParams({ ...keyparams, dfile: dfile, loaded: true })
    }

    const ClickKPIFill = () => {
        GenericBackendCall("", schema, paths.generics.dataexcelread, user, config, { file: keyparams.dfile }, "dt_upldiv", "Complete!").then(d => {
            let data = SerializeDataFrame(d)
            setKPIBenchmark(data)

        })
    }

    //---backend---------
    const ClickQryUpload = () => {
        GenericBackendCall("", schema, paths.generics.dataexcelread, user, config, { file: keyparams.dfile }, "dt_upldiv", "Complete!").then(d => {
            let data = SerializeDataFrame(d)
            if (data.length > 0) {
                let foundcount = 0
                let headers = Object.keys(data[0])
                setUplFields(headers)
                setUplData(data)
                let tcount = 1
                if (fltfld['x']) {
                    tcount += 1
                } else if (fltfld['g']) {
                    tcount += 1
                } else if (fltfld[0]) {
                    tcount += 1
                } else if (fltfld[1]) {
                    tcount += 1
                }
                if (selBenchModel) {
                    let newbenchmodel = selBenchModel
                    headers.forEach((hd: string) => {
                        if (hd === "XFieldValue" && fltfld['x']) {
                            newbenchmodel.VarHeader = hd
                            foundcount += 1
                        } else if (hd === "GroupFieldValue" && fltfld['g']) {
                            newbenchmodel.GrpHeader = hd
                            foundcount += 1
                        } else if (hd === "FilterValue1" && fltfld[0]) {
                            newbenchmodel.FilterHeader1 = hd
                            foundcount += 1
                        } else if (hd === "FilterValue2" && fltfld[1]) {
                            newbenchmodel.FilterHeader2 = hd
                            foundcount += 1
                        } else if (hd === "Value") {
                            newbenchmodel.BenchHeader = hd
                            foundcount += 1
                        }
                    })
                    setSelBenchModel(newbenchmodel)
                }
                setKeyParams({ ...keyparams, step: 4, note_autopopulated: "Autopopulated " + String(foundcount) + "/" + String(tcount) + " fields." })
            }
        })
    }

    const ClickSaveModel = () => {
        if (selBenchModel) {
            GenericBackendCall(selBenchModel.ModelKey, schema, paths.benchmark.benchmarkmodelpost, user, config,
                {
                    ...selBenchModel, QueryModelName: selqrymdl?.ModelKey,
                }, "dt_savediv", "loading date").then(d => { if (d.length === 1) { ClickSaveSet(d) } })
        }
    }

    const ClickKPISave = () => {
        if (selKPI) {
            GenericBackendCall(selKPI.Key, schema, paths.kpi.kpibenchmarkpost, user, config, { data: kpibenchmark, autofill: keyparams.autofill }, "bmm_kpisave").then(_ => {
                ////
            })
        }
    };

    const ClickSaveSet = (modelkey: string) => {
        if (selBenchModel) {
            let asofdate = keyparams.rundate
            if (keyparams.applytorange) { asofdate = keyparams.asofdate_to }
            let conflicts = overwriteranges.map((kn: Key_Name) => { return kn.Key })
            GenericBackendCall(modelkey, schema, paths.benchmark.benchmarksetpost, user, config,
                {
                    asofdate: asofdate, asofdate_from: keyparams.asofdate_from, applytorange: keyparams.applytorange, benchmark: benchmark, conflicts: conflicts
                }, 'dt_savediv').then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }


    const ClickExport = () => {
        if (keyparams.type === "Query") {
            ExcelExport({ csvDataArry: [benchmark], sheetNames: ["benchmarkvalues"], filename: selqrymdl?.ModelName + "_values for benchmark" })
        } else if (selKPI) {
            let vals = selKPI.values.map((kv: KPIValue) => {
                return { AsofDate: kv.AsofDate, Value: kv.Value, Benchmark: kv.Benchmark }
            })

            ExcelExport({ csvDataArry: [vals], sheetNames: ["kpivalues"], filename: selKPI?.KPIName + "_values for KPI" })
        }
    }

    const ClickDeleteModel = () => {
        if (selBenchModel) {
            GenericBackendCall(selBenchModel.ModelKey, schema, paths.generics.genericdelete, user, config,
                { model: 'BenchModel', field: "ModelKey" }, "dt_deldiv").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }

    const ClickDeleteSet = () => {
        GenericBackendCall(keyparams.selBenchsetkey, schema, paths.generics.genericdelete, user, config,
            { model: 'Benchmarkset', field: "SetKey" }, "dt_delsetdiv").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
    }

    const ImportRunDate = () => {
        GenericBackendCall(keyparams.selrundatekey, schema, paths.query.rundatesview, user, config,
            { how: "single", }).then((d: QueryRunDate[]) => {
                if (d.length > 0) {
                    setBasicQuery(d[0].basicquery)
                }
            })
    }

    //---------------------------------

    const GetUniques = () => {

        // let vars = ['Variable', "Grouping"]
        let vars: string[] = []
        // let varmap: any = { "Variable": "XFieldValue", "Grouping": "GroupFieldValue" }
        if (fltfld['x']) {
            vars.push('Variable')
            varmap["Variable"] = "XFieldValue"
        }
        if (fltfld['g']) {
            vars.push('Grouping')
            varmap["Grouping"] = "GroupFieldValue"
        }
        if (fltfld[0]) {
            vars.push('FilterValue1')
            varmap["FilterValue1"] = "FilterValue1"
        }
        if (fltfld[1]) {
            vars.push('FilterValue2')
            varmap["FilterValue1"] = "FilterValue2"
        }
        let uvals = GetUniquesFromArrayMultiField(basicquery, vars)

        let uvalsm: Benchmark[] = uvals.map((u: any) => {
            let val: any = { ...BenchmarkInit[0] }
            Object.keys(varmap).forEach((k: string) => {
                val[varmap[k]] = u[k]
            })
            return val
        })
        setBenchmark(uvalsm)
        setVarMap(varmap)
        setKeyParams({ ...keyparams, step: 3 })
    }

    const CheckMatches = () => {
        if (selBenchModel) { //&& IsInVector(uplFields, selBenchModel.GrpHeader) && IsInVector(uplFields, selBenchModel?.VarHeader)
            let matchmatrix: any[] = []
            let matches: number = 0
            const DateConvert = (v: any) => {
                return new Date(String(v).replace("t", " "))
            }

            const CheckIsDate = (val: any) => {
                let retval = val
                try {
                    let nd = DateConvert(val)
                    retval = !isNaN(nd.getTime())
                }
                catch (_) { }
                return (retval)
            }
            benchmark.forEach((bm: Benchmark, a: number) => {
                let found = false;
                uplData.forEach((up: any, b: number) => {
                    if (selBenchModel) {
                        let checkval1a: any = String(bm.GroupFieldValue)
                        let checkval1b: any = String(up[selBenchModel.GrpHeader])
                        let checkval2a: any = String(bm.XFieldValue)
                        let checkval2b: any = String(up[selBenchModel.VarHeader])
                        let checkval3a: any = String(bm.FilterValue1)
                        let checkval3b: any = String(up[selBenchModel.FilterHeader1])
                        let checkval4a: any = String(bm.FilterValue2)
                        let checkval4b: any = String(up[selBenchModel.FilterHeader2])

                        if (!selBenchModel.GrpHeader) { checkval1b = bm.GroupFieldValue } //force true if not grpfield
                        if (!selBenchModel.VarHeader) { checkval2b = bm.XFieldValue } //force true if not xfield
                        if (!selBenchModel.FilterHeader1) { checkval3b = bm.FilterValue1 } //force true if not flt1
                        if (!selBenchModel.FilterHeader2) { checkval4b = bm.FilterValue2 } //force true if not flt2


                        //console.log(checkval1a === checkval1b, checkval1a, checkval1b)
                        // if (CheckIsDate(checkval1a)) {
                        //     console.log('converta')
                        //     checkval1a = +DateConvert(bm.GroupFieldValue)
                        //     checkval1b = +DateConvert(up[selBenchModel.GrpHeader])
                        // }
                        // if (CheckIsDate(checkval2a)) {
                        //     console.log('convertb')
                        //     checkval2a = +DateConvert(new Date(bm.XFieldValue))
                        //     checkval2b = +DateConvert(up[selBenchModel.VarHeader])
                        // }
                        // if (a === 0 && b === 0) { //debug first match
                        //     console.log(bm)
                        //     console.log(up)
                        //     console.log(checkval1a, 'b', checkval1b, "2", checkval2a, 'b', checkval2b, "3", checkval3a, 'b', checkval3b, "4", checkval4a, 'b', checkval4b)
                        //     console.log(checkval1a === checkval1b, checkval2a === checkval2b, checkval3a === checkval3b, checkval4a === checkval4b)
                        // }

                        if (!found && checkval1a === checkval1b && checkval2a === checkval2b && checkval3a === checkval3b && checkval4a === checkval4b) {
                            matchmatrix.push({ ...bm, Value: up[selBenchModel.BenchHeader] })
                            found = true
                            matches += 1
                        }
                    }
                })
                if (!found) {
                    matchmatrix.push({ ...bm, Value: null })
                }
            })
            setBenchmark(matchmatrix)
            setKeyParams({ ...keyparams, matchcount: matches, missingcount: uplData.length - matches })
        }
    }

    const KPICase = () => {
        return (<div>
            <div style={{ display: "flex" }}>
                <GenericDropdown
                    data={kpis}
                    keycol="Key"
                    namecol="KPIName"
                    promptstring="Select KPI"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, kpis, setSelKPI, "", ""); setKeyParams({ ...keyparams, kpichange: true }) }}
                    includeDefault={false}
                    filterfield="SchemaName"
                    filtervalue={schema}
                    divID="bmm_selqrydiv"
                />
                <div style={{ fontSize: "18px", marginLeft: "10px" }}>{selKPI?.KPIName}</div>
            </div>
            <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, type: "", step: 0, loaded: false }); setSelKPI(null); setSelBenchModel(null); }}>Back</button>
        </div>)
    }

    const QueryCase = () => {
        return (
            <div className="ndt_gridbox">
                <div>
                    <div>Select Query & Schedule</div>
                    <div style={{ display: "flex", height: "30px", margin: '10px' }}>
                        <GenericDropdown
                            data={querymodels}
                            keycol="ModelKey"
                            namecol="ModelName"
                            promptstring="Select Query"
                            className="ndt_dropdown"
                            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, querymodels, setSelQryMdl, "", "") }}
                            includeDefault={false}
                            filterfield="SchemaName"
                            filtervalue={schema}
                            divID="bmm_selqrydiv"
                        />
                        {selqrymdl ?
                            <GenericDropdown
                                data={qrymetrics}
                                keycol="MetricKey"
                                namecol="MetricName"
                                promptstring="Select Metric"
                                className="ndt_dropdown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, qrymetrics, keyparams, setKeyParams, "selMetricKey", "MetricKey", "") }}
                                divID="bmm_selmetrdiv"
                            />
                            : <div></div>}
                        {selqrymdl && keyparams.selMetricKey !== "" ?
                            <GenericDropdown
                                data={benchmodels}
                                keycol="ModelKey"
                                namecol="ModelName"
                                promptstring="Select Benchmark Model"
                                includeDefault={true}
                                defaultstring={"Create New"}
                                className="ndt_dropdown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, benchmodels, setSelBenchModel, "", { ...BenchModelInit[0], ModelKey: "_", MetricKey: keyparams.selMetricKey }) }}
                                divID="bmm_selmdldiv"
                            />
                            : <div></div>}
                        {selBenchModel?.ModelKey !== "_" && selBenchModel ?
                            <button className='ndt_btn2' onClick={_ => { setKeyParams({ ...keyparams, isdelmdlvisible: true }) }}>Delete Benchmark Model</button>
                            : <div></div>}
                    </div>
                </div>
                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, type: "", step: 0, loaded: false }); setSelKPI(null); setSelBenchModel(null); }}>Back</button>
            </div>
        )
    }

    const QueryMain = () => {
        return (<div>

            {(() => {
                switch (keyparams.step) {
                    case 0: return <div className="ndt_gridbox bmm_stepbox" ></div>
                    case 1: return <div className="ndt_gridbox bmm_stepbox" >
                        <div className="ndt_title2" style={{ marginRight: "10px" }}>Set Fields: </div>
                        {selqrymdl ?
                            <div style={{ height: "150px" }}>
                                <div style={{ fontSize: "22px" }}>Fields:</div>
                                <div style={{ display: "flex" }}>
                                    <div className='bmm_fldbtn'
                                        style={fltfld['x'] ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                        onClick={_ => { setFltFld({ ...fltfld, 'x': !fltfld['x'] }) }}
                                    >
                                        X: {GetFieldName(fields, selqrymdl?.XFieldName)}</div>

                                    {String(selqrymdl?.GroupFieldName) !== "null" ?
                                        <div className='bmm_fldbtn'
                                            style={fltfld['g'] ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                            onClick={_ => { setFltFld({ ...fltfld, 'g': !fltfld['g'] }) }}
                                        >
                                            G: {GetFieldName(fields, selqrymdl?.GroupFieldName)}</div>
                                        : <div></div>}

                                    {selqrymdl.filterfields.map((ff: FilterField, i: number) => {
                                        return <div key={i}
                                            onClick={_ => { setFltFld({ ...fltfld, [i]: !fltfld[i] }) }}
                                            style={fltfld[i] ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                            className='bmm_fldbtn bmm_opt'
                                        >{GetFieldName(fields, ff.FieldName)}</div>
                                    })}
                                </div>
                                <button id="bmm_step1btn" className='ndt_btn1' style={{ margin: "30px" }} onClick={_ => { setKeyParams({ ...keyparams, step: 2 }) }}>Set Fields</button>
                            </div>
                            : <div></div>}
                    </div>
                    case 2: return <div className="ndt_gridbox bmm_stepbox" >
                        <div className="ndt_title3">Run Date Select: </div>
                        {selqrymdl && selqrymdl.rundates.length > 0 ?
                            <div style={{ height: "300px", overflowY: "scroll" }}>
                                {selqrymdl?.rundates.map((rd: QueryRunDate, i: number) => {
                                    let ostr = ""
                                    if (keyparams.selrundatekey === rd.DateKey) { ostr = "- Selected" }
                                    let rstring = DateString(rd.AsofDate) + ostr

                                    return (<div key={i}
                                        className='bmm_rdobj'
                                        onClick={_ => { setKeyParams({ ...keyparams, selrundatekey: rd.DateKey, rundate: rd.AsofDate, asofdate_from: rd.AsofDate, asofdate_to: rd.AsofDate }) }}
                                    >{rstring}</div>)
                                })}
                            </div> :
                            <div style={{ height: "200px" }}>This model must be run at least once before continuing</div>}
                        <div style={{ fontSize: "22px" }}>Existing Uploads:</div>
                        {selBenchModel && selBenchModel?.benchmarkset.length > 0 ?
                            <div style={{ overflowY: "scroll", height: '250px', width: '60%', minWidth: '500px' }}>
                                {selBenchModel?.benchmarkset.map((bs: Benchmarkset, i: number) => {
                                    let rstring = DateString(bs.CurrDate) + " - Rows: " + String(bs.benchmark.length)
                                    if (bs.ApplyRange) {
                                        rstring = DateString(bs.PrevDate) + " - " + DateString(bs.CurrDate) + " - Rows: " + String(bs.benchmark.length)
                                    }
                                    return (<div key={i}
                                        className='bmm_existsetitm'
                                        onClick={_ => { setKeyParams({ ...keyparams, selBenchsetkey: bs.SetKey, isdelsetvisible: true }) }}>
                                        {rstring}
                                    </div>)
                                })}
                            </div>
                            : <div style={{ height: '250px' }}>No Uploads Yet</div>}
                        {keyparams.selrundatekey !== "" ?
                            <button className='ndt_btn1' style={{ width: "100px" }} onClick={_ => { ImportRunDate() }}>Confirm</button>
                            : <div></div>}
                    </div>
                    case 3: return <div>
                        <div style={{ marginTop: "15px", marginLeft: "30px" }}>
                            <div className="ndt_title2" style={{ marginRight: "10px" }}>Benchmarks Import: </div>

                        </div>
                        <div style={{ display: "grid", height: "200px", gridTemplateColumns: "40% 60%" }}>
                            <div style={{ margin: '20px' }}>
                                <div className='ndt_title4'>Check Data</div>
                                <div>{benchmark.length > 0 ?
                                    <button className='ndt_btn1' onClick={_ => { ClickExport() }}>Export Values List</button>
                                    : <div></div>}
                                </div>
                            </div>
                            <div style={{ margin: "20px" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginRight: "20px" }} className='ndt_title4'>Import Values File</div>
                                    <input type="file" id="dt_fileinput" name="benchFile" onChange={_ => FileLoad("dt_fileinput")} />
                                </div>
                                {keyparams.loaded ?
                                    <div>
                                        <button className="ndt_btn1" style={{ width: "120px" }} onClick={_ => ClickQryUpload()}>Import</button>
                                    </div>
                                    : <div></div>}
                            </div>
                        </div>
                    </div>
                    case 4: return <div className="ndt_gridbox bmm_stepbox">
                        <div style={{ display: "flex" }}>
                            <button className="ndt_btn3" onClick={_ => { setKeyParams({ ...keyparams, ischeckcolsvisible: true }) }}>Set Columns</button>

                            <button className="ndt_btn1" onClick={_ => CheckMatches()}>Check Values</button>

                            <div>{keyparams.note_autopopulated}</div>
                        </div>

                        <div>
                            <div className="ndt_gridbox" >
                                <div style={{ height: "400px", overflowY: "scroll" }}>
                                    <div className="ndt_title3">Value Pairings: {uplData.length > 0 ? <a style={{ fontSize: "18px" }}>{keyparams.matchcount} of {benchmark.length} rows</a> : <a></a>}</div>
                                    {benchmark.map((uv: Benchmark, i: number) => {
                                        return (<div key={i} className="bmm_valueitm" style={{ display: 'flex', height: '35px', width: '80%', border: '3px solid black', cursor: 'pointer', padding: '3px', marginTop: '5px' }}>
                                            <div style={{ display: "flex" }}>
                                                {Object.keys(varmap).map((k: string, n: number) => {
                                                    //console.log(GetObjectValue(uv, varmap[k]))
                                                    return <div className="bmm_uniqueitm" key={n}>{GetObjectValue(uv, varmap[k])}</div>
                                                })}
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>

                        </div>
                        {keyparams.matchcount === benchmark.length && keyparams.missingcount === 0 ?
                            <button style={{ margin: '10px', width: '100px' }} className='ndt_btn1' onClick={_ => setKeyParams({ ...keyparams, step: 5 })}>Confirm</button>
                            : <div></div>}
                    </div>



                    case 5: return <div className="ndt_gridbox bmm_stepbox">
                        {selBenchModel ?
                            <div>
                                <div className='ndt_title3'>Benchmark Model: {selBenchModel.ModelName}</div>
                                <div>Metric: {GetObjectField(selBenchModel.MetricKey, metrics, "MetricKey", "MetricName")}</div>
                                <div>{Object.keys(varmap).map((k: string, n: number) => {
                                    return (<div key={n}>{k}: {varmap[k]}</div>)
                                })}</div>

                                <div>
                                    Apply to Range
                                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, applytorange: false }) }}>Single Date</button>
                                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, applytorange: true }) }}>Range</button>
                                </div>
                                {keyparams.applytorange ?
                                    <div>
                                        <div className="datepick_class" style={{ width: "155px" }}>
                                            <DatePicker value={keyparams.asofdate_from} onChange={(e: any) => setKeyParams({ ...keyparams, "asofdate_from": e })} />
                                        </div>
                                        <div className="datepick_class" style={{ width: "155px" }}>
                                            <DatePicker value={keyparams.asofdate_to} onChange={(e: any) => setKeyParams({ ...keyparams, "asofdate_to": e })} />
                                        </div>
                                    </div> : <div>
                                        <div className="datepick_class" style={{ width: "155px" }}>
                                            <DatePicker value={keyparams.asofdate} onChange={(e: any) => setKeyParams({ ...keyparams, "asofdate": e })} />
                                        </div>
                                    </div>}
                            </div>
                            : <div></div>}

                        <div>
                            <div style={{ fontSize: "20px" }}>Date Conflicts:</div>
                            {overwriteranges.map((kn: Key_Name, o: number) => {
                                return (<div key={o}>{kn.Name}</div>)
                            })}
                            {overwriteranges.length > 0 ? <div>Conflicts will be deleted/edited to fit</div> : <div></div>}
                        </div>
                        {selBenchModel && selBenchModel.ModelKey === "_" ?
                            <button className="ndt_btn1" style={{ marginLeft: "5px", marginTop: "20px", width: "220px" }} onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}>Save New Model and Run Date</button>
                            : <button className="ndt_btn1" style={{ marginLeft: "5px", marginTop: "20px", width: "120px" }} onClick={_ => { setKeyParams({ ...keyparams, issavedatevisible: true }) }}>Save Run Date</button>
                        }
                    </div>
                    default: return <div></div>;
                }
            })()}
        </div>)
    }

    const ClickKPIVal = (n: number) => {
        if (keyparams.applytorange) {

            if (keyparams.selidx_from === -1) {
                setKeyParams({ ...keyparams, selidx_from: n, selidx_to: n })
            } else if (keyparams.selidx_from === keyparams.selidx_to && n > keyparams.selidx_from) {
                setKeyParams({ ...keyparams, selidx_to: n })
            } else if (n > keyparams.selidx_from && keyparams.selidx_from > -1) {
                setKeyParams({ ...keyparams, selidx_to: n })
            } else if (n < keyparams.selidx_from && keyparams.selidx_to > -1) {
                setKeyParams({ ...keyparams, selidx_from: n })
            }
        } else {
            setKeyParams({ ...keyparams, selidx_from: n, selidx_to: n })
        }
    }
    const CheckStyle = (n: number) => {
        if (n >= keyparams.selidx_from && n <= keyparams.selidx_to) {
            return clickstyle.btnSelected
        } else {
            return clickstyle.btnUnselected
        }

    }

    const KPIMain = () => {

        return (<div className='ndt_gridbox'>
            {selKPI ? <div>
                <div style={{ fontSize: "18px" }}>Metric {GetObjectField(selKPI.MetricKey, metrics, "MetricKey", "MetricName")}</div>

                {(() => {
                    switch (keyparams.step) {
                        case 0: return <div className='ndt_innerbox'>
                            <div className='ndt_subinner'>
                                <div className="bmm_kpiitm">
                                    <div className="bmm_kpiitm_dt">Date</div>
                                    <div className='bmm_kpiitm_val'>Value</div>
                                    <div className='bmm_kpiitm_bm'>Benchmark</div>
                                </div>
                                <div style={{ height: '300px', overflowY: "scroll" }}>
                                    {selKPI.values.map((kval: KPIValue, i: number) => {
                                        return (<div key={i} className="bmm_kpiitm"
                                            style={CheckStyle(i)}
                                            onClick={_ => { ClickKPIVal(i) }}>
                                            <div className="bmm_kpiitm_dt">{DateString(kval.AsofDate)}</div>
                                            <div className='bmm_kpiitm_val'>{Math.round(kval.Value * 100) / 100}</div>
                                            <div className='bmm_kpiitm_bm'>{Math.round(kval.Benchmark * 100) / 100}</div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ display: "flex", marginRight: "100px" }}>
                                    <button className='ndt_btn1'
                                        style={keyparams.applytorange ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                        onClick={_ => { setKeyParams({ ...keyparams, applytorange: !keyparams.applytorange, selidx_from: -1, selidx_to: -1 }) }}>Multiselect</button>
                                    {keyparams.applytorange ?
                                        <button style={{ width: '100px', marginLeft: "10px", marginRight: "10px" }} className='ndt_btn1'
                                            onClick={_ => { if (selKPI) { setKeyParams({ ...keyparams, selidx_from: 0, selidx_to: selKPI.values.length - 1 }) } }}
                                        >Select All</button>
                                        : <div style={{ width: "120px" }}></div>}
                                    <button className='ndt_btn1'
                                        onClick={_ => { setKeyParams({ ...keyparams, selidx_from: -1, selidx_to: -1 }) }}>Clear</button>
                                </div>
                                <button className='ndt_btn1' style={keyparams.autofill ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setKeyParams({ ...keyparams, autofill: true }) }}>Autofill for new dates</button>
                                {keyparams.selidx_to > -1 ?
                                    <button className='ndt_btn1' style={{ marginLeft: "30px", width: "100px" }} onClick={_ => { setKeyParams({ ...keyparams, step: 1 }) }}>Next</button> : <div></div>}

                            </div>

                        </div>;
                        case 1: return <div className='ndt_innerbox'>
                            <div style={{ fontSize: "18px" }}>
                                Range: {DateString(selKPI.values[keyparams.selidx_from].AsofDate)} - {DateString(selKPI.values[keyparams.selidx_to].AsofDate)}
                            </div>
                            <div>
                                <div style={{ marginBottom: "100px" }}>
                                    <div>Export:</div>
                                    <button className='ndt_btn1' onClick={_ => { ClickExport() }}>Export Values List</button>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginRight: "20px", fontSize: "18px" }}>Import Values File</div>
                                    <input type="file" id="bmm_fileinput" name="kpiFile" onChange={_ => FileLoad("bmm_fileinput")} />
                                </div>
                                <div>
                                    <button className='ndt_btn1' onClick={_ => { ClickKPIFill() }}>Import</button>
                                </div>
                                {keyparams.loaded && kpibenchmark.length > 0 ? <div>
                                    <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, step: 2 }) }}>Next</button>
                                </div> : <div></div>}
                            </div>
                        </div>;
                        case 2: return <div>
                            Save
                            <button className='ndt_btn1' onClick={_ => { ClickKPISave() }}>Save KPI</button>
                            <div id="bmm_kpisave"></div>
                        </div>;
                        default: return <div>

                        </div>;
                    }
                })()}

            </div> : <div></div>}

        </div>)
    }

    return (<div className="ndt_canvas">
        <div className="ndt_title2">Benchmarks</div>
        <div>
            {(() => {
                switch (keyparams.type) {
                    case "Query": return <QueryCase />;
                    case "KPI": return <KPICase />;

                    default: return <div>
                        <button className='bmm_topbtn ndt_btn1'
                            style={keyparams.type === "Query" ? clickstyle.btnSelected : clickstyle.btnUnselected}
                            onClick={_ => { setKeyParams({ ...keyparams, type: "Query" }) }}
                        >
                            Query
                        </button>
                        <button className='bmm_topbtn ndt_btn1'
                            style={keyparams.type === "KPI" ? clickstyle.btnSelected : clickstyle.btnUnselected}
                            onClick={_ => { setKeyParams({ ...keyparams, type: "KPI" }) }}
                        >
                            KPI
                        </button>
                    </div>;
                }
            })()}
        </div>

        <div>
            {keyparams.type !== "KPI" ? <div>
                <QueryMain />
            </div> : <div> <KPIMain /></div>}
        </div>


        <div>
            {keyparams.step > 0 ?
                <button className="ndt_btn1" style={{ margin: '10px' }} onClick={_ => { setKeyParams({ ...keyparams, step: keyparams.step - 1, selidx_from: -1, selidx_to: -1, applytorange: false }) }}>Back</button>
                : <div></div>}
        </div>
        <Modal show={keyparams.issavevisible}>
            <div className="ndt_modal" style={{ padding: "10px" }}>
                <div style={{ fontSize: "20px" }}>Save New Benchmark Model as of {DateString(keyparams.asofdate)}</div>
                <div>with name:</div>
                <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => { if (selBenchModel) { setSelBenchModel({ ...selBenchModel, ModelName: e.target.value }) } }} />
                <div>
                    <button className="ndt_btn1" style={{ width: "150px", margin: '5px' }} onClick={_ => { ClickSaveModel() }}>Save</button>
                </div>
                <div id="dt_savediv"></div>

                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.issavedatevisible}>
            <div className="ndt_modal" style={{ padding: "10px" }}>
                <div className='ndt_modaltitle'>Save these benchmarks as of {DateString(keyparams.asofdate)}:</div>
                <button className="ndt_btn1" style={{ width: "150px", margin: '5px' }} onClick={_ => { if (selBenchModel) { ClickSaveSet(selBenchModel.ModelKey) } }}>Save </button>
                <div id="dt_savediv"></div>

                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavedatevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>


        <Modal show={keyparams.isdelsetvisible}>
            <div className="ndt_modal" style={{ padding: "10px" }}>
                <div className='ndt_modaltitle'>Delete Benchmark as of {DateString(keyparams.asofdate)}:</div>
                <button className="ndt_btn1" style={{ width: "150px", margin: '5px' }} onClick={_ => { ClickDeleteSet() }}>Delete </button>
                <div id="dt_delsetdiv"></div>

                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelsetvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdelmdlvisible}>
            <div className="ndt_modal" style={{ padding: "10px" }}>
                {selBenchModel ?
                    <div className='ndt_modaltitle'>Delete Benchmark Model for {GetObjectField(selBenchModel?.QueryModelName, querymodels, "ModelKey", "ModelName")}:</div>
                    : <div></div>}
                <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { ClickDeleteModel() }}>Delete </button>
                <div id="dt_deldiv"></div>

                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelmdlvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.ischeckcolsvisible} dialogClassName='modalwide'>
            <div className="ndt_modal">
                <div>
                    <div className='ndt_modaltitle' style={{ marginLeft: "20px" }}>Set Imported Columns to Model</div>
                    <div className="ndt_innerbox" style={{ gridColumn: '1/1', padding: "10px" }}>
                        {selBenchModel ?
                            <div>
                                <div className='bmm_bfldrow'>
                                    <div className='bmm_bfldsubrw' style={{ fontWeight: "bold" }}>Benchmark Attribute</div>
                                    <div className='bmm_bfldsubrw' style={{ fontWeight: "bold" }}>Selected Field</div>
                                    <div></div>
                                </div>
                                <div className='bmm_bfldrow' style={keyparams.activefld === "VarHeader" ? clickstyle.itmSelected : clickstyle.itmUnselected}>
                                    <div className='bmm_bfldsubrw'>Query X Field: </div>
                                    <div className='bmm_bfldsubrw'>{selBenchModel?.VarHeader}</div>
                                    <button onClick={_ => { setKeyParams({ ...keyparams, activefld: "VarHeader" }) }}
                                        style={btnStyles.VarHeader}>Change</button>
                                </div>
                                <div className='bmm_bfldrow' style={keyparams.activefld === "GrpHeader" ? clickstyle.itmSelected : clickstyle.itmUnselected}>
                                    <div className='bmm_bfldsubrw'>Query Group Field: </div>
                                    <div className='bmm_bfldsubrw'>{selBenchModel?.GrpHeader}</div>
                                    <button onClick={_ => { setKeyParams({ ...keyparams, activefld: "GrpHeader" }) }}
                                        style={btnStyles.GrpHeader}>Change</button>
                                </div>
                                {fltfld[0] ?
                                    <div className='bmm_bfldrow' style={keyparams.activefld === "FilterHeader1" ? clickstyle.itmSelected : clickstyle.itmUnselected}>
                                        <div className='bmm_bfldsubrw'>Filter Field 1: </div>
                                        <div className='bmm_bfldsubrw'>{selBenchModel?.FilterHeader1}</div>
                                        <button onClick={_ => { setKeyParams({ ...keyparams, activefld: "FilterHeader1" }) }}
                                            style={btnStyles.FilterHeader1}>Change</button>
                                    </div> : <div></div>}
                                {fltfld[1] ?
                                    <div className='bmm_bfldrow' style={keyparams.activefld === "FilterHeader2" ? clickstyle.itmSelected : clickstyle.itmUnselected}>
                                        <div className='bmm_bfldsubrw'>Filter Field 2: </div>
                                        <div className='bmm_bfldsubrw'>{selBenchModel?.FilterHeader2}</div>
                                        <button onClick={_ => { setKeyParams({ ...keyparams, activefld: "FilterHeader2" }) }}
                                            style={btnStyles.FilterHeader2}>Change</button>
                                    </div> : <div></div>}

                                <div className='bmm_bfldrow' style={keyparams.activefld === "BenchHeader" ? clickstyle.itmSelected : clickstyle.itmUnselected}>
                                    <div className='bmm_bfldsubrw'>Benchmark Value Field: </div>
                                    <div className='bmm_bfldsubrw'>{selBenchModel?.BenchHeader}</div>
                                    <button onClick={_ => { setKeyParams({ ...keyparams, activefld: "BenchHeader" }) }}
                                        style={btnStyles.BenchHeader}>Change</button>
                                </div>


                            </div>
                            : <div></div>}
                    </div>
                    <div className="ndt_innerbox" style={{ gridColumn: '2/2' }}>
                        <div style={{ fontSize: "20px", letterSpacing: ".05em" }}>Imported Fields</div>
                        <div className="bmm_flditm">
                            <div className='bmm_fldsubitm' style={{ fontWeight: "bold" }}>Field</div>
                            <div className='bmm_fldsubitm' style={{ fontWeight: "bold" }}>Set To</div>
                        </div>
                        {uplFields.map((fld: any, i: number) => {
                            let val: string = ""
                            if (selBenchModel?.GrpHeader === fld) { val = "Group Field" }
                            if (selBenchModel?.VarHeader === fld) { val = "X Field" }
                            if (selBenchModel?.BenchHeader === fld) { val = "Bench Field" }
                            if (selBenchModel?.FilterHeader1 === fld) { val = "Filter Field 1" }
                            if (selBenchModel?.FilterHeader2 === fld) { val = "Filter Field 2" }
                            return (<div
                                className="bmm_flditm"
                                onClick={_ => { if (selBenchModel) { setSelBenchModel({ ...selBenchModel, [keyparams.activefld]: fld }) } }}
                                key={i}>
                                <div className='bmm_fldsubitm'>{fld}</div>
                                <div className='bmm_fldsubitm'>{val}</div>
                            </div>)
                        })}
                    </div>
                </div>

                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, ischeckcolsvisible: false })}>Close</button>
            </div>
        </Modal >

    </div >)
};
export default BenchmarkManager;