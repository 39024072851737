import DateAdd from "./DateAdd";
import ConvertToUTC from "./ConvertToUTC";

const CalculateDateSequence = (anchordate: Date, period: string, nperiods: number, iperiod: number, forwards: boolean = true) => {
    //ref: querymodelviewscheduler, graphcreator, calculateschedule
    let Darry = Array.apply(null, Array(nperiods)).map(function (_, i) { return i; })
    let dir = 1;
    if (!forwards) {
        dir = -1
    }

    //console.log(enddate, period, nperiods, iperiod)
    let prevdate = anchordate
    let nextdate = anchordate
    let hist: Date[][] = Darry.slice(0).reverse().map((_) => {
        nextdate = prevdate
        if (period === "Monthly") {
            let monthdate = new Date(nextdate.getFullYear(), nextdate.getMonth() + iperiod * dir, anchordate.getDate())
            if (nextdate.getDate() > monthdate.getDate()) {
                monthdate = DateAdd(monthdate, -1)
            }
            prevdate = ConvertToUTC(monthdate)
            if (DateAdd(prevdate, 28) > nextdate) { //month error, (29th  - 31st), subtract extra month
                let monthdate = new Date(nextdate.getFullYear(), nextdate.getMonth() + (iperiod * dir), anchordate.getDate())
                if (nextdate.getDate() > monthdate.getDate()) {
                    monthdate = DateAdd(monthdate, 1)
                }
                prevdate = ConvertToUTC(monthdate)
            }
            //second failsafe, for 5.31.2024 monthly
            if (nextdate.getDate() > prevdate.getDate()) {
                prevdate = DateAdd(prevdate, -1)
            }

        } else if (period === "Weekly") {
            prevdate = ConvertToUTC(DateAdd(nextdate, dir * 7 * iperiod))
        } else if (period === "Yearly") {
            prevdate = ConvertToUTC(new Date(nextdate.getFullYear() + dir, nextdate.getMonth(), nextdate.getDate()))

        } else if (period === "Daily") {
            prevdate = DateAdd(nextdate, dir * iperiod)
        }

        if (forwards) {
            return [DateAdd(nextdate, 1), prevdate]
        }
        else {
            return [DateAdd(prevdate, 1), nextdate]
        }
    })

    return hist
};
export default CalculateDateSequence;