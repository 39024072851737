import axios from 'axios';
import GetFieldName from '../../functions/GetFieldName';

//import { empstateDataActionCreator, empstatedeleteActionCreator } from '../../reducers/employee_actions';
import { FieldMap } from '../../type';

const GenericsUniqueImport = async (uniquefields: string[], schemakey: string,
    fieldmap: FieldMap[], config: any, dispatcher: any): Promise<any> => {


    let data: any[] = []

    //to accept either key or attrname
    let keyflds = uniquefields.map((fld: string) => {
        if (fld) {
            if (fld.substring(0, 4) === "fld_") {
                return fld
            } else {
                return GetFieldName(fieldmap, fld, "AttrName", "Key")
            }
        } else {
            return ("")
        }
    })

    let dataArray = new FormData();
    dataArray.append("flddata", String(keyflds));
    dataArray.append("schemakey", schemakey);

    try {
        let empresp = await axios.post<any>(
            `${process.env.REACT_APP_API_URL}/api/generics/genericuniques`,
            dataArray,
            config
        )
        data = empresp.data

    } catch (error) {
        console.log(error)
    }

    return (data)
};
export default GenericsUniqueImport;