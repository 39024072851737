import { useState, FormEvent, useContext, useEffect, ChangeEvent } from "react";
import { Modal } from "react-bootstrap";
import FieldsUploadSample from "../../../data/Fields/Fields_UploadSample";
import FieldsImport from "../../../data/Fields/Fields_Import";
import MatchFieldLists from "../fields/subfunctions/MatchFieldLists";
import EmployeeUpload from "../../../data/Employee/Employee_Upload";
import { Link } from 'react-router-dom';
import { StartupContext } from "../../../App";
import { useSelector } from "react-redux";
import { RenameSet, RuleModel, Ruleset, Schema } from "../../../type";
import { FieldMap } from "../../../type";
import ReportTypeDropdown from "../../../functions/Dropdowns/ReportTypeDropdown";
import IsInObject from "../../../functions/Object Functions/IsInObject";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import GetObjectValue from "../../../functions/Object Functions/GetObjectValue";
import FilterData from "../../../functions/FilterData";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import "./css/ModelUploader.css";
import GetFieldName from "../../../functions/GetFieldName";


interface samplefld {
	Value: string;
}
interface userquestion {
	QuestionString: string;
	ModalNumber: number;
	Completed: boolean;
}

interface paramtype {
	reporttype: string; modelname: string; isImported: boolean;
	header_errcount: number; header_warncount: number; firsterrfield: string;
	uploadsuccess: boolean; headermatchchange: boolean; hiredatefield: string; xfrdatefield: string;
	batchkey: '', linkstring: string;
	assumehire: boolean; assumexfr: boolean; assumeposns: boolean;
	nameoverwrite: boolean; rememberheaders: boolean; includevacant: boolean;
	overwriteentry: boolean; hirefldwarn: string; xfrfldwarn: string; isrulemodalvisible: boolean;
	submittext: string;
}

const ModelTutorial = () => {

	let { config, dispatcher, schema, paths, user } = useContext(StartupContext)
	let CurrSchema: Schema = useSelector((state: any) => state.selectedSchema)

	//modal visibility
	let [isModalVisible, setIsModalVisible] = useState({
		'A': false, 'B': false, 'C': false, 'D': false,
		0: false, 1: false, 2: false, 3: false, 4: false, 5: false,
		6: false, 7: false, 8: false, 9: false, 10: false
	});
	//----------------------


	//style params
	const stylePre = { 'display': "none", 'height': '80px' }
	const stylePast = { 'display': "block", 'height': '80px' }
	const [style, setStyle] = useState<any[]>([stylePast, stylePre, stylePre, stylePre, {}])
	const styleSel = {
		'backgroundColor': "rgb(229, 243, 84)", "color": "black",
		"boxShadow": "2px 2px 2px #faffb1"
	}
	const styleUnSel = { 'backgroundColor': "rgb(59, 59, 59)", "color": "white", "boxShadow": "" }
	const [yesStyle, setYesStyle] = useState<any>({ 1: styleUnSel, 2: styleUnSel, 3: styleUnSel, 4: styleUnSel, 5: styleUnSel });
	const [noStyle, setNoStyle] = useState<any>({ 1: styleSel, 2: styleSel, 3: styleSel, 4: styleSel, 5: styleSel });
	const [ruleModel, setRuleModel] = useState<RuleModel | null>(null)
	//----------------------

	//other parameters
	let [fields, setFields] = useState<FieldMap[]>([]);
	let [sampleFields, setSampleFields] = useState<samplefld[]>([]);
	let [headersList, setHeadersList] = useState<any>({ 0: "", 1: "" })
	let [currStep, setCurrStep] = useState<number>(0)
	let [userquestions, setUserQuestions] = useState<userquestion[]>([])

	const [empFile, setEmpFile] = useState<any | null>(null);
	const [params, setParams] = useState<paramtype>({
		reporttype: "none selected", modelname: '', isImported: false,
		header_errcount: -1, header_warncount: -1, firsterrfield: "",
		uploadsuccess: false, headermatchchange: false, hiredatefield: "", xfrdatefield: "",
		batchkey: '', linkstring: "/",
		assumehire: false, assumexfr: false, assumeposns: false,
		nameoverwrite: false, rememberheaders: false, includevacant: false,
		overwriteentry: false, hirefldwarn: '', xfrfldwarn: '', isrulemodalvisible: false, submittext: 'Submit!'
	})

	let colorErr = "red";
	let colorWarn = "yellow";

	const clickedopen = (val: any) => {
		setIsModalVisible({ ...isModalVisible, [val]: true })
		userquestions.forEach((uq: userquestion, i: number) => {
			if (uq.ModalNumber === val) {
				//is a supplemental question
				let updquestions: userquestion[] = userquestions
				updquestions[i].Completed = true
				setUserQuestions(updquestions)
			}
		})
	};

	const clickedclose = (val: any) => { setIsModalVisible({ ...isModalVisible, [val]: false }) };

	//---------------------------------------------
	useEffect(() => {
		GenericBackendCall("", schema, paths.rulesets.rulemodelview, user,
			config, { model: "RuleModel", filterfield: "Model", OnUpload: "True" }).then(r => {
				if (r.length > 0) {
					setRuleModel(r[0])
				}
			})
	}, [])

	useEffect(() => {
		console.log("reimport")
		let fieldpromise = FieldsImport("EmployeeState", schema, config, dispatcher, false);
		fieldpromise.then(newflds => {
			let useflds = FilterData(newflds, "FieldCategory", "Grouping", true)

			setFields(useflds)
			setHeadersList(MatchFieldLists(sampleFields, useflds))
		})
	}, [params.isImported])

	useEffect(() => { SetQStyle(params.nameoverwrite, 1) }, [params.nameoverwrite]);

	useEffect(() => { SetQStyle(params.overwriteentry, 2) }, [params.overwriteentry]);

	useEffect(() => { SetQStyle(params.rememberheaders, 3) }, [params.rememberheaders]);

	useEffect(() => { SetQStyle(params.assumehire, 4) }, [params.assumehire]);

	useEffect(() => { SetQStyle(params.assumexfr, 5) }, [params.assumexfr]);

	useEffect(() => { SetQStyle(params.assumeposns, 6) }, [params.assumeposns]);



	useEffect(() => {
		if (params.hiredatefield === "") {
			setParams({ ...params, assumehire: false })
		} else {
			setParams({ ...params, assumehire: true })
		}
	}, [params.hiredatefield])

	useEffect(() => {
		CheckPADField("hiredatefield", "hirefldwarn")
	}, [params.hiredatefield, headersList])

	useEffect(() => {
		CheckPADField("xfrdatefield", "xfrfldwarn")
	}, [params.xfrdatefield, headersList])

	const CheckPADField = (paramfield: string, paramwarnfield: string) => {
		let founderr: boolean = false;
		Object.keys(headersList).forEach((k: any) => {
			if (headersList[k][0] === GetObjectValue(params, paramfield)) {

				if (headersList[k][2] === '') {//field not assigned
					founderr = true;
				}
			}
		})

		if (founderr) {
			setParams({ ...params, [paramwarnfield]: "Warning: data column for this field not selected!" })
		} else {
			setParams({ ...params, [paramwarnfield]: "" })
		}
	}

	const SetQStyle = (boolval: boolean, int: number) => {
		if (boolval) {
			setYesStyle({ ...yesStyle, [int]: styleSel })
			setNoStyle({ ...noStyle, [int]: styleUnSel })
		} else {
			setYesStyle({ ...yesStyle, [int]: styleUnSel })
			setNoStyle({ ...noStyle, [int]: styleSel })
		}
	}

	const NextStep = (limit: number) => {
		if (currStep < limit) { //prevent lower step change from increasing step
			setCurrStep(currStep => currStep += 1)
			setStyle({ ...style, [currStep + 1]: stylePast })
		}
	}

	const ApplyFile = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) { setEmpFile(e.target.files[0]) }
	};

	const FileSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (empFile) {
			let uploadpromise = FieldsUploadSample(empFile, schema, config, user, "checkupload_div")
			uploadpromise.then((sd: any) => {
				setSampleFields(sd.data)
				ChangeParameter("isImported", true, 2)
			})
		} else {
			let elem = document.getElementById("checkupload_div")
			if (elem) { elem.innerHTML = "No File Selected" }
		}
	};

	const ChangeParameter = (par: string, val: any, limit: number) => {
		setParams({ ...params, [par]: val })
		NextStep(limit)
	};

	const ConfirmHeaders = (limit: number) => {
		let elemfldarry = document.getElementsByClassName("t_field")
		let errcount: number = 0, warncount: number = 0;
		let errfld: string = "";

		Array.from(elemfldarry).forEach((e: Element, i: number) => {
			let lmnt: HTMLElement | null = document.getElementById(e.id);
			if (lmnt) {
				if (lmnt.style.backgroundColor === colorErr) {
					warncount += 1
					errcount += 1
					if (errfld === "") {
						errfld = lmnt.attributes[0].value;
					}
				} else if (lmnt.style.backgroundColor === colorWarn) {
					warncount += 1
				}
			}
		});
		setParams({ ...params, "header_errcount": errcount, "header_warncount": warncount, "firsterrfield": errfld })
		clickedclose('C')

		if (params.reporttype !== "Terminations") {
			let newquestion1: userquestion = { QuestionString: "Add to hires if new?", ModalNumber: 4, Completed: false }
			AddQuestion(newquestion1)

			let newquestion2: userquestion = { QuestionString: "Add to xfrs if changed attributes?", ModalNumber: 5, Completed: false }
			AddQuestion(newquestion2)

		}

		if (errcount === 0) { NextStep(limit) }
	};


	const condstyle = (matchpair: string[]) => {
		let bkground: string = "white";
		if (matchpair[3] === "True" && matchpair[2] === "") { //err on mandatory field
			bkground = colorErr

		} else if (matchpair[2] === "") { //err on other field
			bkground = colorWarn
		}

		return ({ backgroundColor: bkground })
	};

	const FinishSetup = () => {

		//upload
		EmployeeUpload(empFile, params, headersList, schema, config, "t_uploadempdiv").then((d: any) => {
			if (d) {
				setParams({ ...params, batchkey: d.data, uploadsuccess: true, linkstring: "/employee/modelviewer/" + d.data, submittext: "Complete" })
			}
		})
	};

	const ChangeReport = (e: ChangeEvent<HTMLSelectElement>) => {
		let val = e.target.value
		if (val !== "Select Option:") {
			ChangeParameter("reporttype", val, 1)
			let newquestion: userquestion = { QuestionString: "Push Emp Names?", ModalNumber: 1, Completed: false }
			AddQuestion(newquestion)
			let newquestion2: userquestion = { QuestionString: "Overwrite Duplicates?", ModalNumber: 2, Completed: false }
			AddQuestion(newquestion2)
		}
	};

	const AddQuestion = (newquestion: userquestion) => {
		let qexists: number | boolean = IsInObject(userquestions, String(newquestion.ModalNumber), "ModalNumber")
		if (qexists === false) {
			let update: userquestion[] = userquestions
			update.push(newquestion)
			setUserQuestions(update)
		}
	}

	const HeaderDdown = (props: any) => {
		let val: number = props.val;

		return (
			<select onChange={(e: ChangeEvent<HTMLSelectElement>) => ChangeHeader(e, val)}>
				<option value="" key={-1}>
					Select Column
				</option>
				{sampleFields.map((val: samplefld, i: number) => {
					return (<option value={val.Value} key={i + "sample"} >
						{val.Value}
					</option>)
				})}
			</select>
		)
	};
	const ChangeHeader = (e: ChangeEvent<HTMLSelectElement>, i: number) => {
		let val = e.target.selectedOptions[0].attributes[0].value
		let newlist = [headersList[i][0], headersList[i][1], val, "True", headersList[i][4], "Y"];
		if (val = "") {
			newlist = [headersList[i][0], headersList[i][1], val, "True", headersList[i][4], ""]
		}
		setHeadersList({ ...headersList, [i]: newlist })

		if (!params.headermatchchange) {
			AddQuestion({ QuestionString: "Remember Selected Headers", ModalNumber: 3, Completed: false })
		}

	};

	const ModuleSel = () => {
		return (
			<div className="mt_main-overview">
				<div className="overviewcard" style={style[0]} onClick={_ => clickedopen('A')}>
					<div id="empupl_card1" className="overviewsubCard">
						<div className="overviewcard__icon">Select Report Type</div>
						<div className="overviewcard__info">{params.reporttype}</div>
					</div>
				</div>
				<div className="overviewcard" style={style[1]} onClick={_ => clickedopen('B')}>
					<div className="overviewsubCard">
						<div className="overviewcard__icon">Import Data</div>
						<div className="overviewcard__info">{params.isImported ? "Imported" : "Not Imported!"}</div>
					</div>
				</div>

				<div className="overviewcard" style={style[2]} onClick={_ => clickedopen('C')}>
					<div className="overviewsubCard">
						<div className="overviewcard__icon">Check Headers</div>
						<div className="overviewcard__info">
							{params.header_errcount > -1 ? "Errors:" + String(params.header_errcount) +
								" Warnings:" + String(params.header_warncount) : ""}
							{params.header_errcount > 0 ? "  No match for " + String(params.firsterrfield) : ""}</div>
					</div>
				</div>

				<div className="overviewcard" style={style[3]} onClick={_ => clickedopen('D')}>
					<div className="overviewsubCard">
						<div className="overviewcard__icon">Model Name</div>
						<div className="overviewcard__info">{params.modelname}</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div id="mt_canvas">

			<div className="mt_gridcontainer ndt_canvas">
				<aside className="mt_sidenav">
					<div id="mt_sidenavheader">Supplemental Options</div>
					<ul className="mt_sidenav__list">
						{userquestions.map((uq: userquestion, i: number) => {
							return <li key={i} className="mt_sidenav__list-item"
								onClick={_ => clickedopen(uq.ModalNumber)}
								style={uq.Completed ? { backgroundColor: "yellow", color: "black" } : { backgroundColor: "black", color: "#ffebcd" }}
							>
								{uq.QuestionString}
							</li>
						}
						)}
					</ul>
				</aside>
				<main>
					<div id="mt_main" className="ndt_header ndt_innerbox" >
						<div className="mt_mainheader__heading" style={{ 'color': 'white' }}>Schema: {CurrSchema.SchemaName}  </div>
						<div className="mt_mainheader__heading" style={{ 'color': 'white' }}> Fields Loaded {fields.length}</div>
						<div style={{ cursor: "pointer" }} onClick={_ => { setParams({ ...params, isrulemodalvisible: true }) }}>Check Ruleset for Upload</div>
					</div>
					{/* items */}
					<ModuleSel />
					<div>

						{params.isImported && params.modelname !== "" ?

							<div id="tmodelreadyform">
								<div id="tmodelreadyitms">
									<div id="tsubmitheader">All set?</div>
									<button id="tmodelsubmitbtn" onClick={() => FinishSetup()}>{params.submittext}</button>
								</div>
								<div id="t_uploadempdiv"></div>
							</div> :
							<div></div>
						}

						{/* when finished */}
						{params.uploadsuccess === true ?
							<div>
								<Link id="checkmodel_link" aria-current="page"
									to={params.linkstring} state={params.modelname} >
									View your model when completed
								</Link>
							</div> :
							<div>
							</div>
						}
					</div>
				</main>

			</div>



			{/* chart type modal */}
			<Modal show={isModalVisible['A']} >
				<div className="mt_modal">
					<form style={{ 'marginTop': '20px', 'textAlign': 'left' }}>
						<div className="mt_header">Select Data Type</div>
						<ReportTypeDropdown className="mt_reporttypesel" change={(e: ChangeEvent<HTMLSelectElement>) => ChangeReport(e)} />
					</form>

					<button className="mtclosemodalbtn" onClick={_ => clickedclose('A')}>Close</button>
				</div>
			</Modal>

			{/* upload fields modal */}
			<Modal show={isModalVisible['B']}>
				<div className="mt_modal">
					<div className="mt_header">Upload Data File</div>
					<form onSubmit={e => FileSubmit(e)}
						style={{ 'marginTop': '20px', 'textAlign': 'left' }}>
						<input type="file" className="mt_empfilebtn" name="reviewFile" onChange={(e: ChangeEvent<HTMLInputElement>) => ApplyFile(e)} />
						<button className="mt_empsubmitbtn" type="submit" >Upload Data</button>
						<div id="checkupload_div" style={{ height: "30px" }}>{""}</div>
					</form>
					<button className="mtclosemodalbtn" onClick={_ => clickedclose('B')}>Close</button>
				</div>
			</Modal>

			{/* headerscompare modal */}
			<Modal show={isModalVisible['C']} dialogClassName="modalwide">
				<div className="mt_modal">
					<div style={{ height: "500px", width: "590px" }}>
						<div className="mt_header">Submitted Fields Check</div>
						<div key={-1} className="t_head">
							<div className="t_headitem" style={{ width: "150px" }}> Field Name</div>
							<div className="t_headitem" style={{ width: "150px" }}> File header</div>
							<div className="t_headitem" style={{ width: "150px" }} >Required? </div>
							<div className="t_headitem" style={{ width: "150px" }}> Manual Select </div>
						</div>
						<div style={{ height: "350px", overflowY: "scroll" }}>
							{Object.keys(headersList).map((k: any, i: number) => {
								return (
									<div key={i} data-value={headersList[k][1]} id={"tfld_" + i} className="t_field" style={condstyle(headersList[k])}>
										<div className="t_fielditem" style={{ width: "150px" }} > {headersList[k][1]} </div>
										<div className="t_fielditem" style={{ width: "150px" }}> {headersList[k][2]} </div>
										<div className="t_fielditem" style={{ width: "150px" }}> {headersList[k][3]} </div>

										<div id={"tflddwn_" + i} className="t_fielditem" > <HeaderDdown val={i} /> </div>

									</div>
								)
							})}
						</div>
						<button className="ndt_btn1" style={{ marginTop: '10px', marginLeft: "20px", width: "200px" }}
							onClick={_ => ConfirmHeaders(3)}> Confirm Fields</button>
						<button className="mtclosemodalbtn" onClick={_ => clickedclose('C')}>Close</button>
					</div>
				</div>
			</Modal>

			{/* modelname modal */}
			<Modal show={isModalVisible['D']} >
				<div className="mt_modal">
					<div>
						<div className="mt_header">Model Name</div>
						<input type="text" name="modelname" onChange={e => ChangeParameter("modelname", e.target.value, 4)} />
					</div>
					<button className="mtclosemodalbtn" onClick={_ => clickedclose('D')}>Close</button>
				</div>
			</Modal>




			{/* secondary modals: */}
			<Modal show={isModalVisible[1]} >
				{/* any report selected */}
				<div className="mt_demoquestion mt_modal">
					Update employee Names using this file?
					<button className="mt_assumebtn" style={yesStyle[1]} onClick={_ => setParams({ ...params, nameoverwrite: true })}>Yes - these contain updated names</button>
					<button className="mt_assumebtn" style={noStyle[1]} onClick={_ => setParams({ ...params, nameoverwrite: false })}>No - leave as is</button>

					<button className="mtclosemodalbtn" onClick={_ => clickedclose(1)}>Close</button>
				</div>
			</Modal>

			<Modal show={isModalVisible[2]} >
				{/* changed any header dropdown */}
				<div className="mt_demoquestion mt_modal">
					Overwrite where duplicate entries are identified?
					<button className="mt_assumebtn" style={yesStyle[2]} onClick={_ => setParams({ ...params, overwriteentry: true })}>Yes - keep new entry</button>
					<button className="mt_assumebtn" style={noStyle[2]} onClick={_ => setParams({ ...params, overwriteentry: false })}>No - preserve old entry</button>

					<button className="mtclosemodalbtn" onClick={_ => clickedclose(2)}>Close</button>
				</div>
			</Modal>

			<Modal show={isModalVisible[3]} >
				{/* changed any header dropdown */}
				<div className="mt_demoquestion mt_modal">
					Remember Applied Headers?
					<button className="mt_assumebtn" style={yesStyle[3]} onClick={_ => setParams({ ...params, rememberheaders: true })}>Yes - remember these for next time</button>
					<button className="mt_assumebtn" style={noStyle[3]} onClick={_ => setParams({ ...params, rememberheaders: false })}>No - this is a one-off upload</button>
					<button className="mtclosemodalbtn" onClick={_ => clickedclose(3)}>Close</button>
				</div>
			</Modal>

			<Modal show={isModalVisible[4]} >
				{/* selected non-terms report */}
				<div className="mt_modal">
					<div className="mt_demoquestion ">
						Can this upload contain hired (new) employees?
						<button className="mt_assumebtn" style={yesStyle[4]} onClick={_ => setParams({ ...params, assumehire: true })}>Yes - assume hire if new employee</button>
						<button className="mt_assumebtn" style={noStyle[4]} onClick={_ => setParams({ ...params, assumehire: false })}>No - hires are uploaded separately (default)</button>

						{params.assumehire ?
							<div>Select Hire Date Field
								<GenericDropdown
									data={fields}
									classname=""
									keycol="Key"
									namecol="FieldName"
									change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, fields, params, setParams, "hiredatefield", "Key", "")}
									value={params.hiredatefield}
								/>
							</div>
							: <div></div>
						}
						<div>{params.hirefldwarn}</div>
					</div>

					<button className="mtclosemodalbtn" onClick={_ => clickedclose(4)}>Close</button>
				</div>
			</Modal>

			{/* Assume Transfer*/}
			<Modal show={isModalVisible[5]}>
				{/* selected non-terms report */}
				<div className="mt_modal">
					<div className="mt_demoquestion ">
						Should transfers be inferred for employee changes?
						<button className="mt_assumebtn" style={yesStyle[5]} onClick={_ => setParams({ ...params, assumexfr: true })}>Yes - assume xfr if new employee</button>
						<button className="mt_assumebtn" style={noStyle[5]} onClick={_ => setParams({ ...params, assumexfr: false })}>No - xfrs are uploaded separately (default)</button>

						{params.assumexfr ?
							<div>Select XFR Date Field
								<GenericDropdown
									data={fields}
									classname=""
									keycol="Key"
									namecol="FieldName"
									change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, fields, params, setParams, "xfrdatefield", "Key", "")}
								/>
							</div>
							: <div></div>
						}
						<div>{params.xfrfldwarn}</div>
					</div>

					<button className="mtclosemodalbtn" onClick={_ => clickedclose(5)}>Close</button>
				</div>
			</Modal>

			<Modal show={params.isrulemodalvisible}>
				{/* Rule Model for Upload */}
				<div style={{ padding: '5px' }} className="mt_demoquestion mt_modal">
					<div style={{ fontSize: "22px" }}>Exceptions Model for Upload</div>

					<div>
						{ruleModel?.ruleset.map((rs: Ruleset, i: number) => {
							if (rs.Field2 === "") {
								return (<div key={i} className="mt_ruleitem">Rule: {GetFieldName(fields, rs.Field1)}</div>)
							} else {
								return (<div key={i} className="mt_ruleitem">Rule: {GetFieldName(fields, rs.Field1)} - {GetFieldName(fields, rs.Field2)}</div>)
							}
						})}
						{ruleModel?.renames.map((rn: RenameSet, i: number) => {
							return (<div key={i} className="mt_ruleitem">Renames: {GetFieldName(fields, rn.FieldName)}</div>)
						})}

					</div>
					{ruleModel ?
						<div style={{ fontSize: "18px", padding: '5px', letterSpacing: '.02em', fontWeight: "bold" }}>
							<Link to={"/rulesets/manager/" + ruleModel.ModelKey}>Investigate</Link>
						</div>
						: <div></div>}

					<button className="mtclosemodalbtn" onClick={_ => setParams({ ...params, isrulemodalvisible: false })}>Close</button>
				</div>
			</Modal>

		</div>

	)

};

export default ModelTutorial;