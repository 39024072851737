
import { fielddeleteActionCreator } from "../../reducers/field_actions";
import AxiosUpload from "../AxiosUpload";

const FieldsDelete = (modelkey: string, fieldcategory: string, config: any, dispatcher: any, elemid: string) => {


    let retval = AxiosUpload({ "modelkey": modelkey, 'fieldcategory': fieldcategory }, 'api/fields/fielddelete', config, null, "")

    dispatcher(fielddeleteActionCreator([]))
    //dispatcher(fieldValdeleteActionCreator([]))

    let elem = document.getElementById(elemid);

    if (elem) { elem.innerHTML = "Deleted Successfully" }

    console.log("deleted fields data")
    return (retval)
};
export default FieldsDelete