
import AxiosUpload from '../AxiosUpload';
import { User, GroupSetValue, GroupSet } from '../../type';
import StringReformatforBackend from '../../functions/String Functions/StringReformatforBackend';


const GroupFieldUpload = async (groupset: GroupSet, schemakey: string, user: User,
    config: any, elementstring: string) => {

    let dataArray = new FormData();
    const grpdataencoded: string[] = []

    let groupvaldata: GroupSetValue[] = groupset.values

    groupvaldata.forEach((d: GroupSetValue) => {
        grpdataencoded.push(StringReformatforBackend(d.Groupvalue))
        grpdataencoded.push(StringReformatforBackend(d.Value))
    });

    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("modelkey", groupset.FieldName); //field key
    dataArray.append("handleothervalues", groupset.HandleOtherValues);
    dataArray.append("othergroupname", groupset.OtherGroupName);

    dataArray.append("groupdata", String(grpdataencoded));
    dataArray.append("schemakey", schemakey);
    dataArray.append("uploadvalues", String(true));

    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/groupsets/groupsetpost", config, elem, "")

    return ("Ok")
};

export default GroupFieldUpload;