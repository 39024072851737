import * as THREE from 'three';
import { useEffect } from 'react';
import {OrbitControls} from "three/examples/js/controls/OrbitControls"
//import {OrbitControls} from "./js/OrbitControls"

const Threetest = () => {
	
	let w = 700;
	let h = 700;
	let mesh
	let scene = new THREE.Scene();
	let camera = new THREE.PerspectiveCamera( 75, w/h, 0.1, 1000 );

	let renderer = new THREE.WebGLRenderer();
	
	let controls = new THREE.OrbitControls(camera, renderer.domElement);	

	renderer.setSize( w, h);

	const drawfunc = () => {

		scene.remove(mesh)

			let geometry = new THREE.CubeGeometry(4, 4, 4);
			let material = new THREE.MeshLambertMaterial({color: 0x126ea1})
			mesh = new THREE.Mesh(geometry, material) 

		 scene.add(mesh);
	  }

	useEffect(() => {
		
		document.getElementById("canvas1").appendChild(renderer.domElement);
		
		camera.position.z = 10
		let pointlight = new THREE.PointLight(0xffffff);
		pointlight.position.set(-4,4,2)
		scene.add(pointlight)

		let ambientlight = new THREE.AmbientLight(0xf1f1f1);
		scene.add(ambientlight)

		drawfunc();
		
		//iterative
		let animate = function () {
			mesh.rotation.y += .02

			requestAnimationFrame( animate );
			renderer.render( scene, camera );
		};

		animate();
	  }, []);


	  return (
		<div id="canvas1" style={{'height':'600px','width':'700px'}}>
		</div>
	  );
}

export default Threetest;