import { FormEvent } from "react";
import AxiosUpload from "../AxiosUpload";

const FieldsUploadFile = async (event: FormEvent<HTMLFormElement>, fieldsFile: any,
    modelname: string, schema: string, modelcategory: string, config: any, dispatcher: any, elementstring: string,) => {
    event.preventDefault()

    let dataArray = new FormData();
    dataArray.append("file", fieldsFile);
    dataArray.append("modelname", modelname);
    dataArray.append("schemakey", schema);
    dataArray.append("modelcategory", modelcategory); //only necessary 

    let elem: HTMLElement | null = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    let promise = AxiosUpload(dataArray, "api/fields/fieldpostall", config, elem, "")
    promise.then(d => {
        if (elem && d) {
            elem.innerHTML = String(d.data)
        }
    })
};

export default FieldsUploadFile;
