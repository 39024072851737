

const TableGroupDropdown = (props: any) => {
    //ref: metricsmanager, genericdatatable
    let change = props.change;
    let tablegroups = props.tablegroups;

    return (
        <div>
            {tablegroups && tablegroups.tables ? <div>
                <select className="ndt_dropdown" onChange={change}>
                    <option value={""} data-value={""} key={-1}>Group:</option>
                    {
                        Object.keys(tablegroups.tables).map((k: string, i: number) => {
                            return (<option value={k} data-value={k} key={i}>{k}</option>)
                        })
                    }
                </select>
            </div>
                : <div>Select Connection Model</div>}
        </div>
    )
};
export default TableGroupDropdown