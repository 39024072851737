import GenericDropdown from "../../../functions/Dropdown_Generic";
import { FilterModel, Metric, FieldMap, ConnectionModel } from "../../../type";
import { useState, ChangeEvent, useEffect, useContext } from 'react';
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import { StartupContext } from "../../../App";
import ReportTypeDropdown from "../../../functions/Dropdowns/ReportTypeDropdown";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import { Modal } from "react-bootstrap";
import { MetricInit } from "../../../InitTypes";
import GetFieldName from "../../../functions/GetFieldName";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import MeasurementDropdown from "../../../functions/Dropdowns/MeasurementDropdown";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import FieldsImport from "../../../data/Fields/Fields_Import";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import FilterData from "../../../functions/FilterData";
import TableGroupsImport from "../../../data/Tables/TableGroups_Import";
import IsInVector from "../../../functions/IsInVector";
import GetObjectfromArray from "../../../functions/Array Functions/GetObjectfromArray";
import "./css/MetricsManager.css"
import FieldDropdown from "../fields/subfunctions/FieldDropdown";
import StringTrim from "../../../functions/String Functions/StringTrim";
import GenericsUniqueImport from "../../../data/Generics/GenericsUnique_Import";
import AddRemovefromArray from "../../../functions/Array Functions/AddRemovefromArray";
import ConvertBackendArryString from "../../../functions/String Functions/ConvertBackendArryString";
import TableGroupDropdown from "../../../functions/ConnectionModel Functions/TableGroupDropdown";


const MetricsManager = () => {
    //aggregation type is null if no field, blank if field but not yet selected (cannot be blank)
    //measurement field/type must be set for all metrics (12.30.23)

    let { config, schema, user, dispatcher, paths, clickstyle } = useContext(StartupContext)

    interface keyparamsinit {
        setDenom: boolean;
        setAgg: boolean;
        storeddenom: string;
        storedaggfield: string;
        storedaggtype: string;
        aggfield_datatype: string; //values are lower case
        measurefield_datatype: string; //values are lower case
        measurefielddenom_datatype: string; //values are lower case
        issavevisible: boolean;
        isdeletevisible: boolean;
        refresh: number;
        tablegroup: string;
        modelcat_fltnum: string;
        modelcat_fltdenom: string;
        measurecopynumerator: boolean;
        simplefilternumvalues: string[]; //use instead of metric field
        simplefilterdenomvalues: string[]; //use instead of metric field
    }

    let [filtermodels, setFiltermodels] = useState<FilterModel[]>([])
    let [fieldsNonText, setFieldsNonText] = useState<FieldMap[]>([])

    let [tablegroups, setTablegroups] = useState<any | null>(null)
    let [connModels, setConnModels] = useState<ConnectionModel[]>([])
    let [selConnModel, setSelConnModel] = useState<ConnectionModel | null>(null)

    let [metrics, setMetrics] = useState<Metric[]>([])
    let [fields, setFields] = useState<FieldMap[]>([])
    let [tablefields, setTableFields] = useState<FieldMap[]>([])
    let [selTables, setSelTables] = useState<string[]>([])
    let [selMetric, setSelMetric] = useState<Metric | null>(null)
    let [keyparams, setKeyParams] = useState<keyparamsinit>({
        setDenom: false, setAgg: false, storeddenom: "", storedaggfield: "", storedaggtype: "",
        aggfield_datatype: "", measurefield_datatype: "", measurefielddenom_datatype: "", tablegroup: "",
        modelcat_fltnum: '', modelcat_fltdenom: '', simplefilternumvalues: [], simplefilterdenomvalues: [],
        issavevisible: false, isdeletevisible: false, measurecopynumerator: false, refresh: 0
    })
    let [mdlsforDel, setMdlsforDel] = useState<any[]>([])
    let [saveparams, setSaveParams] = useState<any>({ ready: false, missingval: '' })

    let [allfltvalsNum, setAllFltValsNum] = useState<any[]>([])
    let [allfltvalsDenom, setAllFltValsDenom] = useState<any[]>([])

    useEffect(() => {
        if (keyparams.refresh >= 100) {
            window.location.reload()
        }
    }, [keyparams.refresh])

    useEffect(() => {
        ImportAndSetStateGeneric(setFiltermodels, "*", schema, paths.filtersets.filtermodelview, user, config)
        ImportAndSetStateGeneric(setMetrics, "*", schema, paths.query.metricview, user, config, { workspacekey: null })
        FieldsImport("", schema, config, dispatcher, false).then(d => { setFields(d) })
        ImportAndSetStateGeneric(setConnModels, "", schema, paths.tables.connectionmodelview, user, config)
    }, [])


    useEffect(() => {
        //select different metric
        if (selMetric && selMetric.MetricKey !== "") {
            console.log(selMetric)
            let setdenom = false;
            let setagg = false;
            let storedaggtype = selMetric.AggregationType;
            let storedaggfield = selMetric.AggregateField;
            let storeddenom = selMetric.ReportTypeDenominator;

            if (selMetric.ReportTypeDenominator !== "None" && selMetric.ReportTypeDenominator !== "") {
                setdenom = true
            }
            if (selMetric.AggregateField !== "None" && selMetric.AggregateField !== null) {
                setagg = true
            }
            console.log(connModels)

            let cmdl = GetObjectfromArray(connModels, selMetric.ConnectionModelName, "ModelKey")
            console.log(cmdl)

            setKeyParams({
                ...keyparams, refresh: keyparams.refresh + 1, setDenom: setdenom, storeddenom: storeddenom,
                setAgg: setagg, storedaggfield: storedaggfield, storedaggtype: storedaggtype
            })

            setSelConnModel(cmdl)


        } else if (selMetric) {
            //new metric
            setSelConnModel(null)
            setKeyParams({ ...keyparams, tablegroup: '' })
        }

    }, [selMetric?.MetricKey])

    useEffect(() => {
        if (selConnModel && selMetric) {
            console.log(selConnModel)
            TableGroupsImport(selConnModel?.ModelKey, schema, paths.tables.connectionnetwork, user, config, dispatcher, false).then(d => {
                setTablegroups({ tables: d })
            })
            setSelMetric({ ...selMetric, ConnectionModelName: selConnModel.ModelKey })

            setKeyParams({
                ...keyparams, tablegroup: '', simplefilternumvalues: ConvertBackendArryString(selMetric.SimpleFilterNumValues),
                simplefilterdenomvalues: ConvertBackendArryString(selMetric.SimpleFilterDenomValues)
            })
        } else {
            setKeyParams({ ...keyparams, tablegroup: '' })
        }


    }, [selConnModel])


    useEffect(() => {
        if (tablegroups && tablegroups.tables && keyparams.tablegroup !== "") {
            let tbls = tablegroups.tables[keyparams.tablegroup]
            let tblgrpflds = FilterData(fields, "ModelCategory", tbls)
            setTableFields(tblgrpflds)
            setSelTables(tbls)
        }
    }, [tablegroups, keyparams.tablegroup])

    useEffect(() => {
        let fn: FieldMap[] = []
        tablefields.forEach((fld: FieldMap) => {
            if (fld.IsKeyField) {
                fn.push({ ...fld, FieldName: fld.FieldName + ' (Key)' })
            } else {
                fn.push(fld)
            }
        })

        setFieldsNonText(fn);
    }, [tablefields])

    useEffect(() => {
        if (selMetric) {
            if (keyparams.setDenom) {
                setSelMetric({ ...selMetric, ReportTypeDenominator: keyparams.storeddenom })
            } else {
                setSelMetric({ ...selMetric, ReportTypeDenominator: "None" })
            }
        }
    }, [keyparams.setDenom])

    useEffect(() => {
        //for re-showing aggregation
        if (selMetric) {
            if (keyparams.setAgg) {
                setSelMetric({ ...selMetric, AggregationType: keyparams.storedaggtype, AggregateField: keyparams.storedaggfield })
            } else {
                setSelMetric({ ...selMetric, AggregationType: "None", AggregateField: "None" })
            }
        }
    }, [keyparams.setAgg])


    useEffect(() => {
        if (selMetric) {
            let tablegroup = ""
            let tbl = selMetric.Table
            Object.keys(tablegroups.tables).map((k: string, i: number) => {
                if (IsInVector(tablegroups.tables[k], tbl)) {
                    tablegroup = k
                }
            })
            setKeyParams({ ...keyparams, tablegroup: tablegroup })
        }
    }, [tablegroups])

    useEffect(() => {
        if (selMetric) {
            let oktosave = true;
            let missingval = "";
            if (selMetric.MetricKey === "") {
                for (const [k, value] of Object.entries(selMetric)) {
                    let check = true
                    //exceptions
                    if (k === "MetricKey" || k === "SchemaName" || k === "Notes" || k === "DisplayName" || k === "WorkspaceName" || k.substring(0, 6) == "Simple") {
                        check = false
                    }
                    if (!keyparams.setDenom && (k === "MeasurementFieldDenom" || k === "MeasurementDenom" || k === "TableDenom")) {
                        check = false
                    }
                    if (value === "" && check) {
                        oktosave = false;
                        missingval = k;
                    }
                }
            }
            setSaveParams({ ready: oktosave, missingval: missingval })
        }
    }, [selMetric, keyparams.refresh])

    useEffect(() => {
        if (selMetric && selMetric.Table !== "EmployeeState") {
            setSelMetric({ ...selMetric, ReportTypeNumerator: "Demographic", ReportTypeDenominator: "None" })
        }
    }, [selMetric?.Table])

    useEffect(() => {
        if (keyparams.modelcat_fltnum != "" && selMetric) {
            GenericsUniqueImport([selMetric?.SimpleFilterNumField], schema, fields, config, dispatcher).then(d => {
                console.log(d)
                if (d.length > 0) {
                    setAllFltValsNum(d[0])
                }
            })
        }
    }, [selMetric?.SimpleFilterNumField])

    useEffect(() => {
        if (keyparams.modelcat_fltdenom != "" && selMetric) {
            GenericsUniqueImport([selMetric?.SimpleFilterDenomField], schema, fields, config, dispatcher).then(d => {
                if (d.length > 0) {
                    setAllFltValsDenom(d[0])
                }
            })
        }
    }, [selMetric?.SimpleFilterDenomField])

    useEffect(() => {
        if (keyparams.measurecopynumerator && selMetric) {
            setSelMetric({ ...selMetric, MeasurementDenom: selMetric.Measurement, MeasurementFieldDenom: selMetric.MeasurementField, TableDenom: selMetric.Table })
        }
    }, [keyparams.measurecopynumerator, selMetric?.Measurement, selMetric?.MeasurementFieldDenom])

    //------backend --------------


    const SaveMetric = () => {
        if (selMetric) {
            let metr: Metric = { ...selMetric, SimpleFilterNumValues: keyparams.simplefilternumvalues, SimpleFilterDenomValues: keyparams.simplefilterdenomvalues }
            GenericBackendCall("*", schema, paths.query.metricpost, user, config, metr, "mm_divupl").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }

    const DeleteMetric = () => {
        if (selMetric) {
            GenericBackendCall(selMetric?.MetricKey, schema, paths.query.metricdelete, user, config, selMetric, "mm_divdel").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }
    const GetRelatedModels = () => {
        if (selMetric) {
            let prm = GenericBackendCall("", schema, paths.generics.generickeynamemap, user, config,
                { model: "QueryModel", keyfield: "ModelKey", namefield: "ModelName", filterfield: "Metrics", filtervalue: selMetric.MetricKey })
            prm.then(d => { setMdlsforDel(SerializeDataFrame(d)) })
        }
    }
    //-------------------------

    const ChangeMetricFld = (e: ChangeEvent<HTMLSelectElement>, onfield: string, ontypefld: string, setsel: boolean = true) => {
        let val = e.target.selectedOptions[0].attributes[1].value

        if (selMetric && setsel) { setSelMetric({ ...selMetric, [onfield]: val }) }

        let dtype = GetFieldName(fields, val, "Key", "DataType")
        setKeyParams({ ...keyparams, [ontypefld]: dtype })
    }

    const ChangeMetric = (e: any, onfield: string, onfieldkeyparams: string) => {
        if (selMetric) { setSelMetric({ ...selMetric, [onfield]: e.target.selectedOptions[0].attributes[1].value }) }
        if (onfieldkeyparams !== "") {
            setKeyParams({
                ...keyparams,
                [onfieldkeyparams]: e.target.selectedOptions[0].attributes[1].value
            })
        }
    }



    const AssignFieldfromTable = (e: ChangeEvent<HTMLSelectElement>, onfield: string, keyfield: string) => {
        //table string, get primary field
        if (selMetric) {
            let tbl = selMetric?.Table
            let secondfld = ""
            if (onfield === "MeasurementField") {
                tbl = e.target.selectedOptions[0].attributes[0].value
                secondfld = "Table"
            } else if (onfield === "MeasurementFieldDenom") {
                tbl = e.target.selectedOptions[0].attributes[0].value
                secondfld = "TableDenom"
            }

            let fltfields: FieldMap[] = FilterData(fields, "ModelCategory", e.target.selectedOptions[0].attributes[0].value, false)
            let keyflds: FieldMap[] = FilterData(fltfields, "IsKeyField", "true")

            console.log(fltfields)
            console.log(keyflds)

            if (selMetric) {
                if (secondfld !== "") {
                    setSelMetric({ ...selMetric, [onfield]: keyflds[0].Key, [secondfld]: tbl })
                } else {
                    setSelMetric({ ...selMetric, [onfield]: '' })
                }
            }
        }
        ChangeMetricFld(e, onfield, keyfield, false)
    }

    const Block_Numerator = () => {
        return (
            <div className="ndt_gridbox metr_box" >
                {selMetric ? <div>
                    <div className="ndt_title3">Metric Numerator</div>

                    {keyparams.tablegroup !== "EmployeeState" ?
                        <div>
                        </div> :
                        <div>
                            <div>Report Type: {selMetric?.ReportTypeNumerator}</div>
                            <ReportTypeDropdown className="metr_ddown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeMetric(e, "ReportTypeNumerator", "") }}
                            />
                        </div>
                    }
                    <div>Filter Model: {capitalize(GetObjectField(selMetric?.FilterModelNumerator, filtermodels, "ModelKey", "ModelName"))}</div>
                    <GenericDropdown
                        data={filtermodels}
                        keycol="ModelKey"
                        namecol="ModelName"
                        className="metr_ddown"
                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, filtermodels, selMetric, setSelMetric, "FilterModelNumerator", "ModelKey", "None")}
                        promptstring="Select Filter Model"
                        includeDefault={true}
                        defaultstring="None"
                    />
                </div> : <div></div>}
            </div>
        )
    }
    const Block_Denominator = () => {
        return (
            <div className="ndt_gridbox metr_box">
                {selMetric ?
                    <div>
                        <div className="ndt_title3">Metric Denominator</div>

                        {keyparams.tablegroup !== "EmployeeState" ?
                            <div>
                            </div> :
                            <div>
                                <div>Report Type: {selMetric?.ReportTypeDenominator}</div>
                                <ReportTypeDropdown className="metr_ddown"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeMetric(e, "ReportTypeDenominator", "") }}
                                />
                            </div>
                        }
                        <div>Filter Model: {capitalize(GetObjectField(selMetric?.FilterModelDenominator, filtermodels, "ModelKey", "ModelName"))}</div>
                        <GenericDropdown
                            data={filtermodels}
                            keycol="ModelKey"
                            namecol="ModelName"
                            className="metr_ddown"
                            change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, filtermodels, selMetric, setSelMetric, "FilterModelDenominator", "ModelKey", "None")}
                            promptstring="Select Filter Model"
                            includeDefault={true}
                            defaultstring="None"
                        />
                    </div>
                    : <div></div>}
            </div>
        )
    }
    const Block_Aggregation = () => {
        return (
            <div className="ndt_gridbox metr_box" >
                {selMetric ?
                    <div>
                        <div>
                            <div className="ndt_title3">Aggregation</div>

                            <div>Aggregate Field: {capitalize(GetFieldName(fields, selMetric?.AggregateField, "Key", "FieldName"))}</div>

                            <GenericDropdown
                                data={tablefields}
                                keycol="Key"
                                namecol="FieldName"
                                className="ndt_dropdown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                    ChangeParamSelectorbyIndex(e, fields, selMetric, setSelMetric, "AggregateField", "Key", "None")
                                    ChangeMetricFld(e, "AggregateField", "aggfield_datatype")
                                }}
                                promptstring="Select Aggregate Field (optional)"
                                includeDefault={true}
                                defaultstring="None"
                            />

                            <MeasurementDropdown
                                className="metr_ddown"
                                includetypes={[keyparams.aggfield_datatype]}
                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                    ChangeMetric(e, "AggregationType", "storedaggfield")
                                }} />

                        </div>
                        <div style={{ minWidth: "280px", marginLeft: "40px", marginTop: "350px" }}>
                            <div>Agg Measurement: {capitalize(selMetric?.AggregationType)}</div>
                            <div style={{ display: "flex" }}>

                                {keyparams.aggfield_datatype !== "Text" ?

                                    <MeasurementDropdown
                                        className="ndt_dropdown"
                                        includetypes={[keyparams.aggfield_datatype]}
                                        includemixed={keyparams.setDenom}
                                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeMetric(e, "AggregationType", "storedaggtype") }} />
                                    : <div>
                                        Count on (table):
                                        <div>
                                            <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => { AssignFieldfromTable(e, "AggregationField", "aggfield_datatype") }}>
                                                <option value="" data-value={""}>Select Table</option>
                                                {selTables.map((tbl: string, i: number) => {
                                                    return (<option key={i} value={tbl} data-value={tbl}>{tbl}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    : <div></div>}
            </div>
        )
    }

    const Block_Measurement = () => {
        return (
            <div className="ndt_gridbox metr_box" style={{ minWidth: "600px" }}>
                {selMetric ?
                    <div style={{ overflow: "hidden", display: "flex" }}>
                        <div style={{ minWidth: "250px", width: "350px" }}>
                            <div style={{ fontSize: "20px" }}>Set Core Measurement Type: {selMetric?.Measurement} </div>


                            <div style={{ marginTop: "15px" }}>
                                <div>Table: {selMetric.Table}</div>
                                <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => { AssignFieldfromTable(e, "MeasurementField", "measurefield_datatype") }}>
                                    <option value="" data-value="">Select Table</option>
                                    {selTables.map((tbl: string, i: number) => {
                                        return (<option key={i} value={tbl} data-value={tbl}>{tbl}</option>)
                                    })}
                                </select>
                            </div>

                        </div>
                        {selMetric.Table !== "" ?
                            <div>
                                <div>Measurement On Field: {capitalize(GetFieldName(fields, selMetric?.MeasurementField, "Key", "FieldName"))}</div>
                                <GenericDropdown
                                    data={fieldsNonText}
                                    keycol="Key"
                                    namecol="FieldName"
                                    className="metr_ddown"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                                        ChangeParamSelectorbyIndex(e, fieldsNonText, selMetric, setSelMetric, "MeasurementField", "Key", "None")
                                        ChangeMetricFld(e, "MeasurementField", "measurefield_datatype")
                                    }}
                                    promptstring="Select Measurement Field"
                                    filterfield="ModelCategory"
                                    filtervalue={selMetric.Table}
                                />
                                <br />
                                <div>Measurement Type: {selMetric?.Measurement}</div>
                                <MeasurementDropdown
                                    className="metr_ddown"
                                    includetypes={[keyparams.measurefield_datatype]}
                                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                                        ChangeMetric(e, "Measurement", "")
                                    }} />

                            </div>
                            : <div></div>}

                    </div> : <div></div>}
            </div>
        )
    }

    const Block_MeasurementDenom = () => {
        return (
            <div className="ndt_gridbox metr_box" style={{ minWidth: "600px" }}>
                <div>
                    <button className="ndt_btn1"
                        onClick={_ => { setKeyParams({ ...keyparams, measurecopynumerator: !keyparams.measurecopynumerator }) }}
                        style={keyparams.measurecopynumerator ? clickstyle.btnSelected : clickstyle.btnUnselected}
                    >Same as Numerator </button>
                </div>
                {keyparams.measurecopynumerator ? <div>
                    <div>Measurement: {selMetric?.Measurement}</div>
                    {selMetric ?
                        <div>Measurement Field: {capitalize(GetFieldName(fields, selMetric?.MeasurementField, "Key", "FieldName"))}</div>
                        : <div></div>
                    }
                </div> :
                    <div>
                        {selMetric ?
                            <div style={{ overflow: "hidden", display: "flex" }}>
                                <div style={{ minWidth: "250px", width: "350px" }}>
                                    <div style={{ fontSize: "20px" }}>Set Denom Measurement Type: {selMetric?.Measurement} </div>


                                    <div style={{ marginTop: "15px" }}>
                                        <div>Table: {selMetric.TableDenom}</div>
                                        <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => { AssignFieldfromTable(e, "MeasurementFieldDenom", "measurefielddenom_datatype") }}>
                                            <option value="" data-value="">Select Table</option>
                                            {selTables.map((tbl: string, i: number) => {
                                                return (<option key={i} value={tbl} data-value={tbl}>{tbl}</option>)
                                            })}
                                        </select>
                                    </div>

                                </div>
                                {selMetric.Table !== "" ?
                                    <div>
                                        <div>Measurement On Field: {capitalize(GetFieldName(fields, selMetric?.MeasurementFieldDenom, "Key", "FieldName"))}</div>
                                        <GenericDropdown
                                            data={fieldsNonText}
                                            keycol="Key"
                                            namecol="FieldName"
                                            className="metr_ddown"
                                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                                ChangeParamSelectorbyIndex(e, fieldsNonText, selMetric, setSelMetric, "MeasurementFieldDenom", "Key", "None")
                                                ChangeMetricFld(e, "MeasurementFieldDenom", "measurefielddenom_datatype")
                                            }}
                                            promptstring="Select Measurement Field"
                                            filterfield="ModelCategory"
                                            filtervalue={selMetric.Table}
                                        />
                                        <br />
                                        <div>Measurement Type: {selMetric?.MeasurementDenom}</div>
                                        <MeasurementDropdown
                                            className="metr_ddown"
                                            includetypes={[keyparams.measurefielddenom_datatype]}
                                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                                ChangeMetric(e, "MeasurementDenom", "")
                                            }} />

                                    </div>
                                    : <div></div>}

                            </div> : <div></div>}
                    </div>
                }
            </div>
        )
    }

    const Block_FilterNum = () => {
        return (
            <div className="ndt_gridbox metr_box" >
                <div style={{ fontSize: "20px" }}>Simple Filter Numerator (optional)</div>
                <div>

                    <div style={{ display: "flex" }}>
                        <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setKeyParams({ ...keyparams, modelcat_fltnum: e.target.selectedOptions[0].attributes[1].value })
                        }}>
                            <option value="" data-value={""}>Select Table</option>
                            {selTables.map((tbl: string, i: number) => {
                                return (<option key={i} value={tbl} data-value={tbl}>{tbl}</option>)
                            })}
                        </select>

                        <FieldDropdown
                            className="ndt_dropdown"
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                if (selMetric) {
                                    setSelMetric({ ...selMetric, SimpleFilterNumField: e.target.selectedOptions[0].attributes[0].value })
                                    setKeyParams({ ...keyparams, simplefilternumvalues: [] })
                                }
                            }}
                            includecategory={true}
                            filterfield={"ModelCategory"}
                            filtervalue={keyparams.modelcat_fltnum}
                        />

                    </div>
                    {selMetric?.SimpleFilterNumField !== "" ?
                        <div style={{ width: '350px' }}>
                            <div style={{ fontSize: "18px" }}>Filter on Values:</div>
                            <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setKeyParams({ ...keyparams, simplefilternumvalues: AddRemovefromArray(keyparams.simplefilternumvalues, e.target.selectedOptions[0].attributes[0].value) })
                            }}>
                                <option value="">Select Value(s)</option>
                                {allfltvalsNum.map((itm: any, j: number) => {
                                    return (<option key={j} value={itm}>{StringTrim(String(itm), 120)}</option>)
                                })}
                            </select>
                            <div>{StringTrim(String(keyparams.simplefilternumvalues), 120)}</div>
                        </div>
                        : <div></div>}
                </div>
            </div>
        )
    }
    const Block_FilterDenom = () => {
        return (
            <div className="ndt_gridbox metr_box" >
                <div style={{ fontSize: "20px" }}>Simple Filter Denominator (optional)</div>
                <div>

                    <div style={{ display: "flex" }}>
                        <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setKeyParams({ ...keyparams, modelcat_fltdenom: e.target.selectedOptions[0].attributes[1].value })
                        }}>
                            <option value="" data-value={""}>Select Table</option>
                            {selTables.map((tbl: string, i: number) => {
                                return (<option key={i} value={tbl} data-value={tbl}>{tbl}</option>)
                            })}
                        </select>

                        <FieldDropdown
                            className="ndt_dropdown"
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                if (selMetric) {
                                    setSelMetric({ ...selMetric, SimpleFilterDenomField: e.target.selectedOptions[0].attributes[0].value })
                                    setKeyParams({ ...keyparams, simplefilterdenomvalues: [] })
                                }
                            }}
                            includecategory={true}
                            filterfield={"ModelCategory"}
                            filtervalue={keyparams.modelcat_fltdenom}
                        />

                    </div>
                    {selMetric?.SimpleFilterDenomField !== "" ?
                        <div style={{ width: '350px' }}>
                            <div style={{ fontSize: "18px" }}>Filter on Values:</div>
                            <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setKeyParams({ ...keyparams, simplefilterdenomvalues: AddRemovefromArray(keyparams.simplefilterdenomvalues, e.target.selectedOptions[0].attributes[0].value) })
                            }}>
                                <option value="">Select Value(s)</option>
                                {allfltvalsDenom.map((itm: any, j: number) => {
                                    return (<option key={j} value={itm}>{StringTrim(String(itm), 120)}</option>)
                                })}
                            </select>
                            <div>{StringTrim(String(keyparams.simplefilterdenomvalues), 120)}</div>
                        </div>
                        : <div></div>}
                </div>
            </div>
        )
    }
    return (<div id="metr_canvas" className="ndt_canvas">
        <div className="ndt_title2">Metrics Manager</div>
        <div id="metr_header" className="ndt_innerbox">
            <div id="metr_subheader">
                <div>
                    <div style={{ fontSize: '22px', minWidth: '350px' }}>Existing Metrics</div>
                    <GenericDropdown
                        data={metrics}
                        keycol="MetricKey"
                        namecol="MetricName"
                        divID="metr_metddown"
                        className="metr_ddown"
                        change={(e: ChangeEvent<HTMLSelectElement>) =>
                            ChangeSelectorbyIndex(e, metrics, setSelMetric, "", { ...MetricInit[0], Table: "", ReportTypeDenominator: "None", FilterModelDenominator: "None", AggregateField: "None", AggregationType: "None" })}
                        promptstring="Select Metric"
                        includeDefault={true}
                        defaultstring="New Metric"
                    />

                    {selMetric ?
                        <div style={{ minWidth: "500px" }}>
                            <div>Metric Name:</div>
                            {selMetric?.MetricKey !== "" ?
                                <div>
                                    {selMetric.MetricName}
                                </div> : <div>
                                    <input type="text" placeholder={selMetric?.MetricName} onChange={(e: ChangeEvent<HTMLInputElement>) => { if (selMetric) { setSelMetric({ ...selMetric, MetricName: e.target.value }) } }} />
                                </div>}
                        </div>
                        : <div></div>}
                </div>
                {selMetric ?
                    <div style={{ minWidth: '400px', overflow: "visible" }}>
                        <div>Connection Model: {selConnModel?.ModelName}</div>
                        <div>
                            {selMetric?.MetricKey === "" ?
                                <GenericDropdown
                                    data={connModels}
                                    keycol="ModelKey"
                                    namecol="ModelName"
                                    className="metr_ddown"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, connModels, setSelConnModel, "") }}
                                    promptstring="Select Connection Model"
                                />
                                : <div></div>}
                            {selConnModel && tablegroups && tablegroups.tables ?
                                <div>
                                    <div>Table Group: {keyparams.tablegroup}</div>
                                    {selMetric?.MetricKey === "" ?
                                        <TableGroupDropdown
                                            tablegroups={tablegroups}
                                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                                setKeyParams({ ...keyparams, tablegroup: e.target.selectedOptions[0].attributes[0].value })
                                            }}
                                        />
                                        : <div></div>}
                                </div>
                                : <div></div>}
                        </div>
                    </div>

                    : <div></div>}

                {selMetric && selConnModel && keyparams.tablegroup !== "" ?
                    <div style={{ marginTop: "15px", marginLeft: 'auto' }}>
                        <button className="ndt_btn1" style={{ width: "220px" }} onClick={_ => setKeyParams({ ...keyparams, setDenom: !keyparams.setDenom, storeddenom: String(selMetric?.ReportTypeDenominator) })}>{keyparams.setDenom ? "Remove" : "Add"} Denominator</button>
                        <button className="ndt_btn1" style={{ width: "220px" }} onClick={_ => setKeyParams({ ...keyparams, setAgg: !keyparams.setAgg })}>{keyparams.setAgg ? "Remove" : "Add"} Aggregation</button>
                        <button className="ndt_btn1" style={{ width: "220px" }} onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: true })}>Delete Metric</button>
                    </div>
                    : <div></div>}

            </div>


        </div>

        {
            selMetric && selConnModel && keyparams.tablegroup !== "" ?
                <div>
                    <div id="metr_main">
                        <div style={{ display: "flex" }}>
                            <Block_Numerator />
                            <Block_FilterNum />
                            <Block_Measurement />
                        </div>
                        <div >
                            {keyparams.setDenom ?
                                <div style={{ display: "flex", }}>
                                    <Block_Denominator />
                                    <Block_FilterDenom />
                                    <Block_MeasurementDenom />
                                </div>
                                : <div></div>}

                        </div>
                        <div style={{ display: "flex" }}>

                            {keyparams.setAgg ?
                                <Block_Aggregation />
                                : <div></div>}


                        </div>
                    </div>
                    <div className="ndt_innerbox" style={{ marginTop: '15px', width: "60%", minWidth: "400px", overflow: "hidden", display: "flex" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: "17px" }}>Notes on this metric:</div>
                            <div>{selMetric.Notes}</div>
                        </div>

                        {saveparams.ready ?
                            <div id="metr_savebox" className="ndt_innerbox" style={{ fontSize: "18px", width: "400px", padding: '10px' }}>
                                <div>Save this metric?</div>
                                <button className="ndt_btn1" onClick={_ => setKeyParams({ ...keyparams, issavevisible: true })}>Save</button>
                            </div>
                            : <div>
                                <div>Missing:</div>
                                <div>{saveparams.missingval}</div>
                            </div>}
                    </div>
                </div> : <div></div>
        }


        <Modal show={keyparams.issavevisible}>
            <div className="ndt_modal">
                <div>Save Metric {selMetric?.MetricName}</div>
                <div>Display Name</div>
                <input type="text" placeholder={selMetric?.MetricName} onChange={(e: ChangeEvent<HTMLInputElement>) => { if (selMetric) { setSelMetric({ ...selMetric, DisplayName: e.target.value }) } }} />

                <div>
                    <div>Notes</div>
                    <textarea style={{ width: "70%" }} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { if (selMetric) { setSelMetric({ ...selMetric, Notes: e.target.value }) } }} />
                </div>
                <div>
                    <button className="ndt_btn1" style={{ width: "200px" }} onClick={_ => SaveMetric()}>Save</button>
                </div>
                <div id="mm_divupl"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdeletevisible}>
            <div className="ndt_modal" style={{ height: "460px" }}>
                <div style={{ height: "400px" }}>
                    <div className="ndt_modaltitle">Delete Metric {selMetric?.MetricName}</div>
                    <div>
                        <div style={{ marginRight: "5px" }}>Note: this will delete all associated models !</div>
                        <button className="ndt_btn3" onClick={_ => { GetRelatedModels() }}>Check</button>
                    </div>
                    <div style={{ height: '200px', margin: "5px", background: "black", border: "1px solid grey" }} id="mm_mdlsbox">
                        {mdlsforDel.map((mdl: any, i: number) => {
                            return (<div key={i}>{mdl.ModelName}</div>)
                        })}
                    </div>
                    <br />
                    <button className="ndt_btn2" style={{ width: "150px" }} onClick={_ => DeleteMetric()}>Delete</button>
                    <div id="mm_divdel"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal >

    </div >)
};
export default MetricsManager

function capitalize(txt: string | null) {
    if (txt || txt === "") {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    } else {
        return "None"
    }
}