import { useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import { Metric, FieldMap, RuleModel, QueryModel, FilterModel, Dashboard, queryparams, QueryRunDate, ColorModel, QueryVisuals } from "../../../type";
import { useEffect, useState, MouseEvent, ChangeEvent, FormEvent, useContext } from "react";
import { Modal } from 'react-bootstrap';
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ModalRuleManager from "../rulesets/subfunctions/ModalRuleManager";
import GetFieldName from "../../../functions/GetFieldName";
import { SwatchesPicker } from 'react-color';
import QueryModelDropdown from "./subfunctions/QueryModelDropdown";
import { StartupContext } from "../../../App"
import DashboardDropdown from "../dashboards/subfunctions/DashboardDropdown";
//import SampleGraph from "./subfunctions/SampleGraph";
import SampleGraph from "../../../charts/sample/SampleGraph";
import QueryModelNameImport from "../../../data/Query/QueryModelName_Import";
import QueryModelUpload from "../../../data/Query/QueryModel_Upload";
import DateString from "../../../functions/Date Functions/DateString";
import ImportAndSetState from "../../../functions/Import/ImportAndSetState";
import RuleModelImport from "../../../data/Rulesets/RuleModel_Import";
import FilterModelImport from "../../../data/Filtersets/FilterModel_Import";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import ChangeElementCollectionAttr from "../../../functions/ElementSelect/ChangeElementCollectionAttr";
import CreateChart from "../../../charts/CreateChart";
import IsInVector from "../../../functions/IsInVector";
import QueryModel_to_Params from '../../../functions/QueryFunctions/QueryModel_to_Params';
import QueryModelImport from "../../../data/Query/QueryModel_Import";
import { queryparamsInit, queryvisInit, QueryVisualsInit } from "../../../InitTypes";
import ReportTypeDropdown from "../../../functions/Dropdowns/ReportTypeDropdown";
import PeriodDropdown from "../../../functions/Dropdowns/PeriodDropdown";
import { DateSpread_DeterminefromParams } from "../../../functions/QueryFunctions/DateSpread_Determine";
//import ThreeDBarGraph_Prepare from "../../../charts/ThreeDBarGraph_Prepare";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import './css/GraphEditor.css';
import ChartIcon from "../../../charts/ChartIcon";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";

const GraphEditor = () => {

    const { config, dispatcher, schema, user, paths } = useContext(StartupContext)

    const fields: FieldMap[] = useSelector((state: any) => state.fieldmapping);
    //const Query: QueryModel[] = useSelector((state: any) => state.querymodels);
    const [fModels, setFModels] = useState<FilterModel[]>([])
    const [rModels, setRModels] = useState<RuleModel[]>([])
    const [refreshimport, setRefreshImport] = useState<number>(0)

    const [qryvis, setQryvis] = useState<QueryVisuals>(QueryVisualsInit[0])

    const [queries, setQueries] = useState<QueryModel[] | null>(null)
    const [workingQuery, setWorkingQuery] = useState<QueryModel | null>(null)
    const [workingColorModel, setWorkingColorModel] = useState<ColorModel | null>(null)
    const [existingKey, setExistingKey] = useState<string>("") //store existing querymodel key for saving update
    const [topelem, setTopelem] = useState<string>("");
    const [dateSpread, setDateSpread] = useState<string>("");
    const [ruleModel, setRuleModel] = useState<RuleModel | null>(null);
    const [filterModel, setFilterModel] = useState<FilterModel | null>(null);
    const showparams = ["modelname", "dashboardkey", "chartType", "reportType",
        "xfieldname", "groupfield", "ruleModelName", "filterModelName",
        "endDate", "fieldBand", "period", "nperiods",
        "labelsonoff", "colorGradientType", "measure"];
    //const [tblmappings, setTblMappings] = useState(initmappings)
    //const [colors, setColors] = useState<any>({ 0: '#49ab1b', 1: '#5de5de', 2: '' });
    const [vis_states, setVis_States] = useState<any>({})
    const [params, setParams] = useState<queryparams>(queryparamsInit);
    const [paramsUser, setparamsUser] = useState<queryparams>(queryparamsInit);
    const [metrics, setMetrics] = useState<Metric[]>([])

    const [dashboards, setDashboards] = useState<Dashboard[]>([])
    const [anti_colorGradientType, setAnti_colorGradientType] = useState("discrete");

    const [colorIdx, setColorIdx] = useState<number>(0);
    const [isSample, setIsSample] = useState<boolean>(true);

    let [isModalVisible, setIsModalVisible] = useState({
        0: false, 1: false, 2: false, 3: false, 4: false,
        5: false, 6: false, 7: false, 8: false, 9: false,
        10: false, 11: false, 12: false, 13: false, 14: false,
        15: false, 16: false, 17: false,
    });
    const clickedopen = (val: number) => { setIsModalVisible({ ...isModalVisible, [val]: true }) };
    const clickedclose = (val: number) => { setIsModalVisible({ ...isModalVisible, [val]: false }) };

    const changeParameter = (par: string, str: string | number) => {
        setParams({ ...params, [par]: str })
        setparamsUser({ ...paramsUser, [par]: str })
    };

    useEffect(() => {
        ImportAndSetStateGeneric(setMetrics, "", schema, paths.query.metricview, user, config, {})
        ImportAndSetStateGeneric(setDashboards, "", schema, paths.dashboard.dashview, user, config, { permission: "read/write" })
        ImportAndSetState(QueryModelNameImport, setQueries, "*", schema, config, dispatcher, false);
        ImportAndSetState(RuleModelImport, setRModels, "*", schema, config, dispatcher, false);
        ImportAndSetState(FilterModelImport, setFModels, "", schema, config, dispatcher, false)
    }, []);

    useEffect(() => {
        if (params.newparamset) {
            setParams({ ...params, newparamset: false })
        }
    }, [params])

    useEffect(() => {
        PreCreateChart()
    }, [isSample, params.showdefault])


    useEffect(() => {
        setparamsUser({ ...paramsUser, "xfieldname": GetFieldName(fields, params.xfieldname, "Key", "FieldName") })
    }, [params.xfieldname])
    useEffect(() => {
        setparamsUser({ ...paramsUser, "groupfield": GetFieldName(fields, params.groupfield, "Key", "FieldName") })
    }, [params.groupfield])


    useEffect(() => {
        if (ruleModel) {
            setParams({ ...params, "ruleModelKey": ruleModel.ModelKey, "ruleModelName": ruleModel.ModelName })
            setparamsUser({ ...paramsUser, "ruleModelKey": ruleModel.ModelKey, "ruleModelName": ruleModel.ModelName })
        }
    }, [ruleModel]);

    useEffect(() => {
        if (filterModel) {
            setParams({ ...params, "filterModelKey": filterModel.ModelKey, "filterModelName": filterModel.ModelName })
            setparamsUser({ ...paramsUser, "filterModelKey": filterModel.ModelKey, "filterModelName": filterModel.ModelName })
        }
    }, [filterModel]);

    useEffect(() => {
        //datespread logic
        let datespread = DateSpread_DeterminefromParams(paramsUser, true) //check this is working
        let revisednperiod: number
        datespread === "spread" || datespread === "" ? revisednperiod = params.nperiods : revisednperiod = 1
        setParams({ ...params, dateSpread: datespread, nperiods: revisednperiod })
        setparamsUser({ ...paramsUser, dateSpread: datespread, nperiods: revisednperiod })
    }, [paramsUser.endDate, paramsUser.xfieldname, refreshimport]) //on user in case of reimport, has to run after paramsuser is settled

    useEffect(() => {
        if (workingQuery) {
            QueryModel_to_Params(workingQuery, qryvis, paramsUser, setparamsUser, fields, true)
        }
    }, [refreshimport])


    useEffect(() => {
        if (isSample) { SampleGraph(params, "#graph1x", [700, 500]) }
    }, [params.nperiods, params.groupfield, params.colors, params.colorGradientType]);


    //////////////
    const changedisplay = (Index: number) => {
        let coll: HTMLCollectionOf<Element> = document.getElementsByClassName("TopElement")
        Array.from(coll).forEach((e: Element, i: number) => {
            let lmntTop: HTMLElement | null = document.getElementById(e.id);
            let lmnt2: HTMLElement | null = document.getElementById("collapse" + i);

            if (lmntTop && lmnt2) {
                if (e.id === "topcollapse" + Index) {
                    lmnt2.style.display = "block";
                    setTopelem(lmntTop.innerHTML)
                } else {
                    lmnt2.style.display = "none";
                }
            }
        })
    };

    const FieldSetup = (e: MouseEvent<HTMLDivElement>, xy: string) => {
        let keynm: string = e.currentTarget.attributes[1].value;
        ChangeElementCollectionAttr(keynm, "backgroundColor", "fieldclick_xy", "rgb(182, 145, 145)", "white", false)
        if (xy === "X") {
            setParams({ ...params, "xfieldname": keynm });

        } else {
            setParams({ ...params, "groupfield": keynm });
        }
        e.currentTarget.style.background = "rgb(182, 145, 145)";
    };

    const ChangeReportModel = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== "Select Data Category") {
            changeParameter("reportType", e.target.value)
        }
        let repinfo_elem: HTMLElement | null = document.getElementById("emp1_selreport")
        if (repinfo_elem) { repinfo_elem.innerHTML = "" }
    };


    const ChangePeriod = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "Select Period") {
            changeParameter("period", "None")
        } else {
            changeParameter("period", e.target.value)
        }
        let elem = document.getElementById("d_spread")
        if (elem) { elem.innerText = "Good" }
    };

    const SwitchLabelsOnOff = () => {
        if (params.labelsonoff === "On") {
            changeParameter("labelsonoff", "Off")
        } else {
            changeParameter("labelsonoff", "On")
        }
    };
    const ChangeColorGradient = () => {
        if (anti_colorGradientType === "discrete") {
            changeParameter("colorGradientType", "discrete")
            setAnti_colorGradientType("continuous")
        } else {
            changeParameter("colorGradientType", "continuous")
            setAnti_colorGradientType("discrete")
        }
    };

    const StringToBool = (inputstr: string) => {
        let returnval: boolean = false
        if (inputstr == "Yes") {
            returnval = true
        }
        return (returnval)

    }
    const SwitchBanding = (e: ChangeEvent<HTMLSelectElement>) => {

        let val = e.target.selectedOptions[0].value

        if (val !== "Select:") {
            let boolval: boolean = StringToBool(val)
            setParams({ ...params, "useBanding": boolval })
            setparamsUser({ ...paramsUser, "useBanding": boolval })
        }
    }
    // console.log("-")
    // console.log(params.dateSpread)
    // console.log(paramsUser.dateSpread)

    const ChangeColor = (e: any) => {
        let tempcolor = params.colors;
        tempcolor[colorIdx] = e.hex;

        setParams({ ...params, ["colors"]: tempcolor })
        let colorelem = document.getElementById("colorpicker" + colorIdx)
        if (colorelem) { colorelem.style.backgroundColor = e.hex }
    };
    const ChangeColorIndex = (d: number) => {
        setColorIdx(d)
        let colorelem = document.getElementById("colorsel" + d)
        if (colorelem) { colorelem.style.color = "red" }
        let el_coll: HTMLCollectionOf<Element> = document.getElementsByClassName("colorsel")
        Array.from(el_coll).forEach((e: Element) => {
            let lmnt: HTMLElement | null = document.getElementById(e.id)
            if (lmnt) { if (e.id !== "colorsel" + d) { lmnt.style.color = "black" } }
        })
    };


    const ModelSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        SaveQueryModel();
    };

    //actual function to save querymodel/basicquery
    const SaveQueryModel = () => {
        let uploadpromise = QueryModelUpload(params, existingKey, schema, user, config, dispatcher, "ge_uploaddiv");

        uploadpromise.then(_ => {
            setIsSample(false)
            DeleteSample()
            //PreCreateChart()
        });

    };
    const DeleteSample = () => {
        let elem = document.getElementById("#samplebars")
        let parentelem = document.getElementById("ge_chartgroup")
        if (elem && parentelem) { parentelem.removeChild(elem) }
    }

    const PreCreateChart = () => {
        if (workingQuery) {
            CreateChart(workingQuery, { ...qryvis, colors: params.colors }, workingColorModel, params.rundatekey,
                fields, metrics, "#graph1x", [600, 500], "ge_icon", queryvisInit, "", 0, {})
        }
        //ThreeDBarGraph_Prepare(workingQuery, rundatekey, "tdbarcanvas1")
    };

    const ChangeDashboard = (e: ChangeEvent<HTMLSelectElement>) => {
        let val = e.target.selectedOptions[0].attributes[1].value;
        changeParameter("dashboardkey", val);
    };

    const ResetParams = (qry: QueryModel) => {
        let qrypromise = QueryModel_to_Params(qry, qryvis, params, setParams, fields);
        qrypromise.then(_ => { setRefreshImport(refreshimport + 1) });
    };

    const ImportFullModel = (e: ChangeEvent<HTMLSelectElement>) => {
        let modelkey: string = e.target.selectedOptions[0].attributes[1].value
        //import
        let promise = QueryModelImport(modelkey, schema, config, dispatcher, false)
        //populate chart
        promise.then((d: any) => {
            setWorkingQuery(d);
            setExistingKey(d.ModelKey);
            ResetParams(d);
            if (d.ColorModelName) {
                let colpromise = GenericBackendCall(d.ColorModelName, schema, paths.colorsets.colormodelview, user, config, "")
                colpromise.then(c => {
                    setWorkingColorModel(c[0])
                    setIsSample(false);
                    DeleteSample();
                })
            }
        })

        //PreCreateChart()
    };

    const DateSpreadOptionRange = () => {
        return (
            <div style={{ height: '150px' }}>
                <div id={"d_range"}> </div>
            </div>
        )
    };
    const DateSpreadOptionSpread = () => {
        return (
            <div>
                Period
                <PeriodDropdown change={(e: ChangeEvent<HTMLSelectElement>) => ChangePeriod(e)} />

                <div>
                    Number of Periods
                    <input type="number" name="n_periods" min="1" max="50" value={+params.nperiods}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => { changeParameter("nperiods", +e.target.value) }} />
                </div>
                <div id={"d_spread"}> </div>
            </div>
        )
    };
    return (
        <div id="egraphContainer">
            <div id="ge_graphsidebar" className="sidenav">
                <div id="ge_sidebarbody">
                    <div id="graphsidebarHeader" > Create Chart Dataset </div>
                    <div id="topcollapse0" className="TopElement" onClick={_ => changedisplay(0)}>Manage Charts</div>
                    <div id="topcollapse1" className="TopElement" onClick={_ => changedisplay(1)}>Timeframe Setup</div>
                    <div id="topcollapse2" className="TopElement" onClick={_ => changedisplay(2)}>Data Elements</div>
                    <div id="topcollapse3" className="TopElement" onClick={_ => changedisplay(3)}>Visual Elements</div>
                </div>
                <div id="selectedTopElem">{topelem}</div>
                <div id="collapse0" style={{ display: "none" }} >
                    <ul className="ge_sidenav__list1">
                        <ul className="ge_sidenav__list-item" id="openchart" onClick={_ => clickedopen(10)}>Open Existing Chart</ul>
                        <ul className="ge_sidenav__list-item" id="dashboard" onClick={_ => clickedopen(14)}>Set to Dashboard</ul>
                        <ul className="ge_sidenav__list-item" id="modelname" onClick={_ => clickedopen(9)}>Set Chart Name: {paramsUser.modelname}</ul>
                        <ul className="ge_sidenav__list-item" id="savechart" onClick={_ => clickedopen(15)}>Save Chart</ul>
                    </ul>
                </div>
                <div id="collapse1" style={{ display: "none" }} >
                    <ul className="ge_sidenav__list1">
                        <ul className="ge_sidenav__list-item" id="reportType" onClick={_ => clickedopen(4)}><div>Set Primary Metric:</div> <div> {paramsUser.metrics.length > 0 ? paramsUser.metrics[0] : ""}</div></ul>

                        <ul className="ge_sidenav__list-item" id="isTrend" onClick={_ => clickedopen(13)}>Set IsTrend: {paramsUser.isTrend}</ul>
                        <ul className="ge_sidenav__list-item" id="endDate" onClick={_ => clickedopen(2)}>Set Endpoint Date: {DateString(paramsUser.endDate)}</ul>
                    </ul>
                </div>
                <div id="collapse2" style={{ display: "none" }} >
                    <ul className="ge_sidenav__list1">
                        <ul className="ge_sidenav__list-item" id="groupfield" onClick={_ => clickedopen(1)}>Set X-Axis Field: {paramsUser.xfieldname}</ul>
                        <ul className="ge_sidenav__list-item" id="groupfield" onClick={_ => clickedopen(16)}>Set Grouping Field: {paramsUser.groupfield}</ul>
                        <ul className="ge_sidenav__list-item" id="showBandInput" onClick={_ => clickedopen(6)}> Field Banding: {paramsUser.fieldBand}</ul>
                        <ul className="ge_sidenav__list-item" id="chartType" onClick={_ => clickedopen(12)}>Filter</ul>
                        <ul className="ge_sidenav__list-item" id="ruleModelName" onClick={_ => clickedopen(3)}>Field Exceptions: {paramsUser.ruleModelName}</ul>
                    </ul>
                </div>
                <div id="collapse3" style={{ display: "none" }} >
                    <ul className="ge_sidenav__list1">
                        <ul className="ge_sidenav__list-item" id="chartType" onClick={_ => clickedopen(0)}>Chart Type: {paramsUser.chartType}</ul>
                        <ul className="ge_sidenav__list-item" onClick={_ => clickedopen(11)}>Color Gradient Type</ul>
                        <ul className="ge_sidenav__list-item" onClick={_ => clickedopen(7)}>Edit Colors</ul>
                        <ul className="ge_sidenav__list-item" onClick={_ => clickedopen(8)}>Toggle Labels</ul>
                    </ul>
                </div>

                {/* bottom section */}
                <div id="footer">
                    <ul className="ge_sidenav__list1">
                        <ul className="ge_sidenav__list-item" id="sidebtnCreate" onClick={_ => PreCreateChart()}>Create Chart</ul>
                    </ul>
                </div>

            </div>


            <div id="ge_chartgroup">
                <div id="chart1">
                    <div id="charttitle"> {"Count of " + "Employees" + " by " + paramsUser.xfieldname + ",  as of " + DateString(paramsUser.endDate)} </div>
                    <ChartIcon iconID={"ge_icon"} vis_states={vis_states} setVis_States={setVis_States} int={0} />
                    <div id="graph1x" style={{ margin: '30px', fontSize: '12px' }}></div>
                </div>
                {workingQuery ?
                    <div id="ge_rundatesel">

                        <div id="ge_rundatesel_header">Run Dates</div>

                        {workingQuery.rundates.map((qrd: QueryRunDate, i: number) => {
                            let returnstring = ""
                            if (qrd.IsPrimary) {
                                returnstring = " - Primary"
                            }
                            let rdstyle = {}
                            if (params.rundatekey === qrd.DateKey) {
                                rdstyle = { backgroundColor: "lightgreen" }
                            }
                            return (<div data-value={qrd.DateKey} key={i}
                                className={"ge_rundate_elem"}
                                style={rdstyle}
                                onClick={(e: MouseEvent<HTMLDivElement>) => setParams({ ...params, rundatekey: e.currentTarget.attributes[0].value })}>
                                {qrd.AsofDate} {returnstring}
                            </div>)
                        })}
                        <div id="graph1x" />
                    </div>

                    : <div></div>}
            </div>
            <div id="legend1"></div>

            <div id="ge_properties">
                <div id="ge_propHeader">Assigned Properties:</div>
                {Object.entries(paramsUser).map(([k, v]) => {
                    if (typeof v === "object" && k.includes("Date")) { v = DateString(v); }
                    if (IsInVector(showparams, k)) {
                        return (
                            <div key={k + "p"} className="ge_prop">
                                <div className="ge_propKey">{k}:</div>
                                <div className="ge_propVal">{v}</div>
                            </div>
                        )
                    }
                })}
            </div>

            <div id="tdbarcanvas1" style={{ 'height': '600px', 'width': '700px' }}>
            </div>

            {/* chart type modal */}
            <Modal show={isModalVisible[0]}>
                <div className="chartclick" style={params.chartType == "bar" ? { backgroundColor: "pink" } : {}} onClick={_ => changeParameter("chartType", "bar")}> Bar chart </div>
                <div className="chartclick" style={params.chartType == "line" ? { backgroundColor: "pink" } : {}} onClick={_ => changeParameter("chartType", "line")}> Line chart </div>
                <div className="chartclick" style={params.chartType == "bubble" ? { backgroundColor: "pink" } : {}} onClick={_ => changeParameter("chartType", "bubble")}> Bubble chart </div>
                <button className="closemodalbtn" onClick={_ => clickedclose(0)}>Close</button>
            </Modal>

            {/* 1. Report Type modal */}
            <Modal show={isModalVisible[4]}>
                <div id="mdl_report">
                    <div className="modalemp_header"> Report Type </div>
                    <ReportTypeDropdown className="modalemp_ddown" change={(e: ChangeEvent<HTMLSelectElement>) => ChangeReportModel(e)} />
                    <div>Report Info:</div>
                    <div id="emp1_reportinfo"></div>
                </div>

                <button className="closemodalbtn" onClick={_ => clickedclose(4)}>Close</button>
            </Modal>



            {/* 2. datespread modal */}
            <Modal show={isModalVisible[5]}>
                <div id="mdl_datespread">
                    <div className="modalemp_header">Timeframe Options</div>

                    {(() => {
                        switch (dateSpread) {
                            case "spread": return <DateSpreadOptionSpread />;
                            case "range": return <DateSpreadOptionRange />;
                            case "point": return <div>Done!</div>;
                            default: return <div></div>;
                        }
                    })()}
                </div>
                <button className="closemodalbtn" onClick={_ => clickedclose(5)}>Close</button>
            </Modal>

            {/* 3. set end date modal */}
            <Modal show={isModalVisible[2]}>
                <div style={{ height: '150px' }}>
                    <div> Select Endpoint Date </div>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}> Selected: {DateString(params.endDate)}</div>
                    <DatePicker value={params.endDate} onChange={(e: any) => changeParameter("endDate", e)} />
                </div>
                <button className="closemodalbtn" onClick={_ => clickedclose(2)}>Close</button>
            </Modal>



            {/* Ruleset modal */}
            <Modal show={isModalVisible[3]}>
                <div id="mdl_rule">
                    <div>Use an existing Rule Model?</div>
                    <GenericDropdown data={rModels} keycol={"ModelKey"} namecol={"ModelName"}
                        divID="ge_ruleddown" promptstring="Select Rule Model"
                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, rModels, setRuleModel, "")} />

                </div>
                <ModalRuleManager selModel={ruleModel} />
                <button className="closemodalbtn" onClick={_ => clickedclose(3)}>Close</button>
            </Modal>


            {/* XField modal */}
            <Modal show={isModalVisible[1]}>
                <div style={{ backgroundColor: 'white', width: '600px', margin: 'auto' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="fldheader"> Field Name </div>
                        <div className="fldheader"> Source </div>
                        <div className="fldheader"> Grouped Field </div>
                    </div>
                    {
                        fields.map((fld: FieldMap, i: number) => {
                            if (fld.ModelName !== "sample") {
                                return (
                                    <div key={i} className="fieldelem">
                                        <div className="fieldclick"> {fld['FieldName']}</div>
                                        <div className="fieldclick"> {fld['ModelName']} </div>
                                        <div key={i + 'x'} id={'X_' + i + "_" + fld['AttrName']} data-name={fld['Key']} className="fieldclick_xy" onClick={e => FieldSetup(e, "X")}>
                                            Set as XField Value
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    <button className="closemodalbtn" onClick={_ => clickedclose(1)}>Close</button>
                </div>
            </Modal>

            {/* Groupingfield modal */}
            <Modal show={isModalVisible[16]}>
                <div style={{ backgroundColor: 'white', width: '600px', margin: 'auto' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="fldheader"> Field Name </div>
                        <div className="fldheader"> Source </div>
                        <div className="fldheader"> Grouped Field </div>
                    </div>
                    {
                        fields.map((fld: FieldMap, i: number) => {
                            if (fld.ModelName !== "sample") {
                                return (
                                    <div key={i} className="fieldelem">
                                        <div className="fieldclick"> {fld['FieldName']}</div>
                                        <div className="fieldclick"> {fld['ModelName']} </div>
                                        <div key={i + 'x'} id={'X_' + i + "_" + fld['AttrName']} data-name={fld['Key']} className="fieldclick_xy" onClick={e => FieldSetup(e, "Group")}>
                                            Set as Grouping Value
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    <button className="closemodalbtn" onClick={_ => clickedclose(16)}>Close</button>
                </div>
            </Modal>

            {/* Banding modal */}
            <Modal show={isModalVisible[6]} >
                <div>
                    <div className="modalemp_header">Set Maximum Number of Elements</div>
                    <div>(all others will be grouped into "Other")</div>
                    <div>Use Field Banding?</div>
                    <select onChange={(e: ChangeEvent<HTMLSelectElement>) => SwitchBanding(e)}>
                        <option>Select:</option>
                        <option>No</option>
                        <option>Yes</option>
                    </select>
                    {params.useBanding &&
                        <input type="number" id="input_fldbandmax" placeholder={"0"} onChange={e => changeParameter("fieldBand", String(e.target.value))}></input>
                    }
                    <button className="closemodalbtn" onClick={_ => clickedclose(6)}>Close</button>
                </div>
            </Modal>


            {/* Visuals modal */}
            <Modal show={isModalVisible[7]} >
                Select Colors
                <div id="mdl_visuals">
                    <div>
                        {
                            [0, 1, 2, 3, 4, 5].map((d: number) => {
                                return (
                                    <div key={d} style={{ display: 'flex' }}>
                                        <div className="colorsel" id={"colorsel" + d}>{"->"}</div>
                                        <div className="colorpicker" id={"colorpicker" + d} style={{ backgroundColor: params.colors[d] }} onClick={_ => ChangeColorIndex(d)}>
                                            Color {d}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <SwatchesPicker onChangeComplete={(e: any) => ChangeColor(e)} />
                </div>
                <button className="closemodalbtn" onClick={_ => clickedclose(7)}>Close</button>
            </Modal>
            {/* modal color gradient*/}
            <Modal show={isModalVisible[11]}>
                <div onClick={ChangeColorGradient}> Make Colors {anti_colorGradientType}</div>
                <button className="closemodalbtn" onClick={_ => clickedclose(11)}>Close</button>
            </Modal>

            {/* modal labels on off */}
            <Modal show={isModalVisible[8]}>
                <div onClick={SwitchLabelsOnOff}>Turn Labels {params.labelsonoff}</div>
                <button className="closemodalbtn" onClick={_ => clickedclose(8)}>Close</button>
            </Modal>

            {/* model name */}
            <Modal show={isModalVisible[9]}>

                <div> Create Model Name</div>
                <input type="text" name="modelname" onChange={e => changeParameter("modelname", e.target.value)} />

                <button className="closemodalbtn" onClick={_ => clickedclose(9)}>Close</button>
            </Modal>

            {/* modal save */}
            <Modal show={isModalVisible[15]}>
                <form onSubmit={(e: FormEvent<HTMLFormElement>) => ModelSubmit(e)}>
                    <div> Save this model?</div>
                    <button type="submit">Submit</button>
                </form>
                <div id="ge_uploaddiv"></div>
                <button className="closemodalbtn" onClick={_ => clickedclose(15)}>Close</button>
            </Modal>

            {/* modal dropdown */}
            <Modal show={isModalVisible[10]}>
                <div>Select Exist Query Model</div>
                <QueryModelDropdown models={queries} change={(e: ChangeEvent<HTMLSelectElement>) => ImportFullModel(e)} />
                <button className="closemodalbtn" onClick={_ => clickedclose(10)}>Close</button>
            </Modal>

            {/* modal filter */}
            <Modal show={isModalVisible[12]}>
                <div id="mdl_filter">
                    <div>Use an existing Filter Model?</div>
                    <GenericDropdown data={fModels} keycol={"ModelKey"} namecol={"ModelName"}
                        divID="ge_filtddown" promptstring="Select Filter Model"
                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, fModels, setFilterModel, "")} />
                </div>
                <button className="closemodalbtn" onClick={_ => clickedclose(12)}>Close</button>
            </Modal>

            {/* modal dashboardsel */}
            <Modal show={isModalVisible[14]}>
                <div>Select Existing Dashboard</div>
                <DashboardDropdown dashboards={dashboards} change={(e: ChangeEvent<HTMLSelectElement>) => ChangeDashboard(e)} />
                <button className="closemodalbtn" onClick={_ => clickedclose(14)}>Close</button>
            </Modal>


        </div >

    )
};
export default GraphEditor;