
import NetworkGraph2D from "./NetworkGraph2D";
import { useState, ChangeEvent, useEffect, useContext } from 'react'
import { FieldMap, NetModel, NetworkRunDate, SNAAttrField, snaState } from "../../../type";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import { StartupContext } from "../../../App";
import SetObjectfromKey from "../../../functions/SetFunctions/SetObjectfromKey";
import { NetModelInit, NetworkRunDateInit, SNAStateInit } from "../../../InitTypes";
import "./css/NetworkGraph.css"
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import GetFieldName from "../../../functions/GetFieldName";
import FieldsImport from "../../../data/Fields/Fields_Import";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import NetworkGraphSetup from "./NetworkGraphSetup";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import GetRandomHexadecimal from "../../../functions/GetRandomHexadecimal";
import StringTrim from "../../../functions/String Functions/StringTrim";
import { AIModel, MessageBranch } from "../../../type";


const NetworkPage = () => {

    let { config, dispatcher, schema, paths, user } = useContext(StartupContext)

    const [SNAstate, setSNAstate] = useState<snaState>(SNAStateInit)

    interface paramtype {
        view: string;
        selModelName: string;
        selModelKey: string;
        selattrcategory: string;
        selrundatekey: string;
        refresh: number;
        chattext: string;
        msgtype: string;
        branchkey: string;
    }

    let [fields, setFields] = useState<FieldMap[]>([])
    let [networks, setNetworks] = useState<NetModel[]>([])
    let [selNetwork, setSelNetwork] = useState<NetModel>(NetModelInit[0])
    let [selNetworkRunDate, setSelNetworkRunDate] = useState<NetworkRunDate>(NetworkRunDateInit[0])
    let [keyparams, setKeyParams] = useState<paramtype>({
        view: '', selModelName: "", selModelKey: "", selattrcategory: '',
        selrundatekey: '', refresh: 0, chattext: '', msgtype: '', branchkey: ''
    })
    let [attrcats, setAttrCats] = useState<SNAAttrField[]>([])
    const [selModel, setSelModel] = useState<AIModel | null>(null)
    const [selBranch, setSelBranch] = useState<MessageBranch | null>(null)

    useEffect(() => {
        ImportAndSetStateGeneric(setNetworks, "*", schema, paths.sna.netmodelview, user, config, dispatcher, false)
        FieldsImport("", schema, config, dispatcher, false).then(d => { setFields(d); })
    }, [])

    useEffect(() => {
        SetObjectfromKey(keyparams.selModelKey, networks, "ModelKey", setSelNetwork, false, null)
    }, [keyparams.selModelKey])

    useEffect(() => {
        if (selNetwork.attrfields) {
            let newfields: SNAAttrField[] = selNetwork.attrfields.map((af: SNAAttrField) => {
                return { ...af, FieldName: GetFieldName(fields, af.FieldName) }
            })
            setAttrCats(newfields)
        }
        setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
    }, [selNetwork])

    useEffect(() => {
        if (keyparams.selattrcategory !== "") {
            RefreshRun_Attr()
        }
    }, [keyparams.selattrcategory])

    useEffect(() => {
        if (keyparams.selrundatekey !== "") {
            GenericBackendCall(keyparams.selrundatekey, schema, paths.sna.netmodelrunview, user, config, {}, "").then(d => {
                if (d.length === 1) {
                    console.log(d[0])
                    setSelNetworkRunDate(d[0])
                    RefreshRun_Attr()
                }
            })
        }
    }, [keyparams.selrundatekey])

    const RefreshRun_Attr = () => {
        let attrnum = GetObjectField(keyparams.selattrcategory, attrcats, "id", "AttrNum")
        let attrfield = "Attribute" + String(attrnum)
        let ctype = GetObjectField(keyparams.selattrcategory, attrcats, "id", "DataType")

        let [newcats, colors] = NetworkGraphSetup(selNetwork, keyparams.selrundatekey, attrfield, ctype)
        setSNAstate({ ...SNAstate, categoryattribute: attrfield, categoryattrtype: ctype, categories: newcats, colors: colors })
        setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
    }

    const ClickRandomColors = () => {
        let colors = Array.apply(null, Array(SNAstate.colors.length)).map(function (_) { return GetRandomHexadecimal(6); })
        setSNAstate({ ...SNAstate, colors: colors })
        setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
    }


    const ClickScenario = () => {
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.ai.aiinterfacepost, user, config,
                { text: keyparams.chattext, branchkey: keyparams.branchkey, msgtype: keyparams.msgtype }).then(d => {
                    setSelBranch(d)
                })
        }
    }

    //-----------------
    const View_ChartDetail = () => {
        return (
            <div>
                <div style={{ fontSize: '18px', letterSpacing: ".05em" }}>Chart Legend</div>

                <div id="jdng_legend" style={{
                    height: String(Math.round(SNAstate.categories.length * 15) + 50) + "px",
                    overflowY: "scroll", border: "1px solid grey", padding: "5px", minHeight: '200px', minWidth: "400px",
                    backgroundColor: "black"
                }}>

                    {SNAstate.categories.map((c: string, i: number) => {
                        return (<div key={i} style={{ display: "flex", cursor: "pointer", width: '120px' }} onClick={_ => { setSNAstate({ ...SNAstate, selcategory: c }); setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 }) }}>
                            <div style={{ height: '15px', width: '15px', marginTop: "5px", marginRight: "5px", background: SNAstate.colors[i] }}></div>

                            <div>{StringTrim(c, 34)}</div>
                        </div>)
                    })}
                </div>
            </div>
        )
    }
    const View_Changes = () => {
        return (<div>

        </div>)
    }

    const View_AIChat = () => {
        return (<div>
            <textarea id="aim_textarea" style={{ width: "700px", height: '50px' }}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setKeyParams({ ...keyparams, chattext: e.target.value }) }} />

            <button onClick={_ => { ClickScenario() }}>Create</button>
        </div>)
    }


    NetworkGraph2D(selNetworkRunDate, SNAstate, setSNAstate, "#snagraph1", [1200, 700], keyparams.refresh)



    return (<div className="ndt_canvas">
        <div className="ndt_title3 ndt_header ndt_gridbox" style={{ marginBottom: "10px" }}>Network Page

        </div>
        <div style={{ display: "flex" }}>
            <GenericDropdown
                data={networks}
                change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, networks, keyparams, setKeyParams, "selModelKey", "ModelKey", "")}
                keycol="ModelKey"
                namecol="ModelName"
                className="ndt_dropdown"
                promptstring="Select Net Model"
            />
            {selNetwork ?
                <div>
                    <GenericDropdown
                        data={selNetwork.rundates}
                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, selNetwork.rundates, keyparams, setKeyParams, "selrundatekey", "DateKey", "")}
                        keycol="DateKey"
                        namecol="AsofDate"
                        className="ndt_dropdown"
                        promptstring="Select Run Date"
                    />
                    <GenericDropdown
                        data={attrcats}
                        className="ndt_dropdown"
                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, attrcats, keyparams, setKeyParams, "selattrcategory", "id", "")}
                        keycol="id"
                        namecol="FieldName"
                        divID=""
                        promptstring="Select Attribute"
                    />
                </div>
                : <div></div>}
            <button className="ndt_btn1" onClick={_ => { ClickRandomColors() }}>Randomize Colors</button>
        </div>
        <div id="npg_mainbox">
            <div id="snagraph1" style={{ backgroundColor: "white", height: '700px', width: '1200px' }}></div>

            <div>
                <div style={{ display: "flex" }}>
                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, view: "chartdetail" }) }}>Chart Detail</button>
                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, view: "aichat" }) }}>Scenario</button>
                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, view: "changes" }) }}>Changes</button>
                </div>

                {(() => {
                    switch (keyparams.view) {
                        case "chartdetail": return <div className="ndt_gridbox wlk_stepbox"><View_ChartDetail /></div>
                        case "aichat": return <div className="ndt_gridbox wlk_stepbox"><View_AIChat /></div>
                        case "changes": return <div className="ndt_gridbox wlk_stepbox"><View_Changes /></div>

                        default: return <div>Select a view</div>;
                    }
                })()}



            </div>
        </div>
    </div>)
};
export default NetworkPage;