
const GetObjectValue = (data: any, keystring: string, checkonly: boolean = false) => {

    if (checkonly) {

        try {
            let y = data[keystring]
            if (y) {
                return true
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    } else {
        try {
            return (data[keystring])
        } catch (err) {
            return ""
        }
    }


}
export default GetObjectValue