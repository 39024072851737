import { QueryModel, FieldMap, QueryRunDate, FilterField, queryfiltervaluestate } from "../../type";
//import GetObjectfromArray from "../Array Functions/GetObjectfromArray";
import GetUniquesFromObjectAttribute from "../GetUniquesFromObjectAttribute";
import FilterData from "../FilterData";

const QueryFilterValues = (query: QueryModel, selrundatekey: string) => {
    //single query (simplified) version of MultiQueryFilter
    let filtervaluearry: any = {}

    query.filterfields.forEach((flt: FilterField, i: number) => {
        let vals: any[] = []
        //let fld: FieldMap = GetObjectfromArray(fields, flt.FieldName, "Key")

        query.rundates.forEach((rd: QueryRunDate) => {
            if (selrundatekey === rd.DateKey || (selrundatekey === "" && rd.IsPrimary === true)) {
                let fltdata = FilterData(rd.basicquery, "FilterLevel", "Filtered")
                let unique = GetUniquesFromObjectAttribute(fltdata, "FilterValue" + (i + 1), true)
                vals = unique
            }
        })
        let qfvs: queryfiltervaluestate = { Values: vals, Selected: '', OnQryIdx: [0] }

        filtervaluearry[flt.FieldName] = qfvs
    })

    return filtervaluearry
};
export default QueryFilterValues;