import {
    Department, Employee, Emp_State, EmpModel,
    JobTitle, Dashboard, UserPermssion,
    FilterModel, FilterSet, FilterValue, Schema,
    FieldMap, Header, User, BasicQuery, QueryModel,
    QueryColor, TableMap, Exception, Ruleset, RuleModel, QueryRunDate, queryparams,
    DetailField, FilterField, NetModel,
    Link, Node, ColorSet, ColorValue, ColorModel, AppColors, UserAccount, TrackerItem,
    DashboardNote, Metric, queryvisualstate, QueryVisuals, ChartSeries,
    GroupNumericRule, DashboardPage, GroupDateRule, TblUploadSet, TableData, TableModel,
    Connection, ConnectionSet, GroupSet, DashboardFilter, Schedule, KPI, KPIValue, KPIString,
    Workspace, BenchModel, Benchmarkset, UserReports, DashRunDate, RenameValue, RenameSet, Benchmark, LeaderModel, JobDescription, ConnectionModel, snaState, snavalues,
    ScenarioModel,
    ScenarioPath,
    ScenarioEvent,
    NetworkRunDate

} from "./type";

export const DateDefault = new Date('January 1, 1990')

export const AppColorsInit: AppColors = {
    colorprimary: "rgb(27, 13, 49)",
    colorsecondary: "rgb(100, 215, 225)",
    coloraltprimary: "rgb(24, 25, 72)",
    colorhighlight: "rgb(252, 252, 166)",
    coloroffwhite: "rgb(236, 249, 254)",
    colorprimarybright: "rgb(87, 42, 170)",
    colorfade1: "rgba(2, 0, 36, .7)",
    colorfade2: "rgba(69, 69, 123, 0.292)",
}

export const SchemaInit: Schema[] = [{
    SchemaKey: "",
    SchemaName: "",
    SchemaDescription: "",
    IsProductionDefault: false,
    IsGuest: false,
    BaseColor: "#000000",
    CreateDate: new Date(),
    models: [],
    workspaces: [],
    userperms: [],
    modelcount: 0,
    workspacecount: 0,
    dashcount: 0,
    querycount: 0,
    kpicount: 0,
    schedulecount: 0,
    tablecount: 0,
    fieldcount: 0,
    filtermdlcount: 0,
    rulemdlcount: 0,
    jobdesccount: 0,
    categorycount: 0
}]

export const WorkspaceInit: Workspace[] = [{
    WorkKey: '',
    WorkspaceName: '',
    SchemaName: '',
    IsSimulation: false,
    CreateDate: new Date(),
    models: [],
}]

export const UserInit: User = {
    id: "",
    email: "",
    password: "",
    authtoken: "",
    refreshtoken: '',
    isAuthenticated: false,
    level: '',
    defaultschema: '',
}

export const UserAccountInit: UserAccount[] = [{
    id: "",
    email: "",
    group: "",
    level: "",
    defaultschema: ''
}]


export const UserPermissionInit: UserPermssion[] = [{
    Key: "",
    User: "",
    SchemaPrm: "",
    DashboardPrm: "",
    QueryModelPrm: "",
    KPIStringPrm: '',
    Permission: "",
    LastViewedDateTime: new Date()
}]

export const UserReportsInit: UserReports[] = [{
    id: 0,
    ManagingUserID: '',
    EmpUserID: '',
    EmployeeEmail: '',
}]

export const EmpModelInit: EmpModel[] = [{
    ModelKey: '',
    ModelName: '',
    CreateDate: new Date(),
    AsofDate: new Date(),
    SchemaName: '',
    WorkspaceName: '',
    ReportType: '',
    state: []
}]

export const Emp_StateInit: Emp_State = {
    StateKey: "",
    EmpID: "",
    Name: "",
    ModelName: "",
    SchemaName: '',
    WorkspaceName: '',
    Date: "",
    JobCode: "",
    DeptID: "",
    ReportsToID: "",
    Status: "",
    Position: "",
    CurrDate: new Date(),
    NextDate: new Date(),
    PrevDate: new Date(),
    Attribute1: "",
    Attribute2: "",
    Attribute3: "",
    Attribute4: "",
    Attribute5: "",
    Attribute6: "",
    Attribute7: "",
    Attribute8: "",
};

export const EmpInit: Employee[] = [{
    EmpID: '',
    Name: '',
    SchemaName: '',
    WorkspaceName: '',
    CreateDate: '',
    EmailAddress: '',
    state: [Emp_StateInit],
}]


export const JobTitleInit: JobTitle[] = [{
    JobCode: "",
    JobTitle: "",
    SchemaName: "",
    WorkspaceName: '',
    PrevDate: new Date(),
    CurrDate: new Date(),
    NextDate: new Date(),
    IsMostRecent: false,
    Attribute1: "",
    Attribute2: "",
    Attribute3: "",
    Attribute4: "",
    Attribute5: "",
    Attribute6: "",
    Attribute7: "",
    Attribute8: "",
    Grouping1: "",
    Grouping2: "",
    Grouping3: "",
    Grouping4: "",
}]

export const DepartmentInit: Department = {
    AttrKey: '',
    Dept: '',
    DepartmentName: '',
    SchemaName: '',
    WorkspaceName: '',
    CurrDate: new Date(),
    NextDate: new Date(),
    IsMostRecent: true,
    Attribute1: '',
    Attribute2: '',
    Attribute3: '',
    Attribute4: '',
    Attribute5: '',
    Attribute6: '',
    Attribute7: '',
    Attribute8: ''
}

export const FilterValueInit: FilterValue[] = [{
    Key: '',
    FieldKey: '',
    Value: '',
}]

export const FilterSetInit: FilterSet[] = [{
    Key: '',
    ModelKey: '',
    FilterName: '',
    SchemaName: "",
    Field: '',
    LastUpdatedDate: new Date(),
    values: FilterValueInit
}]

export const FilterModelInit: FilterModel[] = [{
    ModelKey: '',
    DateCreated: new Date(),
    ModelName: '',
    SchemaName: '',
    fields: FilterSetInit
}]

const HeaderInit: Header[] = [{
    FieldKey: '',
    HeaderName: '',
    id: '',
}]


export const FieldMapInit: FieldMap[] = [{
    Key: '',
    ModelCategory: '',
    SchemaName: '',
    FieldCategory: '',
    ModelName: '',
    AttrName: '',
    FieldName: '',
    HeaderName: '',
    DataType: '',
    TrackChanges: false,
    AutoTrack: false,
    DateChanged: new Date(),
    OnField: '',
    GroupFieldKey: "",
    IsTableModel: false,
    IsNewField: false,
    IsKeyField: false,
    ConditionNote: "",
    Headers: HeaderInit
}]


export const BasicQueryInit: BasicQuery[] = [{
    id: 0,
    ModelName: '',
    QueryDate: '',
    Variable: '',
    Grouping: '',
    MetricNo: 0,
    Metric: '',
    Value: 0,
    ValueCalc: 0,
    Complexity: '',
    DetailFieldName: "",
    DetailValue: "",
    FilterLevel: '',
    FilterFieldName1: '',
    FilterValue1: '',
    FilterFieldName2: '',
    FilterValue2: '',
    N: 0,
    N_Denom: 0,
    Benchmark: null,
    EncodedKey: ''
}]

export const QueryColorInit: QueryColor[] = [{
    ModelName: '',
    Color: 'black',
    SecondaryColor: ''
}]

export const TableMapInit: TableMap[] = [{
    ModelName: "",
    ConnectKey: "",
    TableName: "",
    MappingStyle: "",
}]

export const QueryRunDateInit: QueryRunDate[] = [{
    DateKey: "",
    ModelName: "",
    RunDate: new Date(),
    AsofDate: new Date(),
    EndDate: new Date(),
    DashRunDateName: '',
    IsPrimary: false,
    ScheduleKey: '',
    Schedules: [],
    Notes: "",
    basicquery: BasicQueryInit
}]

export const DetailFieldinit: DetailField[] = [{
    DetailKey: '',
    ModelName: '',
    FieldName: '',
    AttrName: ''
}]


export const FilterFieldInit: FilterField[] = [{
    FilterKey: '',
    ModelName: '',
    FieldName: '',
    AttrName: '',
    FieldNum: 0
}]

export const ColorValuesInit: ColorValue[] = [{
    ValueKey: "",
    SetName: "",
    ValueName: "",
    Color: "",
    SecondaryColor: ''
}]

export const ColorSetInit: ColorSet[] = [{
    SetKey: "",
    SetName: "",
    ColorModelName: "",
    SchemaName: "",
    FieldName: "",
    LastUpdatedDate: new Date(),
    colorvalues: ColorValuesInit,
}]

export const ColorModelInit: ColorModel[] = [{
    ModelKey: "",
    ModelName: "",
    SchemaName: "",
    colorsets: ColorSetInit
}]

export const ChartSeriesInit: ChartSeries[] = [{
    SeriesKey: "",
    MetricName: "",
    ModelName: "",
    MetricOrder: 0,
    ChartType: "",
    LabelRounding: 0,
    LabelFormat: '',
    Calculation: "Default",
    BenchmarkMode: 'None',
    BenchmarkModelName: '',
    IsHidden: false,
}]

export const QueryVisualsInit: QueryVisuals[] = [{
    VisKey: "",
    VisName: "",
    SchemaName: "",
    WorkspaceName: '',
    DashboardName: '',
    ModelName: "",
    ChartTitle: '',
    ChartType: 'Bar',
    ColorModelName: '',
    ColorGradientType: "Discrete",
    LabelsOnOff: "",
    LabelColor: "#000000",
    LabelType: "value",
    IsDefault: false,
    IsPrimary: false,
    RunDateKey: 'Primary',
    XPos: 0,
    YPos: 0,
    Height: 0,
    Width: 0,
    ShowDefault: "chart",
    PageNo: 0,
    TablePaginate: 0,
    FilterFields: [],
    FilterValues: [],
    chartseries: [],
    colors: QueryColorInit,

}]

export const ScheduleInit: Schedule[] = [{
    ScheduleKey: "",
    StickyDateRef: "",
    ScheduleName: '',
    WorkspaceName: '',
    SchemaName: '',
    AnchorDate: new Date(),
    OffsetDays: 0,
    Period: "",
    IPeriod: 0
}]


export const KPIStringInit: KPIString[] = [{
    Key: '',
    Name: '',
    Text: '',
    HiddenText: '',
    DashboardName: '',
    XPos: 0,
    YPos: 0,
    Height: 0,
    Width: 0,
    PageNo: 0,
    FormatWeight: '',
    NRows: 1,
    KPIs: [],
    userperms: UserPermissionInit,
    parsed: []
}]


export const KPIValuesInit: KPIValue[] = [{
    id: 0,
    KPIName: "",
    KPIFltName: "",
    EncodedFltKey: '',
    AsofDate: new Date(),
    RunDate: '',
    Schedules: '',
    Value: 0,
    Benchmark: 0,
}]

export const KPIInit: KPI[] = [{
    Key: "",
    KPIName: "",
    SchemaName: "",
    WorkspaceName: '',
    EncodedKey: "",
    ModelName: "",
    MetricKey: "",
    MetricNo: 0,
    Note: '',
    FltNote: '',
    values: KPIValuesInit,
    AutoFillBenchmark: false,
    kpiflts: []
}]

export const QueryModelInit: QueryModel[] = [{
    ModelKey: '',
    ModelName: '',
    SchemaName: '',
    WorkspaceName: '',
    DateSpread: '',
    XFieldName: '',
    GroupFieldName: '',
    basicquery: BasicQueryInit,
    RuleModelName: '',
    FilterModelName: '',
    FieldBand: 0,
    BandGroupName: '',
    NPeriods: 0,
    Period: "",
    IPeriod: 0,
    StickyDateRef: "",
    OffsetDays: 0,
    MetricOrder: '[]',
    IncludeToDate: false,
    Notes: '',
    DateCreated: new Date(),
    IsDefault: false,
    SimpleFilterField: '',
    SimpleFilterValues: [],
    Schedules: [],
    Metrics: [],
    tablemap: [],
    rundates: [],
    detailfields: [],
    filterfields: [],
    userperms: [],
    visuals: [],
    kpis: KPIInit,
    metrmodelchoices: [],
    colors: []
}]


export const DashNotesInit: DashboardNote[] = [{
    id: 0,
    DashboardName: "",
    User: "",
    Timestamp: new Date(),
    IsComplete: false,
    Note: ""

}]

export const DashPageInit: DashboardPage[] = [{
    DashboardName: "",
    PageName: "",
    PageNo: 0,
}]

export const DashFilterInit: DashboardFilter[] = [{
    DashboardName: "",
    FieldName: "",
    IsTangible: false,
    Height: 0,
    Width: 0,
    PageNo: 0,
}]

export const DashRunDateInit: DashRunDate[] = [{
    DateKey: '',
    DashboardName: "",
    AsofDate: new Date(),
    RunDate: new Date()
}]

export const DashboardInit: Dashboard[] = [{
    Key: "",
    Name: "",
    SchemaName: "",
    UrlString: "",
    ScheduleName: '',
    visuals: [],
    userperms: UserPermissionInit,
    notes: DashNotesInit,
    pages: DashPageInit,
    filters: DashFilterInit,
    kpistrings: [],
    rundates: []
}]

export const SNAvalsInit: snavalues = {
    fstrength: -300,
    dmin: 5,
    dmax: 300,
}

export const SNAStateInit: snaState = {
    nodeColor: "#12ffff",
    linkColor: "#111111",
    secondaryNode: "",
    isHighlight: false,
    selNodeid: '',
    labelfield: '',
    labelappend: true,
    categoryattribute: '',
    categoryattrtype: "Text",
    categories: [],
    colors: [],
    selcategory: '',
    clickmode: '',
    value: SNAvalsInit
}

export const ExceptionInit: Exception[] = [{
    Rule: "",
    ExceptionVal: "",
    FieldNo: 0
}]

export const RuleInit: Ruleset[] = [{
    ModelName: "",
    RuleKey: "",
    RuleName: "",
    SchemaName: "",
    Operation: "",
    Field1: "",
    Field2: "",
    LastUpdatedDate: new Date(),
    exceptions: ExceptionInit,
}]

export const RenameValueInit: RenameValue[] = [{
    id: 0,
    SetName: '',
    ValueFrom: '',
    ValueTo: ''
}]

export const RenameSetInit: RenameSet[] = [{
    SetKey: '',
    SchemaName: '',
    ModelName: '',
    FieldName: '',
    values: []
}]

export const RuleModelInit: RuleModel[] = [{
    ModelKey: '',
    ModelName: '',
    CreateDate: '',
    OnUpload: false,
    ruleset: RuleInit,
    renames: []
}]

export const tblmappinginit: any = {
    Department: "Current",
    JobTitle: "Current",
    Position: "Current"
}
export const colorsinit: any = {
    0: ['#001010', ''],
    1: ['#5DE5DE', ''],
    2: ['#D31C0A', ''],
    3: ['#66B877', ''],
    4: ['#5822B8', ''],
    5: ['#3E2635', ''],
    6: ['#6A729C', ''],
    7: ['#E8A01F', ''],
    8: ['#6D1C71', ''],
    9: ['#BDB76B', ''],
    10: ['#0E8096', '']
}

export const queryparamsInit: queryparams = {
    newparamset: true, modelname: '', dashboardkey: '',
    chartType: 'Bar', dateSpread: "",
    xfieldname: "", groupfield: "", isTrend: "",
    ruleModelKey: '', filterModelKey: '',
    ruleModelName: '', filterModelName: '',
    asofDate: new Date(), endDate: new Date(),
    fieldBand: 6, bandgroupname: '',
    period: "", nperiods: 6, iperiod: 1, useMulti: true,
    labelsonoff: "On", labelcolor: "#000000", labeltype: "value",
    colorGradientType: "Discrete", stickydateref: "",
    runDatetext: "", charttitle: '', schedule: "None", offsetdays: 0, includetodate: false,
    useGrouping: false, isimported: "", useBanding: false, complexity: "", tablepaginate: 0,
    simplefilterfield: '', simplefiltervalues: [],
    mappings: tblmappinginit, mappingSet: "", visualskey: '', visualsname: "", notes: '',
    colors: colorsinit, colorModelKey: "", colorModelName: "", usecolormap: false,
    detailfields: [], filterfields: [], metrics: [], chartseries: [],
    defaultfilterflds: [], defaultfiltervals: [], metrmodelchoices: [],
    showdefault: "chart", rundatekey: "", isprimary: false
}

export const LinkInit: Link[] = [{
    LinkKey: "",
    NetModelName: "",
    NetDate: '',
    Source: 'A',
    Target: 'B',
    Count: 0,
    CountPct: 0,
    otherdata: []
}]

export const NodeInit: Node[] = [{
    Key: "A",
    NetModelName: "",
    NetDate: '',
    Name: "A",
    Attribute1: "",
    Attribute2: "",
    Attribute3: "",
    Attribute4: "",
    Attribute5: "",
    Attribute6: "",

}, {
    Key: "B",
    NetModelName: "",
    NetDate: '',
    Name: "B",
    Attribute1: "",
    Attribute2: "",
    Attribute3: "",
    Attribute4: "",
    Attribute5: "",
    Attribute6: "",
}
]

export const NetworkRunDateInit: NetworkRunDate[] = [{
    DateKey: '',
    ModelName: '',
    ScenarioPathName: '',
    AsofDate: new Date(),
    RunDate: new Date(),
    nodes: NodeInit,
    links: LinkInit
}]

export const NetModelInit: NetModel[] = [{
    ModelKey: "",
    ModelName: "",
    SchemaName: "",
    WorkspaceName: '',
    ScenarioName: '',
    rundates: NetworkRunDateInit,
    attrfields: [],
    grpfields: [],

}]

export const TrackerInit: TrackerItem[] = [{
    id: 0,
    ModelCategory: "",
    ModelName: "",
    EventType: "",
    EventValue: "",
    User: "",
    Timestamp: new Date(),
    Notes: ""
}]

export const MetricInit: Metric[] = [{
    MetricKey: "",
    MetricName: "",
    SchemaName: "",
    WorkspaceName: '',
    Table: '',
    TableDenom: '',
    ReportTypeNumerator: "",
    ReportTypeDenominator: "",
    FilterModelNumerator: "",
    FilterModelDenominator: '',
    Measurement: "",
    MeasurementField: "",
    MeasurementDenom: "",
    MeasurementFieldDenom: "",
    AggregationType: "",
    AggregateField: "",
    ConnectionModelName: '',
    SimpleFilterNumField: '',
    SimpleFilterNumValues: '',
    SimpleFilterDenomValues: '',
    SimpleFilterDenomField: '',
    DisplayName: '',
    IsDefault: false,
    Notes: "",
    DateCreated: new Date(),
}]

export const queryvisInit: queryvisualstate[] = [{
    chartkey: '', //visualstate key, or kpistring key
    querykey: '',
    queryname: '',
    show: 'chart',
    selectedmetric: "", //if horiztbl, 1-indexed
    selectedmetricname: '',
    reload: false,
    selEncKey: "",
    selChartElemIndex: '',
    selChartElemMetricNo: -1,
    filterfields: [],
    filtervals: [],
    clickevent: '',
    highlight: 'none',
    inspect: ''
}]

export const GroupNumericRuleInit: GroupNumericRule[] = [{
    id: 0,
    GroupSetName: "",
    NumericType: "",
    LessThanThresh: 0,
    EqualTo: 0,
    GreaterThanThresh: 0,
    BandingName: ""
}]


export const GroupDateRuleInit: GroupDateRule[] = [{
    id: 0,
    GroupSetName: "",
    LessThanThresh: new Date(),
    EqualTo: new Date(),
    GreaterThanThresh: new Date(),
    BandingName: ""
}]

export const GroupSetInit: GroupSet[] = [{
    SetKey: '',
    SchemaName: '',
    FieldName: '',
    LastUpdatedDate: new Date(),
    DataType: '',
    HandleOtherValues: '',
    OtherGroupName: '',
    values: [],
    numericrules: [],
    daterules: []
}]

export const TblUploadSetInit: TblUploadSet[] = [{
    UploadKey: "",
    ModelCategory: "",
    SchemaName: '',
    CreateDate: new Date(),
    AsofDate: new Date(),
    rowcount: 0
}]

export const TableDataInit: TableData[] = [{
    ModelCategory: "",
    SchemaName: '',
    WorkspaceName: '',
    UploadSet: "",
    PrevDate: new Date(),
    CurrDate: new Date(),
    NextDate: new Date(),
    PrimaryField: "",
    Attribute1: "",
    Attribute2: "",
    Attribute3: "",
    Attribute4: "",
    Attribute5: "",
    Attribute6: "",
    Attribute7: "",
    Attribute8: "",
    Attribute9: "",
    Attribute10: "",
    Attribute11: "",
    Attribute12: "",
    Attribute13: "",
    Attribute14: "",
    Attribute15: "",
    Attribute16: "",
    Attribute17: "",
    Attribute18: "",
    Attribute19: "",
    Attribute20: "",
    Grouping1: "",
    Grouping2: "",
    Grouping3: "",
    Grouping4: "",
}]

export const TableModelInit: TableModel[] = [{
    ModelKey: "",
    ModelName: "",
    SchemaName: "",
    WorkspaceName: '',
    DataKeyField: "",
    DataNameField: "",
    DateUploadType: "",
    DateEventType: '',
    DateAssumeDropoff: false,
    DateField: '',
    DateField2: '',
    datacount: 0,
    uploadsets: TblUploadSetInit,
}]

export const ConnectionInit: Connection[] = [{
    ConnKey: '',
    SchemaName: '',
    ConnectionSetName: '',
    ModelCategory1: '',
    ModelCategory2: '',
    Field1: '',
    Field2: '',

}]

export const ConnectionSetinit: ConnectionSet[] = [{
    SetKey: '',
    SchemaName: '',
    ModelCategory1: '',
    ModelCategory2: '',
    ConnectionType: '',
    connections: []
}]

export const ConnectionModelInit: ConnectionModel[] = [{
    ModelKey: '',
    ModelName: '',
    SchemaName: '',
    IsDefault: false,
    sets: []
}]

export const BenchmarkInit: Benchmark[] = [{
    id: 0,
    BenchModelName: '',
    XFieldValue: '',
    GroupFieldValue: '',
    FilterValue1: '',
    FilterValue2: '',
    Value: null
}]

export const BenchModelInit: BenchModel[] = [{
    ModelKey: '',
    ModelName: '',
    QueryModelName: '',
    SchemaName: '',
    MetricNo: -1,
    MetricKey: '',
    VarHeader: '',
    GrpHeader: '',
    BenchHeader: '',
    FilterHeader1: '',
    FilterHeader2: '',
    DisplayName: '',
    benchmarkset: []
}]

export const BenchmarksetInit: Benchmarkset[] = [{
    SetKey: '',
    BenchModelName: '',
    QueryModelName: '',
    SchemaName: '',
    PrevDate: new Date(),
    CurrDate: new Date(),
    NextDate: new Date(),
    ApplyRange: false,
    Notes: '',
    benchmark: []
}]

export const LeaderModelInit: LeaderModel[] = [{
    ModelKey: '',
    ModelName: '',
    SchemaName: '',
    LeaderUser: '',
    KPIs: [],
    KPIFilters: []
}]

export const JobDescriptionInit: JobDescription[] = [{
    DescKey: '',
    SchemaName: '',
    JobCodeName: '',
    JobCode: '',
    CareerLevel: 0,
    Description: '',
    skillcount: 0,
    skills: [],
}]

export const ScenarioInit: ScenarioModel[] = [{
    Key: '',
    Name: '',
    SchemaName: '',
    WorkspaceName: '',
    SnapshotDate: new Date(),
    CreateDate: new Date(),
    paths: []
}]

export const ScenarioPathInit: ScenarioPath[] = [{
    PathKey: '',
    PathName: "",
    SchemaName: '',
    ScenarioName: '',
    events: []
}]

export const ScenarioEventInit: ScenarioEvent[] = [{
    EventKey: '',
    EventType: '',
    EventDate: new Date(),
    ScenarioPathName: '',
    Description: '',
}]