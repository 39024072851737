import { ChangeEvent, useState, MouseEvent, useEffect, useContext } from "react";
import Employee_Dropdown from "./Employee_Dropdown";
import { Emp_State } from "../../../../type";
import IsInVector from "../../../../functions/IsInVector";
import IndexInVector from "../../../../functions/IndexInVector";
import { Modal } from 'react-bootstrap';
import { StartupContext } from "../../../../App";
import "./css/DeptViewerEmps.css"

const DeptViewerEmps = ({ data }: any) => {

    const { appcolors } = useContext(StartupContext)

    let [hierdata, emps, newstate] = data
    let [empstyles, setEmpstyles] = useState<any[]>(emps.map((_: any) => { }))
    let defaultColor = appcolors.colorprimary
    let [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    let [rownames, setRownames] = useState<string[]>(['', '', '', '', ''])
    let [selEmp, setSelEmp] = useState<string>("")

    const clickedopen = () => { setIsModalVisible(true) };
    const clickedclose = () => { setIsModalVisible(false) };

    //reset the state when newstate changes
    useEffect(() => {
        console.log('uselayouteffect')
        setRownames(['', '', '', '', ''])
        setEmpstyles(emps.map((_: any) => { }))
    }, [emps, newstate])

    useEffect(() => {
        CenterEmp()
    }, [selEmp])

    const CenterEmp = () => {
        console.log('change center emp')

        let colNo: number[] = [1, 1, 2, 1, 1]; //2nd above to 2nd under

        setEmpstyles(emps.map((e: Emp_State) => {
            setRownames(['2nd Level Manager', '1st Level Manager', 'Employee', '1st Level Subsidiary', '2nd Level Subsidiary'])
            if (e.EmpID === selEmp) {
                return ({
                    "background": appcolors.colorhighlight,
                    "color": "black",
                    'gridColumn': '2/2', 'gridRow': '3/3'
                })
            } else {
                let descendant: boolean = false;  //check if e is a descendant of name, by iterating through hierdata
                let higherup: boolean = false;
                let coworker: boolean = false;
                let lvl: number = 0; //vertical

                hierdata.forEach((h: any) => {
                    //console.log("is "+ e.EmpID + " in " + h[1] + "? - " + IsInVector(h[1],e.EmpID))
                    if (h[0] === selEmp && IsInVector(h[1], e.EmpID)) {
                        higherup = true
                        lvl = IndexInVector(h[1], e.EmpID)
                    } else if (IsInVector(h[1], selEmp) && e.EmpID === h[0]) {
                        descendant = true
                        lvl = IndexInVector(h[1], selEmp)
                    } else if (h[1][0] === e.ReportsToID && selEmp === h[0]) {
                        coworker = true
                    } //if emp sup is in vector
                })
                if (descendant) { //rows 4 + 5
                    colNo[lvl + 3] += 1
                    return ({ 'backgroundColor': defaultColor, color: "white", 'gridRow': String(4 + lvl) + "/" + String(4 + lvl), 'gridColumn': colNo[lvl + 3] })
                } else if (higherup) { //rows 1 + 2
                    colNo[1 - lvl] += 1
                    return ({ 'backgroundColor': defaultColor, color: "white", 'gridRow': String(2 - lvl) + "/" + String(2 - lvl), 'gridColumn': colNo[1 - lvl] })
                } else if (coworker) { //row 3
                    colNo[2] += 1
                    return ({ 'backgroundColor': defaultColor, color: "white", 'gridRow': '3/3', 'gridColumn': colNo[2] })
                } else { //none
                    return ({ 'backgroundColor': 'white', 'visibility': 'hidden' })
                }
            } //endif
        }))
    }

    const DivClick = (e: MouseEvent<HTMLDivElement>) => {
        clickedopen()
        console.log(e)
        console.log(hierdata)
        //let empint = e.currentTarget.attributes[1].value
        //let selEmp: Emp_State = emps[empint]

    }

    const EmplGrid = () => {
        return (
            <div className="empGrid">

                {rownames.map((rw, i) => {
                    return (<div key={i + "_rw"} style={{ 'gridRow': String(i + 1) + "/" + String(i + 1), 'gridColumn': '1' }} >
                        {rw}
                    </div>)
                })}

                {emps.map((d: Emp_State, i: number) => {
                    return (
                        <div className="deptempbox" key={d.EmpID + 'a'} style={empstyles[i]} data-int={i} onClick={(e: MouseEvent<HTMLDivElement>) => DivClick(e)}>
                            <div className="deptemp_attribute">ID: {d.EmpID}</div>
                            <div className="deptemp_attribute">Reports To:{d.ReportsToID}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div style={{ 'position': 'relative' }}>
            <div id="dept_empddown">
                <Employee_Dropdown Employees={emps} keyfield="EmpID" textfield="Name"
                    change={(e: ChangeEvent<HTMLSelectElement>) => setSelEmp(e.target.selectedOptions[0].attributes[1].value)} />
            </div>
            <EmplGrid />
            <Modal show={isModalVisible}>
                <div>Employee Data</div>
                <button className="closemodalbtn" onClick={_ => clickedclose()}>Close</button>
            </Modal>
        </div>
    )

};
export default DeptViewerEmps;