
const ConvertDateToArray = (date1: Date) => {

    return [
        date1.getDate(),
        date1.getMonth() + 1,
        date1.getFullYear()
    ]

};
export default ConvertDateToArray;