

const FilterDatabyField = (data, objFldName, objValFldName,  filterFields, filterValues, except=false) => {

	//onfield can be an array for nested fields
    
	let returndata = []
	if (typeof(filterValues)!=="object"){
		filterValues=[filterValues]
	}	

	data.forEach(d => {
			//for nested fields, e.g., {Date: {id: '1', children:{child:"A"}}}
            let isinfilter = false;
            filterFields.forEach((f,i)=>{
                //console.log(d[objFldFld], f)    
               
                if  (d[objFldName[i]]===f){
                    //console.log(d[objFldFld], f)
                    
                    if (filterValues[i]===d[objValFldName[i]]) {
                        isinfilter = true
                    }               
                }
            })
            
            if (isinfilter && !except){
                returndata.push(d)
            } else if (!isinfilter && except) {
                returndata.push(d)
            }
	})
	return(returndata)
};

export default FilterDatabyField;