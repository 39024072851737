import { FieldMap, QueryModel, Metric, BenchModel, Key_Name } from "../../type"
import GetFieldName from "../../functions/GetFieldName"
import IsInVector from "../../functions/IsInVector"
import GetObjectField from "../../functions/Object Functions/GetObjectField"
import IsInObject from "../../functions/Object Functions/IsInObject"

const GetHeaderNames = (headers: any[], type: string, query: QueryModel, metrickeys: string[], metrics: Metric[],
    fields: FieldMap[], benchmarkmodels: BenchModel[], includebenchmark: Key_Name[]) => {
    //data key, cleaned headername, backend note
    let retarry: string[][] = []
    let metricindexmap: any[] = []
    let metrno = -1;
    // console.log(includebenchmark)
    //console.log(headers)


    headers.forEach((h, i) => {
        if (h === "Variable") {
            let fldname = GetFieldName(fields, query.XFieldName, "Key", "FieldName")
            if (fldname === "") {
                retarry.push(["Variable", query.XFieldName, 'xfld'])
            } else {
                retarry.push(["Variable", fldname, 'xfld'])
            }
            metricindexmap.push({ Key: metricindexmap.length, Name: metrno })
        } else if (h === "Grouping") {
            let fldname = GetFieldName(fields, query.GroupFieldName, "Key", "FieldName")
            if (fldname === "") {
                retarry.push(["Grouping", query.GroupFieldName, 'grpfld'])
            } else {
                retarry.push(["Grouping", fldname, 'grpfld'])
            }
            metricindexmap.push({ Key: metricindexmap.length, Name: metrno })
        } else {
            //metric
            if (type === "TableHorizontal") {
                retarry.push([h, h, '']) //single metric
            } else {
                //console.log(IsInVector(metrickeys, h), h)
                if (IsInVector(metrickeys, h)) {
                    if (h === "ValueCalc") {
                        retarry.push([h, "Value", ''])
                    } else {
                        retarry.push([h, GetObjectField(h, metrics, "MetricKey", "DisplayName"), ''])
                    }
                    metrno += 1
                    metricindexmap.push({ Key: metricindexmap.length, Name: metrno })
                    if (IsInObject(includebenchmark, h, "Key")) {
                        //retarry.push([h, "Benchmark", ""])
                        let y = GetObjectField(h, includebenchmark, "Key", "Name") //translate h as metrickey to y as benchmodelkey
                        let name = GetObjectField(y, benchmarkmodels, "ModelKey", "DisplayName") //translate benchmodelkey to displayname (for header)
                        retarry.push([y, name, ""])
                        metricindexmap.push({ Key: metricindexmap.length, Name: metrno })
                    }
                }
            }

        }
    })

    return ([retarry, metricindexmap])
}
export default GetHeaderNames