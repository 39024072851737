import axios from "axios";
import { fielddeleteActionCreator, fieldDataActionCreator } from "../../reducers/field_actions";
import { FieldMap } from "../../type";


const FieldsImport = async (modelcategory: string, schema: string, config: any, dispatcher: any, store: boolean) => {
    //ref: FiltersetsManage, AITest, AIQueryPrompt
    let flddata: FieldMap[] = []


    try {
        let res = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/api/fields/fieldretrieve`,
            { modelkey: modelcategory, schemakey: schema }, config);
        flddata = res.data
        if (store) {
            dispatcher(fielddeleteActionCreator({}))
            flddata.forEach((fld: FieldMap) => {
                dispatcher(fieldDataActionCreator(fld))
            })
        }
    } catch (error) {
        console.log(error)
    }

    return (flddata)
};
export default FieldsImport