
const FilterbyDates = (data: any[], onfield: string, beginDate: Date, endDate: Date) => {
    //ref:employeemodelviewer
    let filtdata: any[] = [];
    data.forEach((d: any) => {

        if (new Date(d[onfield]) >= beginDate && new Date(d[onfield]) <= endDate) {
            filtdata.push(d)
        }
    })

    return filtdata
};
export default FilterbyDates