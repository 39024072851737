function GetRandomHexadecimal(N: number) {
    const characters = '0123456789ABCDEF';
    let result = '';

    for (let i = 0; i < N; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return '#' + result; // Prefix with '0x' to indicate a hexadecimal value
};
export default GetRandomHexadecimal