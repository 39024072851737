
import AxiosUpload from '../AxiosUpload';

const HeadersUpload = (formdata: any, schemakey: string, modelcategory: string, isAdd: string, elementstring: string, config: any) => {

    let dataArray = new FormData();
    dataArray.append("headerID", formdata.id);
    dataArray.append("headerName", formdata.HeaderName);
    dataArray.append("fldKey", formdata.Key);
    dataArray.append("add", isAdd);
    dataArray.append("schemakey", schemakey);

    dataArray.append("modelcategory", modelcategory);

    let elem: HTMLElement | null = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..."; }

    AxiosUpload(dataArray, "api/fields/headerupload", config, elem, "Changed successfully")

};

export default HeadersUpload;