function RbgaToHex(colorrgb) {
    //ref: createtabled3
    colorrgb = String(colorrgb) //format rgba(0,1,3,.2)

    if (colorrgb==="white"){
        return "#ffffff"
    } else if (colorrgb==="black"){
        return "#000000"
    }

    let [r,g,b,a] = String(colorrgb).replace("rgb(","").replace(")","").split(",")

    // Ensure that the values are within the valid range (0-255)
    r = Math.max(0, Math.min(255, +r));
    g = Math.max(0, Math.min(255, +g));
    b = Math.max(0, Math.min(255, +b));

    // Convert each component to hexadecimal and concatenate
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');

    // Combine the components to form the final hexadecimal color code
    const hexColor = `#${hexR}${hexG}${hexB}`;

  return hexColor.toUpperCase(); // Convert to uppercase for consistency
}
export default RbgaToHex;