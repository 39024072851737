import IsInVector from "../IsInVector";

const DatatoFormArray = (data: any) => {

    let dataArray = new FormData();
    const nondatekeys = ['rundates', 'rundatekeys']

    Object.keys(data).forEach((key: string) => {
        if (!data[key]) { //null value
            dataArray.append(key, String(data[key]));
            //console.log("null - ", key, data[key])
        } else {
            if (typeof (data[key]) === "object") {
                try {
                    if (data[key]["lastModifiedDate"]) { //file type
                        dataArray.append(key, data[key])
                    } else if (data[key].length > 0) {
                        data[key].forEach((obj: any, i: number) => {
                            if (obj["lastModifiedDate"]) {
                                dataArray.append(key + String(i), obj)
                            }
                        })
                    }
                }
                catch (_) { }
            }
            if (typeof (data[key]) === "object" && data[key] && key.toLowerCase().includes("date", 0) && !IsInVector(nondatekeys, key)) {
                dataArray.append(key + "_dy", String(data[key].getDate()));
                dataArray.append(key + "_mo", String(data[key].getMonth() + 1));
                dataArray.append(key + "_yr", String(data[key].getFullYear()));
            } else if (data[key] == null) {
                dataArray.append(data[key], data[key]);
            } else if (typeof (data[key]) === "object") {
                if (String(data[key].length) !== "undefined") {
                    //array
                    let arry = data[key].map((itm: any) => {
                        if (typeof (itm) === "object") {
                            let subarry = Object.keys(itm).map((ik: string) => {
                                return String(itm[ik])
                            })
                            return (subarry)
                        } else if (!itm["lastModifiedDate"]) {
                            return (String(itm))
                        }
                    })
                    dataArray.append(key, arry)
                } else {
                    //pure object
                    Object.keys(data[key]).forEach((subkey: string) => {
                        dataArray.append(key + "_" + subkey, String(data[key][subkey]));
                    })
                }

            } else {
                dataArray.append(key, String(data[key]));
            }
        }
    })

    return (dataArray)
};


export default DatatoFormArray;