import { Dashboard } from "../../../../type";

const DashboardDropdown = (props: any) => {

    let change = props.change;
    let dashboards = props.dashboards;
    let className = props.className;

    return (
        <select id="dropdown_dash" onChange={change} className={className}>
            <option key='1' value={"none"} data-name={"none"}>
                Select Dashboard
            </option>
            {dashboards ? dashboards.map((d: Dashboard, i: number) => {
                return (
                    <option key={"db" + i} value={d.Key} data-name={d.Name}>
                        {d.Name}
                    </option>
                )

            }) : <option></option>
            }
        </select>
    )
};
export default DashboardDropdown;