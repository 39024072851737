import { useState, useContext, ChangeEvent, useEffect } from "react";
import { StartupContext } from "../../../App";
import { Modal } from 'react-bootstrap';
import FieldDropdown from "../fields/subfunctions/FieldDropdown";
import DatePicker from "react-date-picker";
import { FilterModel, RuleModel, ColorModel, queryparams, Metric, TableModel, FieldMap, ConnectionModel, Key_Name } from "../../../type";
import SampleGraph from "../../../charts/sample/SampleGraph";
import QueryModelUpload from "../../../data/Query/QueryModel_Upload";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ImportAndSetState from "../../../functions/Import/ImportAndSetState";
import RuleModelImport from "../../../data/Rulesets/RuleModel_Import";
import FilterModelImport from "../../../data/Filtersets/FilterModel_Import";
import { ChartSeriesInit, queryparamsInit, tblmappinginit } from "../../../InitTypes";
import DateString from "../../../functions/Date Functions/DateString";
import PeriodDropdown from "../../../functions/Dropdowns/PeriodDropdown";
import IsInVector from "../../../functions/IsInVector";
import ColorModelImport from "../../../data/ColorModel/ColorModel_Import";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import DateRemoveTimestamp from "../../../functions/Date Functions/DateRemoveTimestamp";
import ModelCategoryDropdown from "../../../functions/Dropdowns/ModelCategoryDropdown";
import GetFieldName from "../../../functions/GetFieldName";
import FieldsImport from "../../../data/Fields/Fields_Import";
import CalculateStickyDate from "../../../functions/Date Functions/CalculateStickyDate";
import CalculateDateSequence from "../../../functions/Date Functions/CalculateDateSequence";
import { Link } from "react-router-dom";
import "./css/GraphCreator.css"
import FilterData from "../../../functions/FilterData";
import GetObjectValue from "../../../functions/Object Functions/GetObjectValue";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import GetObjectfromArray from "../../../functions/Array Functions/GetObjectfromArray";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import GenericsUniqueImport from "../../../data/Generics/GenericsUnique_Import";
import StringTrim from "../../../functions/String Functions/StringTrim";
import ArraySliceValue from "../../../functions/Array Functions/ArraySliceValue";


const GraphCreator = () => {

    const { config, dispatcher, schema, appcolors, user, paths } = useContext(StartupContext)
    interface tempparams_type {
        changetable: boolean; modelcat_x: string; modelcat_grp: string; perioddatesize: number;
        grouptbls: string[]; detail_mcats: string[], filter_mcats: string[], metricfields: string[];
        simplefltmodelcat: string; overlap_error: string;
    }
    interface modelparams_type {
        metrmodels: string[][]; uniquemodels: string[]; listdetails: { Model: string, Value: Key_Name[] }[];
        checkedmodels: boolean; modelrefresh: number;
    }

    let [isModalVisible, setIsModalVisible] = useState({
        0: false, 1: false, 2: false, 3: false, 4: false,
        5: false, 6: false, 7: false, 8: false, 9: false,
        10: false, 11: false, 12: false, 13: false, 14: false,
    });
    //let tablegroups = useSelector((state: any) => { return state.tablegroups })

    const [ruleModels, setRuleModels] = useState<RuleModel[]>([])
    const [fltModels, setFltModels] = useState<FilterModel[]>([])
    const [colorModels, setColorModels] = useState<ColorModel[]>([])
    const [metrics, setMetrics] = useState<Metric[]>([])
    //const [connModels, setConnModels] = useState<ConnectionModel[]>([]) //use connection model of the metric(s) selected

    const [fields, setFields] = useState<FieldMap[]>([])

    const [params, setParams] = useState<queryparams>(queryparamsInit);
    const [paramsUser, setparamsUser] = useState<queryparams>(queryparamsInit);
    const [tempparams, setTempParams] = useState<tempparams_type>({
        grouptbls: [], changetable: false, metricfields: [],
        modelcat_x: '', modelcat_grp: '', detail_mcats: [], filter_mcats: [], perioddatesize: 1,
        simplefltmodelcat: '', overlap_error: "",
    })
    const [modelparams, setModelParams] = useState<modelparams_type>({
        metrmodels: [], uniquemodels: [], listdetails: [],
        checkedmodels: false, modelrefresh: 0
    })

    //const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const clickedopen = (val: number) => { setIsModalVisible({ ...isModalVisible, [val]: true }) };
    const clickedclose = (val: number) => { setIsModalVisible({ ...isModalVisible, [val]: false }) };
    const [showBucket, setShowBucket] = useState<number>(0);
    const [complete, setComplete] = useState<boolean>(false);
    const [df_errmsg, setDf_errmsg] = useState<string[]>(["", "", "", ""])
    const [flt_errmsg, setFlt_errmsg] = useState<string[]>(["", "", "", ""])

    const [metr_errmsg, setMetr_errmsg] = useState<string[]>(["", "", "", "", "", ""])
    const [tables, setTables] = useState<TableModel[]>([])
    const [xdates, setXDates] = useState<Date[][]>([])
    const [uniquevals, setUniqueVals] = useState<any>([])


    const styleUnselected = {
        backgroundColor: "rgb(30,30,30)", color: "white", border: "1px solid " + appcolors.colorsecondary,
        boxShadow: "0px 0px 0px " + appcolors.colorhighlight
    }
    const styleSelected = {
        backgroundColor: "rgb(100,100,100)", color: "white", border: "1px solid " + appcolors.colorsecondary,
        boxShadow: "0px 0px 8px " + appcolors.colorhighlight
    }

    let divStyleIncomplete = { backgroundColor: appcolors.colorfade1, opacity: .7, border: "1px solid white", boxShadow: "-1px 1px 2px #9592bf, 1px 1px 2px #65655f, -1px -1px 2px #65655f, 1px 1px 2px #65655f" }
    let divStyleComplete = { backgroundColor: appcolors.colorprimary, opacity: 1, border: "1px solid white", boxShadow: "-1px 1px 4px #f5f557, 1px 1px 4px #f5f557, -1px -1px 4px #f5f557, 1px 1px 4px #f5f557" }

    interface buckets {
        "Metrics": any;
        "DatePeriod": any;
        "AsofDate": any;
        "Filters": any;
        "Connections": any;
        "XAxis": any;
        "Grouping": any;
        "Banding": any;
        "Detailfields": any;
        "Filterfields": any;
        "SimpleFilter": any;
    }
    let bucketinit: buckets = {
        Metrics: divStyleIncomplete, DatePeriod: divStyleIncomplete, AsofDate: divStyleIncomplete,
        Filters: divStyleIncomplete, Connections: divStyleIncomplete, XAxis: divStyleIncomplete,
        Grouping: divStyleIncomplete, Banding: divStyleIncomplete, Detailfields: divStyleIncomplete,
        Filterfields: divStyleIncomplete, SimpleFilter: divStyleIncomplete
    }

    const [bucketStyle, setBucketStyle] = useState<buckets>(bucketinit)
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    //need an error for if metric agg field overlaps with groupfield    
    useEffect(() => {
        //imports
        //ImportAndSetStateGeneric(setDashboards, "", schema, paths.dashboard.dashnameview, user, config, { permissions: ["manage", 'read/write'] })
        ImportAndSetState(RuleModelImport, setRuleModels, "", schema, config, dispatcher, false)
        ImportAndSetState(FilterModelImport, setFltModels, "", schema, config, dispatcher, false)
        ImportAndSetState(ColorModelImport, setColorModels, "", schema, config, dispatcher, false)
        ImportAndSetStateGeneric(setMetrics, "", schema, paths.query.metricview, user, config)
        ImportAndSetStateGeneric(setTables, "*", schema, paths.tables.tablemodelview, user, config, {})
        FieldsImport("", schema, config, dispatcher, false).then(d => { setFields(d); })
        setParams({ ...queryparamsInit, metrics: [] })
    }, []);


    useEffect(() => {
        if (metrics.length > 0) {
            setParams(queryparamsInit)
            let names = params.metrics.map((metr: string) => {
                return GetObjectField(metr, metrics, "MetricKey", "MetricName")
            })
            setparamsUser({ ...paramsUser, metrics: names })
        }
    }, [metrics])

    //models key to name
    useEffect(() => {
        SetModelName(ruleModels, params.ruleModelKey, "ruleModelName", "ModelKey", 'ModelName')
    }, [params.ruleModelKey])

    useEffect(() => {
        SetModelName(fltModels, params.filterModelKey, "filterModelName", "ModelKey", 'ModelName')
    }, [params.filterModelKey])

    useEffect(() => {
        SetModelName(colorModels, params.colorModelKey, "colorModelName", "ModelKey", 'ModelName')
    }, [params.colorModelKey])

    const SetModelName = (data: any[], keystr: string, paramnamefld: string, keyfld: string, namefld: string) => {
        let name = GetObjectField(keystr, data, keyfld, namefld)
        setparamsUser({ ...paramsUser, [paramnamefld]: name })
    }
    useEffect(() => {
        let overlaperr = ""
        let allfields = params.detailfields.concat([params.xfieldname], [params.groupfield], params.filterfields)
        allfields.forEach((fldkey: string) => {
            let idx1: number = tempparams.metricfields.indexOf(fldkey)
            if (idx1 > -1) {
                overlaperr = "Field " + GetFieldName(fields, fldkey) + " conflicts with Metric " + GetObjectField(tempparams.metricfields[idx1], metrics, "MetricKey", "MetricName")
            }
        })
        setTempParams({ ...tempparams, overlap_error: overlaperr })
    }, [tempparams.metricfields, params.groupfield, params.xfieldname, params.detailfields, params.filterfields])

    //-----dates
    useEffect(() => {
        setparamsUser({ ...paramsUser, includetodate: params.includetodate })
    }, [params.includetodate])


    const ClickSingleRange = () => {
        if (params.nperiods === 1) {
            setParams({ ...params, nperiods: 6, useMulti: true })
            setparamsUser({ ...paramsUser, nperiods: 6, useMulti: true })
        } else {
            setParams({ ...params, nperiods: 1, useMulti: false })
            setparamsUser({ ...paramsUser, nperiods: 1, useMulti: false })
        }
    }

    useEffect(() => {
        if (params.period === "None") {
            setParams({ ...params, iperiod: 0, nperiods: 0, xfieldname: '' })
            setparamsUser({ ...paramsUser, iperiod: 0, nperiods: 0, xfieldname: '' })
        } else if (params.nperiods === 0) {
            setParams({ ...params, iperiod: 1, nperiods: 6 })
            setparamsUser({ ...paramsUser, iperiod: 1, nperiods: 6 })
        }
    }, [params.period])

    useEffect(() => {
        if (params.groupfield == "none") {
            setParams({ ...params, useBanding: false, groupfield: "none", bandgroupname: "Other" })
            setparamsUser({ ...paramsUser, useBanding: false, groupfield: "none", bandgroupname: "Other" })
        } else if (params.useGrouping && !params.useBanding) {
            setParams({ ...params, useBanding: false })
            setparamsUser({ ...paramsUser, useBanding: false })
        }

    }, [params.groupfield])

    useEffect(() => {
        if (params.useBanding && params.bandgroupname === "") {
            setParams({ ...params, bandgroupname: "Other" })
        }
    }, [params.useBanding])

    useEffect(() => {
        if (params.period === "Monthly") {
            setTempParams({ ...tempparams, perioddatesize: 31 * params.iperiod })
        } else if (params.period === "Yearly") {
            setTempParams({ ...tempparams, perioddatesize: 365 * params.iperiod })
        } else if (params.period === "Weekly") {
            setTempParams({ ...tempparams, perioddatesize: 7 * params.iperiod })
        } else if (params.period === "Daily") {
            setTempParams({ ...tempparams, perioddatesize: 1 * params.iperiod })
        }
    }, [params.stickydateref])

    useEffect(() => {
        //calculated asofdate
        let newasofdate = CalculateStickyDate(params.asofDate, params.stickydateref, params.period, params.iperiod, params.offsetdays, params.includetodate, false)
        setParams({ ...params, endDate: newasofdate })
    }, [params.offsetdays, params.stickydateref, params.includetodate, params.asofDate])

    useEffect(() => {
        let hist = CalculateDateSequence(params.endDate, params.period, params.nperiods, params.iperiod, false)
        setXDates(hist)
    }, [params.offsetdays, params.stickydateref, params.nperiods, params.endDate, params.period, params.iperiod, tempparams.perioddatesize])

    useEffect(() => {
        if (params.metrics.length > 0) {
            //let ctables: string[][] = []
            let cmdls: string[] = []
            let tbls: string[] = []
            params.metrics.forEach((m: string) => {
                let metr = GetObjectfromArray(metrics, m, "MetricKey")
                cmdls.push(metr.ConnectionModelName)
                tbls.push(metr.Table)
            })

            GenericBackendCall("", schema, paths.tables.connectionnetwork, user, config, { modelkeys: cmdls, tables: tbls }).then(tblgrps => {
                setTempParams({ ...tempparams, grouptbls: tblgrps })
            })
        }
    }, [metr_errmsg])

    //--models
    useEffect(() => {
        if (isModalVisible[0]) {
            QueryModelUpload(params, "", schema, user, config, dispatcher, "gc_uploaddiv", false).then(d => {
                let mdls: any = d
                let mdlcount: number = 0

                let mdlchoices = mdls.map((metrmdls: string[]) => {
                    return metrmdls.map((m: string) => {
                        mdlcount += 1
                        return ({ Key: m, Name: '' })
                    })
                })
                let completed = false
                if (mdlcount === 0) {
                    completed = true
                }
                setModelParams({ ...modelparams, checkedmodels: completed, metrmodels: mdls })
                setParams({ ...params, metrmodelchoices: mdlchoices })
            });
        }
    }, [isModalVisible[0]])

    useEffect(() => {
        if (modelparams.metrmodels.length > 0) {
            //console.log(modelparams.metrmodels)

            let ulist: string[] = []
            modelparams.metrmodels.forEach((mdl: string[]) => {
                mdl.forEach((m: string) => {
                    if (!IsInVector(ulist, m)) {
                        ulist.push(m)
                    }
                })
            })
            setModelParams({ ...modelparams, uniquemodels: ulist })
        }
    }, [modelparams.metrmodels])

    useEffect(() => {
        if (modelparams.uniquemodels.length > 0) {
            //confirm this still works - 7.31
            GenericBackendCall('', schema, paths.generics.genericmodelnames, user, config, { models: modelparams.uniquemodels }).then(d => {
                let modellists: any[] = d
                console.log(d)
                setModelParams({ ...modelparams, listdetails: modellists })
            })

        }
    }, [modelparams.uniquemodels])


    //--------------------------------
    //check

    useEffect(() => {
        let elem: HTMLCollectionOf<Element> = document.getElementsByClassName("gc_minibutton")
        Array.from(elem).forEach((e: Element) => {
            let lmnt: HTMLElement | null = document.getElementById(e.id)
            if (lmnt) {
                if (e.id === "gc_mini" + showBucket) {
                    lmnt.style.border = "3px solid " + appcolors.colorsecondary
                    lmnt.style.boxShadow = "0px 0px 15px violet"
                } else {
                    lmnt.style.border = "1px solid " + appcolors.colorsecondary
                    lmnt.style.boxShadow = ""
                }
            }
        })
    }, [showBucket]);

    const CheckDiv = (div: any) => {
        if (div['opacity'] === 1) {
            return true
        } else {
            return false
        }
    }

    const CheckParam = (checkbuckets: any, bucket: string, param: string[], type: string[]) => {
        let oparams: any = params
        let truth: boolean = true
        param.forEach((p: string, i: number) => {
            if (type[i] === "number") {
                if (oparams[p] <= 0) {
                    truth = false
                }
            } else if (type[i] === "text") {
                if (oparams[p] === "") {
                    truth = false
                }
            } else if (type[i] === "array") {
                if (oparams[p].length === 0) {
                    truth = false
                }
            }
        })
        if (truth) {
            checkbuckets[bucket] = divStyleComplete
        } else {

            checkbuckets[bucket] = divStyleIncomplete
        }
        return checkbuckets
    }


    useEffect(() => {
        let checkbuckets = bucketinit;
        checkbuckets = CheckParam(checkbuckets, "Metrics", ["metrics"], ["array"])
        checkbuckets = CheckParam(checkbuckets, "AsofDate", ["stickydateref"], ["text"])
        checkbuckets = CheckParam(checkbuckets, "DatePeriod", ["period"], ["text"]) //date, text
        checkbuckets = CheckParam(checkbuckets, "Filters", ["filterModelKey", "ruleModelKey"], ["text", "text"])
        checkbuckets = CheckParam(checkbuckets, "Connections", ["mappingSet"], ["text"])
        checkbuckets = CheckParam(checkbuckets, "XAxis", ["xfieldname"], ["text"])
        checkbuckets = CheckParam(checkbuckets, "Grouping", ["groupfield"], ["text"])
        checkbuckets = CheckParam(checkbuckets, "Banding", ["bandgroupname"], ["text"])
        checkbuckets = CheckParam(checkbuckets, "Detailfields", ["detailfields"], ["array"])
        checkbuckets = CheckParam(checkbuckets, "Filterfields", ["filterfields"], ["array"])
        checkbuckets = CheckParam(checkbuckets, "SimpleFilter", ["simplefiltervalues"], ["array"])
        setBucketStyle(checkbuckets)
    }, [params])


    useEffect(() => {
        if (params.newparamset) {
            setParams({ ...params, newparamset: false })
        }
        //check group 1
        let elem1 = document.getElementById("gc_mini1")
        if (CheckDiv(bucketStyle.Metrics) && CheckDiv(bucketStyle.DatePeriod) && CheckDiv(bucketStyle.AsofDate)) {
            if (elem1) { elem1.style.backgroundColor = 'red' }
        }
        //check group 2
        let elem2 = document.getElementById("gc_mini2")
        if (CheckDiv(bucketStyle.Filters)) {
            if (elem2) { elem2.style.backgroundColor = 'red' }
        }
        //check group 3
        let elem3 = document.getElementById("gc_mini3")
        if (CheckDiv(bucketStyle.Connections)) {
            if (elem3) { elem3.style.backgroundColor = 'red' }
        }
        //check group 4
        let elem4 = document.getElementById("gc_mini4")
        if (CheckDiv(bucketStyle.XAxis) && CheckDiv(bucketStyle.Grouping) && CheckDiv(bucketStyle.Banding)) {
            if (elem4) { elem4.style.backgroundColor = 'red' }
        }

        let elem5 = document.getElementById("gc_mini4")
        if ((CheckDiv(bucketStyle.Detailfields) && CheckDiv(bucketStyle.Filterfields)) || CheckDiv(bucketStyle.SimpleFilter)) {
            if (elem5) { elem5.style.backgroundColor = 'red' }
        }

        if (tempparams.overlap_error === "") {
            if (elem1 && elem2 && elem3 && elem4) {
                if (elem1.style.backgroundColor === 'red' && elem2.style.backgroundColor === 'red'
                    && elem3.style.backgroundColor === 'red' && elem4.style.backgroundColor === 'red') {
                    setComplete(true)
                }
            }
        }

    }, [bucketStyle]);

    useEffect(() => {
        if (params.simplefilterfield !== "") {
            GenericsUniqueImport([params.simplefilterfield], schema, fields, config, dispatcher).then(d => {
                let ser_data = SerializeDataFrame(d, ["Fld"])
                setUniqueVals(ser_data)
            })
        }
    }, [params.simplefilterfield])

    useEffect(() => {
        SampleGraph(params, "#gc_samplegraph", [500, 500])
    }, [params.nperiods, params.useGrouping, params.colors, params.chartType])

    const ChangeAttrBool = (e: ChangeEvent<HTMLSelectElement>, param: string, secondparam: string) => {
        let tgt = e.target.selectedOptions[0]
        let val = tgt.attributes[0].value
        let secondparamstr: string = tgt.attributes[1].value

        if (val === "true") {
            setParams({ ...params, [param]: true, [secondparam]: secondparamstr })
            setparamsUser({ ...paramsUser, [param]: true, [secondparam]: secondparamstr })
        } else if (val === "false") {
            setParams({ ...params, [param]: false, [secondparam]: secondparamstr })
            setparamsUser({ ...paramsUser, [param]: false, [secondparam]: secondparamstr })
        }
    }

    const ChangeAttrSel = (e: ChangeEvent<HTMLSelectElement>, param: string, isfield: boolean = false) => {
        let tgt = e.target.selectedOptions[0]
        let val = tgt.attributes[0].value
        let valUser = tgt.innerText
        if (isfield) { valUser = tgt.attributes[2].value + " - " + tgt.attributes[1].value }

        if (val != "") {
            setParams({ ...params, [param]: val })
            setparamsUser({ ...paramsUser, [param]: valUser })
        }

    };

    const ChangeAttrInput = (e: ChangeEvent<HTMLInputElement>, param: string, isnumber: boolean = false) => {
        let targtval: string | number = e.target.value
        if (isnumber) { targtval = +targtval }
        setParams({ ...params, [param]: targtval })
        setparamsUser({ ...paramsUser, [param]: targtval })
    };

    const ChangeAttrDate = (dateval: Date, param: string) => {
        let datestr = dateval.getMonth() + 1 + "-" + dateval.getDate() + "-" + dateval.getFullYear()
        setParams({ ...params, [param]: DateRemoveTimestamp(dateval) })
        setparamsUser({ ...paramsUser, [param]: datestr })
    };


    const SaveModel = () => {
        QueryModelUpload(params, "", schema, user, config, dispatcher, "gc_uploaddiv").then(d => {
            console.log(d)
        });
        //savesave
        let save_elem = document.getElementById("gc_save")
        if (save_elem) {
            save_elem.style.background = "green"
        }
    };

    //bucket divs
    const Bucket1 = () => {
        return (
            <div>
                <div className="ndt_title3" style={{ marginTop: "10px", marginLeft: "1%", fontSize: "22px" }}>Report Parameters</div>
                <div id="gc_bucket1canvas" style={{ overflow: 'hidden' }}>
                    <div>
                        <div id="gc_b1Metr" style={bucketStyle.Metrics} className="gc_bucket gcbucket1" onClick={_ => clickedopen(1)}>
                            Metrics:
                            {paramsUser.metrics.map((metr: string, n: number) => {
                                return (<div key={n}>Metric {n + 1}: {metr}</div>)
                            })}

                        </div>

                        {/* end/asof date */}
                        <div id="gc_b1AOD" style={{ ...bucketStyle.AsofDate, height: '150px' }} className="gc_bucket gcbucket1" onClick={_ => clickedopen(2)}>
                            <div>As of Date: {DateString(params.asofDate)}</div>
                            <div>Rpt End Date: {DateString(params.endDate)}</div>
                        </div>

                        {/* iterate date */}
                        <div id="gc_b1date" style={bucketStyle.DatePeriod} className="gc_bucket gcbucket1" onClick={_ => clickedopen(3)}>
                            Date Period:
                            {paramsUser.period !== "" ?
                                <div>Period: {paramsUser.period} ({paramsUser.iperiod})</div>
                                : <div>Period:</div>}
                            <div>Num Periods: {paramsUser.nperiods}</div>
                            <div>{paramsUser.includetodate}</div>
                        </div>


                    </div>

                    {/* Show Dates*/}

                    {params.stickydateref !== "" && params.period !== "" && xdates.length > 0 ?
                        <div>
                            <div style={{ fontSize: "20px", marginLeft: "20px" }}>X Axis Date Ranges</div>
                            {xdates.map((date: Date[], i: number) => {
                                if (i <= 7) {
                                    return (
                                        <div key={i} style={{ height: '50px', fontSize: "16px", width: "240px" }} className="gc_bucket gcdate1">
                                            ({DateString(date[0])}) - {DateString(date[1])}
                                        </div>
                                    )
                                } else if (i === 9) {
                                    return (<div key={"_etc"} style={{ marginLeft: "10px", fontSize: '26px' }}>...</div>)
                                }
                            })}
                        </div>
                        : <div></div>}
                </div>
            </div>)
    };
    const Bucket2 = () => {
        return (
            <div>
                <div className="ndt_title3" style={{ marginTop: "10px", marginLeft: "1%" }}>Filter Parameters</div>

                <div id="gc_b2flt" style={{ ...bucketStyle.Filters, height: "150px" }} className="gc_bucket gcbucket1" onClick={_ => clickedopen(4)}>

                    <div>Filter: {paramsUser.filterModelName}</div>
                    <div>Exceptions: {paramsUser.ruleModelName}</div>
                </div>
            </div>
        )
    };
    const Bucket3 = () => {
        return (
            <div>
                <div className="ndt_title3" style={{ marginTop: "10px", marginLeft: "1%" }}>Connection Parameters</div>

                <div id="gc_b3conn" style={bucketStyle.Connections} className="gc_bucket gcbucket1" onClick={_ => clickedopen(5)}>

                    <div>Connect to: </div>
                    <div> As of:  </div>
                </div>
            </div>
        )
    };

    const Bucket4 = () => {
        return (
            <div>
                <div className="ndt_title3" style={{ marginTop: "10px", marginLeft: "1%" }}>Other Parameters</div>
                <div id="gc_b4xaxis" style={bucketStyle.XAxis} className="gc_bucket gcbucket1" onClick={_ => clickedopen(6)}>
                    <div>X Axis Field: {paramsUser.xfieldname}</div>
                </div>

                <div id="gc_b4grp" style={bucketStyle.Grouping} className="gc_bucket gcbucket1" onClick={_ => clickedopen(7)}>
                    <div>Grouping Field {paramsUser.groupfield}</div>
                </div>

                <div id="gc_b4band" style={{ ...bucketStyle.Banding, height: "150px" }} className="gc_bucket gcbucket1" onClick={_ => clickedopen(8)}>
                    {paramsUser.useBanding ?
                        <div>
                            <div>Banding {paramsUser.fieldBand}</div>
                            <div>Group Name {paramsUser.bandgroupname}</div>
                        </div> : <div>No Banding</div>}
                </div>
            </div>
        )
    };

    const Bucket5 = () => {
        return (
            <div>
                <div className="ndt_title3" style={{ marginTop: "10px", marginLeft: "1%" }}>Other Parameters</div>

                <div style={bucketStyle.Detailfields} className="gc_bucket gcbucket1" onClick={_ => clickedopen(9)}>
                    <div>Detail Fields</div>
                </div>

                <div style={bucketStyle.Filterfields} className="gc_bucket gcbucket1" onClick={_ => clickedopen(10)}>
                    <div>Filter Fields</div>
                </div>

                <div style={bucketStyle.SimpleFilter} className="gc_bucket gcbucket1" onClick={_ => clickedopen(11)}>
                    <div>Simple Filter</div>
                </div>

            </div>
        )
    }

    const ChangeBandingState = (e: ChangeEvent<HTMLSelectElement>) => {
        let val = e.target.selectedOptions[0].attributes[0].value
        if (val === "false") {
            let bgn = params.bandgroupname
            if (params.bandgroupname === "") { bgn = "Other" }
            setParams({ ...params, fieldBand: 6, useBanding: false, bandgroupname: bgn })
            setparamsUser({ ...paramsUser, fieldBand: 6, useBanding: false, bandgroupname: bgn })
        } else if (val === "true") {
            setParams({ ...params, fieldBand: 6, useBanding: true })
            setparamsUser({ ...paramsUser, fieldBand: 6, useBanding: true })
        }
    }

    const ChangeTblMapping = (e: ChangeEvent<HTMLSelectElement>, tbl: string) => {
        let tmpmap: any = params.mappings;
        tmpmap[tbl] = e.target.value
        setParams({ ...params, "mappings": tmpmap })
    }

    const ClickXAxisDate = () => {
        let datefld = GetFieldName(FilterData(fields, "ModelCategory", "EmployeeState"), "Date", "AttrName", "Key")
        setParams({ ...params, xfieldname: datefld })
        setparamsUser({ ...paramsUser, xfieldname: "Date" })
    }



    const AddDetailMCat = (e: ChangeEvent<HTMLSelectElement>, tmpparam: string, n: number) => {
        let val = e.currentTarget.selectedOptions[0].attributes[0].value
        let cats = GetObjectValue(tempparams, tmpparam)
        let newcats = cats
        if (n >= cats.length) {
            newcats.push(val)
        } else {
            newcats[n] = val
        }
        setTempParams({ ...tempparams, [tmpparam]: newcats })
    }

    const AddDetailField = (e: ChangeEvent<HTMLSelectElement>, n: number) => {
        let val = e.currentTarget.selectedOptions[0].attributes[0].value

        if (val === params.xfieldname || val == params.groupfield && val !== "") {
            setDf_errmsg({ ...df_errmsg, [n]: "cant add - already being incorporated" })
        } else {
            if (!IsInVector(params.detailfields, val)) {
                let tmp = params.detailfields
                if (n <= tmp.length) {
                    tmp[n] = val
                } else {
                    tmp.push(val)
                }
                setParams({ ...params, detailfields: tmp })
                setDf_errmsg({ ...df_errmsg, [n]: GetFieldName(fields, val, "Key", "FieldName") })
            }
        }
    }

    const AddFilterField = (e: ChangeEvent<HTMLSelectElement>, n: number) => {
        let val = e.currentTarget.selectedOptions[0].attributes[0].value

        if (val === params.xfieldname || val == params.groupfield && val !== "") {
            setFlt_errmsg({ ...flt_errmsg, [n]: "cant add - already being incorporated" })
        } else {
            if (!IsInVector(params.filterfields, val)) {
                let tmp = params.filterfields
                if (n <= tmp.length) {
                    tmp[n] = val
                } else {
                    tmp.push(val)
                }
                setParams({ ...params, filterfields: tmp })
                setFlt_errmsg({ ...flt_errmsg, [n]: GetFieldName(fields, val, "Key", "FieldName") })
            }
        }
    }


    const GetMetricNames = (metrkeys: string[]) => {

        let metricnames: string[] = metrkeys.map((m: string) => {
            let fndval = ""
            metrics.forEach((M: Metric) => {
                if (m === M.MetricKey) {
                    fndval = M.MetricName
                }
            })
            if (fndval !== "") {
                return fndval
            } else {
                return ""
            }
        })
        return metricnames
    }
    const AddMetric = (e: ChangeEvent<HTMLSelectElement>, n: number) => {
        let int: number = +e.currentTarget.selectedOptions[0].attributes[0].value
        if (int >= 0) {
            let val: string = metrics[int].MetricKey
            //let name: string = metrics[int].MetricName

            // if (!IsInVector(params.metrics, val)) {//allowing duplicates now
            let tmp = params.metrics;
            let cstmp = params.chartseries;
            let newcs = ChartSeriesInit[0];
            newcs.MetricName = val

            if (n <= tmp.length) {
                tmp[n] = val
                cstmp[n] = newcs
            } else {
                tmp.push(val)
                cstmp.push(newcs)
            }

            let newmetrfields = tempparams.metricfields
            if (tempparams.metricfields.indexOf(metrics[int].AggregateField) === -1) {
                newmetrfields.push(metrics[int].AggregateField)
            }
            if (tempparams.metricfields.indexOf(metrics[int].MeasurementField) === -1) {
                newmetrfields.push(metrics[int].MeasurementField)
            }

            setParams({ ...params, metrics: tmp, chartseries: cstmp })
            setparamsUser({ ...paramsUser, metrics: GetMetricNames(tmp), chartseries: cstmp })
            setMetr_errmsg({ ...metr_errmsg, [n]: "Set " + metrics[int].MetricName })
            setTempParams({ ...tempparams, metricfields: newmetrfields })
            // }// } else {
            //     setMetr_errmsg({ ...metr_errmsg, [n]: "already applied this metric!" })
            // }
        }
    }


    const RmvMetric = () => {
        if (metr_errmsg[params.metrics.length] === "") {
            setParams({ ...params, metrics: params.metrics.slice(0, -1) })
            setparamsUser({ ...paramsUser, metrics: GetMetricNames(params.metrics.slice(0, -1)) })
            setMetr_errmsg({ ...metr_errmsg, [params.metrics.length - 1]: "" })
        } else {
            setMetr_errmsg({ ...metr_errmsg, [params.metrics.length]: "" })
        }
    };

    const RmvDetailField = () => {
        if (df_errmsg[params.detailfields.length] === "") {
            setParams({ ...params, metrics: params.detailfields.slice(0, -1) })
            setparamsUser({ ...paramsUser, detailfields: GetMetricNames(params.detailfields.slice(0, -1)) })
            setDf_errmsg({ ...df_errmsg, [params.detailfields.length - 1]: "" })
        } else {
            setDf_errmsg({ ...df_errmsg, [params.detailfields.length]: "" })
        }
    };

    const RmvFiltField = () => {
        if (flt_errmsg[params.filterfields.length] === "") {
            setParams({ ...params, metrics: params.filterfields.slice(0, -1) })
            setparamsUser({ ...paramsUser, filterfields: GetMetricNames(params.filterfields.slice(0, -1)) })
            setFlt_errmsg({ ...flt_errmsg, [params.filterfields.length - 1]: "" })
        } else {
            setFlt_errmsg({ ...flt_errmsg, [params.filterfields.length]: "" })
        }
    };

    const ChangeModelChoice = (e: ChangeEvent<HTMLSelectElement>, metrno: number, modelno: number) => {
        let val = e.target.selectedOptions[0].attributes[1].value
        let newmodelchoices = params.metrmodelchoices
        newmodelchoices[metrno][modelno].Name = String(val)
        setParams({ ...params, metrmodelchoices: newmodelchoices })
    }

    return (
        <div id="graphcreate_canvas" className="ndt_canvas">
            <div className="ndt_gridbox" id="gcc_header">
                <div className="ndt_title2">Query Creator</div>

                {/* mini btns */}
                <div id="graphcreate_topline" >

                    <div id="gc_mini1" className="gc_minibutton" onClick={_ => setShowBucket(1)}>
                        Report Basics
                    </div>
                    <div id="gc_mini2" className="gc_minibutton" onClick={_ => setShowBucket(2)}>
                        Filters
                    </div>
                    <div id="gc_mini3" className="gc_minibutton" onClick={_ => setShowBucket(3)}>
                        Connections
                    </div>
                    <div id="gc_mini4" className="gc_minibutton" onClick={_ => setShowBucket(4)}>
                        Grouping
                    </div>
                    <div id="gc_mini5" className="gc_minibutton" onClick={_ => setShowBucket(5)}>
                        Other Fields
                    </div>
                    {complete ? <div id="gc_save" className="gc_minibutton" onClick={_ => clickedopen(0)}>Save</div> :
                        <div style={{ margin: "5px" }}>{tempparams.overlap_error}</div>}

                </div>
                <div>
                    <div style={{ display: "flex" }}>

                    </div>
                    <div style={{ marginLeft: '80%' }}>
                        <Link to={"/query/creategraph/auto"}>
                            <div style={{ color: "white" }}>
                                Create via Text prompt
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="ndt_gridbox" style={{ minWidth: "500px" }}>
                <div id="graphcreate_buckets">
                    {(() => {
                        switch (showBucket) {
                            case 1: return <Bucket1 />;
                            case 2: return <Bucket2 />;
                            case 3: return <Bucket3 />;
                            case 4: return <Bucket4 />;
                            case 5: return <Bucket5 />;

                            default: return <div></div>;
                        }
                    })()}
                </div>

            </div>
            <div className="ndt_gridbox" >
                <div className="ndt_title3" style={{ marginTop: "30px", marginLeft: "7%" }}>Sample Graph</div>
                <div id="gc_samplegraph"></div>
            </div>


            {/* 1. Metrics modal */}
            <Modal show={isModalVisible[1]} className="graphmodal">
                <div className="ndt_modal">
                    <div className="gc_modalbackground" style={{ padding: "3px" }}>
                        <div className="ndt_title3">Metrics Modal</div>
                        <div style={{ marginTop: '10px' }}>

                            <div style={{ fontSize: '16px', marginBottom: "3px" }}>Set Metrics (max=4):</div>

                            {[0, 1, 2, 3, 4, 5].map((n: number) => {
                                if (params.metrics.length >= n) {
                                    return (
                                        <div key={n} className="gc_df_itm"> Metric {n + 1}
                                            <GenericDropdown
                                                data={metrics}
                                                keycol="metric"
                                                namecol="MetricName"
                                                change={(e: ChangeEvent<HTMLSelectElement>) => AddMetric(e, n)}
                                                className="gc_gen_ddown"
                                                promptstring="Select Metric"
                                            />
                                            <div>{metr_errmsg[n]}</div>

                                        </div>)
                                }
                            })}
                            <div style={{ margin: '8px' }}>
                                {params.metrics.length >= 1 ?
                                    <button className="ndt_btn1" onClick={_ => RmvMetric()}>Remove Last</button>
                                    : <div></div>}
                            </div>
                        </div>

                        <button className="closemodalbtn" onClick={_ => clickedclose(1)}>Close</button>
                    </div>
                </div>
            </Modal >

            {/* 2. AsofDate Modal v2 */}
            < Modal show={isModalVisible[2]} className="graphmodal" >
                <div className="ndt_modal">
                    <div className="gc_modalbackground">
                        <div className="ndt_title3" style={{ marginBottom: "5px" }}>As of Date</div>
                        <div style={{ fontSize: '16px', marginLeft: "10px" }}>Effective Run Date: {DateString(params.asofDate)}</div>
                        <div id="gc_datepickprops" style={{ marginLeft: "15px" }}>
                            <DatePicker value={params.asofDate} onChange={(d: any) => ChangeAttrDate(d, "asofDate")} />
                        </div>
                        <br />
                        <div style={{ fontSize: '16px', marginLeft: "10px" }}>Report End date (calculated by below): {DateString(params.endDate)}</div>
                        <div style={{ border: "1px solid grey", padding: '5px', borderRadius: "5px" }}>
                            <div style={{ display: "flex", marginTop: "5px" }}>
                                <div style={{ width: '150px' }}>Sticky to:</div>
                                <select onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setParams({ ...params, stickydateref: e.target.selectedOptions[0].attributes[0].value })
                                }}>
                                    <option value="">Select:</option>
                                    <option value="None">None</option>
                                    <option value="EOY">End of Year</option>
                                    <option value="EOM">End of Month</option>
                                    <option value="EOW">End of Week (Sunday)</option>
                                </select>
                            </div>
                            <div style={{ display: "flex", marginTop: "5px" }}>
                                <div style={{ width: '150px' }}>Offset N Days:</div>

                                <input type="number" name="n_periods" min={-400} max={400}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setParams({ ...params, offsetdays: +e.target.value }) }} />
                                {weekday[params.endDate.getDay()]}
                            </div>
                            <div style={{ display: "flex", marginTop: "5px" }}>
                                <div style={{ width: '150px' }}>Include to Date?</div>
                                <button style={paramsUser.includetodate ? styleSelected : styleUnselected} onClick={_ => { setParams({ ...params, includetodate: true }) }}>Include</button>
                                <button style={paramsUser.includetodate ? styleUnselected : styleSelected} onClick={_ => { setParams({ ...params, includetodate: false }) }}>Don't Include</button>
                            </div>
                        </div>
                        <button className="closemodalbtn" onClick={_ => clickedclose(2)}>Close</button>
                    </div>
                </div>
            </Modal >

            {/* 3. Period Modal */}
            < Modal show={isModalVisible[3]} className="graphmodal" >
                <div className="ndt_modal">
                    <div className="gc_modalbackground" >
                        <div className="ndt_title3">Dates</div>
                        <div style={{ height: "300px" }}>

                            <br />
                            <div>
                                <div style={{ fontSize: "22px" }}>Period</div>
                                <PeriodDropdown className="gc_gen_ddown" change={(e: ChangeEvent<HTMLSelectElement>) => ChangeAttrSel(e, "period")} />
                                <br />
                            </div>
                            <div>
                                {params.period !== "None" ?
                                    <div>
                                        <div>
                                            <div style={{ display: "block" }}>
                                                {paramsUser.period !== "" ?
                                                    <div>
                                                        Number of {paramsUser.period}:
                                                        <input type="number" name="n_periods" min="1" max="52"
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) => ChangeAttrInput(e, "iperiod", true)} />

                                                        <div>Period Length: {paramsUser.iperiod} {paramsUser.period}</div>
                                                    </div>
                                                    : <div>Not set</div>}
                                            </div>
                                            <br />

                                            <div style={{ fontSize: "22px" }}>Range</div>
                                            <div>
                                                <button style={params.useMulti ? styleSelected : styleUnselected} onClick={_ => ClickSingleRange()}>Multi date range</button>
                                                <button style={!params.useMulti ? styleSelected : styleUnselected} onClick={_ => ClickSingleRange()}>Single report date range</button>
                                                <div>
                                                    {params.useMulti ?
                                                        <div>Set Number of Periods:
                                                            <input type="number" name="n_periods" min="2" max="30" onChange={(e: ChangeEvent<HTMLInputElement>) => ChangeAttrInput(e, "nperiods", true)} />
                                                            <div>N Periods: {paramsUser.nperiods}</div>
                                                        </div> : <div>Single Period (Range)</div>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    : <div>Note: this should be used with Demographic metrics only</div>}
                            </div>

                        </div>
                        <button className="closemodalbtn" onClick={_ => clickedclose(3)}>Close</button>
                    </div>
                </div>
            </Modal >


            {/* 4. Filter Modal */}
            < Modal show={isModalVisible[4]} className="graphmodal" >
                <div className="ndt_modal">
                    <div className="gc_modalbackground">
                        <div style={{ marginTop: '10px', width: '500px' }}>
                            <div style={{ width: '200px' }}>Filter Models</div>
                            <GenericDropdown data={fltModels}
                                divID="gc_ruleddown"
                                className="gc_gen_ddown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                    let val = ChangeParamSelectorbyIndex(e, fltModels, params, setParams, "filterModelKey", "ModelKey", "None", false);
                                    setParams({ ...params, "filterModelKey": val });
                                }}
                                keycol="ModelKey"
                                namecol="ModelName"
                                promptstring="Select Filter Model"
                                includeDefault={true}
                                defaultstring="None!"
                            />
                        </div>
                        <div style={{ marginTop: '10px', width: '500px' }}>
                            <div style={{ width: '200px' }}>Exception Models</div>
                            <GenericDropdown data={ruleModels}
                                divID="gc_ruleddown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                    let val = ChangeParamSelectorbyIndex(e, ruleModels, params, setParams, "ruleModelKey", "ModelKey", "None", false);
                                    setParams({ ...params, ruleModelKey: val });
                                }}
                                keycol="ModelKey"
                                namecol="ModelName"
                                promptstring="Select Rule Model"
                                className="gc_gen_ddown"
                                includeDefault={true}
                                defaultstring="None!"
                            />
                        </div>
                        <button className="closemodalbtn" onClick={_ => clickedclose(4)}>Close</button>
                    </div>
                </div>
            </Modal >


            {/* 5. Connections */}
            <Modal show={isModalVisible[5]} >
                <div className="ndt_modal">
                    <div className="gc_modalbackground">
                        <div>Table Mappings</div>
                        {Object.keys(tblmappinginit).map((tbl: string, i: number) => {
                            return (
                                <div key={i} className="gc_mtblmap" >
                                    <div className="gc_mtblhead">{tbl}</div>
                                    <select className="gc_mtblddown" onChange={(e: ChangeEvent<HTMLSelectElement>) => ChangeTblMapping(e, tbl)}>
                                        <option id="current">Current</option>
                                        <option id="historical">Historical</option>
                                    </select>

                                    <div className="gc_mtblname"> --- </div>
                                </div>
                            )
                        })}
                        <br />
                        <button className="ndt_btn1" onClick={_ => setParams({ ...params, ["mappingSet"]: "true" })}>Confirm</button>

                        <button className="closemodalbtn" onClick={_ => clickedclose(5)}>Close</button>
                    </div>
                </div>
            </Modal>

            {/* 6. X Field modal */}
            <Modal show={isModalVisible[6]} className="graphmodal">
                <div className="ndt_modal" >
                    <div className="gc_modalbackground" style={{ height: "250px" }}>
                        <div className="ndt_title3">Set X-Axis Field</div>
                        <div style={{ height: "160px" }}>
                            {params.nperiods <= 1 ?
                                <div style={{ marginTop: '10px' }}>
                                    <ModelCategoryDropdown className="gc_gen_ddown" tables={tables} usetables={tempparams.grouptbls}
                                        change={(e: ChangeEvent<HTMLSelectElement>) => {
                                            setTempParams({ ...tempparams, modelcat_x: e.target.selectedOptions[0].attributes[0].value })
                                        }} />
                                    <FieldDropdown className="gc_gen_ddown"
                                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeAttrSel(e, "xfieldname", true)}
                                        includecategory={false}
                                        filterfield={["ModelCategory"]}
                                        filtervalue={[tempparams.modelcat_x]}
                                    />
                                    {paramsUser.xfieldname}
                                </div>
                                : <div style={{ margin: '10px' }}>
                                    <div>X Field - using Date</div>
                                    <button className="ndt_btn1" onClick={_ => { ClickXAxisDate() }}>Confirm</button>
                                </div>
                            }
                        </div>
                        <button className="closemodalbtn" onClick={_ => clickedclose(6)}>Close</button>
                    </div>
                </div>
            </Modal >

            {/* 7. Grouping Modal */}
            < Modal show={isModalVisible[7]} className="graphmodal" >
                <div className="ndt_modal">
                    <div className="gc_modalbackground">
                        <div className="ndt_title3">Grouping</div>
                        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => ChangeAttrBool(e, "useGrouping", "groupfield")}>
                                <option data-val={"none"} data-val2={"none"}>Grouping Style:</option>
                                <option data-val={"true"} data-val2={""}> Use Grouping</option>
                                <option data-val={"false"} data-val2={"none"}> Do not use grouping</option>
                            </select>
                            {params.useGrouping ?
                                <div>
                                    <ModelCategoryDropdown className="gc_gen_ddown" usetables={tempparams.grouptbls}
                                        change={(e: ChangeEvent<HTMLSelectElement>) => {
                                            setTempParams({ ...tempparams, modelcat_grp: e.target.selectedOptions[0].attributes[0].value })
                                        }} />
                                    <FieldDropdown className="gc_gen_ddown"
                                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeAttrSel(e, "groupfield")}
                                        includecategory={true}
                                        filterfield={['ModelCategory']}
                                        filtervalue={[tempparams.modelcat_grp]} />
                                </div>
                                : <div></div>
                            }
                        </div>
                        <button className="closemodalbtn" onClick={_ => clickedclose(7)}>Close</button>
                    </div>
                </div>
            </Modal >

            {/* 8. Banding Modal */}
            < Modal show={isModalVisible[8]} className="graphmodal" >
                <div className="ndt_modal">
                    <div className="gc_modalbackground">
                        <div className="ndt_title3">Grouping</div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}>
                            <div>Field Banding</div>
                            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => ChangeBandingState(e)}>
                                <option key={"none"} data-type={"none"}>Banding Style:</option>
                                <option key={"true"} data-type={"true"}> Use Banding</option>
                                <option key={"false"} data-type={"false"}> Do not use Banding</option>
                            </select>
                            {params.useBanding ?
                                <div>
                                    <input type="number" id="input_fldbandmax" placeholder={"6"} min={"2"} max={"40"} onChange={e => ChangeAttrInput(e, "fieldBand")}></input>
                                    <div>Banding Group Name
                                        <input type="text" name="bandgroupname" placeholder={params.bandgroupname} onChange={e => ChangeAttrInput(e, "bandgroupname")} />
                                    </div>
                                </div>
                                : <div></div>
                            }
                        </div>
                        <br />
                        <button className="closemodalbtn" onClick={_ => clickedclose(8)}>Close</button>
                    </div>
                </div>
            </Modal >

            {/* 9. Detail Fields Modal */}
            < Modal show={isModalVisible[9]} className="graphmodal" dialogClassName="modalwide2" >
                <div className="ndt_modal">
                    <div className="gc_modalbackground">
                        <div className="ndt_title3">Detail Fields</div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}>

                            {[0, 1, 2, 3].map((n: number) => {
                                if (params.detailfields.length >= n) {
                                    return (
                                        <div key={n} className="gc_df_itm"> Detail Field {n + 1}
                                            <ModelCategoryDropdown className="gc_gen_ddown" tables={tables} usetables={tempparams.grouptbls}
                                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                                    AddDetailMCat(e, "detail_mcats", n)
                                                }} />
                                            <FieldDropdown
                                                className="gc_gen_ddown"
                                                change={(e: ChangeEvent<HTMLSelectElement>) => AddDetailField(e, n)}
                                                includecategory={true}
                                                filterfield={"ModelCategory"}
                                                filtervalue={tempparams.detail_mcats[n]}
                                            />
                                            <div>{df_errmsg[n]}</div>
                                        </div>)
                                }
                            })}
                            {params.detailfields.length >= 1 ?
                                <button className="ndt_btn1" onClick={_ => RmvDetailField()}>Remove Last</button>
                                : <div></div>}
                        </div>
                        <button className="closemodalbtn" onClick={_ => clickedclose(9)}>Close</button>
                    </div>
                </div>
            </Modal >

            {/* 10. Filter Fields Modal */}
            < Modal show={isModalVisible[10]} className="graphmodal" dialogClassName="modalwide2" >
                <div className="ndt_modal">
                    <div className="gc_modalbackground">
                        <div className="ndt_title3">Filter Fields</div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}>

                            {[0, 1].map((n: number) => {
                                if (params.filterfields.length >= n) {
                                    return (
                                        <div key={n} className="gc_df_itm"> Filter Field {n + 1}
                                            <ModelCategoryDropdown className="gc_gen_ddown" tables={tables} usetables={tempparams.grouptbls}
                                                change={(e: ChangeEvent<HTMLSelectElement>) => {
                                                    AddDetailMCat(e, "filter_mcats", n)
                                                }} />
                                            <FieldDropdown
                                                className="gc_gen_ddown"
                                                change={(e: ChangeEvent<HTMLSelectElement>) => AddFilterField(e, n)}
                                                includecategory={true}
                                                filterfield={"ModelCategory"}
                                                filtervalue={tempparams.filter_mcats[n]}
                                            />
                                            <div>{flt_errmsg[n]}</div>
                                        </div>)
                                }
                            })}
                            {params.filterfields.length >= 1 ?
                                <button className="ndt_btn1" onClick={_ => RmvFiltField()}>Remove Last</button>
                                : <div></div>}
                        </div>
                        <button className="closemodalbtn" onClick={_ => clickedclose(10)}>Close</button>
                    </div>
                </div>
            </Modal >


            {/* 11. Simple Filter */}
            < Modal show={isModalVisible[11]} className="graphmodal" dialogClassName="modalwide2" >
                <div className="ndt_modal">
                    <div className="gc_modalbackground">
                        <div className="ndt_title3">Simple Filter</div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}>

                            <div className="gc_df_itm">
                                <ModelCategoryDropdown className="gc_gen_ddown" tables={tables} usetables={tempparams.grouptbls}
                                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                                        setTempParams({ ...tempparams, simplefltmodelcat: e.currentTarget.selectedOptions[0].attributes[0].value })
                                    }} />
                                <FieldDropdown
                                    className="gc_gen_ddown"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => setParams({ ...params, simplefilterfield: e.target.selectedOptions[0].attributes[0].value, simplefiltervalues: [] })}
                                    includecategory={true}
                                    filterfield={"ModelCategory"}
                                    filtervalue={tempparams.simplefltmodelcat}
                                />

                            </div>
                            {params.simplefilterfield !== "" ?
                                <div>
                                    <div>Filter Value</div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: '350px' }}>
                                            <div>Selected Values:</div>
                                            <div>
                                                {params.simplefiltervalues.map((val: string, j: number) => {
                                                    return (<div key={j}>{StringTrim(String(val), 120)}</div>)
                                                })}
                                            </div>
                                        </div>
                                        <div style={{ height: '200px', overflowY: "scroll", width: '350px' }}>
                                            {uniquevals.map((uv: any, i: number) => {
                                                let usestyle = {}
                                                if (IsInVector(params.simplefiltervalues, uv['Fld'])) {
                                                    usestyle = { backgroundColor: "pink" }
                                                }
                                                return (<div key={i}
                                                    className="gc_smfltitm"
                                                    style={usestyle}
                                                    onClick={_ => { setParams({ ...params, simplefiltervalues: ArraySliceValue(params.simplefiltervalues, uv['Fld'], '') }) }}
                                                >
                                                    {StringTrim(uv['Fld'], 20)}
                                                </div>)
                                            })}</div>
                                    </div>
                                </div> : <div></div>}

                        </div>
                        <button className="closemodalbtn" onClick={_ => clickedclose(11)}>Close</button>
                    </div>
                </div>
            </Modal >


            {/* 0. Save */}
            < Modal show={isModalVisible[0]} className="graphmodal" >
                <div className="ndt_modal" style={{ margin: '0px' }}>
                    <div className="ndt_modaltitle" style={{ margin: "5px" }}>Query Finalization</div>
                    {modelparams.checkedmodels ?
                        <div className="gc_modalbackground" style={{ padding: '2px', paddingLeft: "5px" }}>

                            <div style={{ paddingLeft: "5px" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "150px" }}>Set Query Name</div>
                                    <input style={{ width: '250px' }} type="text" name="modelname" onChange={e => ChangeAttrInput(e, "modelname")} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "150px" }}>Notes</div>
                                    <textarea style={{ width: '250px' }} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setParams({ ...params, notes: e.target.value })} />
                                </div>
                            </div>
                            <button id="ndt_btn1" onClick={_ => setModelParams({ ...modelparams, checkedmodels: false })}>Back</button>
                            <button id="gc_savebtn" onClick={_ => SaveModel()}>Save</button>
                            <div id="gc_uploaddiv"></div>
                            <button className="closemodalbtn" onClick={_ => clickedclose(0)}>Close</button>
                        </div>
                        : <div>
                            {modelparams.metrmodels.map((mdls: string[], i: number) => {

                                return (<div key={i} className="gc_modelinfobox">
                                    <div style={{ fontSize: "18px" }}>{paramsUser.metrics[i]}</div>
                                    {mdls.map((m: string, j: number) => {
                                        let knlist: any[] = []
                                        modelparams.listdetails.forEach((det: any) => {
                                            if (det.Model === m) {
                                                knlist = det.Values
                                            }
                                        })

                                        return (<div key={j}>
                                            {m}
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: "5px", marginTop: '3px' }}>Dropdown:</div>
                                                <GenericDropdown
                                                    data={knlist}
                                                    keycol="Key"
                                                    namecol="Name"
                                                    promptstring="Select Model"
                                                    includeDefault={true}
                                                    defaultstring="(None - do not filter)"
                                                    className='gc_gen_ddown'
                                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeModelChoice(e, i, j) }}
                                                />
                                            </div>
                                        </div>)
                                    })}

                                </div>)
                            })}
                            <button className="ndt_btn1" onClick={_ => { setModelParams({ ...modelparams, checkedmodels: true }) }}>Confirm Choices</button>
                            <button className="closemodalbtn" onClick={_ => clickedclose(0)}>Close</button>
                        </div>}
                </div>
            </Modal >

        </div >
    )
};
export default GraphCreator;