import HoverData from "./functions/HoverData";
import TooltipInspect from "./functions/tooltip/TooltipInspect";
import TooltipDetail from "./functions/tooltip/TooltipDetail";
import "./functions/tooltip/css/TooltipModal.css"
import FilterData from "../functions/FilterData";
import UpdateState from "./newfunctions/UpdateState";


const SVGMouseFunctions = (svgelems, vis_state, setVis_State, visStateIdx, charttype, simpledata, detaildata, context) =>{

    let highlightcolor = "rgb(0,0,250)"

    try {

        svgelems
        .on("click", function (e,d) { 
            console.log(vis_state[visStateIdx])
            console.log(vis_state)
            if (vis_state[visStateIdx].clickevent!=="n/a"){
                if (+this.attributes.selected.value === 0 && setVis_State && +this.attributes['data-metricno'].value>=0){
                    //metricno eliminates table x/g columns
                    //indicate new selected value
                    UpdateState(vis_state, setVis_State, charttype,
                            ["selChartElemIndex","selEncKey","selChartElemMetricNo", "selectedmetric"], 
                            [ this.attributes.id.value, this.attributes['data-id'].value, this.attributes['data-metricno'].value, 
                            this.attributes['data-metrickey'].value], visStateIdx)


                    this.style.fill = highlightcolor
                    this.style.stroke = highlightcolor
                    this.style.backgroundColor = highlightcolor

                    this.attributes.selected.value = 1
                    if (vis_state[visStateIdx].clickevent==="detail"){
                        let hd = HoverData(e.target.__data__, detaildata, );
                        let popupelem = TooltipDetail(hd)
                        document.body.appendChild(popupelem)

                    } else if (vis_state[visStateIdx].clickevent==="inspect"){
                        let hd = FilterData(simpledata,"EncodedKey",this.attributes['data-id'].value,false);
                        let popupelem = TooltipInspect(hd,this, vis_state, setVis_State, visStateIdx, context)
                        document.body.appendChild(popupelem)

                    } else if (vis_state[visStateIdx].clickevent==="dashboard"){
                        //hide selected element id in button attrs
                        let elem = document.getElementById("dsh_insbtn")
                        if (elem){
                            elem.attributes[1].value = this.attributes.id.value
                            elem.style.boxShadow = "0px 0px 5px yellow"
                            elem.style.background = "blue"
                        }
                        vis_state.forEach((vs,t)=>{ //clear other chart highlightings
                            if (vs.selChartElemIndex!=="" && t!==visStateIdx){
                                UpdateState(vis_state, setVis_State, charttype, ['selChartElemIndex',"selEncKey",'selChartElemMetricNo'],["","",-1],  t)
                            }
                        })
                        
                    }
                } else if (setVis_State){
                    UpdateState(vis_state, setVis_State, charttype, ['selChartElemIndex',"selEncKey",'selChartElemMetricNo'],["","",-1],  visStateIdx)
                    this.attributes.selected.value = 0

                    if (vis_state[visStateIdx].clickevent==="dashboard"){
                        //hide selected element id in button attrs
                        let elem = document.getElementById("dsh_insbtn")
                        if (elem){
                            elem.attributes[1].value = ''
                            elem.style.boxShadow= ""
                            elem.style.background = ""
                        }
                    }
                } 
            }

        })

    } catch(err){
            
    }

};
export default SVGMouseFunctions;

