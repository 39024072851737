import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Employee, Emp_State, EmpModel } from "../type";
import 'dayjs'
import { EmpInit, Emp_StateInit, EmpModelInit } from '../InitTypes';


const EmpStateInit: Emp_State[] = [Emp_StateInit]


//model name list
export const empModelSlice = createSlice({
	name: 'empmodel_action',
	initialState: EmpModelInit,
	reducers: {
		empmodel: {
			reducer: (state, { payload }: PayloadAction<EmpModel>) => {
				state.push(payload);
			},
			prepare: ({ ModelKey, ModelName, CreateDate, AsofDate, ReportType, SchemaName, WorkspaceName, state }: EmpModel) => ({
				payload: {
					ModelKey: ModelKey,
					ModelName: ModelName,
					CreateDate: CreateDate,
					AsofDate: AsofDate,
					SchemaName: SchemaName,
					ReportType: ReportType,
					WorkspaceName: WorkspaceName,
					state
				}
			})
		},
		empmodel_delete: (state, _: PayloadAction<{}>) => {
			state.splice(0, state.length);
		},
	}
});

//employee
export const employeestateDataSlice = createSlice({
	name: 'empstatedata_action',
	initialState: EmpStateInit,
	reducers: {
		empstatedata: {
			reducer: (state, { payload }: PayloadAction<Emp_State>) => {
				state.push(payload);
			},
			prepare: ({ StateKey, EmpID, Name, SchemaName, WorkspaceName, ModelKey, Date, JobCode, DeptID, ReportsToID, Status, Position,
				Attribute1, Attribute2, Attribute3, Attribute4, Attribute5, Attribute6, Attribute7, Attribute8 }: Emp_State) => ({
					payload: {
						StateKey,
						EmpID: EmpID,
						Name: Name,
						SchemaName: SchemaName,
						WorkspaceName: WorkspaceName,
						ModelKey: ModelKey,
						Date: Date,
						JobCode: JobCode,
						DeptID: DeptID,
						ReportsToID: ReportsToID,
						Status: Status,
						Position: Position,
						Attribute1,
						Attribute2,
						Attribute3,
						Attribute4,
						Attribute5,
						Attribute6,
						Attribute7,
						Attribute8
					}
				})
		},
		employeestate_delete: (state, _: PayloadAction<{}>) => {
			state.splice(0, state.length);
		},
	}
});

export const employeeDataSlice = createSlice({
	name: 'empdata_action',
	initialState: EmpInit,
	reducers: {
		empdata: {
			reducer: (state, { payload }: PayloadAction<Employee>) => {
				state.push(payload);
			},
			prepare: ({ EmpID, Name, SchemaName, WorkspaceName, CreateDate, EmailAddress, state }: Employee) => ({
				payload: {
					EmpID: EmpID,
					Name: Name,
					SchemaName,
					WorkspaceName,
					CreateDate: CreateDate,
					EmailAddress: EmailAddress,
					state: state
				}
			})
		},
		employee_delete: (state, _: PayloadAction<{}>) => {
			state.splice(0, state.length);
		},
	}
});


//emp state
export const {
	empstatedata: empstateDataActionCreator,
	employeestate_delete: empstatedeleteActionCreator,
} = employeestateDataSlice.actions;


//emp
export const {
	empdata: empDataActionCreator,
	employee_delete: empdeleteActionCreator,
} = employeeDataSlice.actions;


//emp model
export const {
	empmodel: empModelActionCreator,
	empmodel_delete: empModelDeleteActionCreator,
} = empModelSlice.actions;