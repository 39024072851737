import { QueryModel, FieldMap, QueryRunDate, FilterField, DashboardFilter, queryfiltervaluestate } from "../../type";
import GetObjectfromArray from "../Array Functions/GetObjectfromArray";
import GetUniquesFromObjectAttribute from "../GetUniquesFromObjectAttribute";
import FilterData from "../FilterData";

const MultiQueryFilterValues = (queries: QueryModel[], filters: DashboardFilter[], rundatekeys: string[], fields: FieldMap[]): any => {
    //ref: dashboardview
    let filtervaluearry: any = {}

    filters.forEach((dflt: DashboardFilter) => {
        let vals: any[] = []
        let qryidx: number[] = [] //keep track of which query numbers use this filter
        let fld: FieldMap = GetObjectfromArray(fields, dflt.FieldName, "Key")

        queries.forEach((query: QueryModel, i: number) => {
            //check if is selected filtervalue, but as a filter (can still be groupval, xfieldval, etc)
            let isinqry = false;
            let onIdx = 0;

            query.filterfields.forEach((flt: FilterField) => {
                if (flt.FieldName === fld.Key) {
                    isinqry = true;
                    onIdx = flt.FieldNum
                }
            })
            if (isinqry) {
                query.rundates.forEach((rd: QueryRunDate, t: number) => {
                    if (rundatekeys.indexOf(rd.DateKey) > -1) {
                        let fltdata = FilterData(query.rundates[t].basicquery, "FilterFieldName" + onIdx, fld.AttrName + "_" + fld.ModelCategory)
                        let unique = GetUniquesFromObjectAttribute(fltdata, "FilterValue" + onIdx, true)
                        vals = vals.concat(unique)
                    }
                })
                qryidx.push(i)
            }
        })
        let qfvs: queryfiltervaluestate = { Values: vals, Selected: '', OnQryIdx: qryidx }

        filtervaluearry[dflt.FieldName] = qfvs
    })
    return filtervaluearry
};
export default MultiQueryFilterValues;