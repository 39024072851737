import { useContext, useState, useEffect, ChangeEvent, useMemo } from "react";
import { StartupContext } from "../../../../App";
import { KPI, KPIFilter, LeaderModel } from "../../../../type";
import GenericBackendCall from "../../../../functions/Import/GenericBackendCall";
import GenericDropdown from "../../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../../functions/ElementSelect/ChangeSelectorbyIndex";
import { KPIInit, LeaderModelInit } from "../../../../InitTypes";
import ImportAndSetStateGeneric from "../../../../functions/Import/ImportAndSetStateGeneric";
import ChangeParamSelectorbyIndex from "../../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import ArraySliceValue from "../../../../functions/Array Functions/ArraySliceValue";

import "./css/LeaderViewManager.css"
import GetObjectField from "../../../../functions/Object Functions/GetObjectField";
import IsInObject from "../../../../functions/Object Functions/IsInObject";
import FilterData from "../../../../functions/FilterData";
import { Modal } from "react-bootstrap";
import GetUserEmail from "../../../../functions/User Functions/GetUserEmail";

const LeaderViewManager = () => {

    const { config, dispatcher, schema, user, paths, allusers, clickstyle } = useContext(StartupContext)
    const [leadermodels, setLeaderModels] = useState<LeaderModel[]>([])
    const [selModel, setSelModel] = useState<LeaderModel | null>(null)
    const [allKPIs, setAllKPIs] = useState<KPI[]>([])
    const [selKPI, setSelKPI] = useState<KPI | null>(null)
    const [activeKPIs, setActiveKPIs] = useState<KPI[]>([]) //kpis included in model
    const [kpifilters, setKPIFilters] = useState<KPIFilter[]>([]) //kpifilters in model (any kpi)
    const [showFlts, setShowFlts] = useState<KPIFilter[]>([])

    const [keyparams, setKeyParams] = useState({ selKPIKey: '', modelname: '', seluserid: '', seluseremail: '', fltkeychange: 0, issavevisible: false })


    useEffect(() => {
        ImportAndSetStateGeneric(setLeaderModels, "", schema, paths.leader.leaderview, user, config, {})
        ImportAndSetStateGeneric(setAllKPIs, '', schema, paths.kpi.kpiview, user, config, { name: 'true' })
    }, [])

    useEffect(() => {
        if (keyparams.selKPIKey !== "") {
            GenericBackendCall('', schema, paths.kpi.kpiview, user, config, { modelkeys: [keyparams.selKPIKey], full: "true" }).then(d => {
                if (d.length === 1) {
                    let kpi = d[0]
                    setSelKPI(kpi)
                    ChangeKPI(kpi.Key)
                    AddKPI(kpi.Key, GetObjectField(kpi.Key, allKPIs, "Key", "KPIName"))
                }
            })
        }
    }, [keyparams.selKPIKey])

    useEffect(() => {
        let user_email = GetUserEmail(keyparams.seluserid, allusers)
        setKeyParams({ ...keyparams, seluseremail: user_email })
    }, [keyparams.seluserid])

    useEffect(() => {
        if (selModel && selModel.ModelKey !== "") {

            GenericBackendCall('', schema, paths.kpi.kpiview, user, config, { modelkeys: [selModel.KPIs], full: "true" }).then(d => {

                let importkpis: KPI[] = d;

                let newflts: KPIFilter[] = []
                selModel.KPIFilters.forEach((kf: string) => {
                    importkpis.forEach((kpi: KPI) => {
                        let check = IsInObject(kpi.kpiflts, kf, "Key", true)
                        if (typeof (check) == "number" && check >= 0) {
                            newflts.push(kpi.kpiflts[check])
                        }
                    })
                })
                console.log(importkpis)
                setActiveKPIs(importkpis)
                setKPIFilters(newflts)
                setKeyParams({ ...keyparams, seluserid: String(selModel.LeaderUser), modelname: selModel.ModelName })
            })

        }
    }, [selModel?.ModelKey])

    ///backend-----------------
    const ClickSave = () => {
        let kpifltkeys: string[] = kpifilters.map((kf: KPIFilter) => { return kf.Key })
        let kpikeys: string[] = activeKPIs.map((kpi: KPI) => { return kpi.Key })
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.leader.leaderpost, user, config,
                { modelname: keyparams.modelname, kpifltkeys, kpikeys, leaderid: keyparams.seluserid }, "lvm_save")
        }
    }
    //---------------------------------

    const AddFltValue = (kflt: KPIFilter) => {
        let newval = ArraySliceValue(showFlts, kflt, "Key")
        setShowFlts(newval)
        setKeyParams({ ...keyparams, fltkeychange: keyparams.fltkeychange + 1 })

    }
    const AddKPI = (kpikey: string, kpiname: string) => {
        setActiveKPIs(ArraySliceValue(activeKPIs, { ...KPIInit[0], Key: kpikey, KPIName: kpiname }, "Key", true))
    }

    const RmvKPI = () => {
        let idx = IsInObject(activeKPIs, keyparams.selKPIKey, "Key", true)
        console.log(idx)
        if (typeof (idx) === "number") {
            let left = activeKPIs.slice(0, idx)
            let right = activeKPIs.slice(idx + 1)

            if (left.length + right.length < activeKPIs.length) {
                setActiveKPIs([...left, ...right])
            } else {
                console.log(left)
                console.log(right)
            }

        }
        let kpifltdata = FilterData(kpifilters, "KPIName", keyparams.selKPIKey, true)
        setKPIFilters(kpifltdata)
        setKeyParams({ ...keyparams, selKPIKey: '' })
    }

    const ChangeKPI = (key: string) => {
        let kpifltdata = FilterData(kpifilters, "KPIName", key, true)
        let allfltdata = kpifltdata.concat(showFlts)
        let newflts = FilterData(kpifilters, "KPIName", key)

        setKPIFilters(allfltdata)
        setShowFlts(newflts)

    }

    const FltMgr = () => {
        return (
            <div>
                {selKPI ?
                    <div style={{ height: '400px', overflowY: "scroll", width: '600px' }}>
                        {selKPI.kpiflts.length > 0 ?
                            <div >
                                {selKPI.kpiflts.map((kflt: KPIFilter, i: number) => {
                                    let ustyle = clickstyle.btnUnselected
                                    if (IsInObject(showFlts, kflt.Key, "Key")) { ustyle = clickstyle.btnSelected }
                                    return (<div
                                        key={i}
                                        className="lvm_fltitm ndt_item"
                                        style={ustyle}
                                        onClick={_ => { AddFltValue(kflt) }}
                                    >{kflt.FilterValue1} + {kflt.FilterValue2}</div>)
                                })}
                            </div> : <div>No Filters available for this KPI</div>}
                    </div>
                    : <div></div>}
            </div>
        )
    }

    const fltmemo = useMemo(() => { return <FltMgr /> }, [showFlts, selKPI, keyparams.fltkeychange])

    return (<div className="ndt_canvas">
        <div className="ndt_gridbox ndt_header">
            <div className="ndt_title2">Leader View Manager</div>
        </div>
        <div className="ndt_gridbox" style={{ marginTop: '20px', height: "900px" }}>
            <GenericDropdown
                data={leadermodels}
                keycol="ModelKey"
                namecol="ModelName"
                promptstring="Select Model or Create New"
                className="ndt_dropdown"
                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, leadermodels, setSelModel, "", LeaderModelInit[0]) }}
                includeDefault={true}
                divID="lvm_selmdldiv"
                defaultstring="Create new view"
            />

            {selModel ?
                <div>
                    <div>
                        {selModel.ModelKey === "" ?
                            <div className="ndt_title3">Create New Model</div>
                            :
                            <div>
                                <div className="ndt_title3">{selModel.ModelName} </div>
                                <div style={{ fontSize: "20px" }}>{GetUserEmail(selModel.LeaderUser, allusers)}</div>
                            </div>}
                        <div>
                            <div>
                                <div className="ndt_title4">Included KPIs</div>
                                <div style={{ height: "150px", width: "500px", display: "grid", gridTemplateColumns: "50% 50%", border: "1px solid rgb(50,50,50)" }}>
                                    {activeKPIs.map((kpi: KPI, i: number) => {
                                        return (<div key={i} className="ndt_btnmain lvm_kpiitm" onClick={_ => { setKeyParams({ ...keyparams, selKPIKey: kpi.Key }) }}>
                                            {kpi.KPIName}
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div className="ndt_title4">Add KPI</div>
                            <div>
                                <GenericDropdown
                                    data={allKPIs}
                                    keycol="Key"
                                    namecol="KPIName"
                                    promptstring="Select KPI"
                                    className="ndt_dropdown"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, allKPIs, keyparams, setKeyParams, "selKPIKey", "Key", "") }}
                                    divID="lvm_selkpidiv"
                                />
                                {selKPI ?
                                    <div style={{ display: "flex" }}>
                                        <button className="ndt_btn2" onClick={_ => { RmvKPI() }}>Remove KPI</button>
                                    </div> : <div></div>}
                            </div>
                            {fltmemo}
                        </div>
                        <button className="ndt_btn1" style={{ width: '150px' }} onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}>Save</button>
                    </div>
                </div> : <div>Select a Model first</div>}
        </div>

        <Modal show={keyparams.issavevisible}>
            <div className="ndt_modal">
                <div>Save Model with Name:</div>
                <input style={{ width: "60%" }} type="text" placeholder={keyparams.modelname} value={keyparams.modelname}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, modelname: e.target.value }) }} />

                <div>
                    <GenericDropdown
                        data={allusers}
                        keycol="id"
                        namecol="email"
                        change={(e: ChangeEvent<HTMLSelectElement>) => {
                            ChangeParamSelectorbyIndex(e, allusers, keyparams, setKeyParams, "seluserid", "id")
                        }}
                        promptstring="Select Target Manager"
                    />
                </div>
                <div>
                    <button className="ndt_btn1" style={{ margin: "5px", width: "120px" }} onClick={_ => { ClickSave() }}>Save</button>
                </div>
                <div id="lvm_save" style={{ height: "30px" }}></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false })}>Close</button>
            </div>
        </Modal>
    </div>)
};
export default LeaderViewManager