import { useState, ChangeEvent, useEffect, } from 'react'
//import DataTable from "../../../functions/DataTable";

import FilterModelDropdown from "../filtersets/subfunctions/FilterModelDropdown";
import EmployeeStateSliceImport from "../../../data/Employee/EmployeeStateSlice_Import";
import { useContext } from "react";
import { StartupContext } from "../../../App";
import { Emp_State, FieldMap, FilterModel, TableModel } from '../../../type';
import FilterModelImport from '../../../data/Filtersets/FilterModel_Import';
import DatePicker from 'react-date-picker';
import CustomTable from '../../../functions/EditableTable/CustomTable';
import ExcelExport from '../../../functions/ExcelExport';
import ImportAndSetState from '../../../functions/Import/ImportAndSetState';
import FieldsImport from '../../../data/Fields/Fields_Import';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import "./css/EmployeeDataTablePlus.css"
import ModelCategoryDropdown from '../../../functions/Dropdowns/ModelCategoryDropdown';
import DateString from '../../../functions/Date Functions/DateString';


const EmployeeDataTable = () => {

    const { config, dispatcher, schema, paths, user } = useContext(StartupContext)

    interface paramtype {
        modelcat: string;
        filtKey: string;
        dateStr: string;
    }

    const [data, setData] = useState<any[]>([])
    const [filtModels, setFiltModels] = useState<FilterModel[]>([])
    const [asofdate, setAsofdate] = useState(new Date())
    const [changeState, setChangeState] = useState<any>(0)
    const [fields, setFields] = useState<FieldMap[]>([])
    const [tables, setTables] = useState<TableModel[]>([])
    const [keyparams, setKeyParams] = useState<paramtype>({ modelcat: '', filtKey: "", dateStr: "" })

    useEffect(() => {
        console.log('getdata')
        let importpromise = FilterModelImport("", schema, config, dispatcher, false)
        importpromise.then(d => setFiltModels(d))
        ImportAndSetStateGeneric(setTables, "*", schema, paths.tables.tablemodelview, user, config, {})
        ImportAndSetState(FieldsImport, setFields, "", schema, config, dispatcher, false)

    }, [])

    const ChangeFModel = (e: ChangeEvent<HTMLSelectElement>) => {
        console.log("changemodel")
        setKeyParams({ ...keyparams, filtKey: e.target.selectedOptions[0].value })
    }


    const UpdateData = () => {
        let importpromise = EmployeeStateSliceImport({ "asofdate": asofdate, "filtkey": keyparams.filtKey }, schema, false, config, dispatcher)
        importpromise.then(d => setData(d))

    }

    const ChangeDate = (date: Date) => {
        setAsofdate(date)
        setKeyParams({ ...keyparams, dateStr: DateString(date) })
    }

    const FilterDivs = () => {
        return (<div>
            <FilterModelDropdown fModels={filtModels} change={(e: ChangeEvent<HTMLSelectElement>) => ChangeFModel(e)} />
        </div>)
    }

    const FileExport = () => {
        ExcelExport({ csvDataArry: [data], sheetNames: ["dataset"], filename: "employeedata" })
    }


    return (
        <div className='ndt_canvas'>
            <div style={{ overflow: "" }}>
                <div style={{ marginLeft: "10px", display: "block" }}>
                    <div className="ndt_title2" style={{ marginLeft: "10px" }}>Data Table</div>
                    <div style={{ width: '300px', padding: "10px" }}>
                        <ModelCategoryDropdown className="ndt_dropdown" tables={tables} change={(e: ChangeEvent<HTMLSelectElement>) => {
                            setKeyParams({ ...keyparams, modelcat: e.target.selectedOptions[0].attributes[0].value })
                        }} />
                        <FilterDivs />
                        <div>
                            <DatePicker className="datepick_class" value={asofdate} onChange={(date: any) => ChangeDate(date)} />
                        </div>
                        <button className="ndt_btn1" onClick={_ => UpdateData()}>Populate</button>
                    </div>
                </div>
            </div>
            <div className='ndt_gridbox'>
                {data.length > 0 ?
                    <div><CustomTable
                        data={data}
                        colsHide={["PrevDate", "NextDate"]}
                        rowcount={13}
                        keycol={"StateKey"}
                        modelcategory="EmployeeState"
                        changestate={setChangeState}
                        optionalchoices={[]}
                        hardcodedfields={[]}
                        editable={{
                            "EmpID": false, "Name": true, "DeptID": false, "ModelKey": false,
                            "Date": false, "JobCode": false, "ReportsToID": false,
                            "Attribute1": true, "Attribute2": true, "Attribute3": true
                        }}
                        backendstring={paths.generics.datachange}
                        classname="empdatatbl"
                        mainDiv="empdata_div"
                        fields={fields}
                        shape={[1300, 800]}
                    />
                        <button id="edt_exportbtn" className="ndt_btn1" style={{ marginLeft: '40px', marginTop: '10px' }} onClick={FileExport}>Export </button>
                    </div>
                    : <div></div>}
            </div>
        </div >
    )
};
export default EmployeeDataTable;
