

function IsInVector(Arr,val,returnIndex=false){

	var x=-1;
	var contained;

	//loop through
	Arr.forEach((ind,i) => {
		if(String(ind) === String(val)){
			x = i
		} 
	//console.log("IsInVector: " + ind + " vs " + val + "..." + x)
	})
	
	x>=0 ? contained = true : contained = false
	if (returnIndex){
		return(x)
	} else {
		return(contained)
	}
}
export default IsInVector;