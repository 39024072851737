import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import "./css/TutorialDiv.css"
import IsInVector from "../functions/IsInVector";
import TutorialPageMap from "../context/TutorialPageMap";
import { Pagemap, DivStep, } from "../type";

const TutorialDiv = () => {

    const location = useLocation();

    interface paramtype {
        step: number;
        laststep: number;
        selpagemap: null | Pagemap;
    }
    const [keyparams, setKeyParams] = useState<paramtype>({ step: -1, laststep: -1, selpagemap: null })
    const [posn, setPosn] = useState<{ x: number, y: number }>({ x: window.innerWidth * .75, y: 60 })

    let pagemap = TutorialPageMap()

    useEffect(() => {
        let found = false
        pagemap.forEach((pm: Pagemap, i: number) => {
            if (location.pathname.includes(pm.Path)) {
                found = true
                setKeyParams({ ...keyparams, selpagemap: pm, step: -1, laststep: -1 })
            }
        })
        if (!found) {
            setKeyParams({ ...keyparams, selpagemap: null, step: -1, laststep: -1 })
        }
    }, [location])

    const CheckStepChange = (n: number) => {
        if (keyparams.selpagemap) {
            let nextelem = document.getElementById(keyparams.selpagemap.Detail.Steps[keyparams.step + n].Div)
            if (nextelem) {
                setKeyParams({ ...keyparams, step: keyparams.step + n, laststep: keyparams.step })
            }
        }
    }

    const ClickBatch = (n: number) => {
        if (keyparams.selpagemap) {
            let currbatch = keyparams.selpagemap.Detail.Steps[keyparams.step].Batch
            let newbatchno = -1
            let found = false
            console.log(currbatch + n)
            keyparams.selpagemap.Detail.Steps.forEach((ds: DivStep, i: number) => {
                console.log(ds.Batch)
                if (ds.Batch === currbatch + n && !found) {
                    console.log("found")
                    newbatchno = i
                    found = true
                }
            })
            if (found) {
                setKeyParams({ ...keyparams, step: newbatchno, laststep: keyparams.step })
            }
        }
    }

    useEffect(() => {
        if (keyparams.selpagemap) {

            if (keyparams.laststep >= 0) {
                let prevelem = document.getElementById(keyparams.selpagemap.Detail.Steps[keyparams.laststep].Div)

                if (prevelem) {
                    if (IsInVector(prevelem.classList, "seldivtutorial")) {
                        prevelem.classList.toggle("seldivtutorial")
                    }
                }
            }
            if (keyparams.step >= 0) {
                let elem = document.getElementById(keyparams.selpagemap.Detail.Steps[keyparams.step].Div)
                if (elem) {
                    let bounds = elem.getBoundingClientRect();
                    let x = bounds.left + 50
                    let y = bounds.top + 50
                    if (bounds.width > 500) {
                        y = y + bounds.height
                    } else {
                        x = x + bounds.width
                    }
                    if (bounds.y + bounds.height > 700) {
                        y = y - 300
                    }

                    setPosn({ x: x, y: y })
                    elem.classList.toggle("seldivtutorial")
                }
            }
            if (keyparams.step < 0) {
                setPosn({ x: window.innerWidth * .75, y: 60 })
            }

        }
    }, [keyparams.step])


    return (<div id="ndttutorial_div" style={{ zIndex: 5, left: posn.x, top: posn.y }}>

        <div style={{ display: "flex" }}>
            <div style={{ fontSize: "22px" }}>Tutorial:</div>
            <div style={{ fontSize: "19px", marginTop: "4px", marginLeft: "8px", color: "rgb(10,10,10)", height: "30px", overflow: "hidden" }}>{keyparams.selpagemap?.Name}</div>
        </div>
        <div style={{ fontSize: "17px" }}>
            {keyparams.selpagemap ?
                <div>

                    <div style={{ height: "30px" }}>{keyparams.selpagemap.Detail.Text}</div>
                    {keyparams.selpagemap.BatchCount > 1 && keyparams.step >= 0 ?
                        <div style={{ display: "flex" }}>
                            <div style={{ marginRight: "10px" }}>Batch {keyparams.selpagemap.Detail.Steps[keyparams.step].Batch + 1} of {keyparams.selpagemap.BatchCount}</div>
                            <button onClick={_ => { ClickBatch(-1) }}>Prev Batch</button>
                            <button onClick={_ => { ClickBatch(1) }}> Next Batch</button>
                        </div>
                        : <div style={{ height: "30px" }}></div>}

                    <div style={{ height: "120px", background: "rgb(170,170,170)", padding: "3px", margin: "3px" }}>
                        {keyparams.selpagemap.Detail.Steps.map((ds: DivStep, i: number) => {
                            if (i === keyparams.step) {
                                return (<div key={i + 1} style={{ height: "60px" }}>
                                    <div>{ds.Name}</div>
                                    <div style={{ fontSize: "15px" }}>{ds.Text}</div>
                                </div>)
                            }
                        })}
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                        {keyparams.step >= 0 ?
                            <div>
                                <div>Step {keyparams.step + 1} of {keyparams.selpagemap.Detail.Steps.length}</div>
                                <div style={{ display: "flex" }}>
                                    {keyparams.step > 0 ?
                                        <button style={{ height: "30px", width: "100px" }} onClick={_ => { CheckStepChange(-1) }}>Prev Step</button>
                                        : <button style={{ height: "30px", width: "100px", opacity: ".3", cursor: "default" }}>Prev Step</button>}
                                    {keyparams.step < keyparams.selpagemap.Detail.Steps.length - 1 ?
                                        <button style={{ height: "30px", width: "100px" }} onClick={_ => { CheckStepChange(1) }}>Next Step</button>
                                        : <button style={{ height: "30px", width: "100px", opacity: ".3", cursor: "default" }}>Next Step</button>}
                                    <button style={{ marginLeft: "10px", background: "rgb(200, 200, 200)", width: "90px" }} onClick={_ => { setKeyParams({ ...keyparams, step: -1, laststep: keyparams.step }) }}>Exit</button>
                                </div>
                            </div>
                            : <div>
                                {keyparams.selpagemap && keyparams.selpagemap.Detail.Steps.length > 0 ?
                                    <button onClick={_ => { setKeyParams({ ...keyparams, step: 0, laststep: 0 }) }}>Begin</button>
                                    : <div></div>}
                            </div>}
                    </div>
                </div> : <div></div>
            }
        </div >
    </div >)
}

export default TutorialDiv;