import DatatoFormArray from '../../functions/Import/DatatoFormArray';
import { FieldMap } from '../../type';
import AxiosUpload from '../AxiosUpload';

const FieldsUploadNew = async (fieldsingle: FieldMap, schemakey: string, config: any, elementstring: string) => {

    let dataArray = DatatoFormArray(fieldsingle)
    dataArray.append("schemakey", schemakey);


    let elem: HTMLElement | null = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..."; }

    let promise = AxiosUpload(dataArray, "api/fields/fieldpostnew", config, elem, "", true)

    return (promise)
};

export default FieldsUploadNew;