import axios from 'axios';
import ConvertDateToObject from '../../functions/Date Functions/ConvertDateToObject';
import { QueryModelInit } from '../../InitTypes';
import { QueryModel, queryparams, User, Key_Name } from '../../type'

const QueryModelUpload = async (params: queryparams, existingmodelkey: string,
    schemakey: string, user: User, config: any, dispatcher: any, elementstring: string, uploadfull: boolean = true) => {
    //ref: graphcreate,

    //params contains a list of attributes
    let dataArray = new FormData();

    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "loading..." }
    //logic for date spread is contained within backend view

    //model connections mapping
    let mappings = Object.keys(params.mappings).map((mp: any) => {
        return [
            String(mp).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(params.mappings[mp]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`)
        ]
    });


    let detfields = Object.keys(params.detailfields).map((mp: any) => {
        return [
            String(params.detailfields[mp])
        ]
    });

    let fltfields = Object.keys(params.filterfields).map((mp: any) => {
        return [
            String(params.filterfields[mp])
        ]
    });

    let mdlchoices: any[] = []
    params.metrmodelchoices.forEach((mmc: Key_Name[], i: number) => {
        mmc.map((m: Key_Name) => {
            mdlchoices.push([m.Key, m.Name, i])
        })
    })


    //let simplefltvals = params.simplefiltervalues.concat(",")

    let enddate = ConvertDateToObject(params.endDate)
    let asofdate = ConvertDateToObject(params.asofDate)

    if (!params.useBanding) { params.fieldBand = 100 } //fieldband should not be zero, should have some limit

    dataArray.append("initialrun", String(true));
    dataArray.append("metrics", String(params.metrics));

    dataArray.append("existingmodelkey", existingmodelkey); //for models that are being updated

    dataArray.append("enddate_yr", String(enddate.year));
    dataArray.append("enddate_mo", String(enddate.month));
    dataArray.append("enddate_dy", String(enddate.day));
    dataArray.append("asofdate_yr", String(asofdate.year));
    dataArray.append("asofdate_mo", String(asofdate.month));
    dataArray.append("asofdate_dy", String(asofdate.day));


    dataArray.append("runDatetext", String(params.runDatetext));
    dataArray.append("dateSpread", params.dateSpread);

    dataArray.append("stickydateref", params.stickydateref)
    dataArray.append("offsetdays", String(params.offsetdays))
    dataArray.append("modelname", params.modelname);
    dataArray.append("mappings", String(mappings));
    dataArray.append("detailfields", String(detfields));
    dataArray.append("filterfields", String(fltfields));


    dataArray.append("xfieldname", params.xfieldname);
    dataArray.append("groupfield", params.groupfield);
    dataArray.append("schemakey", schemakey);
    dataArray.append("ruleModelKey", params.ruleModelKey);
    dataArray.append("filterModelKey", params.filterModelKey);
    dataArray.append("colorModelKey", params.colorModelKey);

    dataArray.append("fieldBand", String(params.fieldBand));
    dataArray.append("bandgroupname", String(params.bandgroupname));


    dataArray.append("period", params.period);
    dataArray.append("nperiods", String(params.nperiods));
    dataArray.append("iperiod", String(params.iperiod));
    dataArray.append("includetodate", String(params.includetodate))

    dataArray.append("simplefilterfield", params.simplefilterfield)
    dataArray.append("simplefiltervalues", String(params.simplefiltervalues))

    //dataArray.append("schedule", params.schedule);
    dataArray.append("schedulekeys", String('[]')); //first run is never "scheduled", for now
    dataArray.append("userid", user.id)
    dataArray.append("notes", params.notes)
    //dataArray.append("tablegroup", params.tablegroup)

    dataArray.append("metrmodelchoices", String(mdlchoices))

    let data: QueryModel = QueryModelInit[0];

    let path: string = ''
    if (uploadfull) {
        path = `${process.env.REACT_APP_API_URL}/api/query/basicpost`
    } else {
        path = `${process.env.REACT_APP_API_URL}/api/query/basicquerycheckmodels`
    }

    let res = await axios.post<any>(path, dataArray, config)
    let msg: string = "uploading"
    try {
        if (res.data.length > 0) {
            if (res.data !== "Nothing") { //hardcoded in views.BasicQueryPost
                data = res.data


            }
        };
        msg = "Complete!"
    }
    catch (error) {
        console.log(error)
        msg = String(error)
    }

    if (elem) { elem.innerHTML = msg };
    return (data)

};
export default QueryModelUpload;