
import { useState } from "react";
import "./css/StyleMainInput.css";


const StyleMainInput = (props: any) => {
    //side main, basic inputs
    //ref: scenariobuilder,
    let baseComponent = props.base
    let highlight: boolean = props.highlight

    let [collapsed, setCollapsed] = useState<boolean>(true)

    const ChangeCollapse = () => {
        setCollapsed(!collapsed)
    }

    return (<div>
        <div className="ndt_stlmaininput">
            <input type="checkbox" id="collapsible-mid" hidden />
            <div id="stlcollapsible-divI" >
                <div className="ndt_sideYborder">
                    <div className="ndt_sideYoffset">
                        <div className="ndt_sideYinner">
                            <label htmlFor="collapsible-mid"
                                className={highlight && collapsed ? "stltoggleLabel stlLabelI stlcollapseHighlight" : "stltoggleLabel stlLabelI"}
                                onClick={_ => ChangeCollapse()}>
                                {!collapsed ? "" : ""}
                            </label>
                            {baseComponent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
};
export default StyleMainInput;