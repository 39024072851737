
import StringReformatforBackend from '../../functions/String Functions/StringReformatforBackend';
import AxiosUpload from '../AxiosUpload';
import { Ruleset, Exception, User } from '../../type';

const RulesetsUpload = async (modelkey: string, ruleset: Ruleset, schema: string, user: User, config: any, elementstring: string) => {

    let dataArray = new FormData();
    console.log(ruleset)
    let dataencoded = ruleset.exceptions.map((exc: Exception) => {
        return [
            StringReformatforBackend(exc.ExceptionVal),
            StringReformatforBackend(exc.FieldNo)]
    })

    dataArray.append("modelkey", String(modelkey));
    dataArray.append("exceptionsFile", String(dataencoded));
    dataArray.append("fieldkey1", String(ruleset.Field1));
    dataArray.append("fieldkey2", String(ruleset.Field2));
    dataArray.append("ruleName", String(ruleset.RuleName));
    dataArray.append("ruleKey", String(ruleset.RuleKey));
    dataArray.append("operation", String(ruleset.Operation));
    dataArray.append("schemakey", String(schema));



    let elem: HTMLElement | null = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..."; }

    //post
    let y = await AxiosUpload(dataArray, "api/rulesets/rulepost", config, elem, "");
    return (y)
};

export default RulesetsUpload;