import { useState, ChangeEvent, FormEvent, useContext, useEffect } from "react";
import FieldsUploadFile from "../../data/Fields/Fields_UploadFile";
import FieldsDelete from "../../data/Fields/Fields_Delete";
import { StartupContext } from "../../App";
import { FieldMap, Schema, User, UserAccount } from "../../type";
import { useSelector } from "react-redux";
import GetUniquesFromObjectAttribute from "../../functions/GetUniquesFromObjectAttribute";
import SchemaUpload from "../../data/Schema/Schema_Upload";
import { SwatchesPicker } from "react-color";
import SchemaImport from "../../data/Schema/Schema_Import";
import { Modal } from "react-bootstrap";
import GenericBackendCall from "../../functions/Import/GenericBackendCall";
import { UserInit } from "../../InitTypes";
import GetGuestUser from "../../functions/User Functions/GetGuestUser";
import GetGuestPW from "../../functions/User Functions/GetGuestPW";

const AdminOptions = () => {

    let { schema, config, dispatcher, paths, user, allusers } = useContext(StartupContext)
    const fields: FieldMap[] = useSelector((state: any) => state.fieldmapping);
    const [primaryschema, setPrimarySchema] = useState<Schema | null>(null)
    const [fieldsFile, setFieldsFile] = useState<any>();
    const [modelname, setModelName] = useState<string>("");
    const [keyparams, setKeyParams] = useState<any>({
        key: '', name: '', color: '', isuploadvisible: false, isrunvaluesvisible: false, refresh: 0
    })
    const [guestUser, setGuestUser] = useState<UserAccount | null>(null)
    const [guestSchema, setGuestSchema] = useState<Schema | null>(null)


    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams])

    useEffect(() => {
        GetGuestUser(allusers, setGuestUser)
    }, [allusers])


    useEffect(() => {
        GetUniquesFromObjectAttribute(fields, "ModelName")
        SchemaImport("", "", user, config, dispatcher, true).then(d => {
            console.log(d)
            d.forEach((sch: Schema) => {
                if (sch.IsProductionDefault) {
                    setPrimarySchema(sch)
                    setKeyParams({ ...keyparams, key: sch.SchemaKey, name: sch.SchemaName, color: sch.BaseColor })
                }
                if (sch.IsGuest) {
                    setGuestSchema(sch)
                }
            })
        })

    }, [])

    const ApplyFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) { setFieldsFile(e.target.files[0]) }
    }

    //---backend----------
    const SubmitKeyFileToBackend = (e: FormEvent<HTMLFormElement>) => {
        FieldsDelete("*", 'Primary', config, dispatcher, "fieldstatus_div")
        FieldsUploadFile(e, fieldsFile, modelname, schema, "", config, dispatcher, "fieldstatus_div")
    }

    const CreateProdSchema = () => {
        SchemaUpload(keyparams.key, keyparams.name, "", keyparams.color, { fields: false, tables: false, metrics: false }, true, user, config, "ad_opt_divupl")
    }
    const ClickRunValues = () => {
        GenericBackendCall("", schema, paths.fields.allvaluesrunhistory, user, config, {}, "ad_opt_divvals")
    }

    const CreateGuest = () => {
        //authentication - move to separate file
        GenericBackendCall("", schema, paths.accounts.signup, user, config, {
            email: "guest@guest.com", name: "guest", password: GetGuestPW(), re_password: GetGuestPW()
        }, "ao_guestdiv")
    }

    const CreateGuestSchema = () => {
        if (guestUser) {
            SchemaUpload("", "GuestSchema", "", "#FFFFFF",
                { fields: "false", tables: "false", metrics: "false" }, false,
                { ...UserInit, id: guestUser.id, email: guestUser.email }, config, "ao_guestdiv")
        }
    }


    // const DeleteGuest = () =>{
    //     GenericBackendCall("", schema, paths.accounts.deleteuser, user, config, {
    //         email: "guest@guest.com", name: "guest"}, "ao_guestdiv")
    // }

    //----------------

    return (
        <div className="ndt_canvas">

            <div className="ndt_gridbox">
                <div className="ndt_title3">Import Field Keys File</div>

                <form onSubmit={e => SubmitKeyFileToBackend(e)}
                    style={{ 'marginTop': '20px', 'textAlign': 'left' }}>

                    <input type="file" className="empfilebtn" name="reviewFile" onChange={(e: ChangeEvent<HTMLInputElement>) => ApplyFile(e)} />
                    <div>
                        <div> ModelName </div>
                        <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => setModelName(e.target.value)} />
                    </div>
                    <button>Submit</button>
                </form>

                <div>
                    <button onClick={_ => FieldsDelete("*", "Primary", config, dispatcher, "fieldstatus_div")}> Delete Fields Data</button>
                </div>
                <div id="fieldstatus_div"> </div>
            </div>
            <br />

            <div className="ndt_gridbox">
                <div className="ndt_title3" >Create/Edit Primary (Production) Schema</div>
                <div style={{ fontWeight: "bold", margin: "5px", letterSpacing: ".1em" }}>{primaryschema ? "-" + primaryschema.SchemaName + "-" : "None Created"}</div>
                <div>
                    <div>
                        Name
                        <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, name: e.target.value }) }} />
                    </div>
                    <div>
                        <div>Using Color: {keyparams.color}</div>
                        <SwatchesPicker onChangeComplete={(e: any) => setKeyParams({ ...keyparams, color: e.hex })} />
                    </div>
                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, isuploadvisible: true }) }}>{primaryschema ? "Edit" : "Create"}</button>

                </div>
            </div>
            <br />



            <div className="ndt_gridbox">
                <div className="ndt_title3">Run Field Value History Tracker</div>
                <button className="ndt_btn1" onClick={_ => setKeyParams({ ...keyparams, isrunvaluesvisible: true })}>Run</button>
            </div>

            <div className="ndt_gridbox">
                <div style={{ fontSize: "22px" }}>Guest User/Schema</div>
                {guestUser ? <div>
                    {guestUser.email}
                    <button onClick={_ => { }}>Delete Guest User</button>
                </div> :
                    <div>Set Guest <button onClick={_ => { CreateGuest() }}>Create Guest User</button></div>}
                {guestSchema ? <div>
                    Guest Schema
                    <div >Uploads: {guestSchema.modelcount}</div>
                    <div >Dashboards: {guestSchema.dashcount}</div>
                    <div >Queries: {guestSchema.querycount}</div>

                </div> : <button onClick={_ => CreateGuestSchema()}>Create Schema</button>}


                <div id="ao_guestdiv"></div>
            </div>

            <Modal show={keyparams.isuploadvisible}>
                <div className="ndt_modal">
                    <div className="ndt_title4">{primaryschema ? "Create" : "Upload"} Schema {keyparams.name}</div>
                    <button onClick={_ => CreateProdSchema()}>Upload</button>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isuploadvisible: false })}>Close</button>
                    <div id="ad_opt_divupl"></div>
                </div>
            </Modal>

            <Modal show={keyparams.isrunvaluesvisible}>
                <div className="ndt_modal">
                    <div>Run Values History Tracker</div>
                    <button className="ndt_btn1" onClick={_ => ClickRunValues()}>Run All</button>
                    <div id="ad_opt_divvals"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isrunvaluesvisible: false })}>Close</button>

                </div>
            </Modal>

        </div>
    )
}
export default AdminOptions;