import * as d3 from 'd3';
import ChartDelete from './ChartDelete';


const DeletePrior = (svgid) => {
    //redundant to chartdelete - where am i still referencing this?
    ChartDelete(svgid)

    //remove table
    const svgrmv = d3.select(`${svgid}`);
    svgrmv.select("table").remove();
    svgrmv.select("svg").remove();

}
export default DeletePrior