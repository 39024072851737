import DateRemoveTimestamp from "./Date Functions/DateRemoveTimestamp";
import DateString from "./Date Functions/DateString";
import IsInObject from "./Object Functions/IsInObject";

function PivotDataFrame(data, rowFields, columnField, valuefield, includedetail, benchmarkfields) {
    //ref: createchart, 
    //Initialize an empty result object
    const result = {};

    let valueFields = [valuefield,"Benchmark"]
    // benchmarkfields.forEach((bf)=>{
    //     valueFields.push("Benchmark")
    // })

    // console.log(data)
    // console.log(benchmarkfields)
    // console.log(valueFields)

    // Iterate over the data and pivot it
    data.forEach((row) => {
        // Generate a unique key for each row based on rowFields
        const rowKey = rowFields.map((field) => row[field]).join('-');

        // Initialize a new object for the row if it doesn't exist
        if (!result[rowKey]) {
            result[rowKey] = {};
        }
  
        // Generate a unique key for each column based on columnFields
        const colKey = [row[columnField]]
        benchmarkfields.forEach((bf)=>{
            if (bf!==""){
                colKey.push(bf.Name)
            }
        })

        rowFields.forEach(rowField => {
            if (row[rowField]){
                result[rowKey][rowField] = [DateString(DateRemoveTimestamp(row[rowField])),"",""]
            }
        })

        // Assign the value to the corresponding cell
        if (includedetail){
            valueFields.forEach((fld,f)=>{
                //benchmark must be conditioned for multiple metrics, multiple benchmarks. May still be confusion for multi 2.14.24
                if (fld==="Benchmark"){
                    //console.log(row['Metric'], IsInObject(benchmarkfields,row['Metric'], "Key"))
                    if (IsInObject(benchmarkfields,row['Metric'], "Key")){
                        result[rowKey][colKey[f]] = [row[fld], row['id'], row['EncodedKey']];
                    }
                } else {
                    result[rowKey][colKey[f]] = [row[fld], row['id'], row['EncodedKey']];
                }    
            })

            //result[rowKey]["Benchmark"]
        } else {
            valueFields.forEach((fld,f)=>{
                result[rowKey][colKey[f]] = row[fld];
            })
        }

        // colvals.forEach((c)=>{
        //     console.log(c, result.hasOwnProperty(c))
        //     if (!result.hasOwnProperty(c)){
        //         result[c] = ["","",""]
        //     }
        // })

        // console.log(result)

    });

    let arry = Object.keys(result).map(k=>{
        return result[k]
    })

    return arry;
  }

export default PivotDataFrame