import GenericDropdown from "../../../functions/Dropdown_Generic";
import { useState, ChangeEvent, useEffect, useContext } from "react";
import { ColorSet, Ruleset, GroupSet, FieldMap, FilterSet, AllValues } from "../../../type";
import FieldsImport from "../../../data/Fields/Fields_Import";
import { StartupContext } from "../../../App";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import FilterData from "../../../functions/FilterData";
import { FieldMapInit } from "../../../InitTypes";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import GetObjectValue from "../../../functions/Object Functions/GetObjectValue";
import ConvertBackendDates from "../../../functions/Date Functions/ConvertBackendDates";
import DateString from "../../../functions/Date Functions/DateString";
import { Link } from "react-router-dom";
import "./css/SetsTracker.css"


const SetsTracker = () => {

    let { config, schema, user, dispatcher, paths } = useContext(StartupContext)

    interface statedata {
        colorsets: ColorSet[];
        filtersets: FilterSet[];
        rulesets: Ruleset[];
        groupsets: GroupSet[];
    }
    interface modelany {
        Key: string; Name: string; LastUpdateDate: Date, Category: string, linkurl: string
    }
    let modelanyInit: modelany = { Key: "", Name: "", LastUpdateDate: new Date, Category: "", linkurl: "" }

    interface modelset {
        mdl: string, keyfld: string, namefld: string, fieldfld: string, datefld: string, linkurl: string; url: string, setFn: any
    }

    interface paramsint {
        imported: boolean; selfld: FieldMap; datasetname: string; selmodel: modelany;
    }

    let [params, setParams] = useState<paramsint>({ imported: false, selfld: FieldMapInit[0], datasetname: "", selmodel: modelanyInit })
    let [fields, setFields] = useState<FieldMap[]>([])
    let [dataSets, setDataSets] = useState<statedata>({ colorsets: [], filtersets: [], rulesets: [], groupsets: [] })
    let [colset, setColSet] = useState<ColorSet[] | null>(null)
    let [fltset, setFltSet] = useState<FilterSet[] | null>(null)
    let [rulset, setRulSet] = useState<Ruleset[] | null>(null)
    let [grpset, setGrpSet] = useState<GroupSet[] | null>(null)
    let [seldataset, setSelDataSet] = useState<modelany[]>([])
    let [allvals, setAllVals] = useState<AllValues[]>([])

    useEffect(() => {
        FieldsImport("*", schema, config, dispatcher, true).then(d => setFields(FilterData(d, "TrackChanges", "true")))
    }, [])

    let modelsetlog: modelset[] = [
        { mdl: "colorsets", keyfld: "SetKey", namefld: "SetName", fieldfld: "FieldName", datefld: "LastUpdatedDate", linkurl: "/colorsets/editor", url: paths.colorsets.colorsetview, setFn: setColSet },
        { mdl: "filtersets", keyfld: "Key", namefld: "FilterName", fieldfld: "Field", datefld: "LastUpdatedDate", linkurl: '/filtersets/manager', url: paths.filtersets.filtersetsview, setFn: setFltSet },
        { mdl: "rulesets", keyfld: "RuleKey", namefld: "RuleName", fieldfld: "Field1", datefld: "LastUpdatedDate", linkurl: '/rulesets/manager', url: paths.rulesets.rulesetsview, setFn: setRulSet },
        { mdl: "groupsets", keyfld: "SetKey", namefld: "", fieldfld: "FieldName", datefld: "LastUpdatedDate", linkurl: '/groupsets/manager', url: paths.groupsets.groupsetsview, setFn: setGrpSet }
    ]

    useEffect(() => {
        console.log('rerun', params.selfld.Key)
        modelsetlog.forEach((mdl: modelset) => {
            GenericBackendCall("*", schema, mdl.url, user, config).then(d => {
                let data = ConvertBackendDates(d)
                if (params.selfld.Key !== "") {
                    data = FilterData(d, mdl.fieldfld, params.selfld.Key)
                }
                mdl.setFn(data)
            })
        })
    }, [params.selfld.Key])


    useEffect(() => {
        if (colset && fltset && rulset && grpset) {
            setDataSets({ colorsets: colset, filtersets: fltset, rulesets: rulset, groupsets: grpset })
            setColSet(null)
            setFltSet(null)
            setRulSet(null)
            setGrpSet(null)
        }
    }, [colset, fltset, rulset, grpset])

    useEffect(() => {
        modelsetlog.forEach((mdl: any) => {
            if (mdl.mdl === params.datasetname) {
                let data = GetObjectValue(dataSets, params.datasetname)
                let converteddata = data.map((d: any) => {
                    return ConvertData(d, mdl)
                })
                setSelDataSet(converteddata)
            }
        })
    }, [params.datasetname])

    useEffect(() => {
        if (params.selmodel.Key !== "") {
            let promise = GenericBackendCall("", schema, paths.fields.allvaluesview, user, config, { fieldkey: params.selfld.Key, date: params.selmodel.LastUpdateDate })
            promise.then((d: AllValues[]) => {
                setAllVals(d)
            })
        }
    }, [params.selmodel.Key])

    const ConvertData = (d: any, mdl: modelset): modelany => {
        //data = colorset, fieldset, etc.
        return { Key: d[mdl.keyfld], Name: d[mdl.namefld], LastUpdateDate: d[mdl.datefld], Category: mdl.mdl, linkurl: mdl.linkurl }
    }

    return (<div className="ndt_canvas">
        <div className="ndt_title2">Sets Tracker</div>

        <div className="ndt_gridbox">
            Tracked Fields:
            <GenericDropdown
                data={fields}
                keycol="Key"
                namecol="FieldName"
                promptstring="Select Tracked Field"
                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, fields, params, setParams, "selfld", "", "") }}
            />
        </div>

        <div className="ndt_gridbox">
            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, datasetname: e.target.selectedOptions[0].attributes[0].value }) }}>
                <option value="">Select:</option>
                <option value="colorsets">Color Sets ({dataSets.colorsets.length})</option>
                <option value="filtersets">Filter Sets ({dataSets.filtersets.length})</option>
                <option value="rulesets">Rule Sets({dataSets.rulesets.length})</option>
                <option value="groupsets">Group Sets ({dataSets.groupsets.length})</option>
            </select>
        </div>

        <GenericDropdown
            data={seldataset}
            keycol="Key"
            namecol="Name"
            promptstring="Select Set:"
            change={(e: ChangeEvent<HTMLSelectElement>) => {
                ChangeParamSelectorbyIndex(e, seldataset, params, setParams, "selmodel", "")
            }}
        />

        {params.selmodel.Key ? <div>
            Selected Model: {params.selmodel.Name}
            Last Updated {DateString(params.selmodel.LastUpdateDate)}
            <Link className="nav-link active" aria-current="page"
                to={params.selmodel.linkurl + "/" + params.selmodel.Key} >View Set</Link>
            {allvals.map((av: AllValues, i: number) => {
                return (<div key={i} style={{ display: "flex" }}>
                    <div className="settrk_avitem">{av.Value}</div>
                    <div className="settrk_avitem">{DateString(av.DateAdded)}</div>

                </div>)
            })}
        </div> : <div></div>}

    </div>)
};
export default SetsTracker