

const StringReformatforBackend = (str1: any) => {
    let str2 = String(str1).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`)
    str2 = String(str2).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`)
    str2 = String(str2).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`)


    return str2
};
export default StringReformatforBackend