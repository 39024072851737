
function CompareObjects(obj1, obj2){
    //key name, value1, value2, difference (numeric only)
    let mismatches = []
    Object.entries(obj1).forEach(([key,value]) => {
        if(key!=="id"){
            if (obj1[key] !== obj2[key]){
                if (typeof(obj1[key])==="number"){
                    mismatches.push([key, obj1[key], obj2[key],obj1[key] - obj2[key]])
                } else{
                    mismatches.push([key, obj1[key], obj2[key]])
                }

            }
        }
    })
    return(mismatches)
};

export default CompareObjects;
