import * as d3 from 'd3';

const ForceBubble = (data, xfield, yfield, fieldnames, div_ID,size) => {

	d3.select("#svgshow").remove();

	let minradius = 20

	let width = size[0]
	let height = size[1]
	let margin = {top: 10, bottom: 10}
	
	let countTotal = 0
		data.forEach(function(d) {
			countTotal +=  Math.sqrt(+d[yfield])
		})

	
	let nodes =  data.map(function(d,i) {
			   var node = {};
				   node.id = i;
				   node.sleeve = d[xfield];
				   node.groupNum = 0;
				   node.groupName = d[xfield];
				   node.count = +d[yfield];
				   node.radius = Math.sqrt(+d[yfield])/Math.sqrt(countTotal) * 110;
				   return node;
				});
		console.log(nodes);

		var svg = d3.select(div_ID)
				.append('svg')
				.attr("id","svgshow")
				.attr("width",width)
				.attr("height",height)
				.attr("transform","translate(0,"+ margin.top +")")
				.style("background-color", "#333")
				.append("g");
	
		var clusters = d3.scalePoint()
				.domain(d3.range(data.length))
				.range([100,height-100]);
	
	
		var color = d3.scaleSequential()
		  		.domain([0,data.length])
		  		.interpolator(d3.interpolateRainbow);
	
		var simulation = d3.forceSimulation()
			    .force("charge", d3.forceManyBody())
			    .force('collision', d3.forceCollide().radius(function(d){return d.radius;}))
			    .force("center", d3.forceCenter(width / 2, height / 2))

		simulation
			.nodes(nodes)
			.on("tick", ticked);

		/* draw the circles */
		var node = svg.append("g")
			.attr("class", "nodes")
			.selectAll("circle")
			.data(nodes)
			.enter()
			.append("circle")
			.attr("r", function(d){return d.radius;})
			.attr("fill", function(_,i) { return color(i) })
	/*		.call(d3.drag()
			  .on("start", dragstarted)
			  .on("drag", dragged)
			  .on("end", dragended));
		*/
		
		//tooltip
		node.append("title")
				.text(function(d) {return d.groupName;})

		var label = svg.append("g")
						.attr("class", "label_text")
						.selectAll("nodes")
						.data(nodes)
						.enter()
						.append("text")
						.attr("fill","white")
						.attr("x",0)
						.attr("y",0)
						.text(function(d){ return d.radius >= minradius ? d.groupName : ""; });
			
	function ticked() {
		var k = this.alpha() * .5;
		//move the nodes to their foci/cluster
		nodes.forEach(function(n, i) {
				n.y += (clusters(n.groupNum) - n.y) * k;
				n.x += (0 - n.x) * k;
		  });
		//update coordinates for the circle
		node
			.attr("cx", function(d) { return d.x; })
			.attr("cy", function(d) { return d.y; });
		label
			.attr("x", function(d) { return d.x; })
			.attr("y", function(d) { return d.y; });		
	}

	/*
		function dragstarted(d) {
		if (!d3.event.active) simulation.alphaTarget(.03).restart();
		d.fx = d.x;
		d.fy = d.y;
		}

		function dragged(d) {
		d.fx = d3.event.x;
		d.fy = d3.event.y;
		}

		function dragended(d) {
		if (!d3.event.active) simulation.alphaTarget(.03);
		d.fx = null;
		d.fy = null;
		}
	*/
	
};
export default ForceBubble;