function CompareObjects_SingleField(obj1, obj2, fieldName){

    if (obj1[fieldName]!==obj2[fieldName]){
        return([obj1[fieldName],obj2[fieldName]])
    } else {
        return([])
    }

};
export default CompareObjects_SingleField;
