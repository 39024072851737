import { UserAccount } from "../../type";


const GetGuestUser = (allusers: UserAccount[], setfn: any) => {
    allusers.forEach((usr: UserAccount) => {
        if (usr.email === "guest@guest.com") {
            setfn(usr)
        }
    })
};
export default GetGuestUser;