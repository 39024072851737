import { Schedule } from "../../type";
import CalculateDateSequence from "./CalculateDateSequence";
import CalculateStickyDate from "./CalculateStickyDate";

const CalculateSchedule = (sched: Schedule, n: number = 5, offset = 0, includehist: boolean = true, fromdate: Date = new Date()): Date[][] => {
    //ref by ScheduleManager, DashboardsManage, BenchmarkManager, DatasetWalkthrough

    let newasofdate = CalculateStickyDate(sched.AnchorDate, sched.StickyDateRef, sched.Period, sched.IPeriod, sched.OffsetDays, true, true, fromdate)

    if (n > 0) {
        let hist = CalculateDateSequence(newasofdate, sched.Period, n - offset, sched.IPeriod, false)
        let future = CalculateDateSequence(newasofdate, sched.Period, n + offset, sched.IPeriod, true)

        if (includehist) {
            let alldates = hist.reverse().concat(future)
            return (alldates)
        } else {
            return future
        }

    } else {
        return []
    }
};

export default CalculateSchedule;