import axios from "axios";
import { User } from "../../type";
//import ConvertBackendDates from "../Date Functions/ConvertBackendDates";
import DatatoFormArray from "./DatatoFormArray";
import ConvertBackendDates from "../Date Functions/ConvertBackendDates";


const GenericBackendCall = async (modelkey: string, schemakey: string, path: string,
    user: User, config: any, otherdata: any = {}, elemstring: string = "", completestring: string = "",
    convertdates: boolean = false): Promise<any> => {

    //functions as data or a return string
    let retdata: any[] = []
    let dataArray = DatatoFormArray(otherdata);
    dataArray.append("modelkey", String(modelkey));
    dataArray.append("schemakey", String(schemakey));
    dataArray.append("userid", String(user.id));
    //dataArray.append("userid1", String(1)); //what is this for?

    let elem = null;
    if (elemstring !== "") {
        elem = document.getElementById(elemstring);
    }
    if (elem) {
        elem.innerHTML = "loading"
    }
    try {
        let res = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/` + path,
            dataArray, config);
        if (convertdates) {
            retdata = ConvertBackendDates(res.data)
        } else {
            retdata = res.data
        }
    } catch (error) {
        retdata = ["There was an error!"]
        console.log(error)
    }

    if (elem) {
        if (completestring !== "") {
            elem.innerHTML = completestring
        } else {
            elem.innerHTML = String(retdata)
        }
    }

    return (retdata)
};
export default GenericBackendCall;