
const CheckIsDate = (datestring: any): boolean => {
    const timestampRegex = /\b([01]?\d|2[0-3]):[0-5]?\d:[0-5]?\d\b/;

    // Test if the string contains a timestamp
    //console.log("test", datestring, timestampRegex.test(datestring))
    if (timestampRegex.test(datestring)) {
        return true
    }

    if (typeof (datestring) !== "number" && String(+datestring) === "NaN") {
        if (String(datestring).indexOf("") >= 0) {
            const date = new Date(datestring);

            // Check if the resulting date is valid
            //ref: customtable, datestring
            return !isNaN(date.getTime());
        } else {
            return false
        }
    } else {
        return false
    }
};
export default CheckIsDate;