import IsInVector from "../IsInVector"
import IsInObject from "../Object Functions/IsInObject";

const AddtoArray = (arry: any[], val: any, objfield: string = "", addobject: boolean = true, noblank: boolean = true) => {
    //ref: scenariobuilder, 
    //objfield for adding objects

    if (!noblank || val !== "") {
        if (objfield === "") {
            if (!IsInVector(arry, val)) {
                arry.push(val)
            }
        } else {

            if (!IsInObject(arry, val[objfield], objfield)) {
                if (addobject) {
                    arry.push(val)
                } else {
                    arry.push(val[objfield])
                }
            }
        }
    }
    return arry
};

export default AddtoArray;