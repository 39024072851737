import axios from 'axios';
import { tablegroupsActionCreator, tablegroupsdeleteActionCreator } from '../../reducers/tablegroup_actions';
import { User } from '../../type';

const TableGroupsImport = async (connmodelkey: string, schema: string, path: string, user: User, config: any, dispatcher: any, store: boolean): Promise<any> => {
    //ref: onload, tablemgr, metricsmanager, genericdatatable,
    let dset: any = []

    try {
        let schema_resp = await axios.post(`${process.env.REACT_APP_API_URL}/${path}`,
            { 'modelkey': connmodelkey, 'userid': user.id, schemakey: schema }, config,
        )
        if (dispatcher !== "") {
            dispatcher(tablegroupsdeleteActionCreator([]))
        }
        dset = schema_resp.data //should only be one
        if (store) {
            dispatcher(tablegroupsActionCreator(dset))
        }
    } catch (error) {
        console.log(error)
    }

    return (dset)

};
export default TableGroupsImport;