import * as d3 from 'd3';

const TreeLayout = ({data}) => {
    
   // data = data.data
    console.log(data)

    //translate graph points
    let a = 10
    let b = 10

    d3.selectAll(".svgtree").remove()
    
    let svg = d3.select("body").append("svg")
                .attr("class","svgtree")
                .attr("height","600")
                .attr("width","1200")
                .attr("margin-left", "10px")
                .attr("style", "outline: thin solid black");

        var treeLayout1 = d3.tree()
            .size([1100, 500])
        
        var root = d3.hierarchy(data)

        treeLayout1(root)

          svg.selectAll('line.link')
                .data(root.links())
                .join('line')
                .classed('link', true)
                .attr('x1', function(d) {return d.source.x + a;})
                .attr('y1', function(d) {return d.source.y + b;})
                .attr('x2', function(d) {return d.target.x + a;})
                .attr('y2', function(d) {return d.target.y + b;})
                .attr("stroke","red")
                .attr("stroke-width", 2);
         
        var Tooltip = d3.select("body")
                .append("div")
                .style("opacity", 0)
                .attr("class", "tooltip")
                .style("background-color", "red")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("padding", "5px")
                  
        let circles = svg.selectAll('circle.node')
            .data(root.descendants())
            .join('circle')
            .classed('node', true)
            .attr('cx', function(d) {return d.x + a;})
            .attr('cy', function(d) {return d.y + b;})
            .attr("id",d=> d.data.data[0])
            .attr('r', 4)
            .on("mouseover",function(d){
                if (d.target.attributes.length>4){ //prevent error for id-less node
                  let nodeid = d.target.attributes.id.value;
                    Tooltip
                      .style("opacity", 1)
                      .html("ID: " + nodeid)
                    d3.select(this)
                      .style("stroke", "black")
                      .style("opacity", 1)

              }
            })
          
            circles.append("g").append("rect")
            .attr("class","rect1")
            .attr("fill", "red")
            .attr("height","50px")
            .attr('width','50px')
            .text('x')

            function handleZoom(e) {
                d3.select('svg')
                  .attr('transform', e.transform);
              }
              
            let zoom = d3.zoom()
                .on('zoom', handleZoom);
              
            let drag = d3.drag()
              .on('drag',handleZoom);

              svg
                .call(zoom)
                .call(drag);

    return(<div>Treelayout</div>)
};
export default TreeLayout;