
import NetworkGraph2D from '../../sna/networkgraph/NetworkGraph2D';
import { useState, ChangeEvent, useEffect, useContext, useMemo } from 'react'
import { FieldMap, NetModel, NetworkRunDate, SNAAttrField, snaState, DescModel, Workspace, Emp_State, ScenarioModel, AIMessage, ScenarioPath, ScenarioEvent, Employee, Department, JobTitle, Position, Node } from "../../../type";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import { StartupContext } from "../../../App";
import { FieldMapInit, NetworkRunDateInit, ScenarioEventInit, ScenarioInit, ScenarioPathInit, SNAStateInit } from "../../../InitTypes";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import GetFieldName from "../../../functions/GetFieldName";
import FieldsImport from "../../../data/Fields/Fields_Import";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import NetworkGraphSetup from "../../sna/networkgraph/NetworkGraphSetup";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import StringTrim from "../../../functions/String Functions/StringTrim";
import { AIModel, MessageBranch } from "../../../type";
import DatePicker from 'react-date-picker';
import { Modal } from 'react-bootstrap';
import "./css/ScenarioBuilder.css"
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import GetObjectValue from '../../../functions/Object Functions/GetObjectValue';
import SetObjectfromKey from '../../../functions/SetFunctions/SetObjectfromKey';
import { useParams, useNavigate } from 'react-router-dom';
import StyleMainSelect from "../../../styling/StyleMainSelect";
import StyleMainInput from '../../../styling/StyleMainInput';
import GetInputText from '../../../functions/HTML Functions/GetInputText';
import SerializeDataFrame from '../../../functions/Import/SerializeDataFrame';
import GetUniquesFromObjectAttribute from '../../../functions/GetUniquesFromObjectAttribute';
import FilterData from '../../../functions/FilterData';
import DateString from '../../../functions/Date Functions/DateString';
import PivotArray from '../../../functions/PivotArray';
import AddtoArray from '../../../functions/Array Functions/AddtoArray';
import SetTextAreaText from "../../../functions/SetTextAreaText";
import IsInObject from '../../../functions/Object Functions/IsInObject';


const ScenarioBuilder = () => {

    let { config, dispatcher, schema, paths, user, clickstyle } = useContext(StartupContext)

    const [SNAstate, setSNAstate] = useState<snaState>(SNAStateInit)
    let { id } = useParams()
    let history = useNavigate()

    interface paramtype {
        view: string;
        mainview: string; //unused
        scenariokey: string; //for model save/refresh
        scenarioname: string;
        selModelName: string;
        selModelKey: string;
        selattrcategory: string;
        selrundatekey: string;
        pathname: string;
        chattext: string;
        msgtype: string;
        branchkey: string;
        pathkey: string;
        scenario_asofdate: Date;
        chat_asofdate: Date;
        workspacekey: string;
        workspacename: string;
        aimodelkey: string;
        refresh: number;
        isdeletevisible: boolean;
        iscreatenewvisible: boolean;
        isdeletesnavisible: boolean;
        reimportAIModel: boolean;
        isaddpathvisible: boolean;
        isdeletepathvisible: boolean;
        isdeleteeventvisible: boolean;
        highlight_select: boolean;
        highlight_input: boolean;
        chattrigger: number;
        updateSNA: boolean;
        datatrigger: boolean;
        importdata: boolean;
        isselempvisible: boolean;
    }
    let paraminit: paramtype = {
        view: '', mainview: '', scenariokey: '', scenarioname: '', selModelName: "", selModelKey: "", selattrcategory: '',
        selrundatekey: '', refresh: 0, chattext: '', msgtype: '', branchkey: '', aimodelkey: '', pathkey: '', pathname: '',
        scenario_asofdate: new Date(), chat_asofdate: new Date(), workspacekey: '', workspacename: '', updateSNA: false,
        isdeletevisible: false, isdeletesnavisible: false, iscreatenewvisible: false, reimportAIModel: false,
        isaddpathvisible: false, isdeletepathvisible: false, isdeleteeventvisible: false, chattrigger: 0,
        highlight_select: true, highlight_input: false, datatrigger: false, importdata: false,
        isselempvisible: false
    }
    interface mdlparams {
        catuniques: string;
        fieldunique: string;
        uniquedata: any[];
        uniquevals: string[];
        fieldcategory: string;
        catflds: FieldMap[];
        showdatatype: string;
        seldata: any[];
        selvalue: string;
        descmdlkey: string;
    }
    interface eventparams {
        edittype: string;
        mgrID: string;
        empID: string;
        empName: string;
        isselected: boolean;
    }
    let textoptions = ["The company plans to add to its workforce due to a push in sales",
        "Add to the IT team to account for a new software.",
        "The finance team is being trimmed after purchasing 3rd party financial assistance",

    ]
    let [fields, setFields] = useState<FieldMap[]>([])
    //let [networks, setNetworks] = useState<NetModel[]>([])
    let [scenarios, setScenarios] = useState<ScenarioModel[]>([])
    let [selScenario, setSelScenario] = useState<ScenarioModel | null>(null)

    let [selNetwork, setSelNetwork] = useState<NetModel | null>(null) //select a network view
    let [selNetworkRunDate, setSelNetworkRunDate] = useState<NetworkRunDate>(NetworkRunDateInit[0])
    let [keyparams, setKeyParams] = useState<paramtype>(paraminit)
    let [attrcats, setAttrCats] = useState<SNAAttrField[]>([])
    const [selAIModel, setSelAIModel] = useState<AIModel | null>(null)
    const [selBranch, setSelBranch] = useState<MessageBranch | null>(null)
    const [selPath, setSelPath] = useState<ScenarioPath | null>(null)
    const [selEvent, setSelEvent] = useState<ScenarioEvent | null>(null)
    const [jdmodels, setJDModels] = useState<DescModel[]>([])
    const [eventparams, setEventParams] = useState<eventparams>({ edittype: '', empID: '', empName: '', mgrID: '', isselected: false })
    const [pthData, setPthData] = useState<{ EmployeeState: Emp_State[], Department: Department[], JobTitle: JobTitle[], Position: Position[] }>({
        EmployeeState: [], Department: [], JobTitle: [], Position: []
    })
    const [fldData, setFldData] = useState<{ EmployeeState: FieldMap[], Department: FieldMap[], JobTitle: FieldMap[], Position: FieldMap[] }>({
        EmployeeState: [], Department: [], JobTitle: [], Position: []
    })
    let modelcategories = ["EmployeeState", 'Department', "JobTitle"];
    let [modelparams, setModelParams] = useState<mdlparams>({
        catuniques: '', fieldunique: '', uniquedata: [], uniquevals: [], fieldcategory: "EmployeeState", catflds: [], showdatatype: '',
        seldata: [], selvalue: '', descmdlkey: ''
    })

    const [highlightParams, setHighlightParams] = useState<{ select: boolean; input: boolean }>({
        select: true,
        input: false
    })
    const edittypes: string[] = ["Move", "Duplicate", "Terminate"]

    useEffect(() => {
        if (keyparams.refresh >= 1000) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        FieldsImport("", schema, config, dispatcher, false).then(d => { setFields(d); })
        ImportAndSetStateGeneric(setScenarios, "", schema, paths.scenario.scenarioview, user, config, {})
        ImportAndSetStateGeneric(setJDModels, "", schema, paths.jobdescription.descmodelview, user, config, { type: "name" })
    }, [])

    useEffect(() => {
        if (id !== "") {
            SetObjectfromKey(String(id), scenarios, "Key", setSelScenario, false)
        }

    }, [id, scenarios])

    useEffect(() => {
        if (selScenario) {

            setKeyParams({ ...keyparams, workspacekey: GetObjectValue(selScenario.WorkspaceName, "WorkKey") })
            setSelPath(null)
            setSelBranch(null)
            setSelAIModel(null)
            setSelNetwork(null)
            setSelNetworkRunDate(NetworkRunDateInit[0])
        }
    }, [selScenario?.Key])

    useEffect(() => {
        if (selScenario && selScenario.Key !== "") {
            GenericBackendCall("", schema, paths.sna.netmodelview, user, config, { 'scenariokey': selScenario.Key }).then(d => {
                if (d.length === 1) {
                    setSelNetwork(d[0])
                    let aimodelkey = ""
                    GenericBackendCall("", schema, paths.ai.aimodelview, user, config, { type: "branch", workspacekey: GetObjectValue(selScenario.WorkspaceName, "WorkKey") }).then((d: AIModel[]) => {
                        if (d.length === 1) {
                            setSelAIModel(d[0])
                            aimodelkey = d[0].ModelKey
                        }
                    })
                    setKeyParams({ ...paraminit, aimodelkey: aimodelkey, reimportAIModel: true, workspacekey: GetObjectValue(selScenario.WorkspaceName, "WorkKey") })
                    history("/scenario/manager/" + selScenario.Key)
                }
            })

        } else {
            //history("/scenario/manager/")
            setKeyParams({ ...keyparams, iscreatenewvisible: true, workspacekey: '' })
        }
        if (selScenario) {
            setHighlightParams({ ...highlightParams, select: false, input: true })
        }
    }, [selScenario])

    useEffect(() => {

        if (selNetwork && selNetwork.attrfields) {
            let newfields: SNAAttrField[] = selNetwork.attrfields.map((af: SNAAttrField) => {
                return { ...af, FieldName: GetFieldName(fields, af.FieldName) }
            })
            setAttrCats(newfields)
        }
        setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
    }, [selNetwork])

    useEffect(() => {
        if (keyparams.selattrcategory !== "") {
            RefreshRun_Attr()
        }
    }, [keyparams.selattrcategory])

    useEffect(() => {
        if (selPath) {
            RerunSNA()
        }
    }, [selPath?.PathKey])

    useEffect(() => {
        GenericBackendCall(keyparams.selrundatekey, schema, paths.sna.netmodelrunview, user, config, {}).then(d => {
            if (d.length === 1) {
                console.log(d[0])
                setSelNetworkRunDate(d[0])
                setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
            }
        })
    }, [keyparams.selrundatekey])

    useEffect(() => {
        if (keyparams.aimodelkey !== "" || selNetwork || keyparams.reimportAIModel) {
            let otherdata: any = { type: "branch" }
            if (selNetwork) {
                otherdata['categorymodelkey'] = selNetwork.ModelKey
            }
            GenericBackendCall(keyparams.aimodelkey, schema, paths.ai.aimodelview, user, config, otherdata).then((d: AIModel[]) => {
                if (d.length === 1) {
                    setSelAIModel(d[0])
                    setKeyParams({ ...keyparams, reimportAIModel: false })
                }
            })
        }
    }, [keyparams.aimodelkey, selNetwork, keyparams.reimportAIModel])

    useEffect(() => {
        if (selPath) {
            GenericBackendCall(selPath.PathKey, schema, paths.scenario.scenariopathview, user, config, {}).then((d: ScenarioPath[]) => {
                if (d.length === 1) {
                    setSelPath(d[0])
                    setSelEvent(ScenarioEventInit[0])
                }
            })
        }
    }, [keyparams.chattrigger])

    useEffect(() => {
        if (keyparams.pathkey !== "" && keyparams.pathkey !== "_" && selAIModel && selScenario) {

            SetObjectfromKey(keyparams.pathkey, selScenario.paths, "PathKey", setSelPath, false)
            let returnbr: number = SetObjectfromKey(keyparams.pathkey, selAIModel.branches, "CategoryModelKey", setSelBranch, true)
            let branchuse: MessageBranch = selAIModel.branches[returnbr]
            setKeyParams({ ...keyparams, branchkey: branchuse.BranchKey })
        } else if (keyparams.pathkey === "_") {
            setKeyParams({ ...keyparams, isaddpathvisible: true })
        }
    }, [keyparams.pathkey])

    useEffect(() => {
        if (selNetwork && keyparams.updateSNA) {
            RerunSNA()
        }
    }, [keyparams.updateSNA])

    useEffect(() => {
        if (modelparams.fieldunique !== "") {
            let fldattr = GetFieldName(fields, modelparams.fieldunique, "Key", "AttrName")
            let data = PivotArray(GetObjectValue(pthData, modelparams.catuniques), fldattr, "")
            setModelParams({ ...modelparams, uniquedata: data, uniquevals: [] })
        }
    }, [modelparams.fieldunique])

    useEffect(() => {
        if (keyparams.branchkey !== "") {
            GenericBackendCall(keyparams.branchkey, schema, paths.ai.aibranchview, user, config, {}).then(d => {
                console.log(d)
                if (d.length === 1) {
                    setSelBranch(d[0])
                }
            })
        }
    }, [keyparams.branchkey])

    useEffect(() => {
        if (keyparams.importdata) {
            GenericBackendCall("", schema, paths.scenario.scenariodataview, user, config, {
                modelcats: modelcategories, workspacekey: keyparams.workspacekey,
                scenariopathkey: selPath?.PathKey
            }, "").then(d => {
                //employee, jobcode, dept, posn
                setPthData({ EmployeeState: SerializeDataFrame(d[0]), Department: SerializeDataFrame(d[1]), JobTitle: SerializeDataFrame(d[2]), Position: SerializeDataFrame(d[3]) })
                let newflds: any = {}
                modelcategories.forEach((mc: string) => {
                    let flds: FieldMap[] = FilterData(fields, "ModelCategory", mc)
                    newflds[mc] = flds
                })

                setFldData(newflds)
                setKeyParams({ ...keyparams, datatrigger: true, importdata: false })
            })
        }
    }, [keyparams.importdata])

    useEffect(() => {
        if (modelparams.fieldcategory !== "" && selPath) {

            let data = GetObjectValue(pthData, modelparams.fieldcategory)

            if (modelparams.showdatatype === "path") {
                data = FilterData(data, "ScenarioPathName", selPath.PathKey, false)
            } else {
                data = FilterData(data, "ScenarioPathName", "", false)
            }
            let flds = GetObjectValue(fldData, modelparams.fieldcategory)

            if (modelparams.fieldcategory == "EmployeeState") {
                //make custom edits to EmployeeState fields
                flds = flds.map((fld: FieldMap) => {
                    if (fld.AttrName === "DeptID") {
                        fld.AttrName = "DepartmentName"
                        fld.FieldName = "Department"
                    } else if (fld.AttrName === "JobCode") {
                        fld.AttrName = "JobTitle"
                        fld.FieldName = "JobTitle"
                    }
                    return fld
                })
                if (!IsInObject(flds, "Status", "AttrName") && flds.length > 0) {
                    flds.push({ ...FieldMapInit[0], AttrName: "Status", FieldName: "Status" })
                }
            }
            setModelParams({
                ...modelparams, catflds: flds, seldata: data, fieldunique: ''
            })
        } else {
            setModelParams({ ...modelparams, catflds: [] })
        }

    }, [modelparams.fieldcategory, modelparams.showdatatype])

    useEffect(() => {
        if (modelparams.descmdlkey !== "") {
            GenericBackendCall(modelparams.descmdlkey, schema, paths.jobdescription.skillsnapshot, user, config, { type: "name" }).then(d => {
                console.log(d)
            })
        }
    }, [modelparams.descmdlkey])

    //-------------------------
    const RerunSNA = () => {
        if (selNetwork) {

            GenericBackendCall(selNetwork?.ModelKey, schema, paths.sna.netmodelcreaterun, user, config, {
                asofdate: keyparams.scenario_asofdate, saveAI: "true", scenariokey: selScenario?.Key,
                scenariopathkey: selPath?.PathKey, saverun: "false", filterfldkeys: [modelparams.fieldunique],
                filtervals: modelparams.uniquevals

            }, "scb_divupl").then(d => {
                let nodes = SerializeDataFrame(d[0])
                let links = SerializeDataFrame(d[1])
                let netrundate: NetworkRunDate = {
                    DateKey: "_", ModelName: '', ScenarioPathName: '', nodes: nodes, links: links,
                    AsofDate: keyparams.scenario_asofdate, RunDate: new Date()
                }
                setSelNetworkRunDate(netrundate)

                let maxcat = 1
                let maxcatstr = ""

                Object.keys(nodes[0]).forEach((k: string) => {
                    if (k.substring(0, 9) == "Attribute") {
                        let cat = +k.slice(9)
                        if (cat > maxcat) {
                            maxcat = cat
                            maxcatstr = k
                        }
                    }
                })
                let scenpathattr = "Attribute" + String(maxcat - 1)
                let cats: any[] = GetUniquesFromObjectAttribute(nodes, scenpathattr, true)

                setSNAstate({ ...SNAStateInit, categoryattribute: scenpathattr, colors: ['#ffffff', '#2eb938'], categories: cats })

                setKeyParams({ ...keyparams, updateSNA: false, importdata: true })
            })
        }
    }

    const RefreshRun_Attr = () => {
        let attrnum = GetObjectField(keyparams.selattrcategory, attrcats, "id", "AttrNum")
        let attrfield = "Attribute" + String(attrnum)
        let ctype = GetObjectField(keyparams.selattrcategory, attrcats, "id", "DataType")

        let [newcats, colors] = NetworkGraphSetup(selNetwork, keyparams.selrundatekey, attrfield, ctype)
        setSNAstate({ ...SNAstate, categoryattribute: attrfield, categoryattrtype: ctype, categories: newcats, colors: colors })
        setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
    }

    const ClickAIChat = () => {
        if (selAIModel && selBranch) {
            let chattxt = GetInputText("scb_textchatdiv")
            GenericBackendCall(selAIModel.ModelKey, schema, paths.ai.aiinterfacepost, user, config,
                { text: chattxt, branchkey: selBranch.BranchKey, msgtype: keyparams.msgtype, asofdate: keyparams.scenario_asofdate }, "scb_chatdiv", 'Complete').then(d => {
                    console.log(d)
                    if (d.length === 1) {
                        //setSelBranch(d)
                        setKeyParams({ ...keyparams, updateSNA: true, importdata: true })
                    }
                })
        } else {
            console.log("no branch selected")
        }
    };


    //--------------------------------------------------------

    const ClickCreatePath = () => {
        if (selAIModel && selScenario) {
            GenericBackendCall(selScenario.Key, schema, paths.scenario.scenariopathpost, user, config,
                { aimodelkey: selAIModel.ModelKey, pathname: keyparams.pathname }, "scb_divpathadd", "Created").then(_ => {
                    setKeyParams({ ...keyparams, refresh: 999 })
                })
        }
    }
    const refreshfn = (key: string) => {
        history("/scenario/manager/" + key)
        setKeyParams({ ...keyparams, refresh: 1000 })
    }

    const ClickCreateNewScenario = () => {
        GenericBackendCall("", schema, paths.scenario.scenariopost, user, config,
            {
                scenarioname: keyparams.scenarioname, workspacekey: keyparams.workspacekey, workspacename: keyparams.workspacename,
                asofdate: keyparams.chat_asofdate
            }, "scb_divupl", "Working...  (1/3)").then(d => {
                let scenariokey = d[0]
                setKeyParams({ ...keyparams, aimodelkey: d[1], scenariokey: scenariokey })
                GenericBackendCall("", schema, paths.sna.netmodelupload, user, config,
                    {
                        //workspacekey: keyparams.workspacekey, 
                        modeltype: "scenario", scenariokey: scenariokey, modelname: keyparams.scenarioname, grpfields: [], attrfields: []
                    }, "scb_divupl", "Saved model... (2/3)").then(n => {

                        //do I want to keep this sna/aimodel creation?
                        GenericBackendCall(n, schema, paths.sna.netmodelcreaterun, user, config, {
                            asofdate: keyparams.scenario_asofdate, scenariokey: scenariokey
                        }, "scb_divupl", "Complete!").then(aikey => {
                            setTimeout(() => { refreshfn(scenariokey) }, 1000)

                        })

                    })
            })
    }

    const ClickDelete = () => {
        if (selScenario) {
            //deleting the workspace only should work, right?
            GenericBackendCall(keyparams.workspacekey, schema, paths.generics.genericdelete, user, config,
                { field: 'WorkKey', model: "Workspace", workspacekey: keyparams.workspacekey }, "scb_divdel").then(d => {
                    setKeyParams({ ...keyparams, refresh: 999 })
                })
        }
    }
    const ClickDeleteSNA = () => {
        if (selNetwork && selScenario) {
            GenericBackendCall(selNetwork.ModelKey, schema, paths.generics.genericdelete, user, config,
                { field: 'ModelKey', model: "NetModel", workspacekey: keyparams.workspacekey }, "scb_divdel").then(d => {
                    setKeyParams({ ...keyparams, refresh: 999 })
                })
        }
    }
    const ClickDeleteEvent = () => {
        if (selEvent) {
            GenericBackendCall(selEvent.EventKey, schema, paths.generics.genericdelete, user, config,
                { field: 'EventKey', model: "ScenarioEvent", workspacekey: keyparams.workspacekey }, "scb_divdelevent").then(d => {
                    setKeyParams({ ...keyparams, refresh: 999 })
                })
        }
    }

    const ClickDeletePath = () => {
        if (selBranch && selScenario) {
            let pathkey = selBranch.CategoryModelKey
            GenericBackendCall(selBranch.BranchKey, schema, paths.generics.genericdelete, user, config,
                { field: 'BranchKey', model: "MessageBranch", workspacekey: keyparams.workspacekey }, "scb_divdelpath", "Deleting...").then(d => {
                    setKeyParams({ ...keyparams, refresh: 999 })
                })
            GenericBackendCall(pathkey, schema, paths.generics.genericdelete, user, config,
                { field: 'PathKey', model: "ScenarioPath", workspacekey: keyparams.workspacekey }, "scb_divdelpath").then(d => {
                    setKeyParams({ ...keyparams, refresh: 999 })
                })


        }
    }
    const ClickImportEmpSliceData = () => {
        if (selEvent) {
            GenericBackendCall("", schema, paths.generics.genericdataview, user, config, {
                modelcats: ["EmployeeState", 'Department', "JobTitle"], workspacekey: keyparams.workspacekey,
                scenariopathkey: selPath?.PathKey, asofdate: selEvent.EventDate, fldkeys: [], connmdlkey: "default"
            }, "").then(d => {
                console.log(d)
                //setPthData({Emp: SerializeDataFrame(d)})
            })
        }
    }

    const ClickFilterData = () => {
        RerunSNA()
    }

    const ClickEditData = () => {
        if (selPath) {
            GenericBackendCall("", schema, paths.scenario.scenariopathedit, user, config,
                { scenariopathkey: selPath.PathKey, empid: eventparams.empID, mgrid: eventparams.mgrID, asofdate: keyparams.chat_asofdate, type: eventparams.edittype }, "scb_divedit").then(_ => {
                    setKeyParams({ ...keyparams, importdata: true })
                })
        }
    }


    //-----------------
    const View_ChartDetail = () => {
        return (
            <div>
                <div style={{ fontSize: "20px" }}>Filter SNA Chart</div>
                <MdlDropdown onfield={"catuniques"} />
                {modelparams.catuniques !== "" ?
                    <div>
                        <select className='ndt_dropdown' onChange={(e: ChangeEvent<HTMLSelectElement>) => { setModelParams({ ...modelparams, fieldunique: e.target.selectedOptions[0].attributes[0].value }) }}>
                            <option value={""}>Select Field</option>
                            {GetObjectValue(fldData, modelparams.catuniques).map((fld: FieldMap, i: number) => {
                                return (<option key={i} value={fld.Key}>{fld.FieldName}</option>)
                            })}
                        </select>
                    </div> : <div></div>}

                {modelparams.fieldunique !== "" ? <div>
                    <select className='ndt_dropdown' onChange={(e: ChangeEvent<HTMLSelectElement>) => { ChangeUniqueArray(e.target.selectedOptions[0].attributes[0].value) }}>
                        <option value="">Select Value</option>
                        {modelparams.uniquedata.map((val: any, i: number) => {
                            return (<option key={i} value={val.Value}>{val.Value}</option>)
                        })}
                    </select>
                </div>
                    : <div></div>}

                {modelparams.uniquevals.length > 0 ?
                    <div>
                        <button className="ndt_btn1" onClick={_ => { ClickFilterData() }}>Filter by these values</button>
                        <div style={{ marginTop: '20px' }}>
                            {modelparams.uniquevals.map((val: string, j: number) => {
                                return (<div key={j} className='scb_uniqueval'>{val}</div>)
                            })}
                        </div> </div> : <div></div>}
                <button className="ndt_btn1" onClick={_ => { setModelParams({ ...modelparams, fieldunique: "", uniquevals: [] }); setKeyParams({ ...keyparams, updateSNA: true }) }}>Unfilter</button>

                <div>
                    <div>Select Label Field</div>

                    <div>
                        <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => { setSNAstate({ ...SNAstate, labelfield: e.target.selectedOptions[0].attributes[0].value }) }}>
                            <option value="">Select Field</option>
                            <option value="Attribute2">Job Title</option>
                            <option value="Attribute3">Department</option>
                            <option value="">None</option>

                        </select>
                        <div>
                            <div>Label Style:</div>
                            <button className='ndt_btn1' style={SNAstate.labelappend ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                onClick={_ => { setSNAstate({ ...SNAstate, labelappend: true }) }}>Append</button>
                            <button className='ndt_btn1' style={!SNAstate.labelappend ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                onClick={_ => { setSNAstate({ ...SNAstate, labelappend: false }) }}>Replace</button>
                        </div>
                    </div>
                </div>

                {/* <div style={{ marginTop: "20px", fontSize: '18px', letterSpacing: ".05em" }}>Chart Legend</div>

                <div id="" style={{ overflowY: "scroll", height: "200px" }}>

                    {SNAstate.categories.map((c: string, i: number) => {
                        return (<div key={i} style={{ display: "flex", cursor: "pointer", width: '120px' }} onClick={_ => { setSNAstate({ ...SNAstate, selcategory: c }); setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 }) }}>
                            <div style={{ height: '15px', width: '15px', marginTop: "5px", marginRight: "5px", background: SNAstate.colors[i] }}></div>

                            <div>{StringTrim(c, 34)}</div>
                        </div>)
                    })}
                </div> */}

            </div>
        )
    }

    const MdlDropdown = (props: any) => {
        let mdls = ['EmployeeState', 'Department', 'JobTitle']
        let onfield = props.onfield
        return (
            <select className='ndt_dropdown' value={GetObjectValue(modelparams, onfield)}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => { setModelParams({ ...modelparams, [onfield]: e.target.selectedOptions[0].attributes[0].value }) }}>
                <option value="">Select Table</option>
                {mdls.map((m: string, i: number) => {
                    return (<option value={m} key={i} >{m}</option>)
                })}
            </select>
        )
    }

    const View_Data = () => {

        return (<div>
            <div>
                <MdlDropdown onfield="fieldcategory" />
            </div>
            <div>
                <button className='ndt_btn1' style={modelparams.showdatatype === "path" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setModelParams({ ...modelparams, showdatatype: "path" }) }}>Path-Specific Data</button>
                <button className='ndt_btn1' style={modelparams.showdatatype === "base" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setModelParams({ ...modelparams, showdatatype: "base" }) }}>Base Data</button>
                {eventparams.isselected ?
                    <button className='ndt_btn3' style={{ marginLeft: "20px", fontSize: "16px", height: "30px" }} onClick={_ => { setKeyParams({ ...keyparams, isselempvisible: true }) }}>Edit Employee</button>
                    : <div></div>}
            </div>

            {keyparams.datatrigger ?
                <div>

                    <div className='scb_emphdr'>
                        {modelparams.catflds.map((f: FieldMap, j: number) => {
                            return (<div key={j} className='scb_empsubitm'>{f.FieldName}</div>)
                        })}
                    </div>
                    <div style={{ height: "750px", width: "650px", overflow: "scroll" }}>
                        {modelparams.seldata.map((d: any, i: number) => {

                            return (<div key={i} className='scb_empitm' onClick={_ => {
                                if (modelparams.fieldcategory === "EmployeeState") {
                                    let selID = GetObjectField(d.EmpID, selNetworkRunDate.nodes, "Attribute1", "Key")
                                    setSNAstate({ ...SNAstate, selNodeid: selID });
                                    setEventParams({ ...eventparams, empID: d.EmpID, empName: d.Name, isselected: true });
                                }
                            }}
                                style={d.EmpID === eventparams.empID ? clickstyle.itmSelected : clickstyle.itmUnselected}
                                onMouseOver={_ => { }}
                            >
                                {modelparams.catflds.map((f: FieldMap, j: number) => {
                                    let val = GetObjectValue(d, f.AttrName)
                                    return (<div key={j} className='scb_empsubitm'
                                        onClick={_ => { setModelParams({ ...modelparams, selvalue: val }) }}>
                                        <div>{StringTrim(DateString(val), 35)}</div>
                                    </div>)
                                })}
                            </div>
                            )

                        })}
                    </div>

                </div> : <div></div>}
        </div>)
    }


    const View_Paths = () => {
        return (<div >
            <div style={{ fontSize: "16px", width: "530px" }}>
                {selAIModel ?
                    <div style={{ marginLeft: "10px", marginRight: "10px", display: "flex" }}>
                        <div style={{ fontSize: "19px", marginLeft: "10px", marginTop: '2px', marginRight: "10px" }}>Paths:</div>
                        <div style={{ display: "flex", minWidth: "500px" }}>

                            {selScenario ?
                                <GenericDropdown
                                    data={selScenario.paths}
                                    keycol="PathKey"
                                    namecol="PathName"
                                    className="ndt_dropdown"
                                    promptstring="Select Path"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, selScenario.paths, keyparams, setKeyParams, "pathkey", "PathKey", "_") }}
                                    includeDefault={true}
                                    defaultstring="New Path"
                                    value={keyparams.pathkey}
                                />
                                : <div></div>}

                            {keyparams.branchkey !== "" ?
                                <button className='ndt_btn2' onClick={_ => { setKeyParams({ ...keyparams, isdeletepathvisible: true }) }}>Delete</button>
                                : <div></div>}
                        </div>
                    </div> : <div></div>}
                {selPath ?
                    <div style={{ marginTop: '5px' }}>
                        <div>
                            <GenericDropdown
                                data={selPath.events}
                                keycol="EventKey"
                                namecol="EventName"
                                change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, selPath.events, setSelEvent, "", null)}
                                promptstring="Select from Events"
                                includeDefault={true}
                                defaultstring="New Event"
                                value={selEvent}
                                className="ndt_dropdown"
                            />
                            {selEvent ?
                                <button style={{ marginRight: "5px" }} className='ndt_btn2' onClick={_ => { setKeyParams({ ...keyparams, isdeleteeventvisible: true }) }}>Delete</button>
                                : <div></div>}
                        </div>

                        {selEvent ?
                            <div>
                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                    {selBranch?.messages.map((aim: AIMessage, k: number) => {
                                        return (<div key={k}>
                                            <div>{aim.UserPrompt}</div>
                                            <div>{StringTrim(aim.ResponseText, 200)}</div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            : <div>
                                <div style={{ fontSize: "20px" }}>Create New Event</div>
                                <div>Example Prompts:</div>
                                <div style={{ height: "150px", overflowY: "scroll" }}>
                                    {textoptions.map((txt: string, i: number) => {
                                        return <div key={i} className='scb_txtoptitm' onClick={_ => {

                                            SetTextAreaText("scb_textchatdiv", txt)
                                            // let elem = document.getElementById("scb_textchatdiv")
                                            // if (elem) {
                                            //     elem.innerHTML = txt
                                            // }
                                        }}>{StringTrim(txt, 200)}</div>
                                    })}
                                </div>
                                <div>
                                    <div>Chat Prompt to the AI:</div>
                                    <textarea id="scb_textchatdiv" style={{ width: "530px", height: '50px' }}
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setKeyParams({ ...keyparams, chattext: e.target.value }) }} />
                                </div>
                                <div>As of Date:</div>
                                <div className='datepick_class' style={{ width: "165px" }}>
                                    <DatePicker value={keyparams.scenario_asofdate} onChange={(d: any) => { ChangeUserDate(d, "scenario_asofdate") }} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <button className='ndt_btn1' onClick={_ => { ClickAIChat() }}>Create Event</button>
                                    <div id="scb_chatdiv" style={{ margin: "3px" }}></div>
                                </div>
                            </div>}
                    </div>
                    : <div></div>}

            </div>

        </div>)
    }


    NetworkGraph2D(selNetworkRunDate, SNAstate, setSNAstate, "#snagraphsx", [800, 750], keyparams.refresh)

    const SelectMain_FullScenario = () => {
        return (
            <div>
                {selScenario ?
                    <div>
                        {/* <GenericDropdown
                            data={networks}
                            change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, networks, setSelNetwork, "", null)}
                            keycol="ModelKey"
                            namecol="ModelName"
                            className="ndt_dropdown"
                            promptstring="Select or Create Network"
                            includeDefault={true}
                            defaultstring="Create New"
                        /> */}
                        {selNetwork ?
                            <div>
                                {/* <GenericDropdown
                                    data={selNetwork.rundates}
                                    change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, selNetwork.rundates, keyparams, setKeyParams, "selrundatekey", "DateKey", "")}
                                    keycol="DateKey"
                                    namecol="AsofDate"
                                    className="ndt_dropdown"
                                    promptstring="Select Run Date"
                                    value={keyparams.selrundatekey}
                                /> */}


                                {/* <button className='ndt_btn2' onClick={_ => { setKeyParams({ ...keyparams, isdeletesnavisible: true }) }}>Delete</button> */}
                                {selPath ?
                                    <div>
                                        <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, updateSNA: true }) }}>Refresh Graph</button>
                                    </div>
                                    : <div></div>}
                            </div> : <div>
                            </div>}
                    </div>
                    : <div></div>}
            </div>
        )
    }

    const ChangeUserDate = (d: any, onparam: string) => {
        if (selScenario) {
            if (d > selScenario.SnapshotDate) {
                setKeyParams({ ...keyparams, [onparam]: d })
            }
        } else if (onparam === "scenario_asofdate") {
            setKeyParams({ ...keyparams, [onparam]: d })
        }

    }
    const ChangeUniqueArray = (val: string) => {
        let newarry = AddtoArray(modelparams.uniquevals, val, "", true, true)
        if (newarry.length <= 5) {
            setModelParams({ ...modelparams, uniquevals: newarry })
        }

    }
    const View_Skills = () => {
        return (<div>
            <div style={{ fontSize: "18px" }}>Skills</div>
            <GenericDropdown
                data={jdmodels}
                keycol="ModelKey"
                namecol="ModelName"
                promptstring="Select from Models"
                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, jdmodels, modelparams, setModelParams, "descmdlkey", "Key", "") }}
                className="ndt_dropdown"
            />
        </div>)
    }

    return (<div className="ndt_canvas">
        <div className="ndt_title3 ndt_header ndt_gridbox" style={{ marginBottom: "10px", display: "flex" }}>
            Scenario Builder
            <div style={{ marginLeft: "20px", fontSize: "20px", marginTop: "7px" }}>{selScenario?.Name}</div></div>
        <div style={{ display: "flex" }}>
            <StyleMainSelect base={<GenericDropdown
                data={scenarios}
                change={(e: ChangeEvent<HTMLSelectElement>) => {
                    ChangeSelectorbyIndex(e, scenarios, setSelScenario, "", ScenarioInit[0])
                }}
                keycol="Key"
                namecol="Name"
                className="ndt_dropdown"
                promptstring={"Select Scenario"}
                includeDefault={true}
                defaultstring="Create New Scenario"
                divID="scb_ddownmain"
                value={selScenario}
            />}
                delete={
                    <button className='ndt_btn2' style={{ marginLeft: "4px" }} onClick={_ => { setKeyParams({ ...keyparams, isdeletevisible: true }) }}>Delete</button>
                }
                deleteCondition={selScenario}
                highlight={highlightParams.select}
            />

            <div>
                <StyleMainInput
                    base={
                        <View_Paths />
                    }
                    highlight={highlightParams.input}
                />
            </div>



        </div>

        <div id="" style={{ display: "flex" }}>

            <div className="ndt_gridbox">
                <SelectMain_FullScenario />
                <div id="snagraphsx" style={{ backgroundColor: "white", height: '750px', width: '800px' }}></div>
            </div>

            {selScenario && selPath ?
                <div className='ndt_gridbox' style={{ marginLeft: "10px", height: "800px", width: "700px" }}>
                    <div>
                        <div>
                            <div style={{ display: "flex" }}>
                                <button className="ndt_tab1" style={keyparams.view === "chartdetail" ? clickstyle.tabSelected : clickstyle.tabUnselected} onClick={_ => { setKeyParams({ ...keyparams, view: "chartdetail" }) }}>Chart Detail</button>
                                <button className="ndt_tab1" style={keyparams.view === "data" ? clickstyle.tabSelected : clickstyle.tabUnselected} onClick={_ => { setKeyParams({ ...keyparams, view: "data" }) }}>Data</button>
                                <button className="ndt_tab1" style={keyparams.view === "skills" ? clickstyle.tabSelected : clickstyle.tabUnselected} onClick={_ => { setKeyParams({ ...keyparams, view: "skills" }) }}>Job Skills</button>

                            </div>



                            <div >
                                {(() => {
                                    switch (keyparams.view) {
                                        case "chartdetail": return <div className="ndt_gridbox wlk_stepbox"><View_ChartDetail /></div>
                                        case "skills": return <div className="ndt_gridbox wlk_stepbox">
                                            <View_Skills />
                                        </div>
                                        case "data": return <div className="ndt_gridbox wlk_stepbox"><View_Data /></div>
                                        default: return <div>Select a view</div>;
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                </div> :
                <div>
                    {selScenario ?
                        <div style={{ marginTop: "200px", marginLeft: "30px" }}>A Scenario Path must be selected from the sidebar</div>
                        : <div></div>}</div>}

        </div>

        <Modal show={keyparams.isselempvisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Make Changes to Employee {eventparams.empID}?</div>
                    {edittypes.map((t: string) => {
                        return (<button key={t} className='ndt_btn1'
                            style={t === eventparams.edittype ? clickstyle.btnSelected : clickstyle.btnUnselected}
                            onClick={_ => { setEventParams({ ...eventparams, edittype: t }) }}>{t}</button>)
                    })}
                    <div style={{ marginLeft: "5px", width: '165px' }} className='datepick_class'>
                        <DatePicker value={keyparams.chat_asofdate} onChange={(d: any) => { ChangeUserDate(d, "chat_asofdate") }} />
                    </div>

                    <div>
                        {(() => {
                            switch (eventparams.edittype) {
                                case "Move": return <div>Move to under Manager:
                                    <GenericDropdown
                                        data={pthData.EmployeeState}
                                        keycol="EmpID"
                                        namecol="Name"
                                        promptstring="Select Manager"
                                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, pthData.EmployeeState, eventparams, setEventParams, "mgrID", "EmpID") }}
                                        includeDefault={false}
                                    />
                                </div>
                                case "Duplicate": return <div></div>
                                case "Terminate": return <div></div>
                                default: return <div>Select an option</div>;
                            }
                        })()}
                    </div>
                    {eventparams.edittype !== "" ?
                        <button className='ndt_btn1' onClick={_ => { ClickEditData() }}>Change</button> : <div></div>}
                    <div id="scb_divedit"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => { setKeyParams({ ...keyparams, isselempvisible: false, refresh: keyparams.refresh + 1 }); setEventParams({ ...eventparams, empID: '', empName: '' }) }}>Close</button>
            </div>
        </Modal >


        <Modal show={keyparams.iscreatenewvisible}>
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Create New Scenario</div>
                <div style={{ padding: "10px" }}>
                    <div style={{ fontSize: "17px" }}>Scenario Name</div>
                    <input type="text" value={keyparams.scenarioname} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, scenarioname: e.target.value }) }} />

                    {/* <div style={{ fontSize: "17px" }}>Workspace Name (use scenario name if blank)</div>
                    <input type="text" value={keyparams.workspacename} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, workspacename: e.target.value }) }} /> */}

                    <div style={{ fontSize: "17px" }}> Scenario Beginning Date</div>
                    <div style={{ marginLeft: "5px", width: '165px' }} className='datepick_class'>
                        <DatePicker value={keyparams.chat_asofdate} onChange={(d: any) => { ChangeUserDate(d, "chat_asofdate") }} />
                    </div>

                    <div style={{ marginTop: "10px" }}>
                        <button className="ndt_btn1" style={{ width: "70%", minWidth: "300px", overflow: "hidden" }} onClick={_ => ClickCreateNewScenario()}> Create New</button>
                        <div id="scb_divupl"></div>
                    </div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, iscreatenewvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>


        <Modal show={keyparams.isdeletevisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Are you sure you want to delete this scenario?</div>
                    <button className="ndt_btn1" style={{ width: "70%", minWidth: "300px", overflow: "hidden" }} onClick={_ => ClickDelete()}> Delete</button>
                    <div id="scb_divdel"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdeletesnavisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Are you sure you want to delete this Network model?</div>
                    <button className="ndt_btn2" onClick={_ => ClickDeleteSNA()}> Delete</button>
                    <div id="scb_divdel"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletesnavisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdeletepathvisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Delete path {keyparams.pathname}?</div>
                    <button className="ndt_btn2" onClick={_ => ClickDeletePath()}> Delete</button>
                    <div id="scb_divdelpath"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletepathvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdeleteeventvisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Delete path {keyparams.pathname}?</div>
                    <button className="ndt_btn2" onClick={_ => ClickDeleteEvent()}> Delete</button>
                    <div id="scb_divdelevent"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeleteeventvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isaddpathvisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Add Path to this scenario with name:</div>
                    <input type="text" value={keyparams.pathname} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, pathname: e.target.value }) }} />

                    <button className="ndt_btn1" onClick={_ => ClickCreatePath()}> Create</button>
                    <div id="scb_divpathadd"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isaddpathvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

    </div >)
};
export default ScenarioBuilder;