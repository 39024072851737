import IsInVector from "../IsInVector";

const MeasurementDropdown = (props: any) => {
    //ref: metricsmanager, datasetwalkthrough

    let change = props.change
    let className = props.className
    let includetypes: string[] = props.includetypes;
    let includemixed: boolean = props.includemixed;
    const value = props.value ? props.value : "";

    let measures = [
        { Value: "count", Text: "Count", Type: "any" },
        { Value: "mean", Text: "Mean", Type: "Numeric" },
        { Value: "sum", Text: "Sum", Type: "Numeric" },
        { Value: "min", Text: "Minimum", Type: "Numeric" },
        { Value: "max", Text: "Maximum", Type: "Numeric" },
        { Value: "median", Text: "Median", Type: "Numeric" },
        { Value: "std", Text: "Std Dev", Type: "Numeric" },

        { Value: "timesince-mean", Text: "Time Since - Mean", Type: "Date" },

    ]
    if (includemixed) {
        measures.push({ Value: "count/sum", Text: "Count/Sum", Type: "Numeric" })
        measures.push({ Value: "sum/count", Text: "Count/Sum", Type: "Numeric" })
    }

    return (
        <select className={className} value={value} onChange={change}>
            <option value="" data-name="">Select Measurement</option>
            {measures.map((m: any, i: number) => {
                if (IsInVector(includetypes, m.Type) || m.Type === "any") {
                    return (<option key={i} value={m.Value} data-value={m.Value}>{m.Text}</option>)
                }
            })}


        </select>
    )
    // <option value="count" data-name="count">Count</option>
    // <option value="average" data-name="average">Average</option>
    // <option value="sum" data-name="sum">Sum</option>
    // <option value="min" data-name="min">Minimum</option>
    // <option value="max" data-name="max" >Maximum</option>
    // <option value="median" data-name="median" >Median</option>

};
export default MeasurementDropdown;