
const ConvertDateToObject = (date1: Date) => {

    return {
        'day': date1.getDate(),
        'month': date1.getMonth() + 1,
        'year': date1.getFullYear()
    }

};
export default ConvertDateToObject;