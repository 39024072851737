import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export const tablegroupsDataSlice = createSlice({
    name: 'table_action',
    initialState: {},
    reducers: {
        tablegroups: (_, { payload }: PayloadAction<any>) => {
            return {
                tables: payload
            }
        },
        tablegroups_delete: (state, _: PayloadAction<{}>) => {
            return {

            }
        }
    }
});


//single workspace
export const {
    tablegroups: tablegroupsActionCreator,
    tablegroups_delete: tablegroupsdeleteActionCreator,
} = tablegroupsDataSlice.actions;
