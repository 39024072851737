import * as d3 from 'd3';
import DateRemoveTimestamp from '../../functions/Date Functions/DateRemoveTimestamp';
import DateString from '../../functions/Date Functions/DateString';
import FormatLabel from '../newfunctions/FormatLabel';


const LineChartStacked1 = (svg, data, xcol, ycols, x, yscales, groupcol, groups, colorScales, attrs, useopacity, 
    clr, fmt, rnd, wait_period, includeZeros) => {
    //ref: chartsample   

    let radius = Math.max(Math.min(3,Math.round(10/Math.pow(data.length*100,.5))/100),3)
    let linewidth = Math.max(Math.min(3,Math.round(3/Math.pow(data.length*100,.5))/100),1)


    //both line fns
    var lines = ycols.map((yc,t) => {
        let prevval = 0
        return d3.line()
            .x(function(d){return x(DateString(DateRemoveTimestamp(d[xcol]))) + x.bandwidth()/2})
            .y(function(d,i){
                let val = GetYVal(d, yc, t, prevval)
                prevval = val
                return val
            })     
    });

    
    //line stacked
    let grpdata = function(data,grp){
        let gdata = [];
        data.forEach(d=>{
            if (d[groupcol]===grp){
                gdata.push(d)
            }
        })
        return(gdata)
    }

    svg.data(data).enter()

    function GetYVal(d, yc, t, prev){
            let zz = +d[yc]
            if (!isNaN(zz)){
                return yscales[t](zz)
            } else {
                return prev
            }

    }

    ycols.forEach((yc,t)=>{
        groups.forEach((grp,_) => {
            svg.append("path")
                .attr("class","line")
                .attr("fill","none")
                .attr("stroke-width",linewidth)
                .attr("stroke", function(_) { if (groupcol){
                    if (t>0){
                        return attrs.secondarylinecolor
                    } else {
                        return attrs.linecolor
                    }
                }  })       
               .attr("d",lines[t](grpdata(data,grp)))

            let prevval = 0;
            svg.selectAll("rect1")
                .data(data)
                .enter().append("circle")
                .attr("class", "bar")
                .attr("cx", function(d) { return  x(DateString(DateRemoveTimestamp(d[xcol]))) + x.bandwidth()/2; })
                .style("fill",d=>{ return colorScales[t](+d[yc])})
                .attr("width",10)
                .attr("height", function(_) { return 10; })
                 .attr("cy", function(d) { 
                    let val = GetYVal(d, yc, t, prevval)
                    prevval = val
                    return val
                })
                 .attr("r",radius)        
        })
    })
        
        let ycol = ycols[0]
        let textN = Math.round(data.length/30)

        //text
        svg.append("g").selectAll("text")
				.data(data)
				.enter().append("text")
				.attr("dy","1.3em")
				.attr("text-anchor","start")
				.attr("fill-opacity",0) //start blank
				.attr("x",function(d) { return x(DateString(DateRemoveTimestamp(d[xcol]))) + x.bandwidth() / 2 ; })
				.attr("y", function(d) { 
								if (!isNaN(+d[ycol])){
									return yscales[0](+d[ycol]) - x.bandwidth()/2; 
								} else{
									return 0
								}
				})
				.attr("fill",clr)
				.text(function(d,i) { 
					if (i/textN === Math.round(i/textN) && (+d[ycol]!==0 || includeZeros)){
						return FormatLabel(d[ycol],{format:fmt, rounding:rnd}); 
					} else {
						return ""
					}
				})
				//.call(Textwrap,width/xgroups.length-5,.2);

			//txt transition
			svg.selectAll("text")
				.transition()
				.duration(500)
				.attr("fill-opacity",1)
				.attr("text-anchor","middle")
				.style("opacity",useopacity)
				.attr("y", function(d) { 
					if (!isNaN(+d[ycol])){
						return yscales[0](d[ycol]) - 30; 
					} else {
						return 0
					}
				})
				.attr("fill",clr)
				.style("font-size",'10px')
				.delay(function(_,i) { return (i*wait_period+200)});


};
export default LineChartStacked1;