import CheckIsDate from "./CheckIsDate";

const DateString = (date: Date | string, format = "") => {
    //ref: format - createaxes.js,


    if (CheckIsDate(date)) {
        let newdate = new Date(date)
        try {
            let yr = newdate.getFullYear()
            let mo = (newdate.getMonth() + 1).toString().padStart(2, "0")
            let dy = newdate.getDate().toString().padStart(2, "0")
            if (format === "") {
                return (String(mo + "/" + dy + "/" + yr))
            } else if (format === "%Y-%m-%d") {
                return `${yr}-${mo}-${dy}`
            } else {
                return String(newdate)
            }

        }
        catch (err) {
            return (String(date))
        }
    } else {
        return (String(date))
    }

};
export default DateString;