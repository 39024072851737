import axios from 'axios';


const EmpRowImport = async (data: any, schema: string, config: any, dispatcher: any, store: boolean) => {

	let res: any;

	let dataArray = new FormData();
	dataArray.append("schemakey", schema);
	dataArray.append("keystring1", data['keystring1']);
	dataArray.append("field1", data['field1']);
	dataArray.append("keystring2", data['keystring2']);
	dataArray.append("field2", data['field2']);

	try {
		res = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/api/employee/emprow`, dataArray, config)
		data = res.data
	}
	catch (err) {
		console.log(err)
	}
	return (data);

};

export default EmpRowImport;