const AddRemovefromArray = (arry: any[], value: string) => {
    //ref: metricsmanager
    let newarry: any[] = Array.from(arry)
    if (!arry.includes(value)) {
        newarry.push(value);
    } else {
        const index = arry.indexOf(value);
        if (index !== -1) {
            newarry.splice(index, 1);
        }
    }
    return newarry;

};
export default AddRemovefromArray;