import { useState, useContext, useEffect } from "react";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { Dashboard, DashboardNote, UserPermssion } from "../../../type";
import { StartupContext } from "../../../App";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import CreateUniqueUserMap from "../../../functions/User Functions/CreateUniqueUserMap";
import { useParams } from "react-router-dom";
import "./css/DashboardNotesPage.css"
import DateTimeString from "../../../functions/Date Functions/DateTimeString";


const DashboardNotesPage = () => {

    let { id, url } = useParams()


    let { config, schema, user, allusers, paths } = useContext(StartupContext)
    interface paramtype {
        addnote: boolean; newnote: string; refresh: boolean; selnoteid: number;
    }

    let [notes, setNotes] = useState<any[]>([])
    let [params, setParams] = useState<paramtype>({ addnote: false, newnote: "", refresh: true, selnoteid: -1 })
    let [userLookup, setUserLookup] = useState<any[]>([])
    let [userprm, setUserPrm] = useState<UserPermssion | null>(null)

    useEffect(() => {
        if (id) {
            GenericBackendCall(id, schema, paths.permissions.permview, user, config, { modelcategory: "Dashboard" }).then(d => {
                if (d.length === 1) {
                    setUserPrm({ ...d[0], LastViewedDateTime: new Date(d[0].LastViewedDateTime) })
                };
            })
        }
    }, [])

    useEffect(() => {
        if (id && params.refresh) {
            ImportAndSetStateGeneric(setNotes, id, schema, paths.dashboard.dashnotesview, user, config).then(_ => {
                setParams({ ...params, refresh: false })
                CreateLine()
            })
        }
    }, [params.refresh])

    useEffect(() => {
        setUserLookup(CreateUniqueUserMap(notes, "User", allusers))
    }, [notes])

    const SubmitNewNote = () => {
        if (id) {
            GenericBackendCall(id, schema, paths.dashboard.dashnotespost, user, config, { note: params.newnote }, "dnp_divnew").then(_ => {
                setParams({ ...params, refresh: true })
                CreateLine()
            })
        }
    }

    const CreateLine = () => {

        document.getElementById("_x_newdiv")?.remove()

        const newDiv = document.createElement('div');
        newDiv.id = "_x_newdiv"
        newDiv.textContent = ''; //

        let fnd = notes.length
        let targetDiv = document.getElementById("dnprow_" + fnd)
        if (targetDiv && targetDiv.parentNode) {
            targetDiv.parentNode.insertBefore(newDiv, targetDiv.nextSibling);
        }

        console.log(newDiv)
    }

    const ClickNote = (note: DashboardNote, isownnote: boolean) => {
        if (isownnote) {
            console.log('click')
            setParams({ ...params, selnoteid: note.id })
        }
    }

    return (<div className="ndt_canvas">
        <div className="ndt_title2">Notes</div>
        <div>
            <div id="dnp_notedata">
                {notes.map((note: DashboardNote, i: number) => {
                    let useclass = "dnp_noteitm dnp_otheruser"
                    let isOwnNote = false;
                    let usestyle = {}
                    if (user.email === userLookup[Number(note.User)]) {
                        useclass = "dnp_noteitm dnp_curruser"
                        isOwnNote = true;
                    }
                    if (note.id === params.selnoteid) {
                        usestyle = { backgroundColor: "rgb(177, 213, 248)" }
                    }
                    return (<div key={i} id={"dnprow_" + i} className="dnp_noterow" onClick={_ => ClickNote(note, isOwnNote)}>
                        <div className={useclass} style={usestyle} >
                            <div style={{ height: "35px", width: "100%" }}>
                                <div style={{ float: "left", maxWidth: "400px", overflow: "hidden" }}>{DateTimeString(note.Timestamp)}</div>
                                <div style={{ float: "right", maxWidth: "400px", overflow: "hidden" }}>{userLookup[Number(note.User)]}</div>
                            </div>
                            <div>{note.Note}</div>
                        </div>
                    </div>)
                })}
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
                <textarea style={{ marginLeft: "15px", width: "500px" }} onChange={e => { setParams({ ...params, newnote: e.target.value }) }}>
                </textarea>
                <button className="ndt_btn1" style={{ margin: "10px" }} onClick={_ => SubmitNewNote()}>Add Note</button>
                <div id="dnp_divnew"></div>
            </div>
        </div>
    </div>)
};
export default DashboardNotesPage;