import { QueryModel, QueryRunDate, QueryVisuals, FilterField, Metric, queryvisualstate, FieldMap, Benchmarkset, BenchModel, Schedule } from "../../../type";
import { useState, useEffect, useContext, MouseEvent, ChangeEvent } from 'react'
import { StartupContext } from "../../../App";
import DataTable from "../../../functions/Data Table/DataTable";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import DateString from "../../../functions/Date Functions/DateString";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import "./css/QueryModelViewRun.css"
import { Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import QueryModelRerun from "../../../data/Query/QueryModel_Rerun";
import { queryvisInit } from "../../../InitTypes";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import QueryFilterValues from "../../../functions/QueryFunctions/QueryFilterValues";
import CheckObjectKey from "../../../functions/Object Functions/CheckObjectKey";
import GetFieldName from "../../../functions/GetFieldName";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import ConvertChartTypetoShow from "../../../functions/QueryFunctions/ConvertChartTypetoShow";
import ChartConst from "../../../charts/ChartConst";
import CalculateStickyDate from "../../../functions/Date Functions/CalculateStickyDate";
import RunDateUpdate from "../../../functions/QueryFunctions/RunDateUpdate";
import QueryVisualsPrepare from "../../../functions/QueryFunctions/QueryVisualsPrepare";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";



const QueryModelViewRun = (props: any) => {

    let query: QueryModel = props.query
    let setQuery: any = props.setQuery
    let fields: FieldMap[] = props.fields
    let metrics: Metric[] = props.metrics
    let schedules: Schedule[] = props.schedules

    interface paramsinit {
        ismultiselect: boolean;
        compare: boolean;
        compare_nonzeros: boolean;
        nonzerobtn_style: any;
        selfield: string;
        isrunvisible: boolean;
        isdeletevisible: boolean;
        isassignrunvisible: boolean;
        issetprimaryvisible: boolean;
        asofdate: Date;
        enddate: Date;
        rundatetext: string;
        rundateAssign: string;
        refresh: number;
        selrundatekey: string;
        altrundatekey: string;
        selrundateidx: number;
        rerun: number;
        rdschedulenames: string[];
    }
    //using full query, not basic

    const { config, schema, user, paths } = useContext(StartupContext)
    //const context = useContext(StartupContext)


    let btn_defaultstyle = {}
    let btn_selectedstyle = { "backgroundColor": "rgb(100,30,60)" }

    let onStyle = { "backgroundColor": "rgb(106, 153, 177)", "boxShadow": "0px 0px 9px #a824d7, inset 0px 0px 8px #c8cb82", "border": "1px solid grey" }
    let offStyle = {}

    let [selqryvis, setSelqryvis] = useState<QueryVisuals | null>(null)
    let [qryvisall, setQryvisAll] = useState<QueryVisuals[]>([])

    let windowsize = window.innerWidth;

    let [keyparams, setKeyParams] = useState<paramsinit>({
        "ismultiselect": false,
        "compare": false, "compare_nonzeros": false, "nonzerobtn_style": btn_defaultstyle,
        "selfield": "", isrunvisible: false, isdeletevisible: false, isassignrunvisible: false,
        selrundatekey: '', selrundateidx: -1, altrundatekey: '', asofdate: new Date(), enddate: new Date(),
        rundatetext: "", rundateAssign: '', issetprimaryvisible: false,
        refresh: 0, rerun: 0, rdschedulenames: []
    })
    let [data, setData] = useState<{ "tabledata": any[][], "tabledataNZ": any[][] }>({ "tabledata": [], "tabledataNZ": [] })
    let [filterValueData, setFilterValueData] = useState<any>({})
    let [vis_states, setVis_States] = useState<queryvisualstate[]>(queryvisInit)
    //let [benchModels, setBenchModels] = useState<BenchModel[]>([])
    //let [benchmark, setBenchmark] = useState<Benchmarkset | null>(null)
    //const [metrics, setMetrics] = useState<Metric[]>([])

    let chartwidth: number = Math.max(windowsize - 750, 700)

    useEffect(() => {
        //ImportAndSetStateGeneric(setMetrics, "", schema, paths.query.metricview, user, config, {})
        ImportAndSetStateGeneric(setQryvisAll, query.ModelKey, schema, paths.query.queryvisualsview, user, config, {})
        setFilterValueData(QueryFilterValues(query, keyparams.selrundatekey))
    }, [])

    useEffect(() => {
        if (keyparams.refresh >= 1000) {
            window.location.reload()
        }
    }, [keyparams.refresh])

    useEffect(() => {
        RunDateUpdate(keyparams.selrundatekey, schema, query, setQuery, config)
        GenericBackendCall(keyparams.selrundatekey, schema, paths.benchmark.benchmarksetview, user, config, { querykeys: selqryvis })
    }, [keyparams.selrundatekey])

    useEffect(() => {
        if (keyparams.selrundateidx > 0) {
            if (keyparams.selrundatekey === query.rundates[keyparams.selrundateidx].DateKey) {
                let schedkeys = query.rundates[keyparams.selrundateidx].Schedules
                let schednames = schedkeys.map((sk: string) => {
                    return GetObjectField(sk, schedules, "ScheduleKey", "ScheduleName")
                })
                setKeyParams({ ...keyparams, rdschedulenames: schednames })
            }
        }
    }, [keyparams.selrundateidx])

    useEffect(() => {
        setKeyParams({ ...keyparams, rerun: keyparams.rerun + 1 })
    }, [query])

    useEffect(() => {
        if (selqryvis) {
            QueryVisualsPrepare(selqryvis, setSelqryvis)
        }
    }, [selqryvis?.VisKey])

    useEffect(() => {
        let flds = Object.keys(filterValueData).map((k: string) => { return (k) })
        let vals = Object.keys(filterValueData).map((k: string) => { return (filterValueData[k].Selected) })
        if (selqryvis) {
            let tmpstate = { ...vis_states[0], clickevent: "detail", filterfields: flds, filtervals: vals, show: ConvertChartTypetoShow(selqryvis.ChartType) }
            setVis_States([tmpstate])
        }
    }, [filterValueData, selqryvis?.VisKey])



    useEffect(() => {
        //filtervalues for selected run date
        setFilterValueData(QueryFilterValues(query, keyparams.selrundatekey))
    }, [keyparams.selrundatekey])

    useEffect(() => {
        if (query && selqryvis) {

            //copied from dashboardview - make its own function?
            let clrmodel = null
            if (selqryvis.ColorModelName !== null) {
                let colpromise = GenericBackendCall(selqryvis.ColorModelName, schema, paths.colorsets.colormodelview, user, config, "")
                colpromise.then(c => {
                    clrmodel = c[0]
                })
            }
            // CreateChart(query, selqryvis, clrmodel, keyparams.selrundatekey, fields, "#qmvr_graph1a",
            //     [900, 700], "qmvr_icon1a", vis_states, setVis_States, 0, context)
        }
    }, [keyparams.selrundatekey, selqryvis, vis_states]);

    useEffect(() => {
        let eod = CalculateStickyDate(keyparams.asofdate, query.StickyDateRef, query.Period, query.IPeriod, query.OffsetDays, query.IncludeToDate, false)
        setKeyParams({ ...keyparams, enddate: eod })
    }, [keyparams.asofdate])

    const ChangeQueryVis = (qv: QueryVisuals) => {
        setSelqryvis(QueryVisualsPrepare(qv, null))
    }

    const ChangeRunDate = (e: MouseEvent<HTMLDivElement>, i: number) => {
        if (keyparams.ismultiselect) {
            console.log("multi")
        }
        let key = e.currentTarget.attributes[0].value
        setKeyParams({ ...keyparams, selrundatekey: key, selrundateidx: i, refresh: keyparams.refresh + 1 })
    };

    const ChangeMultiSelect = () => {
        if (keyparams.ismultiselect) {
            //disable
            setKeyParams({ ...keyparams, ismultiselect: false })
        } else {
            //enable
            setKeyParams({ ...keyparams, ismultiselect: true })
        }
    };

    const ChangeNonZero = () => {
        if (keyparams.compare_nonzeros) {
            setKeyParams({ ...keyparams, "compare_nonzeros": false, "nonzerobtn_style": btn_defaultstyle })
        } else {
            setKeyParams({ ...keyparams, "compare_nonzeros": true, "nonzerobtn_style": btn_selectedstyle })
        }
    };

    const RunQuery = () => { //ad hoc run
        setKeyParams({ ...keyparams, refresh: 999 })
        QueryModelRerun(query.ModelKey, schema, "", keyparams.asofdate, keyparams.enddate, [""], keyparams.rundatetext, user, config, "qmvr_rdsuccess_div")
    };

    const DeleteRun = () => {
        setKeyParams({ ...keyparams, refresh: 999 })
        GenericBackendCall(keyparams.selrundatekey, schema, paths.query.rundatedelete, user, config, {}, "qmvr_deldiv")
    }

    const SetPrimary = () => {

        setKeyParams({ ...keyparams, refresh: 999 })
        GenericBackendCall(keyparams.selrundatekey, schema, paths.query.rundatesetprimary, user, config, {}, "qmvr_setprimarydiv")
    }

    const UpdateVisRun = () => {
        if (selqryvis) {
            GenericBackendCall(selqryvis.VisKey, schema, paths.generics.genericupdatevalue, user, config, { model: "QueryVisuals", field: "VisKey", changefield: "RunDateKey", newvalue: keyparams.rundateAssign }, "qmvr_updatediv").then(_ => {
                setKeyParams({ ...keyparams, refresh: 999 })
            })
        }
    }

    const ChangeFilter = (e: ChangeEvent<HTMLSelectElement>, fld: string) => {
        let selval = e.target.selectedOptions[0].attributes[1].value
        setFilterValueData({ ...filterValueData, [fld]: { ...filterValueData[fld], Selected: selval } })
    }

    return (
        <div id="qm_modelviewcanvas">
            <div id="qmvr_header">
            </div>
            <div id="qmvr_leftbox" className="ndt_gridbox" style={{ marginTop: '10px' }}>
                {query ?
                    <div>
                        <div id="qmvr_qryrdbox" style={{ minHeight: "650px" }}>
                            <div style={{ fontSize: "19px" }}>Query Runs</div>
                            <div className="ndt_innerbox">
                                <button className="ndt_btn3" style={{ marginLeft: "10px", fontSize: "15px", height: "28px" }} onClick={_ => setKeyParams({ ...keyparams, isrunvisible: true })}>Add New Run (ad hoc)</button>
                                <button className="ndt_btn1" id="qmvr_multibtn" onClick={_ => ChangeMultiSelect()} style={keyparams.ismultiselect ? btn_selectedstyle : btn_defaultstyle}>Enable Multiselect</button>

                                <div id="qmvr_existrunbox" style={{ overflowY: "scroll", height: "350px", width: "99%" }}>
                                    {query.rundates.map((qrun: QueryRunDate, i: number) => {
                                        let pstring = ""
                                        if (qrun.IsPrimary) { pstring = " - Primary" }
                                        return (<div key={i}
                                            data-value={qrun.DateKey}
                                            className="qmvr_qryrunsel"
                                            onClick={(e: MouseEvent<HTMLDivElement>) => ChangeRunDate(e, i)}
                                            style={keyparams.selrundatekey === qrun.DateKey || keyparams.altrundatekey === qrun.DateKey ? onStyle : offStyle}
                                        >
                                            {DateString(qrun.AsofDate)}{pstring}: {qrun.Notes.substring(0, 80)}
                                        </div>)
                                    })}
                                </div>
                            </div>
                            {keyparams.selrundatekey !== "" ?
                                <div className="ndt_innerbox" style={{ height: "200px" }}>
                                    <div style={{ marginLeft: "15px", fontSize: "19px" }}>
                                        Selected Run Options:
                                    </div>
                                    <div style={{ display: "flex", marginLeft: "10px", height: "50px", fontSize: "16px", minWidth: "400px" }}>
                                        <button className="ndt_btn2" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: true })}>Delete Run</button>
                                        <button className="ndt_btn1" onClick={_ => setKeyParams({ ...keyparams, issetprimaryvisible: true })}>Set Primary</button>
                                        {selqryvis ?
                                            <button className="ndt_btn1" onClick={_ => setKeyParams({ ...keyparams, isassignrunvisible: true })}>Assign Run to Visual Set</button>
                                            : <div></div>}
                                    </div>
                                    <div style={{ fontSize: "15px" }}>
                                        <div style={{ fontSize: "19px" }}>Schedules:</div>
                                        {keyparams.rdschedulenames.map((sn: string, i: number) => {
                                            return (<div key={i}>{sn}</div>)
                                        })}
                                    </div>
                                </div>
                                : <div></div>}
                        </div>
                        <div className="ndt_innerbox">
                            <div id="qmvr_detuploaddiv"></div>
                            Filters
                            {query.filterfields.map((flt: FilterField, i: number) => {
                                if (CheckObjectKey(filterValueData, flt.FieldName)) {
                                    return (
                                        <div key={i}>
                                            <GenericDropdown
                                                data={filterValueData[flt.FieldName].Values}
                                                keycol={""}
                                                promptstring={"Select " + GetFieldName(fields, flt.FieldName, "Key", "FieldName")}
                                                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeFilter(e, flt.FieldName) }}
                                            />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    : <div></div>}
            </div>
            <div className="ndt_gridbox" style={{ marginTop: '10px' }}>
                {keyparams.compare === false ?
                    <div id="qmvr_rightbox" style={{ height: "560px" }} >
                        {selqryvis ? <div style={{ width: "700px", paddingTop: "10px" }}>
                            <ChartConst query={query} setQuery={setQuery} svgid="ttisros" vis_state={vis_states}
                                setVis_State={setVis_States} qryvis={selqryvis} stateIdx={0} style={{ "backgroundColor": "white" }}
                                rundatekey={keyparams.selrundatekey}
                                rerun={keyparams.rerun}
                                metrics={metrics}
                                size={[chartwidth, 500]}
                            />
                        </div> : <div> No Visual Set Selected</div>}
                    </div>
                    : <div>
                        <button onClick={_ => ChangeNonZero()} style={keyparams.nonzerobtn_style}>Only show changes?</button>
                        <DataTable data={keyparams.compare_nonzeros ? data.tabledataNZ : data.tabledata} num={10} />
                    </div>}

                <div className="ndt_innerbox" style={{ overflowY: "scroll", width: "60%", height: "180px" }}>
                    <div style={{ fontSize: "20px" }}>Visual Sets</div>
                    <div id="qmvr_vissetbox">
                        {qryvisall.map((qv: QueryVisuals) => {
                            let qvdivstyle = {}
                            if (qv.VisKey === selqryvis?.VisKey) { qvdivstyle = { backgroundColor: "rgb(30,10,140)", boxShadow: "0px 0px 7px rgb(100,0,100)" } }
                            return (
                                <div key={qv.VisKey} style={qvdivstyle} className="qmvr_visitem" onClick={_ => ChangeQueryVis(qv)}>
                                    {qv.VisName} - {qv.RunDateKey}
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
            <Modal show={keyparams.isrunvisible}>
                <div className="ndt_modal">
                    <div style={{ fontSize: "22px" }}>Run Query</div>
                    <div style={{ margin: '10px' }}>
                        <div>Assign As Primary?</div>
                        <button className="ndt_btn1" onClick={_ => { let y = 1 }}>Set Primary</button>
                        <div id="gm_divrunprimary" style={{ height: "25px" }}></div>
                    </div>
                    <div className="gm_reldateOpt">
                        Run as of date:
                        <div className="datepick_class" style={{ width: "155px" }}>
                            <DatePicker value={keyparams.asofdate} onChange={(e: any) => setKeyParams({ ...keyparams, "asofdate": e })} />
                        </div>
                    </div>
                    <input type="text" value={keyparams.rundatetext} onChange={e => setKeyParams({ ...keyparams, rundatetext: e.target.value })} />
                    <button id="gm_rdrunbtn" onClick={_ => RunQuery()}>Run!</button>
                    <div id="qmvr_rdsuccess_div"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isrunvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal >

            <Modal show={keyparams.isdeletevisible}>
                <div className="ndt_modal">
                    <div>Delete this run? </div>
                    <button id="gm_rdrunbtn" onClick={_ => DeleteRun()}>Delete</button>
                    <div id="qmvr_deldiv"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            <Modal show={keyparams.issetprimaryvisible}>
                <div className="ndt_modal">
                    <div>Set this as the primary run? </div>
                    <button id="gm_rdrunbtn" onClick={_ => SetPrimary()}>Set</button>
                    <div id="qmvr_setprimarydiv"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issetprimaryvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>


            <Modal show={keyparams.isassignrunvisible}>
                <div className="ndt_modal">
                    <div style={{ padding: "10px" }}>
                        <div style={{ fontSize: "22px" }}>Assign run to visuals - {selqryvis?.VisName} </div>
                        <div style={{ width: "80%", height: "120px" }}>
                            <button onClick={_ => { setKeyParams({ ...keyparams, rundateAssign: "Primary" }) }}>Always use Primary?</button>
                            <button onClick={_ => { setKeyParams({ ...keyparams, rundateAssign: "_" }) }}>Use Run:</button>
                            <div style={{ padding: "5px" }}>
                                {keyparams.rundateAssign !== "Primary" ? <div>
                                    <GenericDropdown
                                        data={query.rundates}
                                        keycol={"DateKey"}
                                        namecol={"AsofDate"}
                                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, query.rundates, keyparams, setKeyParams, "rundateAssign", "DateKey") }}
                                        promptstring="Hardcode Run:"
                                    />
                                </div> : <div>Set to use Primary run</div>}
                            </div>
                        </div>
                        <button onClick={_ => { UpdateVisRun() }}>Update</button>
                        <div id="qmvr_updatediv"></div>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isassignrunvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>


        </div >
    )
};

export default QueryModelViewRun;
