import { useState, ChangeEvent, MouseEvent, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeDropdown from "./subfunctions/Employee_Dropdown";
import { Emp_State, Employee } from "../../../type";
import "./css/SingleEmployeePage.css";
import EmployeeCard from './subfunctions/EmployeeCard';
import EmpFieldTrend from './subfunctions/EmpFieldTrend';
import { empdeleteActionCreator } from '../../../reducers/employee_actions';
import EmployeeSingleEmp from '../../../data/Employee/EmployeeSingleEmp_Import';
import { StartupContext } from '../../../App';
//import GetFieldName from '../../../functions/GetFieldName';
import GenericsUniqueImport from '../../../data/Generics/GenericsUnique_Import';
import SerializeDataFrame from '../../../functions/Import/SerializeDataFrame';
import ObjectSort from '../../../functions/ObjectSort';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';

const SingleEmployeePage = () => {

    let { config, dispatcher, schema, paths, user } = useContext(StartupContext)
    let dispatcher1 = useDispatch() //dispatcher reading as string?
    let fieldData = useSelector((state: any) => state.fieldmapping);
    let [empData, setEmpData] = useState<Emp_State[]>([]);

    const [empSelect, setEmpSelect] = useState<string>();
    const [singleEmp, setSingleEmp] = useState<Employee | null>(null)
    const [fieldFocus, setFieldFocus] = useState(false);
    const [selectedField, setSelectedField] = useState("");
    const MapFields = ['Location', "DeptID", "Position", "ReportsToID", "JobCode"];

    useEffect(() => {
        let importdata = GenericsUniqueImport(["EmpID", "Name"], schema, fieldData, config, dispatcher);
        importdata.then(d => {
            let data = SerializeDataFrame(d, ["EmpID", "Name"])
            data = ObjectSort(data, "Name")
            setEmpData(data);
        })
    }, [])


    const EmpSelector = (e: ChangeEvent<HTMLSelectElement>) => {
        let eid = e.target.selectedOptions[0].attributes[1].value;
        let singleempPromise = GenericBackendCall('', schema, paths.employee.singleemp, user, config, { "empid": eid })

        singleempPromise.then(d => { console.log(d); if (d.length === 1) { setSingleEmp(d) } })
        setEmpSelect(eid);

    };

    const FieldSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.selectedOptions[0].attributes.length > 0) {
            let fld: string = e.target.selectedOptions[0].attributes[0].value
            setSelectedField(fld)
        }
    };

    const ClickedRadio = (event: MouseEvent<HTMLDivElement>, div_id: string) => {
        let simuldiv: HTMLElement | null = document.getElementById(div_id)
        if (event.currentTarget.style.background === "white") {
            //turn off
            if (event.currentTarget.id === "radio_field_sub") {
                setFieldFocus(false)
            }
            event.currentTarget.style.background = "black"
            if (simuldiv) { simuldiv.style.backgroundColor = "#999999" }
        } else {
            //turn on
            if (event.currentTarget.id === "radio_field_sub") {
                setFieldFocus(true)
            }
            event.currentTarget.style.background = "white"
            if (simuldiv) { simuldiv.style.backgroundColor = "white" }
        }
    }

    const DeleteEmps = () => {
        dispatcher1(empdeleteActionCreator([]))
    }
    const FilterFieldSelect = () => {
        return (
            <select id="dropdown_filter" onChange={(e: ChangeEvent<HTMLSelectElement>) => FieldSelect(e)} >
                <option key='1'  >
                    Fields
                </option>
                {
                    MapFields.map((d, i) => {
                        return (
                            <option key={"e_flt" + i} value={d}>
                                {d}
                            </option>
                        )
                    })
                }
            </select>
        )
    };
    let fieldselMemo = useMemo(() => FilterFieldSelect(), []) //prevent rerun of static dropdown

    return (
        <div id="empdatacontainer1">
            <div id="empimportbar">
                <div id="empimportbar_header">Data Options</div>
                <div id="empimportbar_container">

                    <div id="empselector" className="empimportelem">
                        <div className="empheader" >
                            <div style={{ 'fontSize': '16px', 'textAlign': 'left' }}> Imported Data: </div>
                            <div style={{ 'marginTop': "6px" }}>Employee Select</div>
                            <EmployeeDropdown
                                Employees={empData}
                                keyfield="EmpID"
                                textfield="Name"
                                classname="empsel_ddown"
                                change={(e: ChangeEvent<HTMLSelectElement>) => EmpSelector(e)}
                            />
                        </div>
                    </div>


                    <div id="fieldselector" className="empimportelem">
                        <div style={{ color: "white" }}> Field Focus </div>
                        {fieldselMemo}
                    </div>

                    <div className="borderplus" id="radio_field">
                        <div id="radio_field_sub" className="empradiobtn"
                            onClick={(e) => ClickedRadio(e, "fieldselector")}>
                        </div>
                    </div>

                    <div className="borderplus" id="radio_field2">
                        <div id="radio_field_sub2" className="empradiobtn"
                            onClick={(e) => ClickedRadio(e, "")}>
                        </div>
                    </div>
                </div>
            </div>

            <div className="empSpace">
                {empSelect ? <EmployeeCard Employee={singleEmp} /> : <div></div>}
                {fieldFocus ? <EmpFieldTrend empl={singleEmp} field={selectedField} /> : <div></div>}
            </div>
            <div>
                <button onClick={DeleteEmps}>Delete Emps</button>
            </div>
        </div>
    );
};


export default SingleEmployeePage;
