
const SerializeDataFrame = (data: any, usekey: string[] = []) => {
    let returndf: any[] = []

    let dlen: number = 0;

    Object.keys(data).map((k: string, i: number) => {
        if (i === 0) {
            dlen = data[k].length
        }
    })

    for (let i = 0; i < dlen; i++) {
        let datarow: any = {}
        Object.keys(data).forEach((k: string, p: number) => {
            if (usekey.length > 0) {
                datarow[usekey[p]] = data[k][i]
            } else {
                let keystr = k
                if (k.substring(k.length - 3, k.length) === "_id") {
                    keystr = k.substring(0, k.length - 3)
                }
                datarow[keystr] = data[k][i]
            }
        })
        returndf.push(datarow)
    }

    return (returndf)
};
export default SerializeDataFrame;