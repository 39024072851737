import * as d3 from 'd3';

import DateRemoveTimestamp from '../../functions/Date Functions/DateRemoveTimestamp';
import GetStackedValues from './GetStackedValues';
import DateString from '../../functions/Date Functions/DateString';
import FormatLabel from './FormatLabel';
import CheckRoundedEquivalency from '../../functions/Math Functions/CheckRoundedEquivalency';
import GetUniquesFromObjectAttribute from '../../functions/GetUniquesFromObjectAttribute';
import RoundValue from '../../functions/Math Functions/RoundValue';
import StringTrim from '../../functions/String Functions/StringTrim';

const CreateLabels = (svg, graphtype, data, xaxisfn, yaxisfn, xfield, yfield, xgroups, groupfield, barscount, 
        bar_n, formats, attributes, direction, labelsonoff, labeltype) => {
    
	let nvals = data.length
	let wait_period = 500/nvals

        let g = svg.append("g")

        let [valuefn, cumvaluefn] = GetStackedValues(data, xfield, yfield, xgroups, groupfield)        
        let groups = GetUniquesFromObjectAttribute(data,groupfield,true)

        let barwidth = xaxisfn.bandwidth()/barscount;

        let max = []
        let min = []
        let mean = []
        let usedmean = []
        let usedmin = []
        let usedmax = []

        groups.forEach((grp)=>{
                let gmean = RoundValue(d3.mean(data,function(d) {  if (d[groupfield]===grp){return +d[yfield]} ; }),4)
                let gmin = d3.min(data,function(d) { if (d[groupfield]===grp){return +d[yfield]} else {return mean}; })
                let gmax = d3.max(data,function(d) { if (d[groupfield]===grp){return +d[yfield]} else {return mean}; })
                max.push(gmax)
                min.push(gmin)
                mean.push(gmean)
                usedmean.push(false)
                usedmin.push(false)
                usedmax.push(false)
        })

        function ReturnXY(d,attr){
                if ((direction==="horizontal" && attr==="y")||(direction==="vertical" && attr==="x")){
                        let adj =0
                        let baradj = 0
                        if (direction==="horizontal"){adj = -10}
                        if (graphtype==="Bar"){baradj = barwidth*(bar_n)}
                        return (xaxisfn(DateString(DateRemoveTimestamp(d[xfield]),"%Y-%m-%d"))+ baradj + barwidth/2) + adj
                } else {
                        let adj = -10
                        if (direction==="vertical"){adj = 30}
                        if (graphtype==="Line"){
                                return yaxisfn(valuefn(d) )- adj; 
                        } else {
                                return yaxisfn(cumvaluefn(d) )- adj; 
                        }
                }
        }

        function checkhighlow(value,grpno){
                
                if (!usedmin[grpno]){
                        if (CheckRoundedEquivalency(value,min[grpno],4)){
                                usedmin[grpno] = true
                                return true
                        }
                }
                if (!usedmax[grpno]){
                        if (CheckRoundedEquivalency(value,max[grpno],4)){
                                usedmax[grpno] = true
                                return true
                        }
                }
                if (!usedmean[grpno]){
                        if (CheckRoundedEquivalency(value,mean[grpno],2)){
                                usedmean[grpno] = true
                                return true
                        }
                }
                return false
        }

        function CheckZero(fulltext, val){
                if (+val!==0){
                        return fulltext;
                } else {
                        return ""
                }
        }
        
        function GetLabel(d, i, metrno, grpno){
                let val2 = FormatLabel(d[yfield], formats[metrno])
                if (labeltype==="value"){
                        if (labelsonoff==="HighLow"){
                               if(checkhighlow(+d[yfield],grpno)){
                                        return val2
                               }
                        } else {
                                return CheckZero(val2, val2)
                        }
                } else if (labeltype==="vartext"){
                        return CheckZero(DateString(DateRemoveTimestamp(d[xfield]),"%Y-%m-%d") + " - " + String(val2), val2)
                } else if (labeltype==="grptext"){
                        return CheckZero(StringTrim(DateString(DateRemoveTimestamp(d[groupfield]),"%Y-%m-%d"),18,2) + " - " + String(val2), val2)
                }
                
        }

        function GetGroupNum(d){
                return groups.indexOf(d[groupfield])
        }

        let xlen = xaxisfn.domain().length


        let maxlen = 10
        data.forEach((d,i) => {
            let lbl = GetLabel(d, i, d['MetricNo'],GetGroupNum(d))
            if (lbl.length>maxlen){
                maxlen = lbl.length
            }
        })
        let fontZ = Math.max(Math.min(14,Math.round(1200/(xlen * maxlen))),5)
        let fz = String(fontZ) + "px"

        let text = g.selectAll("xx")
                        .data(data)
                        .enter().append("text")
                        .attr("dy","1.1em")
                        .attr("text-anchor","start")
                        .attr("font-weight","bold")
                        .attr("font-size", fz)
                        .attr("text-anchor","middle")
                        //.style("color","attributes.labelcolor")
                        .style("fill",attributes.labelcolor)
                        .attr("fill-opacity",0) //start blank
                        .attr("x",function(d) {return ReturnXY(d,"x") })
                        .attr("y", function(d) { return ReturnXY(d,"y")})
                        .text(function(d,i) {  return GetLabel(d, i, d['MetricNo'],GetGroupNum(d)) })
                        //.call(Textwrap,width/xgroups.length-5,.2);

                if (!attributes.reload){
                        
                        //txt transition
                        text.transition()
                                .duration(500)
                                .attr("fill-opacity",Math.round(attributes.opacity,0))
                                .attr("text-anchor","middle")
                                //.style("opacity",useopacity)
                                //.attr("y", function(d) { return yaxisfn(d[yfield]) - 30; })
                                .delay(function(_,i) { return (i*wait_period+200)});
                } else{
                        text.attr("fill-opacity",Math.round(attributes.opacity,0))
                }

};
export default CreateLabels