import { Employee, Emp_State } from "../../../../type";
import CompareObjects_SingleField from "../../../../functions/CompareObjects_SingleField";
import ReturnValfromType from "../../../../functions/ReturnValfromType";
import "./css/EmpFieldTrend.css"

const EmpFieldTrend = (props: any) => {

    let empl: Employee = props.empl
    let field: string = props.field;

    const CheckTrend = () => {
        let series1: any = [];
        let series2: any = [];
        empl.state.forEach((st: Emp_State, i: number) => {

            if (i !== 0) {
                let val = CompareObjects_SingleField(st, empl.state[i - 1], field)
                if (val.length > 0) {
                    series1.push([val[1], empl.state[i - 1].Date])
                    series2.push([val[0], st.Date])
                }
            }
        });
        if (series2.length > 0) {
            series1.push(series2[series1.length - 1]);
        } else {
            series1.push([ReturnValfromType(empl.state[0], field), empl.state[0].Date])
        }

        return (
            <div>
                Date History: Beginning...
                <div id="empfield_subbox">
                    {series1.map((s: any[], i: number) => {
                        return (
                            <div key={i + 'a'} className="fieldsub">
                                <div> {s[0]} </div>
                                <div> {s[1]} </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    };


    if (field !== "Fields" && empl.state.length > 0) {
        return (
            <div id="empfieldbox">
                <div style={{ 'fontSize': '18px' }}> Field:   {field}</div>
                <CheckTrend />
            </div>
        )
    } else {
        return (<div></div>)
    }
};
export default EmpFieldTrend;