//import { tblmappinginit } from "../../InitTypes";
import { QueryModel, FieldMap, queryparams, TableMap, QueryColor, QueryVisuals, DetailField, FilterField, ChartSeries, Key_Name, MetrModelChoice } from "../../type";
import GetFieldName from "../GetFieldName";
import ConvertBackendArryString from "../String Functions/ConvertBackendArryString";
import GetQueryRunDateKey from "./GetQueryRunDateKey";

const QueryModel_to_Params = async (qry: QueryModel, qryvis: QueryVisuals, params: queryparams, setparamfn: any, fields: FieldMap[], userview: boolean = false) => {

    let tblnames = qry.tablemap.map((tm: TableMap) => { return tm.TableName });
    let tblattrs = qry.tablemap.map((tm: TableMap) => { return tm.MappingStyle });
    let tblmap = Object.assign({}, ...tblnames.map((t: string, i: number) => ({ [t]: tblattrs[i] })));
    let colors = Object.assign({}, ...qryvis.colors.map((col: QueryColor, i: number) => ({ [i]: [col.Color, col.SecondaryColor] })));
    let detailflds = Object.assign({}, ...qry.detailfields.map((df: DetailField, i: number) => ({ [i]: df.FieldName })));
    let filterflds = Object.assign({}, ...qry.filterfields.map((ff: FilterField, i: number) => ({ [i]: ff.FieldName })));
    let metrics = ConvertBackendArryString(qry.MetricOrder)
    let modelchoices: Key_Name[][] = metrics.map((_) => { return [] })

    if (qry.metrmodelchoices) {
        qry.metrmodelchoices.forEach((mm: MetrModelChoice) => {
            modelchoices[mm.MetricNo].push({ Key: mm.ModelCategory, Name: mm.ModelNameKey })
        })
    }

    let default_fltflds = ConvertBackendArryString(String(qryvis.FilterFields))
    let default_fltvals = ConvertBackendArryString(String(qryvis.FilterValues))


    let chartseries: ChartSeries[] = []
    metrics.forEach((m: string) => {
        qryvis.chartseries.forEach((cs: ChartSeries) => {
            if (cs.MetricName === m) {
                chartseries.push(cs)
            }
        })
    });

    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((n: number) => { if (!colors[n]) { colors[n] = ["", ""] } })

    let groupfieldname = qry.GroupFieldName;
    let xfieldname = qry.XFieldName;
    let simplefiltervals = ConvertBackendArryString(String(qry.SimpleFilterValues))

    if (userview) {
        groupfieldname = GetFieldName(fields, qry.GroupFieldName, "Key", "FieldName")
        xfieldname = GetFieldName(fields, qry.XFieldName, "Key", "FieldName")
    }

    await setparamfn({
        ...params,
        'newparamset': true,
        "modelname": qry.ModelName,
        "metrics": metrics,

        "dateSpread": qry.DateSpread,
        "xfieldname": xfieldname,
        "groupfield": groupfieldname,
        'ruleModelKey': qry.RuleModelName,
        'filterModelKey': qry.FilterModelName,
        'includetodate': String(qry.IncludeToDate),
        'fieldBand': String(qry.FieldBand),
        'bandgroupname': qry.BandGroupName,
        'period': qry.Period,
        "nperiods": qry.NPeriods,
        "mappings": tblmap,
        "colors": colors,
        "detailfields": detailflds,
        "filterfields": filterflds,
        "rundatekey": GetQueryRunDateKey(qryvis, qry.rundates),
        "visualskey": qryvis.VisKey,
        "visualsname": qryvis.VisName,
        "dashboardkey": qryvis.DashboardName,
        'colorModelKey': qryvis.ColorModelName,
        "labelsonoff": qryvis.LabelsOnOff,
        "labelcolor": qryvis.LabelColor,
        "labeltype": qryvis.LabelType,
        "colorGradientType": qryvis.ColorGradientType,
        "showdefault": qryvis.ShowDefault,
        "chartseries": chartseries,
        "chartType": qryvis.ChartType,
        "notes": qry.Notes,
        "tablepaginate": qryvis.TablePaginate,
        "simplefilterfield": qry.SimpleFilterField,
        "simplefiltervalues": simplefiltervals,
        "defaultfiltervals": default_fltvals,
        'defaultfilterflds': default_fltflds,
        "metrmodelchoices": modelchoices,
    })
};
export default QueryModel_to_Params;