import { useState, useContext, useEffect, ChangeEvent, useMemo } from "react";
import { NetModelInit, JobDescriptionInit, JobTitleInit, NetworkRunDateInit } from "../../../InitTypes";
import NetworkGraph2D from "../../sna/networkgraph/NetworkGraph2D";
import { DescModel, FieldMap, JobDescription, JobSkill, JobTitle, Link, NetModel, SkillCategory, snaState, NetworkRunDate } from "../../../type";
import { StartupContext } from "../../../App";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import GetUniquesFromObjectAttribute from "../../../functions/GetUniquesFromObjectAttribute";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import FilterData from "../../../functions/FilterData";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import { Modal } from "react-bootstrap";
import ConvertBackendArryString from "../../../functions/String Functions/ConvertBackendArryString";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import NetworkGraphSetup from "../../sna/networkgraph/NetworkGraphSetup";
import JDSkillsToNetwork from "./subfunctions/JDSkillsToNetwork";
import ObjectSort from "../../../functions/ObjectSort";

import "./css/JobDescNetGraph.css"


const JobDescNetGraph = () => {
    //network graph uses a single, default run date key of "_"

    interface Category {
        Category: string;
        CategoryName: string;
        CategoryTerms: string[];
    }


    const [SNAstate, setSNAstate] = useState<snaState>({
        selNodeid: '',
        nodeColor: "#12ffff",
        linkColor: "#666666",
        secondaryNode: "",
        isHighlight: false,
        categoryattribute: '',
        categoryattrtype: "Text",
        categories: [],
        colors: [],
        selcategory: '',
        labelappend: true,
        labelfield: '',
        clickmode: '',
        value: { fstrength: -500, dmax: 300, dmin: 5 }
    })

    interface seldata {
        linkdata: Link[];
        jobdesc: JobDescription;
        jobtitle: JobTitle;
        categories: Category[];
        sourcejc: string;
        sourcetitle: string;
        targetjc: string;
        targettitle: string;
        category: string;
        selskills: JobSkill[];
        careerpaths: any[];
    }
    const { config, schema, user, paths } = useContext(StartupContext)
    const [keyparams, setKeyParams] = useState<any>({
        selmodelkey: '', refresh: 0, view: '', sensitivity: .1,
        nodeselected: false, iscatvisible: false
    })
    const [jobskills, setJobSkills] = useState<JobSkill[]>([])
    const [jdmodels, setJDModels] = useState<DescModel[]>([])
    const [selJDModel, setSelJDModel] = useState<DescModel | null>(null)
    const [categories, setCategories] = useState<Category[]>([])
    const [jobdesc, setJobDesc] = useState<JobDescription[]>([])

    const [netmodel, setNetModel] = useState<NetModel>(NetModelInit[0])
    const [alllinkdata, setAllLinkData] = useState<Link[]>([])
    const [selData, setSelData] = useState<seldata>({
        linkdata: [], jobdesc: JobDescriptionInit[0], jobtitle: JobTitleInit[0], categories: [],
        sourcejc: '', sourcetitle: '', targetjc: '', targettitle: '', category: '', selskills: [], careerpaths: []
    })
    const [jobtitles, setJobTitles] = useState<JobTitle[]>([])
    const [fields, setFields] = useState<FieldMap[]>([])
    const [attrfields, setAttrFields] = useState<FieldMap[]>([])

    useEffect(() => {
        ImportAndSetStateGeneric(setJobSkills, '', schema, paths.jobdescription.jobskillview, user, config, { type: "Name" })
        ImportAndSetStateGeneric(setJDModels, '', schema, paths.jobdescription.descmodelview, user, config, { type: "Name" })
        ImportAndSetStateGeneric(setJobDesc, '', schema, paths.jobdescription.jobdescview, user, config, { type: "Name" })
        GenericBackendCall("", schema, paths.generics.genericfilterview, user, config, { model: "JobTitle", filterfield: "IsMostRecent", filtervalues: ['true'] }).then(d => {
            let data = SerializeDataFrame(d);
            setJobTitles(data)
        })
        ImportAndSetStateGeneric(setFields, "", schema, paths.fields.fieldretrieve, user, config, {})
    }, [])

    useEffect(() => {
        let allattrs: FieldMap[] = []
        fields.forEach((fld: FieldMap) => {
            if (fld.FieldCategory === "Attribute" && fld.ModelCategory === "JobTitle") {
                allattrs.push(fld)
            }
        })
        setAttrFields(allattrs)
    }, [fields])

    useEffect(() => {
        if (keyparams.selmodelkey !== "") {
            GenericBackendCall("", schema, paths.jobdescription.descmodelview, user, config,
                { type: "Full", modelkeys: [keyparams.selmodelkey] }).then(d => {
                    if (d.length > 0) {
                        let cats = GetUniquesFromObjectAttribute(d[0].categories, "CategoryNum")
                        let catobjs: Category[] = cats.map((c: any) => {
                            return { Category: c[0], CategoryName: '', CategoryTerms: [] }
                        })
                        setCategories(catobjs)
                        setSelJDModel(d[0])
                    }
                })
        }
    }, [keyparams.selmodelkey])

    useEffect(() => {
        if (selJDModel) {
            let [nodes, links, alllinks] = JDSkillsToNetwork(jobdesc, selJDModel.categories, jobtitles, categories, keyparams.sensitivity)
            setAllLinkData(alllinks)
            let rundate: NetworkRunDate = { ...NetworkRunDateInit[0], DateKey: "_", nodes: nodes, links: links }
            setNetModel({ ...NetModelInit[0], rundates: [rundate] })
        }
    }, [selJDModel, keyparams.sensitivity])

    useEffect(() => {
        let [newcats, colors] = NetworkGraphSetup(netmodel, "_", SNAstate.categoryattribute, SNAstate.categoryattrtype)
        setSNAstate({ ...SNAstate, categories: newcats, colors: colors })
        setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
    }, [netmodel, SNAstate.categoryattribute])



    const ClickAnalyze = () => {
        let idval = SNAstate.selNodeid;
        if (idval !== "") {
            let seljd: JobDescription = JobDescriptionInit[0]
            let seljt: JobTitle = JobTitleInit[0]

            jobdesc.forEach((jd: JobDescription) => {
                if (String(jd.JobCode) === String(idval)) {
                    seljd = jd
                }
            })
            jobtitles.forEach((jt: JobTitle) => {
                if (jt.JobCode === idval) {
                    seljt = jt
                }
            })
            let sourcelinks = FilterData(alllinkdata, "Source", idval);
            let targetlinks = FilterData(alllinkdata, "Target", idval);

            let comblinks: Link[] = sourcelinks.concat(targetlinks)
            let filtlinks: Link[] = []
            comblinks.forEach((lk: Link) => {
                if ((lk.Count / lk.CountPct) >= keyparams.sensitivity) {
                    filtlinks.push(lk)
                }
            })


            let selskills = FilterData(jobskills, "JobCode", String(seljd.JobCode))
            let idcareerpath = GetObjectField(seljd.JobCode, jobdesc, "JobCode", "CareerLevel")
            let careerpaths: any[] = []

            sourcelinks.forEach((lk: Link) => {
                let val1 = GetObjectField(lk.Target, jobdesc, "JobCode", "CareerLevel")
                if (val1 >= idcareerpath) {
                    careerpaths.push({ ...lk, PathLevel: val1, CountPct: lk.Count / lk.CountPct })
                }
            })
            targetlinks.forEach((lk: Link) => {
                let val2 = GetObjectField(lk.Source, jobdesc, "JobCode", "CareerLevel")
                if (val2 >= idcareerpath) {
                    careerpaths.push({
                        ...lk, Source: lk.Target, Target: lk.Source,
                        TargetName: GetObjectField(lk.Source, jobtitles, "JobCode", "JobTitle"),
                        PathLevel: val2, CountPct: lk.Count / lk.CountPct
                    })
                }
            })

            careerpaths = ObjectSort(careerpaths, "CountPct", true)
            let nextcodes: string[] = []
            careerpaths.forEach((cp: any, i: number) => {
                if (i <= 3) {
                    let skills = FilterData(jobskills, "JobCode", cp.Target)

                    nextcodes.push(cp.Target)
                }
            })

            GenericBackendCall("", schema, paths.jobdescription.jobcareerview, user, config,
                { jobcode: seljd.JobCode, othcodes: nextcodes }).then(d => {
                    let data = SerializeDataFrame(d)
                    let uniquejc = GetUniquesFromObjectAttribute(data, "JobCode", true)
                    let data_exist = FilterData(data, "New_Exist", "exist")
                    let data_new = FilterData(data, "New_Exist", "new")

                    uniquejc.forEach((jc: string) => {
                        let flt_exist = FilterData(data_exist, "JobCode", jc)
                        let flt_new = FilterData(data_new, "JobCode", jc)
                        let existcat = GetUniquesFromObjectAttribute(flt_exist, "JobCode", true)
                        let newcat = GetUniquesFromObjectAttribute(flt_new, "JobCode", true)
                    })
                })

            setSelData({
                ...selData, linkdata: filtlinks, selskills: selskills, jobdesc: seljd,
                jobtitle: seljt, categories: [], careerpaths: careerpaths
            })
            setKeyParams({ ...keyparams, nodeselected: true })
        }
    }

    const ClickLink = (lk: Link) => {
        let cats: Category[] = []
        if (selJDModel) {
            selJDModel.categories.map((sc: SkillCategory) => {
                if (lk.otherdata.indexOf(String(sc.CategoryNum)) >= 0) {
                    let terms = ConvertBackendArryString(sc.CategoryTerms)
                    cats.push({ Category: String(sc.CategoryNum), CategoryName: sc.Category, CategoryTerms: terms })
                }
            })

            let sjt = GetObjectField(lk.Source, jobtitles, "JobCode", "JobTitle")
            let tjt = GetObjectField(lk.Target, jobtitles, "JobCode", "JobTitle")

            setSelData({
                ...selData, categories: cats, sourcejc: lk.Source,
                sourcetitle: sjt, targetjc: lk.Target, targettitle: tjt
            })
            setKeyParams({ ...keyparams, iscatvisible: true })
        }
    }


    NetworkGraph2D(netmodel.rundates[0], SNAstate, setSNAstate, "#networkf1i", [900, 900], keyparams.refresh)



    return (<div>
        <div className="ndt_header" style={{ padding: '5px', border: "1px solid grey" }}>
            <div className="ndt_title2" style={{ color: "white" }}>Skills Classifier Model</div>
            <div style={{ display: "flex" }}>
                <GenericDropdown
                    data={jdmodels}
                    keycol="ModelKey"
                    namecol="ModelName"
                    promptstring="Models"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, jdmodels, keyparams, setKeyParams, "selmodelkey", "ModelKey", "") }}

                />
                <GenericDropdown
                    data={attrfields}
                    keycol="Key"
                    namecol="FieldName"
                    promptstring="Category Attribute"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, attrfields, SNAstate, setSNAstate, "categoryattribute", "AttrName", "") }}
                />
                <div style={{ display: "flex", color: "white", marginLeft: "100px", height: "30px" }}>
                    <div style={{ marginTop: "4px", marginRight: "3px" }}>Sensitivity:</div>
                    <input type="number" min={0} max={100} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, sensitivity: +e.target.value / 100, refresh: keyparams.refresh + 1 }) }} />
                    <div style={{ marginTop: "4px", marginLeft: "3px" }}>%</div>
                </div>
            </div>
        </div>

        <div id="jdng_main">
            <div id="networkf1i" style={{ border: "1px solid grey" }}>

            </div>
            <div style={{ marginLeft: "10px" }}>
                <div className="ndt_gridbox" style={{ padding: "25px", height: "700px" }}>
                    <div className="ndt_title4">Selected Node</div>

                    <div>
                        <div style={{ display: "flex" }}>
                            <button className="ndt_btn3" onClick={_ => { ClickAnalyze() }} style={{ marginRight: "50px", width: "150px" }}>Analyze</button>

                            {keyparams.nodeselected ?
                                <div style={{ display: "flex" }}>
                                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, view: "Links" }) }}>Links</button>
                                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, view: "Skills" }) }}>Skills</button>
                                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, view: "Path" }) }}>Career Path</button>
                                    <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setSNAstate({ ...SNAstate, clickmode: e.target.selectedOptions[0].attributes[0].value }) }}>
                                        <option value="">Select Option</option>
                                        <option value="Neighborhood">Neighborhood</option>
                                    </select>
                                </div> : <div></div>}
                        </div>
                        <div style={{ fontWeight: "bold", margin: "5px", fontSize: "20px" }}>
                            {selData.jobdesc.JobCode} - {selData.jobtitle.JobTitle}
                        </div>
                    </div>
                    {(() => {
                        switch (keyparams.view) {
                            case "Links": return <div>
                                <div key={"0x"} className="jdng_lkitm" style={{ backgroundColor: "black", color: "white", marginRight: '15px' }}>
                                    <div className="jdng_lksubitm" >Source - Target</div>
                                    <div className="jdng_lksubitm">Count</div>
                                    <div className="jdng_lksubitm" style={{ paddingLeft: "10px" }}>Categories</div>
                                </div>
                                <div style={{ height: "500px", overflowY: "scroll" }}>
                                    {selData.linkdata.map((lk: Link, i: number) => {
                                        return (<div key={i} className="jdng_lkitm" onClick={_ => { ClickLink(lk) }}>
                                            <div className="jdng_lksubitm">{lk.Source} - {lk.Target}</div>
                                            <div className="jdng_lksubitm">{lk.Count} / {lk.CountPct}</div>
                                            <div className="jdng_lksubitm" style={{ display: "flex" }}>
                                                <div >Categories:</div>
                                                {lk.otherdata.map((d: any, x: number) => {
                                                    return (<div key={"_" + String(x)} className="jdng_lkitm_cat">{d}, </div>)
                                                })}
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            case "Skills": return <div>
                                {selData.selskills.map((jd: JobSkill, i: number) => {
                                    return (<div key={i} className="jdng_skillitm">{jd.Skill}</div>)
                                })}
                            </div>;

                            case "Path": return <div>
                                {selData.careerpaths.map((cp: Link, i: number) => {
                                    if (i < 4) {
                                        return (<div key={i} className="jdng_skillitm">
                                            <div className="jdng_pathsubitm">{cp.Target}</div>
                                            <div className="jdng_pathsubitm">{cp.Count} </div>
                                            <div className="jdng_pathsubitm">{Math.round(cp.CountPct * 100) / 100}</div>
                                        </div>)
                                    }
                                })}
                            </div>
                            default: return <div></div>;
                        }
                    })()}

                    <div>

                    </div>


                </div>
                <div style={{ margin: "5px", padding: "5px" }}>
                    <div className="ndt_title4">Chart Legend:</div>
                    <div id="jdng_legend" style={{ height: String(Math.round(SNAstate.categories.length * 15)) + "px", overflowY: "scroll", border: "1px solid black", padding: "5px", width: "50%" }}>
                        {SNAstate.categories.map((c: string, i: number) => {
                            return (<div key={i} style={{ display: "flex", cursor: "pointer" }} onClick={_ => { setSNAstate({ ...SNAstate, selcategory: c }); setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 }) }}>
                                <div>{c}</div>
                                <div style={{ height: '15px', width: '15px', marginTop: "5px", marginLeft: "10px", background: SNAstate.colors[i] }}></div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </div>



        <Modal show={keyparams.iscatvisible} dialogClassName="modalwide2">
            <div className="ndt_modal" >
                <div style={{ minHeight: "350px" }}>
                    <div style={{ fontSize: "24px", display: "flex", marginBottom: "5px" }}>
                        <div >Category:</div>
                        <div style={{ fontSize: "20px", marginLeft: "5px", marginTop: "6px" }}>Job Codes: {selData.sourcetitle} - {selData.targettitle}</div>
                    </div>
                    <div className="jdng_mdlcat" style={{ display: "flex", overflow: "hidden" }}>
                        <div style={{ width: "50px", marginRight: "10px" }}>Cat</div>
                        <div style={{ width: "300px", marginRight: "10px" }}>Desc</div>
                        <div style={{ display: "flex", width: "400px" }}>
                            Terms:
                        </div>
                    </div>
                    {selData.categories.map((cat: Category, i: number) => {
                        return (<div key={i} className="jdng_mdlcat" style={{ display: "flex", overflow: "hidden" }}>
                            <div style={{ width: "50px", marginRight: "10px" }}>{cat.Category}</div>
                            <div style={{ width: "300px", marginRight: "10px" }}> {cat.CategoryName}</div>

                            <div style={{ display: "flex", width: "400px" }}>
                                {cat.CategoryTerms.map((t: string, j: number) => {
                                    return (<div key={"_" + String(j)} className="jdng_lkitm_cat">{t}</div>)
                                })}
                            </div>
                        </div>)
                    })}
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, iscatvisible: false })}>Close</button>
            </div>
        </Modal>
    </div>)
};
export default JobDescNetGraph;