import { TableModel } from "../../type";

const ModelCategoryTablesDropdown = (props: any) => {
    //ref ConnectionManager,
    let change = props.change
    let className = props.className
    let tables = props.tables;

    let usetables = []
    if (props.usetables) {
        usetables = props.usetables;
    } else {
        usetables = tables
    }

    return (
        <select className={className} onChange={change}>
            <option value="">Select Table:</option>
            {usetables.map((tbl: TableModel, i: number) => {
                return (<option key={i} value={tbl.ModelName}>{tbl.ModelName}</option>)
            })}
        </select>
    )
};
export default ModelCategoryTablesDropdown;