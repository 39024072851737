
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Key_Name, queryvisualstate } from "../type";
const ChartIcon = (props: any) => {
    //ref: dashboardview, 

    let iconID: string = props.iconID
    let vis_states: queryvisualstate[] = props.vis_states
    let setVis_States: any = props.setVis_States
    let int: number = +props.int
    let charttype: string = props.charttype
    let metrics: Key_Name[] = props.metrics

    let [viewstate, setViewState] = useState<string>("default")
    let [modalvisible, setModalVisible] = useState<any>(false)

    const ChangeStateView = (fields: string[], vals: any[]) => {

        let newvisstate = vis_states.map((a: any) => ({ ...a }))
        fields.forEach((f: string, i: number) => {
            newvisstate[int][f] = vals[i]
        })

        setVis_States(newvisstate)
    }


    const OptionsChart = () => {
        return (<div>
            <button className="ndt_btn1" onClick={_ => { ChangeStateView(["show"], ["chart"]); setViewState("chart") }}> Set To Chart</button>
            <button className="ndt_btn1" onClick={_ => { ChangeStateView(["show"], ["table"]); setViewState("table") }}> Set To Table</button>
            <button className="ndt_btn1" onClick={_ => { ChangeStateView(["show"], ["summary"]); setViewState("summary") }}> Show Summary</button>
        </div>)
    }

    // const ChangeTblHoriz = (metr: Key_Name) => {
    //     //need to change both for tblhoriz: uses selmetricname
    //     let newvisstate = vis_states.map((a: any) => ({ ...a }))
    //     newvisstate[int].selectedmetric = metr.Key
    //     newvisstate[int].selectedmetricname = metr.Name
    //     setVis_States(newvisstate)
    // }

    const OptionsTblHoriz = () => {
        return (<div style={{ display: "flex" }}>
            {metrics.map((metr: Key_Name, i: number) => {
                return (<div data-value={metr.Key} className="ndt_btn1" key={i}
                    onClick={_ => { ChangeStateView(["selectedmetric", "selectedmetricname"], [metr.Key, metr.Name]) }}>
                    {metr.Name}
                </div>)
            })}
        </div>)
    }

    const ChartTypeOptions = () => {
        return (<div>
            {(() => {
                switch (charttype) {
                    case "Table": return <div></div>
                    case "TableHorizontal": return <div><OptionsTblHoriz /></div>
                    default: return <div><OptionsChart /></div>;
                }
            })()}
        </div>)
    }


    const ChartEventOptions = () => {
        return (<div>
            <button className="ndt_btn1" onClick={_ => { ChangeStateView(["clickevent"], ["inspect"]) }}>Select</button>
            <button className="ndt_btn1" onClick={_ => { ChangeStateView(["clickevent"], ["detail"]) }}>Detail Popup</button>
        </div>)
    }

    const ChartHighlightOptions = () => {
        //not sure if changing selmetrname is necessary
        return (<div>
            <div>Highlight Series</div>
            <button className="ndt_btn1" onClick={_ => { ChangeStateView(["highlight", "selectedmetric", "selectedmetricname"], ["none", "", ""]) }}>None</button>
            {metrics.map((metr: Key_Name, i: number) => {
                return (
                    <button className="ndt_btn1" key={i} onClick={_ => { ChangeStateView(["highlight", "selectedmetric", "selectedmetricname"], ["series", metr.Key, metr.Name]); }}>{metr.Name}</button>
                )
            })}
        </div>)
    }


    return (
        <i id={iconID}
            className="fa fa-asterisk"
            style={{ "fontSize": 24, "cursor": "pointer", float: "right" }}
            onClick={_ => setModalVisible(!modalvisible)}
        >
            <Modal show={modalvisible}>
                <div className="ndt_modal" style={{ backgroundColor: "black", color: "white" }}>
                    <div>Switch View</div>
                    <ChartTypeOptions />
                    <div>Viewing:{viewstate}</div>
                    <ChartEventOptions />
                    <ChartHighlightOptions />
                    <button className="closemodalbtn" onClick={_ => { setModalVisible(false) }}>Close </button>
                </div>
            </Modal>
        </i>
    )
};
export default ChartIcon