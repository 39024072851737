

function IndexInVector(Arr,val){

	var x= 0;
	var int = -1;
	//loop through
	Arr.forEach((ind,i) => {

		if(ind === val){
			x = x + 1
            int = i
		} else {
			x = x + 0
		}
	})

	return(int)
}
export default IndexInVector;