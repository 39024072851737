
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
//import {logger} from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';
import { fieldmapSlice } from './reducers/field_actions';
import { allUsersSlice, userSlice } from "./reducers/user_actions"
// import { employeeDataSlice, employeestateDataSlice, empModelSlice } from "./reducers/employee_actions"
// import { ruleModelDataSlice } from './reducers/ruleset_actions';
// import { queryModelSlice } from './reducers/query_actions';
// import { filterModelDataSlice } from './reducers/filterset_actions';
// import { dashboardSlice } from './reducers/dashboard_actions';
import { schemaDataSlice, schemalistDataSlice, workspaceDataSlice } from './reducers/schema_actions';

import { tablegroupsDataSlice } from './reducers/tablegroup_actions';
import { otherSlice } from './reducers/other_actions';

//combines reducers - toolkit will understand object
const reducer = combineReducers({
	othervalues: otherSlice.reducer,
	user: userSlice.reducer,
	usersAll: allUsersSlice.reducer,
	selectedSchema: schemaDataSlice.reducer,
	selectedWorkspace: workspaceDataSlice.reducer,
	schemas: schemalistDataSlice.reducer,
	tablegroups: tablegroupsDataSlice.reducer,
	//dashboard: dashboardSlice.reducer,
	// employee: employeeDataSlice.reducer,
	// employee_full: employeestateDataSlice.reducer,
	fieldmapping: fieldmapSlice.reducer,
	//rulemodels: ruleModelDataSlice.reducer,
	//filtermodels: filterModelDataSlice.reducer,
	//selectedModel: selectedRuleModelSlice.reducer,
	//empmodel: empModelSlice.reducer,
	//basicquery: basicquerySlice.reducer,
	//querymodels: queryModelSlice.reducer,
});

const persistConfig = {
	key: 'root',
	storage,
}

const persistedReducer = persistReducer(persistConfig, reducer);


//store: comes with redux-thunk & devtools
//serializablecheck ignores an (unimportant?) error from redux-persist
export default configureStore({
	reducer: persistedReducer,
	middleware: [...getDefaultMiddleware({ serializableCheck: false }),
		//logger
	]
});

