import { useContext, useEffect, useState, ChangeEvent } from "react";
import { StartupContext } from "../../../App";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { DescModel, JobSkill, SkillCatValue, SkillCategory } from "../../../type";
import "./css/JobSkillPipeline.css"
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import FilterData from "../../../functions/FilterData";
import GetUniquesFromObjectAttribute from "../../../functions/GetUniquesFromObjectAttribute";


const JobSkillPipeline = (props: any) => {

    const selJDModel: null | DescModel = props.seljdmodel
    const jobdesc = props.jobdesc
    const jobskills = props.jobskills
    const roster = props.roster
    const jdmodels = props.jdmodels
    const jobtitles = props.jobtitles

    interface paramtype {
        seljobcode: string;
        seljobtitle: string;
        selcategory: string;
        categories: any[];
    }
    interface catdata {
        CategoryFrom: string;
        CategoryTo: string;
        Count: number
    }

    let { config, dispatcher, schema, user, paths } = useContext(StartupContext)
    let [keyparams, setKeyParams] = useState<paramtype>({ seljobcode: '', seljobtitle: '', categories: [], selcategory: '' })
    let [data, setData] = useState<catdata[]>([])
    //let [selJDModel, setSelJDModel] = useState<DescModel | null>(null)
    let [catdata, setCatData] = useState<catdata[]>([])

    useEffect(() => {
        if (selJDModel) {
            GenericBackendCall(selJDModel.ModelKey, schema, paths.jobdescription.skillcategoryview, user, config, { cats: [keyparams.selcategory] }).then(d => {
                console.log(d)
            })
        }
    }, [selJDModel?.ModelKey])

    const ClickAnalyze = () => {
        if (selJDModel) {
            GenericBackendCall(selJDModel.ModelKey, schema, paths.jobdescription.skillpipelineview, user, config, { asofdate: new Date() }, 'jsp_divana').then(d => {
                let dd = SerializeDataFrame(d[0])
                let cc: any[] = SerializeDataFrame(d[1])
                console.log(dd)
                console.log(cc)
                setData(dd)
                setKeyParams({ ...keyparams, categories: cc })
            })
        }
    }

    useEffect(() => {
        if (keyparams.selcategory !== "" && selJDModel) {
            let c = FilterData(data, "CategoryFrom", keyparams.selcategory)
            setCatData(c)


        }
    }, [keyparams.selcategory])

    return (<div>
        <div className="ndt_title4" style={{ margin: '5px' }}>Pipeline</div>

        <div>
            <button onClick={_ => { ClickAnalyze() }}>Analyze</button>

            {keyparams.categories.length > 0 ?
                <GenericDropdown
                    data={keyparams.categories}
                    keycol="Category"
                    namecol={"Category"}
                    promptstring="Select Category"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, keyparams.categories, keyparams, setKeyParams, "selcategory", "Category", "") }}
                /> : <div></div>}
        </div>

        <div id="jsp_main">
            <div>
                <div>Adjacent Categories</div>
                {catdata.map((cd: catdata, i: number) => {
                    return (<div key={i}>{cd.CategoryTo} {cd.Count}</div>)
                })}
            </div>

            <div>
                <div>Skills</div>
            </div>
        </div>
    </div>
    )
};
export default JobSkillPipeline;