import { BasicQuery, queryvisualstate } from "../../../type";
import AddElement from "./AddElement";
import DateString from "../../../functions/Date Functions/DateString";
import UpdateState from "../../newfunctions/UpdateState";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";


interface Hovertype {
    fieldname: string;
    values: string[];
}

function TooltipInspect(idata: BasicQuery[], elem: HTMLDivElement, vis_states: queryvisualstate[], setVis_States: any, visstateIdx: number,
    context: any) {


    let popupelem = document.createElement('div');
    popupelem.id = "bargraph_elempopup"
    popupelem.style.cssText = 'width:400px;height:450px;position:absolute;';
    popupelem.style.top = '500px'
    popupelem.style.left = '500px'

    let headerelem = document.createElement('div');
    headerelem.innerHTML = 'Inspect:';

    popupelem.appendChild(headerelem)

    const ClickClosed = (e: any) => {
        popupelem.remove()
    }

    const ClickItm = (e: any) => {
        console.log('-')
        let clickval = e.target.innerHTML;
        console.log(clickval)
        UpdateState(vis_states, setVis_States, ["inspect"], [clickval], visstateIdx)


        //handle backend call
        GenericBackendCall("", context.schema, context.paths.answers.default, context.user, context.config,
            { type: clickval, valueid: elem.attributes[1].value, metricno: elem.attributes[3].value }, "tte_divnote")
    }

    let optn1 = AddElement("button", "Value History", "tooltipelem_btnoptn", "", ClickItm)
    popupelem.appendChild(optn1)


    let optn2 = AddElement("button", "Trend", "tooltipelem_btnoptn", "", ClickItm)
    popupelem.appendChild(optn2)

    // let optn3 = AddElement("button", "Save KPI", "tooltipelem_btnoptn", "", ClickItm)
    // popupelem.appendChild(optn3)


    //values
    let dtelem1 = AddElement("div", "X Field: " + DateString(idata[0].Variable), "", "", "tooltipelem_divinsp")
    popupelem.appendChild(dtelem1)


    let dtelem2 = AddElement("div", "Grouping: " + idata[0].Grouping, "", "", "tooltipelem_divinsp")
    popupelem.appendChild(dtelem2)


    let dtelem3 = AddElement("div", "Value: " + elem.attributes[2].value, "", "", "tooltipelem_divinsp")
    popupelem.appendChild(dtelem3)


    let noteelem = AddElement("div", "", "", "tte_divnote", "tooltipelem_divnote")
    popupelem.appendChild(noteelem)


    let btnelem = AddElement("button", "Close", "tooltipelem_btnclose", "", ClickClosed)
    popupelem.appendChild(btnelem)

    window.addEventListener("beforeunload", (ev) => {
        console.log("eventlistener")
        ev.preventDefault();
        popupelem.remove()
    });

    return (popupelem)
};
export default TooltipInspect;


