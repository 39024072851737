function AddElement(type: string, innerhtml: string, classname: string, id: string, clickfunction: any) {
    let newelem = document.createElement(type);
    newelem.innerHTML = innerhtml
    newelem.className = classname
    newelem.id = id
    if (clickfunction !== "") {
        newelem.addEventListener("click", e => clickfunction(e))
    }
    return newelem
}
export default AddElement