import { useState } from "react"
import { createContext } from "react"
import jwt_decode from 'jwt-decode'
//import { useNavigate } from 'react-router-dom'

const AuthContext = createContext<any>({ "loginUser": "xx" })  //'any type prevents it from becoming string, so we can reference as form event also

export default AuthContext;


export const AuthProvider = ({ children }: any) => {

    //let [authTokens, setAuthTokens] = useState(null)
    let [user, setUser] = useState(null)

    //let navigate = useNavigate();

    //get tokens from backend
    let loginUserFn: any = async (e: any) => {
        e.preventDefault()

        let response = await fetch("http://127.0.0.1:8000/api/token/", {
            method: 'POST',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({ 'username': e.target.username.value, "password": e.target.password.value })
        })

        let data: any = await response.json()
        console.log(data)
        console.log('response:', response)

        if (response.status === 200) {
            //setAuthTokens(data)
            setUser(jwt_decode(data.access))
            localStorage.setItem("authTokens", JSON.stringify(data))
        } else {
            alert('something went wrong')
        }

    }


    let contextData = {
        user: user,
        loginUser: loginUserFn
    }

    return (<AuthContext.Provider value={contextData}>
        {children}
    </AuthContext.Provider>)

};