import { KPI, KPIValue, KPIFilter } from "../../../type";

function GetKPIValue(kpi: KPI, encfltkey: string, asofdate: Date | null) {
    //ref: ConvertKPIString
    //no date - default to most recent

    let kval: any = ""
    let maxdate: Date = new Date("1900-01-01")
    let vals = kpi.values

    if (encfltkey !== "") { //use filter value
        kpi.kpiflts.forEach((kflt: KPIFilter) => {
            if (kflt.EncodedFltKey === encfltkey) {
                vals = kflt.values
            }
        })
    }

    vals.map((kv: KPIValue) => {
        if (asofdate) {
            if (kv.AsofDate === asofdate) {
                kval = kv.Value
            }
        } else {
            if (kv.AsofDate > maxdate) {
                maxdate = kv.AsofDate
                kval = kv.Value
            }
        }
    })
    return kval
};
export default GetKPIValue