import axios from "axios";
import { RuleModelActionCreator, RuleModelDeleteActionCreator } from "../../reducers/ruleset_actions";
import { RuleModel } from '../../type'


const RuleModelImport = async (modelkey: string, schema: string, config: any, dispatcher: any, store: boolean) => {

    let dataArray = new FormData();
    dataArray.append("schemakey", schema);

    let data = [];
    try {
        let res = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/api/rulesets/rulemodelview`, dataArray, config)

        data = res.data

        if (store) {
            let ImportedModel: RuleModel[] = res.data
            dispatcher(RuleModelDeleteActionCreator({}))

            ImportedModel.forEach(model => {
                dispatcher(RuleModelActionCreator(model))
            })
        }

    }
    catch (error) {
        console.log(error)
    }
    return (data)

};

export default RuleModelImport;