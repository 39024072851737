function DateDifference(startDate, endDate, unit) {
    const diffTime = endDate.getTime() - startDate.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const oneWeek = oneDay * 7;
    const oneMonth = oneDay * 30; // Approximation for a month
    const oneYear = oneDay * 365; // Approximation for a year

    switch (unit) {
        case 'Daily':
            return Math.round(diffTime / oneDay);
        case 'Weekly':
            return Math.round(diffTime / oneWeek);
        case 'Monthly':
            return Math.round(diffTime / oneMonth);
        case 'Yearly':
            return Math.round(diffTime / oneYear);
        default:
            throw new Error('Invalid unit');
    }
    
};
export default DateDifference;