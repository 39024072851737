
import * as d3 from 'd3';

const CreateColorScale = (colors, colorgradienttype) => {
    
    let primarycolors = colors.map(c=>{return c[0]})
    let secondarycolors = colors.map(c=>{return c[1]})

    let scale1 = d3.scaleOrdinal().range(primarycolors);
    let scale2 = d3.scaleOrdinal().range(secondarycolors);
   
    
    if (colorgradienttype==="Continuous"){
        let scale1 = d3.scaleSequential()
                    .interpolator(d3.interpolateRgbBasis(primarycolors)) // Use interpolateRgbBasis to interpolate between colors
                    .domain([0, colors.length]);
        let scale2 = d3.scaleSequential()
                    .interpolator(d3.interpolateRgbBasis(secondarycolors)) // Use interpolateRgbBasis to interpolate between colors
                    .domain([0, colors.length]);
        return [scale1, scale2] 

    } else if (colorgradienttype==="Discrete" ||colorgradienttype==="Gradient"){
     
     } else if (colorgradienttype==="None"){
         scale1 = d3.scaleOrdinal().range(['#ffffff']);
         scale2 = d3.scaleOrdinal().range(['#ffffff']);
     }

    return [scale1, scale2]
};
export default CreateColorScale