import axios from "axios";
import { QueryModel } from "../../type";

const QueryModelNameImport = async (modelkey: string, schemakey: string, config: any, dispatcher: any, store: boolean) => {
    let querydata: QueryModel[] = []

    //let formData = new FormData()

    try {
        let res = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/api/query/querynameview`,
            { 'schemakey': schemakey, "modelkey": modelkey }, config);

        if (res.data.length > 0) {
            querydata = res.data
        }
    } catch (error) {
        console.log(error)
    }
    return (querydata)
};
export default QueryModelNameImport;