import { useState, useEffect, useContext, MouseEvent, FormEvent, ChangeEvent, useMemo } from 'react'
import { StartupContext } from '../../../App';
import { Dashboard, QueryVisuals, QueryModel, UserPermssion, Schema } from '../../../type';
import { DashboardInit } from '../../../InitTypes';
import { Link } from 'react-router-dom';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import QueryVisualsReattach from '../../../data/Query/QueryVisuals_ReAttach';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import GetObjectField from '../../../functions/Object Functions/GetObjectField';
import GetUserPermission from '../../../functions/QueryFunctions/GetUserPermission';
import { Modal } from 'react-bootstrap';
import DateString from '../../../functions/Date Functions/DateString';
import ObjectSort from '../../../functions/ObjectSort';
import "./css/GraphManager.css"

const GraphManager = () => {

    const { config, dispatcher, schema, user, paths, appcolors, clickstyle } = useContext(StartupContext)

    //setup
    const [view, setView] = useState<string>("dashboard") //dashboard || dates
    const [qryvisuals, setqryvisuals] = useState<QueryVisuals[]>([])
    const [querymodels, setQueryModels] = useState<QueryModel[]>([])
    const [selquery, setSelQuery] = useState<QueryModel | null>(null)
    const [selQVis, setSelQVis] = useState<QueryVisuals | null>(null)
    const [selDashboard, setSelDashboard] = useState<Dashboard>(DashboardInit[0])
    const [userperm, setUserPerm] = useState<UserPermssion | null>(null)

    const [keyparams, setKeyParams] = useState({
        modelname: '', selDashboardKey: "", modeltype: "", selschema: schema, selschemaname: '',
        querycount: 0, refresh: 0,
        isdeletevisible: false, rename: '', isrenamevisible: false, isduplicatevisible: false
    });

    const [linkstring, setLinkstring] = useState<string>("")
    const [dashlinkstring, setDashLinkstring] = useState<string>("")

    const viewtypes = [{ A: 'assign', B: 'Assign' }, { A: 'copy', B: 'Copy' }, { A: 'delete', B: "Delete" }, { A: 'rename', B: "Rename" }]
    const qmhovercolor = "rgb(20,20,20)";
    const qmdefaultstyle = { ...clickstyle.btnUnselected, border: "1px solid grey" }
    const qmselstyle = { ...clickstyle.btnSelected, border: '2px solid yellow' }
    const qmhoverstyle = { backgroundColor: qmhovercolor, border: "2px solid " + appcolors.colorsecondary, boxShadow: "0px 0px 10px " + appcolors.colorsecondary }

    const [querydivStyles, setQuerydivStyles] = useState<any[]>([])
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [schemas, setSchemas] = useState<Schema[]>([]);


    const viewbtndefault = { backgroundColor: "rgb(20,50,100)", opacity: "1", color: "white" }
    const viewbtnselected = { backgroundColor: "rgb(40,40,40)", opacity: "1", color: "pink" }
    const viewbtnfaded = { backgroundColor: "rgb(20,50,100)", opacity: ".5", color: "white" }

    let viewstylesbase = Array.apply(null, Array(viewtypes.length)).map(function (_) { return viewbtndefault; })
    let viewstylesinit = Array.apply(null, Array(viewtypes.length)).map(function (_) { return viewbtnfaded; })
    const [ViewButtonStyle, setViewButtonStyle] = useState<any[]>(viewstylesinit)

    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        ImportAndSetStateGeneric(setSchemas, "", schema, paths.schema.schemaimport, user, config, { permissions: ["manage", "creator", 'read/write'] })
        ImportAndSetStateGeneric(setDashboards, "", schema, paths.dashboard.dashnameview, user, config, { permissions: ["manage", "creator"] })
        ImportAndSetStateGeneric(setqryvisuals, "", schema, paths.query.queryvisualsview, user, config, {})
        GenericBackendCall("", schema, paths.query.queryview, user, config, { type: "base", permissions: ['manage', 'read/write', 'creator'] }).then(d => {
            setQueryModels(ObjectSort(d, "DateCreated", true))
        })
    }, [])

    useEffect(() => {

        if (selquery) {
            setViewButtonStyle(viewstylesbase)
            setLinkstring("/graph/" + selquery.ModelKey)
            setView("")
            GetUserPermission(selquery, user, setUserPerm)
            setKeyParams({ ...keyparams, modelname: selquery.ModelName })
        } else {
            setViewButtonStyle(viewstylesinit)
        }

    }, [selquery?.ModelKey])


    useEffect(() => {
        setQuerydivStyles(Array.apply(null, Array(querymodels.length)).map(function (_) { return qmdefaultstyle; }))
    }, [querymodels])

    useEffect(() => {
        if (selDashboard) {
            setKeyParams({ ...keyparams, selDashboardKey: selDashboard.Key })
            setDashLinkstring("/dashboard/live/" + selDashboard.UrlString)
        }
    }, [selDashboard])

    useEffect(() => {
        setKeyParams(({ ...keyparams, selschemaname: GetObjectField(keyparams.selschema, schemas, "SchemaKey", "SchemaName") }))
    }, [keyparams.selschema])


    useEffect(() => {
        if (selQVis) {
            setKeyParams({ ...keyparams, modeltype: "visual set", modelname: selQVis.VisName })
        } else if (selquery) {
            setKeyParams({ ...keyparams, modeltype: "query", modelname: selquery?.ModelName })
        }
    }, [selQVis])


    //---functions---
    const SelectQModel = (e: MouseEvent<HTMLDivElement>) => {

        let val: string = e.currentTarget.attributes[0].value;
        let snum: number = 0;
        querymodels.forEach((qry: QueryModel, i: number) => {
            if (qry.ModelKey === val) {
                setSelQuery(qry)
                setSelQVis(null)
                snum = i
            }
        })
        let newstyleinit = Array.apply(null, Array(querymodels.length)).map(function (_) { return qmdefaultstyle; })
        newstyleinit[snum] = qmselstyle
        setQuerydivStyles(newstyleinit)
    }


    const ClickedView = (vw: any, i: number) => {
        if (vw.A !== view && ViewButtonStyle[i].opacity === "1") {
            setView(vw.A)
            let newstyles = viewstylesbase
            newstyles[i] = viewbtnselected
            setViewButtonStyle(newstyles)
        }
    }

    //--backend calls
    const DeleteSelModel = (modelkey: string, modelcategory: string, keyfield: string) => {
        //QueryVisualsDelete(keyparams.selQVisKey, config, dispatcher, "qm_deletediv");

        GenericBackendCall(modelkey, schema, paths.generics.genericdelete,
            user, config, { model: modelcategory, field: keyfield }, "qm_deletediv")
            .then(_ => {
                setKeyParams({ ...keyparams, refresh: 99 })

                if (modelcategory === "QueryModel") {
                    setSelQuery(null);
                }
                setSelQVis(null); //either case
                setView("")
            });
    };

    const DuplicateSelModel = (model: string, modelkey: string) => {
        //QueryVisualsDuplicate(keyparams.selQVisKey, config, dispatcher, "qm_dupdiv");
        GenericBackendCall(modelkey, schema, paths.query.queryduplicate, user, config, { model: model }, "qm_dupdiv").then(_ => {
            setKeyParams({ ...keyparams, refresh: 99 })
        });

    };

    const SetToNewDash = () => {
        if (selQVis) {
            QueryVisualsReattach(schema, selQVis.VisKey, keyparams.selDashboardKey, config, "qrymgr_uploaddiv").then(_ => {
                setKeyParams({ ...keyparams, refresh: 99 })
            });
        }
    };
    const RmvFromDash = () => {
        if (selQVis) {
            QueryVisualsReattach(schema, selQVis.VisKey, "none", config, "qrymgr_uploaddiv").then(_ => {
                setKeyParams({ ...keyparams, refresh: 99 })
            });
        }
    };


    const RenameSelModel = (modelkey: string, model: string) => {
        GenericBackendCall(modelkey, schema, paths.query.queryrename, user, config,
            { modelname: keyparams.rename, model: model }, "gmgr_divren").then(_ => {
                setKeyParams({ ...keyparams, refresh: 99 })
            });
    }

    const ClickRename = () => {
        if (selQVis) {
            RenameSelModel(selQVis.VisKey, "QueryVisuals")
        } else if (selquery) {
            RenameSelModel(selquery.ModelKey, "QueryModel")
        }
    }


    const ClickDelete = () => {
        if (selQVis) {
            DeleteSelModel(selQVis.VisKey, "QueryVisuals", "VisKey")
        } else if (selquery) {
            DeleteSelModel(selquery.ModelKey, "QueryModel", "ModelKey")
        }
    }

    const ClickDuplicate = () => {
        if (selQVis) {
            DuplicateSelModel("QueryVisuals", selQVis.VisKey)
        } else if (selquery) {
            DuplicateSelModel("QueryModel", selquery.ModelKey)
        }
    }

    const SwitchQry_Dash = () => {
        return (
            <div >
                {(selDashboard.Key !== "" && selQVis) ?
                    <div id="qrymgr_switchcanvas">

                        {selQVis.DashboardName !== selDashboard.Key ?

                            <div className="gm_viewoption qrymgr_assign">
                                {/* assign */}
                                <div className="qrymgr_switchheader">
                                    <div className="qrymgr_header1">Assign Model</div>
                                    <div className="qrymgr_switchitm">Set Model: <div>{selQVis.ModelName}</div></div>
                                    <div className="qrymgr_switchitm">to Dashboard: <div>{selDashboard.Name}</div> ? </div>
                                </div>
                                <form onSubmit={_ => SetToNewDash()}>
                                    <button type="submit" id="qrymgr_confirmbtn"> Confirm </button>
                                </form>
                                <div id="qrymgr_uploaddiv"></div>
                            </div>
                            :
                            <div className="gm_viewoption qrymgr_unassign">
                                {/* unassign */}
                                <div className="qrymgr_switchheader">
                                    <div className="qrymgr_header1">Unassign Model</div>
                                    <div className="qrymgr_switchitm">Remove Model: <div>{selQVis.ModelName}</div></div>
                                    <div className="qrymgr_switchitm">from Dashboard: <div>{selDashboard.Name}? </div></div>
                                </div>
                                <form onSubmit={_ => RmvFromDash()}>
                                    <button type="submit" id="qrymgr_confirmbtn"> Confirm </button>
                                </form>
                                <div id="qrymgr_uploaddiv"></div>
                            </div>
                        }

                    </div>
                    :
                    <div></div>
                }
            </div >
        )
    };


    //assign
    const ViewAssign = () => {
        return (<div>
            <div id="qrymgr_dashboardbox" className="gm_viewoption">
                <div id="qrymgr_dashheader">Assign/Unassign Dashboard</div>
                <div>Dashboard Set: {selDashboard.Name}</div>
                {!selQVis && selDashboard ? <div>Select a Visual Set to assign/unassign</div> : <div></div>}
            </div>
            <SwitchQry_Dash />
        </div>)

    }
    //copy
    const ViewCopy = () => {
        return (
            <div className="gm_viewoption">
                <div className="gm_viewoption_body">
                    <div>Do you want to duplicate {selQVis?.ModelName}? </div>
                    <div>An additional, unassigned graph will be created</div>
                    <button className="ndt_btn1" type="submit" onClick={_ => setKeyParams({ ...keyparams, isduplicatevisible: true })}> Duplicate </button>
                </div>
            </div>
        )
    }
    //delete
    const ViewDelete = () => {

        if (userperm && userperm.Permission === "creator" || user.level === "Admin") {
            return (
                <div className="gm_viewoption">

                    {selQVis ?
                        < div className="gm_viewoption_body">
                            <div >Are you sure you want to delete visualset {keyparams.modelname}? </div>
                            <button className="ndt_btn1" type="submit" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: true })}> Delete </button>
                        </div>
                        :
                        <div className="gm_viewoption_body">
                            <div>Are you sure you want to delete model {keyparams.modelname}? </div>
                            <button className="ndt_btn1" type="submit" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: true })}> Delete </button>
                        </div>}
                </div>
            )
        } else {
            return (
                <div className="gm_viewoption">
                    Only the creator of this model may delete
                </div>)
        }
    }


    const ViewRename = () => {
        return (
            <div className="gm_viewoption">
                <div className="gm_viewoption_body">
                    <div>Rename Selected Graph To:</div>
                    <input type="text" value={keyparams.rename} onChange={e => setKeyParams({ ...keyparams, rename: e.target.value })} />
                    <button className="ndt_btn1" onClick={_ => setKeyParams({ ...keyparams, isrenamevisible: true })}>Rename</button>
                </div>
            </div>
        )
    }
    let renamememo = useMemo(() => ViewRename(), [keyparams.rename, view])

    return (
        <div id="gm_canvas" className="ndt_canvas">
            {/* Query Models */}
            <div className="ndt_gridbox" style={{ minWidth: '250px', height: '900px' }}>

                <div id="qrylst_header">
                    <div id="qrylst_graphheader" style={{ fontSize: "22px" }}>Data Queries</div>
                    <div id="qrylst_subheader" style={{ fontSize: "16px" }}>
                        {/* <div style={{ marginRight: "10px", fontSize: "17px" }}>Schema:</div> <GenericDropdown
                            data={schemas}
                            keycol={"SchemaKey"}
                            namecol="SchemaName"
                            promptstring="Select Schema"
                            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, schemas, keyparams, setKeyParams, "selschema", "SchemaKey", "") }}
                        /> */}
                    </div>
                </div>
                <div style={{ height: "600px" }}>

                    {querymodels.length > 0 ?
                        <div id="gm_qrylist_body" className="ndt_subinner">
                            {querymodels.map((qry: QueryModel, i: number) => {
                                if (qry.SchemaName === keyparams.selschema) {
                                    return (
                                        <div
                                            data-value={qry.ModelKey}
                                            data-name={qry.ModelName}
                                            className="qm_itm qm_qrylist_itm"
                                            key={i}
                                            id={'qry_' + qry.ModelKey}
                                            style={querydivStyles[i]}
                                            onClick={(e: MouseEvent<HTMLDivElement>) => SelectQModel(e)}
                                            onMouseOver={_ => { if (selquery?.ModelKey !== qry.ModelKey) { setQuerydivStyles({ ...querydivStyles, [i]: qmhoverstyle }) } }}
                                            onMouseOut={_ => { if (selquery?.ModelKey !== qry.ModelKey) { setQuerydivStyles({ ...querydivStyles, [i]: qmdefaultstyle }) } }}
                                        >
                                            <div style={{ overflow: "hidden" }}>Name: {qry.ModelName}</div>
                                            <div style={{ overflow: "hidden" }}>Date Created: {DateString(qry.DateCreated)}</div>
                                        </div>
                                    )
                                }
                            })}
                        </div> :
                        <div id="gm_qrylist_body">No Graphs Created Yet!</div>}
                    {selquery ?
                        <div id="gm_visdetail" className="ndt_subinner">
                            <div style={{ fontSize: '17px' }}>Visual Sets on this Query:</div>
                            {qryvisuals.length > 0 ?
                                <div id="gm_visdetailinner">

                                    {qryvisuals.map((qv: QueryVisuals, i: number) => {
                                        let qvstyle = { backgroundColor: "" }
                                        if (qv.VisKey === selQVis?.VisKey) { qvstyle = { backgroundColor: "blue" } }
                                        if (qv.ModelName === selquery.ModelKey) {
                                            return (<div className="gm_qvisitem" key={i} style={qvstyle} onClick={_ => setSelQVis(qv)}>
                                                {qv.VisName}:   {GetObjectField(qv.DashboardName, dashboards, "Key", "Name")}
                                            </div>)
                                        }
                                    })}
                                </div>
                                : <div>None!</div>}
                        </div>
                        : <div>Select a query model first</div>}
                </div>


            </div>
            <div id="gm_subcanvas">
                <div id="gm_viewseldiv" className='ndt_gridbox' style={{ display: "flex" }}>
                    <div id="gm_viewdivheader" className="ndt_subinner"  >
                        <div style={{ marginLeft: "10px", overflow: "hidden" }}> Action:</div>
                        <div style={{ display: "flex" }}>
                            {
                                viewtypes.map((vw: any, i: number) => {
                                    return (
                                        <button key={i} className="qm_viewbtn"
                                            style={vw.A === view ? viewbtnselected : ViewButtonStyle[i]}
                                            onClick={_ => ClickedView(vw, i)}>{vw.B}</button>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='ndt_subinner' style={{ display: "flex", width: '250px', height: '75px' }}>
                        {selquery && selquery.SchemaName === schema && schema !== "" ?
                            <div className="gm_viewlink"><Link className="nav-link active" aria-current="page" to={linkstring} >View Model</Link></div>
                            : <div></div>}
                        {selDashboard && selDashboard.SchemaName === schema && schema !== "" ?
                            <div className="gm_viewlink"><Link className="nav-link active" aria-current="page" to={dashlinkstring} >View Dashboard</Link></div>
                            : <div></div>}
                    </div>

                </div>

                {/* View Selection */}
                {selQVis || selquery ?
                    <div id="gm_gridoptbox">
                        <div>{(() => {
                            switch (view) {
                                case "assign": return <div >
                                    <ViewAssign />
                                </div>
                                case "copy": return <div>
                                    <ViewCopy />
                                </div>
                                case "delete": return <div>
                                    <ViewDelete />
                                </div>
                                case "rename": return <div>
                                    {renamememo}
                                </div>
                                default: return <div style={{ marginLeft: '40px' }}> Please select an above option</div>;
                            }
                        })()}</div>

                        <div className="ndt_innerbox" style={{ height: "150px", overflow: "hidden" }}>
                            <div style={{ fontSize: '17px' }}>Query Notes:</div>
                            <div>{selquery?.Notes}</div>
                        </div>
                    </div>
                    : <div style={{ marginLeft: '50px' }}>No Model Selected</div>}

                <div id="gm_dashbox" className="ndt_gridbox">
                    <div style={{ fontSize: "22px" }}>Dashboard</div>
                    <GenericDropdown
                        data={dashboards}
                        keycol="Key"
                        namecol="Name"
                        className="ndt_dropdown"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, dashboards, setSelDashboard, "", DashboardInit[0]) }}
                        promptstring="Select Dashboard"
                        includeDefault={true}
                        defaultstring="All Unassigned"
                        filterfield={"SchemaName"}
                        filtervalue={keyparams.selschema}
                    />
                    {selDashboard.visuals?.map((qv: QueryVisuals, i: number) => {
                        return (<div key={i}>{GetObjectField(qv.ModelName, querymodels, "ModelKey", "ModelName")}: {qv.VisName} - {qv.ChartType}</div>)
                    })}
                </div>
            </div>

            {/* delete */}
            <Modal show={keyparams.isdeletevisible}>
                <div className='ndt_modal'>
                    <div className='ndt_modaltitle'>Delete {keyparams.modeltype} {keyparams.modelname}</div>
                    <button className='ndt_btn2' onClick={_ => { ClickDelete() }}>Delete</button>
                    <div id="qm_deletediv"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            {/* rename */}
            <Modal show={keyparams.isrenamevisible}>
                <div className='ndt_modal'>
                    <div>Rename {keyparams.modeltype} to: {keyparams.rename}</div>
                    <button className='ndt_btn1' onClick={_ => { ClickRename() }}>Rename</button>
                    <div id="gmgr_divren"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isrenamevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            {/* duplicate */}
            <Modal show={keyparams.isduplicatevisible}>
                <div className='ndt_modal'>
                    <div>Duplicate {keyparams.modeltype} {keyparams.modelname}:</div>
                    <button className='ndt_btn1' onClick={_ => { ClickDuplicate() }}>Duplicate</button>
                    <div id="qm_dupdiv"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isduplicatevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>

        </div>
    )
};
export default GraphManager