import { ChangeEvent } from "react";


export const ChangeParamSelectorbyIndex = (e: ChangeEvent<HTMLSelectElement>, data: any[], param: any,
    setfunction: any, paramfield: string, datafield: string, returndefault: any = "", set: boolean = true) => {
    //does not trigger useeffect in parent component, change set to false and return value

    let val = +e.target.selectedOptions[0].attributes[0].value
    let returnval = returndefault
    if (val >= 0) {
        if (datafield !== "") {
            returnval = data[val][datafield]
            if (set) {
                setfunction({ ...param, [paramfield]: data[val][datafield] })
            }
        } else {
            returnval = data[val]
            if (set) {
                setfunction({ ...param, [paramfield]: data[val] })
            }
        }
    } else if (val === -1) {
        if (set) {
            setfunction({ ...param, [paramfield]: returndefault })
        }
    }
    // else {
    //     setfunction({ ...param, [paramfield]: returnnotfound })
    // }

    return (returnval) //not really the purpose but return the matched data value

};
export default ChangeParamSelectorbyIndex;