import * as THREE from 'three';
import { useEffect } from 'react';
import {OrbitControls} from "three/examples/js/controls/OrbitControls"
//import {OrbitControls} from "./js/OrbitControls"
import fragmentShaderPlus from './shaders/fragmentShaderPlus';
//import fragshaderSimpleCircle from './shaders/fragshaderSimpleCircle';
//import fragshaderFlagellum from './shaders/fragshaderFlagellum';
//import fragmentKIFS from './shaders/fragmentKIFS';


const Threetest = () => {
	let counter = 1;
	let testval = 1;	
	let uniforms;
	let w = 700;
	let h = 700;
	let mesh
	let scene = new THREE.Scene();
	let camera = new THREE.PerspectiveCamera( 75, w/h, 0.1, 1000 );
	let renderer = new THREE.WebGLRenderer();
	let controls = new THREE.OrbitControls(camera, renderer.domElement);	

	renderer.setSize( w, h);

	const drawfunc = () => {

		scene.remove(mesh)
		uniforms = {
			res: {type: 'vec2', value: new THREE.Vector2(window.innerWidth, window.innerHeight)},
			aspect: {type: 'float', value: 1},
			zoom: {type:'float', value: .12},
			testval: {type:'float',value: testval},
			pset1: {type:'vec3', value: new THREE.Vector3(1,-.1,-.7)},
			pset2: {type:'vec3', value: new THREE.Vector3(0,-1,-.6)},
			iTime: {type:'float',value: testval},
		  };
		  let geometry1 = new THREE.PlaneBufferGeometry(4, 4);
		  let material1 = new THREE.ShaderMaterial({
			uniforms: uniforms,
			vertexShader: vShader,
			fragmentShader: fragmentShaderPlus(),
		  });
		 mesh = new THREE.Mesh(geometry1, material1) 
		 scene.add(mesh);
	  }



	const mouseWheel = (e) => {
		let delta = e.wheelDelta;
		delta = delta / 240;
		delta = -delta;
		if (delta <= 0) {
			delta -= camera.position.z * 0.1;
		} else {
			delta += camera.position.z * 0.1;
		}
		if (camera.position.z + delta > 1 && camera.position.z + delta < 200) {
			camera.translateZ(delta);
		}
	};

	useEffect(() => {
		
		document.getElementById("canvas1").appendChild(renderer.domElement);
		
		camera.position.z = 2.5

		//iterative
		let animate = function () {
			//counter += .01
			testval = Math.sin(counter) * 1 + 2
			drawfunc()

			requestAnimationFrame( animate );
			renderer.render( scene, camera );
		};

		animate();
	  }, []);


	  return (
		<div id="canvas1" style={{'height':'600px','width':'700px'}}>
		</div>
	  );
	}

export default Threetest

const vShader = `
  varying vec2 v_uv;
  void main() {
     v_uv = uv;
     gl_Position = projectionMatrix * modelViewMatrix *    vec4(position, 1.0);
}`
