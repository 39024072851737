
    function ChartGetColor(d, elem_id, colorScale, metricno, groups, groupfield, colorgradienttype, vis_state, stateIdx){
        //console.log(metricno)

        if (vis_state[stateIdx].selEncKey===elem_id){
            return '#000000'
        } else {
            if (groupfield){
                //console.log(d[groupfield], groups.indexOf(d[groupfield]), colorScale(groups.indexOf(d[groupfield])))
                return colorScale(groups.indexOf(d[groupfield])) 
            } else { 
                if (colorgradienttype==="Gradient"){
                    return "url(#myGradient" + String(metricno) + ")"
                } else {
                    //why is doing a console.log changing the colorscale range?
                    // console.log(metricno - 1, colorScale(+metricno - 2))
                    // console.log(metricno, colorScale(+metricno))
                    // console.log(metricno+1, colorScale(+metricno+1))
                    return colorScale(+metricno)
                }
            } 
        }
    };
    export default ChartGetColor;

