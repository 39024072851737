import * as d3 from 'd3';


function TableSample(data,svgid) {
    //ref: dashboardview

    // Create the table element
    let table = d3.select(svgid)
                    .append('table');
    
    // Create the table header
    let thead = table.append("thead");
    let tbody = table.append('tbody');

    let columns = Object.keys(data[0])

    thead.append("tr")
        .selectAll("th")
        .data(columns)
        .enter()
        .append("th")
        .style("border","1px solid grey")
        .text(function(d) { return d; });
    
    // Create the table body
    let rows = tbody.selectAll("tr")
        .data(data)
        .enter()
        .append("tr");
    
    // Create cells for each row
    let cells = rows.selectAll("td")
        .data(function(row) {
            return columns.map(function(column) {
                return {column: column, value: row[column]};
            });
        })
        .enter()
        .append("td")
        .attr("class",(_,i)=>{return "tblcell_" + String(i)})
        .attr("width",(_,i)=>{if (i===0){return '200px'} else if(i===1){return '150px'} else {return '70px'}})
        .style("border","1px solid grey")
        .text(function(d) { return d.value; });
}


export default TableSample;