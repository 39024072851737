import UniqueArrayValues from "../functions/UniqueArrayValues";
import { ColorModel, ColorSet, ColorValue, QueryColor, QueryModel, QueryVisuals } from "../type"


const AssignColorScheme = (groupfieldname: any, qry: QueryModel, qryvis: QueryVisuals, colormdl: ColorModel | null, simpledata: any[]) => {

    let colors: any[] = []

    if (qryvis.ColorModelName !== null && qryvis.ColorModelName !== "") {
        colormdl?.colorsets.forEach((cset: ColorSet) => {
            if (cset.FieldName === groupfieldname) {
                colors = ApplyColorSet(cset, simpledata)
            }
        })
    } else {
        //default colors on model
        if (qry.colors.length > 0) {
            colors = qry.colors.map((qc: QueryColor) => {
                return [qc.Color, qc.SecondaryColor]
            })
        }
    }
    if (colors.length === 0) {
        colors = [["black", ""]]
    }

    return (colors)
};

export default AssignColorScheme;


const ApplyColorSet = (cset: ColorSet, simpledata: any[]) => {
    //replicate the unique grouping array of chartprimary
    let groupsunique: string[] = UniqueArrayValues(simpledata, "Grouping")
    let colors: string[][] = [];

    groupsunique.forEach((grp: string) => {
        cset.colorvalues.map((cv: ColorValue) => {
            if (grp === cv.ValueName) {
                colors.push([cv.Color, ""])
            }
        })
    });

    if (groupsunique.length - colors.length > 0) {
        Array.apply(null, Array(groupsunique.length - colors.length)).forEach(_ => {
            colors.push(["#000000", ""])
        })
    }

    return colors;
}