
function GetTextColor(color) {

    if (color==="white" || color===""){
        return "black"
    } else if (color==="black"){
        return "white"
    }
    try {
        const match = color.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i); 

        const r = parseInt(match[1], 16);
        const g = parseInt(match[2], 16);
        const b = parseInt(match[3], 16);

        const sRGB = [r / 255, g / 255, b / 255];
        const RGB = sRGB.map((value) => {
            return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
        });
        const luminance = 0.2126 * RGB[0] + 0.7152 * RGB[1] + 0.0722 * RGB[2];

            if (luminance <=.5){
                return("white") 
            } else {
                return("black")
            }
    } catch(_){
        return("black")
    }
}
export default GetTextColor;

