import "./css/DataTable.css"
import { useState } from "react";


const DataTable = ({ data, num }: any) => {

    let [page, setPage] = useState<number>(0)
    let maxnum: number = Math.round(data.length / num - .5)


    const TableEnumHead = () => {
        return (
            <tr>
                {Object.keys(data[0]).map((k: string, i: number) => {
                    return (
                        <td key={i} className="datatbl_header">
                            {k}
                        </td>
                    )
                })}
            </tr>
        )
    }

    const Increment = (dir: string) => {
        if (dir === "up" && page < maxnum) {
            setPage(page => page + 1)
        } else if (dir === "down" && page > 0) {
            setPage(page => page - 1)
        }
    }

    const DTPagination = () => {
        return (
            <div id="datatbl_pagination">
                <button id="datatbl_incDown" className="datatbl_paginatebtn" onClick={_ => { Increment("down") }}> Prev </button>
                <div id="datatbl_paginatePg"> {page} </div>
                <button id="datatbl_incUp" className="datatbl_paginatebtn" onClick={_ => { Increment("up") }}> Next </button>
            </div>
        )
    }

    const TableEnumBody = () => {
        return (
            <tbody id="datatable_body">
                {data.map((drow: any, i: number) => {
                    if (i >= page * num && i < num * (page + 1)) {
                        return (
                            <tr key={"r" + i}>
                                {Object.keys(drow).map((d: any, k: number) => {
                                    return (<td key={"d" + k} className="datatbl_td" > {drow[d]} </td>)
                                })}
                            </tr>
                        )
                    } else if (page === maxnum && i < page) { //fill out last page
                        return (
                            <tr key={"r" + i}>
                                {Object.keys(drow).map((d: any, k: number) => {
                                    return (<td key={"d" + k} className="datatbl_td" > </td>)
                                })}
                            </tr>
                        )
                    }
                }
                )
                }
            </tbody>
        )
    }



    return (<div>
        {data.length > 0 ?
            <div>
                <table id="datatbl" style={{ 'height': String(num * 33) + "px" }}>
                    <thead>
                        <TableEnumHead />
                    </thead>
                    <TableEnumBody />
                </table>
                <DTPagination />
            </div>
            : <div></div>}
    </div>)
};

export default DataTable;
