//sorts descending
function EmpSort(Arry,val,asc_desc="Ascending"){
    if (Arry){
    let aa = [...Arry]
        if (aa.length > 0){
            aa.sort((a, b) => {
                let da = new Date(a[val]),
                    db = new Date(b[val]);

                if (asc_desc==="Descending"){
                    return (db - da);
                } else {
                    return(da - db);
                }
                
            });
            return(aa)
        } else{
            return(Arry)
        }
    }
}

export default EmpSort;