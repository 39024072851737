import { useState } from "react"
//const { Configuration, OpenAIApi } = require("openai");
import axios from "axios";
//import { Configuration, OpenAIApi } from "openai";

const ChatbotApp = () => {
    // const configuration = new Configuration({
    //     apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    // });


    //const openai = new OpenAIApi(configuration);
    const [prompt, setPrompt] = useState("");
    const [apiResponse, setApiResponse] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        // try {
        //     const result = await openai.createCompletion({
        //         model: "text-davinci-003",
        //         prompt: prompt,
        //         temperature: 0.5,
        //         max_tokens: 4000,
        //     });
        //     //console.log("response", result.data.choices[0].text);
        //     setApiResponse(result.data.choices[0].text);
        // } catch (e) {
        //     //console.log(e);
        //     setApiResponse("Something is going wrong, Please try again.");
        // }
        setLoading(false);
    };

    const generateResponse = async (e: any) => {
        e.preventDefault()
        try {
            const response = await axios.post(
                'https://api.openai.com/v1/engines/davinci-codex/completions',
                {
                    prompt: prompt,
                    max_tokens: 100,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                    },
                }
            );
            console.log(response)
            //setApiResponse(response.data.choices[0].text);
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: '100vh',
            }}
        >
            <form onSubmit={generateResponse}>
                <textarea
                    value={prompt}
                    placeholder="Please ask to openai"
                    onChange={(e) => setPrompt(e.target.value)}
                ></textarea>
                <button
                    disabled={loading || prompt.length === 0}
                    type="submit"
                >
                    {loading ? "Generating..." : "Generate"}
                </button>
            </form>
            <div>{apiResponse}</div>
        </div>

    );
};


export default ChatbotApp;