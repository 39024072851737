import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DivStep, Pagemap, Schema, User } from '../type';
import { NavDropdown } from 'react-bootstrap';
import "./css/navbar.css";
//import SchemaNav from './data/schema/SchemaNav';
import { useState, useContext } from 'react';
import GetTextColor from '../functions/GetTextColor';
import { otherActionCreator } from '../reducers/other_actions';
import TutorialPageMap from '../context/TutorialPageMap';
import IsInVector from '../functions/IsInVector';

const Navbar = () => {

	//console.log(specialstyling)
	//const { dispatcher } = useContext(StartupContext)
	let dispatcher = useDispatch()

	const schema: Schema = useSelector((state: any) => state.selectedSchema);

	const userstate: User = useSelector((state: any) => state.user);
	const [homelinkState, setHomeLinkState] = useState<any>({ color: "white", fontWeight: "", borderBottom: "" });
	const [keyparams, setKeyParams] = useState<any>({ tutorialenabled: false })
	let linearbckgd = String('linear-gradient(70deg, #110b1a 40%, ' + schema.BaseColor + ' 100%)')
	let textcolor = GetTextColor(schema.BaseColor);
	let othervalues = useSelector((state: any) => state.othervalues)

	let navbarstyle = { 'background': linearbckgd, 'ZIndex': '1', 'height': '50px', }
	let navddownstyle = { 'color': "black" }
	let linkstyle = { 'color': "white", 'background': "#111111", 'width': '160px' }
	let colorstyle = { color: textcolor }
	let tutOn = { background: "yellow", color: "black" }
	let tutOff = { background: "" }

	const defaultschema = useSelector((state: any) => state.selectedSchema)
	const defaultworkspace = useSelector((state: any) => state.selectedWorkspace)
	const pagemap: Pagemap[] = TutorialPageMap()

	const guestLinks = () => {
		return (

			<div className="guestlink" style={{ color: "white" }}>
				<NavDropdown title="Account" id="basic-nav-dropdown3" style={{ color: "white" }}>
					<Link className="nav-link active" id="guestlink1" aria-current="page" to="/login" style={linkstyle} >Log In</Link>

					<div >
						<Link className="nav-link active" id="guestlink2" aria-current="page" to="/signup" style={linkstyle} >Sign Up</Link>
					</div>
				</NavDropdown>
			</div>
		)
	};

	const authLinks = () => {
		return (
			<div style={{ color: textcolor, display: "flex" }}>
				<i style={{ marginTop: "15px" }} className="fa fa-user fa-lg"></i>
				{userstate.email !== "guest@guest.com" ?
					<NavDropdown title={userstate.email.substring(0, 15)} id="basic-nav-dropdown3"
						style={{ fontSize: '16px', color: "black" }}>
						<div className="nav-item" >
							<Link className="nav-link active" aria-current="page" to="/logout" >Logout</Link>
						</div>
						<div className="nav-item">
							<Link className="nav-link active" aria-current="page" to="/users/mywork" >My Page</Link>
						</div>
						<div className="nav-item">
							<Link className="nav-link active" aria-current="page" to="/status" >Status Checker</Link>
						</div>
						<div className="nav-item">
							<Link className="nav-link active" aria-current="page" to="/dashboard/permissions">Manage Permissions</Link>
						</div>
						{userstate.level === "Admin" ?
							<div className="nav-item">
								<Link className="nav-link active" aria-current="page" to="/adminoptions">Admin Options</Link>
							</div> : <div></div>}
						<div className="nav-item">
							<Link className="nav-link active" aria-current="page" to="/leader/view/" >Leader View</Link>
						</div>
						<div className="nav-item">
							<Link className="nav-link active" aria-current="page" to="/tracker" >Tracker</Link>
						</div>
					</NavDropdown>
					: <NavDropdown title={userstate.email.substring(0, 15)} id="basic-nav-dropdown3"
						style={{ fontSize: '16px', color: "black" }}>
						<div className="nav-item">
							<Link className="nav-link active" aria-current="page" to="/status" >Status Checker</Link>
						</div>
						<div className="nav-item" >
							<Link className="nav-link active" aria-current="page" to="/logout" >Logout</Link>
						</div>
					</NavDropdown>}
			</div>
		)
	};
	const homehover = () => {
		setHomeLinkState({ ...homelinkState, "color": "violet", "fontWeight": "bold", "borderBottom": "" })
	};
	const homemouseout = () => {
		setHomeLinkState({ ...homelinkState, "color": "white", "fontWeight": "", "borderBottom": "" })
	};

	const ClickTutorial = () => {
		pagemap.forEach((pm: Pagemap) => {
			pm.Detail.Steps.forEach((ds: DivStep) => {
				let elem = document.getElementById(ds.Div)
				if (elem) {
					if (IsInVector(elem.classList, "seldivtutorial")) {
						elem.classList.toggle("seldivtutorial")
					}
				}
			})
		})
		let newothervalues = { ...othervalues, tutorial: !othervalues.tutorial }
		dispatcher(otherActionCreator(newothervalues))
	}

	return (
		<nav className="navbar navbar-expand-sm navbar-light bg-light0" style={navbarstyle} id="navbar1">

			<div className="container-fluid">

				<button
					className="navbar-toggler"
					type="button"
					data-mdb-toggle="collapse"
					data-mdb-target="#navbarNav"
					aria-controls="navbarNav"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<i className="fas fa-bars"></i>
				</button>

				<div style={{ width: "100%" }}>
					<ul className="navbar-nav">
						<div id="navgrid">
							<div style={{ display: "flex" }}>


								<Link className="nav-link active" aria-current="page" to="/home" style={homelinkState}
									onMouseEnter={homehover}
									onMouseLeave={homemouseout}>Home</Link>


								{schema.SchemaKey !== "" ?
									<div id="navbar_menubarX" style={{ display: "flex" }}>
										{/* Employee */}

										<NavDropdown title="Employee" id="basic-nav-dropdown1" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/upload" style={linkstyle}>Upload</Link>
											</li>

											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/modelviewer" style={linkstyle}>Empl Model Viewer</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/table" style={linkstyle}>Empl Table</Link>
											</li>

											{/* 
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/viewer" style={linkstyle}>Viewer</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/ataglance" style={linkstyle}>At A Glance</Link>
											</li>
											*/}
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/detail" style={linkstyle}>Example Custom View</Link>
											</li>

										</NavDropdown>

										{/* Sets */}
										<NavDropdown title="Sets" id="basic-nav-dropdown2" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/rulesets/manager" style={linkstyle}>Manage Rulesets</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/filtersets/manager" style={linkstyle}>Manage Filtersets</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/groupsets/manager" style={linkstyle}>Manage Grouped Fields</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/colorsets/manager" style={linkstyle}>Manage Color Models</Link>
											</li>

										</NavDropdown>

										{/* Other Data */}
										<NavDropdown title="Tables" id="basic-nav-dropdown3" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/fields/viewer" style={linkstyle}>Fields</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/tables/manager" style={linkstyle}>Table Manager</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/datamanager/department" style={linkstyle}>Departments</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/datamanager/jobtitle" style={linkstyle}>Job Titles</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/datamanager/position" style={linkstyle}>Positions</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/tables/connections" style={linkstyle}>Table Connections</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/tables/data" style={linkstyle}>Data Viewer</Link>
											</li>
										</NavDropdown>


										{/* Queries */}
										<NavDropdown title="Queries" id="basic-nav-dropdown2" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/query/creategraph" style={linkstyle}>Create Query</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/query/managegraph" style={linkstyle}>Manage Queries</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/metrics/manager" style={linkstyle}>Metrics</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/kpis/manager" style={linkstyle}>KPI Manager</Link>
											</li>

											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/schedules/manager" style={linkstyle}>Schedules</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/benchmark/manager" style={linkstyle}>Benchmark Manager</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/ai/query" style={linkstyle}>AI Query Create</Link>
											</li>
										</NavDropdown>



										{/* Dashboards */}
										<NavDropdown title="Dashboards" id="basic-nav-dropdown2" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/dashboard/manager" style={linkstyle}>Dashboard Manager</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/dashboard/select" style={linkstyle}>Dashboard Select</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/kpis/stringmanager" style={linkstyle}>KPI Strings</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/leader/manager" style={linkstyle}>Leader Manager</Link>
											</li>

										</NavDropdown>

										<NavDropdown title="Job Skills" id="basic-nav-dropdown2" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/jobdescription/manager" style={linkstyle}>Manage Job Desc</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/jobdescription/netmodel" style={linkstyle}>JD Model Viewer</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/jobdescription/" style={linkstyle}>Skills Viewer</Link>
											</li>
											{/* <li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/jobdescription/categorymodelai" style={linkstyle}>CategoryAI Model</Link>
											</li> */}
										</NavDropdown>

										{/* More Complex Functions*/}
										<NavDropdown title="Analytics" id="basic-nav-dropdown2" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/analytics/simulation" style={linkstyle}>Simulate Employees</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/jobposting/viewer" style={linkstyle}>Job Applications</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/sna/data" style={linkstyle}>Manage SNA Data</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/sna/view" style={linkstyle}>SNA Graph Viewer</Link>
											</li>

											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/ai/model" style={linkstyle}>AI Model Manager</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/scenario/manager" style={linkstyle}>Scenario Builder</Link>
											</li>

										</NavDropdown>


										{/* Tracker 
										<NavDropdown title="Data Tracking" id="basic-nav-dropdown3" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/tracker" style={linkstyle}>Tracker</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/setstracker" style={linkstyle}>Sets Tracker</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/valuetracker" style={linkstyle}>Value Tracker</Link>
											</li>
										</NavDropdown>
										*/}


										{/* Testing 
										<NavDropdown title="Testing" id="basic-nav-dropdown2" >

											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/three" style={linkstyle}>ThreeTest</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/three/particles" style={linkstyle}>Particles</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/three/bargraph" style={linkstyle}>Bar Graph</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/deptview" style={linkstyle}>Dept Viewer</Link>
											</li>

											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/depttree" style={linkstyle}>Dept Tree</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/employee/audit" style={linkstyle}>Audit</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/ai/" style={linkstyle}>AI</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/staffing/manager" style={linkstyle}>Staffing</Link>
											</li>
										</NavDropdown>
										*/}

										{/* Tracker */}
										<NavDropdown title="Info" id="basic-nav-dropdown2" >
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/help" style={linkstyle}>I know nothing</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/status" style={linkstyle}>Status Checker</Link>
											</li>

											<li className="nav-item">
												<Link className="nav-link active" aria-current="page" to="/walkthrough" style={linkstyle}>Example Data Walkthrough</Link>
											</li>
											<li className="nav-item">
												<div className="nav-link active" aria-current="page" style={{ ...linkstyle, cursor: "pointer" }}
													onClick={_ => { setKeyParams({ ...keyparams, tutorialenabled: !keyparams.tutorialenabled }) }}>{keyparams.tutorialenabled ? "Disable" : "Enable"} Page Tutorial</div>
											</li>
										</NavDropdown>

										{keyparams.tutorialenabled ?
											<div className="nav-link active" aria-current="page" id="nav_tutorialdiv" style={othervalues.tutorial ? tutOn : tutOff} onClick={_ => { ClickTutorial() }}>Tutorial</div>
											: <div></div>
										}
									</div>
									: <div></div>}
							</div>

							<div style={{ display: 'flex' }}>
								<div id="navschema" style={{ width: "20%", minWidth: "250px" }}>
									{userstate.isAuthenticated ?
										<div>
											{defaultschema.SchemaKey !== "" ?
												<div id="navschema_curr" >
													<Link id="schemalinknav" className="nav-link active link1" aria-current="page" to="/schema" style={{ color: textcolor }}> Env: {defaultschema.SchemaName} {defaultworkspace.WorkspaceName}</Link>
												</div>
												: <div style={{ backgroundColor: 'rgb(200,30,30)', border: '1px solid white' }}>
													<Link id="schemalinknav" className="nav-link active link1" aria-current="page" to="/schema" >
														No Env Selected!
													</Link>
												</div>}
										</div>
										: <div></div>}
								</div>

								<div style={{ float: "right", width: "50px" }}>{userstate.isAuthenticated ? authLinks() : guestLinks()}</div>
							</div>
						</div>

					</ul>
				</div>

			</div >
		</nav >
	)
}
export default Navbar;