import { QueryModel, QueryRunDate, BasicQuery } from "../../type";
import DateSort from "../DateSort"
import FilterData from "../FilterData";


const RunDateQueryParse = (query: QueryModel, rundatekey: string): BasicQuery[][] => {
    //ref: threedbargraph_prepare
    let sorteddata: BasicQuery[] = [];
    let simpledata: BasicQuery[] = [];
    let detaildata: BasicQuery[] = [];

    query.rundates.forEach((qrd: QueryRunDate) => {
        if (qrd.DateKey === rundatekey) {
            sorteddata = DateSort(qrd.basicquery, "DateVar")

            simpledata = FilterData(sorteddata, "Complexity", "Simple")
            detaildata = FilterData(sorteddata, "Complexity", "Complex")
        }
    });

    return ([simpledata, detaildata])
};
export default RunDateQueryParse;