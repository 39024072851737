
const ReportTypeDropdown = (props: any) => {

    let change = props.change
    let className = props.className

    return (
        <select className={className} onChange={change}>
            <option id="" value="">Select Report Category</option>
            <option id="Demographic" value="Demographic">Demographic</option>
            <option id="Hire" value="Hire">Hires</option>
            <option id="Term" value="Term">Terminations</option>
            <option id="Transfer" value="Transfer">Transfers</option>
            <option id="Promo" value="Promo" >Promotions</option>
        </select>
    )
};
export default ReportTypeDropdown;