import { ChangeEvent, useEffect, useState, useContext } from "react";
import { StartupContext } from "../../../App";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import SetTextAreaText from "../../../functions/SetTextAreaText";
import { QueryModel, Metric, FieldMap } from "../../../type";
import { MetricInit, QueryModelInit } from "../../../InitTypes";
import GetObjectValue from "../../../functions/Object Functions/GetObjectValue";
import FieldsImport from "../../../data/Fields/Fields_Import";
import GetFieldName from "../../../functions/GetFieldName";
import GenericDropdown from "../../../functions/Dropdown_Generic";


const AIQueryPrompt = () => {


    const { config, dispatcher, schema, user, paths, clickstyle } = useContext(StartupContext)
    let [keyparams, setKeyParams] = useState<{ text: string }>({ text: '' })
    let [selQuery, setSelQuery] = useState<QueryModel | any>(QueryModelInit[0])
    let [selMetric, setSelMetric] = useState<Metric | any>(MetricInit[0])
    let [fields, setFields] = useState<FieldMap[]>([])

    let examplestrs = [
        "Count of employee ID by department name, grouped by Job Function beginning 6/6/2024, monthly, for 6 months.",
        "Sum of Salary by Job Function between 1/1/2024 and 4/1/2024, weekly."
    ]
    const qryfields = [
        ["X Field", "XFieldName", 'Field', ""],
        ["Grouping Field", "GroupingFieldName", 'Field', ""],
        ["Period", "Period", 'Text', ""],
        ["Period Length", "IPeriod", 'Number', ""],
        ["Num Periods", "NPeriods", 'Number', ""],
        ["Filter Field", "SimpleFilterField", 'Field', ""],
        ["Filter Values", "SimpleFilterValues", 'Text', ""],
        ["As of Date", "Notes", 'Text', ""]
    ]

    const metrfields = [
        ["Measurement", "Measurement", 'Text', ""],
        ["MeasurementField", "MeasurementField", 'Field', ""],
        ["Filter Field", "SimpleFilterField", 'Field', ""],
        ["Filter Values", "SimpleFilterValues", 'Text', ""],

    ]

    useEffect(() => {
        FieldsImport("", schema, config, dispatcher, false).then(d => { setFields(d) })
    }, [])

    const ClickSubmitAsk = () => {
        GenericBackendCall("", schema, paths.ai.aipostsingle, user, config,
            { prompt: keyparams.text, type: "query" }, "aiq_divask", "Check the output").then(d => {
                console.log(d)
                if (d.length === 1) {
                    let qry: QueryModel = d[0] //query with metric, serializer depth=1
                    console.log(qry)
                    setSelQuery(qry)
                    if (qry.Metrics.length === 1) {
                        let metr: any = qry.Metrics[0]
                        setSelMetric(metr)
                    }

                }
            })
    }

    const ReturnMapping = (props: any) => {

        let model = props.model
        let setmodel = props.setmodel
        let fieldlist = props.fieldlist

        return (
            <div>
                {fieldlist.map((lst: string[], i: number) => {
                    let val = GetObjectValue(model, lst[1])
                    if (lst[2] === "Field") {
                        val = GetFieldName(fields, val, "Key", "FieldName")
                    }
                    return (<div key={i} style={{ display: 'flex' }}>
                        <div style={{ width: "150px" }}>{lst[0]}:</div>

                        {(() => {
                            switch (lst[2]) {
                                case "Text": return <div>
                                    <input type="text" placeholder={val}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setmodel({ ...model, [lst[1]]: e.target.value })} />
                                </div>
                                case "Number": return <div>
                                    <input type="number" placeholder={val}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setmodel({ ...model, [lst[1]]: e.target.value })} />
                                </div>
                                case "Field": return <div style={{ display: "flex" }}>
                                    <GenericDropdown
                                        data={fields}
                                        keycol="Key"
                                        namecol="FieldName"
                                        change={(e: ChangeEvent<HTMLSelectElement>) => { setmodel({ ...model, [lst[1]]: e.target.selectedOptions[0].attributes[0].value }) }}
                                        promptstring="Select Field"
                                        className="ndt_dropdown aip_flddown"
                                        value={GetObjectValue(model, lst[1])}
                                    /><div>{GetObjectValue(model, lst[1])}</div>
                                </div>
                                default: return <div></div>

                            }
                        })()}
                    </div>)
                })}
            </div>
        )
    }
    console.log(selMetric)
    console.log(selQuery)

    return (<div className="ndt_canvas">
        <div className="ndt_header">

        </div>

        <div>
            <div>Example Promps</div>
            {examplestrs.map((str: string, i: number) => {
                return (<div key={i} style={{ background: "darkblue", border: '1px solid white', margin: '2px', cursor: "pointer" }}
                    onClick={_ => { SetTextAreaText("aiq_textarea", str) }}>{str}</div>)
            })}

        </div>
        <div className="ndt_gridbox">
            <textarea id="aiq_textarea" style={{ width: "1000px", height: '50px' }}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setKeyParams({ ...keyparams, text: e.target.value }) }} />
            <button className="ndt_btn1" style={{ margin: "3px" }} onClick={_ => { ClickSubmitAsk() }}>Ask</button>
            <div id="aiq_divask"></div>
        </div>

        <div>
            <div style={{ margin: '10px' }}>
                <div style={{ fontSize: "20px" }}>Query</div>
                <ReturnMapping fieldlist={qryfields} model={selQuery} />
            </div>
            <div style={{ margin: '10px' }}>
                <div style={{ fontSize: "20px" }}>Metric</div>
                <ReturnMapping fieldlist={metrfields} model={selMetric} />
            </div>
        </div>
    </div>)
};
export default AIQueryPrompt;