import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { useContext, useState, useEffect, ChangeEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StartupContext } from "../../../App";
import { AIModel, AIMessage, ChatAI, MessageBranch, FieldMap, Metric, BasicQuery, KPIValue, JobPosting, Applicant, JobPostingRequirement } from "../../../type";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import { Modal } from "react-bootstrap";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import "./css/AIModelManager.css"
import { useParams } from 'react-router-dom';
import SetTextAreaText from "../../../functions/SetTextAreaText";
import FieldsImport from "../../../data/Fields/Fields_Import";
import ImportAndSetState from "../../../functions/Import/ImportAndSetState";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import GetFieldName from "../../../functions/GetFieldName";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import ConvertBackendArryString from "../../../functions/String Functions/ConvertBackendArryString";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import ChartSample from "../../../charts/sample/ChartSample";
import GetRandomHexadecimal from "../../../functions/GetRandomHexadecimal";
import FilterData from "../../../functions/FilterData";
import CheckMetricFormat from "../../../functions/QueryFunctions/CheckMetricFormat";
import { MetricInit } from "../../../InitTypes";
import StringTrim from "../../../functions/String Functions/StringTrim";

const AIModelManager = () => {

    const { config, dispatcher, schema, user, paths, clickstyle } = useContext(StartupContext)
    let { id } = useParams()
    let history = useNavigate();

    interface paramtype {
        modelkey: string;
        category: string;
        branchkey: string;
        text: string;
        refreshStyle: any;
        selXitm: string;
        selGrpitm: string;
        msgtype: string;
        isdeletevisible: boolean;
        isuploadvisible: boolean;
        isupdatevisible: boolean;
        refresh: number;
        reload: boolean;
    }
    interface chartparamtype {
        format: string;
        rounding: number;
        xfieldname: string;
        grpfieldname: string;
        selmetric: number;
        lastN: number;
        charttype: string;
    }
    interface detailtype {
        Type: String; XField: string; GrpField: string; Metrics: string[]
    }

    let offStyle = { opacity: "50%" }
    let onStyle = { opacity: "100%", cursor: "pointer" }

    const [keyparams, setKeyParams] = useState<paramtype>({
        modelkey: '', category: '', text: '', msgtype: '',
        refreshStyle: offStyle, branchkey: '', selXitm: '', selGrpitm: '',
        isdeletevisible: false, isuploadvisible: false, isupdatevisible: false,
        refresh: 0, reload: true
    })
    const [chartparams, setChartParams] = useState<chartparamtype>({
        format: '', rounding: 0, xfieldname: '', grpfieldname: '', selmetric: 0, lastN: 40, charttype: "Bar"
    })

    interface cdata {
        id: number;
        xfield: any;
        grpfield: any;
        value: number;
        MetricNo: number;
    }
    const [AIModels, setAIModels] = useState<AIModel[]>([])
    const [selModel, setSelModel] = useState<AIModel | null>(null)
    const [selBranch, setSelBranch] = useState<MessageBranch | null>(null)
    const [uniquevals, setUniqueVals] = useState({ "XField": [], "GroupField": [] })
    const [fields, setFields] = useState<FieldMap[]>([])
    const [mdldetails, setMdlDetails] = useState<detailtype>({ Type: '', XField: '', GrpField: "", Metrics: [] })
    const [metrics, setMetrics] = useState<Metric[]>([])

    const [randcolors, setRandColors] = useState<string[]>([])
    const [chartdata, setChartData] = useState<cdata[]>([])
    const [usedata, setUseData] = useState<cdata[]>([])

    const [selJobPosting, setSelJobPosting] = useState<JobPosting | null>(null)
    const [applicants, setApplicants] = useState<Applicant[]>([])


    useEffect(() => {
        if (id) { setKeyParams({ ...keyparams, modelkey: id }) }
    }, [id])

    useEffect(() => {
        ImportAndSetState(FieldsImport, setFields, "*", schema, config, dispatcher, false);
        ImportAndSetStateGeneric(setMetrics, "*", schema, paths.query.metricview, user, config)
        setRandColors([1, 2, 3, 4, 5].map(_ => { return GetRandomHexadecimal(6) }))
    }, [])


    useEffect(() => {
        if (keyparams.refresh >= 1000) { window.location.reload() }
    }, [keyparams.refresh])


    useEffect(() => {
        if (keyparams.reload) {
            GenericBackendCall("", schema, paths.ai.aimodelview, user, config, { type: "name" }).then(d => {
                setAIModels(d);
                setKeyParams({ ...keyparams, reload: false })
            })
        }
    }, [keyparams.reload])

    useEffect(() => {
        if (keyparams.modelkey !== "") {
            GenericBackendCall(keyparams.modelkey, schema, paths.ai.aimodelview, user, config, { type: "branch" }).then(d => {
                if (d.length === 1) {
                    console.log(d[0])
                    setSelModel(d[0])
                    setSelBranch(null)
                    setKeyParams({ ...keyparams, modelkey: d[0].ModelKey, branchkey: '' })

                }
                try {
                    history("/ai/model/" + d[0].ModelKey)
                }
                catch (err) {
                    history("/ai/model/")
                }
            })
        } else {
            setSelModel(null)
            history("/ai/model/")
        }
    }, [keyparams.modelkey])

    useEffect(() => {
        if (keyparams.branchkey !== "") {
            GenericBackendCall(keyparams.branchkey, schema, paths.ai.aibranchview, user, config, { type: "user" }).then(d => {
                if (d.length === 1) {
                    setSelBranch(d[0])
                }
            })
        }
    }, [keyparams.branchkey])

    useEffect(() => {
        if (selModel && selModel.Category === "Query") {
            //import unique field values
            GenericBackendCall(selModel.CategoryModelKey, schema, paths.query.basicqueryuniquevalues, user, config, { fields: ["XField", "GroupField"] }).then(d => {
                if (d.length === 2) {
                    setUniqueVals({ "XField": d[0], "GroupField": d[1] })
                }
            })
            //import query model data
            GenericBackendCall(selModel.CategoryModelKey, schema, paths.generics.genericfilterview, user, config,
                { model: "QueryModel", filterfield: "ModelKey", filtervalue: selModel.CategoryModelKey }).then(d => {
                    let data = SerializeDataFrame(d)
                    if (data.length == 1) {
                        let qry = { ...data[0], Metrics: ConvertBackendArryString(data[0].MetricOrder) }
                        let metrs: string[] = qry.Metrics.map((m: string) => {
                            return GetObjectField(m, metrics, "MetricKey", "MetricName")
                        })
                        setMdlDetails({
                            Type: "Query",
                            XField: GetFieldName(fields, data[0].XFieldName),
                            GrpField: GetFieldName(fields, data[0].GroupFieldName),
                            Metrics: metrs
                        })
                        setChartParams({ ...chartparams, charttype: "Bar" })
                    }
                })
            //import basicquery primary for chart
            GenericBackendCall(selModel.CategoryModelKey, schema, paths.query.rundatesview, user, config, { how: 'primary' }).then(rd => {
                if (rd.length === 1) {
                    let data: cdata[] = []
                    rd[0].basicquery.forEach((bq: BasicQuery, i: number) => {
                        //if (chartparams.selmetric === bq.MetricNo) {
                        data.push({ id: i, xfield: bq.Variable, grpfield: bq.Grouping, value: bq.Value, MetricNo: bq.MetricNo })
                        //}
                    })
                    setChartData(data)
                }
            })

        }
        if (selModel && selModel.Category === "KPI") {
            GenericBackendCall("", schema, paths.kpi.kpiview, user, config, { full: true, modelkeys: [selModel.CategoryModelKey] }).then(kd => {

                if (kd.length === 1) {
                    let data: cdata[] = []
                    kd[0].values.forEach((kval: KPIValue, i: number) => {
                        data.push({ id: +i, xfield: kval.AsofDate, grpfield: "", value: +kval.Value, MetricNo: 0 })
                    })
                    setChartData(data)
                    let metric: Metric = MetricInit[0]
                    metrics.forEach((metr: Metric) => {
                        if (kd[0].MetricKey === metr.MetricKey) {
                            metric = metr
                        }
                    })
                    let fmt = CheckMetricFormat(metric)
                    setChartParams({ ...chartparams, format: fmt.format, rounding: fmt.rounding, charttype: "Line" })
                }
            })
        }
        if (selModel && selModel.Category === "JobPosting") {
            GenericBackendCall('', schema, paths.application.jobpostingview, user, config, { modelkeys: [selModel.CategoryModelKey] }).then(d => {
                if (d.length === 1) {
                    setSelJobPosting(d[0])
                }
            })
            GenericBackendCall('', schema, paths.application.applicantview, user, config, { jobpostingkey: selModel.CategoryModelKey }).then(d => {
                setApplicants(d)
            })
        }
    }, [selModel])

    useEffect(() => {
        if (keyparams.selXitm !== "") {
            SetTextAreaText("aim_textarea", keyparams.selXitm, true)
            setKeyParams({ ...keyparams, text: keyparams.text + " " + keyparams.selXitm })
        }
    }, [keyparams.selXitm])

    useEffect(() => {
        if (keyparams.selGrpitm !== "") {
            SetTextAreaText("aim_textarea", keyparams.selGrpitm)
            setKeyParams({ ...keyparams, text: keyparams.text + " " + keyparams.selGrpitm })
        }
    }, [keyparams.selGrpitm])

    //-----chart-------
    useEffect(() => {
        let metrdata: cdata[] = []
        if (selModel && selModel?.Category === "Query") {
            metrdata = FilterData(chartdata, "MetricNo", chartparams.selmetric)
        } else if (selModel && selModel?.Category === "KPI") {
            metrdata = chartdata
        }

        setUseData(metrdata.slice(-1 * chartparams.lastN))
    }, [chartdata, chartparams.lastN, chartparams.selmetric])

    useEffect(() => {
        if (usedata.length > 0 && selModel && chartparams.charttype !== "") {
            ChartSample(chartparams.charttype, "#aimgraph1x",
                usedata, [],
                ["xfield", ["value"], "grpfield"], [chartparams.xfieldname, chartparams.grpfieldname],
                "Continuous", randcolors,
                [800, 800], "On", false,
                { format: chartparams.format, rounding: chartparams.rounding, clr: "white", lineclr: "white", secondarylineclr: "blue" }, true
            )
        }
    }, [usedata])

    //-----------------------------------------
    const ClickSubmitAsk = () => {
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.ai.aiinterfacepost, user, config,
                { text: keyparams.text, branchkey: keyparams.branchkey, msgtype: keyparams.msgtype }).then(d => {
                    setSelBranch(d)
                })
        }
    }

    const ClickChatDelete = () => {
        GenericBackendCall(keyparams.branchkey, schema, paths.generics.genericdelete, user, config, { model: "MessageBranch", field: "BranchKey" }, "").then(_ => {
            setKeyParams({ ...keyparams, refresh: 1000 })
        })
    }

    const ClickSubmitNewChat = () => {
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.ai.aibranchpost, user, config, {}).then(d => {
                setKeyParams({ ...keyparams, refresh: 1000 })
            })
        }
    }

    const ClickDelete = () => {
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.generics.genericdelete, user, config, { field: "ModelKey", model: "AIModel" }, "aim_deldiv").then(_ => {
                history("/ai/model")
                setKeyParams({ ...keyparams, refresh: 999 })
            })
        }
    }

    const ClickUpdate = () => {
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.ai.aimodelrepost, user, config, {}, "aim_upddiv").then(_ => {
                setKeyParams({ ...keyparams, refresh: 999 })
            })
        }
    }

    const ClickDefault = () => {
        if (selModel) {
            GenericBackendCall(selModel.ModelKey, schema, paths.ai.aimodelview, user, config, { type: "branchdefault" }, "").then(d => {
                console.log(d)
            })
        }
    }

    return (<div className="ndt_canvas">
        <div className="ndt_header ndt_gridbox">
            <div className="ndt_title2">AI Integration Manager</div>
        </div>

        <div >
            <div style={{ display: "flex" }}>
                <div style={{ marginTop: '3px' }}>

                </div>
                {selModel ?
                    <div className="ndt_gridbox" id="aim_modelheader" style={{ display: 'flex', marginBottom: "10px", marginLeft: "30px", marginTop: '5px' }}>
                        <div style={{ fontSize: "20px", width: "400px", height: "70px" }}>
                            <div>Selected Model:</div> <div>{selModel.ModelName}</div>
                        </div>
                        <div style={{ marginLeft: "30px", marginTop: "2px", fontSize: "18px", width: "200px", height: "70px" }}>
                            <div>Model Category: </div><div>{selModel.Category}</div>
                        </div>

                        <button className="ndt_btn1" style={{ width: '150px', marginLeft: "15px", marginTop: "15px", marginRight: "5px", height: "30px" }} onClick={_ => { setKeyParams({ ...keyparams, modelkey: '' }); setSelBranch(null); setSelModel(null) }}>Back</button>
                        <button className="ndt_btn2" style={{ width: '150px', marginLeft: "15px", marginTop: "15px", marginRight: "5px", height: "30px" }} onClick={_ => { setKeyParams({ ...keyparams, isdeletevisible: true }) }}>Delete Model</button>
                        <button className="ndt_btn1" style={{ width: '150px', marginLeft: "15px", marginTop: "15px", marginRight: "5px", height: "30px" }} onClick={_ => { setKeyParams({ ...keyparams, isupdatevisible: true }) }}>Update Model</button>

                    </div> : <div id="aim_selblockBorder" style={{ marginLeft: "300px", marginTop: "100px" }}>
                        <div id="aim_selblock" >

                            <div>
                                <div className="ndt_title3">AI Chat Model Selection</div>
                                <select id="ai_mainddown" className="ndt_dropdown" style={{ width: "200px" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setKeyParams({ ...keyparams, category: e.target.selectedOptions[0].attributes[0].value }) }}>
                                    <option value="">Select Category</option>
                                    <option value="Company">Company</option>
                                    <option value="JobSkill">Job Skill</option>
                                    <option value="Query">Query</option>
                                    <option value="KPI">KPI</option>
                                    <option value="JobPosting">Job Posting</option>
                                </select>
                            </div>
                            <div>
                                {keyparams.category !== "" ?
                                    <GenericDropdown
                                        data={AIModels}
                                        keycol="ModelKey"
                                        namecol="ModelName"
                                        promptstring="Select AI Model"
                                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, AIModels, keyparams, setKeyParams, "modelkey", "ModelKey", "") }}
                                        className="ndt_dropdown"
                                        divID="aim_modelddown"
                                        filterfield={"Category"}
                                        filtervalue={keyparams.category}
                                    />
                                    : <div></div>}
                            </div> </div>
                    </div>}
            </div>
            {selModel ?
                <div style={{ display: "flex" }}>
                    <div className="ndt_innerbox" style={{ width: "1100px" }}>
                        <div style={{ display: "flex" }}>

                            <div style={{ marginBottom: "10px" }}>
                                <div style={{ fontSize: "18px" }}>{selModel.ExplanationText}</div>
                                <div style={{ display: "flex" }}>
                                    <GenericDropdown
                                        data={selModel.branches}
                                        keycol="BranchKey"
                                        namecol="BranchName"
                                        promptstring="Select Chat"
                                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, selModel.branches, keyparams, setKeyParams, "branchkey", "BranchKey", "") }}
                                        divID="aim_chatddown"
                                        className="ndt_dropdown"

                                    />

                                    <button id="aim_newchatbtn" className="ndt_btn3" style={{ marginLeft: "100px" }} onClick={_ => { ClickSubmitNewChat() }}> Create New Chat</button>
                                    {/* 
                                    {user.level === "Admin" ? <div><button className="ndt_btn1" onClick={_ => { ClickDefault() }}>Check Default</button></div> : <div></div>}
                                    */}
                                </div>
                            </div>
                            {mdldetails.Type === "Query" ?
                                <div style={{ marginLeft: "30px" }}>
                                    <div style={{ fontSize: "18px" }}>About</div>
                                    <div>XField: {mdldetails.XField}</div>
                                    <div>GrpField: {mdldetails.GrpField}</div>
                                    <div style={{ display: "flex" }}>Metrics: {mdldetails.Metrics.map((m: string, i: number) => {
                                        return (<div key={i} style={{ marginLeft: "5px" }}>{m}</div>)
                                    })}</div>

                                </div>
                                : <div></div>}
                        </div>
                        <div>
                            {selBranch && selModel.Category === "Query" ?
                                <div>
                                    <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                        setKeyParams({ ...keyparams, selXitm: e.target.selectedOptions[0].attributes[0].value })
                                    }}>
                                        <option value="">Add X Field Value (optional)</option>
                                        {uniquevals.XField.map((fld: string, i: number) => {
                                            return (<option key={i} value={fld}>{fld}</option>)
                                        })}
                                    </select>

                                    <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                        setKeyParams({ ...keyparams, selGrpitm: e.target.selectedOptions[0].attributes[0].value })
                                    }}>
                                        <option value="">Add Group Field Value(optional)</option>
                                        {uniquevals.GroupField.map((fld: string, i: number) => {
                                            return (<option key={i} value={fld}>{fld}</option>)
                                        })}
                                    </select>
                                </div>
                                : <div></div>}
                        </div>

                        {keyparams.branchkey !== "" ?
                            <div>
                                <div style={{ display: "flex" }}>
                                    <textarea id="aim_textarea" style={{ width: "700px", height: '50px' }}
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setKeyParams({ ...keyparams, text: e.target.value }) }} />
                                    <div style={{ marginLeft: "5px", border: "1px solid grey", padding: "5px", display: "flex" }}>
                                        <div style={{ margin: '4px' }}>Style: </div>
                                        <button className="ndt_btn1" style={keyparams.msgtype === "brief" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setKeyParams({ ...keyparams, msgtype: "brief" }) }}>Brief</button>
                                        <button className="ndt_btn1" style={keyparams.msgtype === "detail" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setKeyParams({ ...keyparams, msgtype: "detail" }) }}>Detail</button>
                                    </div>

                                </div>
                                <div>
                                    <button className="ndt_btn1" style={{ margin: "3px" }} onClick={_ => { ClickSubmitAsk() }}>Ask</button>
                                    <button className="ndt_btn2" style={{ margin: "3px" }} onClick={_ => { ClickChatDelete() }}>Delete</button>

                                    <button className="ndt_btn1" style={keyparams.refreshStyle}
                                        onClick={_ => { setKeyParams({ ...keyparams, refreshStyle: offStyle }) }}>Refresh</button>
                                </div>
                            </div> : <div></div>}
                        {selBranch ?
                            <div className="ndt_subinner" style={{ background: "black", height: '550px', overflowY: "scroll" }}>
                                {selBranch.messages.map((msg: AIMessage, i: number) => {
                                    return (<div key={i} >
                                        <div className="aim_chat aim_textuser">{msg.UserPrompt}</div>
                                        <div className="aim_chat aim_textresp">{msg.ResponseText}</div>
                                    </div>)
                                })}
                            </div> : <div></div>}

                    </div>
                    <div id="aim_chartbox">
                        {selModel && selModel.Category === "Query" ? <div>
                            <select className="ndt_dropdown" onChange={(e: ChangeEvent<HTMLSelectElement>) => { setChartParams({ ...chartparams, selmetric: +e.target.selectedOptions[0].attributes[0].value }) }}>
                                <option value={0}>Select Metric</option>
                                {mdldetails.Metrics.map((metr: string, i: number) => {
                                    return <option key={i} value={i}>{metr}</option>
                                })}

                            </select>
                        </div> : <div></div>}
                        <div id="aimgraph1x" style={{ backgroundColor: "rgb(40,40,40)", width: "800px", height: "800px" }}>
                            {selModel && selModel.Category === "JobPosting" && selJobPosting ? <div>
                                <div style={{ fontSize: "20px" }}>Req: {selJobPosting.Requisition}, Name: {selJobPosting.PostingName}</div>
                                <div style={{ fontSize: "20px" }}># Applications: {selJobPosting.applicationcount}</div>
                                <div style={{ height: "500px", overflowY: "scroll", margin: "3px", border: "1px solid grey", background: "rgb(20,20,20)", padding: '4px' }}>
                                    <div>Details:</div>
                                    {selJobPosting.requirements.map((req: JobPostingRequirement, i: number) => {
                                        return (<div key={i} style={{ margin: "1px" }}>-{StringTrim(req.Description, 100)}</div>)
                                    })}
                                </div>


                            </div> : <div></div>}
                        </div>
                    </div>
                </div> :
                <div>

                </div>}


        </div>

        <Modal show={keyparams.isdeletevisible}>
            <div className="ndt_modal">
                Delete AI Model {selModel?.ModelName}?
                <div style={{ display: "flex" }}>
                    <button className="ndt_btn1" style={{ margin: "10px", width: "200px" }} onClick={_ => ClickDelete()}>Delete</button>
                    <div style={{ margin: "10px" }} id="aim_deldiv"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isupdatevisible}>
            <div className="ndt_modal">
                Refresh AI Model {selModel?.ModelName}?
                <div style={{ display: "flex" }}>
                    <button className="ndt_btn1" style={{ margin: "10px", width: "200px" }} onClick={_ => ClickUpdate()}>Update</button>
                    <div style={{ margin: "10px" }} id="aim_upddiv"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isupdatevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>


    </div>)
};
export default AIModelManager