import { useState, useContext, useEffect } from 'react'
import { SkillCatValue, SkillCategory, DescModel } from '../../../type'
import { StartupContext } from '../../../App'
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric'

const JobSkillCategories = (props: any) => {

    const selJDModel: null | DescModel = props.seljdmodel

    let { config, dispatcher, schema, user, paths } = useContext(StartupContext)
    let [skillcatvals, setSkillCatVals] = useState<SkillCatValue[]>([])

    useEffect(() => {
        if (selJDModel) {
            ImportAndSetStateGeneric(setSkillCatVals, selJDModel.ModelKey, schema, paths.jobdescription.skillcatvalueview, user, config)
        }
    }, [])

    useEffect(() => {

    }, [])

    return (<div>
        <div className="ndt_title4" style={{ margin: '5px' }}>Categories</div>

    </div>)
}
export default JobSkillCategories;