
//filtering function
function PivotArray(data, byField1, byField2, aggField){
	//ref: createchart, scenariobuilder
		let arr = [];
		let arrcount = [];
		let arrsum  = [];
		let arrstr = [];
		
		if (byField2===""){
			data.forEach(x => {
				if (arr.indexOf(x[byField1])=== -1){
					arr.push(x[byField1]);
					arrcount.push(1)
					arrsum.push(+x[aggField]);
				} else {
					arrcount[arr.indexOf(x[byField1])] = arrcount[arr.indexOf(x[byField1])] + 1;
					arrsum[arr.indexOf(x[byField1])] = arrsum[arr.indexOf(x[byField1])] + +x[aggField];
				}
			})
			} else {
			data.forEach(x => {
				if (arrstr.indexOf(x[byField1] + "_" + x[byField2])=== -1){
					arrstr.push(x[byField1] + "_" + x[byField2]);
					arr.push([x[byField1], x[byField2]])
					arrcount.push(1)
					arrsum.push(+x[aggField]);
				} else {
					arrcount[arrstr.indexOf(x[byField1] + "_" + x[byField2])] = arrcount[arrstr.indexOf(x[byField1] + "_" + x[byField2])] + 1;
					arrsum[arrstr.indexOf(x[byField1] + "_" + x[byField2])] = arrsum[arrstr.indexOf(x[byField1] + "_" + x[byField2])] + +x[aggField];
				}
			})			
		}

		var result = [];
		arr.forEach((_,i) => {
			result.push({
				Value: arr[i],
				Count: arrcount[i],
				Sum: arrsum[i],
				Mean: Math.round(arrsum[i]/arrcount[i]*100)/100
			})
		})


		result.sort( function(a, b) {
			if (a.Value.length > 1){
				return (a.Value[0] - b.Value[0]);
			} else {
				if(a.Value[0]===b.Value[0]){
					return(a.Value[0] - b.Value[0])
				} else {
					return (a.Value[1] - b.Value[1]);
				}		
			}		
		});

		return(result)
	}
	
export default PivotArray	