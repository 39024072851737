import { useContext } from 'react'
import { StartupContext } from '../../../../App';
import DepartmentUpload from '../../../../data/Departments/Department_Upload';
import JobTitleUpload from '../../../../data/JobDescription/JobTitle_Upload';
import PositionUpload from '../../../../data/Position/Position_Upload';

const DataMgrProps = (modelcategory: string | undefined) => {

    const { paths } = useContext(StartupContext)
    let returnprops: any = {
        modelcategory: ''
    }

    if (modelcategory && modelcategory.toLowerCase() === "department") {
        returnprops = {
            modelcategory: 'Department',
            fldIDattr: "DeptID",
            datefield: "CurrDate",
            keycol: "AttrKey",
            editablefields: {
                "Dept": false, "DeptartmentName": true, "Attribute1": true,
                "Attribute2": true, "Attribute3": true, "Attribute4": true
            },
            pathmdl: paths.department.deptmodelview,
            pathdata: paths.department.deptview,
            pathattrs: paths.department.deptattrview,
            pathdatachange: paths.generics.datachange,
            pathdelete: paths.department.deptmodeldelete,
            hardcodedfields: [{ Key: "PrevDate", Name: "PrevDate" }, { Key: "CurrDate", Name: "AsofDate" }, { Key: "NextDate", Name: "NextDate" }, { Key: "IsMostRecent", Name: "IsMostRecent" }],
            UploadFunction: DepartmentUpload,
        }
    } else if (modelcategory && modelcategory.toLowerCase() === "jobtitle") {
        returnprops = {
            modelcategory: 'JobTitle',
            fldIDattr: "JobCode",
            datefield: "CurrDate",
            keycol: "AttrKey",
            editablefields: {
                "JobCode": false, "Jobtitle": true, "Attribute1": true,
                "Attribute2": true, "Attribute3": true, "Attribute4": true
            },
            pathmdl: paths.jobtitle.jobtitlemodelview,
            pathdata: paths.jobtitle.jobtitleview,
            pathattrs: paths.jobtitle.jobtitleattrview,
            pathdatachange: paths.generics.datachange,
            pathdelete: paths.jobtitle.jobtitlemodeldelete,
            hardcodedfields: [{ Key: "PrevDate", Name: "PrevDate" }, { Key: "CurrDate", Name: "AsofDate" }, { Key: "NextDate", Name: "NextDate" }, { Key: "IsMostRecent", Name: "IsMostRecent" }],
            UploadFunction: JobTitleUpload,
        }
    } else if (modelcategory && modelcategory.toLowerCase() === "position") {
        returnprops = {
            modelcategory: 'Position',
            fldIDattr: "PosnCode",
            datefield: "CurrDate",
            keycol: "AttrKey",
            editablefields: {
                "PosnCode": false, "Position": true, "Attribute1": true,
                "Attribute2": true, "Attribute3": true, "Attribute4": true
            },
            pathmdl: paths.position.positionmodelview,
            pathdata: paths.position.positionview,
            pathattrs: paths.position.positionattrview,
            pathdatachange: paths.generics.datachange,
            pathdelete: paths.position.positionmodeldelete,
            hardcodedfields: [{ Key: "Position", Name: "Position" }, { Key: "PrevDate", Name: "PrevDate" }, { Key: "CurrDate", Name: "AsofDate" }, { Key: "NextDate", Name: "NextDate" }, { Key: "IsMostRecent", Name: "IsMostRecent" }],
            UploadFunction: PositionUpload,
        }
    }

    return (returnprops)
};
export default DataMgrProps