
import { UserAccount } from "../../type";
import GetUniquesFromObjectAttribute from "../GetUniquesFromObjectAttribute";
import GetUserEmail from "./GetUserEmail";

const CreateUniqueUserMap = (dataset: any[], datafield: string, allusers: UserAccount[]) => {

    let usrids = GetUniquesFromObjectAttribute(dataset, datafield)
    let usremails = usrids.map((d: any) => { return GetUserEmail(d[0], allusers) })

    return Object.assign({}, ...usremails.map((t: any, i: any) => ({ [usrids[i][0]]: t })))

};

export default CreateUniqueUserMap;