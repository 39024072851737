import axios from "axios";

const AxiosUpload = async (dataArray: any, path: string, config: any,
    elem: HTMLElement | null,
    success_string: string | null,
    usestring_returndata: boolean = false) => {

    let data;

    //post
    //console.log(config)

    try {
        data = await axios.post(`${process.env.REACT_APP_API_URL}/${path}`,
            dataArray,
            config
        )
        if (elem) {
            if (success_string) {
                elem.innerHTML = success_string
            } else if (usestring_returndata) {
                elem.innerHTML = String(data.data)
            } else {
                elem.innerHTML = "Uploaded Successfully!"
            }
        }
    }
    catch (err) {
        console.log('data error');
        if (elem) {

            elem.innerHTML = "There was an error."

        }
    }
    return (data)


};
export default AxiosUpload;