import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Schema, Workspace, ModelBatch, UserPermssion } from '../type';
import { SchemaInit, WorkspaceInit } from '../InitTypes';

//schemas
export const schemalistDataSlice = createSlice({
    name: 'schemalist_action',
    initialState: SchemaInit,
    reducers: {
        schemalist: {
            reducer: (state, { payload }: PayloadAction<Schema>) => {
                state.push(payload);
            },
            prepare: ({ SchemaKey, SchemaName, SchemaDescription, IsProductionDefault, IsGuest, BaseColor, CreateDate, modelcount, workspacecount, dashcount, querycount, models, workspaces, userperms }: Schema) => ({
                payload: {
                    SchemaKey: SchemaKey,
                    SchemaName: SchemaName,
                    SchemaDescription: SchemaDescription,
                    IsProductionDefault: IsProductionDefault,
                    IsGuest: IsGuest,
                    BaseColor: BaseColor,
                    CreateDate: CreateDate,
                    modelcount,
                    workspacecount,
                    dashcount,
                    querycount,
                    models: models,
                    workspaces,
                    userperms
                }
            })
        },
        schemalist_delete: (state, _: PayloadAction<{}>) => {
            state.splice(0, state.length);
        },
    }
});

//single schema (selected)

export const schemaDataSlice = createSlice({
    name: 'user_action',
    initialState: SchemaInit[0],
    reducers: {
        schemadata: (_, { payload }: PayloadAction<{
            SchemaKey: string, SchemaName: string, CreateDate: Date, BaseColor: string, SchemaDescription: string,
            IsProductionDefault: boolean, IsGuest: boolean, modelcount: number, workspacecount: number, dashcount: number,
            querycount: number, models: ModelBatch[], workspaces: Workspace[], userperms: UserPermssion[]
        }>) => {
            //uses primitive values (null, string, int, etc.), so can't mutate state directly
            //just return completely new state
            return {
                SchemaKey: payload.SchemaKey,
                SchemaName: payload.SchemaName,
                SchemaDescription: payload.SchemaDescription,
                IsProductionDefault: payload.IsProductionDefault,
                IsGuest: payload.IsGuest,
                BaseColor: payload.BaseColor,
                CreateDate: payload.CreateDate,
                modelcount: payload.modelcount,
                workspacecount: payload.workspacecount,
                dashcount: payload.dashcount,
                querycount: payload.querycount,
                models: payload.models,
                workspaces: payload.workspaces,
                userperms: payload.userperms
            }
        },
        schema_delete: (state, _: PayloadAction<{}>) => {
            //uses primitive values (null, string, int, etc.), so can't mutate state directly
            //just return completely new state
            return {
                SchemaKey: '',
                SchemaName: '',
                SchemaDescription: '',
                IsProductionDefault: false,
                IsGuest: false,
                BaseColor: '#000000',
                CreateDate: new Date(),
                modelcount: 0,
                workspacecount: 0,
                dashcount: 0,
                querycount: 0,
                models: [],
                workspaces: [],
                userperms: []
            }
        }
    }
});



export const workspaceDataSlice = createSlice({
    name: 'workspace_action',
    initialState: WorkspaceInit[0],
    reducers: {
        workspacedata: (_, { payload }: PayloadAction<{
            WorkKey: string, WorkspaceName: string,
            SchemaName: string, CreateDate: Date, IsSimulation: boolean, models: ModelBatch[]
        }>) => {
            return {
                WorkKey: payload.WorkKey,
                SchemaName: payload.SchemaName,
                WorkspaceName: payload.WorkspaceName,
                CreateDate: payload.CreateDate,
                IsSimulation: payload.IsSimulation,
                models: payload.models,

            }
        },
        workspace_delete: (state, _: PayloadAction<{}>) => {
            return {
                WorkKey: '',
                SchemaName: '',
                WorkspaceName: '',
                IsSimulation: false,
                CreateDate: new Date(),
                models: []
            }
        }
    }
});

//single schema
export const {
    schemadata: schemaActionCreator,
    schema_delete: schemadeleteActionCreator,
} = schemaDataSlice.actions;

//schema list
export const {
    schemalist: schemalistActionCreator,
    schemalist_delete: schemalistdeleteActionCreator,
} = schemalistDataSlice.actions;


//single workspace
export const {
    workspacedata: workspaceActionCreator,
    workspace_delete: workspacedeleteActionCreator,
} = workspaceDataSlice.actions;
