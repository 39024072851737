import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { StartupContext } from "../../../App";
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import FieldsImport from "../../../data/Fields/Fields_Import";
import FilterData from "../../../functions/FilterData";
import { FieldMap } from "../../../type";
import IsInObject from "../../../functions/Object Functions/IsInObject";
import { Modal } from "react-bootstrap";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import "./css/EmployeeAuditView.css"
import GenericDropdown from "../../../functions/Dropdown_Generic";
import UniqueArrayValues from "../../../functions/UniqueArrayValues";
import FilterDataMultiple from "../../../functions/Filter Functions/FilterDataMultiple";

const EmployeeAuditView = () => {

    interface keyparamtype {
        selfld: string;
        isupdatevisible: boolean;
        isxfr: boolean;
        filtDate: string;
        filtRep: string;
    }
    interface audittype {
        ID: string;
        Name: string;
        Date: string;
        ReportType: string;
    }

    const { config, dispatcher, schema, user, paths } = useContext(StartupContext)
    const [xfrcols, setXFRcols] = useState<any[]>([])
    const [fields, setFields] = useState<FieldMap[]>([])
    const [keyparams, setKeyParams] = useState<keyparamtype>({
        selfld: "", isupdatevisible: false, isxfr: false, filtDate: '', filtRep: ''
    })
    const [auditResult, setAuditResult] = useState<audittype[]>([])
    const [uniqueAuditReports, setUniqueAuditReports] = useState<string[]>([])
    const [uniqueAuditDates, setUniqueAuditDates] = useState<string[]>([])
    const [auditFilterData, setAuditFilterData] = useState<audittype[]>([])

    let nonXFRstyle = { backgroundColor: "" }
    let XFRstyle = { backgroundColor: "rgb(100,100,200)" }

    useEffect(() => {
        let xfrpromise = GenericBackendCall("", schema, paths.generics.genericfilterview,
            user, config, { model: "TransferColumn", filterfield: "", filtervalue: "" })
        xfrpromise.then(d => { setXFRcols(SerializeDataFrame(d)) })

        let fieldpromise = FieldsImport("EmployeeState", schema, config, dispatcher, false);
        fieldpromise.then(newflds => {
            setFields(FilterData(newflds, "FieldCategory", "Grouping", true))
        })
    }, [])

    useEffect(() => {
        let f = FilterDataMultiple(auditResult, ['Date', 'ReportType'], [[keyparams.filtDate], [keyparams.filtRep]])
        setAuditFilterData(f)
    }, [keyparams.filtDate, keyparams.filtRep])

    //backend
    const ClickUpload = () => {
        GenericBackendCall(keyparams.selfld, schema, paths.employee.xfrfieldpost, user, config, { existing: keyparams.isxfr },
            "eav_divupl")
    }

    const ClickAudit = () => {
        GenericBackendCall("", schema, paths.employee.empaudit, user, config, {}, "eav_divaudit", "Complete").then(d => {
            let data = SerializeDataFrame(d)
            setAuditResult(data)
            setAuditFilterData(data)
            setUniqueAuditReports(UniqueArrayValues(data, "ReportType"))
            setUniqueAuditDates(UniqueArrayValues(data, "Date"))
        })
    }

    return (<div id="eav_canvas" style={{ padding: '10px' }}>
        <div className="ndt_title2">Employee Audit Check</div>
        <div id="eav_main">
            <div className="ndt_gridbox" style={{ padding: '25px' }}>
                <div style={{ width: "400px" }}>
                    Fields that Determine Transfer
                    {fields.map((fld: FieldMap, i: number) => {
                        let isxfr: any = false;
                        if (xfrcols.length > 0) {
                            isxfr = IsInObject(xfrcols, fld.Key, "FieldName_id", false)
                        }
                        return (<div key={i} className="ndt_item" style={isxfr ? XFRstyle : nonXFRstyle}
                            onClick={_ => { setKeyParams({ ...keyparams, selfld: fld.Key, isupdatevisible: true, isxfr: isxfr }) }}>
                            {fld.FieldName} {isxfr ? "- Dictates Transfer" : ""}
                            <div>{isxfr ? "Remove" : "Add"}</div>
                        </div>)
                    })}
                </div>
                <button id="eav_auditbtn" onClick={_ => { ClickAudit() }}>Audit</button>
                <div id="eav_divaudit"></div>
            </div>

            {auditResult.length > 0 ?
                <div className="ndt_gridbox" style={{ width: '80%', padding: '25px' }}>
                    <GenericDropdown
                        data={uniqueAuditDates}
                        keycol={""}
                        namecol={""}
                        promptstring="Filter by Date"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { setKeyParams({ ...keyparams, filtDate: e.target.selectedOptions[0].attributes[1].value }) }}
                        includeDefault={true}
                        defaultstring={"(All)"}
                    />
                    <GenericDropdown
                        data={uniqueAuditReports}
                        keycol={""}
                        namecol={""}
                        promptstring="Filter by Report"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { setKeyParams({ ...keyparams, filtRep: e.target.selectedOptions[0].attributes[1].value }) }}
                        includeDefault={true}
                        defaultstring={"(All)"}
                    />

                    <div style={{ height: '720px', overflowY: 'scroll' }}>
                        <div style={{ display: 'flex', fontWeight: "bold" }}>
                            <div className="eav_audititm" style={{ width: "50px" }}>ID</div>
                            <div className="eav_audititm" style={{ width: "180px" }}>Name</div>
                            <div className="eav_audititm" style={{ width: "100px" }}>Date</div>
                            <div className="eav_audititm" style={{ width: "100px" }}>ReportType</div>
                        </div>
                        {auditFilterData.map((av: audittype, i: number) => {
                            return (<div key={i} className="eav_auditrow">
                                <div className="eav_audititm" style={{ width: "50px" }}>{av.ID}</div>
                                <div className="eav_audititm" style={{ width: "180px" }}>{av.Name}</div>
                                <div className="eav_audititm" style={{ width: "100px" }}>{av.Date}</div>
                                <div className="eav_audititm" style={{ width: "100px" }}>{av.ReportType}</div>

                            </div>)
                        })}
                    </div>
                </div>
                :
                <div></div>}
        </div>
        <Modal show={keyparams.isupdatevisible}>
            Add this as transfer?
            <button onClick={_ => { ClickUpload() }}>{keyparams.isxfr ? "Remove" : "Add"}</button>
            <div id="eav_divupl"></div>
            <button className="mtclosemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isupdatevisible: false })}>Close</button>
        </Modal>

    </div>)
};
export default EmployeeAuditView;