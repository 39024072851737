
import {Link} from 'react-router-dom';
//import { useDispatch} from "react-redux";
import { RuleModel} from "../../../../type";

import "./css/ModalRuleManager.css";

const ModalRuleManager = (props:any) => {

    const selModel:RuleModel = props.selModel

    //const dispatcher = useDispatch();

    if (selModel){
        return(
            <div id="modal_RuleSel" >

            <div id="selectedrulemodel_div">
                <div id="activemodelheader">Active Model: {selModel.ModelName}</div>
                <div>
                {
                    selModel.ruleset.map((d,i) => {
                        return(
                            <div key={"r"+i}>
                                Rule: {d.RuleKey}
                            </div>
                        )
                    })
                }
                </div>
            </div>
            <Link to="/employee/RuleManager">
                <div id="explorelink">
                    Or Explore Further
                </div>
            </Link>
            </div>
        )
    } else {return(<div></div>)}
};
export default ModalRuleManager;