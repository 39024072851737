
import AxiosUpload from '../AxiosUpload';


const JobTitleUpload = async (jtdata: any, schema: string, modelname: string,
    asofDate: Date, headerslist: any, rememberheaders: boolean, includeBOT: boolean, batchfield: string, config: any, elementstring: string) => {

    let elem: HTMLElement | null = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    let dates = { 'day': asofDate.getDate(), 'month': asofDate.getMonth() + 1, 'year': asofDate.getFullYear() }
    let promise: Promise<any> | null = null;

    let arryheaders = Object.keys(headerslist).map((hdkey: string) => {
        return [
            String(headerslist[hdkey][0]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(headerslist[hdkey][1]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(headerslist[hdkey][2]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(headerslist[hdkey][4]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
        ]
    });

    let dataArray = new FormData();
    dataArray.append("data", jtdata);
    dataArray.append("schemakey", schema);
    dataArray.append("modelname", modelname);

    dataArray.append("year", String(dates.year));
    dataArray.append("month", String(dates.month));
    dataArray.append("day", String(dates.day));

    dataArray.append("headerslist", String(arryheaders))
    dataArray.append("rememberheaders", String(rememberheaders))
    dataArray.append("includeBOT", String(includeBOT))
    dataArray.append("batchfield", String(batchfield))

    promise = AxiosUpload(dataArray, "api/jobtitle/jobtitlepost", config, elem, "");

    return (promise);

}
export default JobTitleUpload;