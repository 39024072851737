import AxiosUpload from '../AxiosUpload';


const QueryVisualsReattach = async (schemakey: string, modelname: string, dashboardname: string, config: any, elementstring: string) => {

    let dataArray = new FormData();

    console.log(dashboardname)

    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("dashboardkey", dashboardname);
    dataArray.append("schemakey", schemakey);
    dataArray.append("modelkey", modelname);


    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/query/reattachquerymodel", config, elem, "");

    return ("Ok")
};

export default QueryVisualsReattach;