import * as d3 from 'd3';
import {useEffect, useState} from 'react';
import HexToRgba from '../../../functions/Color Functions/HexToRgba';
import GraphNeighborhood from './functions/Graph_Neighborhood';
import IsInObject from '../../../functions/Object Functions/IsInObject';



const NetworkGraph2D = (modelrundate, SNAstate, setfn, svgID, size, refresh) => {
	//console.log('reset network graph')
	//console.log(modelrundate.nodes.length)
	// console.log(modelrundate)
	// console.log(SNAstate.value.fstrength, SNAstate.value.dmin, SNAstate.value.dmax)
	// // values for all forces
	//console.log(SNAstate)



	/////variables///////
	let svg;
	let g; 
	let labels;
	let [RevNodes, setRevNodes] = useState([]);
	let [RevLinks, setRevLinks] = useState([]);
	let [netparams, setNetParams] = useState({rerun: 0})

	// svg objects
	var link, node, circles;

	// force simulation

	var simulation = d3.forceSimulation();



	var forceProperties = {
		center: {
			x: 0.5,
			y: 0.5
		},
		charge: {
			enabled: true,
			strength: SNAstate.value.fstrength,
			distanceMin: SNAstate.value.dmin,
			distanceMax: SNAstate.value.dmax
		},
		collide: {
			enabled: true,
			strength: .7,
			iterations: 1,
			radius: 5
		},
		forceX: {
			enabled: false,
			strength: .1,
			x: .5
		},
		forceY: {
			enabled: false,
			strength: .1,
			y: .5
		},
		link: {
			enabled: true,
			distance: 30,
			iterations: 1
		}
	}

	
	let [laststate, setLastState] = useState({selnodeid:'', fillcolor:'', strokecolor:''})

	let rgbcolors = SNAstate.colors
	if (SNAstate.categoryattrtype==="Numeric"){
		rgbcolors = [HexToRgba(SNAstate.colors[0],true), HexToRgba(SNAstate.colors[1],true)]
	}

	//edit data to be readable by force network
	const fixNodeformat = (nodes) => {
		let tempnodes = nodes.map((nd)=>{
			let val = nd.Name
			if (SNAstate.labelfield!==""){
				if (SNAstate.labelappend){
					val = nd.Name + " - " + SNAstate.labelfield
				} else {
					val = SNAstate.labelfield
				}
			}
			return({
				id: nd.Key,
				Name: val,
				Attribute1: nd.Attribute1,
				Attribute2: nd.Attribute2,
				Attribute3: nd.Attribute3,
				Attribute4: nd.Attribute4,
				Attribute5: nd.Attribute5,
				Attribute6: nd.Attribute6
			})	
		})
		setRevNodes(tempnodes)
	}

	const fixLinkformat = (links) => {
		let tempRevLinks = []
		links.forEach((ln) => {
			if (IsInObject(RevNodes,ln.Source,"id") && IsInObject(RevNodes,ln.Target,"id") ){
					tempRevLinks.push({id: ln.LinkKey, 
							source: ln.Source, 
							target: ln.Target,
							value: ln.Count,
					})
			}
		})

		setRevLinks(tempRevLinks)
		setNetParams({...netparams, rerun:netparams.rerun+1})
	};

	//step 1
	useEffect(()=>{
		fixNodeformat(modelrundate.nodes);
	},[modelrundate])
	useEffect(()=>{
		fixLinkformat(modelrundate.links);
	},[RevNodes])

	//step 2
	useEffect(() => {
			console.log('refresh sna');
			d3.selectAll("#sna_svg").remove()


			svg = d3.select(svgID)
						.append("svg")
						.attr("id","sna_svg")
						.attr("width", width)
						.attr("height", height)
						.attr("style", "outline: thin solid black");

			g = svg.append("g").attr("id","g_focus").attr("transform","translate(0,0)"); //requried to prevent zoom/drag from crashing svg parent
			
			initializeSimulation();
			rerun();
			
			//apply defaults
			//circles.style("stroke",SNAstate.nodeColor).attr("stroke-width", 2)
			link.style("stroke",SNAstate.linkColor)		


	},[netparams.rerun]);

	useEffect(()=>{
		let elem = document.getElementById("node_" + String(SNAstate.selNodeid))
		if (elem){
			ApplyClickMode(g, elem, SNAstate.clickmode)
		}
	},[SNAstate.clickmode])

	useEffect(()=>{
		if (SNAstate.selNodeid!=="" && SNAstate.selNodeid){
			ClickedNode1(SNAstate.selNodeid)
		}
	},[SNAstate.selNodeid])

	const ApplyClickMode = (g, elem, mode) =>{
		if (mode==="Neighborhood"){
			GraphNeighborhood(g,elem,10,false)
		} 
	}
	
	let [width, height] = size;

	// set up the simulation and event to update locations after each tick
	const initializeSimulation = () => {
	  simulation.nodes(RevNodes);
      simulation.on("tick",ticked)
	};
	
	// add forces to the simulation
	const initializeForces = () => {
		// add forces and associate each with a name
		simulation
			.force("link", d3.forceLink())
			.force("charge", d3.forceManyBody())
			.force("collide", d3.forceCollide())
			.force("center", d3.forceCenter())
			.force("forceX", d3.forceX())
			.force("forceY", d3.forceY());
        
		zoom_handler(svg);  
        
	};
	function colorChannelMixer(colorChannelA, colorChannelB, amountToMix){
		var channelA = colorChannelA*amountToMix;
		var channelB = colorChannelB*(1-amountToMix);
		return parseInt(channelA+channelB);
	}

	function colorMixer(rgbA, rgbB, amountToMix){
		var r = colorChannelMixer(rgbA[0],rgbB[0],amountToMix);
		var g = colorChannelMixer(rgbA[1],rgbB[1],amountToMix);
		var b = colorChannelMixer(rgbA[2],rgbB[2],amountToMix);
		return "rgb("+r+","+g+","+b+")";
	}

	const GetNodeColor = (d) =>{
		let val = SNAstate.nodeColor
		if (SNAstate.categoryattribute!==""){
			if (SNAstate.categoryattrtype!=="Numeric"){
				let attr = d[SNAstate.categoryattribute]
				let colridx = SNAstate.categories.indexOf(attr)
				val = SNAstate.colors[colridx]
			} else {
				let attr = +d[SNAstate.categoryattribute]
				if (SNAstate.categories[1]>SNAstate.categories[0]){
					let amt = (attr - SNAstate.categories[0])/(SNAstate.categories[1] - SNAstate.categories[0])
					val = colorMixer(rgbcolors[0], rgbcolors[1], amt)
				} else{
					val = "black"
				}
			}

		} 
		return val
	}

	const GetNodeInnerColor = (d) =>{
		//console.log(SNAstate.categoryattribute, d[SNAstate.categoryattribute], SNAstate.selcategory)
		if (SNAstate.categoryattribute!=="" && SNAstate.selcategory!==""){
			let attr = d[SNAstate.categoryattribute]
			if (attr===SNAstate.selcategory){
				return "red"
			} 	
		} 
		return "black"
	}

	const GetStrokeWidth = (d) =>{

		if (SNAstate.categoryattribute!==""){
			let attr = d[SNAstate.categoryattribute]
			// if (attr===SNAstate.selcategory){
			// 	return 4
			// } 	
		}
		return 2
	}

	const initializeNodeDisplay = () => {


	  // set the data and properties of node circles

		node = g.append("g")
				.attr("class", "nodes")
				.selectAll("g")
				.data(RevNodes)
				.enter().append("g")
				.attr("name",function(d){ return d.Name })
				.attr("id",function(d){ return d.id })
				.style("stroke-width",d=> GetStrokeWidth(d));
				
		circles = node.append("circle")
				.attr("class","circle1")
				.attr("id",function(d){ return "node_" + String(d.id) })
			    .attr("name",function(d){ return d.Name })
				.attr("data-id",function(d){ return d.id })
				.attr("r", 7)
			    .attr("fill", d=>GetNodeInnerColor(d))
				.style("stroke",function(d){return GetNodeColor(d)})
			    //.attr("stroke-opacity", .5)
			    .call(d3.drag()
				  .on("start", dragstarted)
				  .on("drag", dragged)
				  .on("end", dragended))
				.on("mouseover",function(d){
					d3.select(this).transition().duration(50).attr('r',8);
				})
				.on("mouseout",function(d){
					d3.select(this).transition().duration(50).attr('r',5);
				})
			    .on("click",function(d){
					ClickedNode1(d3.select(this).attr("data-id"))			
				});

	  // node tooltip
	  node.append("title")
		  .text(function(d) { return d.Name; });
	  
	  // visualize the graph
		labels = node.append("text")
			  .text(function(d) {
			
				return d.Name;
			  })
			  .attr("font-size",8)
			  .attr('x', 8)
			  .attr('y', 6);
	};

	const ClickedNode1 = (selID) =>{

			ClickNode(selID).then(d=>{
				setLastState({...laststate, selnodeid: "node_" + String(selID), fillcolor:d[0], strokecolor: d[1]})
			})
	
	}
	const ClickNode = async (selID) => {
		//reset variables, coloring		
		console.log("clickednode", selID, "lastid", laststate.selnodeid)

		let elemidval = "node_" + String(selID)

		d3.selectAll(".snalinks")._groups[0].forEach(dlk => {	
			dlk.style.stroke = SNAstate.linkColor; //reset color
		})
		d3.selectAll(".circle1").attr("fill","black").attr("r",5); 
		
		if (SNAstate.secondaryNode){
			d3.select(String(SNAstate.secondaryNode)).attr("fill","lightgreen");
		}

		let selnode = document.getElementById(elemidval)
		let lastnode = document.getElementById(laststate.selnodeid)
		let origcolor = "";
		let origstroke = "";
		
		if (selnode && (elemidval!==laststate.selnodeid)){
			origcolor = String(selnode.style.fill)
			origstroke = String(selnode.style.stroke)
			selnode.style.fill = "yellow"
			selnode.style.stroke="red"

		}

		if (lastnode){
			lastnode.style.fill = laststate.fillcolor
			lastnode.style.stroke = laststate.strokecolor

		}

		await setfn({...SNAstate, selNodeid: selID})
		
	
		return [origcolor, origstroke]
	};

	// generate the svg objects and force simulation
	const initializeLinkDisplay = () => {

		  // set the data and properties of link lines
		  link = g.append("g")
					.attr("id","linksg")
					.selectAll("line")
					.data(RevLinks)
					.enter().append("line")
					.attr("class", "snalinks")
					.attr("stroke-opacity", 1)
					.attr("stroke-width", 2)
					.attr("id",function(d,_){ return d.source + "--" + d.target; })
					.attr("title",function(_){return "Title"})
					.attr("source",function(d){ return d.source })
					.attr("target",function(d){ return d.target })
					.attr("stroke-opacity",d => {return 1})
					.attr("data-tip","")
					.attr("data-for","registerTip")
					.on("mouseover",function(_){
						d3.select(this).transition().duration(50).attr('stroke-width',5);
					})
					.on("mouseout",function(_){
						d3.select(this).transition().duration(20).attr('stroke-width',2);
					});
        

		  link.append("title")
			  .text(function(d) { //console.log(d3.select(this)._groups[0][0].__data__); 
					return d.source + " - " + d.target; 
			  }) 	
		  
	};	
	
	// apply new force properties
	const updateForces = () => {
		// get each force by name and update the properties
		simulation.force("center")
			.x(width * forceProperties.center.x)
			.y(height * forceProperties.center.y);
		simulation.force("charge")
			.strength(forceProperties.charge.strength * forceProperties.charge.enabled)
			.distanceMin(forceProperties.charge.distanceMin)
			.distanceMax(forceProperties.charge.distanceMax);
		simulation.force("collide")
			.strength(forceProperties.collide.strength * forceProperties.collide.enabled)
			.radius(forceProperties.collide.radius)
			.iterations(forceProperties.collide.iterations);
		simulation.force("forceX")
			.strength(forceProperties.forceX.strength * forceProperties.forceX.enabled)
			.x(width * forceProperties.forceX.x);
		simulation.force("forceY")
			.strength(forceProperties.forceY.strength * forceProperties.forceY.enabled)
			.y(height * forceProperties.forceY.y);
        

		simulation.force("link")
			 .id(function(d) { return d.id;})
			 .distance(forceProperties.link.distance)
			 .iterations(forceProperties.link.iterations)
			 .links(RevLinks);

		// updates ignored until this is run
		// restarts the simulation (important if simulation has already slowed down)
		simulation.alpha(1).restart();

	};
	
	const rerun = () => {

		initializeLinkDisplay();
		initializeNodeDisplay();
		initializeForces();
		updateForces();

	};		


	// update the display positions after each simulation tick
	const ticked = () => {

			link
				.attr("x1", function(d) { return d.source.x; })
				.attr("y1", function(d) { return d.source.y; })
				.attr("x2", function(d) { return d.target.x; })
				.attr("y2", function(d) { return d.target.y; });

			node
				.attr("cx", function(d) { return d.x; })
				.attr("cy", function(d) { return d.y; });

			circles
				.attr("cx", function(d) { return d.x; })
				.attr("cy", function(d) { return d.y; });

			labels
				.attr("x", function(d) { return d.x+4; })
				.attr("y", function(d) { return d.y-3; });

			d3.select('#alpha_value').style('flex-basis', (simulation.alpha()*100) + '%');
		
	}

	//////////// UI EVENTS ////////////

	function dragstarted(event,d) {
	if (!event.active) simulation.alphaTarget(0.1).restart();
	d.fx = d.x;
	d.fy = d.y;
	}

	function dragged(event,d) {
	d.fx = event.x;
	d.fy = event.y;
	}

	function dragended(event,d) {
	if (!event.active) simulation.alphaTarget(0.0001);
	d.fx = null;
	d.fy = null;
	}


	//add zoom capabilities 
	var zoom_handler = d3.zoom()
		.on("zoom", zoom_actions);

	function zoom_actions(event){
		g.attr("transform", event.transform)
	}
};
export default NetworkGraph2D;