import * as d3 from 'd3';

const CreateSVG = (svgid, margin, width, height) => {

    var svg = d3.select(svgid).append("svg")
        .attr("id",svgid)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    return(svg)
};
export default CreateSVG;