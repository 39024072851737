import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterModel } from "../type";
import { FilterModelInit } from '../InitTypes';



//filtermodels
export const filterModelDataSlice = createSlice({
	name: 'filtermodel_action',
	initialState: FilterModelInit,
	reducers: {
		filterModels: {
			reducer: (state, { payload }: PayloadAction<FilterModel>) => {
				state.push(payload);
			},
			prepare: ({ ModelKey, ModelName, SchemaName, DateCreated, fields }: FilterModel) => ({
				payload: {
					ModelKey: ModelKey,
					DateCreated: DateCreated,
					ModelName: ModelName,
					SchemaName: SchemaName,
					fields: fields
				}
			})
		},
		filterModels_delete: (state, _: PayloadAction<{}>) => {
			state.splice(0, state.length);
		},
	}
});

//selected filter
export const {
	filterModels: filterModelActionCreator,
	filterModels_delete: filterModelDeleteActionCreator,
} = filterModelDataSlice.actions;

