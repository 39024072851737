
const GetObjectField = (keystring: string, data: any[], lookupField: string, returnfield: string): any => {
    let val: any = ""

    if (keystring === "None" || keystring === "Null") { val = keystring } //preserve "none/null"


    data.forEach((d: any) => {
        if (String(d[lookupField]) === String(keystring)) {
            if (returnfield === "") {
                val = d
            } else {
                val = d[returnfield];
            }
        }

    })
    return (val)
}
export default GetObjectField