

const SetObjectfromKey = (key: string, objarr: any[], onfield: string, setfunction: any, overwritewithnull: boolean, nullvalue: any | null = null): number => {
    //ref: somewhere..., ScenarioBuilder

    let a: number = -1;
    let found: boolean = false;

    objarr.forEach((obj: any, i: number) => {
        if (obj[onfield] === key) {
            setfunction(obj)
            a = i
            found = true
        }
    })

    if (!found && overwritewithnull) {
        setfunction(nullvalue)
    }

    return (a)
};
export default SetObjectfromKey