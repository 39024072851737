import { JobDescription, SkillCategory, JobTitle, SkillCatValue, Link } from "../../../../type";
import FilterData from "../../../../functions/FilterData";
import PivotArray from "../../../../functions/PivotArray";
import IsInObject from "../../../../functions/Object Functions/IsInObject";
import { LinkInit, NodeInit } from "../../../../InitTypes";
import GetObjectField from "../../../../functions/Object Functions/GetObjectField";


interface CSMatch { JobCode: string; JobSkillKey: string; Category: string; }
interface Category {
    Category: string;
    CategoryName: string;
    CategoryTerms: string[];
}


const JDSkillsToNetwork = (jds: JobDescription[], jscat: SkillCategory[], jobtitles: JobTitle[], categories: Category[], thresh: number) => {
    //ref: jobdescnetfraph
    //skills are links, jscat adds value depending on the model
    let catskill: CSMatch[] = []
    jscat.forEach((js: SkillCategory) => {
        js.values.forEach((scv: SkillCatValue) => {
            catskill.push({ JobCode: scv.JobCode, JobSkillKey: scv.JobSkillName, Category: String(js.CategoryNum) })
        })
    })
    let jc_skillcounts: any[] = jds.map((desc: JobDescription) => {
        return ({ JobCode: desc.JobCode, SkillCount: desc.skillcount })
    })
    let jobcodes: any[] = jds.map((desc: JobDescription) => {
        return (desc.JobCode)
    })
    let linkcodes: any[] = []
    let alllinks: Link[] = []

    categories.forEach((cat: Category, i: number) => {
        let cats = FilterData(catskill, "Category", String(cat.Category))
        let catreduced = PivotArray(cats, "JobCode", "", "Category")

        catreduced.forEach((c: any) => {

            //a link between each item in array
            catreduced.forEach((a: any) => {
                if (String(c.Value) > String(a.Value)) {
                    let idx = IsInObject(linkcodes, c.Value + "-" + a.Value, "Key", true)
                    if (idx === -1) {
                        let srcidx = jobcodes.indexOf(c.Value)
                        let srccount = jc_skillcounts[srcidx].SkillCount
                        let tgtidx = jobcodes.indexOf(c.Value)
                        let tgtcount = jc_skillcounts[tgtidx].SkillCount

                        alllinks.push({ ...LinkInit[0], Source: c.Value, Target: a.Value, Count: 1, CountPct: srccount + tgtcount, otherdata: [cat.Category] })
                        linkcodes.push({ Key: c.Value + "-" + a.Value, Count: 1 })
                        linkcodes.push({ Key: a.Value + "-" + c.Value, Count: 1 })
                    } else if (typeof (idx) === "number") {
                        alllinks[Math.round(idx / 2 - .5)].Count += 1
                        alllinks[Math.round(idx / 2 - .5)].otherdata.push(cat.Category)
                    }
                }
            })

        })
    })
    let links: Link[] = []

    alllinks.forEach((lk: Link) => {
        let pct = lk.Count / lk.CountPct;
        if (pct > thresh) {
            links.push({ ...lk, CountPct: pct })
        }
    })
    let nodes: any[] = []
    jds.forEach((jobdesc: JobDescription) => {
        jobtitles.map((jt: JobTitle) => {
            if (jobdesc.JobCode === jt.JobCode) {
                nodes.push({
                    ...NodeInit[0], Key: String(jobdesc.JobCode),
                    Name: GetObjectField(jobdesc.JobCode, jobtitles, "JobCode", "JobTitle"),
                    Attribute1: jt.Attribute1,
                    Attribute2: jt.Attribute2,
                    Attribute3: jt.Attribute3,
                    Attribute4: jt.Attribute4,
                    Attribute5: jt.Attribute5,
                    Attribute6: jt.Attribute6,
                })
            }
        })
    })
    return ([nodes, links, alllinks])
};
export default JDSkillsToNetwork