import { useState, useContext, ChangeEvent, useEffect, useMemo } from 'react'
import { StartupContext } from '../../App';
import FieldsUploadSample from '../../data/Fields/Fields_UploadSample';
import "./css/DatasetWalkthrough.css";
import StringTrim from '../../functions/String Functions/StringTrim';
import GetObjectValue from '../../functions/Object Functions/GetObjectValue';
import GenericBackendCall from '../../functions/Import/GenericBackendCall';
import { Modal } from 'react-bootstrap';
import { ConnectionModel, FieldMap, Metric, QueryModel, QueryRunDate, WalkThroughDemo, queryparams, queryvisualstate, QueryVisuals, KPI, Schedule, KPIValue } from '../../type';
import GenericDropdown from '../../functions/Dropdown_Generic';
import ChangeParamSelectorbyIndex from '../../functions/ElementSelect/ChangeParamSelectorbyIndex';
import MeasurementDropdown from '../../functions/Dropdowns/MeasurementDropdown';
import GetFieldName from '../../functions/GetFieldName';
import { MetricInit, ScheduleInit, queryparamsInit, queryvisInit } from '../../InitTypes';
import DatePicker from 'react-date-picker';
import DateString from '../../functions/Date Functions/DateString';
import ChartConst from '../../charts/ChartConst';
import QueryVisualsPrepare from '../../functions/QueryFunctions/QueryVisualsPrepare';
import SerializeDataFrame from '../../functions/Import/SerializeDataFrame';
import SchedulePeriodDropdown from '../../functions/QueryFunctions/SchedulePeriodDropdown';
import CalculateSchedule from '../../functions/Date Functions/CalculateSchedule';
import DateMatch from '../../functions/Date Functions/DateMatch';
import QueryModelRerunMulti from '../../data/Query/QueryModel_RerunMulti';
import ChartSample from '../../charts/sample/ChartSample';
import ChartDelete from '../../charts/newfunctions/ChartDelete';
import IdentifyKPIElement from '../../functions/KPI Functions/IdentifyKPIElement';
import GetTextColor from '../../functions/GetTextColor';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import FormatLabel from '../../charts/newfunctions/FormatLabel';
import ExcelExport from '../../functions/ExcelExport';


const DatasetWalkthrough = () => {
    let { id } = useParams()
    let history = useNavigate()
    let location = useLocation()

    interface paramtype {
        step: number; stepmax: number; stepclear: boolean; stepcheck: boolean;
        dfile: any; loadedfile: boolean; isImported: boolean;
        modelname: string; isnamingvisible: boolean;
        uploadchoice: string; eventchoice: string;
        isuploadvisible: boolean; dateheader: string; dateheader2: string; keyheader: string;
        workspacekey: string; walkkey: string; metrfield: string; measurement: string;
        xfield: string; grpfield: string; asofDate: Date; connmdlstring: string;
        metrickey: string; tablekey: string; querykey: string; selrundatekey: string;
        rerun: number; isrundatevisible: boolean;
        refresh: number; kpiinspect: boolean; kpimsg: string; kpivalue: string; kpikey: string;
        iskpisavevisible: boolean; isdelwalkvisible: boolean; selDateIdx: number; initimport: boolean;
        runall: boolean; isrunallvisible: boolean; dateopenclose: boolean; rundateN: number;
    }
    interface matchdate {
        Date: Date;
        RunDateKey: string;
    }
    interface samplefld {
        Value: string;
        Type: string;
    }

    let paramInit: paramtype = {
        step: 0, stepmax: 0, stepcheck: false, stepclear: true, dfile: "", uploadchoice: '', eventchoice: '', dateheader: '', dateheader2: '',
        keyheader: '', modelname: '', loadedfile: false, isImported: false, isnamingvisible: false, isuploadvisible: false, workspacekey: '',
        walkkey: '', metrfield: '', measurement: '', xfield: '', grpfield: '', asofDate: new Date(), connmdlstring: '',
        metrickey: '', tablekey: '', querykey: '', selrundatekey: '', rerun: 0, refresh: 0, isrundatevisible: false, kpiinspect: false,
        kpimsg: "", kpivalue: '', kpikey: '', iskpisavevisible: false, isdelwalkvisible: false,
        selDateIdx: -1, initimport: false, runall: false, isrunallvisible: false, dateopenclose: false,
        rundateN: 5
    }

    let { config, schema, user, paths, clickstyle } = useContext(StartupContext)
    const [keyparams, setKeyParams] = useState<paramtype>(paramInit)
    const [sampleFields, setSampleFields] = useState<samplefld[]>([])
    const [tablefields, setTableFields] = useState<FieldMap[]>([])
    const [tableMetrics, setTableMetrics] = useState<Metric[]>([])
    const [selBasicRow, setSelBasicRow] = useState<any | null>(null)
    const [kpi, setKPI] = useState<KPI | null>(null)
    const [kpivals, setKPIVals] = useState<KPIValue[]>([])
    const [schedule, setSchedule] = useState<Schedule | null>(null)
    const [dates, setDates] = useState<matchdate[]>([])

    const [walkthroughs, setWalkthroughs] = useState<WalkThroughDemo[]>([])
    const [existingWalkthrough, setExistingWalkthrough] = useState<WalkThroughDemo | null>(null)
    const [query, setQuery] = useState<QueryModel | null>(null)
    const [vis_states, setVis_States] = useState<queryvisualstate[]>(queryvisInit)
    const [qryvis, setQryVis] = useState<QueryVisuals | null>(null)

    let stepnames = {
        '0': "Begin", '1': "Import Data", '2': 'Edit Table', '3': "Metric", '4': 'Query', '5': 'Schedule',
        '6': "Select KPI", '7': 'Run Dates', '8': 'Trend', '9': "Join Data"
    }
    let selStyle = { backgroundColor: "rgb(62, 28, 129)" }
    let stepitmON = { backgroundColor: "rgb(50, 70, 73)", boxShadow: "0px 0px 6px rgb(101, 160, 163), inset 0px 0px 3px white" }

    useEffect(() => {
        if (keyparams.refresh >= 1000) {
            window.location.reload()
        }
    }, [keyparams.refresh])

    useEffect(() => {
        if (id) {
            walkthroughs.forEach((wlk: WalkThroughDemo) => {
                if (wlk.Key === id) {
                    setExistingWalkthrough(wlk)
                }
            })
        }
    }, [id])

    useEffect(() => {

    }, [keyparams.stepmax])


    useEffect(() => {
        if (existingWalkthrough) {
            let pathstr = "/walkthrough/" + existingWalkthrough.Key
            if (location.pathname !== pathstr) {
                history(pathstr)
            }
            //console.log(existingWalkthrough)

            let asofdate = new Date()
            let tblkey = GetObjectValue(existingWalkthrough.TableName, "ModelKey")
            let tblname = GetObjectValue(existingWalkthrough.TableName, "ModelName")
            let metrkey = GetObjectValue(existingWalkthrough.MetricName, "MetricKey")
            let metrfield = GetObjectValue(existingWalkthrough.MetricName, "MeasurementField")
            let measurement = GetObjectValue(existingWalkthrough.MetricName, "Measurement")
            let qrykey = GetObjectValue(existingWalkthrough.QueryName, "ModelKey")
            let kpikey = GetObjectValue(existingWalkthrough.KPIName, "Key")
            let xfield = GetObjectValue(existingWalkthrough.QueryName, "XFieldName")
            let grpfield = GetObjectValue(existingWalkthrough.QueryName, "GroupFieldName")
            let sched: any = existingWalkthrough.ScheduleName
            let kpi: any = existingWalkthrough.KPIName
            let tblmetr: any = existingWalkthrough.MetricName
            //let qry: any = existingWalkthrough.QueryName
            if (existingWalkthrough.ScheduleName) {
                asofdate = new Date(existingWalkthrough.ScheduleName.AnchorDate)
            }
            if (metrfield !== "") {
                metrfield = metrfield.Key
            }
            if (qrykey !== "") {
                xfield = xfield.Key
                if (grpfield) {
                    grpfield = grpfield.Key
                }
            }

            //clear values
            setSchedule(sched)
            //setKPI(kpi)
            setTableMetrics([tblmetr])

            setSelBasicRow(null)
            setDates([])
            setSampleFields([])
            //setKeyParams({...paramInit, connmdlstring: keyparams.connmdlstring}) //do I need this to clear values here?
            setTableFields([])

            setKeyParams({
                ...keyparams, initimport: true, walkkey: existingWalkthrough.Key, workspacekey: existingWalkthrough.WorkspaceName.WorkKey,
                asofDate: asofdate, modelname: tblname, tablekey: tblkey, metrickey: metrkey,
                metrfield: metrfield, querykey: qrykey, kpikey: kpikey, measurement: measurement,
                xfield: xfield, grpfield: grpfield
            })
        }
    }, [existingWalkthrough])

    useEffect(() => {

        GenericBackendCall("", schema, paths.tables.connectionmodelview, user, config, {}).then(cmdls => {
            let defaultmdl: any = ""
            cmdls.forEach((mdl: ConnectionModel) => {
                if (mdl.IsDefault) {
                    defaultmdl = mdl.ModelKey
                }
            })
            //import previous workspace
            GenericBackendCall("", schema, paths.walkthrough.walkthroughdemoview, user, config, {}).then((d: WalkThroughDemo[]) => {
                setWalkthroughs(d)
            })
            setKeyParams({ ...keyparams, connmdlstring: defaultmdl })
        })
    }, [])

    useEffect(() => {
        if (keyparams.stepcheck) {
            if (keyparams.step === 0) {
                CheckStep0()
            } else if (keyparams.step === 1) {
                CheckStep1()
            } else if (keyparams.step === 2) {
                CheckStep2()
            } else if (keyparams.step === 3) {
                CheckStep3()
            } else if (keyparams.step === 4) {
                CheckStep4()
            } else if (keyparams.step === 5) {
                CheckStep5()
            } else if (keyparams.step === 6) {
                CheckStep6()
            } else if (keyparams.step === 7) {
                CheckStep7()
            } else if (keyparams.step === 8) {
                CheckStep8()
            }
        }
    }, [keyparams.stepcheck])

    const GetTableFields = () => {
        GenericBackendCall(keyparams.modelname, schema, paths.fields.fieldretrieve, user, config, { workspacekey: keyparams.workspacekey }).then(d => {
            setTableFields(d)
        })
    }


    useEffect(() => {
        if (keyparams.initimport && keyparams.stepmax) { //for existing selected walkthrough
            console.log(tablefields.length, keyparams.stepmax)

            if (keyparams.stepmax <= 4 && tableMetrics.length > 0) {
                setTableMetrics([])
            }
            if (keyparams.stepmax <= 5 && schedule) {
                setSchedule(null)
                setDates([])
            }
            if (keyparams.stepmax <= 6 && (selBasicRow || kpi)) {
                setSelBasicRow(null)
                setKPI(null)
            }
            if (keyparams.stepmax <= 1 && sampleFields.length > 0) {
                setSampleFields([])
            }
            if (keyparams.stepmax <= 2 && tablefields.length > 0) {
                setTableFields([])
            }
            if (keyparams.stepmax >= 2 && tablefields.length === 0) { //step3 previously completed, add fields
                GetTableFields()
            }

            if (keyparams.stepmax >= 4 && tablefields.length === 0) {//step4 previously completed, add metric
                GenericBackendCall(keyparams.modelname, schema, paths.query.metricview, user, config, { workspacekey: keyparams.workspacekey }).then(d => {
                    setTableMetrics(d)
                })
            }

            if (keyparams.stepmax >= 5 && !kpi) {
                GenericBackendCall("", schema, paths.kpi.kpiview, user, config, { modelkeys: [keyparams.kpikey] }).then((d: KPI[]) => {
                    if (d.length === 1) {
                        setKPI(d[0])
                        if (d[0].values.length > 0) {
                            setKPIVals(d[0].values)

                        }
                    }
                })
            }
            if (keyparams.stepmax >= 5 && !query) {
                console.log('check query load')
                GetQuery()
            }
        }

    }, [keyparams.stepmax, keyparams.initimport])

    const GetQuery = () => {
        GenericBackendCall('', schema, paths.query.queryview, user, config, { querykeys: [keyparams.querykey], type: "full" }).then((d: QueryModel[]) => {
            GenericBackendCall(keyparams.querykey, schema, paths.query.queryvisualsview, user, config, { IsPrimary: "Y" }).then((v: QueryVisuals[]) => {
                if (d.length === 1 && v.length > 0) {
                    setQuery(d[0])
                    QueryVisualsPrepare(v[0], setQryVis)
                    setKeyParams({ ...keyparams, selrundatekey: d[0].rundates[0].DateKey })
                }
            })
        })
    }
    useEffect(() => {

    }, [keyparams.stepmax])

    useEffect(() => {
        if (schedule && schedule.ScheduleKey === "") {
            setSchedule({ ...schedule, AnchorDate: keyparams.asofDate })
        }
    }, [keyparams.asofDate])


    useEffect(() => {
        if (keyparams.step >= 7 && kpivals.length > 0) {
            MakeChart()
        }
    }, [keyparams.step, kpivals])

    useEffect(() => {
        setTimeout(() => {
            if ((keyparams.step === 6 || keyparams.step === 7) && kpi && query) {
                let kpielem = IdentifyKPIElement("ndtchartelem", kpi, keyparams.selrundatekey, query)
                if (kpielem) {
                    let basecolor = GetTextColor(kpielem.style.backgroundColor)
                    if (basecolor === "white") { basecolor = "yellow" }
                    kpielem.style.backgroundColor = basecolor
                    kpielem.style.stroke = basecolor
                    kpielem.style.fill = basecolor
                }
            }
        }, 1000)
    }, [keyparams.step, keyparams.selrundatekey, kpi, query])

    useEffect(() => {
        if (schedule && schedule.Period !== "") {
            let alldates = CalculateSchedule(schedule, Math.round(keyparams.rundateN + 1), -1)
            let datesfound: matchdate[] = []
            alldates.forEach((ad: Date[]) => {
                let foundkey: string = ""
                query?.rundates.forEach((rd: QueryRunDate) => {
                    if (DateMatch(ad[1], rd.AsofDate)) {
                        foundkey = rd.DateKey
                    }
                })
                datesfound.push({ Date: ad[1], RunDateKey: foundkey })
            })
            setDates(datesfound)
        }
    }, [schedule, query?.rundates, keyparams.rundateN])

    const ChangeStep = (intdir: number) => {
        let newstep = Math.max(0, keyparams.step + intdir)
        let stepmax1 = Math.max(keyparams.stepmax, newstep, keyparams.step + ~keyparams.stepcheck)
        if (intdir > 0) {
            setKeyParams({ ...keyparams, step: newstep, stepmax: stepmax1, stepclear: false, stepcheck: false })
        } else {
            setKeyParams({ ...keyparams, step: newstep, stepmax: stepmax1, stepclear: true, stepcheck: false })
        }

    }
    const ChangeStepTo = (num: number) => {
        let newmax = Math.max(keyparams.stepmax, num)

        setKeyParams({ ...keyparams, step: num, stepmax: newmax, stepclear: false, stepcheck: false })

    }

    const MakeChart = () => {

        let chartdata = kpivals.map((kv: KPIValue) => {
            return { AsofDate: kv.AsofDate, Value: kv.Value }
        })

        ChartDelete("wlk_qryvw")
        ChartSample("Line", "#wlk_kpigraph",
            chartdata, [],
            ["AsofDate", ['Value'], ""], ["AsofDate", ""],
            "Continuous", ['#230894', '#98ab11'],
            [700, 700], "On", false,
            { format: "dec", rounding: "0", clr: "white", lineclr: "white", secondarylineclr: "blue" }, true
        )
    }


    const FileLoad = (divID: string) => {
        let elem: any = document.getElementById(divID)
        let dfile = elem.attributes[0].ownerElement?.files[0]
        setKeyParams({ ...keyparams, dfile: dfile, loadedfile: true })
    }

    const FileSubmit = () => {
        if (keyparams.dfile) {
            FieldsUploadSample(keyparams.dfile, schema, config, user, "wlk_divsubmitfld").then((sd: any) => {
                let sflds: samplefld[] = []
                sd.data.map((d: samplefld) => {
                    sflds.push({ Value: d.Value, Type: "Text" })
                })
                setSampleFields(sflds)
                setKeyParams({ ...keyparams, isImported: true })
            })
        }
    };

    const ChangeDataType = (e: ChangeEvent<HTMLSelectElement>, i: number) => {
        let val = e.target.selectedOptions[0].attributes[0].value
        let newsmpflds = sampleFields
        newsmpflds[i]['Type'] = val
        setSampleFields(newsmpflds)
    }

    const DataTypeDropdown = (props: any) => {
        let i = props.i
        return (<select onChange={(e: ChangeEvent<HTMLSelectElement>) => { ChangeDataType(e, i) }}>
            <option value="">Select Data Type</option>
            <option value="Text">Text</option>
            <option value="Numeric">Numeric</option>
            <option value="Date">Date</option>
        </select >)
    }
    const ConvertExisting = () => {
        if (existingWalkthrough) {
            if (existingWalkthrough.MetricName) {

            }
            let nextstep = +existingWalkthrough.CompletedStep + 1
            setKeyParams({
                ...keyparams,
                workspacekey: existingWalkthrough.WorkspaceName.WorkKey,
                walkkey: existingWalkthrough.Key,
                step: nextstep, stepmax: nextstep
            })
        }
    }

    const ClickRun = () => {
        if (query) {
            let asofdates: Date[][] = []
            if (!keyparams.runall) {
                asofdates = [[dates[keyparams.selDateIdx].Date, dates[keyparams.selDateIdx].Date]]

            } else {
                dates.forEach((dt: matchdate) => {
                    if (dt.RunDateKey === "") {
                        asofdates.push([dt.Date, dt.Date])
                    }
                })
            }
            let schedules: string[][] = asofdates.map((_) => {
                if (schedule) {
                    return [schedule.ScheduleKey]
                } else {
                    return [""]
                }
            })
            if (asofdates.length > 0) {
                QueryModelRerunMulti(query.ModelKey, schema, keyparams.workspacekey, asofdates, schedules, "", user, config, "wlk_daterundiv").then(_ => {
                    setKeyParams({ ...keyparams, refresh: 999 })
                })
            } else {
                let elem = document.getElementById("wlk_daterundiv")
                if (elem) {
                    elem.innerHTML = "No dates available!"
                }
            }
            // QueryModelRerun(query.ModelKey, schema, keyparams.workspacekey, aod, aod, [""], "", user, config, "wlk_rundatediv").then(_ => {
            //     setKeyParams({ ...keyparams, refresh: 999 })
            // })


        }
    }

    const ClickDeleteRun = () => {
        GenericBackendCall(keyparams.selrundatekey, schema, paths.query.rundatedelete, user, config, {}, "wlk_daterundiv").then(_ => {
            setKeyParams({ ...keyparams, refresh: 999 })
        })
    }
    const ClickInspect = () => {
        if (vis_states[0].selChartElemIndex !== "") {
            setKeyParams({ ...keyparams, kpiinspect: true })
            let bpromise = GenericBackendCall("", schema, paths.generics.genericfilterview, user, config,
                { workspacekey: keyparams.workspacekey, model: "BasicQuery", filterfield: "id", filtervalue: vis_states[0].selChartElemIndex })
            bpromise.then(d => {
                let brow = SerializeDataFrame(d)[0]

                setSelBasicRow(SerializeDataFrame(d)[0])
            })
            //setSelKPI(KPIInit[0])
        } else {
            setKeyParams({ ...keyparams, kpimsg: "No Value Selected" })
        }
    };

    const ClickDeleteWalk = () => {
        GenericBackendCall(keyparams.workspacekey, schema, paths.generics.genericdelete, user, config, { field: "WorkKey", model: "Workspace" }, "wlk_walkdeldiv").then(d => {
            setKeyParams({ ...keyparams, refresh: 999 })
        })
    }

    //------------------------------------------

    const CheckStep0 = () => {
        setSchedule(ScheduleInit[0])
        setKPI(null)
        setQuery(null)
        setDates([])

        if (keyparams.workspacekey === "") {
            GenericBackendCall('', schema, paths.walkthrough.walkuploadstep, user, config, { step: '0' }, "wlk_divsubmitstep0").then(d => {
                console.log(d)
                setKeyParams({ ...keyparams, workspacekey: d[0], walkkey: d[1], step: 1, stepmax: 1, stepcheck: false, stepclear: false })
            })
        }
    }

    const CheckStep1 = () => {
        GenericBackendCall('', schema, paths.walkthrough.walkuploadstep, user, config,
            {
                step: '1', modelname: keyparams.modelname,
                workspacekey: keyparams.workspacekey, walkkey: keyparams.walkkey,
            }
            , "wlk_divsubmitstep1", 'Complete!').then(d => {
                if (d != "There was an error!") {
                    console.log(d)
                    setKeyParams({ ...keyparams, tablekey: d, stepclear: true, stepcheck: false, stepmax: 2 })
                }
            })
    }

    const CheckStep2 = () => {

        GenericBackendCall('', schema, paths.walkthrough.walkuploadstep, user, config,
            {
                step: '2', modelname: keyparams.modelname, columndata: sampleFields, data: keyparams.dfile,
                workspacekey: keyparams.workspacekey, walkkey: keyparams.walkkey, eventchoice: keyparams.eventchoice,
                uploadchoice: keyparams.uploadchoice, keyfield: keyparams.keyheader,
                datefield: keyparams.dateheader, datefield2: keyparams.dateheader2
            }
            , "wlk_divsubmitstep2").then(d => {
                if (d != "There was an error!") {
                    setKeyParams({ ...keyparams, tablekey: d, stepclear: true, stepcheck: false, stepmax: 3 })
                    GetTableFields()
                }
            })
    }

    const CheckStep3 = () => {
        let metr: Metric = {
            ...MetricInit[0],
            SchemaName: schema, WorkspaceName: keyparams.workspacekey, ConnectionModelName: keyparams.connmdlstring,
            Table: keyparams.modelname, MetricName: "WorkspaceTest",
            ReportTypeNumerator: "", ReportTypeDenominator: "None", MeasurementField: keyparams.metrfield, Measurement: keyparams.measurement
        }

        GenericBackendCall("*", schema, paths.walkthrough.walkuploadstep, user, config, { ...metr, step: '3', walkkey: keyparams.walkkey, asofdate: keyparams.asofDate }, "").then(d => {
            setKeyParams({ ...keyparams, metrickey: d, stepclear: true, stepcheck: false, stepmax: 4 })
        })

    }

    const CheckStep4 = () => {
        let qry: any = {
            ...queryparamsInit, nperiods: 1, xfieldname: keyparams.xfield, groupfield: keyparams.grpfield, modelname: "WorkspaceTest",
            metrics: [keyparams.metrickey], mappings: [], walkkey: keyparams.walkkey
        }

        GenericBackendCall("*", schema, paths.walkthrough.walkuploadstep, user, config,
            {
                ...qry, workspacekey: keyparams.workspacekey, step: '4', walkkey: keyparams.walkkey,
                asofdate: keyparams.asofDate, existingmodelkey: ''
            }, "").then(d => {
                setKeyParams({ ...keyparams, querykey: d, stepclear: true, stepcheck: false, stepmax: 5 })
            })
    }


    const CheckStep5 = () => {
        GenericBackendCall("", schema, paths.walkthrough.walkuploadstep, user, config, { ...schedule, walkkey: keyparams.walkkey, asofdate: keyparams.asofDate, step: '5' }, "").then(d => {
            setKeyParams({ ...keyparams, kpikey: d, stepclear: true, stepcheck: false, stepmax: 6 })
        })
    }

    const CheckStep6 = () => {
        let kpidata = {
            valueid: vis_states[0].selChartElemIndex, name: "WalkthroughKPI", selmetric: vis_states[0].selectedmetric,
            selmetricno: vis_states[0].selChartElemMetricNo, note: selBasicRow.Variable + "_" + selBasicRow.Grouping, workspacekey: keyparams.workspacekey, asofdate: keyparams.asofDate,
            step: '6', walkkey: keyparams.walkkey,
        }
        GenericBackendCall("", schema, paths.walkthrough.walkuploadstep, user, config, kpidata, "wlk_savekpidiv", "Complete").then(d => {
            setKeyParams({ ...keyparams, kpikey: d, stepclear: true, stepcheck: false, stepmax: 7 })
        })
    }

    const CheckStep7 = () => {
        GenericBackendCall(keyparams.kpikey, schema, paths.walkthrough.walkuploadstep, user, config, { step: '7', walkkey: keyparams.walkkey }, "").then(d => {
            setKeyParams({ ...keyparams, kpimsg: d, stepclear: true, stepcheck: false, stepmax: 8 })
        })
    }

    const CheckStep8 = () => {
        GenericBackendCall(keyparams.kpikey, schema, paths.walkthrough.walkuploadstep, user, config, { step: '8', walkkey: keyparams.walkkey }, "").then(d => {
            setKeyParams({ ...keyparams, kpimsg: d, stepclear: true, stepcheck: false, stepmax: 9 })
        })
    }
    const ClickExampleData = () => {
        GenericBackendCall(keyparams.kpikey, schema, paths.walkthrough.walkthroughdataexample, user, config, {}, "").then(d => {
            let data = SerializeDataFrame(d)
            ExcelExport({ csvDataArry: [data], sheetNames: ["Sheet1"], filename: 'ExampleData' })
        })
    }

    //------------------------------------------
    const Step0 = () => {
        return (<div>
            <div style={{ fontSize: "20px" }}>Start the walkthrough</div>
            <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...paramInit, connmdlstring: keyparams.connmdlstring, step: 0, stepclear: true, stepcheck: true, initimport: true }) }}
                style={{ margin: "5px", fontSize: "20px", width: "130px", height: "40px" }}>Create New</button>

            <div style={{ height: "300px", overflowY: "scroll" }}>
                {walkthroughs.map((wlk: WalkThroughDemo, i: number) => {
                    let namestr = ""
                    if (wlk.TableName) {
                        namestr = wlk.TableName.ModelName
                    }
                    return (<div className='ndt_item' key={i}
                        style={wlk.Key === keyparams.walkkey ? clickstyle.itmSelected : clickstyle.itmUnselected}
                        onClick={_ => { setExistingWalkthrough(wlk) }}
                    >Walkthrough {i}, Table: {namestr}, Step: {+wlk.CompletedStep + 1}</div>)
                })}</div>

            {existingWalkthrough ?
                <div style={{ marginTop: "5px" }}>
                    <div style={{ fontSize: "20px" }}>Selected Walkthrough:</div>
                    <button className='ndt_btn1' onClick={_ => { ConvertExisting() }}>Use Selected</button>
                    <button className='ndt_btn2' onClick={_ => { setKeyParams({ ...keyparams, isdelwalkvisible: true }) }}>Delete</button>
                </div> : <div></div>}
        </div>)
    }
    const Step1 = () => {
        return (<div>
            <div style={{ fontSize: "20px" }}>Import a dataset</div>
            <div style={{ display: "flex" }}>
                <input type="file" id="wlk_fileinput" name="dataFile" onChange={_ => FileLoad("wlk_fileinput")} />
                {keyparams.loadedfile ? <div>
                    <button style={{ marginLeft: "5px" }} className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, isnamingvisible: true }) }}>Upload Data</button>
                </div> : <div></div>}
            </div>
            {keyparams.isImported ?
                <div style={{ marginTop: "10px" }}>
                    <div style={{ fontSize: "20px" }}>Data Columns (default=Text):</div>
                    <div style={{ height: "500px", overflowY: "scroll" }}>
                        {sampleFields.map((val: samplefld, i: number) => {
                            return (<div key={i + "sample"} className='wlk_samplefld'>
                                <div style={{ width: '300px' }}>{StringTrim(val.Value, 50)}</div>
                                <div><DataTypeDropdown i={i} /></div>
                            </div>)
                        })}
                    </div>
                    <div>Data must include at least one date field</div>
                    <button className='ndt_btn3' style={{ marginTop: "5px" }} onClick={_ => { setKeyParams({ ...keyparams, isuploadvisible: true }) }}>Confirm Choices</button>
                </div> :
                <div style={{ margin: "10px", marginTop: "100px" }}>
                    <div style={{ fontSize: "17px" }}>No Data Handy?</div>
                    <div>
                        <button className='ndt_btn1' onClick={_ => { ClickExampleData() }}>Download Example Data</button>
                    </div>
                </div>}

        </div>)
    }

    const Step2 = () => {
        return (
            <div>
                <div style={{ fontSize: "22px" }}>Help us understand the data</div>

                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <div>What is the key field for this table "{keyparams.modelname}"? {keyparams.keyheader}</div>
                    <div>
                        <HeaderDdown valuefield={"keyheader"} filtertype="" />
                    </div>
                </div>

                <div>What is the date field: {keyparams.dateheader}</div>
                <button
                    style={keyparams.dateopenclose ? clickstyle.btnUnselected : clickstyle.btnSelected}
                    className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, dateopenclose: false }) }}>As of Date</button>
                <button
                    style={keyparams.dateopenclose ? clickstyle.btnSelected : clickstyle.btnUnselected}
                    className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, dateopenclose: true, uploadchoice: "Datefield", eventchoice: "Open/Close" }) }}>Open/Close Date</button>

                <div style={{ display: "flex", padding: '5px', backgroundColor: "rgb(50,50,50)", margin: "5px", border: "1px solid grey" }}>
                    <div style={{ width: "50%" }}>
                        <div>Select {keyparams.dateopenclose ? "Open Date " : "As of Date "} field</div>
                        <HeaderDdown valuefield="dateheader" filtertype={"Date"} />
                    </div>
                    {keyparams.dateopenclose ?
                        <div style={{ width: "50%" }}>
                            <div>Select Close Date field</div>
                            <HeaderDdown valuefield="dateheader2" filtertype={"Date"} />
                        </div>
                        : <div></div>}
                </div>
                {keyparams.dateheader !== "" && !keyparams.dateopenclose ?
                    <div>
                        <div>Do entries persist after their specified date?</div>
                        <div>
                            <button className='ndt_btn1'
                                style={keyparams.eventchoice === "Snapshot" ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                onClick={_ => { setKeyParams({ ...keyparams, eventchoice: "Snapshot", uploadchoice: "Multi" }) }}>Yes</button>
                            <button className='ndt_btn1'
                                style={keyparams.eventchoice === "PointinTime" ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                onClick={_ => { setKeyParams({ ...keyparams, eventchoice: "PointinTime", uploadchoice: "Datefield" }) }}>No</button>
                        </div>
                        <div style={{ margin: "5px" }}>


                        </div>

                    </div>
                    : <div></div>}
                {keyparams.uploadchoice !== "" || (keyparams.eventchoice === "Open/Close" && keyparams.dateheader !== "" && keyparams.dateheader2 !== "") ?
                    <button style={{ width: "120px" }} className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, stepcheck: true }) }}>Submit</button>
                    : <div></div>}


            </div>
        )
    }

    const Step3 = () => {

        return (<div>
            <div style={{ fontSize: "20px" }}>Write a query</div>
            <div>
                <div className='ndt_title3'>Metric</div>
                <div>
                    <div>Select Numeric field to aggregate or select count of:</div>
                    <div>
                        <GenericDropdown
                            data={tablefields}
                            keycol="Key"
                            namecol="FieldName"
                            className="ndt_dropdown"
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                ChangeParamSelectorbyIndex(e, tablefields, keyparams, setKeyParams, "metrfield", "Key", "")
                            }}
                            includecategory={true}
                            filterfield={"DataType"}
                            filtervalue={"Numeric"}
                            promptstring="Select Values Field"
                            value={keyparams.metrfield}
                        /> {GetFieldName(tablefields, keyparams.metrfield)}
                    </div>
                    <div>
                        <MeasurementDropdown
                            className="ndt_dropdown"
                            includetypes={["Numeric"]}
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                setKeyParams({ ...keyparams, measurement: e.target.selectedOptions[0].attributes[1].value })
                            }}
                            value={keyparams.measurement}
                        /> {keyparams.measurement}

                    </div>
                </div>

            </div>
            <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, stepcheck: true }) }}>Create</button>
        </div>)
    }

    const Step4 = () => {
        return (<div>
            <div>
                <div>X Field</div>
                <GenericDropdown
                    data={tablefields}
                    keycol="Key"
                    namecol="FieldName"
                    className="ndt_dropdown wlk_fldddown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                        ChangeParamSelectorbyIndex(e, tablefields, keyparams, setKeyParams, "xfield", "Key", "")
                    }}
                    promptstring="Select X Field"
                    value={keyparams.xfield}
                />{GetFieldName(tablefields, keyparams.xfield)}

                <div>Grouping Field</div>
                <GenericDropdown
                    data={tablefields}
                    keycol="Key"
                    namecol="FieldName"
                    className="ndt_dropdown wlk_fldddown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => {
                        ChangeParamSelectorbyIndex(e, tablefields, keyparams, setKeyParams, "grpfield", "Key", "")
                    }}
                    includeDefault={true}
                    promptstring="Select Grouping Field (optional)"
                    defaultstring="None"
                    value={keyparams.grpfield}
                /> {GetFieldName(tablefields, keyparams.grpfield)}
            </div>
            <div>
                <div>As of Date:</div>
                <DatePicker className="datepick_class" value={keyparams.asofDate} onChange={(e: any) => setKeyParams({ ...keyparams, asofDate: new Date(e) })} />
            </div>
            <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, stepcheck: true }) }}>Create</button>
        </div>)
    }

    const Step6 = () => {
        return (
            <div>
                <div style={{ fontSize: "22px" }}>Add KPI</div>
                {kpi ?
                    <div>
                        KPI Created (XField _ Grouping): {kpi.Note}
                    </div> : <div></div>}

                <div>Select Chart Element</div>
                <button className='ndt_btn1' onClick={_ => { ClickInspect() }}>Check Element</button>
                {selBasicRow ?
                    <div style={{ marginTop: "10px" }}>
                        <div>
                            <div style={{ fontSize: "20px" }}>Selected</div>
                            <div>Variable: {DateString(selBasicRow.Variable)}</div>
                            <div>Grouping: {DateString(selBasicRow.Grouping)}</div>
                            <div>Value: {DateString(selBasicRow.Value)}</div>
                        </div>
                        <div>
                            <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, iskpisavevisible: true }) }}>Submit</button>
                        </div>
                    </div> : <div>None Selected</div>}
            </div>
        )
    }


    const Step5 = () => {
        return (<div>
            <div style={{ fontSize: "22px" }}>Schedule</div>

            {schedule ?
                <div>
                    <div>From Date: {DateString(keyparams.asofDate)}</div>
                    <SchedulePeriodDropdown
                        className="ndt_dropdown"
                        schedule={schedule}
                        setSchedule={setSchedule}
                    />

                    <div>
                        Number per interation:
                        <input style={{ width: "100px" }} value={schedule.IPeriod} type="number" min={1} onChange={(e: ChangeEvent<HTMLInputElement>) => { if (schedule) { setSchedule({ ...schedule, IPeriod: +e.target.value }) } }} />
                    </div>
                </div>
                : <div>
                    <button className='ndt_btn1' onClick={_ => { setSchedule(ScheduleInit[0]) }}>Create New</button>
                </div>}
            {schedule && schedule.IPeriod !== 0 && schedule.Period !== "" ?
                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, stepcheck: true }) }}>Set Schedule</button>
                : <div></div>}
        </div>)
    }

    const Step7 = () => {
        return (
            <div>
                {query ?
                    <div>
                        <div>
                            <div >
                                <div style={{ fontSize: "20px" }}>Dates</div>
                                <div style={{ height: "400px", overflowY: "scroll", minWidth: "250px" }}>
                                    {dates.map((dt: matchdate, t: number) => {
                                        let match = ""
                                        if (dt.RunDateKey !== "") {
                                            match = " - Ran"
                                        }
                                        return (<div key={t} className='wlk_dateitm'
                                            style={t === keyparams.selDateIdx ? selStyle : {}}
                                            onClick={_ => { setKeyParams({ ...keyparams, selDateIdx: t, selrundatekey: dt.RunDateKey, isrundatevisible: true }) }}
                                        >{DateString(dt.Date)} {match}</div>)
                                    })}
                                </div>
                            </div>
                            <div>
                                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, rundateN: Math.min(20, keyparams.rundateN + 5) }) }}>Add Dates</button>
                                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, rundateN: Math.max(0, keyparams.rundateN - 5) }) }}>Rmv Dates</button>

                            </div>

                            <button className='ndt_btn3' onClick={_ => { setKeyParams({ ...keyparams, runall: true, isrunallvisible: true }) }}>Run All Blank</button>

                            <div style={{ margin: '10px' }}>
                                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, stepcheck: true }) }}>Check Step</button>

                            </div>
                        </div>
                    </div> : <div></div>}
            </div>
        )
    }
    const Step8 = () => {
        return (
            <div>
                <div style={{ fontSize: "22px" }}>KPI Trend</div>
                <div style={{ overflowY: "scroll" }}>
                    {kpivals.map((kpival: KPIValue, i: number) => {
                        return (<div key={i} className='walk_kpivalitm'>
                            <div style={{ width: "200px" }}>Date: {DateString(kpival.AsofDate)}</div>
                            <div>Value: {FormatLabel(kpival.Value, { format: "dec", rounding: 0 })}</div>
                        </div>)
                    })}
                </div>
                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, stepcheck: true }) }}>Continue</button>

            </div>
        )
    }
    // const Header_ChangeDate = (val: string) => {
    //     setKeyParams({ ...keyparams, dateheader: val })
    // }
    // const Header_ChangeDate2 = (val: string) => {
    //     setKeyParams({ ...keyparams, dateheader2: val })
    // }

    // const Header_ChangeKey = (val: string) => {
    //     setKeyParams({ ...keyparams, keyheader: val })
    // }

    const HeaderDdown = (props: any) => {
        let filtertype: any = props.filtertype;
        let valuefield: string = props.valuefield
        return (
            <select className='ndt_dropdown' value={GetObjectValue(keyparams, valuefield)}
                style={{ width: "200px" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setKeyParams({ ...keyparams, [valuefield]: e.target.selectedOptions[0].attributes[0].value })
                }}>
                <option value="" key={-1}>
                    Select Column
                </option>
                {sampleFields.map((val: samplefld, i: number) => {
                    if (filtertype === "" || filtertype === val.Type)
                        return (<option value={val.Value} key={i + "sample"} >
                            {val.Value}
                        </option>)
                })}
            </select>
        )
    };

    const chartmemo = useMemo(() => {

        if (keyparams.step >= 5 && keyparams.step <= 7) { //need to repeat this logic because of loading times
            ChartDelete("#wlk_kpigraph")
        }

        return (
            <ChartConst query={query} setQuery={setQuery} svgid="wlk_qryvw" vis_state={vis_states}
                setVis_State={setVis_States} qryvis={qryvis} stateIdx={0} style={{ "backgroundColor": "white" }}
                rundatekey={keyparams.selrundatekey}
                rerun={keyparams.rerun}
                metrics={tableMetrics}
                size={[700, 700]}
            />
        )
    }, [query, qryvis, keyparams.selrundatekey, keyparams.rerun, tableMetrics, vis_states, keyparams.step])



    return (<div className="ndt_canvas">

        <div className="ndt_gridbox ndt_header">
            <div className="ndt_title2">Data Walkthrough</div>
        </div>

        <div style={{ display: "flex" }}>
            {Object.keys(stepnames).map((k: string, i: number) => {
                if (i <= keyparams.stepmax) {
                    return (<div key={i} className="wlk_stepbtn">
                        <div className='wlk_keyitm ndt_btnmain' style={keyparams.step === i ? stepitmON : {}}
                            onClick={_ => { ChangeStepTo(i) }}>{i}. {GetObjectValue(stepnames, k)}</div>
                        <div style={{ marginTop: '8px', marginLeft: "4px", marginRight: "4px" }}>{`->`}</div>
                    </div>)
                }
            })}
        </div>

        <div id="wlk_main" style={{ display: "flex" }}>
            <div style={{ width: "800px" }}>
                {(() => {
                    switch (keyparams.step) {
                        case 0: return <div className="ndt_gridbox wlk_stepbox"><Step0 /></div>
                        case 1: return <div className="ndt_gridbox wlk_stepbox"><Step1 /></div>
                        case 2: return <div className="ndt_gridbox wlk_stepbox"><Step2 /></div>
                        case 3: return <div className="ndt_gridbox wlk_stepbox"><Step3 /></div>
                        case 4: return <div className="ndt_gridbox wlk_stepbox"><Step4 /></div>
                        case 5: return <div className="ndt_gridbox wlk_stepbox"><Step5 /></div>
                        case 6: return <div className="ndt_gridbox wlk_stepbox"><Step6 /></div>
                        case 7: return <div className="ndt_gridbox wlk_stepbox"><Step7 /></div>
                        case 8: return <div className="ndt_gridbox wlk_stepbox"><Step8 /></div>

                        default: return <div></div>;
                    }
                })()}
            </div>
            <div style={{ margin: '30px' }}>
                {keyparams.step > 5 && keyparams.step <= 7 ?
                    <div>

                        {query ?
                            <div>{chartmemo}</div> : <div></div>}

                    </div> : <div>
                        {keyparams.step > 7 ? <div style={{ display: "flex" }}>

                            <div id="wlk_kpigraph" style={{ height: "720px", width: "720px" }}></div>
                        </div> : <div></div>}
                    </div>}
            </div>

        </div>
        <div className='ndt_innerbox' style={{ display: "flex", background: "rgb(5,10,15)" }}>
            {keyparams.step > 0 ?
                <button className='ndt_btn1' style={{ marginRight: "430px", width: "120px" }} onClick={_ => { ChangeStep(-1) }}>Back</button>
                : <div></div>}
            <div style={{ fontSize: "20px", marginRight: "400px" }}>{GetObjectValue(stepnames, String(keyparams.step))}</div>
            {keyparams.stepmax > keyparams.step ? <button className='ndt_btn1' style={{ width: "120px" }} onClick={_ => { ChangeStep(1) }}>Next</button> : <div></div>}
            {/* <button style={{ marginLeft: "150px" }} onClick={_ => { setKeyParams({ ...keyparams, stepcheck: false }) }}>Clear Check</button> */}
        </div>


        <Modal show={keyparams.isnamingvisible}>
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Save Table</div>
                <input type="text" value={keyparams.modelname} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, modelname: e.target.value }) }} />

                {keyparams.modelname ?
                    <button className='ndt_btn1' onClick={_ => { FileSubmit() }}>Submit</button>
                    : <div></div>}
                <div id="wlk_divsubmitfld"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isnamingvisible: false })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isuploadvisible}>
            <div className="ndt_modal">
                <div style={{ fontSize: "20px" }}>Confirm Data Upload</div>
                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, stepcheck: true }) }}>Submit</button>
                <div id="wlk_divsubmitstep1"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isuploadvisible: false })}>Close</button>
            </div>
        </Modal >

        <Modal show={keyparams.isrundatevisible}>
            <div className="ndt_modal">
                {keyparams.selDateIdx >= 0 && dates.length > keyparams.selDateIdx ?
                    <div>
                        <div style={{ fontSize: "20px" }}>Run Date: {DateString(dates[keyparams.selDateIdx].Date)}</div>
                        <div>Option:</div>
                        {dates[keyparams.selDateIdx].RunDateKey !== "" ?
                            <button className='ndt_btn2' onClick={_ => { ClickDeleteRun() }}>Delete</button> :
                            <button className='ndt_btn1' onClick={_ => { ClickRun() }}>Run</button>
                        }
                    </div> :
                    <div></div>}

                <div id="wlk_daterundiv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isrundatevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>


        <Modal show={keyparams.isrunallvisible}>
            <div className="ndt_modal">
                <div>
                    <div className='ndt_modaltitle'>Run All Dates Remaining</div>
                    <button className='ndt_btn1' onClick={_ => { ClickRun() }}>Run All</button>
                </div>

                <div id="wlk_daterundiv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isrunallvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.iskpisavevisible}>
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Save this KPI?</div>
                <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, stepcheck: true }) }}>Submit</button>

                <button onClick={_ => { setKeyParams({ ...keyparams, stepcheck: false }) }}>Clear Check</button>
                <div id="wlk_savekpidiv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, iskpisavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>


        <Modal show={keyparams.isdelwalkvisible}>
            <div className="ndt_modal">
                <div className='ndt_modaltitle'>Delete Existing Walkthrough</div>
                <button className='ndt_btn2' onClick={_ => { ClickDeleteWalk() }}>Delete</button>
                <div id="wlk_walkdeldiv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelwalkvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

    </div >)
};
export default DatasetWalkthrough;