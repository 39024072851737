import AxiosUpload from "../../../../data/AxiosUpload";
import { loginActionCreator } from "../../../../reducers/user_actions";
import { Schema, User, UserAccount } from "../../../../type";
import { schemadeleteActionCreator } from "../../../../reducers/schema_actions";
import { schemaActionCreator } from "../../../../reducers/schema_actions";
import GetAuthConfigHeaders from "../../../../context/GetAuthConfigHeaders";

const LoginAuth = (user: User, dispatcher: any, schemas: Schema[] = [], allusers: UserAccount[] = []) => {
    //ref:  app, will be worked for login

    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer `,
        }
    };
    const body = JSON.stringify(user);

    let promise = AxiosUpload(body, "auth/jwt/create", config, null, "")
    promise.then(resp => {
        try {
            let rdata: any = resp?.data
            let accesstoken: string = rdata.access
            let refreshtoken: string = rdata.refresh

            //get user id from list of users
            let level = ""
            let defaultschema = ''
            let id = ""
            allusers.forEach((u: UserAccount) => {
                if (u.email === user.email) {
                    id = u.id
                    level = u.level
                    defaultschema = u.defaultschema
                }
            })

            //set user to state
            dispatcher(loginActionCreator({
                id: String(user.id),
                email: user.email,
                password: user.password,
                authtoken: accesstoken,
                refreshtoken: refreshtoken,
                isAuthenticated: true,
                level: level,
                defaultschema: defaultschema
            })) //.then((_: any) => { //this may be wrong
            //config = GetAuthConfigHeaders()
            //});


            if (schemas.length > 0) { //base login - reset schema
                dispatcher(schemadeleteActionCreator([]))
                let found = false

                schemas.forEach((sch: Schema) => {
                    if (sch.SchemaKey === defaultschema) {
                        dispatcher(schemaActionCreator(sch))
                        found = true
                    }
                })
            }

        } catch (err) {
            console.log(err)
        }
    })

    return config
};
export default LoginAuth