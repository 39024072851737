
const ParseKPIString = (str1: string) => {
    //converts coded string to array

    let kpstr: string[] = FindSubstringsBetweenRegex(str1, /\[(.*?)\]/g)
    let txtstr: string[] = FindSubstringsBetweenRegex("]" + str1 + "[", /\](.*?)\[/g)

    return ([txtstr, kpstr])
}

export default ParseKPIString


function FindSubstringsBetweenRegex(input: string, regex: any) {
    const matches = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
        matches.push(match[1]);
    }
    return matches;
};
