import axios from "axios";
import { User } from "../../type";


const QueryModelRerunMulti = async (modelkey: string, schemakey: string, workspacekey: string, dates: Date[][],
    schedules: string[][], rundatetext: string, user: User, config: any, elementstring: string) => {
    //ref: querymodelviewschedule, datasetwalkthrough

    let elem = document.getElementById(elementstring);
    //if (String(schedulekey) === "null") { schedulekey = "" }

    let arrdates = dates.map((dt: Date[]) => {
        return [
            dt[0].getFullYear(),
            dt[0].getMonth() + 1,
            dt[0].getDate(),
            dt[1].getFullYear(),
            dt[1].getMonth() + 1,
            dt[1].getDate(),
        ]
    });

    let scheds = schedules.map((sch: string[], i: number) => {

        if (sch.length > 0) {
            return sch.map((s: string) => {
                return [s, i]
            })
        } else {
            return ['', '']
        }
    })

    let dataArray = new FormData();
    dataArray.append("initialrun", String(false));
    dataArray.append("schemakey", schemakey)
    dataArray.append("workspacekey", workspacekey)
    dataArray.append("existingmodelkey", modelkey); //for models that are being updated
    dataArray.append("runDatetext", String(rundatetext));

    dataArray.append("dates", String(arrdates));
    dataArray.append("schedulekeys", String(scheds));

    dataArray.append("userid", user.id);

    let msg: string = "uploading..."
    if (elem) { elem.innerHTML = msg };

    try {
        await axios.post<any>(`${process.env.REACT_APP_API_URL}/api/query/basicpostmulti`, dataArray, config)
        msg = "Complete"
    }
    catch (err) {
        msg = "Error!"
    }

    if (elem) { elem.innerHTML = msg };

};
export default QueryModelRerunMulti