function ConvertArrayStringstoNumber(array: any[]) {

    return array.map((obj: any) => {
        // Iterate through the object's properties
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                // Check if the property is a numeric string and can be converted to a number
                if (!isNaN(obj[key]) && obj[key] !== '') {
                    obj[key] = parseFloat(obj[key]);
                }
            }
        }
        return obj;
    });
};
export default ConvertArrayStringstoNumber;