import DateAdd from "./DateAdd"
import ConvertToUTC from "./ConvertToUTC";
import DateRemoveTimestamp from "./DateRemoveTimestamp";

const CalculateStickyDate = (anchordate: Date, stickyref: string, period: string, iperiod: number, offset: number,
    includenext: boolean, makerecent: boolean = true,
    reldate: Date = new Date()) => {
    //ref:querymodelviewscheduler, schedulemanager, graphcreator, dashboardsmanage

    let date = DateRemoveTimestamp(anchordate)
    reldate = DateRemoveTimestamp(reldate)

    //let date = DateAdd(anchordate, 1) //removed 2.5.24

    let direction = 0

    if (anchordate <= reldate) {
        direction = 1 //future date
    } else {
        direction = -1 //past date
    }

    let lastdate = anchordate
    let a = 0
    let lstop = false
    do {
        a += 1
        lastdate = date


        let newdate = date
        if (period === "Yearly") {
            newdate = DateAdd(date, direction * 365 * iperiod)
        } else if (period === "Monthly") {
            newdate = new Date(date)
            newdate.setMonth(newdate.getMonth() + (1 * iperiod) * direction)

            //newdate = DateAdd(date, direction * 31 * iperiod)

        } else if (period === "Weekly") {

            newdate = DateAdd(date, direction * 7 * iperiod)
            //console.log(newdate)
        }
        date = newdate

        if ((lastdate <= reldate && reldate <= date && direction === 1) ||
            (date <= lastdate && lastdate <= reldate && direction === -1)) {
            lstop = true
        } else {
            lstop = false
        }
        //console.log(a, lastdate, date, date < lastdate, date > reldate)
    }

    while (!lstop && a < 100 && makerecent)
    //console.log("--stickydate", String(date).substring(0, 15), " from ", String(anchordate).substring(0, 15), "near ", String(reldate).substring(0, 15))
    //date = lastdate
    let p: number = 0
    if (!includenext) {
        p = 1
    }

    if (stickyref === "None") {
        //
    } else if (stickyref === "EOY") {
        date = ConvertToUTC(new Date(date.getFullYear() - p, 11, 31))
    } else if (stickyref === "EOW") {
        //date = DateAdd(date, 6 - date.getDay())
        date = ConvertToUTC(new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - (p * 7) - date.getDay())))
    } else if (stickyref === "EOM") {
        date = ConvertToUTC(new Date(date.getFullYear(), date.getMonth() + 1 - p, 1))
    }
    date = DateRemoveTimestamp(DateAdd(date, offset));

    return (date)
};
export default CalculateStickyDate;