import { Modal } from "react-bootstrap";
import { QueryModel, FieldMap, QueryRunDate, Key_Name, Metric } from "../../../type";
import { useState, useEffect, ChangeEvent, useContext } from "react";
import CustomTable from "../../../functions/EditableTable/CustomTable";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import FilterDataMultiple from "../../../functions/Filter Functions/FilterDataMultiple";
//import { StartupContext } from "../../../App";
import GetUniquesFromArrayMultiField from "../../../functions/Array Functions/GetUniquesfromArrayMultiField";
import FilterData from "../../../functions/FilterData";
import ConvertBackendArryString from "../../../functions/String Functions/ConvertBackendArryString";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import { StartupContext } from "../../../App";
import RunDateUpdate from "../../../functions/QueryFunctions/RunDateUpdate";


const QueryModelViewDataEditor = (props: any) => {

    console.log(props)
    let query: QueryModel = props.query
    let setQuery: any = props.setQuery
    let fields: FieldMap[] = props.fields
    let metrics: Metric[] = props.metrics
    const { config, schema, user, paths, appcolors, clickstyle } = useContext(StartupContext)

    //const { config, schema, user, paths } = useContext(StartupContext)

    interface params_interface {
        selrundatekey: string;
        showdetail: boolean;
    }
    interface filters_interface {
        complexity: string; detailfieldname: string; detailvalue: string; filtervalue1: string;
        filtervalue2: string, metric: string;
    }

    const [changeState, setChangeState] = useState<any>(0)
    const [data, setData] = useState<any[]>([])
    const [selRunDate, setSelRunDate] = useState<QueryRunDate | null>(null)
    const bqfieldnames = ["Variable", "Grouping", "Metric", "MetricNo", "Value", 'FilterFieldName1', "FilterValue1",
        'FilterFieldName2', "FilterValue2", "Complexity", "FilterLevel"]
    const bqfields: Key_Name[] = bqfieldnames.map((b: string) => { return { Key: b, Name: b } })

    const [keyparams, setKeyParams] = useState<params_interface>({
        selrundatekey: "", showdetail: false
    })
    const [filtkeys, setFiltKeys] = useState<filters_interface>({
        complexity: "Simple", detailfieldname: '', detailvalue: '', filtervalue1: '', filtervalue2: '', metric: ""
    })
    let [uniques, setUniques] = useState<any>({ detailfields: [], detailvalues: [], filtervalues1: [], filtervalues2: [] })

    const btnHighlightOn = { backgroundColor: "blue", boxShadow: "0px 0px 5px #f13a44" }
    const btnHighlightOff = { backgroundColor: "black", boxShadow: "" }

    const metrickeynames = ConvertBackendArryString(query.MetricOrder).map((m: string) => {
        return ({ "Key": m, "Name": GetObjectField(m, metrics, "MetricKey", "MetricName") })
    })

    useEffect(() => {
        if (keyparams.selrundatekey !== "") {
            RunDateUpdate(keyparams.selrundatekey, schema, query, setQuery, config)
        }
    }, [keyparams.selrundatekey])

    useEffect(() => {
        if (query.rundates) {
            query.rundates.forEach((rd: QueryRunDate) => {
                if (rd.DateKey === keyparams.selrundatekey) {
                    setSelRunDate(rd)
                    console.log(rd)
                    let dfunique = GetUniquesFromArrayMultiField(rd.basicquery, ['DetailFieldName'])
                    let flt1unique = GetUniquesFromArrayMultiField(rd.basicquery, ['FilterValue1'])
                    let fltdata1 = FilterData(flt1unique, ['FilterValue1'], [""], true)
                    let flt2unique = GetUniquesFromArrayMultiField(rd.basicquery, ['FilterValue2'])
                    let fltdata2 = FilterData(flt2unique, ['FilterValue2'], [""], true)

                    setUniques({ ...uniques, detailfields: dfunique, filtervalues1: fltdata1, filtervalues2: fltdata2 })
                }
            })
        }
    }, [query])

    useEffect(() => {
        query.rundates.forEach((rd: QueryRunDate) => {
            if (rd.DateKey === keyparams.selrundatekey) {
                let fltdata = FilterDataMultiple(rd.basicquery, ['Metric', "Complexity", "DetailValue", "FilterValue1", "FilterValue2"],
                    [filtkeys.metric, filtkeys.complexity, filtkeys.detailvalue, filtkeys.filtervalue1, filtkeys.filtervalue2], "and", false, true)

                setData(fltdata)
            }
        })
    }, [filtkeys])

    useEffect(() => {
        if (selRunDate) {
            let fltdata = FilterData(selRunDate.basicquery, ['DetailFieldName'], [filtkeys.detailfieldname])
            let fltdata2 = FilterData(fltdata, ['DetailFieldName'], [""], true)
            let fltunique = GetUniquesFromArrayMultiField(fltdata2, ['DetailValue'])
            setUniques({ ...uniques, detailvalues: fltunique })
        }
    }, [filtkeys.detailfieldname])

    return (<div className="ndt_canvas">
        <div className="ndt_gridbox">
            <GenericDropdown
                data={query.rundates}
                keycol="DateKey"
                namecol="AsofDate"
                promptstring="Select RunDate"
                change={(e: ChangeEvent<HTMLSelectElement>) => {
                    ChangeParamSelectorbyIndex(e, query.rundates, keyparams, setKeyParams, "selrundatekey", "DateKey", "")
                }}
            />
            {keyparams.selrundatekey !== "" ?
                <div>
                    Filters
                    <button className="ndt_btn1" style={keyparams.showdetail ? btnHighlightOn : btnHighlightOff}
                        onClick={_ => setKeyParams({ ...keyparams, showdetail: !keyparams.showdetail })}>Detail?</button>

                    <div style={{ display: "flex" }}>
                        <div style={{ width: "250px" }}>
                            {keyparams.showdetail ?
                                <div>
                                    {filtkeys.detailfieldname !== "" ? <div>
                                        <GenericDropdown
                                            data={uniques.detailvalues}
                                            keycol="DetailValue"
                                            namecol="DetailValue"
                                            promptstring="Select Detail Value"
                                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                                ChangeParamSelectorbyIndex(e, uniques.detailfields, filtkeys, setFiltKeys, "detailvalue", "DetailValue", "")
                                            }}
                                        />
                                    </div> : <div></div>}
                                </div> : <div></div>}


                        </div>
                        <GenericDropdown
                            data={metrickeynames}
                            keycol="Key"
                            namecol="Name"
                            promptstring="Select Metric"
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                ChangeParamSelectorbyIndex(e, metrickeynames, filtkeys, setFiltKeys, "metric", "Key", "")
                            }}
                            includeDefault={true}
                            defaultstring="(none)"
                        />
                        <GenericDropdown
                            data={uniques.filtervalues1}
                            keycol="FilterValue1"
                            namecol="FilterValue1"
                            promptstring="Select Filter 1 Value"
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                ChangeParamSelectorbyIndex(e, uniques.filtervalues1, filtkeys, setFiltKeys, "filtervalue1", "FilterValue1", "")
                            }}
                            includeDefault={true}
                            defaultstring="(none)"
                        />

                        <GenericDropdown
                            data={uniques.filtervalues2}
                            keycol="FilterValue2"
                            namecol="FilterValue2"
                            promptstring="Select Filter 2 Value"
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                ChangeParamSelectorbyIndex(e, uniques.filtervalues2, filtkeys, setFiltKeys, "filtervalue2", "FilterValue2", "")
                            }}
                            includeDefault={true}
                            defaultstring="(none)"
                        />

                        {keyparams.showdetail ? <GenericDropdown
                            data={uniques.detailfields}
                            keycol="DetailFieldName"
                            namecol="DetailFieldName"
                            promptstring="Select Detail Field"
                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                ChangeParamSelectorbyIndex(e, uniques.detailfields, filtkeys, setFiltKeys, "detailfieldname", "DetailFieldName", "")

                            }}
                        />
                            : <div style={{ backgroundColor: "white", color: "black", border: "1px solid grey" }}>Detail: None</div>}
                    </div>
                </div>
                : <div></div>}
        </div>
        <CustomTable
            data={data}
            colsHide={[]}
            rowcount={13}
            keycol={"id"}
            modelcategory="BasicQuery"
            changestate={setChangeState}
            optionalchoices={[]}
            hardcodedfields={bqfields}
            editable={{
                "Variable": false, "Grouping": true, "Value1": true, "Value2": true, "Value3": true, "Value4": true
            }}
            backendstring={"api/generics/datachange"}
            classname="empdatatbl"
            mainDiv="qmvde_tbl"
            fields={[]}
            shape={[700, 1100]}
        />

        <Modal show={false}>
            <div className="ndt_modal">

            </div>
        </Modal>
    </div>)
};
export default QueryModelViewDataEditor