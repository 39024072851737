//import * as d3 from 'd3';
import DateRemoveTimestamp from '../../functions/Date Functions/DateRemoveTimestamp';
import DateString from '../../functions/Date Functions/DateString';
import GetStackedValues from './GetStackedValues';
import AddGradienttoSVGDefs from './AddGradienttoSVGDefs';
import ChartGetColor from './ChartGetColor';


const CreateBarsHorizontal = (svg, classStr, data, detaildata, xfield, yfield, groups, xgroups, groupfield, xaxisfn, yaxisfn, isbenchmark,
    colorScale, secColorScale, colorgradienttype, width, barscount, bar_n, metrno, attributes, vis_state, setVis_State, stateIdx) => {

    let [valuefn, cumvaluefn] = GetStackedValues(data, xfield, yfield, xgroups, groupfield, isbenchmark)
    
    let barwidth = xaxisfn.bandwidth()/barscount;

    let g = svg.append('g')
    

    if (colorgradienttype==="Gradient"){
        AddGradienttoSVGDefs(svg, metrno, colorScale(metrno), secColorScale(metrno))
    }

    const Colorize = (d) =>{
        
        return ChartGetColor(d,d['EncodedKey'], colorScale, d['MetricNo'], groups, groupfield, colorgradienttype, vis_state, stateIdx)
    }



    //attrs order: id, class, data-id
    let bars = g.selectAll("rect1")
        .data(data)
        .enter().append("rect")                        
        .attr("class", classStr + "_bar ndtchartelem")
        .attr("y", function(d) { 
            return xaxisfn(DateString(DateRemoveTimestamp(d[xfield]),"%Y-%m-%d")) + barwidth*(bar_n); })
        // .style("fill", (d)=>{ return Colorize(d)})
        // .attr("basecolor",(d)=>{return Colorize(d)})
        // .attr("stroke",(_)=>{return "grey"})
        .attr("height", barwidth)
        .attr("width",function(d){
           return 0
        })
        // .attr("opacity",attributes['opacity'])
        // .attr("selected",0)

        //if (!attributes.reload){
            //bars.attr("x", function(_) { return yaxisfn(0) }) //was mina
            //.attr("width", function(_) { return Math.max(0,width - yaxisfn(0)); }) //was mina
    
            bars
            .transition()
                .duration(400)
                .attr("x",function(d) { 
                    let hval = valuefn(d)
                    let cval = cumvaluefn(d)
                    return yaxisfn(cval) - yaxisfn(hval); 
                })
                .attr("width",function(d) { 
                        let hval = valuefn(d)
                        return  yaxisfn(hval); 
                }) 
        // } else {
        //     bars.attr("x",function(d) { 
        //         let hval = valuefn(d)
        //         let cval = cumvaluefn(d)
        //         return yaxisfn(cval) - yaxisfn(hval); 
        //     })
        //     .attr("width",function(d) { 
        //             let hval = valuefn(d)
        //             return  yaxisfn(hval); 
        //     })   
        // }

         return(bars)
};
export default CreateBarsHorizontal;