import DateString from "./Date Functions/DateString";
import IsArray from "./Array Functions/IsArray";



function UniqueArrayValues(array, fields,joinval=""){
    //ref: threebargraph, chartsample

    //get unique values from a named field
    let uniquevals = [];
    array.forEach(c => {
        let cstr = ""
        if (IsArray(fields)){
            fields.forEach((fld)=>{
                if (cstr!==""){
                    cstr = cstr + joinval + String(c[fld])
                } else {
                    cstr = c[fld]
                }
            })
        } else{
            cstr = c[fields]
        }
        if (uniquevals.indexOf(cstr) === -1) {
            uniquevals.push(cstr)
        }
    })
    
    //sort
    uniquevals.sort( function(a, b) {
        if (a.length > 1){
            return (a[0] - b[0]);
        } else {
            if(a[0]===b[0]){
                return(a[0] - b[0])
            } else {
                return (a[1] - b[1]);
            }		
        }		
    });
    
    return(uniquevals)
}
export default UniqueArrayValues

export function UniqueArrayStringValues(array, field){

    //get unique values from a named field
    let uniquevals = [];
    array.forEach(c => {
        if (uniquevals.indexOf(DateString(c[field])) === -1) {
            uniquevals.push(DateString(c[field]))
        }
    })
    
    //sort
    uniquevals.sort( function(a, b) {
        if (a.length > 1){
            return (a[0] - b[0]);
        } else {
            if(a[0]===b[0]){
                return(a[0] - b[0])
            } else {
                return (a[1] - b[1]);
            }		
        }		
    });
    
    return(uniquevals)
}
