
import { useState, useEffect, useContext, ChangeEvent, useMemo } from "react";
import { FieldMap, TableData, TableModel, TblUploadSet, AllValues, Key_Name } from "../../../type";
import DatePicker from "react-date-picker";
import CustomTable from "../../../functions/EditableTable/CustomTable";
import GetFieldName from "../../../functions/GetFieldName";
import { StartupContext } from "../../../App";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import DateSort from "../../../functions/DateSort.js";
import FieldsUploadSample from "../../../data/Fields/Fields_UploadSample";
import MatchFieldLists from "../fields/subfunctions/MatchFieldLists";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import FilterData from "../../../functions/FilterData";
import TableDataUpload from "../../../data/Tables/TableData_Upload";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import { Modal } from "react-bootstrap";
import DateString from "../../../functions/Date Functions/DateString";
import { TableModelInit } from "../../../InitTypes";
import StringAfterDelimeter from "../../../functions/String Functions/StringAfterDelimeter";
import { Link, useNavigate } from "react-router-dom";
import GetUniquesFromObjectAttribute from "../../../functions/GetUniquesFromObjectAttribute";
import IsInVector from "../../../functions/IsInVector";
import RemovefromArray from "../../../functions/Array Functions/RemovefromArray";
import ConvertBackendArryString from "../../../functions/String Functions/ConvertBackendArryString";
import ObjectSort from "../../../functions/ObjectSort";
import "./css/TableManager.css"
import TableGroupsImport from "../../../data/Tables/TableGroups_Import";
import { useParams } from "react-router-dom";


const TableMgr = () => {

    let { id } = useParams()
    let history = useNavigate()

    const { config, schema, paths, user, clickstyle } = useContext(StartupContext)
    //let tablegroups = useSelector((state: any) => { return state.tablegroups })
    let [tablegroups, setTableGroups] = useState<any | null>(null)

    interface samplefld {
        Value: string;
    }
    interface paramInit {
        asofDate: Date; //the official date
        asofDateFrom: Date; //for datefield upload
        viewState: string;
        showTable: boolean;
        rerun: boolean;
        modelkey: string;
        modelcategory: string;
        datamsg: string;
        loaded: boolean;
        isImported: boolean;
        headerchange: boolean;
        rememberheaders: boolean;
        ischangesvisible: boolean;
        isdeluplvisible: boolean;
        isdelmdlvisible: boolean;
        issavevisible: boolean;
        isuploadvisible: boolean;
        dfile: any | null;
        uniquefldkey: string;
        uniquesOnFieldAttr: string;
        slice_how: string;
        newfieldexists: boolean;
        filldata: boolean;
        newvaluefld: string;
        batchupload: boolean;
        batchfield: string;
        refresh: number;
        selectedDate1: boolean;
        selectedDate2: boolean;
        uploadrowcount: number;
        usedaterange: boolean;
        uplviewtype: string;
    }

    interface modelparamInit {
        mdlkey: string;
        keyfieldkeys: string[];
        keyfieldnames: string[];
        namefieldkey: string;
        namefieldname: string;
        datefieldkey: string;
        datefield2key: string;
        newmodelname: string;
        uploadchoice: string;
        eventchoice: string;
        newkeyfieldstring: string;
        assumedropoff: string;
    }

    const [params, setParams] = useState<paramInit>({
        asofDate: new Date(), asofDateFrom: new Date(), viewState: "",
        showTable: false, rerun: false,
        modelcategory: "", modelkey: "",
        datamsg: '', loaded: false,
        isImported: false, dfile: null, issavevisible: false, isuploadvisible: false,
        headerchange: false, rememberheaders: false,
        ischangesvisible: false, isdeluplvisible: false, isdelmdlvisible: false,
        uniquefldkey: "", uniquesOnFieldAttr: "", slice_how: "",
        newfieldexists: false, filldata: false, newvaluefld: '', batchupload: false, batchfield: '', refresh: 0,
        selectedDate1: false, selectedDate2: false, uploadrowcount: 0, usedaterange: false, uplviewtype: ''
    })

    const [modelparams, setModelParams] = useState<modelparamInit>({
        mdlkey: "", namefieldkey: "", namefieldname: "", datefieldkey: '', datefield2key: '',
        keyfieldkeys: [], keyfieldnames: [],
        newmodelname: "", eventchoice: "",
        uploadchoice: "", newkeyfieldstring: '', assumedropoff: ''
    })
    const [nearbymodels, setNearbyModels] = useState<string[]>([])

    //const Fields: FieldMap[] = useSelector((state: any) => state.fieldmapping);
    let [fields, setFields] = useState<FieldMap[]>([]);
    const [primaryList, setPrimaryList] = useState<any[] | null>(null)
    const [tabledata, setTableData] = useState<TableData[]>([])
    const [selValue, setSelValue] = useState<string | null>(null)
    const [tblmdls, setTblMdls] = useState<TableModel[]>([])
    const [selTbl, setSelTbl] = useState<TableModel | null>({ ...TableModelInit[0], uploadsets: [] })
    const [selTUpload, setSelTUpload] = useState<TblUploadSet | null>(null)
    const [changeState, setChangeState] = useState<any>(0)
    const [sampleFields, setSampleFields] = useState<samplefld[]>([])
    const [headersList, setHeadersList] = useState<any>({})//{ 0: "", 1: "" }

    const [newvalues, setNewValues] = useState<AllValues[]>([])
    const [newvalueFields, setNewValueFields] = useState<Key_Name[]>([])

    let styleOn = { backgroundColor: "rgb(30,40,150)", boxShadow: "0px 0px 8px yellow" }
    let styleOff = { backgroundColor: "black", boxShadow: "" }

    useEffect(() => {
        if (id) {
            tblmdls.forEach((tbl: TableModel) => {
                if (tbl.ModelKey === id) {
                    setSelTUpload(null)
                    setSelTbl(tbl)
                    setSelValue(null)
                    setTableData([])
                    setHeadersList({})
                    setSampleFields([])
                    setNewValues([])
                    setNewValueFields([])
                    setParams({ ...params, viewState: '' })
                }
            })
        }
    }, [tblmdls])

    useEffect(() => {
        ImportAndSetStateGeneric(setTblMdls, "*", schema, paths.tables.tablemodelview, user, config, {})
        TableGroupsImport('', schema, paths.tables.connectionnetwork, user, config, "", false).then(d => {
            setTableGroups(d)
        })

    }, [])


    useEffect(() => {
        if (selTbl && tablegroups && Object.keys(tablegroups)) {
            if (tablegroups.length > 0) {
                Object.keys(tablegroups.tables).map((t: any) => {
                    let tgrp = tablegroups.tables[t]
                    if (IsInVector(tgrp, selTbl.ModelName)) {
                        setNearbyModels(tgrp)
                    }
                })
            }
            history("/tables/manager/" + selTbl.ModelKey)
        }
    }, [selTbl])


    useEffect(() => {
        if (params.refresh >= 100) { window.location.reload() }
    }, [params.refresh])

    useEffect(() => {
        //going to run twice, occasionally
        if (params.rerun && selTbl) {
            let backenddata = {
                how: params.slice_how, onfield: params.uniquesOnFieldAttr,
                value: selValue, asofdate: params.asofDate,
                modelcategory: selTbl.ModelKey, uplset: ''
            }
            if (selTUpload && params.slice_how === "UploadSet") {
                backenddata['uplset'] = selTUpload.UploadKey
            }
            let promise = GenericBackendCall("", schema, paths.tables.tabledataview, user, config, backenddata)
            promise.then((d: any) => {

                setTableData(DateSort(d, "CurrDate"));
                if (d.length > 0) {
                    setParams({ ...params, "showTable": true, 'rerun': false, datamsg: '' })
                } else {
                    if (params.rerun) {
                        setParams({
                            ...params, 'rerun': false, datamsg: "No data available!"
                        })
                    }
                }
            })
        }
    }, [params.rerun])

    useEffect(() => {
        console.log("reimport")
        if (params.isImported) {
            if (fields.length > 0) {
                let hf = MatchFieldLists(sampleFields, fields);
                let newexists = false;
                let primarykey = 0;
                Object.keys(hf).forEach((k: any) => {

                    if (hf[k][0] === selTbl?.DataNameField) { primarykey = k }
                    if (hf[k][4] === "True") { newexists = true; }
                })
                if (hf[primarykey][4] === "True") { newexists = false; } //override - if primary key is new, no data should exist

                setHeadersList(hf)
                setParams({ ...params, newfieldexists: newexists })
            }
        }
    }, [params.isImported])

    useEffect(() => {
        if (selTUpload) {

            let promise = ImportAndSetStateGeneric(setNewValues, selTUpload.UploadKey, schema, paths.fields.allvaluesview, user, config, {})
            promise.then(d => {

                //set unique fields
                let fldkeys = GetUniquesFromObjectAttribute(d, "FieldName", true)
                let fldkeynames = fldkeys.map((f: string) => {
                    return { Key: f, Name: GetFieldName(fields, f) }
                })
                setNewValueFields(fldkeynames)
                GenericBackendCall(selTUpload.UploadKey, schema, paths.tables.tableuploadview, user, config, {}).then(d => {
                    console.log(d)
                    if (d.length === 1) {
                        setParams({ ...params, uploadrowcount: d[0].rowcount })
                    }
                })
            })
        }
    }, [selTUpload])

    useEffect(() => {

    }, [selTbl])

    useEffect(() => { //table name field
        let fname = StringAfterDelimeter(modelparams.namefieldkey, "__")
        setModelParams({ ...modelparams, namefieldname: fname })
    }, [modelparams.namefieldkey, fields])



    useEffect(() => {
        if (params.uniquefldkey !== "" && selTbl) {
            ImportAndSetStateGeneric(setPrimaryList, selTbl.ModelKey, schema, paths.tables.tabledataunique, user, config,
                { onfield: params.uniquefldkey })
            setParams({ ...params, uniquesOnFieldAttr: GetFieldName(fields, params.uniquefldkey, "Key", "AttrName") })
        }
    }, [params.uniquefldkey])

    useEffect(() => {
        if (selTbl) {
            if (selTbl.ModelName !== "") {
                let fldpromise = GenericBackendCall(selTbl.ModelName, schema, paths.fields.fieldretrieve, user, config, { "ModelCategory": selTbl.ModelName })
                fldpromise.then(d => {
                    let flds = d
                    flds = FilterData(flds, "AttrName", "PrimaryField", true)
                    setFields(flds)

                    let nkey = ""
                    if (selTbl.DataNameField !== "") { nkey = selTbl.DataNameField }
                    let keyflds: string[] = []
                    if (selTbl.DataKeyField !== "") {
                        keyflds = ConvertBackendArryString(selTbl.DataKeyField)
                    }

                    let nameflds: string[] = keyflds.map((k: string) => { return GetFieldName(d, k) })
                    setParams({ ...params, modelcategory: selTbl.ModelName, modelkey: selTbl.ModelKey })
                    setModelParams({ ...modelparams, mdlkey: selTbl.ModelKey, namefieldkey: nkey, keyfieldkeys: keyflds, keyfieldnames: nameflds })

                    //sort tableuploads
                    let sorteduploads = ObjectSort(selTbl.uploadsets, "AsofDate", true)
                    //setSelTbl({ ...selTbl, uploadsets: sorteduploads })
                })
            }
        } else {
            setParams({ ...params, modelcategory: "", modelkey: "", viewState: "newtable" })
            setModelParams({ ...modelparams, mdlkey: "" })
        }

    }, [selTbl?.ModelKey])

    //---file/backend functions

    const FileLoad = () => {
        let elem: any = document.getElementById("dt_fileinput")
        let dfile = elem.attributes[0].ownerElement?.files[0]
        setParams({ ...params, dfile: dfile, loaded: true })
    }

    const FileSubmit = () => {
        if (params.dfile) {
            let uploadpromise = FieldsUploadSample(params.dfile, schema, config, user, "")
            uploadpromise.then((sd: any) => {
                setSampleFields(sd.data)
                setParams({ ...params, isImported: true })
            })
        }
    };


    const ClickSaveUpl = () => {
        if (params.batchfield == "" && selTbl && selTbl.DateUploadType === "Multi") {
            console.log("don't save")
        } else {
            if (params.dfile) {
                TableDataUpload(params.dfile, schema, paths.tables.tabledatapost, params.modelkey, params.modelcategory,
                    params.asofDate, params.asofDateFrom, headersList, params.rememberheaders, params.filldata, params.batchfield, nearbymodels, config, "ttmg_divupl").then(_ => setParams({ ...params, refresh: 99 }))
            };
        }
    };

    const SubmitChanges = () => {
        GenericBackendCall("", schema, paths.tables.tablemodelchange, user, config, modelparams, "tmgr_divchg").then(_ => setParams({ ...params, refresh: 99 }))
    };

    const DeleteUploadSet = () => {
        if (selTUpload) {
            GenericBackendCall(selTUpload.UploadKey, schema, paths.generics.genericdelete, user, config, { model: "TblUploadSet", field: "UploadKey" }, "tmgr_divdelupl").then(_ => setParams({ ...params, refresh: 99 }))
        }
    };

    const ClickDelete = () => {
        if (selTbl) {
            GenericBackendCall(selTbl.ModelKey, schema, paths.tables.tablemodeldelete, user, config, { modelname: selTbl.ModelName }, "ttmg_divdelete").then(_ => setParams({ ...params, refresh: 99 }))
        }
    };

    const ClickSaveMdl = () => {
        GenericBackendCall("", schema, paths.tables.tablemodelpost, user, config,
            {
                modelname: modelparams.newmodelname, uploadchoice: modelparams.uploadchoice, eventchoice: modelparams.eventchoice,
                assumedropoff: modelparams.assumedropoff
            }, "ttmg_divsave").then(_ => setParams({ ...params, refresh: 99 }))
    };


    //-------------------------
    const ClickChangeKeyField = (fld: FieldMap, checkstr: string) => {
        let newkeys = modelparams.keyfieldkeys
        if (checkstr === "") { //new to fld
            newkeys.push(fld.Key)
        } else {
            newkeys = RemovefromArray(modelparams.keyfieldkeys, fld.Key, "")
        }

        let newstr = "["
        newkeys.forEach((k: string) => {
            newstr = newstr + k + ","
        })
        newstr = newstr.substring(0, newstr.length - 1) + "]"
        let nameflds: string[] = newkeys.map((k: string) => { return GetFieldName(fields, k) })

        setModelParams({ ...modelparams, })
        setModelParams({ ...modelparams, keyfieldkeys: newkeys, newkeyfieldstring: newstr, keyfieldnames: nameflds })
    }

    const IsTable = () => {
        if (params.showTable && tabledata.length > 0) {
            let headers: String[] = [];
            Object.keys(tabledata[0]).forEach(key => {
                headers.push(StringAfterDelimeter(key, "__"))
            });

            return (<div >
                <CustomTable data={tabledata}
                    keycol="AttrKey"
                    rowcount={10}
                    optionalchoices={[]}
                    colsHide={[]}
                    changestate={setChangeState}
                    modelcategory={params.modelcategory}
                    backendstring={paths.generics.datachange}
                    hardcodedfields={[{ Key: "PrimaryField", Name: "KeyField" }, { Key: "PrevDate", Name: "PrevDate" },
                    { Key: "CurrDate", Name: "CurrDate" }, { Key: "NextDate", Name: "NextDate" }]}
                    editable={{
                        "PrimaryField": false, "Attribute1": true,
                        "Attribute2": true, "Attribute3": true, "Attribute4": true
                    }}
                    fields={fields}
                    shape={[1200, 500]}
                />
            </div>)
        } else {
            return (<div>{params.datamsg}</div>)
        }
    };

    const fileinputmemo = useMemo(() => {
        return (<input type="file" id="dt_fileinput" name="reviewFile" onChange={_ => FileLoad()} />)
    }, [modelparams.newmodelname, params.viewState, params.loaded])

    const View_Upload = () => {
        return (
            <div>
                {fields.length > 0 ?
                    <div>
                        {selTbl?.DataKeyField !== "" ?
                            <div>
                                {selTbl?.DateUploadType !== "Datefield" || selTbl?.DateField !== "" ?
                                    <div>
                                        <div className="ndt_title3">Import Data</div>
                                        <div id="submitruleform" style={{ 'marginBottom': '20px', 'textAlign': 'left' }}>
                                            <div id="dptupload_form">
                                                <div style={{ display: "block", overflowX: "hidden" }} className="dt_uploadline">
                                                    <div>
                                                        <div style={{ marginRight: "10px" }}>Import File</div>
                                                        {fileinputmemo}
                                                        <div></div>
                                                    </div>
                                                    <br />
                                                    <div >
                                                        {params.loaded ?
                                                            <button className="ndt_btn1" onClick={_ => FileSubmit()}>Import</button>
                                                            : <div></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <div>No Date field selected!</div>}
                            </div>
                            : <div>No Table Key Field Selected</div>}
                    </div> : <div>Add Fields to this Table First</div>}
            </div >
        )
    };

    const View_VerticalSingleValue = () => {

        return (
            <div>
                <div className="ndt_title3">Single {modelparams.namefieldname} History</div>
                <GenericDropdown
                    data={fields}
                    keycol="Key"
                    namecol="FieldName"
                    promptstring="Select Field"
                    className="ndt_dropdown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, fields, params, setParams, "uniquefldkey", "Key", "") }}
                />
                {
                    primaryList ?
                        <div>
                            <div>Select {StringAfterDelimeter(params.uniquefldkey, "__")} to view detail</div>
                            <GenericDropdown
                                data={primaryList}
                                keycol={params.uniquesOnFieldAttr}
                                namecol={params.uniquesOnFieldAttr}
                                divID="tmgr_ddown"
                                promptstring="Select Data"
                                change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, primaryList, setSelValue, params.uniquesOnFieldAttr)} />
                            <button onClick={_ => { setParams({ ...params, rerun: true }) }}>Import</button>
                        </div>
                        : <div></div>
                }
            </div>)
    };

    const View_HorizontalDateslice = () => {
        return (
            <div style={{ overflow: "visible" }}>
                <div className="ndt_title3">Data: By Date</div>
                <br />
                <div>
                    <DatePicker className="datepick_class" value={params.asofDate} onChange={(e: any) => setParams({ ...params, asofDate: new Date(e) })} />
                </div>

                <button style={{ marginTop: "10px" }} className="ndt_btn1" onClick={_ => {
                    setParams({ ...params, rerun: true })
                }}>Import Data</button>
            </div >
        )
    };


    const ChangeHeader = (e: ChangeEvent<HTMLSelectElement>, i: number) => {
        let val = e.target.selectedOptions[0].attributes[0].value
        let newlist = [headersList[i][0], headersList[i][1], val, "True", headersList[i][4], "Y"];
        if (val = "") {
            newlist = [headersList[i][0], headersList[i][1], val, "True", headersList[i][4], ""]
        }
        setHeadersList({ ...headersList, [i]: newlist })
        setParams({ ...params, headerchange: true })
    };


    const HeaderDdown = (props: any) => {
        let val: number = props.val;

        return (
            <select onChange={(e: ChangeEvent<HTMLSelectElement>) => ChangeHeader(e, val)}>
                <option value="" key={-1}>
                    Select Column
                </option>
                {sampleFields.map((val: samplefld, i: number) => {
                    return (<option value={val.Value} key={i + "sample"} >
                        {val.Value}
                    </option>)
                })}
            </select>
        )
    };

    const ViewUpload = () => {
        return (<div>
            {params.dfile && params.isImported ? <div>
                <div className="ndt_title3" style={{ margin: '5px' }}>Confirm Fields for Upload</div>
                <div>
                    <div key={-1} className="dt_head">
                        <div className="dt_headitem">Field</div>
                        <div className="dt_headitem"> File header</div>
                        <div className="dt_headitem">Is Required Field? </div>
                        <div className="dt_headitem">Is New Field? </div>
                        <div className="dt_headitem"> Manual Select </div>
                    </div>

                    {Object.keys(headersList).map((k: any, i: number) => {
                        let bstyle = { backgroundColor: "" }
                        if (headersList[k][2] === "") {
                            bstyle = { backgroundColor: "blue" }
                        }
                        return (
                            <div key={i} data-value={headersList[k][1]} id={"tfld_" + i} style={bstyle} className="dt_field">
                                <div className="dt_fielditem"  > {headersList[k][1]} </div>
                                <div className="dt_fielditem" > {headersList[k][2]} </div>
                                <div className="dt_fielditem" > {headersList[k][3]} </div>
                                <div className="dt_fielditem" > {headersList[k][4]} </div>
                                <div id={"tflddwn_" + i} className={"dt_fielditem"} > <HeaderDdown val={i} /> </div>
                            </div>
                        )
                    })}
                    <div style={{ display: "block" }}>
                        {params.headerchange ?
                            <button style={params.rememberheaders ? styleOn : styleOff} id="tmgr_hdrbtn" className="ndt_btn2" onClick={_ => { setParams({ ...params, rememberheaders: !params.rememberheaders }) }}>
                                {params.rememberheaders ? "Headers will save" : "Remember headers?"}
                            </button>
                            : <div></div>}
                        {params.newfieldexists ? <button className="ndt_btn2" onClick={_ => setParams({ ...params, filldata: !params.filldata })} style={params.filldata ? styleOn : styleOff}>Fill new fields historically by primary field
                            {selTbl ? " " + StringAfterDelimeter(String(selTbl.DataNameField), "__") : ""}
                        </button> :
                            <div></div>}

                        <button style={{ marginTop: '15px' }} className="ndt_btn1" onClick={_ => { setParams({ ...params, isuploadvisible: true }) }}> Submit {params.modelcategory} Data</button>
                    </div>
                    <div id="tmgr_divsubmit"></div>
                </div>
            </div>
                : <div style={{ padding: "10px" }}>
                    <div className="ndt_title3">Fields:</div>
                    <div className="ndt_subinner" style={{ width: "30%" }} >
                        <div style={{ height: "500px", overflow: "scroll" }}>
                            {fields.map((fld: FieldMap, i: number) => {
                                return (<div key={i} style={{ fontSize: "20px" }}>
                                    {fld.FieldName}
                                </div>)
                            })}
                        </div>
                    </div>
                    <br />
                </div>}
        </div>)
    };

    const View_Manage = () => {
        return (<div >
            <div >
                <div className="ndt_innerbox" style={{ display: "flex", width: "60%", margin: "auto", padding: "5px" }}>
                    <div style={{ margin: '5px' }}>Rename Model</div>
                    <input type="text" id="dptmdl_input" value={modelparams.newmodelname}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setModelParams({ ...modelparams, "newmodelname": e.target.value })} />
                </div>
                <div style={{ display: "flex" }}>
                    <div className="ndt_innerbox" style={{ minWidth: "230px", width: '50%', height: "300px" }}>
                        <div style={{ fontSize: "22px", display: "flex" }}>
                            <div style={{ marginRight: "10px" }}>Set Key Fields:</div>
                            {modelparams.keyfieldnames.map((f: string, i: number) => {
                                return (<div style={{ fontSize: "16px", marginTop: "5px", marginLeft: "5px" }} key={i}>{f}, </div>)
                            })}
                        </div>
                        <div style={{ overflowY: "scroll", height: "250px" }}>
                            {fields.map((fld: FieldMap, i: number) => {
                                let checkstr = ""
                                if (IsInVector(modelparams.keyfieldkeys, fld.Key)) {
                                    checkstr = "Selected"
                                }
                                return (<div key={i} className="tmgr_keyflditem" onClick={_ => ClickChangeKeyField(fld, checkstr)}>
                                    <div style={{ width: "50%" }}>{fld.FieldName}</div>
                                    <div style={{ width: "50%" }}>{checkstr}</div>
                                </div>)
                            })}
                        </div>
                    </div>

                    <div className="ndt_innerbox" style={{ minWidth: "230px", width: "45%", padding: "10px" }}>
                        <div style={{ fontSize: "22px" }}>Set Name Field</div>
                        <div style={{ marginLeft: "15px" }}>
                            <div>Currently: {modelparams.namefieldname}</div>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginRight: '5px' }}>Set To</div>
                                <GenericDropdown
                                    data={fields}
                                    keycol="Key"
                                    namecol="FieldName"
                                    promptstring="Select Name Field"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, fields, modelparams, setModelParams, "namefieldkey", "Key", "") }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {selTbl?.DateUploadType === "Datefield" ?

                    <div className="ndt_innerbox" style={{ minWidth: "230px", width: "85%", padding: "10px", display: "flex" }}>
                        <div style={{ marginRight: "20px" }}>
                            <div style={{ fontSize: "20px" }}>Set {selTbl.DateEventType === "Open/Close" ? "Open " : "As of "} Date Field:</div>
                            <div>Currently: {GetFieldName(fields, selTbl.DateField)}</div>
                            <GenericDropdown
                                data={fields}
                                keycol="Key"
                                namecol="FieldName"
                                promptstring="Select Name Field"
                                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, fields, modelparams, setModelParams, "datefieldkey", "Key", "") }}
                                filterfield="DataType"
                                filtervalue="Date"
                                className="ndt_dropdown"
                                value={modelparams.datefieldkey}
                            />
                        </div>

                        {selTbl?.DateEventType === "Open/Close" ?
                            <div>
                                <div style={{ fontSize: "20px" }}>Set Close Date Field:</div>
                                <div>Currently: {GetFieldName(fields, selTbl.DateField)}</div>
                                <GenericDropdown
                                    data={fields}
                                    keycol="Key"
                                    namecol="FieldName"
                                    promptstring="Select Name Field"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, fields, modelparams, setModelParams, "datefield2key", "Key", "") }}
                                    filterfield="DataType"
                                    filtervalue="Date"
                                    className="ndt_dropdown"
                                    value={modelparams.datefield2key}
                                /> </div>
                            : <div></div>}
                    </div> : <div></div>}
                <br />

                <div className="ndt_innerbox" style={{ display: "flex" }}>
                    <button className="ndt_btn1" style={{ margin: "20px" }} onClick={_ => { setParams({ ...params, ischangesvisible: true }) }}>Submit Changes</button>
                    <div style={{ marginLeft: "60%" }}>
                        <div>Delete this Table</div>
                        <button className="ndt_btn2" onClick={_ => { setParams({ ...params, isdelmdlvisible: true }) }}>Delete</button>
                    </div>
                </div>
            </div>
        </div>)
    };

    const managemodelmemo = useMemo(() => View_Manage(), [modelparams, selTbl?.ModelName])


    const ClickTblUplItem = (upl: TblUploadSet) => {
        if (upl.UploadKey !== selTUpload?.UploadKey) {
            setSelTUpload(upl)
        } else {
            setSelTUpload(null)
        }
        setParams({ ...params, uplviewtype: '' })
    };

    const UplHistDetail_NewValues = () => {
        return (
            <div>
                <div className="ndt_title4">New Values</div>
                <div style={{ display: "flex", overflowX: 'scroll' }}>
                    {newvalueFields.map((kn: Key_Name, i: number) => {
                        return (<div className="ndt_item" style={{ width: '200px' }} key={i}
                            onClick={_ => { setParams({ ...params, newvaluefld: kn.Key }) }}
                        >
                            {kn.Name}
                        </div>)
                    })}
                </div>

                New Values: {params.newvaluefld !== "" ? StringAfterDelimeter(params.newvaluefld, "__") : ""}
                <div style={{ overflowY: "scroll" }}>
                    {newvalues.map((nv: AllValues, i: number) => {
                        if (nv.FieldName === params.newvaluefld) {
                            return (<div key={i} className="dmgr_newvalitm">{nv.Value}</div>)
                        }
                    })}
                </div>
            </div>
        )
    }

    const UplHistDetail_Data = () => {
        return (
            <div>
                <IsTable />
            </div>
        )
    }

    const Lower_UploadHistory = () => {
        return (<div>
            <div className="ndt_title3">Uploaded Data</div>
            <div id="tmgr_uplhistgrid">
                <div style={{ marginLeft: "100px" }}>
                    {(() => {
                        switch (params.uplviewtype) {
                            case "Data": return <UplHistDetail_Data />;
                            case "NewValues": return <UplHistDetail_NewValues />;
                            default: return <div>Select View</div>;
                        }
                    })()}
                </div>
            </div>
        </div>)
    };

    const ViewUpper_UplHist = () => {
        return (<div>
            <div className="ndt_title3">Upload History </div>
            <div style={{ display: "flex" }}>
                <div style={{ width: '40%', minWidth: "300px" }}>
                    <div style={{ fontSize: "19px", letterSpacing: ".05em" }}>Uploads:</div>
                    <div style={{ overflowY: "scroll", height: "180px" }}>{selTbl?.uploadsets.map((upl: TblUploadSet, i: number) => {
                        let uplstyle = { backgroundColor: "black" }
                        if (upl.UploadKey === selTUpload?.UploadKey) {
                            uplstyle = { backgroundColor: "blue" }
                        }
                        return (<div key={i} className="tmgr_uplitm"
                            style={uplstyle}
                            onClick={_ => ClickTblUplItem(upl)}>
                            Date: {DateString(upl.AsofDate)}
                        </div>)
                    })}
                    </div>
                </div>

                {selTUpload ?
                    <div>
                        <div style={{ fontSize: "19px", letterSpacing: ".05em" }}>Selected UploadSet {DateString(selTUpload.CreateDate)}</div>
                        <div style={{ background: "rgb(20, 20, 20)", border: "1px solid grey", width: "400px", padding: "5px", margin: "5px" }}>
                            <div style={{ fontSize: "17px" }}>Count of Rows: {params.uploadrowcount}</div>
                            <div style={{ marginTop: "10px" }}>
                                <div style={{ fontSize: "17px", margin: "5px" }}>Select View:</div>
                                <button className="ndt_btn1" style={params.uplviewtype === "Data" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setParams({ ...params, uplviewtype: "Data", slice_how: "UploadSet", rerun: true }) }}>All Data</button>
                                <button className="ndt_btn1" style={params.uplviewtype === "NewValues" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setParams({ ...params, uplviewtype: "NewValues" }) }}>New Values</button>
                            </div>
                            <br />
                            <button className="ndt_btn2" onClick={_ => { setParams({ ...params, isdeluplvisible: true }) }}>Delete This Data</button>
                        </div>
                    </div>
                    : <div></div>}
            </div>

        </div>)
    }

    const View_NewModel = () => {
        return (
            <div style={{ display: "flex" }}>

                <div style={{ width: "40%" }}>
                    <div style={{ fontSize: "24px", marginBottom: "20px", letterSpacing: ".1em" }}>Add New Table</div>
                    <div>Table Name</div>
                    <input id="tmgr_newtblnamediv" type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => { setModelParams({ ...modelparams, newmodelname: e.target.value }) }} />

                    <div style={{ marginTop: "40px" }}>
                        {modelparams.newmodelname !== "" && modelparams.uploadchoice !== "" && modelparams.eventchoice !== "" && modelparams.assumedropoff !== "" ?
                            <button id="tmgr_newtblsave" className="ndt_btn3" style={{ width: "120px" }}
                                onClick={_ => { setParams({ ...params, issavevisible: true }) }}>Submit Table</button>
                            : <div></div>}
                    </div>
                </div>

                <div id="tmgr_newtblfeaturesdiv">
                    <div style={{ fontSize: "20px", marginTop: "10px", marginBottom: "10px" }}> - Features - </div>
                    <div>
                        <div>Table Upload Type</div>
                        <button className="ndt_btn1" style={modelparams.uploadchoice === "Single" ? styleOn : styleOff}
                            onClick={_ => setModelParams({ ...modelparams, uploadchoice: "Single" })}>Single Date Upload</button>
                        <button className="ndt_btn1" style={modelparams.uploadchoice === "Multi" ? styleOn : styleOff}
                            onClick={_ => setModelParams({ ...modelparams, uploadchoice: "Multi" })}>Multi</button>
                        <button className="ndt_btn1" style={modelparams.uploadchoice === "Datefield" ? styleOn : styleOff}
                            onClick={_ => setModelParams({ ...modelparams, uploadchoice: "Datefield" })}>Use Date Field</button>
                    </div>
                    <div>
                        <div>Date Event Types</div>
                        <div style={{ display: "flex" }}>
                            <button className="ndt_btn1" style={modelparams.eventchoice === "Snapshot" ? styleOn : styleOff}
                                onClick={_ => setModelParams({ ...modelparams, eventchoice: "Snapshot" })}>Snapshot</button>
                            {modelparams.uploadchoice === "Datefield" ?
                                <div>
                                    <button className="ndt_btn1" style={modelparams.eventchoice === "PointinTime" ? styleOn : styleOff}
                                        onClick={_ => setModelParams({ ...modelparams, eventchoice: "PointinTime", assumedropoff: "False" })}>PointinTime</button>
                                    <button className="ndt_btn1" style={modelparams.eventchoice === "Open/Close" ? styleOn : styleOff}
                                        onClick={_ => setModelParams({ ...modelparams, eventchoice: "Open/Close", assumedropoff: "False" })}>Open/Close</button>
                                </div> : <div></div>}
                        </div>
                    </div>
                    {modelparams.eventchoice === "Snapshot" ?
                        <div>
                            <div>Assume Data Dropoff</div>
                            <button className="ndt_btn1" style={modelparams.assumedropoff === "True" ? styleOn : styleOff}
                                onClick={_ => setModelParams({ ...modelparams, assumedropoff: "True" })}>True</button>
                            <button className="ndt_btn1" style={modelparams.assumedropoff === "False" ? styleOn : styleOff}
                                onClick={_ => setModelParams({ ...modelparams, assumedropoff: "False" })}>False</button>
                        </div>
                        : <div></div>}

                </div>
            </div>
        )
    }

    const SwitchOptionUpper = (view: string) => {
        return (
            <div id="tmgr_across" className="ndt_gridbox" style={{ overflow: "visible" }}>
                {(() => {
                    switch (view) {
                        case "newtable": return <div>{newmodelmemo}</div>;
                        case "manage": return <div style={{ marginTop: "30px", marginLeft: "10px" }}>
                            <div className="ndt_title2">Manage Table - {selTbl?.ModelName}</div>
                            <div style={{ marginTop: '5px', marginLeft: "10px" }}>
                                <div>{selTbl?.ModelName} Table:</div>
                                <div>Model Count: {selTbl?.uploadsets.length}</div>
                                <div>Row Count {selTbl?.datacount}</div>
                                <div>Change Over Time: {selTbl?.DateUploadType}</div>
                                <div>Event Type: {selTbl?.DateEventType}</div>
                                <div>Data Dropoff: {String(selTbl?.DateAssumeDropoff)}</div>
                            </div>
                        </div>;
                        case "upload": return <View_Upload />;
                        case "uploadhistory": return <ViewUpper_UplHist />;
                        case "vertical": return <View_VerticalSingleValue />;
                        case "horizontal": return <View_HorizontalDateslice />;
                        default: return <div>No view selected</div>;
                    }
                })()}
            </div>
        )
    };

    const newmodelmemo = useMemo(() => View_NewModel(), [modelparams])

    const switchmemoupper = useMemo(() => SwitchOptionUpper(params.viewState), [
        modelparams, params.viewState, params.uploadrowcount, params.dfile, params.loaded, params.asofDate, params.uplviewtype, fields, selTUpload, primaryList
    ]);

    const Uploadchoice_MultiDate = () => {
        return (
            <div>
                <div style={{ marginBottom: "10px", display: 'flex' }}>
                    <button className="ndt_btn1" style={params.batchupload ? styleOn : styleOff} onClick={_ => { setParams({ ...params, batchupload: true }) }}>Batch</button>
                    <button className="ndt_btn1" style={params.batchupload ? styleOff : styleOn} onClick={_ => { setParams({ ...params, batchupload: false, batchfield: '' }) }}>Single Date</button>
                </div>
                <div style={{ height: "70px" }}>{
                    !params.batchupload ?
                        <div>
                            <div>Select Date</div>
                            <div style={{ color: "white", display: "block" }} className="dt_uploadline">
                                <DatePicker className="datepick_class" value={params.asofDate} onChange={(e: any) => setParams({ ...params, "asofDate": new Date(e) })} />
                            </div>
                        </div>
                        : <div>
                            <div>Select Date Column: {params.batchfield}</div>

                            <select style={{ marginTop: '10px' }} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, batchfield: e.target.selectedOptions[0].attributes[0].value }) }}>
                                <option key={'-1'} value={''}>Select:</option>
                                {sampleFields.map((fld: samplefld, i: number) => {
                                    return (<option key={i} value={fld.Value}>{fld.Value}</option>)
                                })}
                            </select>

                        </div>
                }</div>
            </div>
        )
    }
    const Uploadchoice_Datefield = () => {
        return (<div>
            <div style={{ marginBottom: "10px", display: 'flex', fontSize: "15px" }}>
                <button className="ndt_btn1" style={params.usedaterange ? styleOn : styleOff} onClick={_ => { setParams({ ...params, usedaterange: true }) }}>Clear Date Range</button>
                <button className="ndt_btn1" style={params.usedaterange ? styleOff : styleOn} onClick={_ => { setParams({ ...params, usedaterange: false }) }}>Ignore Date History</button>
            </div>
            {params.usedaterange ?
                <div>
                    <div>Contains dates between:</div>
                    <div style={{ display: "flex" }}>
                        <div style={{ color: "white", display: "block", marginLeft: "10px" }}>
                            <DatePicker className="datepick_class" value={params.asofDateFrom}
                                onChange={(e: any) => setParams({ ...params, "asofDateFrom": new Date(e), selectedDate1: true })} />
                        </div>

                        <div style={{ color: "white", display: "block", marginLeft: "30px" }}>
                            <DatePicker className="datepick_class" value={params.asofDate}
                                onChange={(e: any) => setParams({ ...params, "asofDate": new Date(e), selectedDate2: true })} />
                        </div>
                    </div>
                </div>
                : <div></div>}
        </div>)
    }

    return (
        <div id="tmgr_canvas" className="ndt_canvas">
            <div id="tmgr_sidebar" className="ndt_gridbox">
                <div className="ndt_title3">Table Manager</div>
                <GenericDropdown
                    data={tblmdls}
                    keycol="ModelKey"
                    namecol="ModelName"
                    promptstring="Select Table"
                    divID="tmgr_tableddown"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, tblmdls, setSelTbl, "", null); setParams({ ...params, viewState: "" }) }}
                    includeDefault={true}
                    defaultstring="Add New Table"
                />
                {selTbl && selTbl?.ModelKey !== "" ?
                    <div>
                        <div className="ndt_title4">Select View</div>
                        <div id="tmgr_mgopt" className="tmgr_sidebtn" onClick={_ => setParams({ ...params, viewState: "manage" })}>Manage Table Properties</div>
                        <div id="tmgr_uplopt" className="tmgr_sidebtn" onClick={_ => setParams({ ...params, viewState: "upload" })}>Upload</div>
                        <div className="tmgr_sidebtn" onClick={_ => setParams({ ...params, viewState: "uploadhistory" })}>Upload History ({selTbl.uploadsets.length})</div>
                        <div className="tmgr_sidebtn" onClick={_ => setParams({ ...params, viewState: "vertical", slice_how: "Vertical" })}>View Single {modelparams.namefieldname} History</div>
                        <div className="tmgr_sidebtn" onClick={_ => setParams({ ...params, viewState: "horizontal", slice_how: "Horizontal" })}>View All by Date</div>
                        <Link id="tmgr_fldopt" to={"/fields/viewer/" + selTbl.ModelName} className="tmgr_sidebtn" style={{ color: "lightblue" }}>Edit Fields (Viewer)</Link>
                    </div> :
                    <div></div>
                }
            </div>

            {switchmemoupper}

            <div id="1" className="ndt_gridbox">
                {(() => {
                    switch (params.viewState) {
                        case "upload": return <ViewUpload />;
                        case "manage": return <div>{fields.length > 0 ?
                            <div>{managemodelmemo}</div> :
                            <div style={{ fontSize: '18px', margin: "10px" }}>
                                <div style={{ fontSize: "22px" }}>Add fields first!</div>
                                <Link to={"/fields/viewer/" + selTbl?.ModelName} className="tmgr_sidebtn" style={{ color: "lightblue" }}>
                                    Link to Fields Manager
                                </Link>
                            </div>}
                        </div>;
                        case "horizontal": return <IsTable />;
                        case "vertical": return <IsTable />;
                        case "uploadhistory": return <Lower_UploadHistory />;
                        default: return <div></div>;
                    }
                })()}

            </div>

            <Modal show={params.ischangesvisible}>
                <div className="ndt_modal">
                    <div style={{ padding: "10px" }}>
                        <div style={{ fontSize: "22px" }}>Save Changes to {params.modelcategory}?</div>
                        <div >
                            {modelparams.newkeyfieldstring !== selTbl?.DataKeyField ?
                                <div style={{ border: "1px solid grey", margin: '5px', padding: '2px', display: "flex" }}>
                                    Key Fields:
                                    {modelparams.keyfieldnames.map((k: string, i: number) => {
                                        return (<div key={i} style={{ marginLeft: "3px" }}>{k}</div>)
                                    })}
                                </div> : <div></div>}
                        </div>
                        <div >
                            {modelparams.newmodelname !== "" ?
                                <div style={{ border: "1px solid grey", margin: '5px', padding: '2px' }}>
                                    Set Table Name to: {modelparams.newmodelname}
                                </div> : <div></div>}
                        </div>
                        <div >
                            {selTbl && modelparams.namefieldkey !== "" && modelparams.namefieldkey !== selTbl.DataNameField ?
                                <div style={{ border: "1px solid grey", margin: '5px', padding: '2px' }}>
                                    Set Table name to field: {modelparams.namefieldname}
                                </div> : <div></div>}
                        </div>
                        <div >
                            {selTbl && modelparams.datefieldkey !== "" ?
                                <div style={{ border: "1px solid grey", margin: '5px', padding: '2px' }}>
                                    Set Table date field: {GetFieldName(fields, modelparams.datefieldkey)}
                                </div> : <div></div>}
                        </div>
                        <div >
                            {selTbl && modelparams.datefield2key !== "" ?
                                <div style={{ border: "1px solid grey", margin: '5px', padding: '2px' }}>
                                    Set Table close date field: {GetFieldName(fields, modelparams.datefield2key)}
                                </div> : <div></div>}
                        </div>

                        <button className="ndt_btn1" style={{ width: "150px", marginTop: '10px' }} onClick={_ => { SubmitChanges() }}>Save</button>
                        <div id="tmgr_divchg"></div>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, ischangesvisible: false, refresh: params.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            <Modal show={params.isdeluplvisible}>
                <div className="ndt_modal">
                    {selTUpload ?
                        <div style={{ padding: "10px" }}>
                            <div>Delete data for {DateString(selTUpload.CreateDate)}?</div>
                            <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { DeleteUploadSet() }}>Delete</button>
                            <div id="tmgr_divdelupl"></div>
                        </div>
                        : <div></div>}
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdeluplvisible: false, refresh: params.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            {/* delete */}
            <Modal show={params.isdelmdlvisible}>
                <div className="ndt_modal">
                    <div style={{ padding: "10px" }}>
                        <div>WARNING: Delete table {selTbl?.ModelName}?</div>
                        <button className="ndt_btn2" style={{ width: "150px" }} onClick={_ => { ClickDelete() }}>Delete</button>
                        <div id="ttmg_divdelete"></div>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdelmdlvisible: false, refresh: params.refresh + 1 })}>Close</button>
                </div>
            </Modal>


            {/* save */}
            <Modal show={params.issavevisible}>
                <div className="ndt_modal">
                    <div style={{ padding: "10px" }}>
                        <div>Save table {modelparams.newmodelname}?</div>
                        <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { ClickSaveMdl() }}>Save</button>
                        <div id="ttmg_divsave"></div>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, issavevisible: false, refresh: params.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            {/* upload */}
            <Modal show={params.isuploadvisible}>
                <div className="ndt_modal">
                    <div style={{ padding: "10px" }}>
                        <div className="ndt_modaltitle">Upload Data</div>
                        <div style={{ margin: '5px', minHeight: "100px" }}>
                            <div style={{ margin: "5px" }}>Manage Conflicting History:</div>
                            {(() => {
                                switch (selTbl?.DateUploadType) {
                                    case "Single": return <div></div>;
                                    case "Multi": return <Uploadchoice_MultiDate />;
                                    case "Datefield": return <Uploadchoice_Datefield />;
                                    default: return <div></div>;
                                }
                            })()}
                        </div>

                        <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { ClickSaveUpl() }}>Save</button>

                        <div id="ttmg_divupl"></div>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, isuploadvisible: false, refresh: params.refresh + 1 })}>Close</button>
                </div>
            </Modal>



        </div >
    )
};

export default TableMgr;
