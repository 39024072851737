
const KSFormatSingle = (formatstr: string, val: number) => {

    let ftype = formatstr.substring(0, 3)
    let digits = formatstr.substring(3, 4)

    let retstring = ""
    if (ftype === "dec") {
        retstring = Number.parseFloat(String(val)).toFixed(+digits)
        let perIdx = retstring.indexOf(".")
        if (perIdx > -1) {
            let whole = retstring.substring(0, perIdx)
            retstring = String(whole).replace(/(.)(?=(\d{3})+$)/g, '$1,') + retstring.substring(perIdx)
        }
    } else if (ftype === "pct") {
        retstring = Number.parseFloat(String(val)).toFixed(+digits) + "%"
    }
    return retstring
};
export default KSFormatSingle;