
import { QueryModel, ColorModel, ColorSet, Metric, ChartSeries, queryvisualstate, QueryVisuals, Key_Name, FieldMap, QueryRunDate, FilterField, BenchModel, Benchmarkset } from "../../../type";
import { useState, useEffect, useContext, ChangeEvent, useMemo } from 'react'
import { queryparams } from "../../../type";
import { queryparamsInit, queryvisInit, QueryVisualsInit, ChartSeriesInit } from "../../../InitTypes";
import CreateChart from "../../../charts/CreateChart";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { StartupContext } from "../../../App";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChartTypeDropdown, { ChartTypeLineBarDropdown } from "../../../functions/Dropdowns/ChartTypeDropdown";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import { SwatchesPicker } from "react-color";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import { Modal } from "react-bootstrap";
import QueryVisualsUpload from "../../../data/Query/QueryVisuals_Upload";
import StringToBoolean from "../../../functions/String Functions/StringToBoolean";
import QueryModel_to_Params from "../../../functions/QueryFunctions/QueryModel_to_Params";
import GetQueryRunDateKey from "../../../functions/QueryFunctions/GetQueryRunDateKey";
import ConvertChartTypetoShow from "../../../functions/QueryFunctions/ConvertChartTypetoShow";
import GetFieldName from "../../../functions/GetFieldName";
import RunDateUpdate from "../../../functions/QueryFunctions/RunDateUpdate";
import GetUniquesFromArrayMultiField from "../../../functions/Array Functions/GetUniquesfromArrayMultiField";
import FilterData from "../../../functions/FilterData";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import QueryVisualsPrepare from "../../../functions/QueryFunctions/QueryVisualsPrepare";
import DateString from "../../../functions/Date Functions/DateString";
import "./css/QueryModelViewVisual.css"

const QueryModelViewVisual = (props: any) => {

    let query: QueryModel = props.query
    let setQuery: any = props.setQuery
    let fields: FieldMap[] = props.fields
    let metrics: Metric[] = props.metrics

    let seriescolor = "#f4f49a"
    interface keyparamstype {
        mode: string; list: string; focus: string;
        viewstring: string; seriesnum: number;
        colorIdx: number; colorSubIdx: number;
        metricnames: Key_Name[];
        issavevisible: boolean; isdeletevisible: boolean;
        isrenamevisible: boolean; iscolorsvisible: boolean;
        islabelcolorvisible: boolean; loadedbench: boolean;
        refresh: number; asofdate: Date; rerun: number;
    }
    const { config, schema, user, paths, appcolors, clickstyle } = useContext(StartupContext)
    const context = useContext(StartupContext)

    const [params, setParams] = useState<queryparams>(queryparamsInit);
    let kpinit: keyparamstype = {
        mode: "", list: "", focus: "", viewstring: "", seriesnum: -1,
        colorIdx: 0, colorSubIdx: 0, metricnames: [],
        issavevisible: false, isdeletevisible: false, loadedbench: false,
        isrenamevisible: false, iscolorsvisible: false, islabelcolorvisible: false,
        refresh: 0, asofdate: new Date(), rerun: 0
    }
    //const [paramsUser, setparamsUser] = useState<queryparams>(queryparamsInit);
    const [keyparams, setKeyParams] = useState<keyparamstype>(kpinit)
    const [tmpparams, setTmpparams] = useState<any>({
        tmplabelround: 0, tmplabelformat: '', init: false
    })
    const [vis_states, setVis_States] = useState<queryvisualstate[]>(queryvisInit)
    const [qryvis, setQryvis] = useState<QueryVisuals>(QueryVisualsInit[0])
    const [qryvisall, setQryvisAll] = useState<QueryVisuals[]>([])
    const [filtkeys, setFiltKeys] = useState<any>({ filtervalue1: '', filtervalue2: '' })

    const [appliedColorModel, setAppliedColorModel] = useState<ColorModel | null>(null)
    const [colorModels, setColorModels] = useState<ColorModel[]>([])
    const [selColModel, setSelColModel] = useState<ColorModel | null>(null)
    const [uniques, setUniques] = useState<any[]>([])
    //const [colorSets, setColorSets] = useState<ColorSet[]>([])
    const [benchModels, setBenchModels] = useState<BenchModel[]>([])
    const [appliedbenchSets, setAppliedBenchSets] = useState<Benchmarkset[]>([])

    const main_selStyle = { backgroundColor: appcolors.colorprimarybright, opacity: "1", boxShadow: "0px 0px 15px white, inset 0px 0px 10px black" }
    const main_unSelStyle = { backgroundColor: appcolors.colorprimary, opacity: ".8", boxShadow: "" }
    const div_selStyle = { opacity: "1", backgroundColor: appcolors.colorprimary, boxShadow: "0px 0px 10px white, inset 0px 0px 10px black" }
    const div_unSelStyle = { opacity: ".8", boxShadow: "" }

    const [chartborderStyle, setChartBorderStyle] = useState<any>({ boxShadow: "", width: '700px' })

    useEffect(() => {
        ImportAndSetStateGeneric(setColorModels, "", schema, paths.colorsets.colormodelview, user, config, {})
        ImportAndSetStateGeneric(setQryvisAll, query.ModelKey, schema, paths.query.queryvisualsview, user, config, {})
        ImportAndSetStateGeneric(setBenchModels, "", schema, paths.benchmark.benchmodelview, user, config, { querykeys: query.ModelKey })
    }, [])

    useEffect(() => {
        let uniquesvals: any[] = []
        query.rundates.forEach((rd: QueryRunDate) => {
            if (rd.DateKey === params.rundatekey) {
                query.filterfields.forEach((_, i: number) => {
                    let fltunique = GetUniquesFromArrayMultiField(rd.basicquery, ['FilterValue' + String(i + 1)])
                    let fltdata = FilterData(fltunique, ['FilterValue' + String(i + 1)], [""], true)
                    uniquesvals.push(fltdata)

                })
                setKeyParams({ ...keyparams, asofdate: rd.AsofDate })
            }
        })
        setUniques(uniquesvals)
    }, [query])



    useEffect(() => {
        if (typeof (qryvis.FilterValues) !== "string" && qryvis.FilterValues.length > 0) {
            setFiltKeys({ filtervalue1: qryvis.FilterValues[0], filtervalue2: qryvis.FilterValues[1] })
        }
    }, [qryvis.FilterValues])

    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        if (qryvis.ChartType === "TableHorizontal") {
            setQryvis({ ...qryvis, ShowDefault: keyparams.metricnames[0].Key })
        } else {
            if (qryvis.ChartType !== "" && qryvis.ChartType !== "Combo") {
                params.chartseries.forEach((cs: ChartSeries) => {
                    cs.ChartType = qryvis.ChartType
                })
            }
        }
        setParams({ ...params, chartType: qryvis.ChartType, showdefault: "", chartseries: params.chartseries })

        let newvis_states: queryvisualstate = { ...queryvisInit[0], show: ConvertChartTypetoShow(qryvis.ChartType) }
        setVis_States([newvis_states])

    }, [qryvis.ChartType])

    useEffect(() => {
        let metricnames = params.metrics.map((metr: string) => {
            return { Key: metr, Name: GetObjectField(metr, metrics, "MetricKey", "MetricName") }
        })
        setKeyParams({ ...keyparams, metricnames: metricnames })

    }, [params.metrics, metrics])

    useEffect(() => {
        QueryModel_to_Params(query, qryvis, params, setParams, fields, true)
    }, [qryvis.VisKey])


    useEffect(() => {
        //have to update once to get default rundate
        RunDateUpdate(params.rundatekey, schema, query, setQuery, config)
    }, [params.rundatekey])

    useEffect(() => {
        //new visualset, create chartseries defaults, after querymodeltoparams runs
        if (params.visualskey === "_") {
            let rundate = GetQueryRunDateKey(qryvis, query.rundates)
            let newchartseries = query.Metrics.map((metr: string, i: number) => {
                return ({ ...ChartSeriesInit[0], MetricName: metr, MetricOrder: i, ChartType: "Bar", BenchmarkMode: "None" })
            })

            setQryvis({ ...qryvis, chartseries: newchartseries })
            setParams({ ...queryparamsInit, metrics: query.Metrics, chartseries: newchartseries, rundatekey: rundate, newparamset: false })
        }
    }, [params.visualskey])

    useEffect(() => {
        if (qryvis.ChartType === "Combo") { //make sure charttypes are line or bar
            let newseries: ChartSeries[] = []
            qryvis.chartseries.forEach((cs: ChartSeries) => {
                let ncs = cs;
                if (cs.ChartType !== "Bar" && cs.ChartType !== "Line") {
                    ncs.ChartType = "Bar"
                }
                newseries.push(ncs)
            })
            setQryvis({ ...qryvis, chartseries: newseries })
        }
    }, [qryvis.ChartType])


    useEffect(() => {
        CreateQueryChart()
    }, [params, qryvis.LabelColor, vis_states[0], keyparams.mode, appliedColorModel, query.rundates, keyparams.rerun])

    //view string effects
    useEffect(() => {
        if (keyparams.mode !== "") {
            let str = keyparams.mode + " -> "
            setKeyParams({ ...keyparams, list: "", focus: "", viewstring: str })
            vis_states[0] = { ...vis_states[0], selectedmetric: '', highlight: "none", reload: true }
            setVis_States(vis_states)
        }
    }, [keyparams.mode])

    useEffect(() => {
        if (keyparams.list !== "") {
            let str = keyparams.mode + " -> " + keyparams.list + " -> "
            setKeyParams({ ...keyparams, focus: "", viewstring: str })
        }
    }, [keyparams.list])

    useEffect(() => {
        if (keyparams.mode === "Series") { setTmpparams({ ...tmpparams, labelrounding: 0 }) }
    }, [keyparams.seriesnum])

    useEffect(() => {
        if (keyparams.focus !== "") {
            let str = keyparams.mode + " -> " + keyparams.list + " -> " + keyparams.focus
            setKeyParams({ ...keyparams, viewstring: str })
        }
    }, [keyparams.focus])

    useEffect(() => {
        if (!params.newparamset) {
            let name = GetObjectField(params.colorModelKey, colorModels, "ModelKey", 'ModelName')
            setParams({ ...params, colorModelName: name })
            setQryvis({ ...qryvis, ColorModelName: params.colorModelKey })
        }
    }, [params.colorModelKey])

    useEffect(() => {
        PullBenchmarkSets().then(_ => { setKeyParams({ ...keyparams, rerun: keyparams.rerun + 1 }) })
    }, [qryvis.VisKey])

    const PullBenchmarkSets = async () => {
        if (qryvis.chartseries.length > 0) {
            let ckeys: string[] = []
            qryvis.chartseries.forEach((cs: ChartSeries) => {
                if (cs.BenchmarkModelName !== "") {
                    ckeys.push(cs.BenchmarkModelName)
                }
            })

            await GenericBackendCall("", schema, paths.benchmark.benchmarksetview, user, config, { modelkeys: ckeys, asofdate: keyparams.asofdate }).then(d => {
                console.log(d)
                setAppliedBenchSets(d)
            })
        }
    }

    const ConvertParamColors = (paramcolors: any) => {
        let colors = Object.keys(paramcolors).map((c: any) => {
            return { ModelName: "", Color: paramcolors[c][0], SecondaryColor: paramcolors[c][1] }
        })
        return colors
    }

    const CreateQueryChart = () => {
        let colors = ConvertParamColors(params.colors)
        CreateChart(query, { ...qryvis, colors: colors }, appliedColorModel, params.rundatekey, fields, metrics, benchModels, appliedbenchSets,
            "#qmvv_graph1g", [700, 500], "", vis_states, setVis_States, 0, context)
    }


    useEffect(() => {
        let colpromise = GenericBackendCall(qryvis.ColorModelName, schema, paths.colorsets.colormodelview, user, config, "")
        colpromise.then(c => { setAppliedColorModel(c[0]) })
    }, [qryvis.ColorModelName])


    const ChangeQueryVis = (e: ChangeEvent<HTMLSelectElement>) => {
        let val = +e.target.selectedOptions[0].attributes[0].value
        if (val === -1) {
            setQryvis({ ...QueryVisualsInit[0], VisKey: "_" })
        } else {
            setQryvis(QueryVisualsPrepare(qryvisall[val], null))
        }
        setKeyParams({ ...keyparams, seriesnum: -1 })
    }

    const ChangeSeriesAttr = (values: any[], attrnames: string[]) => {

        let n = keyparams.seriesnum

        let newcs: any = {}
        Object.assign(newcs, { ...params.chartseries[n] })

        newcs.MetricName = params.metrics[n]
        values.forEach((v: any, i: number) => {
            newcs[attrnames[i]] = v
        })


        let newchartseries: ChartSeries[] = []
        newchartseries = params.chartseries
        Object.assign(newchartseries[n], { ...newcs })

        setParams({ ...params, chartseries: newchartseries })
        setQryvis({ ...qryvis, chartseries: newchartseries }) //just added
    }


    const ChangeSeries = (metr: string, i: number) => {
        let mkey = query.Metrics[i]
        let colorval = seriescolor
        if (i < 0) {
            mkey = ""
            colorval = "black"
        }

        vis_states[0] = {
            ...vis_states[0],
            highlight: "series",
            selectedmetric: mkey, selectedmetricname: GetObjectField(mkey, metrics, "MetricKey", "MetricName")
        }
        setVis_States(vis_states)
        setKeyParams({ ...keyparams, list: metr, seriesnum: i, focus: "" })
        setChartBorderStyle({ boxShadow: "0px 0px 20px " + colorval })
    };

    const SwitchLabelsOnOff = (value: string) => {
        setParams({ ...params, labelsonoff: value });
        setQryvis({ ...qryvis, LabelsOnOff: value })
    };

    const ChangeShowDefault = (e: ChangeEvent<HTMLSelectElement>) => {
        let val = +e.target.selectedOptions[0].attributes[0].value
        let showmetr = params.metrics[val]
        setQryvis({ ...qryvis, ShowDefault: showmetr })
        setParams({ ...params, showdefault: showmetr })
    }

    const ChangeLabelType = (v: string) => {
        setParams({ ...params, labeltype: v })
        setQryvis({ ...qryvis, LabelType: v })
    }

    const ChangeColor = (e: any) => {
        let tempcolor = params.colors;
        tempcolor[keyparams.colorIdx][keyparams.colorSubIdx] = e.hex;
        setParams({ ...params, colors: tempcolor })
    };


    const ClickSave = () => {
        setKeyParams({ ...keyparams, refresh: 99 })
        QueryVisualsUpload({ ...params, defaultfiltervals: [filtkeys.filtervalue1, filtkeys.filtervalue2] },
            query.ModelKey, schema, config, "", "qmvv_savediv")
    }

    const ClickDelete = () => {
        setKeyParams({ ...keyparams, refresh: 99 })
        GenericBackendCall(qryvis.VisKey, schema, paths.generics.genericdelete, user, config, { model: "QueryVisuals", field: "VisKey" }, "qmvv_divdelete")
    }

    //list elements
    const List_Colors = () => {
        return (
            <div>
                <div className="qmvv_subheader" style={{ marginLeft: "10px", letterSpacing: ".05em" }}>Manage Colors:</div>

                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "colorset" }) }}>
                    Colors Manual: {params.colors.length}
                </div>

                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "colorgradient" }) }}>
                    Color Gradient: {params.colorGradientType}
                </div>
                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "colormodel" }) }}>
                    Color Model: {params.colorModelName}

                </div>

            </div>
        )
    };

    const List_Charting = () => {
        return (
            <div>
                <div className="qmvv_subheader">Parameters</div>
                <div className="qmvv_qryitem" onClick={_ => setKeyParams({ ...keyparams, focus: "maincharttype" })}>
                    Main Chart Type: {params.chartType}
                </div>
                <div className="qmvv_qryitem" onClick={_ => setKeyParams({ ...keyparams, focus: "chartname" })}>
                    Chart Name: {params.charttitle}
                </div>
                <div className="qmvv_qryitem" onClick={_ => setKeyParams({ ...keyparams, focus: "filtering" })}>
                    Filtering:
                </div>
            </div>
        )
    };
    const List_Labels = () => {
        return (
            <div>
                <div className="qmvv_subheader">Parameters</div>
                <div className="qmvv_qryitem" onClick={_ => setKeyParams({ ...keyparams, focus: "labels" })}>
                    Labels {params.labelsonoff}
                </div>
                <div className="qmvv_qryitem" onClick={_ => setKeyParams({ ...keyparams, focus: "labelcolor" })}>
                    Label Color: {params.labelcolor}
                </div>
                <div className="qmvv_qryitem" onClick={_ => setKeyParams({ ...keyparams, focus: "labeltype" })}>
                    Label Text Type: {params.labeltype}
                </div>
            </div>
        )
    }

    const Detail_Colorset = () => {
        return (
            <div style={{ height: "200px" }}>
                <button className="ndt_btn1" onClick={_ => setKeyParams({ ...keyparams, iscolorsvisible: true })}>Edit Colors</button>
                <div id="qmvv_colorbox">
                    {Object.keys(params.colors).map((k: string, i: number) => {
                        return (<div key={i}>Color ({i + 1}): {params.colors[k][0]} {params.colors[k][1]}</div>)
                    })}
                </div>
            </div>)
    };

    const Detail_ColorGradient = () => {
        return (<div>
            <div style={{ fontSize: "22px" }}>Gradient:</div>
            <div className="qmvv_item" style={params.colorGradientType === "Discrete" ?
                div_selStyle : div_unSelStyle} onClick={_ => {
                    setParams({ ...params, colorGradientType: "Discrete" });
                    setQryvis({ ...qryvis, ColorGradientType: "Discrete" })
                }} >Discrete</div>

            <div className="qmvv_item" style={params.colorGradientType === "Continuous" ? div_selStyle : div_unSelStyle}
                onClick={_ => {
                    setParams({ ...params, colorGradientType: "Continuous" })
                    setQryvis({ ...qryvis, ColorGradientType: "Continuous" })
                }} >Continuous</div>

            <div className="qmvv_item" style={params.colorGradientType === "Gradient" ?
                div_selStyle : div_unSelStyle} onClick={_ => {
                    setParams({ ...params, colorGradientType: "Gradient" });
                    setQryvis({ ...qryvis, ColorGradientType: "Gradient" })
                }} >Gradient</div>

            <div className="qmvv_item" style={params.colorGradientType === "None" ?
                div_selStyle : div_unSelStyle} onClick={_ => {
                    setParams({ ...params, colorGradientType: "None" });
                    setQryvis({ ...qryvis, ColorGradientType: "None" })
                }} >None (Off)</div>
        </div>)
    };

    const Detail_ColorModel = () => {
        console.log(params.colorModelName)
        return (<div style={{ padding: "5px" }}>
            <div style={{ fontSize: "22px" }}>Color Model {params.colorModelName}</div>
            <GenericDropdown data={colorModels}
                value={params.colorModelKey}
                divID="gc_colorddown"
                className="gc_gen_ddown"
                change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, colorModels, setSelColModel, "", null)}
                keycol="ModelKey"
                namecol="ModelName"
                promptstring="Select Color Model"
                includeDefault={false}
            />
            <div>
                {selColModel ?
                    <div>
                        <div style={{ height: "215px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "150px" }}>Set Name</div>
                                <div style={{ width: "150px" }}>On Field Name</div>
                            </div>
                            {colorModels.map((cm: ColorModel) => {
                                if (cm.ModelKey === selColModel.ModelKey) {

                                    return cm.colorsets.map((cs: ColorSet, t: number) => {
                                        return (<div style={{ display: "flex" }} key={t}>
                                            <div style={{ width: "150px" }}>{cs.SetName}</div>
                                            <div style={{ width: "150px" }}>{GetFieldName(fields, cs.FieldName)}</div>
                                        </div>)
                                    })
                                }
                            })}

                            <div style={{ display: "flex" }}>
                                <button className="ndt_btn1" onClick={_ => setParams({ ...params, colorModelKey: selColModel.ModelKey })}>Assign This Model</button>
                                <div style={{ marginLeft: "10px", marginTop: '3px' }}>{params.colorModelKey === selColModel.ModelKey ? "Assigned" : ""}</div>
                            </div>
                        </div>
                    </div> : <div></div>}
            </div>
        </div >)
    };

    const Detail_ChartTypeMain = () => {
        return (<div>
            <div>Chart Type</div>
            <ChartTypeDropdown className="qmvv_chartddown" change={(e: ChangeEvent<HTMLSelectElement>) =>
                setQryvis({ ...qryvis, ChartType: e.target.selectedOptions[0].attributes[1].value })
            } />
            <div>{params.chartType}</div>
            {params.chartType === "TableHorizontal" ? <div>
                <GenericDropdown
                    data={keyparams.metricnames}
                    keycol={"Key"}
                    namecol={"Name"}
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeShowDefault(e) }}
                />
            </div> : <div></div>}
        </div>)
    };

    const Detail_ChartTypeSeries = () => {
        return (<div>
            <div>Chart Type</div>
            {qryvis.ChartType === "Combo" ?
                <div>
                    <ChartTypeLineBarDropdown className="qmvv_chartddown" change={(e: ChangeEvent<HTMLSelectElement>) =>
                        ChangeSeriesAttr([e.target.selectedOptions[0].attributes[0].value], ["ChartType"])} />
                    {params.chartseries[keyparams.seriesnum].ChartType}
                </div>
                : <div>Chart type set to {params.chartType}</div>}

        </div>)
    };

    const Detail_Labels = () => {
        return (<div style={{ padding: "5px" }}>
            <div style={{ fontSize: "22px" }}>Chart Labels {params.labelsonoff}</div>
            <button className="ndt_btn1" onClick={_ => SwitchLabelsOnOff("On")}>On</button>
            <button className="ndt_btn1" onClick={_ => SwitchLabelsOnOff("Off")}>Off</button>
            <button className="ndt_btn1" onClick={_ => SwitchLabelsOnOff("HighLow")}>High/Low</button>
        </div>)
    }
    const Detail_LabelColor = () => {
        return (<div style={{ padding: "5px" }}>
            <div style={{ fontSize: "22px" }}>Label Colors {params.labelcolor}</div>
            <button onClick={_ => { setKeyParams({ ...keyparams, islabelcolorvisible: true }) }}>Change</button>
        </div>)
    }


    const Detail_LabelType = () => {
        return (<div style={{ padding: "5px" }}>
            <div style={{ fontSize: "22px" }}>Label Type {params.labeltype}</div>
            <div style={{ display: "flex" }}>
                <button className="ndt_btn1" onClick={_ => { ChangeLabelType("value") }}>Value</button>
                <button className="ndt_btn1" onClick={_ => { ChangeLabelType("vartext") }}>{GetFieldName(fields, query.XFieldName)}</button>
                {query.GroupFieldName ?
                    <button className="ndt_btn1" onClick={_ => { ChangeLabelType("grptext") }}>{GetFieldName(fields, query.GroupFieldName)}</button>
                    : <div></div>}
            </div>
        </div>)
    }

    const Detail_LabelFormat = () => {
        return (
            <div style={{ padding: "5px" }}>
                <div style={{ fontSize: "22px" }}>Label Formatting</div>
                <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setTmpparams({ ...tmpparams, tmplabelformat: e.target.selectedOptions[0].attributes[0].value }) }}>
                    <option value="Decimal">Decimal</option>
                    <option value="Percent">Percent</option>
                </select>
                <div style={{ marginBottom: '5px' }}>

                </div>
                <button className="ndt_btn1" onClick={(_) => { ChangeSeriesAttr([tmpparams.tmplabelformat], ["LabelFormat"]) }}>Set Value</button>
            </div >
        )
    }

    const Detail_LabelRounding = () => {
        return (
            <div style={{ padding: "5px" }}>
                <div style={{ fontSize: "22px" }}>Decimal Places</div>
                <div style={{ marginBottom: '5px' }}>
                    <input type="number"
                        value={tmpparams.tmplabelround}
                        min={0}
                        max={6}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => { setTmpparams({ ...tmpparams, tmplabelround: +e.target.value, init: false }) }}
                    />
                    Decimals: {keyparams.seriesnum > -1 ? params.chartseries[keyparams.seriesnum].LabelRounding : ""}
                </div>
                <button className="ndt_btn1" onClick={(_) => { ChangeSeriesAttr([tmpparams.tmplabelround], ["LabelRounding"]) }}>Set Value</button>
            </div >
        )
    }

    let d_labelrndmemo = useMemo(() => Detail_LabelRounding(), [tmpparams, params.chartseries])
    let d_labelfmtmemo = useMemo(() => Detail_LabelFormat(), [tmpparams, params.chartseries])

    //mains
    const Main_Series = () => {
        return (
            <div className="ndt_gridbox">
                {keyparams.metricnames.map((metr: Key_Name, i: number) => {
                    let metrstyle = { border: "2px solid " + seriescolor, boxShadow: "inset 0px 0px 5px " + seriescolor }
                    return (<div key={i} className="ndt_item" style={metr.Name === keyparams.list ? metrstyle : {}}
                        onClick={_ => { ChangeSeries(metr.Name, i) }}>
                        Series: {metr.Name} {params.chartseries[i].ChartType}
                    </div>)
                })}
                <div className="ndt_item" style={{ backgroundColor: "rgb(40,40,40)", border: "2px solid darkblue" }} onClick={_ => { ChangeSeries("", -1) }}>Unselect</div>
                {keyparams.list !== "" ? <div>
                    <CategoriesSeries />
                </div> : <div></div>}
            </div>
        )
    }

    const Main_Chart = () => {
        return (
            <div className="ndt_gridbox">
                <div className="ndt_item" style={keyparams.list === "charts" ? div_selStyle : div_unSelStyle} onClick={_ => { setKeyParams({ ...keyparams, list: "charts", seriesnum: -1 }) }}>Chart Attributes</div>
                <div className="ndt_item" style={keyparams.list === "colors" ? div_selStyle : div_unSelStyle} onClick={_ => { setKeyParams({ ...keyparams, list: "colors", seriesnum: -1 }) }}>Colors</div>
                <div className="ndt_item" style={keyparams.list === "labels" ? div_selStyle : div_unSelStyle} onClick={_ => { setKeyParams({ ...keyparams, list: "labels", seriesnum: -1 }) }}>Label Attributes</div>
                <ChartCategoryManager />
            </div>
        )
    };

    //category elements
    const CategoriesSeries = () => {
        return (
            <div className="ndt_innerbox qmvv_list">
                <div className="qmvv_subheader">Selected: {keyparams.list}</div>
                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "charttype" }) }}>Chart Type</div>
                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "labelrounding" }) }}>Label Decimal Places</div>
                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "labelformat" }) }}>Label Formatting</div>

                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "ishidden" }) }}>Series Visibility</div>
                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "calculation" }) }}>Series Calculation</div>
                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "benchmarkmode" }) }}>Benchmark View</div>
                <div className="qmvv_qryitem" onClick={_ => { setKeyParams({ ...keyparams, focus: "benchmarkmodel" }) }}>Benchmark Model</div>

            </div>
        )
    };

    const Detail_ChartName = () => {
        return (<div style={{ padding: "10px" }}>
            <div style={{ fontSize: "22px" }}>Change Chart Name</div>
            <br />
            <button className="ndt_btn1" onClick={_ => setKeyParams({ ...keyparams, isrenamevisible: true })}>Set Chart Name</button>
            <div>{params.charttitle}</div>
        </div>)
    }

    const Detail_SeriesVisibility = () => {

        return (
            <div style={{ padding: "10px" }}>
                <div style={{ fontSize: "22px" }}>Change Series Visibility</div>
                <br />
                <button className="ndt_btn1" onClick={_ => ChangeSeriesAttr([false], ["IsHidden"])}>Show Series</button>
                <button className="ndt_btn1" onClick={_ => ChangeSeriesAttr([true], ["IsHidden"])}>Hide Series</button>

                <div>{params.charttitle}</div>
            </div>
        )
    }
    const Detail_SeriesCalculation = () => {
        return (
            <div style={{ padding: "10px" }}>
                <div style={{ fontSize: "22px" }}>Change Series Calculation: </div>
                <div>Currently: {params.chartseries[keyparams.seriesnum].Calculation}</div>
                <br />
                <button className="ndt_btn1" onClick={_ => ChangeSeriesAttr(["Default"], ["Calculation"])}>Default</button>
                <button className="ndt_btn1" onClick={_ => ChangeSeriesAttr(["PctofTotal"], ["Calculation"])}>Pct of Total</button>
                <button className="ndt_btn1" onClick={_ => ChangeSeriesAttr(["Annualized"], ["Calculation"])}>Annualized</button>

                <div>{params.charttitle}</div>
            </div>
        )
    }

    const Detail_SeriesBenchmarkMode = () => {
        return (
            <div style={{ padding: "10px" }}>
                <div style={{ fontSize: "22px" }}>Change Series Benchmark: </div>
                <div>Currently: {params.chartseries[keyparams.seriesnum].BenchmarkMode}</div>
                <br />
                <button className="ndt_btn1" style={params.chartseries[keyparams.seriesnum].BenchmarkMode === "None" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => ChangeSeriesAttr(["None"], ["BenchmarkMode"])}>None (default)</button>
                <button className="ndt_btn1" style={params.chartseries[keyparams.seriesnum].BenchmarkMode === "On" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => ChangeSeriesAttr(["On"], ["BenchmarkMode"])}>On (always)</button>
                <button className="ndt_btn1" style={params.chartseries[keyparams.seriesnum].BenchmarkMode === "OnHighlight" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => ChangeSeriesAttr(["OnHighlight"], ["BenchmarkMode"])}>On Highlight</button>
                <button className="ndt_btn1" style={params.chartseries[keyparams.seriesnum].BenchmarkMode === "Compare" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => ChangeSeriesAttr(["Compare"], ["BenchmarkMode"])}>On (Compare)</button>

            </div>
        )
    }

    const ChangeBenchModel = (val: string) => {
        let bkey = benchModels[+val].ModelKey
        ChangeSeriesAttr([bkey], ["BenchmarkModelName"])
    }

    const Detail_SeriesBenchmarkModel = () => {
        return (
            <div style={{ padding: "10px" }}>
                <div style={{ fontSize: "22px" }}>Change Series Benchmark Model: </div>
                <div>Currently: {GetObjectField(params.chartseries[keyparams.seriesnum].BenchmarkModelName, benchModels, "ModelKey", "ModelName")}</div>
                <GenericDropdown
                    data={benchModels}
                    keycol="ModelKey"
                    namecol="ModelName"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeBenchModel(e.target.selectedOptions[0].attributes[0].value) }}
                    promptstring="Select Benchmark Model"
                    includeDefault={true}
                    defaultstring="(None)"
                    className="ndt_dropdown"
                    filterfield="MetricKey"
                    filtervalue={query.Metrics[keyparams.seriesnum]}
                />
                <button onClick={_ => { PullBenchmarkSets().then(_ => { setKeyParams({ ...keyparams, rerun: keyparams.rerun + 1 }) }) }}>Apply</button>
            </div>
        )
    }

    const InputText = () => {
        return (
            <input style={{ width: "300px" }} type="text" value={params.charttitle}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setParams({ ...params, charttitle: e.target.value })
                }} />
        )
    }
    let inputTitlememo = useMemo(() => { return InputText() }, [params])

    const Detail_Filtering = () => {

        return (<div>
            <div style={{ fontSize: "20px" }}>Filter</div>
            {query.filterfields.map((ff: FilterField, i: number) => {
                return (<div key={i}>
                    <div>{GetFieldName(fields, ff.FieldName)}: {filtkeys['filtervalue' + String(i + 1)]}</div>
                    <GenericDropdown
                        data={uniques[i]}
                        keycol={"FilterValue" + String(i + 1)}
                        namecol={"FilterValue" + String(i + 1)}
                        promptstring="Select Value"
                        change={(e: ChangeEvent<HTMLSelectElement>) => {
                            ChangeParamSelectorbyIndex(e, uniques[i], filtkeys, setFiltKeys, "filtervalue" + String(i + 1), "FilterValue" + String(i + 1), "")
                        }}
                        includeDefault={true}
                        defaultstring="(none)"
                    />
                </div>)
            })}
        </div>)
    }

    const FocusManager = () => {
        return (
            <div className="ndt_innerbox">
                {keyparams.mode === "Chart" ?
                    <div>
                        {(() => {
                            switch (keyparams.focus) {
                                case "colorset": return <Detail_Colorset />
                                case "colorgradient": return <Detail_ColorGradient />;
                                case "colormodel": return <Detail_ColorModel />;
                                case "labels": return <Detail_Labels />;
                                case "labelcolor": return <Detail_LabelColor />;
                                case "labeltype": return <Detail_LabelType />;
                                case "maincharttype": return <Detail_ChartTypeMain />;
                                case "chartname": return <Detail_ChartName />;
                                case "filtering": return <Detail_Filtering />;

                                default: return <div></div>;
                            }
                        })()}
                    </div>
                    : <div>
                        {(() => {
                            switch (keyparams.focus) {
                                case "charttype": return <Detail_ChartTypeSeries />;
                                case "labelrounding": return <div>{d_labelrndmemo}</div>;
                                case "labelformat": return <div>{d_labelfmtmemo}</div>;
                                case "ishidden": return <Detail_SeriesVisibility />;
                                case "calculation": return <Detail_SeriesCalculation />;
                                case "benchmarkmode": return <Detail_SeriesBenchmarkMode />;
                                case "benchmarkmodel": return <Detail_SeriesBenchmarkModel />;

                                default: return <div></div>;
                            }
                        })()}
                    </div>}
            </div>
        )
    }


    const ChartCategoryManager = () => {
        return (
            <div className="ndt_innerbox qmvv_list">
                {(() => {
                    switch (keyparams.list) {
                        case "colors": return <List_Colors />
                        case "charts": return <List_Charting />;
                        case "labels": return <List_Labels />;

                        default: return <div></div>;
                    }
                })()}
            </div>
        )
    };
    let focusmanagerMemo = useMemo(() => { return FocusManager() }, [keyparams, params, tmpparams, selColModel, qryvis, filtkeys])

    return (<div >
        <div id="qmvv_header" style={{ height: "100px", padding: "8px", paddingLeft: "15px" }}>
            <div>
                <div className="ndt_title3">Visual Editor</div>
                <div style={{ display: "flex" }}>
                    <GenericDropdown
                        data={qryvisall}
                        keycol={"VisKey"}
                        namecol="VisName"
                        promptstring="Select VisualSet for this Query"
                        includeDefault={true}
                        divID="qmvv_selddown"
                        defaultstring="Create New"
                        className="ndt_dropdown"
                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeQueryVis(e)}

                    />
                    <div style={{ marginLeft: "10px" }}>As of Date: {DateString(keyparams.asofdate)}</div>
                </div>
                <div>{keyparams.viewstring}</div>
            </div>

        </div>
        {qryvis.VisKey !== "" ?
            <div id="qmvv_main">
                <div id="qmvv_mainleft">
                    <div className="ndt_gridbox ndt_header">
                        <div className="qmvv_mainitem" style={keyparams.mode === "Chart" ? main_selStyle : main_unSelStyle} onClick={_ => { setKeyParams({ ...keyparams, mode: "Chart" }) }}>Chart</div>
                        <div className="qmvv_mainitem" style={keyparams.mode === "Series" ? main_selStyle : main_unSelStyle} onClick={_ => { setKeyParams({ ...keyparams, mode: "Series" }) }}>Series</div>
                        <div id="qmvv_saveopt" className="qmvv_mainitem" style={{ backgroundColor: '#111111', border: "1px solid black" }} onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}>Save</div>
                        <div className="qmvv_mainitem" style={{ backgroundColor: '#111111', border: "1px solid black" }} onClick={_ => { setKeyParams({ ...keyparams, isdeletevisible: true }) }}>Delete</div>

                    </div>
                    <div className="ndt_header">
                        {(() => {
                            switch (keyparams.mode) {
                                case "Chart": return <Main_Chart />
                                case "Series": return <Main_Series />;
                                default: return <div></div>;
                            }
                        })()}
                    </div>

                </div>

                {focusmanagerMemo}
                <div></div>

                <div id="qmvv_chartbox" className="ndt_chart" style={chartborderStyle}>
                    <div id="qmvv_graph1g" ></div>
                </div>
                <Modal show={keyparams.isdeletevisible}>
                    <div className="ndt_modal">
                        <div>Delete this view?</div>
                        <button className="ndt_btn2" onClick={_ => { ClickDelete() }}>Delete</button>
                        <div id="qmvv_divdelete"></div>
                        <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                    </div>
                </Modal>

                <Modal show={keyparams.issavevisible}>
                    <div className="ndt_modal">
                        <div>Save Visuals with Name</div>
                        <input style={{ width: "60%" }} type="text" placeholder={params.visualsname} value={params.visualsname} onChange={(e: ChangeEvent<HTMLInputElement>) => { setParams({ ...params, visualsname: e.target.value }) }} />
                        <div>Set this visual as the primary for this Query</div>
                        <select style={{ width: "60%" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, isprimary: StringToBoolean(e.target.value) }) }}>
                            <option data-value={false}>Select:</option>
                            <option data-value={true}>Set Primary</option>
                            <option data-value={false}>Do not set Primary</option>
                        </select>
                        <button className="ndt_btn1" style={{ width: "200px", margin: "auto", marginTop: "10px" }} onClick={_ => ClickSave()}>Save</button>
                        <div id="qmvv_savediv"></div>
                        <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                    </div>
                </Modal>

                <Modal show={keyparams.isrenamevisible}>
                    <div className="ndt_modal">
                        Change the name for this chart:
                        {inputTitlememo}
                        <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isrenamevisible: false })}>Close</button>
                    </div>
                </Modal>

                <Modal show={keyparams.islabelcolorvisible} dialogClassName="modalwide">
                    <div className="ndt_modal" style={{ width: "700px" }}>
                        <div id="qmvv_swatches" >
                            <SwatchesPicker onChangeComplete={(e: any) => {
                                setParams({ ...params, labelcolor: e.hex });
                                setQryvis({ ...qryvis, LabelColor: e.hex })
                            }} />
                        </div>
                        <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, islabelcolorvisible: false })}>Close</button>
                    </div>
                </Modal>

                <Modal show={keyparams.iscolorsvisible} dialogClassName="modalwide">
                    <div className="ndt_modal">
                        <div style={{ height: "430px", width: "500px" }}>
                            <div style={{ fontSize: "22px" }}>Select Colors Manually</div>

                            <div style={{ display: "flex" }}>

                                <div style={{ width: "300px", height: "340px", marginRight: "5px" }}>
                                    <button className="ndt_btn1" style={keyparams.colorSubIdx === 0 ? { boxShadow: "0px 0px 10px white" } : {}} onClick={_ => setKeyParams({ ...keyparams, colorSubIdx: 0 })}>Primary</button>
                                    <button className="ndt_btn1" style={keyparams.colorSubIdx === 1 ? { boxShadow: "0px 0px 10px white" } : {}} onClick={_ => setKeyParams({ ...keyparams, colorSubIdx: 1 })}>Secondary</button>

                                    <div style={{ padding: "5px", background: "black", height: "340px", overflowY: "scroll" }}>
                                        {Object.keys(params.colors).map((n: string) => {
                                            let coloritmStyle = { backgroundColor: 'black', width: "250px" }
                                            if (n === String(keyparams.colorIdx)) { coloritmStyle = { "backgroundColor": "red", width: "250px" } }
                                            return (
                                                <div className="qmvv_coloritm" key={n} style={coloritmStyle} onClick={_ => setKeyParams({ ...keyparams, colorIdx: +n })}>
                                                    <div style={{ width: "220px" }}>Color Group {+n + 1}: {params.colors[n][0]}</div>
                                                    <div style={{ height: '20px', width: "20px", border: "1px solid white", backgroundColor: params.colors[n][0] }}></div>
                                                    <div style={{ height: '20px', width: "20px", border: "1px solid white", backgroundColor: params.colors[n][1] }}></div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div id="qmvv_swatches" style={{ height: "320px", marginTop: "40px" }}>
                                    <SwatchesPicker onChangeComplete={(e: any) => ChangeColor(e)} />
                                </div>
                            </div>
                            <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, iscolorsvisible: false })}>Close</button>
                        </div>
                    </div>
                </Modal>
            </div>
            : <div></div>}
    </div >)
};

export default QueryModelViewVisual;