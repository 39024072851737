import ChartSample from "../../../charts/sample/ChartSample";
import GetRandomHexadecimal from "../../../functions/GetRandomHexadecimal";
import { useContext, useState, useEffect } from 'react'
import { StartupContext } from "../../../App";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import QueryModelRerun from "../../../data/Query/QueryModel_Rerun";
import { BasicQuery, QueryModel } from "../../../type";
import FilterData from "../../../functions/FilterData";
import ConvertBackendArryString from "../../../functions/String Functions/ConvertBackendArryString";
import ObjectSort from "../../../functions/ObjectSort";
import GetQueryRunDateKey from "../../../functions/QueryFunctions/GetQueryRunDateKey";
import { QueryVisualsInit } from "../../../InitTypes";


const EmployeeAtAGlance = () => {

    const { config, schema, user, paths } = useContext(StartupContext)
    const [fmtparams, setFmtParams] = useState({ format: 'dec', rounding: 0 })
    const randcolors = [1, 2, 3, 4, 5].map(_ => { return GetRandomHexadecimal(6) })

    interface paramtype {
        asofdate: Date;
        loaded: number; refresh: number;
    }
    interface paramtype1 {
        modelkey: string; datekey: string;
    }
    interface paramtype2 {
        modelkey: string; datekey: string;
    }
    //const [empdata, setEmpData] = useState<any[]>([])
    const [dqueries, setDQueries] = useState<QueryModel[]>([])
    const [keyparams, setKeyParams] = useState<paramtype>({
        asofdate: new Date(),
        loaded: 0, refresh: 0
    })
    const [params1, setParams1] = useState<paramtype1>({ modelkey: '', datekey: '' })
    const [params2, setParams2] = useState<paramtype2>({ modelkey: '', datekey: '' })

    useEffect(() => {
        //0: init
        GenericBackendCall("", schema, paths.query.queryview, user, config, { type: "base", default: "True" }).then(d => {
            let queries: QueryModel[] = []
            console.log(d)
            d.forEach((qry: QueryModel) => {
                qry.rundates = ObjectSort(qry.rundates, "AsofDate", true)
                qry.Metrics = ConvertBackendArryString(qry.MetricOrder)
                queries.push(qry)
            })
            setDQueries(queries)
        })
    }, [])

    const DataRefresh = () => {
        //1: Clicked refresh
        let demoquerymth = FilterData(dqueries, "ModelName", "DemographicMonthly") //hardcoded from backend
        Query_Assign(demoquerymth, setParams1)

        let demoquerydept = FilterData(dqueries, "ModelName", "DemographicbyDept") //hardcoded from backend
        Query_Assign(demoquerydept, setParams2)


    }

    const Query_Assign = (query: QueryModel[], setparams: any) => {
        //2: Gather query parameters
        if (query.length == 1) {
            let datekey = GetQueryRunDateKey(QueryVisualsInit[0], query[0].rundates, keyparams.asofdate)
            let modelkey = query[0].ModelKey
            if (datekey !== "") {
                setparams({ ...keyparams, modelkey: modelkey, datekey: datekey })
            }
            else {
                QueryModelRerun(modelkey, schema, new Date(), new Date(), "", "", user, config, "").then(d => {
                    console.log(d)
                    setparams({ ...keyparams, modelkey: modelkey, datekey: datekey })
                    setKeyParams({ ...keyparams, loaded: keyparams.loaded + 1 })
                })
            }
        }
    }

    useEffect(() => {
        if (params1.datekey !== "") {
            PullRunDate(params1.datekey, "#eaag_chart1")
        }
    }, [params1.datekey])

    useEffect(() => {
        if (params2.datekey !== "") {
            PullRunDate(params2.datekey, "#eaag_chart2")
        }
    }, [params2.datekey])

    const PullRunDate = (datekey: string, chartid: string) => {
        let otherdata = { how: "keys", rdkeys: [datekey] }
        GenericBackendCall("", schema, paths.query.rundatesview, user, config, otherdata).then(rd => {
            console.log(rd)
            if (rd.length === 1) {
                //setfn(rd[0].basicquery)
                MakeChart(rd[0].basicquery, chartid)
            }
        })
    }

    const MakeChart = (data: BasicQuery[], chartkey: string) => {
        ChartSample("Line", chartkey,
            data, [],
            ["Variable", "Value", "Grouping"], ["As of Date", "Value"],
            "Discrete", randcolors,
            [500, 400], "On", false, { format: fmtparams.format, rounding: fmtparams.rounding, clr: "black", lineclr: "black" }
        )
    }

    return (<div className="ndt_canvas">
        <div className="ndt_title2">At A Glance</div>

        <button className="ndt_btn1" onClick={_ => { DataRefresh() }}>Refresh</button>

        <div id="eaag_chart1" style={{ background: "white", height: '400px', width: "500px" }}></div>

        <div id="eaag_chart2" style={{ background: "white", height: '400px', width: "500px" }}></div>

    </div>)
};
export default EmployeeAtAGlance