
import AxiosUpload from '../AxiosUpload';


const Employee_Upload = async (empFile: any, params: any, headerslist: any,
	schema: string, config: any, elementstring: string) => {

	console.log(params);

	let dataArray = new FormData();
	dataArray.append("schemakey", schema);
	dataArray.append("workkey", "");

	dataArray.append("modelname", params.modelname);
	dataArray.append("empFile", empFile);
	dataArray.append("reporttype", params.reporttype);

	//options
	dataArray.append("assumehire", String(params.assumehire));
	dataArray.append("hiredatefield", String(params.hiredatefield));
	dataArray.append("assumexfr", String(params.assumexfr));
	dataArray.append("xfrdatefield", String(params.xfrdatefield));

	dataArray.append("nameoverwrite", String(params.nameoverwrite));
	dataArray.append("rememberheaders", String(params.rememberheaders));
	dataArray.append("overwriteentry", String(params.overwriteentry));
	//dataArray.append("includevacant", String(params.includevacant));



	let arryheaders = Object.keys(headerslist).map((hdkey: string) => {
		return [
			String(headerslist[hdkey][0]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
			String(headerslist[hdkey][1]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
			String(headerslist[hdkey][2]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
			String(headerslist[hdkey][5]).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
		]
	});

	dataArray.append("headerslist", String(arryheaders))

	let elem = document.getElementById(elementstring)
	if (elem) { elem.innerHTML = "Uploading..." }

	//axios call
	let retdata = await AxiosUpload(dataArray, "api/employee/datapost", config, elem, "")

	return (retdata)
};
export default Employee_Upload;