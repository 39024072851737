
import { ChangeEvent, useState, useContext, useEffect, useMemo } from "react";
import { FieldMap, EmpModel, ModelBatch } from "../../../type";
import { MouseEvent } from "react";
import GetUniquesFromObjectAttribute from "../../../functions/GetUniquesFromObjectAttribute";
import EmpModelDelete from "../../../data/Employee/EmpModel_Delete";
import { StartupContext } from "../../../App";
import { Emp_State } from "../../../type";
import { Modal } from "react-bootstrap";
import EmpRowImport from "../../../data/Employee/EmployeeRow_Import";
import ReturnValfromType from "../../../functions/ReturnValfromType";
import IsInVector from "../../../functions/IsInVector";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import FilterbyDates from "../../../functions/Date Functions/FilterbyDates";
import DatePicker from "react-date-picker";
import DateString from "../../../functions/Date Functions/DateString";
import FieldsImport from "../../../data/Fields/Fields_Import";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import FilterData from "../../../functions/FilterData";
import { useParams } from "react-router-dom";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import GetFieldName from "../../../functions/GetFieldName";
import ObjectSort from "../../../functions/ObjectSort";
import "./css/EmployeeModelViewer.css"
import StyleMainInput from "../../../styling/StyleMainInput";
import StyleMainSelect from "../../../styling/StyleMainSelect";

const EmployeeModelViewer = () => {

    let { config, dispatcher, schema, paths, user } = useContext(StartupContext)
    let { id } = useParams()

    interface param_type {
        selField: string; selFieldAttrName: string; detailvalue: string; loading: boolean; submitdates: boolean;
        isdelete: boolean; ismodalvisible: boolean; isdelbatchvisible: boolean;
        page: number, maxpage: number, refresh: number; fromDate: Date; toDate: Date;
        newvaluesonly: boolean; includeworkspace: boolean; batcheslength: number; mdlstring: string;
    }

    const [params, setParams] = useState<param_type>({
        selField: '', selFieldAttrName: '', detailvalue: '', loading: false, submitdates: false,
        isdelete: true, ismodalvisible: false, isdelbatchvisible: false,
        page: 0, maxpage: 0, refresh: 0,
        fromDate: new Date(), toDate: new Date(), newvaluesonly: false,
        includeworkspace: false, batcheslength: 0, mdlstring: ''
    });

    const [fields, setFields] = useState<FieldMap[]>([])
    const [selModel, setselModel] = useState<EmpModel | null>(null);
    const [selBatch, setSelBatch] = useState<ModelBatch | null>(null);
    const [allModelDates, setAllModelDates] = useState<any[]>([])

    const [empAttrlist, setEmpAttrlist] = useState<any[]>([]);
    const [batches, setBatches] = useState<ModelBatch[] | null>(null);
    const [allBatches, setAllBatches] = useState<ModelBatch[] | null>(null);

    const [detail, setDetail] = useState<Emp_State[] | null>(null);
    const [selDetail, setSelDetail] = useState<Emp_State | null>(null);

    const [newvalues, setNewValues] = useState<any[]>([])
    const [newvalueFields, setNewValueFields] = useState<string[]>([])

    //const detailFlds = ['Name', "Date", "Position", "Job Code", "Location", "EmpID", "DeptID", "ReportsToID", "Status"]
    const styleOn = { background: "darkblue", boxShadow: '0px 0px 7px violet, inset 0px 0px 4px  white' }
    const styleOff = { background: "#2a1f1c", boxShadow: '' }
    let reptypeCounts: any = { entry: 0, hire: 0, promo: 0, transfer: 0, term: 0 }

    const pageStyleEmpty = { color: 'black' }
    const pageStyleActive = { color: 'white' }

    useEffect(() => {
        let flds = FieldsImport("EmployeeState", schema, config, dispatcher, false)
        flds.then(d => setFields(d))

    }, [])

    useEffect(() => {
        if (params.refresh >= 100) { window.location.reload() }
    }, [params.refresh])


    useEffect(() => {
        if (allBatches) {
            allBatches.forEach((mb: ModelBatch) => {
                if (mb.BatchKey === id) {
                    setSelBatch(mb)
                }
            })
        }
    }, [allBatches])

    useEffect(() => {
        if (params.isdelete === true) {
            GenericBackendCall("", schema, paths.employee.empmodelview, user, config, { includeworkspace: params.includeworkspace }).then(d => {
                setAllBatches(d);
                let modeldates: any[] = []
                //console.log(d)
                d.forEach((batch: ModelBatch) => {
                    batch.models.forEach((mdl: EmpModel) => {
                        modeldates.push({ Date: mdl.AsofDate, Key: mdl.ModelKey })
                    })
                })
                modeldates = ObjectSort(modeldates, "Date", true)
                setAllModelDates(modeldates)
                let nows_data = FilterData(d, "WorkspaceName", "null")
                setBatches(nows_data);
                setParams({ ...params, isdelete: false, loading: false, refresh: 99, batcheslength: nows_data.length })
            })
        }
    }, [params.isdelete, params.includeworkspace]);

    useEffect(() => {
        setselModel(null)
    }, [selBatch])

    useEffect(() => {
        if (selModel) {
            let promise = ImportAndSetStateGeneric(setNewValues, selModel.ModelKey, schema, paths.fields.allvaluesview, user, config, {})
            promise.then(d => {
                //set unique fields
                setNewValueFields(GetUniquesFromObjectAttribute(d, "FieldName", true))
            })
        }
    }, [selModel])


    useEffect(() => {
        if (allBatches) {
            let filtmodels = [];
            let uselen = allBatches.length
            if (params.submitdates) {
                filtmodels = FilterbyDates(allBatches, "CreateDate", params.fromDate, params.toDate);
                setSelBatch(null)
                setselModel(null)
            } else {
                filtmodels = allBatches;
            }
            if (!params.includeworkspace) {
                filtmodels = FilterData(filtmodels, "WorkspaceName", "null")
                uselen = FilterData(allBatches, "WorkspaceName", "null").length
            }
            setBatches(filtmodels);
            setParams({ ...params, submitdates: false, batcheslength: uselen })
        }
    }, [params.submitdates]);


    const ClickModel = (modelkey: string, mdlstring: string) => {
        if (modelkey !== "") {
            GenericBackendCall(modelkey, schema, paths.employee.datamodel, user, config, { type: "single" }).then(
                (d) => { if (d) { setselModel(d) } })
            setEmpAttrlist([])
            setParams({ ...params, mdlstring: mdlstring })
        }
    }


    const DeleteModel = () => {
        let elem: HTMLElement | null = document.getElementById("selModelview_delete")
        if (elem && selModel) { EmpModelDelete(selModel.ModelKey, config, elem) }
        setParams({ ...params, isdelete: true })
    };

    const ClickDeleteBatch = () => {
        if (selBatch) {
            let models = selBatch.models.map((em: EmpModel) => { return em.ModelKey })

            GenericBackendCall(selBatch.BatchKey, schema, paths.generics.genericdelete, user, config,
                { model: "ModelBatch", field: "BatchKey", modelkeys: models, workspacekey: selBatch.WorkspaceName }, "emv_delbatchdiv")
        }
    }


    const NewFieldSelected = (e: MouseEvent<HTMLDivElement>) => {
        if (selModel) {
            let fldname = GetFieldName(fields, e.currentTarget.attributes[0].value, "Key", "FieldName")
            let fldattrname = GetFieldName(fields, e.currentTarget.attributes[0].value, "Key", "AttrName")
            setParams({ ...params, selField: fldname, selFieldAttrName: fldattrname, page: 0, maxpage: 0 });
            let fldkey: string = e.currentTarget.attributes[0].value
            let fldattr: string = e.currentTarget.attributes[1].value

            if (params.newvaluesonly) {
                let filtnew = FilterData(newvalues, "FieldName", fldkey)
                setEmpAttrlist(GetUniquesFromObjectAttribute(filtnew, "Value"))
            } else {
                setEmpAttrlist(GetUniquesFromObjectAttribute(selModel.state, fldattr))
            }
        }
    };

    //divs
    const FieldList = () => {

        return (
            <div id="emv_fldlist" className="ndt_innerbox">
                {fields.map((fld: FieldMap, i: number) => {
                    if (params.newvaluesonly && IsInVector(newvalueFields, fld.Key) || !params.newvaluesonly) {
                        return (
                            <div key={i} data-val={fld.Key} data-name={fld.AttrName} className="ndt_item"
                                onClick={(e: MouseEvent<HTMLDivElement>) => NewFieldSelected(e)}>
                                {fld.FieldName}
                            </div>
                        )
                    }
                })}
            </div>
        )
    };

    const DetailPopup = (e: MouseEvent<HTMLDivElement>) => {
        let fldval = e.currentTarget.attributes[0].value;
        let fcount = +e.currentTarget.attributes[1].value;

        if (selModel) {
            let rowdata = { "field1": "ModelKey", "keystring1": selModel.ModelKey, "field2": params.selFieldAttrName, "keystring2": fldval }
            let detailpromise = EmpRowImport(rowdata, schema, config, dispatcher, false)
            detailpromise.then(d => {
                //console.log(d)
                setDetail(d)
                setParams({ ...params, ismodalvisible: true, detailvalue: fldval, page: 0, maxpage: d.length })
            });
        }
    };

    const FieldInfoBox = () => {
        return (
            <div>
                <div className="ndt_innerbox ndt_title4" > -- {params.selField} --</div>
                <div className="ndt_innerbox">
                    <div>Total Records: {selModel?.state.length} </div>
                    {params.selFieldAttrName !== "" ?
                        <div>Total Unique: {empAttrlist.length}</div> : <div></div>}

                    <div className="ndt_subinner" id="emv_fldbox">
                        <div className="emv_fldboxdetail" style={{ fontSize: '19px', fontWeight: "bold", letterSpacing: ".01em" }} >
                            <div className="emv_itmName"> Category </div>
                            <div className="emv_itmVal"> Count </div>
                        </div>
                        <div id="emv_fldinfosub">
                            {empAttrlist.map((itm: any, i: number) => {
                                return (
                                    <div data-value={String(itm[0])} data-count={String(itm[1])} className="emv_fldboxdetail" key={'xa' + i} onClick={(e: MouseEvent<HTMLDivElement>) => DetailPopup(e)}>
                                        <div className="emv_itmName" key={'a' + itm[0]} > {itm[0]} </div>
                                        <div className="emv_itmVal" key={'b' + itm[0]} > {itm[1]} </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const BatchDDown = () => {
        let isempty: boolean = false;
        if (batches) { batches.length > 0 ? isempty = false : isempty = true }
        return (
            <GenericDropdown
                data={batches}
                className={"ndt_dropdown"}
                change={(e: ChangeEvent<HTMLSelectElement>) => {
                    if (batches) {
                        ChangeSelectorbyIndex(e, batches, setSelBatch, "", null)
                    }
                    setSelDetail(null)
                    setselModel(null)
                    setParams({ ...params, mdlstring: '' })
                }}
                keycol={"BatchKey"}
                namecol={"BatchName"}
                divID="emv_mdldown"
                promptstring="Select Model Name"
                includeDefault={isempty}
                defaultstring="No models available!"
            />
        )
    };

    const ddownmemo = useMemo(() => BatchDDown(), [batches]);

    const Details = () => {
        return (<div>
            <div id="emv_detailhead">
                <div style={{ display: "flex", height: "150px" }}>
                    <div className="emv_paginate" style={params.page > 0 ? pageStyleActive : pageStyleEmpty} onClick={_ => ChangePage(-1)}>{"< Page "}</div>
                    <div id="emv_detailheaddata">
                        <div style={{ fontSize: '19px', margin: "auto" }}>Page {params.page} of {params.maxpage}</div>
                        <div className="emv_itmdetail">Model: {selBatch?.BatchName} {params.mdlstring}</div>
                        <div className="emv_itmdetail">Attribute: {params.selField}</div>
                        <div className="emv_itmdetail">Value: "{params.detailvalue}" </div>
                    </div>
                    <div className="emv_paginate" onClick={_ => ChangePage(1)} style={params.page < params.maxpage ? pageStyleActive : pageStyleEmpty}>{"Page >"}</div>
                </div>
            </div>
            <div>
                {selDetail ?
                    <div id="emv_detaillst">
                        <div key={-1} className="emv_detailitm" style={{ border: '2px solid white' }}>
                            <div className="emv_detailitm_a" style={{ fontSize: "19px", "letterSpacing": ".1em" }}>Details</div>
                        </div>
                        {fields.map((k: FieldMap, i: number) => {
                            return (<div key={i} className="emv_detailitm">
                                <div className="emv_detailitm_a">{k.FieldName}:</div>
                                <div className="emv_detailitm_b">{String(ReturnValfromType(selDetail, k.AttrName))}</div>
                            </div>)

                        })}
                    </div>
                    : <div id="emv_detailnone">Scroll Page to view Employee Details</div>}
            </div>
        </div>)
    };
    const ChangePage = (diff: number) => {
        if (params.page + diff >= 1 && params.page + diff <= params.maxpage) {
            setParams({ ...params, "page": params.page + diff });
            if (detail) {
                setSelDetail(detail[params.page + diff - 1]) //detail "n" is one ahead

            }
        }
    };
    const ClickedClose = () => {
        setParams({ ...params, ismodalvisible: false, page: 0, maxpage: 0 })
        setDetail(null)
        setSelDetail(null)
    };

    return (
        <div className="ndt_canvas" id="modelview_canvas">
            <StyleMainSelect
                base={<div>
                    {ddownmemo}
                </div>}
                delete={<button className="ndt_btn2" style={{ marginLeft: "10px", height: '28px' }} onClick={_ => setParams({ ...params, isdelbatchvisible: true })}>Delete Batch</button>}
                deleteCondition={selBatch ? true : false}
                highlight={selBatch ? false : true}
            />
            <StyleMainInput
                base={<div id="emv_dateoptdiv" >
                    <div style={{ fontSize: "20px" }}>Select Upload Batch by Range</div>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                        <div style={{ letterSpacing: ".1em", marginTop: "2px" }}>From Date</div>
                        <div className="datepick_class" style={{ height: "30px", marginLeft: "10px", marginRight: "10px" }}>
                            <DatePicker value={params.fromDate} onChange={(e: any) => { setParams({ ...params, fromDate: e }) }} />
                        </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                        <div style={{ letterSpacing: ".1em", marginTop: "2px" }}>To Date</div>
                        <div className="datepick_class" style={{ height: "30px", marginLeft: "10px", marginRight: "10px" }}>
                            <DatePicker value={params.toDate} onChange={(e: any) => { setParams({ ...params, toDate: e }) }} />
                        </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <button className="ndt_btn1" onClick={_ => { setParams({ ...params, includeworkspace: !params.includeworkspace }) }}
                            style={params.includeworkspace ? styleOn : styleOff}>Include Simulations</button>
                        <button className="ndt_btn1" onClick={_ => { setParams({ ...params, loading: false, submitdates: false, fromDate: new Date(), toDate: new Date() }) }}>Clear Selection</button>
                    </div>

                    <div style={{ marginTop: "20px" }}>
                        <button className="ndt_btn1" onClick={_ => { setParams({ ...params, submitdates: true }) }}>Submit</button>
                    </div>
                    {allBatches ? <div style={{ margin: '5px' }}>Uploads: {batches?.length} of {params.batcheslength}</div> : <div></div>}
                </div>}
                highlight={selBatch ? false : true}
            />

            <div className="ndt_gridbox" style={{ height: "165px", overflow: "visible" }}>
                <div className="ndt_title2">Employee Data Model Viewer</div>
                <div style={{ display: "block" }}>
                    <div style={{ display: "flex" }}>
                        {selBatch ?
                            <div >
                                <div style={{ fontSize: "20px", display: "flex" }}>
                                    <div style={{ marginRight: "15px" }}>Select From Models:</div>
                                    <select className="ndt_dropdown" value={params.mdlstring} onChange={(e: ChangeEvent<HTMLSelectElement>) => { ClickModel(e.target.selectedOptions[0].attributes[0].value, e.target.selectedOptions[0].attributes[1].value) }}>
                                        <option data-value={""} data-name={""}>Select a Dataset</option>
                                        {selBatch.models.map((em: EmpModel, i: number) => {
                                            reptypeCounts[em.ReportType] += 1
                                            let retstring = em.ReportType + "(" + reptypeCounts[em.ReportType] + ")" + DateString(em.AsofDate)
                                            return (<option data-value={em.ModelKey} data-name={retstring} key={i}>
                                                {retstring}
                                            </option>)
                                        })}
                                    </select>
                                </div>

                            </div> : <div>Select a data batch from the sidebar</div>}
                    </div>
                </div>
                <div style={{ height: "40px", fontSize: "17px" }}>
                    {selModel ? <div className="ndt_subinner" style={{ display: 'flex', marginBottom: '20px', border: "1px solid white", background: "#93a5b6" }}>
                        <div className="emv_mdldet">Viewing Model: {selModel.ModelName} </div>
                        <div className="emv_mdldet">Report: {selModel.ReportType}</div>
                        <div className="emv_mdldet">As of: {DateString(selModel.AsofDate)}</div>
                        <div className="emv_mdldet">Created:{DateString(selModel.CreateDate)}</div>
                        <button style={{ marginLeft: '10px', height: "33px", fontSize: "16px" }} className="ndt_btn2" onClick={_ => DeleteModel()}>Delete</button>
                        <div id="selModelview_delete"></div>
                    </div> : <div></div>}
                </div>
            </div>

            <div className="ndt_grid">
                {selModel ?
                    <div id="mv_maingrid">
                        <div className="ndt_gridbox emv_box">
                            <div id="emv_valuetypeoptn" style={{ display: 'flex' }}>
                                <button className="ndt_btn1" style={!params.newvaluesonly ? styleOn : styleOff} onClick={_ => setParams({ ...params, newvaluesonly: false })}>All Values</button>
                                <button className="ndt_btn1" style={params.newvaluesonly ? styleOn : styleOff} onClick={_ => setParams({ ...params, newvaluesonly: true })}>New Values Only</button>
                            </div>
                            <FieldList />
                        </div>
                        <div className="ndt_gridbox emv_box">
                            <FieldInfoBox />
                        </div>
                    </div>
                    : <div className="ndt_gridbox">
                        <div style={{ fontSize: "20px" }}>Timeline:</div>
                        <div className="ndt_subinner" style={{ overflowY: "scroll", height: '450px', width: "300px" }}>
                            {allModelDates.map((md: any, i: number) => {
                                return (<div key={i}>{DateString(md.Date)}</div>)
                            })}
                        </div>
                    </div>}
            </div>

            <Modal show={params.ismodalvisible}>
                <div className="ndt_modal">
                    <div id="emv_detaildiv">
                        {detail ? <div> <Details /> </div> : <div></div>}
                    </div>
                    <button className="closemodalbtn" onClick={_ => ClickedClose()}>Close</button>
                </div>
            </Modal >

            <Modal show={params.isdelbatchvisible}>
                <div className="ndt_modal">
                    <div >
                        <div>Delete Entire Batch {selBatch?.BatchName}?</div>
                        <button className="ndt_btn2" onClick={_ => { ClickDeleteBatch() }}>Delete</button>
                        <div id="emv_delbatchdiv"></div>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdelbatchvisible: false, refresh: params.refresh + 1 })}>Close</button>
                </div>
            </Modal >

        </div >
    )

};
export default EmployeeModelViewer;