import axios from "axios";
import ConvertDateToObject from "../../functions/Date Functions/ConvertDateToObject";
import { User } from "../../type";


const QueryModelRerun = async (modelkey: string, schemakey: string, workspacekey: string, asofdate: Date, enddate: Date, schedulekeys: string[], rundatetext: string,
    user: User, config: any, elementstring: string) => {
    //ref: querymodelviewschedule, querymodelviewrun, datasetwalkthrough, dashboardsmanager

    let elem = document.getElementById(elementstring);
    // if (String(schedulekey) === "null") { schedulekey = "" }

    let dateAsOf = ConvertDateToObject(asofdate)
    let dateEnd = ConvertDateToObject(enddate)

    let dataArray = new FormData();
    dataArray.append("initialrun", String(false));
    dataArray.append("schemakey", schemakey);
    dataArray.append("workspacekey", workspacekey);

    dataArray.append("existingmodelkey", modelkey); //for models that are being updated
    dataArray.append("runDatetext", String(rundatetext));

    dataArray.append("enddate_yr", String(dateEnd.year));
    dataArray.append("enddate_mo", String(dateEnd.month));
    dataArray.append("enddate_dy", String(dateEnd.day));

    dataArray.append("asofdate_yr", String(dateAsOf.year));
    dataArray.append("asofdate_mo", String(dateAsOf.month));
    dataArray.append("asofdate_dy", String(dateAsOf.day));
    dataArray.append("schedulekeys", String(schedulekeys));

    dataArray.append("userid", user.id);

    let msg: string = "uploading..."
    if (elem) { elem.innerHTML = msg };

    try {
        await axios.post<any>(`${process.env.REACT_APP_API_URL}/api/query/basicpost`, dataArray, config)
        msg = "Complete"
    }
    catch (err) {
        msg = "Error!"
    }

    if (elem) { elem.innerHTML = msg };

};
export default QueryModelRerun