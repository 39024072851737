import { ChangeEvent, useState, useContext, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { StartupContext } from "../../../App";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import { Workspace } from "../../../type";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import { WorkspaceInit } from "../../../InitTypes";
import StringToBoolean from "../../../functions/String Functions/StringToBoolean";
import "./css/SimulateEmployee.css"
import FieldsImport from "../../../data/Fields/Fields_Import";
import { FieldMap, TableModel } from "../../../type";
import IsInObject from "../../../functions/Object Functions/IsInObject";
import DateAdd from "../../../functions/Date Functions/DateAdd";
import ModelCategoryDropdown from "../../../functions/Dropdowns/ModelCategoryDropdown";
import TableGroupsImport from "../../../data/Tables/TableGroups_Import";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import IsInVector from "../../../functions/IsInVector";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";


const SimulateEmployee = () => {

    let { config, dispatcher, schema, paths, user, clickstyle } = useContext(StartupContext)

    const [workspaces, setWorkspaces] = useState<Workspace[]>([])
    const [selWorkspace, setSelWorkspace] = useState<Workspace | null>(null)
    interface siminterface {
        NEmps: number; NEmpty: number; PosnRate: number; DateSelect: Date;
        Iterations: number; HireRate: number; TermRate: number; PeriodDays: number;
        issavevisible: boolean; workspacekey: string; workspacename: string; fromData: boolean;
        issave: boolean; toWorkspace: boolean;
    }
    const [simParams, setSimParams] = useState<siminterface>({
        NEmps: 100, NEmpty: 20, PosnRate: .4, DateSelect: new Date(), Iterations: 5,
        HireRate: .1, TermRate: .08, PeriodDays: 30, issavevisible: false,
        workspacekey: '', workspacename: '', issave: false, toWorkspace: false, fromData: true
    })
    const [keyparams, setKeyParams] = useState<any>({
        latestempdate: "", btnSelected: "", resetfields: 0, fromDate: DateAdd(new Date(), - 90), toDate: new Date(),
        grouptbls: [], iscomplete: false
    })
    const styleSelected = { backgroundColor: "lightblue", boxShadow: "0px 0px 6px yellow", color: "black" }
    const styleUnSelected = { backgroundColor: "#999999", boxShadow: "", color: "black" }
    const [fields, setFields] = useState<FieldMap[]>([])
    const [trendfields, setTrendFields] = useState<FieldMap[]>([])
    const [tablegroups, setTablegroups] = useState<any | null>(null)
    const [tables, setTables] = useState<TableModel[]>([])
    const [udata, setUData] = useState<any[]>([])
    const [udataheaders, setUDataHeaders] = useState<string[]>([])

    let pctheaders = ["N Emps", "TermPct", 'HirePct', 'XFRPct']

    useEffect(() => {
        let flds = FieldsImport("", schema, config, dispatcher, false)
        flds.then(d => {
            setFields(d)
            TableGroupsImport("", schema, paths.tables.connectionnetwork, user, config, dispatcher, false).then(d => {

                Object.keys(d).forEach((a: string) => {
                    if (a === "EmployeeState") {
                        setKeyParams({ ...keyparams, grouptbls: d[a] })
                    }
                })
                setTablegroups({ tables: d })
                ImportAndSetStateGeneric(setTables, "*", schema, paths.tables.tablemodelview, user, config, {})
            })
        })
    }, [])

    useEffect(() => {
        GenericBackendCall("", schema, paths.generics.genericfilterview, user, config, { model: "Workspace" }).then(d => {
            setWorkspaces(SerializeDataFrame(d))
        })
        GenericBackendCall("", schema, paths.generics.genericdateinfo, user, config, { model: "EmployeeState", type: 'max' }).then(d => {
            setKeyParams({ ...keyparams, latestempdate: d })
        })
    }, [])

    useEffect(() => {
        setKeyParams({ ...keyparams, fromDate: DateAdd(simParams.DateSelect, -90), toDate: simParams.DateSelect })
    }, [simParams.DateSelect])

    const ClickSim = () => {
        let othparams = {}
        if (selWorkspace) {
            othparams = {
                ...simParams, workspacekey: selWorkspace.WorkKey, workspacename: selWorkspace.WorkspaceName,
                toworkspace: true, fromData: simParams.fromData
            }
        } else {
            othparams = { ...simParams, workspacekey: "", workspacename: "", toworkspace: false, fromData: simParams.fromData }
        }
        let rdata: any[] = [];
        udata.forEach((data: any) => {
            udataheaders.forEach((h: string) => {
                rdata.push(data[h])
            })
        })
        othparams = { ...othparams, data: rdata }

        GenericBackendCall("", schema, paths.simulation.simulatepost, user, config, othparams, "esim_divupl")
    }

    const ClickAddField = (e: ChangeEvent<HTMLSelectElement>) => {
        let val = +e.target.selectedOptions[0].attributes[0].value
        let keyval = fields[val]
        if (!IsInObject(trendfields, keyval, "Key")) {
            let newfields = trendfields
            newfields.push(fields[val])
            setTrendFields(newfields)
            setKeyParams({ ...keyparams, resetfields: keyparams.resetfields + 1 })
        }
    };

    const ClickRmvFld = () => {
        let tf = trendfields
        let idx = +IsInObject(tf, keyparams.btnSelected, "Key", true)
        tf.splice(idx, 1)
        setTrendFields(tf)
        setKeyParams({ ...keyparams, resetfields: keyparams.resetfields + 1 })
    }

    const ClickGenerateTrends = () => {
        let flds = trendfields.map((tf: FieldMap) => { return tf.Key })

        GenericBackendCall('', schema, paths.simulation.generatetrends, user, config,
            { fields: flds, fromDate: keyparams.fromDate, toDate: simParams.DateSelect }).then(d => {
                console.log(d)
                let data = SerializeDataFrame(d)
                let elem = document.getElementById("div_empgen")
                if (data.length > 0) {
                    setUData(data)
                    setUDataHeaders(Object.keys(data[0]))
                    console.log(data)
                } else {
                    if (elem) { elem.innerHTML = "" }
                }
                setKeyParams({ ...keyparams, iscomplete: true })
            })
    }

    const TrendFieldList = () => {
        return (
            <div className="ndt_subinner">
                <div style={{ fontSize: "19px" }}>Custom Trends By Field</div>

                <div style={{ height: "35px", display: "flex", width: '70%' }}>
                    {trendfields.map((fld: FieldMap, i: number) => {
                        return (<div key={i} className="empsim_fldbtn"
                            style={keyparams.btnSelected === fld.Key ? clickstyle.btnSelected : clickstyle.btnUnselected}
                            onClick={_ => { if (keyparams.btnSelected === fld.Key) { setKeyParams({ ...keyparams, btnSelected: "" }) } else { setKeyParams({ ...keyparams, btnSelected: fld.Key }) } }}
                        >{fld.FieldName}</div>)
                    })}
                    {keyparams.btnSelected !== "" ?
                        <button className="ndt_btn2" style={{ marginRight: "10px" }} onClick={_ => { ClickRmvFld() }}>Remove</button>
                        : <div></div>}
                </div>

            </div>
        )
    };

    const trendfieldmemo = useMemo(() => { return <TrendFieldList /> }, [keyparams.resetfields, keyparams.btnSelected])

    const ModelCategoryDdown = () => {
        return (
            <ModelCategoryDropdown tables={tables} usetables={keyparams.grouptbls} className="ndt_dropdown"
                change={(e: ChangeEvent<HTMLSelectElement>) => {
                    setKeyParams({ ...keyparams, modelcat: e.target.selectedOptions[0].attributes[0].value })
                }} />
        )
    }
    const modelcatmemo = useMemo(() => { return <ModelCategoryDdown /> }, [keyparams.grouptbls])


    const ChangeValue = (i: number, k: string, value: number) => {
        let newdata = udata
        newdata[i][k] = value
        setUData(newdata)
    }


    return (<div className="ndt_canvas">
        <div className="ndt_title2">Simulate Employee Data</div>

        <div id="empsim_optrun" style={{ display: "flex", marginLeft: "15px" }}>
            <div style={{ marginRight: "5px", fontSize: "20px" }}>Simulation Type</div>
            <button className="ndt_btn1" style={simParams.fromData ? styleUnSelected : styleSelected} onClick={_ => setSimParams({ ...simParams, fromData: false })}>Run Random</button>
            <button className="ndt_btn1" style={simParams.fromData ? styleSelected : styleUnSelected} onClick={_ => setSimParams({ ...simParams, fromData: true })}>Run from Data</button>
        </div>

        <div className="ndt_title3">Timeframe</div>
        <div>Latest Employee Date: {keyparams.latestempdate}</div>
        <div id="empsim_datebox" className="ndt_innerbox" style={{ overflow: "visible" }}>
            <div className="empsim_parambox">
                <div className="empsim_paramtitle">Date Begin</div>
                <div className="datepick_class" style={{ width: '165px' }}>
                    <DatePicker value={simParams.DateSelect} onChange={(e: any) => {
                        setSimParams({ ...simParams, DateSelect: e })
                    }} />
                </div>
            </div>
            <div className="empsim_parambox">
                <div className="empsim_paramtitle">Iterations</div>
                <input type="number" min={1} max={12}
                    value={simParams.Iterations}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setSimParams({ ...simParams, Iterations: +e.target.value })
                    }} />
            </div>
            <div className="empsim_parambox">
                <div className="empsim_paramtitle">Number of Days</div>
                <input type="number" min={1} max={300}
                    value={simParams.PeriodDays}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setSimParams({ ...simParams, PeriodDays: +e.target.value })
                    }} />
            </div>
        </div>
        <br />

        <div id="empsim_main">
            <div>
                {
                    !simParams.fromData ?
                        <div>
                            <div className="ndt_subinner">
                                <div className="empsim_parambox">
                                    <div className="empsim_paramtitle">NEmps</div>
                                    <input type="number" min={10} max={6000}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setSimParams({ ...simParams, NEmps: +e.target.value })
                                        }} />
                                </div>
                                <div className="empsim_parambox">
                                    <div className="empsim_paramtitle">Empty Posns</div>
                                    <input type="number" min={10} max={1000}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setSimParams({ ...simParams, NEmpty: +e.target.value })
                                        }} />
                                </div>
                                <div className="empsim_parambox">
                                    <div className="empsim_paramtitle">Position Transfer Rate (%)</div>
                                    <input type="number" min={1} max={100}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setSimParams({ ...simParams, PosnRate: +e.target.value / 100 })
                                        }} />
                                </div>
                                <div className="empsim_parambox">
                                    <div className="empsim_paramtitle">Hire Rate (%)</div>
                                    <input type="number" min={1} max={100}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setSimParams({ ...simParams, HireRate: +e.target.value / 100 })
                                        }} />
                                </div>
                                <div className="empsim_parambox">
                                    <div className="empsim_paramtitle">Term Rate (%)</div>
                                    <input type="number" min={1} max={100}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setSimParams({ ...simParams, TermRate: +e.target.value / 100 })
                                        }} />
                                </div>
                            </div>
                            <br />
                            <button className="ndt_btn1" style={{ marginTop: "20px", marginLeft: "10px" }} onClick={_ => setSimParams({ ...simParams, issavevisible: true })}>Run Sim</button>
                            <div id="div_empgen"></div>

                        </div>
                        : <div className="ndt_gridbox" style={{ height: "600px", overflow: "visible" }}>
                            <div style={{ height: '500px' }}>
                                {trendfieldmemo}
                                {modelcatmemo}

                                <GenericDropdown
                                    data={fields}
                                    keycol="Key"
                                    namecol="FieldName"
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ClickAddField(e) }}
                                    promptstring="Select Field"
                                    className="ndt_dropdown"
                                    filterfield="ModelCategory"
                                    filtervalue={keyparams.modelcat}
                                />
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginRight: "15px" }} className="empsim_date">
                                        <div>From Date</div>
                                        <div className="datepick_class" style={{ width: '165px' }}>
                                            <DatePicker value={keyparams.fromDate} onChange={(e: any) => {
                                                setKeyParams({ ...keyparams, fromDate: e })
                                            }} />
                                        </div>
                                    </div>
                                    <div className="empsim_date">
                                        <div>To Date</div>
                                        <div className="datepick_class" style={{ width: '165px' }}>
                                            <DatePicker value={keyparams.toDate} onChange={(e: any) => {
                                                setKeyParams({ ...keyparams, toDate: e })
                                            }} />
                                        </div>
                                    </div>
                                    <button className="ndt_btn1" style={{ marginTop: "20px", marginLeft: "10px" }} onClick={_ => { ClickGenerateTrends() }}>Generate</button>
                                    <div id="div_empgen"></div>

                                </div>
                            </div>
                            {keyparams.iscomplete ?
                                <div>
                                    <button style={{ marginLeft: "10px", width: "200px" }} className="ndt_btn1" onClick={_ => setSimParams({ ...simParams, issavevisible: true })}>Run Sim</button>
                                </div>
                                : <div></div>}
                        </div>
                }
            </div>

            <div className="ndt_gridbox" >
                <div style={{ fontSize: "18px" }}>Rate Data</div>

                <div className="sim_datarow">
                    {udataheaders.map((k: string, i: number) => {
                        let uwidth = { width: "80px" }
                        let fldname = k
                        if (!IsInVector(pctheaders, k)) {
                            uwidth = { width: "200px" }
                            fldname = GetObjectField(k, fields, "AttrName", "FieldName")
                        }
                        return (<div key={i} style={uwidth} className="sim_dataitm">{fldname}</div>)
                    })}
                </div>
                <div style={{ height: "500px", overflowY: "scroll" }}>
                    {udata.map((data: any, i: number) => {
                        return (<div key={i} className="sim_datarow">
                            {Object.keys(data).map((k: string, t: number) => {
                                if (!IsInVector(pctheaders, k)) {
                                    return (<div id={"simval" + i + "_" + t} key={String(i) + "_" + String(t)} style={{ width: "200px" }} className="sim_dataitm">{data[k]}</div>)
                                } else {
                                    return (<input id={"simval" + i + "_" + t} type="number" step={1} min={0} max={100} key={String(i) + "_" + String(t)}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => ChangeValue(i, k, +e.target.value)}
                                        style={{ width: '80px' }} placeholder={data[k]} className="sim_dataitm" />)
                                }
                            })}
                        </div>)
                    })}
                </div>
            </div>
        </div>
        <br />


        <Modal show={simParams.issavevisible}>
            <div className="ndt_modal">
                <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ width: '200px' }}>Save Export?</div>
                    <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setSimParams({ ...simParams, issave: StringToBoolean(e.target.selectedOptions[0].attributes[0].value) }) }}>
                        <option>Set:</option>
                        <option data-value={true}>Save</option>
                        <option data-value={false}>Don't Save</option>
                    </select>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ width: '200px' }}>Save to Workspace?</div>
                    <button style={simParams.toWorkspace ? styleSelected : styleUnSelected} onClick={_ => setSimParams({ ...simParams, toWorkspace: true })}>Yes</button>
                    <button style={simParams.toWorkspace ? styleUnSelected : styleSelected} onClick={_ => setSimParams({ ...simParams, toWorkspace: false })}>No</button>
                </div>
                <div style={{ marginLeft: "200px", display: "flex", marginBottom: "10px" }}>
                    {simParams.toWorkspace ?
                        <div style={{ display: "flex" }}>
                            <div>
                                <GenericDropdown
                                    data={workspaces}
                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, workspaces, setSelWorkspace, "WorkKey", WorkspaceInit[0]) }}
                                    promptstring="Select Workspace"
                                    defaultstring="Add New"
                                    keycol="WorkKey"
                                    namecol="WorkspaceName"
                                    includeDefault={true}
                                />
                                {selWorkspace ?
                                    <div>
                                        {selWorkspace.WorkKey === "" ?
                                            <div>
                                                New Workspace Name
                                                <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => { setSelWorkspace({ ...selWorkspace, WorkspaceName: e.target.value }) }} />
                                            </div> : <div>Workspace: {selWorkspace.WorkspaceName}</div>}

                                    </div> : <div></div>}
                            </div>

                        </div>
                        : <div></div>}
                </div>

                <br />
                <div style={{ marginLeft: "10px" }}>Run with these parameters?</div>
                <button className="ndt_btn1" style={{ width: "200px", marginLeft: "30px" }} onClick={_ => { ClickSim() }}>Run</button>
                <div id="esim_divupl"></div>

                <button className="closemodalbtn" onClick={_ => setSimParams({ ...simParams, issavevisible: false })}>Close</button>
            </div>
        </Modal >




    </div >)
};
export default SimulateEmployee;