import { MouseEvent } from "react";
import { Emp_State, Employee } from "../../../../type";
import "./css/EmployeeCard.css"
import { useState } from "react";
import EmployeeStateDetail from "./EmployeeStateDetail";


const EmployeeCard = (props: any) => {

    let Employee: Employee | null = props.Employee;

    let currState: Emp_State;
    let prevState: Emp_State;

    const [selID, setselID] = useState(0);
    const [lastEmp, setlastEmp] = useState<string>("");

    //const currFormat = new Intl.NumberFormat('en-us',{style:'currency',currency:'USD'})

    const Overall = () => {
        let entryno = 1;
        if (Employee) {
            console.log(Employee)
            //force using the first entry for a newly selected employee, so selID doesn't carry over
            if (Employee.EmpID !== lastEmp) {
                currState = Employee.state[0]
                Employee.state.length === 1 ? prevState = Employee.state[1] : prevState = Employee.state[0]
            } else {
                entryno = selID + 1;
                currState = Employee.state[selID]
                selID < Employee.state.length - 1 ? prevState = Employee.state[selID + 1] : prevState = Employee.state[selID]
            }
            return (
                <div id="cardGrid">
                    <EntryList />
                    <EmployeeStateDetail currState={currState} prevState={prevState} entryno={entryno} />
                </div>
            )
        } else {
            return (<div></div>)
        }
    };

    const EntryList = () => {
        return (
            <div className="ddownCard">
                <div id='ddownHeader'>Timeline:</div>
                <div className="ddownMain">
                    {Employee?.state.map((st: Emp_State, i: number) => {
                        return (
                            <div
                                key={i}
                                id={String(i)}
                                className="datepoint"
                                onClick={(e: MouseEvent<HTMLElement>) => Changecard(e)}
                            >
                                {st.Date}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    };

    const Changecard = (e: MouseEvent<HTMLElement>) => {
        let intgr = +e.currentTarget.id
        setselID(intgr)
        if (Employee) { setlastEmp(Employee.EmpID) }
    };

    return (
        <div id="empboxMain" >
            <div id="empboxtitle" style={{ "color": "white" }}> {selID} Employee: {Employee?.Name} (id: {Employee?.EmpID}) </div>
            <Overall />
            <i className="arrow down"></i>

        </div>
    )
};
export default EmployeeCard