import ConvertToUTC from "./ConvertToUTC";


const DateRemoveTimestamp = (date: any) => {
    //also handles string
    //ref: chartprimary, 

    let newdate = date;

    if (typeof (date) !== "object") {
        //string, new date time conversion subtracts tz hours to become previous day
        newdate = ConvertToUTC(new Date(date))
    }

    newdate = new Date(
        newdate.getFullYear(),
        newdate.getMonth(),
        newdate.getDate()
    );

    if (String(newdate) === "Invalid Date" || String(date).indexOf("-") === -1) { //indexof to prevent true numbers from converting
        return (date)
    } else {
        return newdate
    }
};
export default DateRemoveTimestamp;