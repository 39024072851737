import { Benchmarkset, FieldMap, Metric, QueryModel, Schedule } from "../../../type";
import { useState, useEffect, useContext } from 'react'
import QueryModelViewRun from "./QueryModelViewRun";
import QueryModelViewVisual from "./QueryModelViewVisual";
import { QueryModelInit } from "../../../InitTypes";
import { StartupContext } from "../../../App";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import QueryModelViewInspector from "./QueryModelViewInspector";
import QueryModelViewScheduler from "./QueryModelViewScheduler";
import { useParams } from "react-router-dom";
import QueryModelViewDataEditor from "./QueryModelViewDataEditor";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import ConvertBackendArryString from "../../../functions/String Functions/ConvertBackendArryString";
import ObjectSort from "../../../functions/ObjectSort";
import "./css/QueryModelView.css"
import QueryModelView3D from "./QueryModelView3D";
import { Modal } from "react-bootstrap";
import GetObjectValue from "../../../functions/Object Functions/GetObjectValue";
import DateString from "../../../functions/Date Functions/DateString";
import { useNavigate } from "react-router-dom";


const QueryModelView = () => {

    let { id } = useParams()
    let history = useNavigate();

    const { config, dispatcher, schema, user, paths } = useContext(StartupContext)


    const [params, setParams] = useState<any>({ viewpage: "", isdetailvisible: false, isdeletevisible: false })
    const [query, setQuery] = useState<QueryModel>(QueryModelInit[0])
    const [fields, setFields] = useState<FieldMap[]>([])
    const [metrics, setMetrics] = useState<Metric[]>([])
    const [schedules, setSchedules] = useState<Schedule[]>([])
    const [benchmarksets, setBenchmarkSets] = useState<Benchmarkset[]>([])

    useEffect(() => {
        if (id) {
            GenericBackendCall(id, schema, paths.query.queryview, user, config, { type: "base", querykeys: [id], permissions: ['creator', 'manage', 'read/write'] }).then(d => {
                if (d.length > 0) {
                    try {
                        d[0].rundates = ObjectSort(d[0].rundates, "AsofDate", true)
                        setQuery({ ...d[0], Metrics: ConvertBackendArryString(d[0].MetricOrder) })
                    } catch (error) {
                        console.log("error - loaded run dates improperly")
                    }
                }
            })
        }
        ImportAndSetStateGeneric(setFields, "", schema, paths.fields.fieldretrieve, user, config, {})
        ImportAndSetStateGeneric(setMetrics, "", schema, paths.query.metricview, user, config, {})
        ImportAndSetStateGeneric(setSchedules, "", schema, paths.schedule.scheduleview, user, config, {})
    }, []);

    useEffect(() => {
        if (query.ModelKey !== "") {
            ImportAndSetStateGeneric(setBenchmarkSets, query.ModelKey, schema, paths.benchmark.benchmarksetview, user, config, {})
        }
        console.log(query)
    }, [query.ModelKey])


    const ClickDelete = () => {
        GenericBackendCall(query.ModelKey, schema, paths.generics.genericdelete,
            user, config, { model: "QueryModel", field: "ModelKey" }, "qm_deletediv")
            .then(_ => { history('/query/managegraph') });
    }

    useEffect(() => {
        if (params.viewpage === "scheduler") {
            history("/graph/" + query.ModelKey + "/schedule")
        } else if (params.viewpage === "viseditor") {
            history("/graph/" + query.ModelKey + "/visuals")
        } else if (params.viewpage === "runs") {
            history("/graph/" + query.ModelKey + "/runs")
        } else if (params.viewpage === "inspector") {
            history("/graph/" + query.ModelKey + "/inspector")
        } else if (params.viewpage === "dataeditor") {
            history("/graph/" + query.ModelKey + "/dataeditor")
        } else if (params.viewpage === "3dviewer") {
            history("/graph/" + query.ModelKey + "/3dviewer")
        }
    }, [params.viewpage])

    return (<div className="ndt_canvas">
        <div id="qmv_header" className="ndt_gridbox ndt_header">
            <div style={{ display: "flex" }}>
                <div className="ndt_title2" style={{ width: "75%" }}>Query Model Manager</div>
                <div style={{ marginTop: "10px", fontSize: "20px", cursor: "pointer" }} onClick={_ => { setParams({ ...params, isdetailvisible: true }) }}>Model: {query.ModelName}</div>
            </div>
            {query.ModelKey !== "" ? <div style={{ overflowX: "hidden", height: '50px' }}>
                <button id="qmv_optsched" className="ndt_btnmain qmv_btnopt" onClick={_ => setParams({ ...params, viewpage: "scheduler" })}>Scheduler</button>
                <button id="qmv_optvisual" className="ndt_btnmain qmv_btnopt" onClick={_ => setParams({ ...params, viewpage: "viseditor" })}>Visual Editor</button>
                <button id="qmv_optruns" className="ndt_btnmain qmv_btnopt" onClick={_ => setParams({ ...params, viewpage: "runs" })}>Compare Runs</button>
                <button id="qmv_optinsp" className="ndt_btnmain qmv_btnopt" onClick={_ => setParams({ ...params, viewpage: "inspector" })}>Inspect</button>
                <button id="qmv_optedit" className="ndt_btnmain qmv_btnopt" onClick={_ => setParams({ ...params, viewpage: "dataeditor" })}>Data Editor</button>
                <button className="ndt_btnmain qmv_btnopt" onClick={_ => setParams({ ...params, viewpage: "3dviewer" })}>3D Graph</button>
                <button className="ndt_btn2 qmv_btnopt" onClick={_ => setParams({ ...params, isdeletevisible: true })}>Delete</button>
            </div> : <div></div>}
        </div>
        {(() => {
            switch (params.viewpage) {
                case "scheduler": return <QueryModelViewScheduler query={query} setQuery={setQuery} fields={fields} schedules={schedules} />;
                case "viseditor": return <QueryModelViewVisual query={query} setQuery={setQuery} fields={fields} metrics={metrics} />;
                case "runs": return <QueryModelViewRun query={query} setQuery={setQuery} fields={fields} metrics={metrics} schedules={schedules} />
                case "inspector": return <QueryModelViewInspector query={query} setQuery={setQuery} fields={fields} />;
                case "dataeditor": return <QueryModelViewDataEditor query={query} setQuery={setQuery} fields={fields} metrics={metrics} />;
                case "3dviewer": return <QueryModelView3D query={query} setQuery={setQuery} fields={fields} metrics={metrics} />;
                default: return <div></div>;
            }
        })()}

        <Modal show={params.isdetailvisible}>
            <div className="ndt_modal">
                <div>
                    {Object.keys(query).map((k: string, i: number) => {
                        if (typeof (GetObjectValue(query, k)) !== "object") {
                            return (<div key={i}>{k} - {DateString(GetObjectValue(query, k))}</div>)
                        }
                    })}
                </div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdetailvisible: false })}>Close</button>
            </div>
        </Modal >

        <Modal show={params.isdeletevisible}>
            <div className="ndt_modal">
                <div className="ndt_modalheader">Delete Model {query.ModelName}?</div>
                <div>
                    <button className="ndt_btn2" onClick={_ => { ClickDelete() }}>Yes, delete</button>
                </div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdeletevisible: false })}>Close</button>
            </div>
        </Modal >

    </div>)
};
export default QueryModelView;