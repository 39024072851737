import { useEffect, useContext, useState, ChangeEvent } from 'react'
import FieldsImport from '../../../data/Fields/Fields_Import';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import { StartupContext } from '../../../App';
import { Connection, FieldMap, TableModel, ConnectionSet, snaState, NetworkRunDate, Node, Link, ConnectionModel } from '../../../type';
import ModelCategoryDropdown from '../../../functions/Dropdowns/ModelCategoryDropdown';
import ModelCategoryTablesDropdown from '../../../functions/Dropdowns/ModelCategoryTablesDropdown';
import FilterData from '../../../functions/FilterData';
import GetObjectValue from '../../../functions/Object Functions/GetObjectValue';
import { Modal } from 'react-bootstrap';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import SerializeDataFrame from '../../../functions/Import/SerializeDataFrame';
import DateString from '../../../functions/Date Functions/DateString';
import { ConnectionInit, ConnectionSetinit, TableModelInit, NetworkRunDateInit, NodeInit, LinkInit, ConnectionModelInit, SNAStateInit } from '../../../InitTypes';
import ConnectionSetUpload from '../../../data/Tables/ConnectionSet_Upload';
import StringAfterDelimeter from '../../../functions/String Functions/StringAfterDelimeter';
import "./css/ConnectionManager.css";
import NetworkGraph2D from '../../sna/networkgraph/NetworkGraph2D';
import IsInObject from '../../../functions/Object Functions/IsInObject';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import GetHardcodedTables from '../../../context/GetHardcodedTables';
import StringTrim from '../../../functions/String Functions/StringTrim';
import IsInVector from '../../../functions/IsInVector';
import StyleMainSelect from '../../../styling/StyleMainSelect';

interface missing {
    Value: string;
    Date: Date;
    Field: string;
    Side: string;
}


const ConnectionManager = () => {

    const [SNAstate, setSNAstate] = useState<snaState>({ ...SNAStateInit, value: { fstrength: -200, dmin: 5, dmax: 150 } })

    let highlightcolor = "rgb(179, 222, 162)"
    interface cm_params {
        fromTbl: string;
        toTbl: string;
        fromSelFld: string;
        toSelFld: string;
        fromSelFldName: string;
        toSelFldName: string;
        modelname: string;
        issavevisible: boolean;
        issavemdlvisible: boolean;
        isdelconnvisible: boolean;
        isdelmdlvisible: boolean;
        warning: string;
        missingside: string;
        resetConn: boolean;
        boxview: string;
        refresh: number;
        graphvisible: any;
        selected: boolean;
        checked: boolean;
    }
    let [paramFlds, setParamFlds] = useState<{ fromFlds: any[], toFlds: any[] }>({ fromFlds: [], toFlds: [] })
    let { config, dispatcher, schema, paths, user, clickstyle } = useContext(StartupContext)
    let defaulttablesAll = GetHardcodedTables(true)
    let defaulttablesSecondary = GetHardcodedTables(false)
    let defaulttablesPrimary = defaulttablesAll.filter(item => !defaulttablesSecondary.includes(item));


    let [fields, setFields] = useState<FieldMap[]>([])
    let [tbls, setTbls] = useState<TableModel[]>([])
    let [righttbls, setRightTbls] = useState<TableModel[]>([])

    let [params, setParams] = useState<cm_params>({
        fromTbl: "", toTbl: "", fromSelFld: "", toSelFld: "",
        fromSelFldName: "", toSelFldName: "", modelname: '',
        issavevisible: false, isdelconnvisible: false, isdelmdlvisible: false, issavemdlvisible: false,
        warning: "", missingside: "", resetConn: false, boxview: '', refresh: 0, graphvisible: "hidden", selected: false, checked: false
    })

    let [connmodels, setConnModels] = useState<ConnectionModel[]>([])
    let [selConnMdl, setSelConnMdl] = useState<ConnectionModel | null>(null)
    let [allconnections, setAllConnections] = useState<ConnectionSet[]>([])
    let [activeconnset, setActiveConnSet] = useState<ConnectionSet>(ConnectionSetinit[0])
    let [oldconnection, setOldConnection] = useState<Connection | null>(null)
    let [missingData, setMissingData] = useState<missing[] | null>(null)
    let [examples, setExamples] = useState<any>({ 0: [], 1: [] })
    let [selNetworkRunDate, setSelNetworkRunDate] = useState<NetworkRunDate>(NetworkRunDateInit[0])

    useEffect(() => {
        if (params.refresh >= 1000) { window.location.reload() }
    }, [params.refresh])

    useEffect(() => {
        ImportAndSetStateGeneric(setConnModels, "", schema, paths.tables.connectionmodelview, user, config, {})
        FieldsImport("*", schema, config, dispatcher, true).then(d => { setFields(d) })
        ImportAndSetStateGeneric(setTbls, "", schema, paths.tables.tablemodelview, user, config, {})
    }, [])

    useEffect(() => {
        if (selConnMdl) {
            ImportAndSetStateGeneric(setAllConnections, selConnMdl.ModelKey, schema, paths.tables.connectionsetview, user, config, {})
            setParams({ ...params, fromTbl: '', toTbl: '', fromSelFld: '', toSelFld: '', boxview: '', selected: true })
        } else {
            setParams({ ...params, selected: false })
        }
    }, [selConnMdl])


    useEffect(() => {
        let nodes: Node[] = []
        let links: Link[] = []

        defaulttablesAll.forEach((str: string) => {
            nodes.push({ ...NodeInit[0], Key: str, Name: str })
        })
        tbls.forEach((tbl: TableModel) => {
            if (!IsInObject(nodes, tbl.ModelName, "Name")) {
                nodes.push({ ...NodeInit[0], Key: tbl.ModelName, Name: tbl.ModelName })
            }
        })

        allconnections.forEach((cn: ConnectionSet) => {

            links.push({ ...LinkInit[0], Source: cn.ModelCategory1, Target: cn.ModelCategory2 })
        })

        let newnetwork = { ...NetworkRunDateInit[0], nodes: nodes, links: links }
        setSelNetworkRunDate(newnetwork)
    }, [allconnections])

    useEffect(() => {
        let rt: TableModel[] = tbls.map((t: TableModel) => {
            return t
        });

        // if (params.fromTbl === "EmployeeState") {
        let ctables = defaulttablesSecondary
        ctables.forEach((t: string) => {
            rt.push({ ...TableModelInit[0], ModelName: t })
        })
        //} 
        setRightTbls(rt)
    }, [params.fromTbl])

    useEffect(() => {
        if (params.fromTbl != "") {
            let fromflds: FieldMap[] = FilterData(fields, "ModelCategory", params.fromTbl)
            setParamFlds({ ...paramFlds, fromFlds: fromflds })
            GenericBackendCall("", schema, paths.generics.genericexample, user, config, { modelcategory: params.fromTbl }).then(d => {
                setExamples({ ...examples, [0]: d })
            })
            GetActiveConnections()
        }
    }, [params.fromTbl])

    useEffect(() => {
        if (params.toTbl !== "") {
            let toflds: FieldMap[] = FilterData(fields, "ModelCategory", params.toTbl)
            setParamFlds({ ...paramFlds, toFlds: toflds })
            GenericBackendCall("", schema, paths.generics.genericexample, user, config, { modelcategory: params.toTbl }).then(d => {
                setExamples({ ...examples, [1]: d })
            })
            GetActiveConnections()
        }
    }, [params.toTbl])

    const GetActiveConnections = () => {
        setMissingData(null)
        if (selConnMdl) {
            let connpromise = GenericBackendCall(selConnMdl.ModelKey, schema, paths.tables.connectionsetview, user, config, { modelcategory1: params.fromTbl, modelcategory2: params.toTbl })
            connpromise.then((d: ConnectionSet[]) => {
                if (d.length > 0) {
                    setActiveConnSet(d[0]);
                } else {
                    setActiveConnSet(ConnectionSetinit[0])
                }
            })
        }
    }

    useEffect(() => {
        let fldname = StringAfterDelimeter(params.fromSelFld, "__")
        setParams({ ...params, fromSelFldName: fldname })
    }, [params.fromSelFld])

    useEffect(() => {
        let fldname = StringAfterDelimeter(params.toSelFld, "__")
        setParams({ ...params, toSelFldName: fldname })
    }, [params.toSelFld])

    useEffect(() => {
        if (params.resetConn) {
            setParams({
                ...params, fromSelFld: '', toSelFld: '', fromSelFldName: '',
                toSelFldName: '', warning: "", resetConn: false
            })
        }
    }, [params.resetConn])

    useEffect(() => {
        if (params.boxview === "graph") {
            setParams({ ...params, graphvisible: "visible", refresh: params.refresh + 1 })
        } else {
            setParams({ ...params, graphvisible: "hidden", refresh: params.refresh + 1 })
        }
    }, [params.boxview])

    useEffect(() => {
        if (params.fromTbl === "" || params.toTbl === "") {
            setParams({ ...params, warning: "select two tables", checked: false })
        } else if (params.fromTbl === params.toTbl) {
            setParams({ ...params, warning: "cannot set to same table", checked: false })
        } else if (IsInVector(defaulttablesAll, params.toTbl) && IsInVector(defaulttablesAll, params.toTbl)) {
            setParams({ ...params, warning: "cannot change connections between these tables", checked: false })
        } else {
            setParams({ ...params, warning: "", checked: true })
        }
    }, [params.fromTbl, params.toTbl])

    //-----backend---------
    const ClickCheck = () => {
        if (selConnMdl) {
            GenericBackendCall(selConnMdl.ModelKey, schema, paths.tables.connectioncheck, user, config,
                { modelcategory1: params.fromTbl, modelcategory2: params.toTbl }).then(d => {
                    //console.log(d)
                    if (d.length !== 1 || d[0] !== "There was an error!") {
                        setMissingData(SerializeDataFrame(d))
                    } else {
                        console.log("returned error")
                    }
                })
        }
    }

    const SaveConnection = () => {
        if (selConnMdl) {
            ConnectionSetUpload(selConnMdl.ModelKey, activeconnset.connections, params.fromTbl, params.toTbl, activeconnset.ConnectionType, schema,
                paths.tables.connectionpost, config, "conn_divsave").then(_ => { setParams({ ...params, refresh: 999 }) })
        }
    };

    const DeleteConnection = () => {
        if (oldconnection) {
            GenericBackendCall(oldconnection.ConnKey, schema, paths.generics.genericdelete, user, config,
                { "model": "Connection", "field": "ConnKey" }, "conn_divdelupl").then(_ => { setParams({ ...params, refresh: 999 }) })
        }
    };

    const ClickSaveModel = () => {
        GenericBackendCall("", schema, paths.tables.connectionmodelpost, user, config,
            { "modelname": params.modelname }, "conn_divuplmdl").then(_ => { setParams({ ...params, refresh: 999 }) })
    };
    const ClickDeleteModel = () => {
        if (selConnMdl) {
            GenericBackendCall(selConnMdl.ModelKey, schema, paths.generics.genericdelete, user, config,
                { "model": "ConnectionModel", field: "ModelKey" }, "conn_divuplmdl").then(_ => { setParams({ ...params, refresh: 999 }) })
        }
    };

    //--------------------------

    const CheckExistConn = (fldkey: string): number[] => {
        let existnum: number[] = []
        activeconnset.connections.forEach((ac: Connection, i: number) => {
            if (ac.Field1 === fldkey || ac.Field2 === fldkey) {
                existnum.push(i + 1)
            }
        })
        return existnum
    };

    const FieldsBox = (props: any) => {
        let flds: FieldMap[] = props.flds;
        let selString: string = props.selString;
        let num: number = props.num;
        let tblname: string = props.tblname;

        return (
            <div className="conn_fldlist ndt_innerbox">
                <div style={{ fontSize: "22px" }}>Table {num + 1}: {tblname}</div>
                {
                    flds.map((f: FieldMap, i: number) => {
                        let check: number[] = CheckExistConn(f.Key)
                        let fstyle = { backgroundColor: "" }
                        let showvalue: string = f.FieldName

                        if (check.length > 0) { showvalue = showvalue + " (" + check.toString() + ")" }
                        if (f.Key === GetObjectValue(params, selString)) { fstyle = { backgroundColor: highlightcolor } }
                        let exval = GetExampleValue(num, f.AttrName)

                        return (
                            <div key={i} style={fstyle} className="cmgr_itmrow"
                                onClick={_ => setParams({ ...params, [selString]: f.Key })}
                            >
                                <div style={{ width: '200px' }}>{showvalue}</div>
                                <div style={{ width: "200px" }}>{exval}</div>
                            </div>
                        )
                    })
                }
            </div >
        )
    }

    const GetExampleValue = (tblno: number, field: string) => {
        let retval: string = ""
        Object.keys(examples[tblno]).forEach((k: string) => {
            if (field === k) {
                if (examples[tblno][k][0])
                    retval = examples[tblno][k][0]
            }
        })
        return (retval)
    }

    const ConnectionBox = () => {
        return (<div id="conn_cboxcheck">
            <button className='ndt_btn1' onClick={_ => setParams({ ...params, boxview: "data" })}>Check Data</button>
            <button className='ndt_btn1' onClick={_ => setParams({ ...params, boxview: "graph" })}>Check Graph</button>
            {(() => {
                switch (params.boxview) {
                    case "data": return <ConnCheck />;
                    case "graph": return <ConnGraph />;
                    default: return <div></div>;
                }
            })()}

        </div>)
    }

    NetworkGraph2D(selNetworkRunDate, SNAstate, setSNAstate, "#cngraph1", [450, 450], params.refresh)

    const ConnGraph = () => {
        return (<div>
            <div id="cngraph1" style={{ width: '450px', height: "450px", visibility: params.graphvisible, background: "white" }}></div>
        </div>)
    }

    const ConnCheck = () => {
        return (<div id="cmgr_checkbox" style={{ width: "500px" }}>
            {params.fromTbl !== "" && params.toTbl !== "" ?
                <div>
                    <div className="ndt_title4" style={{ fontSize: "20px" }}>Check Connection Missing Values</div>
                    <button className="ndt_btn1" onClick={_ => { ClickCheck() }}>Check</button>
                </div>
                : <div></div>}
            {missingData ? <div>
                Total Rows:{missingData.length}
                <div>
                    <button className="ndt_btn1 cm_tbltbn" style={params.missingside === "Left" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setParams({ ...params, missingside: "Left" }) }}>Left Table</button>
                    <button className="ndt_btn1 cm_tbltbn" style={params.missingside === "Right" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setParams({ ...params, missingside: "Right" }) }}>Right Table</button>
                </div>
                <div className="cmgr_missrow">
                    <div className="cmgr_missitm">As of Date</div>
                    <div className="cmgr_missitm">On Field</div>
                    <div className="cmgr_missitm">Unmatched Value</div>
                </div>
                <div className="cmgr_scroll" style={{ width: "500px" }}>
                    {missingData.map((drow: missing, n: number) => {
                        if (drow.Side === params.missingside) {

                            return (<div key={n} className="cmgr_missrow">
                                <div className="cmgr_missitm">{DateString(drow.Date)}</div>
                                <div className="cmgr_missitm">{drow.Field}</div>
                                <div className="cmgr_missitm">{StringTrim(drow.Value, 25)}</div>
                            </div>)
                        }
                    })}
                </div>
            </div> : <div></div>
            }
        </div>)
    };


    const LastCheck = () => {
        if (CheckExistConn(params.fromSelFld).length === 0 && CheckExistConn(params.toSelFld).length === 0) {
            let tmpconns = activeconnset.connections
            tmpconns.push({ ...ConnectionInit[0], Field1: params.fromSelFld, Field2: params.toSelFld })
            setParams({ ...params, warning: "", resetConn: true })
            setActiveConnSet({ ...activeconnset, connections: tmpconns })
        } else {
            setParams({ ...params, warning: "Cannot assign same field twice!" })
        }
    }

    return (<div className="ndt_canvas">
        <div className="ndt_title2">Connection Manager</div>
        <StyleMainSelect base={<GenericDropdown
            data={connmodels}
            keycol="ModelKey"
            namecol="ModelName"
            promptstring="Select Connection Model"
            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, connmodels, setSelConnMdl, "", ConnectionModelInit[0]) }}
            includeDefault={true}
            divID="conn_tblselddown"
            className="ndt_dropdown"
            defaultstring="New Model"
        />}
            delete={
                <button className='ndt_btn2' onClick={_ => { setParams({ ...params, isdelmdlvisible: true }) }}>Delete Model</button>
            }
            deleteCondition={selConnMdl}
            highlight={!params.selected}
        />

        {selConnMdl && selConnMdl.ModelKey !== "" ?
            <div>
                <div style={{ fontSize: "20px" }}>Model: {selConnMdl.ModelName}</div>

                <div id="conn_tblselbox" className="ndt_gridbox" style={{ display: 'flex', margin: '10px' }}>
                    <div>
                        <div>Table1</div>
                        <ModelCategoryDropdown className="conn_mcddown ndt_dropdown" tables={tbls} includesecondary={true}
                            change={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, fromTbl: e.target.value }) }} />
                    </div>
                    <div>
                        <div>Table2</div>
                        <ModelCategoryTablesDropdown className="conn_mcddown ndt_dropdown" tables={tbls} usetables={righttbls}
                            change={(e: ChangeEvent<HTMLSelectElement>) => { setParams({ ...params, toTbl: e.target.value }) }} />
                    </div>
                </div>
                <div style={{ height: "200px" }}>
                    <div style={{ height: "150px" }}>
                        <div>Connections on this pairing:</div>
                        <div style={{ display: "flex" }}>
                            {activeconnset.connections.map((conn: Connection, i: number) => {
                                let classstr = "conn_existconnitem conn_existconnitemValid"
                                if (!params.checked) {
                                    classstr = "conn_existconnitem"
                                }
                                return (<div key={i}
                                    className={classstr}
                                    onClick={_ => { if (params.checked) { setOldConnection(conn); setParams({ ...params, isdelconnvisible: true }) } }}
                                >{i + 1}:
                                    {StringAfterDelimeter(conn.Field1, "__")} -
                                    {StringAfterDelimeter(conn.Field2, "__")}
                                </div>)
                            })}
                        </div>
                        {params.fromSelFld !== "" && params.toSelFld !== "" && params.checked ?
                            <div style={{ height: '150px', marginTop: '40px' }}>
                                <div>Set Connection</div>
                                <div>{params.fromTbl} {params.fromSelFldName} to {params.toTbl} {params.toSelFldName}</div>
                                <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { LastCheck() }}>Set</button>
                            </div>
                            : <div style={{ height: '150px', color: "pink" }}>{params.warning}</div>}
                    </div>

                </div>

                <div className="ndt_gridbox" id="conn_tblmain">
                    <FieldsBox flds={paramFlds.fromFlds} num={0} tblname={params.fromTbl} selString="fromSelFld" />
                    <FieldsBox flds={paramFlds.toFlds} num={1} tblname={params.toTbl} selString="toSelFld" />
                    <ConnectionBox />
                </div>

                <div>
                </div>
                {activeconnset.connections.length > 0 ?
                    <button className="ndt_btn3" style={{ width: "300px" }} onClick={_ => setParams({ ...params, issavevisible: true })}>Save</button>
                    : <div></div>}
            </div>
            : <div>

                {selConnMdl ? <div>
                    <input type="text" value={params.modelname} onChange={(e: ChangeEvent<HTMLInputElement>) => { setParams({ ...params, modelname: e.target.value }) }} />
                    <button className='ndt_btn1' onClick={_ => { setParams({ ...params, issavemdlvisible: true }) }}>Save</button>
                </div> : <div></div>}
            </div>}

        {/* save model*/}
        <Modal show={params.issavemdlvisible}>
            <div className='ndt_modal'>
                <div>Save Model {params.modelname}?</div>
                <button className='ndt_btn1' onClick={_ => { ClickSaveModel() }}>Save</button>
                <div id="conn_divuplmdl"></div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, issavemdlvisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        {/* delete model*/}
        <Modal show={params.isdelmdlvisible}>
            <div className='ndt_modal'>
                <div>Delete Model {selConnMdl?.ModelName}?</div>
                <button className='ndt_btn2' onClick={_ => { ClickDeleteModel() }}>Delete</button>
                <div id="conn_divuplmdl"></div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdelmdlvisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        {/* save connection*/}
        <Modal show={params.issavevisible}>
            <div className='ndt_modal'>
                <div style={{ padding: "10px" }}>
                    <div style={{ fontSize: "18px" }}>Set Connection</div>

                    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <div>Connection Type</div>
                        <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { setActiveConnSet({ ...activeconnset, ConnectionType: e.target.selectedOptions[0].attributes[0].value }) }}>
                            <option value="">Set Connection Type:</option>
                            <option value="Left">Left Join</option>
                            <option value="Inner">Inner Join</option>
                            <option value="Right">Right Join</option>
                        </select>
                    </div>
                    <button className="ndt_btn1" style={{ width: "150px" }} onClick={_ => { SaveConnection() }}>Save!</button>
                    <div id="conn_divsave"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, issavevisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        {/* delete connection*/}
        <Modal show={params.isdelconnvisible}>
            <div className='ndt_modal'>
                {oldconnection ?
                    <div style={{ padding: "10px", fontSize: "18px" }}>
                        <div>Delete Connection {StringAfterDelimeter(oldconnection.Field1, "__")} -
                            {StringAfterDelimeter(oldconnection.Field2, "__")} ?</div>
                        <div>Connection Type: {activeconnset.ConnectionType}</div>
                        <button className="ndt_btn2" style={{ width: "150px" }} onClick={_ => { DeleteConnection() }}>Delete!</button>
                        <div id="conn_divdelupl"></div>
                    </div>
                    : <div></div>}
                <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdelconnvisible: false, refresh: params.refresh + 1 })}>Close</button>
            </div>
        </Modal>
    </div>)
};
export default ConnectionManager;