

import AxiosUpload from "../AxiosUpload";

const FieldsRemoveAddtl = (fieldkey: string, config: any, elemid: string) => {

    let dataArray = new FormData()
    dataArray.append("fieldkey", fieldkey)

    let retval = AxiosUpload(dataArray, 'api/fields/fieldremoveaddtl', config, null, "")

    let elem = document.getElementById(elemid);

    retval.then(d => {
        if (elem && d) { elem.innerHTML = String(d.data) }
    })

    return (retval)
};

export default FieldsRemoveAddtl;