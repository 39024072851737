
import AxiosUpload from '../AxiosUpload';
import { User, GroupNumericRule } from '../../type';
import StringReformatforBackend from '../../functions/String Functions/StringReformatforBackend';


const GroupFieldNumUpload = async (modelkey: string, groupdata: GroupNumericRule[], schemakey: string, user: User,
    config: any, elementstring: string) => {

    let dataArray = new FormData();

    let grpdataencoded = groupdata.map((d: GroupNumericRule) => {
        return ([StringReformatforBackend(d.GreaterThanThresh),
        StringReformatforBackend(d.EqualTo),
        StringReformatforBackend(d.LessThanThresh),
        StringReformatforBackend(d.BandingName)]
        )
    });

    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("modelkey", modelkey); //field key
    // dataArray.append("onfieldkey", onfieldkey);
    dataArray.append("groupdata", String(grpdataencoded));
    dataArray.append("schemakey", schemakey);
    dataArray.append("uploadvalues", String(true));


    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/groupsets/groupsetpost", config, elem, "")

    return ("Ok")
};

export default GroupFieldNumUpload;