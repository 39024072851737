import * as THREE from 'three';
import UniqueArrayValues from '../functions/UniqueArrayValues';
import PivotArray from '../functions/PivotArray';

//import {OrbitControls} from "./js/OrbitControls"

const ThreeDBarGraph = (data, colors, divID) => {

    let stored_id = ""
    let variablecol = "VarDateIdx"
    let groupingcol = "Grouping"
    let valuecol = "Value"
    let metrcol = "MetricNo"

    let w = 700;
    let h = 700;
    let mesh
    let scene = new THREE.Scene();
    let camera = new THREE.PerspectiveCamera(75, w / h, 0.1, 1000);

    let renderer = new THREE.WebGLRenderer();

    let controls = new THREE.OrbitControls(camera, renderer.domElement);
    
    renderer.setSize(w, h);


    let UniqueBars = UniqueArrayValues(data, [variablecol, metrcol])
    let UniqueGroups = UniqueArrayValues(data, groupingcol)
    let UniqueVars = UniqueArrayValues(data,variablecol)
    let UniqueMetrno = UniqueArrayValues(data, metrcol)

    let datapivot = PivotArray(data,variablecol, metrcol,valuecol)
    let BarHeights = UniqueBars.map(b => ({ 'Name': b, 'Height':-10 }))
    let datalength = UniqueBars.length
    let maxheights = Array.apply(null, Array(UniqueMetrno.length)).map(function (x, i) { return 1; })

    
    datapivot.forEach((d)=>{
        if (d['Sum']>maxheights[+d.Value[1]]){
            maxheights[+d.Value[1]] = d['Sum']
        }
    })

    const DrawBoxes = () => {

		//scene.remove(mesh)
        data.forEach((elem)=>{   
            let barnum = UniqueBars.indexOf(elem[variablecol] + elem[metrcol])
            let groupnum = UniqueGroups.indexOf(elem[groupingcol])
            let varidx = UniqueVars.indexOf(elem[variablecol])
            let metrno = elem[metrcol]
            let val = +elem[valuecol]
            let addheight = NormalizeHeight(val, metrno)
            let oldheight = +BarHeights[barnum].Height + addheight/2
            NewBox(elem, varidx, metrno, colors[groupnum].Color, oldheight)
            BarHeights[barnum].Height = oldheight+ addheight/2
        })
	}

    function NormalizeHeight(value, metrno, type){
        let heightval = +value * 20 / (maxheights[metrno])
        return heightval
    }

    const NewBox = (datapoint, i, metrno, color, oldheight) => {
        let barvalue = datapoint[valuecol]
        let geometry = new THREE.CubeGeometry(1, NormalizeHeight(barvalue, metrno,'height'), 1);
        //console.log('height', oldheight, barvalue, )
        let material = new THREE.MeshLambertMaterial({color: color})
        mesh = new THREE.Mesh(geometry, material) 
        mesh.position.x = i * 4 - datalength + metrno  //centered around 0
        //console.log(datapoint, oldheight, NormalizeHeight(barvalue, metrno))
        mesh.position.y = oldheight
        mesh.position.z = datapoint[metrcol]*3 
        mesh.userData.Color = color
        mesh.userData.Type="Bar"
        mesh.userData.id = datapoint['id']
        scene.add(mesh);
    }

    const Floor = () => {
        let geo = new THREE.PlaneGeometry(UniqueVars.length*6, UniqueMetrno.length*6);
		let material = new THREE.MeshStandardMaterial({
			side: THREE.DoubleSide,
		  });
        
        let mesh = new THREE.Mesh(geo, material);
        
		mesh.rotation.x = Math.PI/2;
        mesh.position.set(0,-10,0)
        mesh.userData.Type = "Floor"
        mesh.userData.id = ''

        scene.add(mesh)
    }

        let prevelem = document.getElementById("graphxxx")
        if (prevelem){prevelem.remove()}


        document.getElementById(divID).appendChild(renderer.domElement);
        let canvaselem = document.getElementById(divID).appendChild(renderer.domElement);
		canvaselem.setAttribute("data-name","")
        canvaselem.setAttribute("id","graphxxx")

        camera.position.z = datalength * 2
        camera.position.y = 0
		let pointlight = new THREE.PointLight(0xffffff);
		pointlight.position.set(-4,4,2)
		scene.add(pointlight)

		let ambientlight = new THREE.AmbientLight(0xf1f1f1);
		scene.add(ambientlight)

        const raycaster = new THREE.Raycaster();
        const pointer = new THREE.Vector2();

    
        //draw objects
		DrawBoxes();
        Floor();
        let oldelem = null;
        let oldcolor = '';

        //iterative
		let animate = function () {
			requestAnimationFrame( animate );
            raycaster.setFromCamera( pointer, camera );
            const intersects = raycaster.intersectObjects( scene.children );

            intersects.forEach((obj,i)=>{
                if (obj.object.userData.Type!=="Floor"){
                    
                    if (oldelem){
                        oldelem.object.material.color.set( oldcolor );
                    }
                    obj.object.material.color.set( 0xffff00 );
                    oldelem = obj
                    oldcolor = oldelem.object.userData.Color;
                    if (obj.object.userData.id!==stored_id){
                        stored_id = obj.object.userData.id
                        canvaselem.attributes['data-name'].value = stored_id
                    }
                }
            })
              
            renderer.render( scene, camera );

		};

        let canvasX = canvaselem.getBoundingClientRect().x
        let canvasY = canvaselem.getBoundingClientRect().y
        const onPointerMove = (event) =>{
            pointer.x = ( (event.clientX-canvasX) / w ) * 2 - 1;
            pointer.y = - ( (event.clientY - canvasY) / h ) * 2 + 1; 
        }
        canvaselem.addEventListener( 'pointermove', onPointerMove );

		animate();

}

export default ThreeDBarGraph;