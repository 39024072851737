import { ChangeEvent, useState, useContext, useEffect } from 'react'
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import { StartupContext } from '../../../App';
import { DescModel, JobDescription, JobSkill, JobTitle, SkillCatValue, SkillCategory } from '../../../type';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import { Modal } from 'react-bootstrap';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import "./css/JobDescriptionManager.css"
import GetUniquesFromObjectAttribute from '../../../functions/GetUniquesFromObjectAttribute';
import ChangeParamSelectorbyIndex from '../../../functions/ElementSelect/ChangeParamSelectorbyIndex';
import ConvertBackendArryString from '../../../functions/String Functions/ConvertBackendArryString';

const JobSkillManager = () => {

    let { config, dispatcher, schema, user, paths } = useContext(StartupContext)
    let [catdatafile, setCatDataFile] = useState<any>(null)
    let [descdatafile, setDescDataFile] = useState<any>(null)

    let [jobdesc, setJobDesc] = useState<JobDescription[]>([])
    let [jobtitles, setJobTitles] = useState<JobTitle[]>([])
    let [matchedjobs, setMatchedJobs] = useState<any[]>([])
    let [loadedjt, setLoadedJT] = useState<boolean>(false)
    let [loadedjd, setLoadedJD] = useState<boolean>(false)


    let [keyparams, setKeyParams] = useState({
        modelname: '', selCategory: '', categoryterms: [''],
        isupldescvisible: false, isuplcatvisible: false, isdelmdlvisible: false, isdeljdvisible: false, refresh: 0
    })
    let [jdmodels, setJDModels] = useState<DescModel[]>([])
    let [selJDModel, setSelJDModel] = useState<DescModel | null>(null)
    //let [selModelCats, setSelModelCats] = useState<SkillCategory[]>([])
    let [categories, setCategories] = useState<SkillCategory[]>([])

    const ApplyFile = (e: ChangeEvent<HTMLInputElement>, type: string) => {
        if (e.target.files) {
            if (type === "desc") {
                setDescDataFile(e.target.files[0])
            } else {
                setCatDataFile(e.target.files[0])
            }
        }
    };
    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        ImportAndSetStateGeneric(setJobTitles, '', schema, paths.jobtitle.jobtitleview, user, config, {}).then(_ => { setLoadedJT(true) })
        ImportAndSetStateGeneric(setJobDesc, '', schema, paths.jobdescription.jobdescview, user, config, {}).then(_ => { setLoadedJD(true) })
    }, [])

    useEffect(() => {
        ImportAndSetStateGeneric(setJDModels, '', schema, paths.jobdescription.descmodelview, user, config, { type: "Name" })
    }, [])

    useEffect(() => {
        if (selJDModel && selJDModel.ModelKey !== "") {
            GenericBackendCall("", schema, paths.jobdescription.descmodelview, user, config,
                { type: "Full", modelkeys: [selJDModel.ModelKey] }).then(d => {
                    if (d.length > 0) {
                        setSelJDModel(d[0])
                        let cats = GetUniquesFromObjectAttribute(d[0].categories, "CategoryNum")
                        let catobjs = cats.map((c: any) => {
                            return { Category: c[0], CategoryName: '' }
                        })
                        setCategories(catobjs)
                    }
                })
        }
    }, [selJDModel?.ModelKey])

    useEffect(() => {
        if (keyparams.selCategory) {
            let foundterms = ""
            let terms: any[] = []
            selJDModel?.categories.forEach((sc: SkillCategory) => {
                if (+sc.CategoryNum === +keyparams.selCategory) {
                    foundterms = sc.CategoryTerms
                    terms = ConvertBackendArryString(foundterms)
                }
            })
            if (foundterms !== "") {
                setKeyParams({ ...keyparams, categoryterms: terms })
            }
        }
    }, [keyparams.selCategory])

    useEffect(() => {
        if (loadedjd && loadedjt) {
            let matches: any[] = []
            console.log(jobtitles)
            jobtitles.forEach((jt: any) => {
                let newmatch = { JobCode: jt.JobCode, JobTitle: jt.JobTitle, Match: '' }
                jobdesc.forEach((jd: JobDescription) => {
                    if (jt.id === jd.JobCodeName) { newmatch.Match = "Found" }
                })
                matches.push(newmatch)
            })
            setMatchedJobs(matches)
        }
    }, [loadedjd, loadedjt])

    //------------backend---------
    const ClickUploadDesc = () => {
        GenericBackendCall('', schema, paths.jobdescription.jobskillpost, user, config,
            { file: descdatafile }, "jdm_divupl").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
    }
    const ClickUploadCat = () => {
        GenericBackendCall('', schema, paths.jobdescription.descmodelpost, user, config,
            { file: catdatafile, modelname: keyparams.modelname }, "jdm_divupl").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
    }
    const ClickDelete = () => {
        if (selJDModel) {
            GenericBackendCall(selJDModel.ModelKey, schema, paths.generics.genericdelete, user, config,
                { model: "DescModel", field: "ModelKey" }, "jdm_divdel").then(_ => {
                    setKeyParams({ ...keyparams, selCategory: '', refresh: 99 })
                    setCategories([])
                    setSelJDModel(null)
                })
        }
    }
    const ClickDeleteJD = () => {
        GenericBackendCall('', schema, paths.generics.genericdelete, user, config, { model: "JobDescription", field: "DescKey" }, "jdm_divdel").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
    }
    //-=-------------------------


    return (<div className="ndt_canvas">

        <div className="ndt_title2">Job Description Manager</div>

        <div className='ndt_gridbox' style={{ margin: '40px', height: "400px" }}>
            <div style={{ fontSize: "20px" }}>Job Description Skills</div>

            <div style={{ display: "flex" }}>
                <input id="jsm_inputskl" type="file" onChange={(e: ChangeEvent<HTMLInputElement>) => ApplyFile(e, "desc")} />

                {descdatafile ?
                    <div style={{ display: "flex" }}>
                        <div style={{ fontSize: "18px", marginRight: "10px" }}>Upload Dataset</div>
                        <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, isupldescvisible: true }) }} >Upload</button>
                    </div> : <div></div>
                }
            </div>
            <div className='jdmitmhead'>
                <div className='jdmsubitm'>Job Code</div>
                <div className='jdmsubitm'> Job Title</div>
                <div className='jdmsubitm'>Desc Exists</div>
            </div>
            <div style={{ overflowY: "scroll", height: "300px", margin: '5px' }}>
                {matchedjobs.map((job: any, i: number) => {
                    return (<div key={i} className='jdmitm'>
                        <div className='jdmsubitm'>{job.JobCode}</div>
                        <div className='jdmsubitm'> {job.JobTitle}</div>
                        <div className='jdmsubitm'>{job.Match}</div>
                    </div>)
                })}
            </div>
        </div>



        <div className="ndt_gridbox" style={{ margin: '40px' }}>
            <div>
                Model: <GenericDropdown
                    data={jdmodels}
                    keycol="ModelKey"
                    namecol="ModelName"
                    promptstring="Models"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, jdmodels, setSelJDModel, "", null) }}
                    includeDefault={true}
                    className="ndt_dropdown"
                    defaultstring="Create New Model"
                />
            </div>


            {selJDModel ?

                <div>
                    <div style={{ display: "flex" }}>
                        Category:
                        <GenericDropdown
                            data={categories}
                            keycol="Category"
                            namecol="Category"
                            promptstring="Select Category"
                            className="ndt_dropdown"
                            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, categories, keyparams, setKeyParams, "selCategory", "Category", "") }}
                        />
                        <div id="jdm_termsbox" style={{ display: "flex" }}>
                            Terms:
                            {keyparams.categoryterms.map((c: string, i: number) => {
                                return (<div key={i} style={{ marginLeft: "5px" }}>{c}, </div>)
                            })}
                        </div>
                    </div>

                    <div className='ndt_innerbox'>
                        {keyparams.selCategory !== "" ?
                            <div style={{ height: "300px", overflowY: "scroll" }}>
                                {selJDModel.categories.map((sc: SkillCategory, i: number) => {
                                    if (+sc.CategoryNum === +keyparams.selCategory) {

                                        return (<div key={i}>
                                            {sc.values.map((val: SkillCatValue, n: number) => {
                                                return (<div key={n}>
                                                    {val.JobCode} - {val.JobSkill.substring(0, 100)}
                                                </div>)
                                            })}
                                        </div>)
                                    }
                                })}
                            </div> : <div></div>
                        }
                    </div>
                    <button className='ndt_btn2' onClick={_ => { setKeyParams({ ...keyparams, isdelmdlvisible: true }) }}>Delete Model</button>
                </div> : <div>
                    <div style={{ fontSize: "18px" }}>Import Categories File</div>
                    <input id="jsm_inputcat" type="file" onChange={(e: ChangeEvent<HTMLInputElement>) => ApplyFile(e, "cat")} />
                </div>}

            {catdatafile ?
                <div>
                    Upload Dataset
                    <button className='ndt_btn1' onClick={_ => { setKeyParams({ ...keyparams, isuplcatvisible: true }) }} >Upload</button>
                </div> : <div></div>
            }


        </div>

        <div className='ndt_gridbox' style={{ margin: '50px' }}>
            {jobdesc.length > 0 ?
                <button className='ndt_btn2' onClick={_ => { setKeyParams({ ...keyparams, isdeljdvisible: true }) }}>Delete Descriptions</button>
                : <div></div>}
        </div>

        <Modal show={keyparams.isdeljdvisible}>
            <div className='ndt_modal'>
                <div className='ndt_modaltitle'>Delete Job Descriptions?</div>
                <button className="ndt_btn2" onClick={_ => { ClickDeleteJD() }}>Delete</button>
                <div id="jdm_divdel"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeljdvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdelmdlvisible}>
            <div className='ndt_modal'>
                <div className='ndt_modaltitle'>Delete this model?</div>
                <button className="ndt_btn2" onClick={_ => { ClickDelete() }}>Delete</button>
                <div id="jdm_divdel"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelmdlvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>


        {/* categories */}
        <Modal show={keyparams.isuplcatvisible}>
            <div className='ndt_modal'>
                <div className='ndt_modaltitle'>Desc Model Name</div>
                <input type="text" style={{ width: "250px" }} placeholder={keyparams.modelname} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, modelname: e.target.value }) }} />
                <button className='ndt_btn1' onClick={_ => { ClickUploadCat() }}>Upload</button>

                <div id="jdm_divupl"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isuplcatvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        {/* desc */}
        <Modal show={keyparams.isupldescvisible}>
            <div className='ndt_modal'>
                <div className='ndt_modaltitle'>Job Descriptions Upload</div>
                <button className='ndt_btn1' onClick={_ => { ClickUploadDesc() }}>Upload</button>

                <div id="jdm_divupl"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isupldescvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>
    </div>)
};
export default JobSkillManager;