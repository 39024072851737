const StringTrim = (str1: string | undefined, N: number, offset: number = 0) => {
    if (str1) {
        if (str1.length > N) {
            return str1.substring(0, N - offset) + "..."
        } else {
            return str1
        }
    } else {
        return ""
    }
};
export default StringTrim;