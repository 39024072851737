
import { useState, useEffect, ChangeEvent, useContext, useMemo } from "react";
import { FieldMap, FilterField, KPI, KPIFilter, KPIValue, QueryModel, FitModel, Schedule, FitParam } from "../../../../type";
import GenericDropdown from "../../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../../functions/ElementSelect/ChangeSelectorbyIndex";
import { StartupContext } from "../../../../App";
import ImportAndSetStateGeneric from "../../../../functions/Import/ImportAndSetStateGeneric";
import DateString from "../../../../functions/Date Functions/DateString";
import ChartSample from "../../../../charts/sample/ChartSample";
import GetRandomHexadecimal from "../../../../functions/GetRandomHexadecimal";
import GenericBackendCall from "../../../../functions/Import/GenericBackendCall";
import GetFieldName from "../../../../functions/GetFieldName";
import GetUniquesFromObjectAttribute from "../../../../functions/GetUniquesFromObjectAttribute";
import ConvertArraytoObject from "../../../../functions/Array Functions/ConvertArraytoObject";
import FormatLabel from "../../../../charts/newfunctions/FormatLabel";
import { useParams } from "react-router-dom";
import ObjectSort from "../../../../functions/ObjectSort";
import SerializeDataFrame from "../../../../functions/Import/SerializeDataFrame";
import GetObjectValue from "../../../../functions/Object Functions/GetObjectValue";
import RoundValue from "../../../../functions/Math Functions/RoundValue";
import { Modal } from "react-bootstrap";
import ConvertBackendArryString from "../../../../functions/String Functions/ConvertBackendArryString";
import IsInVector from "../../../../functions/IsInVector";
import "./css/KPIManager.css";
import DateMatch from "../../../../functions/Date Functions/DateMatch";
import DateRemoveTimestamp from "../../../../functions/Date Functions/DateRemoveTimestamp";
import DateAdd from "../../../../functions/Date Functions/DateAdd";
import CalculateDateSequence from "../../../../functions/Date Functions/CalculateDateSequence";
import CheckMetricFormat from "../../../../functions/QueryFunctions/CheckMetricFormat";
import { Link } from "react-router-dom";
import StyleMainSelect from "../../../../styling/StyleMainSelect";

const KPIManager = () => {

    let { id } = useParams()
    const { config, schema, user, paths } = useContext(StartupContext)

    interface idval {
        id: any;
        val: number;
    }
    interface paramtype {
        view: string; comparefld: string; lastN: number; refresh: number;
        generated: boolean; usermodel: boolean; usermodelstring: string; showinit: boolean; useexistingmodel: boolean;
        issavevisible: boolean; npred: number; isdelvisible: boolean; highlight: boolean;
    }
    interface trendparamstype {
        asofdate: Date;
        begindate: Date;
        period_est: number;
        preprocess: boolean;
        modelname: string;
    }
    interface tdata {
        id: number;
        AsofDate: Date;
        Value: any;
        Poly: any;
        Sine: any;
        SineInit: any;
        FFT: any;
        Curve: any;
        Grouping: string;
    }
    interface tparams {
        Trend: number[];
        Poly: number[];
        Sine: number[];
        SineInit: number[];
        Curve: number[];
        CurveInit: number[];
    }
    const tparaminit: tparams = {
        Trend: [],
        Poly: [],
        Sine: [],
        SineInit: [],
        Curve: [],
        CurveInit: []
    }

    // interface trendvarstype {
    //     Differences: idval[];
    //     Variables: number[];
    // }
    interface trendvarsfull {
        Model: tparams;
        User: tparams;
    }


    const [fmtParams, setFmtParams] = useState({ format: '', rounding: 0 })
    const [kpis, setKPIs] = useState<KPI[]>([])
    const [fields, setFields] = useState<FieldMap[]>([])
    const [selKPI, setSelKPI] = useState<KPI | null>(null)
    const [query, setQuery] = useState<QueryModel | null>(null)
    const [schedules, setSchedules] = useState<Schedule[]>([])
    const [fitmodels, setFitModels] = useState<FitModel[]>([])
    const [selFitModel, setSelFitModel] = useState<FitModel | null>(null)
    const [selSchedule, setSelSchedule] = useState<Schedule | null>(null)

    const [uniquevals, setUniqueVals] = useState<any>({ '0': [], '1': [] })
    const [filterkeys, setFilterKeys] = useState<string[]>(["", ""])
    const [data, setData] = useState<tdata[]>([])
    const [chartdata, setChartData] = useState<tdata[]>([])
    const [chartdataUser, setChartDataUser] = useState<tdata[]>([])
    const keyparaminit: paramtype = {
        view: "", comparefld: '', lastN: 0, refresh: 0, usermodel: false, npred: 0,
        usermodelstring: "Model", generated: false, showinit: true,
        issavevisible: false, isdelvisible: false, useexistingmodel: false, highlight: false
    }
    const trendparaminit: trendparamstype = {
        asofdate: new Date(), begindate: new Date(), period_est: 12, preprocess: false, modelname: ""
    }

    const [keyparams, setKeyParams] = useState<paramtype>(keyparaminit)
    const [trendparams, setTrendParams] = useState<trendparamstype>(trendparaminit)

    const [randcolors, setRandColors] = useState<string[]>([])
    const [variables, setVariables] = useState<trendvarsfull>({ Model: tparaminit, User: tparaminit })
    const [tempInputs, setTempInputs] = useState<any[]>([])  //changed by user
    const [warn, setWarn] = useState<boolean[]>([])
    const [linkstring, setLinkString] = useState<string>("")

    let styleWarn = { color: 'red' }
    let styleOk = { color: "white" }
    let translation = { Sine: "SineInit", Poly: "Trend", SineInit: "Sine", Trend: "Poly", Curve: "CurveInit", CurveInit: "Curve" }
    let translation_isinit = { Sine: false, Poly: false, SineInit: true, Trend: true, Curve: false, CurveInit: true }
    let styleOn = { backgroundColor: "rgb(100,190,50)", color: "white", opacity: "1" }
    let styleOff = { opacity: "1" }
    let varnames: any = {
        Trend: ["Intercept", "Slope"],
        Poly: ["1", "2", "3", "4", "5", "6"],
        Sine: ["Intercept", "Slope", "Wave1 Period", "Wave1 Phaseshift", "Wave1 Amplitude", "Wave2 Period", "Wave2 Phaseshift", "Wave2 Amplitude",],
        SineInit: ["Intercept", "Slope", "Wave1 Period", "Wave1 Phaseshift", "Wave1 Amplitude", "Wave2 Period", "Wave2 Phaseshift", "Wave2 Amplitude",],
        Curve: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        FFT: ['Peak']
    }


    const Valuesreset = () => {
        setData([])
        setChartData([])
        setChartDataUser([])
        setSelSchedule(null)

        setKeyParams(keyparaminit)
        setTrendParams(trendparaminit)
    }

    useEffect(() => {
        if (keyparams.refresh >= 1000) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        if (id) {
            kpis.forEach((kpi: KPI) => {
                if (kpi.Key === id) {
                    setSelKPI(kpi)
                    setSelFitModel(null)
                    setSelSchedule(null)
                }
            })
        }
        if (kpis.length > 0 && !selKPI) {
            setKeyParams({ ...keyparams, highlight: true })
        }
    }, [kpis])

    useEffect(() => {
        ImportAndSetStateGeneric(setKPIs, "", schema, paths.kpi.kpiview, user, config, { full: true })
        ImportAndSetStateGeneric(setFields, "", schema, paths.fields.fieldretrieve, user, config, {})
        setRandColors([1, 2, 3, 4, 5].map(_ => { return GetRandomHexadecimal(6) }))
    }, [])


    useEffect(() => {
        if (selKPI) {
            console.log(selKPI)
            setLinkString("/graph/" + selKPI.ModelName)

            setKeyParams({ ...keyparams, highlight: false })
            Valuesreset()

            GenericBackendCall("", schema, paths.query.queryview, user, config, { querykeys: [selKPI.ModelName], type: "base", permissions: ['manage', 'read/write', 'creator'] }).then(d => {

                if (d.length === 1) {
                    let qry: QueryModel = d[0]
                    setQuery(qry)
                }
            })

            GenericBackendCall('', schema, paths.query.metricview, user, config, { metrickeys: [selKPI.MetricKey] }).then(d => {
                if (d.length === 1) {
                    let mdata = d[0]
                    let fmt = CheckMetricFormat(mdata)
                    setFmtParams({ ...fmtParams, rounding: fmt.rounding, format: fmt.format })
                }
            })


            let u1 = ConvertArraytoObject(GetUniquesFromObjectAttribute(selKPI.kpiflts, "FilterValue1"), ["Key", "Count"])
            let u2 = ConvertArraytoObject(GetUniquesFromObjectAttribute(selKPI.kpiflts, "FilterValue2"), ["Key", "Count"])
            setUniqueVals({ '0': u1, '1': u2 })

            GenericBackendCall("", schema, paths.answers.fitmodelview, user, config, { KPI: selKPI.Key }).then(d => {
                setFitModels(d)
            })
        }
    }, [selKPI])

    useEffect(() => {
        if (query) {
            GenericBackendCall("", schema, paths.schedule.scheduleview, user, config, { schedkeys: query.Schedules }).then(d => {
                setSchedules(d)
                setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
            })
        }
    }, [query])

    useEffect(() => {
        if (selKPI && selSchedule) {
            selKPI.kpiflts.forEach((kf: KPIFilter) => {
                if (kf.FilterValue1 === filterkeys[0] && kf.FilterValue2 === filterkeys[1]) {
                    setData(ValueMap(kf.values, selSchedule.ScheduleKey))
                }
            })
        }
    }, [filterkeys, selKPI])


    useEffect(() => {
        //slice number of datapoints
        if (data.length > 0) {
            let chtdata = data.slice(-1 * keyparams.lastN)
            setChartData(chtdata)
            setTrendParams({ ...trendparams, begindate: chtdata[0].AsofDate })
        }
    }, [keyparams.lastN])

    useEffect(() => {
        if (data.length > 0 && selSchedule) {
            let chtdata: tdata[] = []
            chartdata.forEach((ct: tdata) => {
                if (ct.id >= 0) {
                    chtdata.push(ct)
                }
            })
            let hist = CalculateDateSequence(trendparams.asofdate, selSchedule.Period, keyparams.npred + 1, selSchedule.IPeriod, true)
            let a: number = 1

            hist.forEach((ndates: Date[], i: number) => {
                if (ndates[1] > DateAdd(trendparams.asofdate, 1) && a <= keyparams.npred) {
                    a += 1
                    chtdata.push({ id: -1, AsofDate: ndates[1], Value: 'X', Grouping: "X", Curve: 0, Sine: 0, SineInit: 0, Poly: 0, FFT: 0 })
                }
            })
            setChartData(chtdata)
            setTrendParams({ ...trendparams, begindate: chtdata[0].AsofDate })
        }
    }, [keyparams.npred])

    useEffect(() => {
        if (keyparams.comparefld !== "") {

            let vars = GetObjectValue(variables, keyparams.usermodelstring)
            let tvars = GetObjectValue(vars, keyparams.comparefld)
            setTempInputs(tvars)

            let warns: boolean[] = []
            tvars.forEach((_: any) => {
                warns.push(false)
            })
            setWarn(warns)

            setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
        }
    }, [keyparams.comparefld, keyparams.usermodelstring])

    useEffect(() => {
        if (selFitModel && selSchedule) {
            GenericBackendCall(selFitModel.ModelKey, schema, paths.answers.fitmodelgenerate, user, config, { schedulekey: selSchedule.ScheduleKey }).then(d => {
                console.log("fitmodelgenerate")
                let mdata = SerializeDataFrame(d)
                let type = selFitModel.Type
                //let model: any = SerializeDataFrame(d[0])
                console.log(mdata)
                let newchartdata: tdata[] = []
                chartdata.forEach((cd: tdata) => {
                    mdata.forEach((md: any) => {
                        if (DateMatch(cd.AsofDate, DateRemoveTimestamp(DateAdd(new Date(md.AsofDate), 1)))) {
                            newchartdata.push({ ...cd, [type]: md.EstValue })
                        }
                    })
                })
                setChartData(newchartdata)

                let fparams = selFitModel.params.map((ft: FitParam) => { return ft.Value })

                AttachVariables({ [type]: fparams }, "Model")
                setKeyParams({ ...keyparams, comparefld: type, generated: true, refresh: keyparams.refresh + 1 })

            })
        }
    }, [selFitModel])

    useEffect(() => {
        if (selKPI && selSchedule) {
            let sorteddata = ValueMap(selKPI.values, selSchedule.ScheduleKey)
            setData(sorteddata)

            let sortedchartdata = sorteddata.slice(-1 * keyparams.lastN)
            if (sortedchartdata.length > 0) {
                setTrendParams({ ...trendparams, asofdate: sortedchartdata[sorteddata.length - 1].AsofDate, begindate: sortedchartdata[0].AsofDate })
            }
            setChartData(sortedchartdata)
            setChartDataUser(sortedchartdata)
        }
    }, [selSchedule])

    useEffect(() => {
        MakeChart()
    }, [data, keyparams.refresh])


    const ResetTrendValues = (values: any) => {
        let newchartdata: tdata[] = chartdataUser.map((d: tdata, i: number) => {
            let newd = { ...d }
            Object.keys(values).map((k: string) => {
                newd = { ...newd, [k]: values[k][i] }
            })
            return newd
        })

        setChartDataUser(newchartdata)
        setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 })
    }

    const AttachVariables = (datavars: any, type: string) => {
        let allvars: any = GetObjectValue(variables, type)
        let newvars = { ...allvars }
        Object.keys(datavars).map((k: string) => {
            newvars[k] = datavars[k]
        })
        setVariables({ ...variables, [type]: newvars })

    }
    //-----------------
    const ClickDelete = () => {
        if (selKPI) {
            GenericBackendCall(selKPI.Key, schema, paths.generics.genericdelete, user, config, { model: "KPI", field: "Key" }, "kpim_divdel").then(_ => {
                setKeyParams({ ...keyparams, refresh: 999 })
            })
        }
    }

    const ClickSave = () => {
        if (selKPI) {
            let usefld = keyparams.comparefld;
            if (GetObjectValue(translation_isinit, usefld)) {
                usefld = GetObjectValue(translation, usefld)
            }
            let asofdate = new Date(chartdata[chartdata.length - 1].AsofDate)
            let begindate = new Date(chartdata[0].AsofDate)
            let enddate = new Date(chartdata[chartdata.length - 1].AsofDate)

            let params = GetObjectValue(GetObjectValue(variables, keyparams.usermodelstring), usefld);
            GenericBackendCall("", schema, paths.answers.fitmodelpost, user, config,
                {
                    ...trendparams, kpikey: selKPI.Key, params: params, type: usefld, preprocess: trendparams.preprocess,
                    asofdate: asofdate, begindate: begindate, enddate: enddate
                }, "kpim_divsave").then(d => {
                    console.log("fitmodelpost")
                    console.log(d)
                    setKeyParams({ ...keyparams, refresh: 999 })
                })
        }
    }

    const ClickAnalyze = () => {
        if (selKPI && selSchedule) {
            console.log(trendparams)
            GenericBackendCall(selKPI.Key, schema, paths.answers.default, user, config,
                { ...trendparams, kpikey: selKPI.Key, type: "Trend", preprocess: trendparams.preprocess, schedulekey: selSchedule.ScheduleKey }).then(d => {
                    if (d.length === 2) {
                        console.log("answers.default")
                        console.log(d)
                        setChartData(SerializeDataFrame(d[0]))
                        AttachVariables(d[1], "Model")
                        setKeyParams({ ...keyparams, comparefld: 'Sine', generated: true, refresh: keyparams.refresh + 1 })
                    }
                })
        }
    };

    const ClickRegen = () => {
        if (selKPI && keyparams.comparefld !== "") {
            let coredata = chartdata.map((d: tdata) => {
                return [d.AsofDate, GetObjectValue(d, keyparams.comparefld)]
            })

            let vars: number[] = tempInputs.map((tval: any, i: number) => {
                if (!warn[i]) {
                    return +tval
                } else {
                    return 0
                }
            })
            let usefld = keyparams.comparefld
            if (GetObjectValue(translation_isinit, usefld)) {
                usefld = GetObjectValue(translation, usefld)
            }

            let columns = ["AsofDate", "Value"]
            GenericBackendCall(selKPI.Key, schema, paths.answers.analysis, user, config,
                {
                    ...trendparams, kpikey: selKPI.Key, type: "Trend", subtype: keyparams.comparefld, coredata: coredata,
                    NCols: '2', params: vars, columns: columns, period: trendparams.period_est
                }).then(d => {
                    console.log("answers.analysis")
                    console.log(d)
                    AttachVariables(d[1], "User")
                    ResetTrendValues(d[0])
                    setKeyParams({ ...keyparams, usermodel: true, usermodelstring: "User", refresh: keyparams.refresh + 1 })
                })
        }
    };


    const MakeChart = () => {
        let yfields = ['Value']
        let usedata = chartdata
        if (keyparams.comparefld != "") { //secondary data line
            yfields.push(keyparams.comparefld)
            if (keyparams.usermodel) {
                usedata = chartdataUser
            }
        }

        ChartSample("Line", "#kpigraph1x",
            usedata, [],
            ["AsofDate", yfields, ""], ["AsofDate", selKPI?.KPIName],
            "Continuous", randcolors,
            [800, 600], "On", false,
            { format: fmtParams.format, rounding: fmtParams.rounding, clr: "white", lineclr: "white", secondarylineclr: "blue" }, true
        )
    }

    const ChangeFilter = (e: ChangeEvent<HTMLSelectElement>, i: number) => {
        let fltval = e.target.selectedOptions[0].attributes[1].value
        if (i == 0) {
            setFilterKeys([fltval, filterkeys[1]])
        } else {
            setFilterKeys([filterkeys[0], fltval])
        }
    }


    //-----------
    const KPIViewValues = () => {
        return (
            <div>
                <div className="ndt_title4">Values</div>
                <div style={{ height: '310px', fontSize: '14px' }}>
                    <div className="kpim_valitm">
                        <div>Date</div>
                        <div>Value</div>
                        <div>{keyparams.comparefld}</div>
                        <div>Difference</div>
                    </div>
                    {!keyparams.usermodel ?
                        <div style={{ height: "300px", overflowY: "scroll" }}>
                            {chartdata.map((v: tdata, i: number) => {
                                return (<div key={i} className="kpim_valitm">
                                    <div>{DateString(v.AsofDate)}</div>
                                    <div>{FormatLabel(v.Value, fmtParams)}</div>
                                    <div>{FormatLabel(GetObjectValue(v, keyparams.comparefld), fmtParams)}</div>
                                    <div>{FormatLabel(v.Value - GetObjectValue(v, keyparams.comparefld), fmtParams)}</div>
                                </div>)
                            })}
                        </div>
                        : <div style={{ height: "300px", overflowY: "scroll" }}>
                            {chartdataUser.map((v: tdata, i: number) => {
                                return (<div key={i} className="kpim_valitm">
                                    <div>{DateString(v.AsofDate)}</div>
                                    <div>{FormatLabel(v.Value, fmtParams)}</div>
                                    <div>{FormatLabel(GetObjectValue(v, keyparams.comparefld), fmtParams)}</div>
                                    <div>{FormatLabel(v.Value - GetObjectValue(v, keyparams.comparefld), fmtParams)}</div>
                                </div>)
                            })}
                        </div>}
                </div>
            </div >
        )
    }

    const ChangePreprocess = (val: string) => {
        if (val === "Yes") {
            setTrendParams({ ...trendparams, preprocess: true })
        } else if (val === "No") {
            setTrendParams({ ...trendparams, preprocess: false })
        }

    }

    const periodmemo = useMemo(() => {
        return (
            <input type="number" min={0} max={100} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setTrendParams({ ...trendparams, period_est: +e.target.value })
            }} />)
    }, [trendparams.period_est])

    const KPIViewInitialize = () => {
        return (
            <div>
                <div style={{ fontSize: "20px" }}>Initialize</div>
                <button className="ndt_btn1" style={keyparams.useexistingmodel ? styleOff : styleOn} onClick={_ => { setKeyParams({ ...keyparams, useexistingmodel: false }); setSelFitModel(null) }}>Create New</button>
                <button className="ndt_btn1" style={keyparams.useexistingmodel ? styleOn : styleOff} onClick={_ => { setKeyParams({ ...keyparams, useexistingmodel: true }) }}>Use Existing</button>

                {!keyparams.useexistingmodel ? <div>
                    <div style={{ marginTop: "10px", marginBottom: "10px", display: "flex" }}>

                        <div style={{ marginRight: "5px" }}>Period:</div>
                        {periodmemo}
                        <div style={{ marginLeft: "10px" }}>{trendparams.period_est}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { ChangePreprocess(e.target.selectedOptions[0].attributes[0].value) }}>
                            <option value="">Use Preprocessing?</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        <div style={{ marginLeft: "10px" }}>{trendparams.preprocess ? "Yes" : "No"}</div>
                    </div>
                    <button id="kpim_analyzediv" className="ndt_btn3" onClick={_ => { ClickAnalyze() }}>Analyze Trend</button>
                </div> : <div style={{}}>
                    <div>Created Models:</div>
                    <GenericDropdown
                        data={fitmodels}
                        keycol="ModelKey"
                        namecol="ModelName"
                        promptstring="Select Model"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, fitmodels, setSelFitModel, "", null) }}
                    />
                    <div>{selFitModel?.ModelName}</div>
                </div>}
            </div>
        )
    }

    const KPIViewShow = () => {
        return (
            <div>
                {["Poly", "Sine", "Curve"].map((strx: string, i: number) => {
                    let usestyle = { ...styleOff }
                    if (strx === keyparams.comparefld) {
                        usestyle = { ...styleOn }
                    }
                    if (chartdata.length > 0) {
                        if (GetObjectValue(chartdata[0], strx, true)) {
                            usestyle.opacity = "1"
                        } else {
                            usestyle.opacity = ".5"
                        }
                    }
                    return (<div key={i} className="kpim_showval" style={usestyle} onClick={_ => {
                        if (usestyle.opacity === "1") {
                            setKeyParams({ ...keyparams, comparefld: strx, refresh: keyparams.refresh + 1 })
                        }
                    }}>{strx}</div>)
                })}
                <div style={{ marginLeft: "15px" }}>
                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, showinit: !keyparams.showinit, comparefld: GetObjectValue(translation, keyparams.comparefld) }) }}
                        style={keyparams.showinit ? styleOff : styleOn}
                    >Show Initial</button>
                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, usermodel: !keyparams.usermodel, usermodelstring: GetUserModelString(!keyparams.usermodel), refresh: keyparams.refresh + 1 }) }}
                        style={keyparams.usermodel ? styleOn : styleOff}
                    >Show User Created</button>
                </div>
            </div>
        )
    }


    const InputParams = () => {
        let mdlusr = GetObjectValue(variables, keyparams.usermodelstring)
        let vals = GetObjectValue(mdlusr, keyparams.comparefld)

        if (vals) {
            return (
                <div>
                    {vals.map((tvar: number, i: number) => {
                        return (<div key={i} style={{ display: "flex", overflow: "hidden" }}>
                            <div className="kpim_inputtag" style={warn[i] ? styleWarn : styleOk}>{varnames[keyparams.comparefld][i]}:</div>
                            <div style={{ width: "170px" }}>
                                <input type="text" className="kpim_inputval" onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    let newvars = tempInputs
                                    newvars[i] = +e.target.value
                                    if (newvars[i]) {
                                        setTempInputs(newvars)
                                        let newwarn = warn
                                        newwarn[i] = false
                                        setWarn(newwarn)
                                    } else {
                                        let newwarn = warn
                                        newwarn[i] = true
                                        setWarn(newwarn)
                                    }
                                }}></input>
                                {RoundValue(tvar, 3)}</div>
                        </div>)
                    })}
                </div>
            )
        }
        else {
            return (<div>None Generated</div>)
        }
    }
    const inputParamMemo = useMemo(() => { return <InputParams /> }, [variables, warn, keyparams.comparefld, keyparams.usermodelstring])

    const ValueParams = () => {
        let mdlusr = GetObjectValue(variables, keyparams.usermodelstring)
        let vals = GetObjectValue(mdlusr, keyparams.comparefld)
        return (<div style={{ backgroundColor: "rgb(70,70,40)", padding: "3px" }}>
            <div style={{ display: "grid", gridTemplateColumns: '120px 120px 120px' }}>
                {vals.map((tvar: number, i: number) => {
                    return (<div key={i}>{RoundValue(tvar, 3)}</div>)
                })}
            </div>
        </div>)
    }

    const GetUserModelString = (val: boolean) => {
        if (val) {
            return "User"
        } else {
            return "Model"
        }
    }

    const KPIViewDetail = () => {
        return (<div style={{ background: "rgb(15,15,15)", marginTop: "20px" }}>
            <div style={{ fontSize: "18px", letterSpacing: ".1em", margin: "3px" }}>Model: {keyparams.comparefld}</div>

            {inputParamMemo}
            <button className="ndt_btn1" onClick={_ => { ClickRegen() }}>Regenerate</button>
            <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, usermodel: false }) }}>Reset</button>
        </div>)
    }
    const KPIViewSave = () => {
        return (<div style={{ background: "rgb(15,15,15)", marginTop: "20px" }}>
            <div style={{ fontSize: "18px", letterSpacing: ".1em", }}>Save</div>
            <div>{keyparams.comparefld} Params:</div>
            <ValueParams />
            <br />
            <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}>Save this Model</button>
        </div>)

    }


    return (<div className="ndt_canvas">
        <div className="ndt_header ndt_gridbox">
            <div className="ndt_title2">KPI Manager</div>
            <div style={{ marginBottom: "5px", display: "flex" }}>
                <StyleMainSelect base={<GenericDropdown
                    data={kpis}
                    keycol="Key"
                    namecol="KPIName"
                    className="ndt_dropdown"
                    promptstring="Select Existing KPI"
                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, kpis, setSelKPI, "", null) }}
                    divID="kpim_seldiv"
                />}
                    delete={
                        <button style={{ marginLeft: "10px" }} className="ndt_btn2" onClick={_ => { setKeyParams({ ...keyparams, isdelvisible: true }) }}>Delete</button>
                    }
                    deleteCondition={selKPI}
                    highlight={keyparams.highlight}
                />

                {selKPI ?
                    <div style={{ display: "flex" }}>

                        <div style={{ margin: '4px', fontSize: "18px" }}><Link to={linkstring}>View Query Model</Link></div>
                    </div>
                    : <div></div>}


            </div>

            {selKPI ?
                <div>
                    <GenericDropdown
                        data={schedules}
                        keycol="ScheduleKey"
                        namecol="ScheduleName"
                        className="ndt_dropdown"
                        promptstring="Select Schedule"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, schedules, setSelSchedule, "", null) }}
                        divID="kpim_selsched"
                    />
                </div>
                : <div></div>}
        </div>
        {kpis.length === 0 ? <div style={{ margin: "100px" }}>
            <div>KPIs must be created from an existing query in the Inspector module</div>
            <Link to={"/query/managegraph"}>View Queries Here</Link>
        </div> : <div></div>}

        {
            selKPI && selSchedule ?
                <div className="ndt_gridbox">
                    <div className="ndt_title4">KPI: {selKPI.KPIName}</div>
                    <div style={{ display: "flex", height: "30px" }}>
                        <div style={{ marginRight: "5px", marginTop: "4px" }}>Label Decimal Places:</div>
                        <input type="number" min={0} max={4} onChange={(e: ChangeEvent<HTMLInputElement>) => { setFmtParams({ ...fmtParams, rounding: +e.target.value }) }} />
                        <div style={{ marginLeft: "35px", marginRight: "5px", marginTop: "4px" }}>Datapoints (Last N):</div>
                        <input type="number" min={5} max={100} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, lastN: +e.target.value }) }} />
                        <div style={{ marginLeft: "35px", marginRight: "5px", marginTop: "4px" }}>Predicted (Next N):</div>
                        <input type="number" min={0} max={10} onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, npred: +e.target.value }) }} />

                        <div style={{ display: "flex", marginLeft: "10px" }}>
                            <div style={{}}>Filter by Field: </div>
                            {query && query.filterfields.length > 0 ? <div>
                                {query.filterfields.map((ff: FilterField, i: number) => {
                                    return (
                                        <div key={i}>
                                            <GenericDropdown
                                                data={uniquevals[String(i)]}
                                                keycol="Key"
                                                namecol="Key"
                                                promptstring={"Filter " + GetFieldName(fields, ff.FieldName)}
                                                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeFilter(e, i) }}
                                            />
                                        </div>
                                    )
                                })}
                            </div> : <div>none available</div>}
                        </div>

                        <button style={{ marginLeft: "10px" }} className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 }) }}>Refresh</button>
                    </div>
                    <div id="kpimgr_main">
                        <div className="ndt_innerbox" style={{ minWidth: "350px" }}>
                            <div className="ndt_innerbox">
                                <button id="kpim_optinit" className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, view: "initialize" }) }} >Initialize</button>
                                <button className="ndt_btn1" style={keyparams.generated ? {} : { opacity: "50%" }} onClick={_ => { if (keyparams.generated) { setKeyParams({ ...keyparams, view: "generate" }) } }} >Generate</button>
                                <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, view: "values" }) }} >Values</button>
                                <button className="ndt_btn1" style={keyparams.generated ? {} : { opacity: "50%" }} onClick={_ => { if (keyparams.generated) { setKeyParams({ ...keyparams, view: "save" }) } }} >Save</button>
                            </div>
                            <div className="ndt_subinner">
                                {keyparams.generated ? <KPIViewShow /> : <div></div>}
                                {(() => {
                                    switch (keyparams.view) {
                                        case "initialize": return <KPIViewInitialize />
                                        case "generate": return <KPIViewDetail />
                                        case "values": return <KPIViewValues />
                                        case "save": return <KPIViewSave />
                                        default: return <div></div>;
                                    }
                                })()}

                            </div>
                        </div>

                        <div>
                            <div style={{ width: "50%" }}>
                                <div className="ndt_title4" style={{ marginLeft: "15px" }}>KPI Inspection:</div>

                            </div>
                            <div id="kpigraph1x" style={{ backgroundColor: "grey", width: "850px", height: "600px" }}>
                            </div>
                        </div>
                    </div>
                </div> : <div></div>
        }

        <Modal show={keyparams.issavevisible}>
            <div className="ndt_modal">
                <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => { setTrendParams({ ...trendparams, modelname: e.target.value }) }} />
                <div>From Date: {DateString(trendparams.begindate)}</div>
                <div>To Date: {DateString(trendparams.asofdate)}</div>

                <button className="ndt_btn1" onClick={_ => { ClickSave() }}>Save</button>
                <div id="kpim_divsave"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdelvisible}>
            <div className="ndt_modal">
                <div>Delete KPI {selKPI?.KPIName}?</div>
                <button className="ndt_btn2" onClick={_ => { ClickDelete() }}>Delete</button>
                <div id="kpim_divdel"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

    </div >)
};
export default KPIManager

const ValueMap = (values: KPIValue[], schedfilter: string = "") => {

    let data: any = []
    values.forEach((sk: KPIValue, i: number) => {
        if (schedfilter === "") {
            data.push({ id: i, AsofDate: sk.AsofDate, Value: sk.Value, Trend: 0, Grouping: '' })
        } else {
            let sch = ConvertBackendArryString(sk.Schedules)
            if (IsInVector(sch, schedfilter)) {
                data.push({ id: i, AsofDate: sk.AsofDate, Value: sk.Value, Trend: 0, Grouping: '' })
            }
        }
    })

    return ObjectSort(data, "AsofDate", false)
}