


import AxiosUpload from '../AxiosUpload';
import { queryparams, ChartSeries } from '../../type';
import DatatoFormArray from '../../functions/Import/DatatoFormArray';

const QueryVisualsUpload = async (params: queryparams, existingmodelkey: string,
    schemakey: string, config: any, dispatcher: any, elementstring: string) => {

    //console.log(params);

    let arrycolors = Object.keys(params.colors).map((mp: any) => {
        return [
            String(mp).replace(",", `${process.env.REACT_APP_DATA_ENCODE_STR}`),
            String(params.colors[mp][0]),
            String(params.colors[mp][1])
        ]
    });

    let dataArray = new FormData();

    dataArray.append("schemakey", schemakey);
    dataArray.append("modelkey", existingmodelkey);
    dataArray.append("visualskey", params.visualskey);
    dataArray.append("visualsname", params.visualsname);
    dataArray.append("chartType", params.chartType);
    dataArray.append("chartTitle", params.charttitle);


    dataArray.append("colors", String(arrycolors));
    dataArray.append("labelsonoff", params.labelsonoff);
    dataArray.append("labelcolor", params.labelcolor);
    dataArray.append("labeltype", params.labeltype);

    dataArray.append("colorGradientType", params.colorGradientType);
    dataArray.append("isprimary", String(params.isprimary));
    dataArray.append("colormodelkey", String(params.colorModelKey));

    dataArray.append("defaultfilterflds", String(params.defaultfilterflds));
    dataArray.append("defaultfiltervals", String(params.defaultfiltervals));

    //dataArray.append("showtable", String(params.showtable)); //for models that are being updated

    dataArray.append("showdefault", params.showdefault)

    let elem = document.getElementById(elementstring)
    if (elem) { elem.innerHTML = "Uploading..." }

    //axios call
    let vispromise = AxiosUpload(dataArray, "api/query/queryvisualspost", config, elem, "")

    vispromise.then((d: any) => {
        // //each chartseries
        console.log(params.chartseries)
        params.chartseries.forEach((cs: ChartSeries, i: number) => {
            let dataSubArray = DatatoFormArray(cs);
            dataSubArray.append("vismodelkey", d.data.VisKey)
            dataSubArray.append("metricorder", String(i))

            AxiosUpload(dataSubArray, "api/query/chartseriespost", config, elem, "")

        });
    })


    return ("Success")

};
export default QueryVisualsUpload;