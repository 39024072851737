import RunDateImport from "../../data/Query/RunDate_Import";
import { QueryModel, QueryRunDate } from "../../type";


const RunDateUpdate = async (rundatekey: string, schema: string, query: QueryModel, setquery: any, config: any) => {
    //adds basicquery to query model
    if (rundatekey !== "") {
        await RunDateImport(rundatekey, schema, config).then(d => {
            let newdates = query.rundates.map((rd: QueryRunDate) => {
                if (rd.DateKey === rundatekey) {
                    return d[0]
                } else {
                    return { ...rd, basicquery: [] }
                }
            })
            setquery({ ...query, rundates: newdates })
        })
    }

};
export default RunDateUpdate;
