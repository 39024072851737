import { useEffect, useState, useContext } from 'react'
import CreateChart from '../../../charts/CreateChart';
import { QueryModel, FieldMap, QueryVisuals, queryvisualstate, Metric, ColorModel, BasicQuery, QueryRunDate, Benchmarkset, BenchModel } from '../../../type';
import { QueryVisualsInit, queryvisInit } from '../../../InitTypes';
import { StartupContext } from '../../../App';
import GetQueryRunDateKey from '../../../functions/QueryFunctions/GetQueryRunDateKey';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import GetObjectValue from '../../../functions/Object Functions/GetObjectValue';
import DateString from '../../../functions/Date Functions/DateString';
import "./css/QueryModelView3D.css"
import DateRemoveTimestamp from '../../../functions/Date Functions/DateRemoveTimestamp';
import GetFieldName from '../../../functions/GetFieldName';
import QueryVisualsPrepare from '../../../functions/QueryFunctions/QueryVisualsPrepare';

const QueryModelView3D = (props: any) => {

    let query: QueryModel = props.query
    let setQuery: any = props.setQuery
    let fields: FieldMap[] = props.fields
    let metrics: Metric[] = props.metrics

    const context = useContext(StartupContext)
    const { config, schema, user, paths, appcolors } = useContext(StartupContext)
    const [qryvis, setQryvis] = useState<QueryVisuals>(QueryVisualsInit[0])
    const [keyparams, setKeyParams] = useState<any>({
        rundatekey: GetQueryRunDateKey(qryvis, query.rundates, null), selvalue: "", colors: [], simpledatafilt: []
    });

    const [selData, setSelData] = useState<BasicQuery[]>([])
    const [vis_states, setVis_States] = useState<queryvisualstate[]>([{ ...queryvisInit[0], show: "chart3D" }])
    const [benchmodels, setBenchmodels] = useState<BenchModel[]>([])
    const [benchmarksets, setBenchmarksets] = useState<Benchmarkset[]>([])
    const [appliedColorModel, setAppliedColorModel] = useState<ColorModel | null>(null)
    const selStyle = { backgroundColor: 'rgb(93, 117, 194)', boxShadow: '0px 0px 7px yellow' }


    useEffect(() => {
        GenericBackendCall(query.ModelKey, schema, paths.query.queryvisualsview, user, config, { IsPrimary: 'true' }).then(d => {
            if (d.length === 1) {
                setQryvis(QueryVisualsPrepare(d[0], null))
            }
        })
    }, [])

    useEffect(() => {
        if (qryvis?.VisKey !== "") {
            CreateQueryChart()
        }
    }, [qryvis])



    const ConvertParamColors = (paramcolors: any) => {
        let colors = Object.keys(paramcolors).map((c: any) => {
            return { ModelName: "", Color: paramcolors[c][0], SecondaryColor: paramcolors[c][1] }
        })
        return colors
    }


    const CreateQueryChart = () => {
        let colors = ConvertParamColors(qryvis.colors)
        let simpledatafilt = CreateChart(query, { ...qryvis, ChartType: "chart3D", colors: qryvis.colors }, appliedColorModel,
            keyparams.rundatekey, fields, metrics, benchmodels, benchmarksets,
            "#qmvv_graph3d", [700, 700], "", vis_states, setVis_States, 0, context)
        setKeyParams({ ...keyparams, simpledatafilt: simpledatafilt })
    }

    const CheckVal = () => {
        let elem = document.getElementById("graphxxx")
        if (elem) {
            let selval = GetObjectValue(elem.attributes, "data-name").value
            setKeyParams({ ...keyparams, selvalue: selval })
            //let fullrow = query.rundates[]
        }
    }
    const View_AllData = () => {
        setSelData(keyparams.simpledatafilt)
    }
    const View_Detail = () => {

    }

    return (<div>
        <div className='ndt_title3' style={{ marginLeft: "20px" }}>3D Graph</div>
        <div className='ndt_gridbox' style={{ display: "flex", height: '765px' }}>
            <div style={{ width: '735px', height: '715px' }}>
                <div id="qmvv_chartbox" style={{ background: "black" }}>
                    <div id="qmvv_graph3d" style={{ margin: "5px", boxShadow: "0px 0px 10px white" }}></div>
                </div>
            </div>
            <div className='ndt_gridinner' style={{ width: '55%', minWidth: "400px" }}>
                <button className="ndt_btn3" style={{ margin: "3px" }} onClick={_ => { CheckVal() }}>Check Selected Value  </button>
                <div>
                    <div style={{ display: "flex", background: "rgb(0,0,0)", border: "1px solid grey" }}>
                        <div style={{ fontSize: '22px', marginLeft: "3px", paddingRight: "15px" }}>View:</div>

                        <button className="ndt_btn1" onClick={_ => { View_AllData() }}>All Data</button>
                        <button className="ndt_btn1" onClick={_ => { View_Detail() }}>Detail</button>
                        <button className="ndt_btn1" onClick={_ => { }}>Filter</button>
                    </div>
                    <a style={{ marginLeft: "5px" }}>{keyparams.selvalue}</a>
                    <div>

                        <div key={-1} className='qmv3d_ditm' style={{ fontWeight: "bold", padding: "3px", border: "3px solid white" }}>
                            <div className='qmv3d_dsubitm'>{GetFieldName(fields, query.XFieldName)}</div>
                            <div className='qmv3d_dsubitm'>{GetFieldName(fields, query.GroupFieldName)}</div>
                            <div className='qmv3d_dsubitm'>Value</div>
                        </div>
                        <div style={{ height: '500px', overflowY: "scroll" }}>

                            {selData.map((br: BasicQuery, i: number) => {
                                let ustyle = {}
                                if (String(br.id) === String(keyparams.selvalue)) { ustyle = selStyle }
                                return (<div key={i} className='qmv3d_ditm' style={ustyle}>
                                    <div className='qmv3d_dsubitm'>{DateString(DateRemoveTimestamp(br.Variable))}</div>
                                    <div className='qmv3d_dsubitm'>{br.Grouping}</div>
                                    <div className='qmv3d_dsubitm'>{br.ValueCalc}</div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
};

export default QueryModelView3D