import { FieldMap } from "../type"
import GetObjectValue from "./Object Functions/GetObjectValue"

const GetFieldName = (fields: FieldMap[], lookupVal: string, lookupField = "Key", returnfield = "FieldName") => {
    //ref: rulesetsmanager, modeltutorial, querymodelviewvisual, customtable, tablemgr, valuetracker, 
    //dashboardmanager, dashboardview, benchmarkmanager, genericdatatable

    let val: string = ""

    function GetReturnFld(fld: FieldMap) {
        let val1: string = ""

        val1 = GetObjectValue(fld, returnfield)
        return (val1)
    }

    fields.forEach(fld => {

        if (GetObjectValue(fld, lookupField) === lookupVal) {
            val = GetReturnFld(fld);
        }

    })

    return (val)
}
export default GetFieldName