import TreeLayout from "../../../charts/TreeLayout";
import "./css/DeptViewer.css"

const DeptTreeViewer = ({data}:any) => {

    return(
        <div id="treediv" >
           <TreeLayout data={data} />
        </div>
    )
};
export default DeptTreeViewer;