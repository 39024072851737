import { ChangeEvent, useContext, useEffect, useState, useMemo } from "react";
import { StartupContext } from "../../../../App";
import ImportAndSetStateGeneric from "../../../../functions/Import/ImportAndSetStateGeneric";
import { KPI, KPIString, Dashboard, UserPermssion } from "../../../../type";
import GenericDropdown from "../../../../functions/Dropdown_Generic";
import ChangeSelectorbyIndex from "../../../../functions/ElementSelect/ChangeSelectorbyIndex";
import { Modal } from "react-bootstrap";
import GenericBackendCall from "../../../../functions/Import/GenericBackendCall";
import { KPIInit, KPIStringInit } from "../../../../InitTypes";
import ConvertKPIString, { EncodeString } from "../../../../functions/QueryFunctions/kpistrings/ConvertKPIString";
import ChangeParamSelectorbyIndex from "../../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import StringBeforeDelimeter from "../../../../functions/String Functions/StringBeforeDelimeter";
import StringAfterDelimeter from "../../../../functions/String Functions/StringAfterDelimeter";
import ParseKPIString from "../../../../functions/QueryFunctions/kpistrings/ParseKPIString";
import GetUserPermission from "../../../../functions/QueryFunctions/GetUserPermission";
import GetUniquesFromArrayMultiField from "../../../../functions/Array Functions/GetUniquesfromArrayMultiField";
import { UniqueArrayStringValues } from "../../../../functions/UniqueArrayValues";
import FilterDataMultiple from "../../../../functions/Filter Functions/FilterDataMultiple";

const KPIStringManager = () => {

    const { config, schema, user, paths, clickstyle } = useContext(StartupContext)
    // interface knf {
    //     Key: string; Name: string; Format: string; EncodedKey: string;
    // }
    let kn: KPI = { ...KPIInit[0], KPIName: "Set KPI" }

    interface paramstype {
        textcount: number; clickelemno: number; isselvisible: boolean; issavevisible: boolean; isdelvisible: boolean;
        workingkpi: KPI; errormsg: string;
        formatsample: string; convert: boolean; dashboardkey: string; refresh: number; nrows: number; formatweight: string;
    }
    interface fltparamstype {
        filterfields: any[]; filter: boolean; encodedfltkey: string; kpifilterval1: string; kpifilterval2: string; warning: string;
    }
    let textareainit = { 0: '', 1: "", 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '' }
    let usekpiinit = { 0: kn, 1: kn, 2: kn, 3: kn, 4: kn, 5: kn, 6: kn, 7: kn, 8: kn, 9: kn }
    const [kpis, setKPIs] = useState<KPI[]>([])
    const [kpistrings, setKPIStrings] = useState<KPIString[]>([])
    const [selKPI, setSelKPI] = useState<KPI | null>(null)
    const [selFltOptions, setSelFltOptions] = useState<any>({ 0: [], 1: [] })
    const [selKPIString, setSelKPIString] = useState<KPIString | null>(null)
    const [textareas, setTextAreas] = useState<any>(textareainit)
    const [usekpi, setUseKPI] = useState<any>(usekpiinit)
    const [keyparams, setKeyParams] = useState<paramstype>({
        textcount: 1, clickelemno: 0, isselvisible: false, issavevisible: false, isdelvisible: false, workingkpi: kn, errormsg: "",
        formatsample: "", convert: false, dashboardkey: '', refresh: 0, nrows: 1, formatweight: 'None'
    })

    let fltparamsinit = { filterfields: [], filter: false, encodedfltkey: "", kpifilterval1: '', kpifilterval2: '', warning: '' }
    const [fltParams, setFltParams] = useState<fltparamstype>(fltparamsinit)
    //const [bqrows, setBQRows] = useState<BasicQuery[]>([])
    const [finalval, setFinalVal] = useState<string>("")
    const [hiddenval, setHiddenVal] = useState<string>("")
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [userperm, setUserPerm] = useState<UserPermssion | null>(null)
    const [stringName, setStringName] = useState<string>("")


    // const [qryvisall, setQryvisAll] = useState<QueryVisuals[]>([])
    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])


    useEffect(() => {
        ImportAndSetStateGeneric(setKPIs, "", schema, paths.generics.genericfilterview, user, config, { model: "KPI" }, true)
        ImportAndSetStateGeneric(setKPIStrings, "", schema, paths.kpi.kpistringview, user, config, { permissions: ['creator', "manage", 'read/write'] })
        ImportAndSetStateGeneric(setDashboards, "", schema, paths.dashboard.dashnameview, user, config, { permissions: ['creator', "manage", 'read/write'] })
    }, [])

    //console.log(usekpi)

    useEffect(() => {
        if (keyparams.convert) {
            let mkeys: string[] = []
            Object.keys(usekpi).forEach((k: string) => {
                if (usekpi[k].Key !== "") {
                    mkeys.push(usekpi[k].Key)
                }
            })

            GenericBackendCall("", schema, paths.kpi.kpiview, user, config, { modelkeys: mkeys, full: true }, "", "", true).then(d => {
                let nstr = ConvertKPIString(hiddenval, d, null, false, false)
                if (typeof (nstr) === "string") {
                    setFinalVal(nstr)
                }
            })
            setKeyParams({ ...keyparams, convert: false })
        }
    }, [keyparams.convert])


    useEffect(() => {
        let newstring: string = ""
        let hiddenstring: string = ""
        Object.keys(textareas).map((k: string, i: number) => {
            if (textareas[k] !== "") {
                newstring = newstring + textareas[k] + "[" + usekpi[i].KPIName + "{" + usekpi[i].Note + "}]"
                hiddenstring = hiddenstring + textareas[k] + "[" + usekpi[i].Key + "<" + usekpi[i].FltNote + ">{" + usekpi[i].Note + "}]"
                hiddenstring = hiddenstring.replace("[<>{}]", "")
                newstring = newstring.replace("[Set KPI{}]", "")
            }
        })
        setFinalVal(newstring)
        setHiddenVal(hiddenstring)
    }, [textareas, usekpi, keyparams.refresh])



    useEffect(() => {
        if (selKPIString) {
            GetUserPermission(selKPIString, user, setUserPerm)

            let [txtstr, kpstr] = ParseKPIString(selKPIString.Text)

            let txtareas = AddToStrObject(textareainit, txtstr)
            let usekpis = AddObjToStrObject(usekpiinit, kpstr, kpis)

            setTextAreas(txtareas)
            setUseKPI(usekpis)
            setStringName(selKPIString.Name)
            setKeyParams({ ...keyparams, textcount: txtstr.length - 1, formatweight: selKPIString.FormatWeight, nrows: selKPIString.NRows })
        }
    }, [selKPIString])

    useEffect(() => {
        setSelKPI(null)
        setStringName("")
    }, [keyparams.clickelemno])


    ///--------------backend-------
    const ClickSave = () => {
        if (selKPIString) {
            let kpikeys: string[] = []
            Object.keys(usekpi).forEach((k: string) => {
                if (usekpi[k].Key !== "") {
                    kpikeys.push(usekpi[k].Key)
                }
            })
            GenericBackendCall(selKPIString?.Key, schema, paths.kpi.kpistringpost, user, config,
                {
                    kpistring: EncodeString(hiddenval), kpistringname: stringName,
                    dashboardkey: keyparams.dashboardkey, kpis: kpikeys, formatweight: keyparams.formatweight
                }, "kpism_divsave").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }
    const ClickDelete = () => {
        if (selKPIString) {
            GenericBackendCall(selKPIString?.Key, schema, paths.generics.genericdelete, user, config, { model: "KPIString", field: "Key" }, "kpism_divdel").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }

    const ClickKPIImport = () => {
        GenericBackendCall("", schema, paths.kpi.kpiview, user, config,
            { model: "KPI", full: true, modelkeys: [keyparams.workingkpi.Key] }).then(d => {
                if (d.length === 1) {
                    setSelKPI(d[0])

                    let uniques1 = GetUniquesFromArrayMultiField(d[0].kpiflts, ["FilterValue1"], true)
                    let uniques2 = GetUniquesFromArrayMultiField(d[0].kpiflts, ["FilterValue2"], true)

                    setSelFltOptions({ 0: uniques1, 1: uniques2 })
                    let fld1 = UniqueArrayStringValues(d[0].kpiflts, "FilterField1")
                    let fld2 = UniqueArrayStringValues(d[0].kpiflts, "FilterField2")
                    let flds = []
                    if (fld1[0] !== "null") {
                        flds.push(fld1)
                    }
                    if (fld2[0] !== "null") {
                        flds.push(fld2)
                    }
                    setFltParams({ ...fltParams, filterfields: flds })
                }
            })
    }

    const ChangeKPIFilt = (e: ChangeEvent<HTMLSelectElement>, i: number) => {
        let fltval = ""
        if (+e.target.selectedOptions[0].attributes[0].value > -1) {
            fltval = e.target.selectedOptions[0].attributes[2].value
        }
        setFltParams({ ...fltParams, ["kpifilterval" + String(i)]: fltval })
    }

    useEffect(() => {
        if (selKPI) {

            if (fltParams.kpifilterval1 !== "" || fltParams.kpifilterval2 !== "") {
                let fltrows = FilterDataMultiple(selKPI.kpiflts, ["FilterValue1", "FilterValue2"],
                    [fltParams.kpifilterval1, fltParams.kpifilterval2], "and", false, true)

                if (fltrows.length === 1) {
                    setFltParams({ ...fltParams, encodedfltkey: fltrows[0].EncodedFltKey, warning: "" })
                    setKeyParams({ ...keyparams, workingkpi: { ...keyparams.workingkpi, FltNote: fltrows[0].EncodedFltKey } })
                } else {
                    setFltParams({ ...fltParams, warning: "combination found nothing" })
                }

            } else {
                setFltParams({ ...fltParams, encodedfltkey: "", warning: "" })
            }
        }
    }, [fltParams.kpifilterval1, fltParams.kpifilterval2])

    //----------------


    const ChangeText = (val: string, k: any) => {
        textareas[k] = val.replaceAll("[", "")
        setTextAreas(textareas)
    }

    const ChangeUseKPI = (e: ChangeEvent<HTMLSelectElement>) => {
        let k = e.target.selectedOptions[0].attributes[1].value
        let selkpi: KPI = keyparams.workingkpi
        kpis.forEach((kpi: KPI) => {
            if (kpi.Key === k) { selkpi = kpi }
        })
        setKeyParams({
            ...keyparams, workingkpi: {
                ...keyparams.workingkpi, Key: k, KPIName: selkpi.KPIName,
                EncodedKey: selkpi.EncodedKey, MetricNo: selkpi.MetricNo
            }
        })


    }

    const ChangeUseKPIFormat = (format: string, fmtsample: string) => {
        setKeyParams({ ...keyparams, workingkpi: { ...keyparams.workingkpi, Note: format }, formatsample: fmtsample })
    }

    const CheckNewKPI = () => {
        //note could be pre-existing
        if (keyparams.workingkpi.Key !== "" && keyparams.workingkpi.Note.length === 4 &&
            (keyparams.workingkpi.Note.substring(0, 3) === "dec" || keyparams.workingkpi.Note.substring(0, 3) === "pct")) {
            setUseKPI({ ...usekpi, [keyparams.clickelemno]: keyparams.workingkpi })
            setKeyParams({ ...keyparams, errormsg: "", formatsample: "", workingkpi: kn })
            setFltParams(fltparamsinit)
        } else if (keyparams.workingkpi.Key === "") {
            setKeyParams({ ...keyparams, errormsg: "No KPI Set" })
        } else {
            setKeyParams({ ...keyparams, errormsg: "No Format Set" })
        }
    }


    const Areas = () => {
        return (
            <div >
                {Object.keys(textareas).map((k: string, i: number) => {
                    if (textareas[k] != "" || i <= keyparams.textcount) {
                        return (<div key={i} id={"ksm_txtarea" + String(i)}>
                            <textarea style={{ width: "700px", height: '40px' }}
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { ChangeText(e.target.value, k) }} />
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "500px" }}>Str: {textareas[k].substring(0, 250)}</div>
                                <div style={{ width: "100px" }}>KPI: {usekpi[i].KPIName}</div>
                                <button id={"ksm_divchg" + String(i)} onClick={_ => { setKeyParams({ ...keyparams, isselvisible: true, clickelemno: i }) }}>Change</button>
                            </div>
                        </div>)
                    }
                })}
            </div>
        )
    }

    let areasmemo = useMemo(() => { return Areas() }, [keyparams, textareas, usekpi])

    const SimpleParse = (fval: string) => {
        let linevals = fval.split("#nl#")
        if (linevals.length !== keyparams.nrows) {
            setKeyParams({ ...keyparams, nrows: linevals.length })
        }
        return (
            <div>{
                linevals.map((v: string, i: number) => {
                    return (<div key={i}>{v}</div>)
                })
            }</div>
        )
    }

    return (<div className="ndt_canvas">

        <div className="ndt_title2">
            KPI String Manager
        </div>

        <div className="ndt_griditem">
            <GenericDropdown
                data={kpistrings}
                keycol="Key"
                namecol="Name"
                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeSelectorbyIndex(e, kpistrings, setSelKPIString, "", KPIStringInit[0]) }}
                promptstring={"Select KPI String"}
                includeDefault={true}
                defaultstring="Create New"
                divID="ksm_seldiv"
                className="ndt_dropdown"
            />
            {selKPIString ?
                <div style={{ margin: "5px" }}>
                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, textcount: keyparams.textcount + 1 }) }}>Add Below</button>
                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, textcount: keyparams.textcount - 1 }) }}>Remove Last</button>
                </div>
                : <div>Select A KPI String or create a new one</div>}
            {selKPIString ?
                <div>
                    {areasmemo}
                    <div className="ndt_innerbox" style={{ minHeight: "200px", height: String(keyparams.nrows * 20 + 100) + "px" }}>
                        <div className="ndt_title3">KPI String</div>
                        <button id="ksim_refreshbtn" className="ndt_btn3" onClick={_ => { setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1 }) }}>Refresh</button>
                        <button className="ndt_btn3" style={{ width: "180px" }} onClick={_ => { setKeyParams({ ...keyparams, convert: true }) }}>Convert</button>
                        <div style={{ height: "120px", width: "900px" }}>{SimpleParse(finalval)}</div>
                    </div>
                    <button className="ndt_btn1" style={{ marginRight: "15px" }} onClick={_ => { setKeyParams({ ...keyparams, issavevisible: true }) }}>Save String</button>
                    <button className="ndt_btn2" onClick={_ => { setKeyParams({ ...keyparams, isdelvisible: true }) }}>Delete String</button>


                </div>
                : <div></div>}



            <Modal show={keyparams.isselvisible}>
                <div className="ndt_modal" style={{ padding: "10px" }}>
                    <div style={{ height: "350px" }}>
                        <div style={{ fontSize: "22px" }}>KPI:</div>
                        <div style={{ display: "flex" }}>
                            <GenericDropdown
                                data={kpis}
                                keycol="Key"
                                namecol="KPIName"
                                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeUseKPI(e) }}
                                promptstring={"Add KPI"}
                            />
                            <button className="ndt_btn1" onClick={_ => ClickKPIImport()}>Select</button>
                        </div>
                        <br />

                        {selKPI ?
                            <div>
                                <div>
                                    <div style={{ fontSize: "20px" }}>Filters</div>
                                    <div>
                                        <button className="ndt_btn1" style={fltParams.filter ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setFltParams({ ...fltParams, filter: !fltParams.filter }) }}>Use Filter</button>
                                    </div>
                                    {fltParams.filter ?
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                FieldFilter1
                                                <GenericDropdown
                                                    data={selFltOptions[0]}
                                                    keycol={"FilterValue1"}
                                                    namecol={"FilterValue1"}
                                                    promptstring="Select Opt Filter Value"
                                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeKPIFilt(e, 1) }}
                                                    includeDefault={true}
                                                    defaultstring={"(None)"}
                                                />
                                            </div>
                                            <div>
                                                FieldFilter2
                                                <GenericDropdown
                                                    data={selFltOptions[1]}
                                                    keycol={"FilterValue2"}
                                                    namecol={"FilterValue2"}
                                                    promptstring="Select Opt Filter Value"
                                                    change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeKPIFilt(e, 2) }}
                                                    includeDefault={true}
                                                    defaultstring={"(None)"}
                                                />
                                            </div>
                                        </div>
                                        : <div></div>}
                                    <div style={{ marginLeft: "auto" }}>{fltParams.warning}</div>
                                </div>

                                <br />
                                <div style={{ fontSize: "20px" }}>Format:</div>
                                <div style={{ display: "flex" }}>
                                    <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { ChangeUseKPIFormat(e.target.selectedOptions[0].attributes[0].value, "") }}>
                                        <option value="">Set Format:</option>
                                        <option value="pct2">Percent</option>
                                        <option value="dec2">Decimal</option>
                                    </select>
                                    {keyparams.workingkpi.Note.substring(0, 3) === "dec" ?
                                        <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { ChangeUseKPIFormat(e.target.selectedOptions[0].attributes[0].value, e.target.selectedOptions[0].attributes[1].value) }}>
                                            <option value="" data-sample="">Decimals:</option>
                                            <option value="dec0" data-sample="3">0</option>
                                            <option value="dec1" data-sample="3.1">1</option>
                                            <option value="dec2" data-sample="3.14">2</option>
                                            <option value="dec3" data-sample="3.141">3</option>
                                            <option value="dec4" data-sample="3.1415">4</option>
                                        </select>
                                        :
                                        <select onChange={(e: ChangeEvent<HTMLSelectElement>) => { ChangeUseKPIFormat(e.target.selectedOptions[0].attributes[0].value, e.target.selectedOptions[0].attributes[1].value) }}>
                                            <option value="" data-sample="">Decimals:</option>
                                            <option value="pct0" data-sample="3%">0</option>
                                            <option value="pct1" data-sample="3.1%">1</option>
                                            <option value="pct2" data-sample="3.14%">2</option>
                                            <option value="pct3" data-sample="3.141%">3</option>
                                            <option value="pct4" data-sample="3.1415%">4</option>
                                        </select>}
                                    <div >{keyparams.formatsample}</div>
                                    <button className="ndt_btn1" onClick={_ => { CheckNewKPI() }}>Submit</button>
                                </div>
                            </div>
                            : <div>Select KPI first</div>}

                        <div>{keyparams.errormsg}</div>
                    </div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isselvisible: false })}>Close</button>
                </div>
            </Modal>

            <Modal show={keyparams.issavevisible}>
                <div className="ndt_modal" >
                    <div style={{ fontSize: "20px" }}>Save this KPI String</div>
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <div>Name {stringName}</div>
                        <input type="text" value={stringName} onChange={e => { setStringName(e.target.value) }} />
                    </div>
                    <div>
                        Set to existing dashboard
                    </div>
                    <GenericDropdown
                        data={dashboards}
                        keycol="Key"
                        namecol="Name"
                        change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, dashboards, keyparams, setKeyParams, "dashboardkey", "Key", "") }}
                        promptstring="Select Dashboard"
                        includeDefault={true}
                        defaultstring="None"
                    />
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <div>Apply format to KPIs: {keyparams.formatweight}</div>

                        <select onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            if (selKPIString) {
                                setKeyParams({ ...keyparams, formatweight: e.target.selectedOptions[0].attributes[0].value })
                            }
                        }}>
                            <option value="none">None</option>
                            <option value="bold">Bold</option>
                            <option value="italics">Italics</option>
                        </select>
                    </div>

                    <button className="ndt_btn1" style={{ width: "200px" }} onClick={_ => { ClickSave() }}>Save</button>
                    <div id="kpism_divsave"></div>

                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>

            <Modal show={keyparams.isdelvisible}>
                <div className="ndt_modal">
                    <div>Delete this KPI String</div>
                    <button className="ndt_btn1" style={{ width: "200px", margin: "10px" }} onClick={_ => { ClickDelete() }}>Delete</button>
                    <div id="kpism_divdel"></div>
                    <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                </div>
            </Modal>

        </div >
    </div >)
};
export default KPIStringManager;

//------------supporting functions
function AddToStrObject(returnobj: any, strarry: string[]) {
    let a: number = 0;

    strarry.forEach((t: string) => {
        if (t != "") {
            returnobj = { ...returnobj, [a]: t }
        }
        a += 1
    })
    return returnobj
};

function AddObjToStrObject(returnobj: any, strarry: string[], objslist: any[]) {
    let a: number = 0;

    strarry.forEach((t: string) => {
        if (t != "") {
            let fndobj: any = {}
            let format: string = StringAfterDelimeter(t, "{").replace("{", "").replace("}", "")
            let rest = StringBeforeDelimeter(t, "{")

            let kpikey = StringBeforeDelimeter(rest, "<")
            let enckey = StringAfterDelimeter(rest, "<").replace(">", "")

            objslist.forEach((obj: any) => {
                if (obj.Key === kpikey) {
                    fndobj = obj
                }
            })

            returnobj = {
                ...returnobj, [a]: { ...fndobj, Note: format, FltNote: enckey }
            }


        }
        a += 1
    })
    return returnobj
};
