import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RuleModel, Ruleset, Exception } from "../type";
import { RuleModelInit, RuleInit, } from '../InitTypes';



//rulemodels
export const ruleModelDataSlice = createSlice({
	name: 'rulemodel_action',
	initialState: RuleModelInit,
	reducers: {
		ruleModels: {
			reducer: (state, { payload }: PayloadAction<RuleModel>) => {
				state.push(payload);
			},
			prepare: ({ ModelKey, ModelName, CreateDate, OnUpload, ruleset, renames }: RuleModel) => ({
				payload: {
					ModelKey: ModelKey,
					ModelName: ModelName,
					CreateDate: CreateDate,
					OnUpload: OnUpload,
					ruleset: ruleset,
					renames: renames
				}
			})
		},
		ruleModels_delete: (state, _: PayloadAction<{}>) => {
			state.splice(0, state.length);
		},
	}
});



//rulesets
export const rulesetDataSlice = createSlice({
	name: 'ruleset_action',
	initialState: RuleInit,
	reducers: {
		ruledata: {
			reducer: (state, { payload }: PayloadAction<Ruleset>) => {
				state.push(payload);
				console.log(payload.Operation);
			},
			prepare: ({ ModelName, RuleKey, RuleName, SchemaName, Operation, Field1, Field2, LastUpdatedDate, exceptions }: Ruleset) => ({

				payload: {
					ModelName: ModelName,
					RuleKey: RuleKey,
					RuleName: RuleName,
					SchemaName,
					Operation: Operation,
					Field1: Field1,
					Field2: Field2,
					LastUpdatedDate: LastUpdatedDate,
					exceptions: exceptions
				}
			})
		},
		ruledata_delete: (state, _: PayloadAction<{}>) => {
			state.splice(0, state.length);
		},
	}
});

//single rulemodel
export const selectedRuleModelSlice = createSlice({
	name: 'selrulemodel_action',
	initialState: RuleModelInit[0],
	reducers: {
		selruledata: (state, { payload }: PayloadAction<RuleModel>) => {
			return ({
				ModelKey: payload.ModelKey,
				ModelName: payload.ModelName,
				CreateDate: payload.CreateDate,
				OnUpload: payload.OnUpload,
				ruleset: payload.ruleset,
				renames: payload.renames
			})
		},
		selruledata_delete: (state, _: PayloadAction<{}>) => {
			state = RuleModelInit[0]
		},
	}
});

//Ruleset
export const {
	ruledata: rulesetActionCreator,
	ruledata_delete: rulesetdeleteActionCreator,
} = rulesetDataSlice.actions;


//selected Rule
export const {
	selruledata: selRuleModelActionCreator,
	selruledata_delete: selRuleModelDeleteActionCreator,
} = selectedRuleModelSlice.actions;

//selected Rule
export const {
	ruleModels: RuleModelActionCreator,
	ruleModels_delete: RuleModelDeleteActionCreator,
} = ruleModelDataSlice.actions;

