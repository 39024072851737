import { useEffect, useContext, useState, ChangeEvent } from 'react'
import GenericsUniqueImport from '../../../data/Generics/GenericsUnique_Import';
import { StartupContext } from '../../../App';
import SerializeDataFrame from '../../../functions/Import/SerializeDataFrame';
import ObjectSort from '../../../functions/ObjectSort';
import { useSelector } from 'react-redux';
import { Emp_State, Employee, DescModel, SkillCategory, JobTitle, Department, FieldMap, TableModel, TableData, SkillCatValue } from '../../../type';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import ChangeParamSelectorbyIndex from '../../../functions/ElementSelect/ChangeParamSelectorbyIndex';
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import DateString from '../../../functions/Date Functions/DateString';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import FilterData from '../../../functions/FilterData';
import PivotArray from '../../../functions/PivotArray';
import GetObjectField from '../../../functions/Object Functions/GetObjectField';
import "./css/EmployeeDetail.css"
import { Emp_StateInit } from '../../../InitTypes';
import GetFieldName from '../../../functions/GetFieldName';
import { Modal } from 'react-bootstrap';
import StringTrim from '../../../functions/String Functions/StringTrim';
import IsInObject from '../../../functions/Object Functions/IsInObject';

const EmployeeDetail = () => {

    interface paramtype {
        empid: string;
        descmodel: string;
        jobcode: string;
        deptfields: FieldMap[];
        jobfields: FieldMap[];
        iscatdetailvisible: boolean;
        selcategory: string;
        jobskillcatvals: SkillCatValue[];
        selsimjob: string;
        issimjobvisible: boolean;
        jobskillcatvals_simjob: SkillCatValue[];
    }
    interface careerparam {
        categories: SkillCategory[];
        jobcodes: JobTitle[];
        selcategory: string;
        seljobcode: string;
        summarizeddata: any[];
        thisjobdata: any[];
    }
    interface histparam {
        selIdx: number;
        selStateData: Emp_State
    }

    const { config, dispatcher, schema, user, paths, clickstyle } = useContext(StartupContext)
    let fieldData: FieldMap[] = useSelector((state: any) => state.fieldmapping);
    let [allData, setAllData] = useState<Emp_State[]>([]);
    let [keyparams, setKeyParams] = useState<paramtype>({
        empid: '', descmodel: '', jobcode: '', deptfields: [], jobfields: [],
        selcategory: '', jobskillcatvals: [], selsimjob: '', jobskillcatvals_simjob: [],
        iscatdetailvisible: false, issimjobvisible: false
    })
    let [careerparams, setCareerParams] = useState<careerparam>({
        categories: [], jobcodes: [], selcategory: '', seljobcode: '', summarizeddata: [], thisjobdata: []
    })
    let [histparams, setHistParams] = useState<histparam>({
        selIdx: -1, selStateData: Emp_StateInit
    })
    let [empStateData, setEmpStateData] = useState<Emp_State[]>([])
    let [descmodels, setDescModels] = useState<DescModel[]>([])
    let [careerdata, setCareerData] = useState<any[]>([])
    let [selcareerdata, setSelCareerData] = useState<any[]>([])
    let [jobtitles, setJobTitles] = useState<JobTitle[]>([])
    let [depts, setDepts] = useState<Department[]>([])
    let [pto, setPTO] = useState<TableData[]>([])
    let [trainings, setTrainings] = useState<TableData[]>([])
    let [tmodelkeys, setTModelKeys] = useState({ modelcatkeypto: '', modelcatkeytr: '' })


    useEffect(() => {
        let importdata = GenericsUniqueImport(["EmpID", "Name"], schema, fieldData, config, dispatcher);
        importdata.then(d => {
            let data = SerializeDataFrame(d, ["EmpID", "Name"])
            data = ObjectSort(data, "Name")
            setAllData(data);
        })
        ImportAndSetStateGeneric(setDescModels, '', schema, paths.jobdescription.descmodelview, user, config, { type: "Name" })
        ImportAndSetStateGeneric(setJobTitles, "", schema, paths.jobtitle.jobtitleattrview, user, config, { asofdate: new Date() })
        ImportAndSetStateGeneric(setDepts, "", schema, paths.department.deptattrview, user, config, { asofdate: new Date() })
        GenericBackendCall("", schema, paths.tables.tablemodelview, user, config, {}).then(d => {
            let ptokey = ""
            let trainkey = ""
            d.forEach((t: TableModel) => {
                if (t.ModelName === "PTO") {
                    ptokey = t.ModelKey
                } else if (t.ModelName === "TrainingRequirements") {
                    trainkey = t.ModelKey
                }
            })
            setTModelKeys({ ...keyparams, modelcatkeypto: ptokey, modelcatkeytr: trainkey })
        })
    }, [])


    useEffect(() => {
        if (jobtitles.length > 0 && depts.length > 0) {
            setKeyParams({ ...keyparams, jobfields: FilterData(fieldData, "ModelCategory", "JobTitle"), deptfields: FilterData(fieldData, "ModelCategory", "Department") })
        }

    }, [jobtitles, depts])


    useEffect(() => {
        if (keyparams.empid !== "" && tmodelkeys.modelcatkeypto !== "") {
            GenericBackendCall("", schema, paths.tables.tabledataview, user, config, {
                modelcategory: tmodelkeys.modelcatkeypto, how: "Vertical", onfield: "Attribute1", value: keyparams.empid
            }).then(d => { setPTO(d) })
        }
    }, [keyparams.empid, tmodelkeys.modelcatkeypto])


    useEffect(() => {
        if (keyparams.empid !== "" && tmodelkeys.modelcatkeytr !== "") {
            GenericBackendCall("", schema, paths.tables.tabledataview, user, config, {
                modelcategory: tmodelkeys.modelcatkeytr, how: "Vertical", onfield: "Attribute2", value: keyparams.empid
            }).then(d => { console.log(d); setTrainings(d) })
        }
    }, [keyparams.empid, tmodelkeys.modelcatkeytr])

    useEffect(() => {
        let newdata: any[] = careerdata
        if (careerparams.selcategory !== "") {
            newdata = FilterData(newdata, "Category", careerparams.selcategory)
        }
        if (careerparams.seljobcode !== "") {
            newdata = FilterData(newdata, "JobCodeTo", careerparams.seljobcode)
        }
        setSelCareerData(newdata)
    }, [careerparams.selcategory, careerparams.seljobcode])

    useEffect(() => {
        if (keyparams.descmodel !== "" && keyparams.empid !== "") {

            GenericBackendCall('', schema, paths.jobdescription.skillemployeeview, user, config, { empid: keyparams.empid, descmodelkey: keyparams.descmodel }).then(d => {
                if (d[0] !== "") {
                    let data = SerializeDataFrame(d[0])
                    let flt = PivotArray(data, "JobCodeTo", "", "FitPct")
                    flt = ObjectSort(flt, "FitPct", true)
                    console.log(flt)
                    setCareerData(data)
                    //setSelCareerData(data)
                    let thisjob = PivotArray(data, "Category", "", "Catcount")
                    setCareerParams({
                        ...careerparams,
                        categories: SerializeDataFrame(d[1]), jobcodes: SerializeDataFrame(d[2]),
                        summarizeddata: flt, thisjobdata: thisjob
                    })
                }
            })
        }
    }, [keyparams.empid, keyparams.descmodel])

    useEffect(() => {
        GenericBackendCall('', schema, paths.employee.singleemp, user, config, { empid: keyparams.empid }).then((d: Employee[]) => {
            if (d.length === 1) {
                setEmpStateData(d[0].state)
                let jc = d[0].state[d[0].state.length - 1].JobCode
                setKeyParams({ ...keyparams, jobcode: jc })
            }
        })
    }, [keyparams.empid])

    useEffect(() => {
        if (keyparams.jobcode !== "" && keyparams.descmodel !== "") {
            GenericBackendCall(keyparams.descmodel, schema, paths.jobdescription.skillcatvalueview, user, config, {
                jobcodes: [keyparams.jobcode],
            }).then(d => {
                setKeyParams({ ...keyparams, jobskillcatvals: d })
            })
        }
    }, [keyparams.jobcode, keyparams.descmodel])

    useEffect(() => {
        if (keyparams.selsimjob !== "" && keyparams.descmodel !== "") {
            GenericBackendCall(keyparams.descmodel, schema, paths.jobdescription.skillcatvalueview, user, config, {
                jobcodes: [keyparams.selsimjob],
            }).then(d => {
                setKeyParams({ ...keyparams, jobskillcatvals_simjob: d })
            })
        }
    }, [keyparams.selsimjob, keyparams.descmodel])

    useEffect(() => {

    }, [histparams.selIdx])

    //NetworkGraph2D(selNetworkRunDate, SNAstate, setSNAstate, "#snagraph1", [1000, 700], params.refresh)


    return (<div className="ndt_canvas">
        <div className="ndt_title2">Employee Detail</div>
        <div className="ndt_gridbox">
            <GenericDropdown
                data={allData}
                keycol="EmpID"
                namecol="Name"
                className="ndt_dropdown"
                divID="empdet_idseldiv"
                promptstring="Select Employee"
                change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, allData, keyparams, setKeyParams, "empid", "EmpID", "")}
            />

            <div className='ndt_innerbox' style={{ padding: "10px" }}>
                <div style={{ fontSize: "18px" }}>History:</div>
                <div style={{ display: "flex" }}>
                    <div id="empdet_histbox" style={{ height: "290px", overflowX: "scroll", width: "300px", fontSize: "15px" }}>
                        {empStateData.map((st: Emp_State, i: number) => {
                            return (<div key={i} className='empdet_histitm'
                                style={histparams.selIdx === i ? clickstyle.itmSelected : clickstyle.itmUnselected}
                                onClick={_ => { setHistParams({ ...histparams, selIdx: i, selStateData: st }) }}
                            >{DateString(st.Date)}</div>)
                        })}
                    </div>
                    <div style={{ marginLeft: "20px", backgroundColor: "rgb(30,10,30)", border: "1px solid white", borderRadius: "10px", padding: "10px", width: "350px", height: "290px" }} >
                        <div>EmpID: {histparams.selStateData.EmpID}</div>
                        <div>Name: {histparams.selStateData.Name}</div>
                        <div>Date: {DateString(histparams.selStateData.Date)}</div>
                        <div>Job Title: {GetObjectField(histparams.selStateData.JobCode, jobtitles, "JobCode", "JobTitle")}</div>
                        <div>{GetFieldName(keyparams.jobfields, "Attribute1", "AttrName", "FieldName")}: {GetObjectField(histparams.selStateData.JobCode, jobtitles, "JobCode", "Attribute1")}</div>
                        <div>Dept: {GetObjectField(histparams.selStateData.DeptID, depts, "Dept", "DepartmentName")}</div>
                        <div>{GetFieldName(keyparams.deptfields, "Attribute1", "AttrName", "FieldName")}: {GetObjectField(histparams.selStateData.DeptID, depts, "Dept", "Attribute1")}</div>

                    </div>
                    <div style={{ display: "flex" }}>
                        <div className='ndt_innerbox empdet_card' style={{ marginLeft: "100px" }}>
                            <div style={{ fontSize: "19px" }}>PTO Days</div>
                            <div style={{ height: '240px', overflowY: "scroll", width: "300px" }}>
                                {pto.map((td: TableData, i: number) => {
                                    return (<div key={i} style={{ display: "flex" }}>
                                        <div style={{ width: "150px" }}>{DateString(td.CurrDate)} </div>
                                        <div style={{ width: "80px" }}>{td.Attribute3}</div>
                                    </div>)
                                })}
                            </div>
                        </div>
                        <div className='ndt_innerbox empdet_card' style={{ marginLeft: "50px" }}>
                            <div style={{ fontSize: "19px" }}>Completed Trainings</div>
                            <div style={{ height: '240px', overflowY: "scroll", width: "400px" }}>
                                {trainings.map((td: TableData, i: number) => {
                                    return (<div key={i} style={{ display: "flex" }}>
                                        <div style={{ width: "150px" }}>{DateString(td.CurrDate)} </div>
                                        <div style={{ width: "200px" }}>{td.Attribute5}</div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ndt_innerbox'>
                <div className='ndt_innerbox'>
                    <div style={{ fontSize: "20px" }}>Career Path Model:</div>
                    <GenericDropdown
                        data={descmodels}
                        keycol="ModelKey"
                        namecol="ModelName"
                        className="ndt_dropdown"
                        divID="empdiv_careersel"
                        promptstring="Select Model"
                        change={(e: ChangeEvent<HTMLSelectElement>) => ChangeParamSelectorbyIndex(e, descmodels, keyparams, setKeyParams, "descmodel", "ModelKey", "")}
                    />
                    <div>
                        <div style={{ marginLeft: "600px" }}>
                            <select style={{ width: "350px" }} defaultValue={"Select"} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setCareerParams({ ...careerparams, selcategory: e.target.selectedOptions[0].attributes[0].value }) }}>
                                <option disabled value="Select">Select a Category</option>
                                <option value="">(Unselect)</option>
                                {careerparams.categories.map((c: SkillCategory, i: number) => {
                                    return (<option key={i} value={c.Category}>{c.Category}</option>)
                                })}
                            </select>
                            <select style={{ width: "300px" }} defaultValue={"Select"} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setCareerParams({ ...careerparams, seljobcode: e.target.selectedOptions[0].attributes[0].value }) }}>
                                <option disabled value="Select">Select a Job Code</option>
                                <option value="">(Unselect)</option>
                                {careerparams.jobcodes.map((c: JobTitle, i: number) => {
                                    return (<option key={i} value={c.JobCode}>{c.JobTitle}</option>)
                                })}
                            </select>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "10px" }}>
                                <div style={{ fontSize: "17px", letterSpacing: ".05em", height: "30px", overflow: "hidden" }}>- Current Position {keyparams.jobcode} - {StringTrim(GetObjectField(keyparams.jobcode, jobtitles, "JobCode", "JobTitle"), 20)}</div>
                                <div id="empdet_currposbox" style={{ background: "rgb(10,10,50)", height: "250px", overflowY: "scroll", width: "30%", minWidth: "400px" }}>
                                    {careerparams.thisjobdata.map((d: any, i: number) => {
                                        return (<div key={i} style={{ display: "flex" }} className='empdet_currcatitm' onClick={_ => { setKeyParams({ ...keyparams, selcategory: d['Value'], iscatdetailvisible: true }) }}>
                                            <div style={{ width: '90%' }}>{d['Value']} </div>
                                            <div>{d['Mean']}</div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                                <div style={{ fontSize: "17px", letterSpacing: ".05em" }}> - Similar Jobs/Match Score - </div>
                                <div style={{ background: "rgb(10,10,50)", height: "250px", overflowY: "scroll", width: "30%", minWidth: "400px" }}>
                                    {careerparams.summarizeddata.map((d: any, i: number) => {
                                        return (<div key={i} style={{ display: "flex" }} className='empdet_simjobitm' onClick={_ => { setKeyParams({ ...keyparams, selsimjob: d['Value'], issimjobvisible: true }) }}>
                                            <div style={{ width: '90%' }}>{GetObjectField(d['Value'], careerparams.jobcodes, "JobCode", "JobTitle")}</div>
                                            <div>{d['Mean']}</div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                                <div style={{ fontSize: "17px", letterSpacing: ".05em" }}> - Selected Job Filters - </div>
                                <div style={{ background: "rgb(30,30,50)", height: "250px", overflowY: "scroll", width: "40%", minWidth: "500px" }}>
                                    {selcareerdata.map((d: any, i: number) => {
                                        return (<div key={i} style={{ display: "flex" }}>
                                            <div style={{ width: "400px" }}>{d['JobCodeTo']} - {d['Category']}</div>
                                            <div>{d['Catcount']} </div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={keyparams.iscatdetailvisible} dialogClassName='modalwide2'>

                    <div className="ndt_modal">
                        <div className='ndt_modaltitle'>Category Detail: </div>
                        <div>{keyparams.jobcode}: {keyparams.selcategory}</div>
                        <div style={{ marginTop: "10px" }}>
                            {keyparams.jobskillcatvals.map((sc: SkillCatValue, i: number) => {
                                if (sc.Category === keyparams.selcategory) {
                                    return <div key={i} className='empdet_skillitm'>
                                        <div>{StringTrim(sc.JobSkill, 250)}</div>
                                    </div>
                                }
                            })}
                        </div>
                        <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, iscatdetailvisible: false })}>Close</button>
                    </div>
                </Modal>


                <Modal show={keyparams.issimjobvisible} dialogClassName='modalwide2'>

                    <div className="ndt_modal">
                        <div className='ndt_modaltitle'>Similar Job: {keyparams.selsimjob}, {GetObjectField(keyparams.selsimjob, jobtitles, "JobCode", "JobTitle")}</div>
                        <div>-Skill Categories shared with {keyparams.jobcode}</div>
                        <div style={{ marginTop: "20px" }}>
                            <div className='' style={{ display: "flex", height: "30px", paddingLeft: "10px" }}>
                                <div style={{ minWidth: "120px", maxWidth: "120px", }}>
                                    Category
                                </div>
                                <div style={{ paddingLeft: "5px" }}>Skill Description</div>

                            </div>
                            {keyparams.jobskillcatvals_simjob.map((sc: SkillCatValue, i: number) => {
                                if (IsInObject(careerparams.categories, sc.Category, "Category")) {
                                    return <div key={i} className='empdet_skillitm' style={{ display: "flex", height: "70px" }}>
                                        <div style={{ minWidth: "120px", maxWidth: "120px", borderRight: "1px solid grey" }}>
                                            {sc.Category}
                                        </div>
                                        <div style={{ paddingLeft: "5px" }}>{StringTrim(sc.JobSkill, 250)}</div>

                                    </div>
                                }
                            })}
                        </div>
                        <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issimjobvisible: false })}>Close</button>
                    </div>
                </Modal>

            </div>
        </div>
    </div>)
};
export default EmployeeDetail