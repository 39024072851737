import { UserPermssion, User } from "../../type";
import GetObjectValue from "../Object Functions/GetObjectValue";


const GetUserPermission = (models: any, user: User, setfn: any) => {
    //ref: schemamanage, dashboardsmanage, graphmanager, kpistringmanager
    GetObjectValue(models, 'userperms').forEach((up: UserPermssion) => {
        if (String(up.User) === String(user.id)) {
            setfn(up)
        }
    })
};
export default GetUserPermission;