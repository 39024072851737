import { QueryVisuals, DashboardFilter, KPIString } from '../../type';
import AxiosUpload from '../AxiosUpload';


const DashboardUpdate = async (modelkey: string, qryvisuals: QueryVisuals[], kpistrings: KPIString[],
    savetypes: any, filterpositions: DashboardFilter[], pages: any, schedulekey: any, config: any, elementstring: string) => {

    let dataArray = new FormData();


    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("modelkey", modelkey);

    //converttoarry
    const qryvisencoded = qryvisuals.map((qv: QueryVisuals) => {
        return (
            [
                qv.VisKey,
                qv.Height,
                qv.Width,
                qv.XPos,
                qv.YPos,
                qv.PageNo
            ]
        )
    })
    const kpisencoded = kpistrings.map((ks: KPIString) => {
        return (
            [
                ks.Key,
                ks.Height,
                ks.Width,
                ks.XPos,
                ks.YPos,
                ks.PageNo
            ]
        )
    })

    const pagesencoded = Object.keys(pages).map((k: string) => {
        return (
            [k, pages[k]]
        )
    })

    const qryrundatesencoded = qryvisuals.map((qv: QueryVisuals) => {
        return (
            [
                qv.VisKey,
                qv.RunDateKey
            ]
        )
    })

    const dashfilters = filterpositions.map((df: DashboardFilter) => {
        return ([
            df.FieldName,
            df.Height,
            df.Width,
            df.PageNo
        ])
    })

    dataArray.append("qryvisparams", String(qryvisencoded));
    dataArray.append("kpistrparams", String(kpisencoded));

    dataArray.append("pages", String(pagesencoded));
    dataArray.append("qryrundates", String(qryrundatesencoded));
    dataArray.append("dashfilters", String(dashfilters));
    dataArray.append("schedulekey", String(schedulekey));


    dataArray.append("update_pages", String(savetypes.pages));
    dataArray.append("update_positions", String(savetypes.positions));
    dataArray.append("update_rundates", String(savetypes.rundates));
    dataArray.append("update_filters", String(savetypes.filters));
    dataArray.append("update_schedule", String(savetypes.schedule));



    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/dashboard/dashupdate", config, elem, "", true);

    return ("Ok")
};

export default DashboardUpdate;