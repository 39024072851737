

const GetStackedValues = (data, xfield, yfield, xgroups, groupfield, isbenchmark) =>{

    let dvals = xgroups.map(_=> {return []});
    let dvars = xgroups.map(_=> {return []});
    let dheights = xgroups.map(_=> {return []});
    let dsums = xgroups.map(_=> {return 0});


    if (!groupfield){groupfield = 'Grouping'}
    data.forEach(d => {
        let i = xgroups.indexOf(d[xfield])
        dvals[i].push(+d[yfield])
        dvars[i].push(d[groupfield])
        dsums[i] += +d[yfield]
        dheights[i].push(dsums[i])
    });


    function GetVal(d){
        let grp = xgroups.indexOf(d[xfield])
        let gvar = dvars[grp].indexOf(d[groupfield])      
        let rt = dvals[grp][gvar]
        return(rt)
    };
    function GetCumVal(d){
        let grp = xgroups.indexOf(d[xfield])
        let gvar = dvars[grp].indexOf(d[groupfield])    
        let rt = dheights[grp][gvar]
        return(rt)
    };


    return ([GetVal,GetCumVal])
};
export default GetStackedValues;