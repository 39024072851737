import { useState, useEffect, useContext, ChangeEvent, useMemo } from 'react';
import GenericsUniqueImport from '../../../data/Generics/GenericsUnique_Import';
import { ColorSet, ColorValue, FieldMap, TableModel } from '../../../type';
import ImportAndSetState from '../../../functions/Import/ImportAndSetState';
import FieldsImport from '../../../data/Fields/Fields_Import';
import { StartupContext } from '../../../App';
import { Modal } from 'react-bootstrap';
import ColorSetImport from '../../../data/ColorModel/ColorSet_Import';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import ChangeSelectorbyIndex from '../../../functions/ElementSelect/ChangeSelectorbyIndex';
import { ColorSetInit } from '../../../InitTypes';
import { SwatchesPicker } from 'react-color';
import ColorSetUpload from '../../../data/ColorModel/ColorSet_Upload';
import GetFieldName from '../../../functions/GetFieldName';
import { useParams } from "react-router-dom";
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import SerializeDataFrame from '../../../functions/Import/SerializeDataFrame';
import GetRandomHexadecimal from '../../../functions/GetRandomHexadecimal';
import GetTextColor from '../../../functions/GetTextColor';
import GetObjectValue from '../../../functions/Object Functions/GetObjectValue';
import ModelCategoryDropdown from '../../../functions/Dropdowns/ModelCategoryDropdown';
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import "./css/ColorSetEditor.css";

const ColorSetEditor = () => {

    let { id } = useParams()

    let { config, dispatcher, schema, user, paths } = useContext(StartupContext)
    interface param_type {
        selindex: number; selFieldName: string; selFieldKey: string; refresh: number;
        iscolormodalvisible: boolean; issavemodalvisible: boolean;
        isdeletemodalvisible: boolean; isrenamemodalvisible: boolean;
        israndomizevisible: boolean; othercolor: string; othercolorsec: string; clickedrwidx: number; seltable: string;
    }

    let [fields, setFields] = useState<FieldMap[]>([])
    let [fValues, setFValues] = useState<ColorValue[]>([])
    let [fValuesStored, setFValuesStored] = useState<ColorValue[]>([])

    let [colSets, setColSets] = useState<ColorSet[]>([])
    let [keyparams, setKeyParams] = useState<param_type>({
        selindex: -1, selFieldKey: "", selFieldName: "", refresh: 0,
        iscolormodalvisible: false, issavemodalvisible: false, isdeletemodalvisible: false,
        isrenamemodalvisible: false, israndomizevisible: false, othercolor: "#000000", othercolorsec: "#000000",
        clickedrwidx: 0, seltable: ''
    })
    let [selColSet, setSelColSet] = useState<ColorSet>(ColorSetInit[0])
    const [tables, setTables] = useState<TableModel[]>([])

    useEffect(() => {
        ImportAndSetState(FieldsImport, setFields, "*", schema, config, dispatcher, false);
        ImportAndSetState(ColorSetImport, setColSets, "*", schema, config, dispatcher, false);
        ImportAndSetStateGeneric(setTables, "*", schema, paths.tables.tablemodelview, user, config, {})
    }, [])

    useEffect(() => {
        colSets.map((cs: ColorSet) => {
            if (cs.SetKey === id) {
                setSelColSet(cs)
            }
        })
    }, [colSets])

    useEffect(() => {
        setKeyParams({ ...keyparams, selFieldKey: selColSet.FieldName })
    }, [selColSet])


    useEffect(() => {
        //reset fvals for new color set
        if (selColSet.FieldName !== "") {
            let fldunique = GenericsUniqueImport([selColSet.FieldName], schema, fields, config, dispatcher);
            fldunique.then((d: any[]) => {
                if (d.length > 0) {
                    let keynm = "XX"
                    let data = SerializeDataFrame(d, [keynm])
                    console.log(d)
                    console.log(data)
                    let colorValues: ColorValue[] = data.map((val: any) => {
                        let pcolor = ""
                        let scolor = ''
                        selColSet.colorvalues.forEach((cv: ColorValue) => {
                            if (cv.ValueName === val[keynm]) {
                                pcolor = cv.Color
                                scolor = cv.SecondaryColor
                            }
                        })
                        return ({ ValueName: String(val[keynm]), Color: pcolor, SecondaryColor: scolor, SetName: "Set Primary Color", ValueKey: "Set Secondary Color" })
                    })
                    setFValues(colorValues);
                }
            })
        }
    }, [selColSet.FieldName])

    const ChangeField = (e: ChangeEvent<HTMLSelectElement>) => {
        let attrs = e.target.selectedOptions[0].attributes
        console.log(attrs)
        setSelColSet({
            ...selColSet,
            FieldName: attrs[1].value
        })
    }

    const ChangeColor = (e: any, i: number) => {
        let newvalues = fValues
        if (keyparams.selindex >= 0) {
            if (i === 0) {
                newvalues[keyparams.selindex].Color = e.hex
                newvalues[keyparams.selindex].SetName = String(e.hex)
            } else if (i === 1) {
                newvalues[keyparams.selindex].SecondaryColor = e.hex
                newvalues[keyparams.selindex].ValueKey = String(e.hex)
            }

            setFValues(newvalues)
        } else {
            setKeyParams({ ...keyparams, othercolor: e.hex })
        }
    }

    const ClickSaveColorSet = () => {
        let colorsetdata: ColorValue[] = []
        fValues.map((fval: ColorValue) => {
            if (fval.Color !== "") {
                colorsetdata.push(fval)
            }
        })
        ColorSetUpload(colorsetdata, schema, selColSet.SetKey, selColSet.SetName, selColSet.FieldName, keyparams.othercolor, config, "cse_savediv")
    }

    const ClickDelete = () => {
        console.log("delete")
        GenericBackendCall(selColSet.SetKey, schema, paths.colorsets.colorsetdelete, user, config, {}, "cse_deldiv")
    }

    const ClickRename = () => {
        GenericBackendCall(selColSet.SetKey, schema, paths.colorsets.colorsetrename, user, config,
            { setname: selColSet.SetName }, "cse_rendiv")
    }

    const ClickRandomize = (includeexisting: boolean, field: string) => {
        let newvals = fValues.map((fv: ColorValue, i: number) => {
            let newcolor = ''
            let fcolor = ''

            newcolor = GetObjectValue(fValues[i], field)
            fcolor = GetObjectValue(fv, field)

            if (fcolor === "" || includeexisting) {
                newcolor = GetRandomHexadecimal(6)
            }
            return { ...fv, [field]: newcolor }
        })
        setFValues(newvals)
        setFValuesStored(fValues)
    }
    const ClickRandUndo = () => {
        setFValues(fValuesStored)
        setFValuesStored([])
    }

    const FieldUniqueValues = () => {
        return (
            <div id="cse_itmlist">
                <div id="cse_flduniquelist" >
                    {fValues?.map((urow: ColorValue, i: number) => {
                        return (<div
                            className="cse_fltitm ndt_item "
                            key={i + "div"}
                        >
                            <div
                                id={"cse_fltitm_" + String(urow.ValueName)}
                                data-value={String(urow.ValueName)}
                                data-int={String(i)}
                                className="cse_fltitmval"
                                key={i}
                            >
                                {urow.ValueName}
                            </div>
                            <div key={i + "b"}
                                className="cse_flditm_color"
                                style={{ backgroundColor: urow.Color, color: GetTextColor(urow.Color) }}
                                onClick={(_) => setKeyParams({ ...keyparams, selindex: i, iscolormodalvisible: true, clickedrwidx: 0 })}
                            >
                                {urow.SetName}
                            </div>
                            <div key={i + "c"}
                                className="cse_flditm_color"
                                style={{ backgroundColor: urow.SecondaryColor, color: GetTextColor(urow.SecondaryColor) }}
                                onClick={(_) => setKeyParams({ ...keyparams, selindex: i, iscolormodalvisible: true, clickedrwidx: 1 })}
                            >
                                {urow.ValueKey}
                            </div>
                        </div>)
                    })}
                    <div
                        className="cse_fltitm ndt_item "
                        style={{ backgroundColor: "#666666" }}
                        onClick={(_) => setKeyParams({ ...keyparams, selindex: -1, iscolormodalvisible: true })}
                    >
                        <div
                            data-value={"Other"}
                            data-int={String('-1')}
                            className="cse_fltitmval"
                        >
                            (Other Values)
                        </div>
                        <div key={"_b"}
                            className="cse_flditm_color"
                            style={{ backgroundColor: keyparams.othercolor }}
                        >
                            {keyparams.othercolor}
                        </div>
                        <div key={"_c"}
                            className="cse_flditm_color"
                            style={{ backgroundColor: keyparams.othercolorsec }}
                        >
                            {keyparams.othercolorsec}
                        </div>
                    </div>
                </div>
            </div>
        )

    }


    let FieldValuesMemo = useMemo(() => FieldUniqueValues(), [keyparams.selindex, keyparams.othercolor, fValues, keyparams.refresh, selColSet])

    return (<div id="cse_canvas" className="ndt_canvas">

        <div className="ndt_gridbox" style={{ marginBottom: "15px" }}>
            <div className="ndt_title2">Manage Colorsets</div>

            <GenericDropdown
                data={colSets}
                change={(e: ChangeEvent<HTMLSelectElement>) => ChangeSelectorbyIndex(e, colSets, setSelColSet, "", { ...ColorSetInit[0], SetKey: "_", })}
                keycol="SetKey"
                namecol="SetName"
                divID="cse_csddown"
                className="ndt_dropdown"
                promptstring="Select Color Set"
                includeDefault={true}
                defaultstring="Create new color set"
            />
            {(() => {
                switch (selColSet.SetKey) {
                    case "_": return <div id="cse_newsetbox" style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <div style={{ marginRight: "10px", fontSize: "18px" }}>Add New Set</div>
                        <ModelCategoryDropdown tables={tables}

                            change={(e: ChangeEvent<HTMLSelectElement>) => {
                                setKeyParams({ ...keyparams, seltable: e.target.selectedOptions[0].attributes[0].value })
                            }} />
                        <GenericDropdown
                            data={fields}
                            keycol="Key"
                            namecol="FieldName"
                            divID="cse_fldddown"
                            className="ndt_dropdown"
                            change={(e: ChangeEvent<HTMLSelectElement>) => ChangeField(e)}
                            includecategory={false}
                            filterfield={"ModelCategory"}
                            filtervalue={keyparams.seltable}
                            promptstring="Select Field"
                        />
                    </div>;
                    case "": return <div></div>;
                    default: return <div style={{ marginTop: "5px", display: 'flex' }}>
                        <div>
                            <div style={{ marginRight: "10px" }}>Delete this set?</div>
                            <button className='ndt_btn2' onClick={_ => setKeyParams({ ...keyparams, isdeletemodalvisible: true })}>Delete</button>
                        </div>
                        <div style={{ marginLeft: "120px" }}>
                            <div style={{ marginRight: "10px" }}>Rename this set?</div>
                            <button className='ndt_btn1' onClick={_ => setKeyParams({ ...keyparams, isrenamemodalvisible: true })}>Rename</button>
                        </div>
                    </div>;
                }
            })()}


        </div>
        <div id="cse_body" className="ndt_gridbox">
            <div >
                {fValues.length > 0 ?
                    <div >
                        {FieldValuesMemo}
                        <div style={{ margin: '10px' }}>
                            <button className="ndt_btn3" onClick={_ => setKeyParams({ ...keyparams, israndomizevisible: true })}>Randomize Colors</button>
                            {fValuesStored.length > 0 ? <button onClick={_ => { ClickRandUndo() }} className="ndt_btn1">Undo</button> : <div></div>}
                        </div>
                    </div>
                    : <div></div>}
            </div>
        </div>

        {selColSet.SetKey ?
            <div className="ndt_gridbox" style={{ marginTop: "15px" }}>
                Save Color Set
                <button className="ndt_btn1" style={{ marginLeft: "10px" }} onClick={_ => setKeyParams({ ...keyparams, issavemodalvisible: true })}>Save</button>
            </div>
            : <div></div>}

        <Modal show={keyparams.iscolormodalvisible}>
            <div className="ndt_modal">
                Select {keyparams.clickedrwidx === 0 ? "Primary" : "Secondary"} Color
                <SwatchesPicker onChangeComplete={(e: any) => ChangeColor(e, keyparams.clickedrwidx)} />
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, selindex: -1, iscolormodalvisible: false })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.issavemodalvisible}>
            <div className="ndt_modal">
                <div style={{ 'width': '80%' }}>
                    <div>Save this Color Set on field: {GetFieldName(fields, selColSet.FieldName, "Key", "FieldName")}?</div>
                    {selColSet.SetKey === "_" ?
                        <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => setSelColSet({ ...selColSet, SetName: e.target.value })} />
                        : <div></div>}
                </div>
                <button className="ndt_btn1" style={{ margin: "10px", width: "200px" }} onClick={_ => ClickSaveColorSet()}>Save</button>
                <div id="cse_savediv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavemodalvisible: false })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isdeletemodalvisible}>
            <div className="ndt_modal">
                Delete Color Set {selColSet.SetName}?
                <button className="ndt_btn1" style={{ margin: "10px", width: "200px" }} onClick={_ => ClickDelete()}>Delete</button>
                <div id="cse_deldiv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, selindex: -1, isdeletemodalvisible: false })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isrenamemodalvisible}>
            <div className="ndt_modal">
                Rename Color Set {selColSet.SetName}?
                <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => setSelColSet({ ...selColSet, SetName: e.target.value })} />
                <button className="ndt_btn1" style={{ margin: "10px", width: "200px" }} onClick={_ => ClickRename()}>Rename</button>
                <div id="cse_rendiv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, selindex: -1, isrenamemodalvisible: false })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.israndomizevisible}>
            <div className="ndt_modal">
                <div style={{ fontSize: "22px" }}>Randomize Colors</div>
                <div>
                    Primary
                    <button className="ndt_btn1" onClick={_ => ClickRandomize(true, "Color")}> All</button>
                    <button className="ndt_btn1" onClick={_ => ClickRandomize(false, "Color")}> Blanks Only</button>
                </div>
                <div>
                    Secondary
                    <button className="ndt_btn1" onClick={_ => ClickRandomize(true, "SecondaryColor")}> All</button>
                    <button className="ndt_btn1" onClick={_ => ClickRandomize(false, "SecondaryColor")}> Blanks Only</button>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, refresh: keyparams.refresh + 1, israndomizevisible: false })}>Close/Apply</button>
            </div>
        </Modal>

    </div>)
};
export default ColorSetEditor