import axios from 'axios';

const EmpModelDelete = (modelkey: string, configheaders: any, elementstring: HTMLElement) => {

    let dataArray = new FormData();
    dataArray.append('modelkey', modelkey);

    axios.post(`${process.env.REACT_APP_API_URL}/api/employee/empmodeldelete`,
        dataArray,
        configheaders
    )
        .then(res => {
            console.log(res)
            console.log('deleted')
            elementstring.innerHTML = "Deleted " + " Successfully!"
        })
        .catch(err => {
            console.log('data deletion error');
            elementstring.innerHTML = "There was an error."
        })

};

export default EmpModelDelete;