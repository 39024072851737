import DateString from "./Date Functions/DateString";
const GetNextValue = (data: any[], field: string, value: any, datatype: string, getprev: boolean = false) => {
    //ref: retval
    let retidx: number = -1
    let maxval: any = 0
    let minval: any = 10000000
    if (datatype === "Date") {
        maxval = DateString(new Date("1800-01-01"))
        minval = DateString(new Date("3000-01-01"))
        value = DateString(value)
    }

    data.forEach((d, i) => {
        if (getprev && d[field] < value && d[field] > maxval) { //looking for max previous value
            maxval = d[field]
            retidx = i
        }
        if (!getprev && d[field] > value && d[field] < minval) { //looking for min next value
            minval = d[field]
            retidx = i
        }
    })
    return (retidx)
};
export default GetNextValue