import axios from "axios";
import DatatoFormArray from "./DatatoFormArray";
//import ConvertBackendDates from "../functions/Date Functions/ConvertBackendDates";


const GenericBackendCallTable = async (data: any, rowkey: string, schemakey: string, modelcategory: string, keycol: string,
    urlstring: string, successString: string, config: any, elementstring: string) => {

    let output: any = [];
    let msg: string = "";

    let dataArray = DatatoFormArray(data);
    dataArray.append("_rowkey", rowkey);
    dataArray.append("schemakey", schemakey);

    dataArray.append("model", modelcategory);
    dataArray.append("field", keycol);


    try {
        let res = await axios.post<any[]>(`${process.env.REACT_APP_API_URL}/` + urlstring,
            dataArray, config);

        output = res.data
        //console.log(output);
        //output = ConvertBackendDates(output)
        msg = successString
    } catch (error) {
        //console.log(error);
        msg = String(error)
    }

    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = msg };

    return (output)
};
export default GenericBackendCallTable;


