import { clickstyle } from "../type";


const GetClickStyles = (): clickstyle => {
    //ref: app

    let clickstyles: clickstyle = {
        itmSelected: { background: "rgba(21, 59, 174, 0.6)", color: "white", boxShadow: "0px 0px 5px yellow, inset 0px 0px 5px white" },
        itmUnselected: {},
        btnSelected: { boxShadow: "0px 0px 8px yellow", background: "rgba(0, 0, 200, .6)" },
        btnUnselected: {},
        tabSelected: { boxShadow: "0px -1px 2px yellow", background: "rgb(17, 16, 89)", borderBottom: '0px solid black' },
        tabUnselected: {}
    }



    return clickstyles
};
export default GetClickStyles;