import axios from "axios";
import { QueryRunDate } from "../../type";
const RunDateImport = async (modelkey: string, schemakey: string, config: any) => {

    let rd: QueryRunDate[] = [];

    try {
        let res = await axios.post<QueryRunDate[]>(`${process.env.REACT_APP_API_URL}/api/query/rundatesview`,
            { 'schemakey': schemakey, 'modelkey': modelkey, 'how': "single" }, config);

        rd = res.data
        //console.log(rd);
    } catch (error) {
        console.log(error);
    }
    return (rd)
};

export default RunDateImport;