const GetMinMaxMean = (data: any[], field: string) => {
    //ref: leaderview
    let min: number = 10000000
    let max: number = 0
    let mean: number = 0
    let totval: number = 0

    data.forEach((d: any) => {
        if (+d[field] < min) {
            min = +d[field]
        }
        if (+d[field] > max) {
            max = +d[field]
        }
        totval += +d[field]
    })

    if (data.length > 0) {
        mean = totval / data.length
    }

    return [min, max, mean]
};
export default GetMinMaxMean