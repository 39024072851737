import JobSkillPipeline from "./JobSkillPipeline";
import { useContext, useEffect, useState, ChangeEvent } from "react";
import { StartupContext } from "../../../App";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import { DescModel, Emp_State, JobSkill } from "../../../type";
import { JobDescription, JobTitle } from "../../../type";
import JobSkillRoster from "./JobSkillRoster";
import JobSkillCategories from "./JobSkillCategories";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import StyleMainSelect from "../../../styling/StyleMainSelect";
import { Modal } from "react-bootstrap";


const JobDescriptionBase = () => {

    let { config, dispatcher, schema, user, paths } = useContext(StartupContext)

    let [jdmodels, setJDModels] = useState<DescModel[]>([])
    let [selJDModel, setSelJDModel] = useState<DescModel | null>(null)

    let [jobdesc, setJobDesc] = useState<JobDescription[]>([])
    let [jobtitles, setJobTitles] = useState<JobTitle[]>([])
    let [jobskills, setJobSkills] = useState<JobSkill[]>([])
    let [roster, setRoster] = useState<Emp_State[]>([])
    let [keyparams, setKeyParams] = useState({ viewpage: '', selmodelkey: '', isdeletevisible: false, refresh: 0 })

    let [loadedjt, setLoadedJT] = useState<boolean>(false)
    let [loadedjd, setLoadedJD] = useState<boolean>(false)
    let [loadedskills, setLoadedSkills] = useState<boolean>(false)


    useEffect(() => {
        ImportAndSetStateGeneric(setJDModels, '', schema, paths.jobdescription.descmodelview, user, config, { type: "Name" })
        ImportAndSetStateGeneric(setJobTitles, '', schema, paths.jobtitle.jobtitleview, user, config, {}).then(_ => { setLoadedJT(true) })
        ImportAndSetStateGeneric(setJobDesc, '', schema, paths.jobdescription.jobdescview, user, config, {}).then(_ => { setLoadedJD(true) })
        ImportAndSetStateGeneric(setJobSkills, '', schema, paths.jobdescription.jobskillview, user, config, {}).then(_ => { setLoadedSkills(true) })
        ImportAndSetStateGeneric(setRoster, '', schema, paths.employee.dataslice, user, config, { asofdate: new Date() }).then(_ => { setLoadedSkills(true) })
    }, [])

    useEffect(() => {

        if (keyparams.selmodelkey !== "") {
            GenericBackendCall("", schema, paths.jobdescription.descmodelview, user, config,
                { type: "Full", modelkeys: [keyparams.selmodelkey] }).then(d => {
                    if (d.length > 0) {
                        setSelJDModel(d[0])
                    }
                })
        }
    }, [keyparams.selmodelkey])

    useEffect(() => {
        if (keyparams.refresh >= 1000) { window.location.reload() }
    }, [keyparams.refresh])

    const ClickDelete = () => {
        if (selJDModel) {
            GenericBackendCall(selJDModel.ModelKey, schema, paths.generics.genericdelete, user, config,
                { model: "DescModel", field: "ModelKey" }, "jdm_divdel").then(_ => {
                    setKeyParams({ ...keyparams, refresh: 999 })
                })
        }
    }

    return (<div className="ndt_canvas">
        <div className="ndt_title2" style={{ margin: '5px' }}>Job Descriptions</div>
        <StyleMainSelect base={<GenericDropdown
            data={jdmodels}
            keycol="ModelKey"
            namecol="ModelName"
            promptstring="Models"
            change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, jdmodels, keyparams, setKeyParams, "selmodelkey", "ModelKey", "") }}
            className="ndt_dropdown"
        />}
            delete={
                <button className='ndt_btn2' style={{ marginLeft: "4px" }} onClick={_ => { setKeyParams({ ...keyparams, isdeletevisible: true }) }}>Delete</button>
            }
            deleteCondition={keyparams.selmodelkey !== ""}
            highlight={selJDModel ? false : true}
        />

        <div style={{ marginLeft: "5px" }}>{selJDModel?.ModelName}</div>

        {keyparams.selmodelkey !== "" ?
            <div>
                <button className="ndt_btnmain qmv_btnopt" onClick={_ => setKeyParams({ ...keyparams, viewpage: "skillroster" })}>Skills Roster</button>
                <button className="ndt_btnmain qmv_btnopt" onClick={_ => setKeyParams({ ...keyparams, viewpage: "pipeline" })}>Pipeline</button>
                <button className="ndt_btnmain qmv_btnopt" onClick={_ => setKeyParams({ ...keyparams, viewpage: "categories" })}>Categories</button>

                <div style={{ marginTop: "5px" }}>
                    {(() => {
                        switch (keyparams.viewpage) {
                            case "skillroster": return <JobSkillRoster seljdmodel={selJDModel} jobdesc={jobdesc} jdmodels={jdmodels} jobtitles={jobtitles} jobskills={jobskills} roster={roster} />
                            case "pipeline": return <JobSkillPipeline seljdmodel={selJDModel} jobdesc={jobdesc} jdmodels={jdmodels} jobtitles={jobtitles} jobskills={jobskills} roster={roster} />
                            case "categories": return <JobSkillCategories seljdmodel={selJDModel} jobdesc={jobdesc} jdmodels={jdmodels} jobtitles={jobtitles} jobskills={jobskills} roster={roster} />

                            default: return <div></div>;
                        }
                    })()}
                </div>
            </div>
            : <div></div>}

        <Modal show={keyparams.isdeletevisible}>
            <div className="ndt_modal">
                <div style={{ padding: "10px" }}>
                    <div>Delete this JobDescription Model:</div>
                    <button className="ndt_btn2" onClick={_ => ClickDelete()}> Delete</button>
                    <div id="scb_divpathadd"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>
    </div>)
};
export default JobDescriptionBase