import Textwrap from "../functions/Textwrap";
import HoverData from "../functions/HoverData";
//import ToolTip from "../functions/ToolTip";
import TooltipDetail from "../functions/tooltip/TooltipDetail";
import DateString from "../../functions/Date Functions/DateString";
import DateRemoveTimestamp from "../../functions/Date Functions/DateRemoveTimestamp";

const BarChartStacked1 = (svg, data, detaildata, xcol, ycols, xgroups, groupcol, groups, colorScales, x, yscales, height, mina2, txtclr,
    useopacity, wait_period, width, hoverdetail, includeZeros) => {
    	
    let showing;

    let ycol = ycols[0]


    //--stacked bar
    let dvals = xgroups.map(_=> {return []});
    let dheights = xgroups.map(_=> {return []});
    let dvars = xgroups.map(_=> {return []});
    let dsums = xgroups.map(_=> {return 0});


    data.forEach(d => {
        let i = xgroups.indexOf(d[xcol])
        dsums[i] += +d[ycol]
        dheights[i].push(dsums[i])
        dvals[i].push(+d[ycol])
        dvars[i].push(d[groupcol])
    });


    function GetVal(d){
        let grp = xgroups.indexOf(d[xcol])
        let gvar = dvars[grp].indexOf(d[groupcol])      
        let rt = dvals[grp][gvar]

        return(rt)
    };
    function GetCumVal(d){
        let grp = xgroups.indexOf(d[xcol])
        let gvar = dvars[grp].indexOf(d[groupcol])    
        let rt = dheights[grp][gvar]
        return(rt)
    };

    svg.selectAll("rect")
        .data(data)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", function(d) { return x(DateString(DateRemoveTimestamp(d[xcol]))); })
        .style("fill", function(d) { if (groupcol){
                                return colorScales[0](groups.indexOf(d[groupcol])) 
                            } else { 
                                return colorScales[0](d[ycol])
                            } 
                        })
        .attr("width", x.bandwidth())
        .attr("y", function(_) { return yscales[0](mina2) })
        .attr("height", function(_) { return Math.max(0,height - yscales[0](mina2)); })

        if (hoverdetail){

            svg.selectAll("rect")
                .on("click", d=>{
                    if (!showing){
                        let hd = HoverData(d.target.__data__, detaildata, );
                        let popupelem = TooltipDetail(hd)
                        document.body.appendChild(popupelem)
                        showing = true;
                    } else {
                        try {
                            document.getElementById("bargraph_elempopup").remove()
                        } catch (e){

                        }
                        showing = false;
                    }
                })

        }
        
        const RoundValue = (val, dec) => {
            return (Math.round(val*Math.pow(10,dec)) / Math.pow(10,dec)).toFixed(dec)
        }

        svg.selectAll("rect")
        .transition()
            .duration(400)
            .attr("y",function(d) { 
                let cval = GetCumVal(d)
                return yscales[0](cval); 
            })
            .attr("height",function(d) { 
                    let hval = GetVal(d)
                    return height - yscales[0](hval); 
            })   

        svg.append("g").selectAll("text")
			.data(data)
			.enter().append("text")
			.attr("dy","1.3em")
			.attr("text-anchor","start")
			.attr("fill-opacity",0) //start blank
            .attr("fill",txtclr)
            .attr("x",function(d) { return x(DateString(DateRemoveTimestamp(d[xcol]))) + x.bandwidth() / 2 ; })
			.attr("y", function(d) { return yscales[0](GetCumVal(d)); })
			.text(function(d) { if (+d[ycol]!==0 || includeZeros){
                if (groupcol){
                    return  d[groupcol] + " - " + RoundValue(d[ycol],2)
                } else {
                        return RoundValue(d[ycol],2)}; 
                }
            })
            .call(Textwrap,width/xgroups.length-5,.2);

		//transition - text
		svg.selectAll("text")
			.transition()
			.duration(500)
			.attr("fill-opacity",useopacity)
            .attr("color","white")
			.attr("text-anchor","middle")
            .attr('class',"textclass1")
            .attr("mix-blend-mode","difference")//testing
			//.attr("y", function(d) {return y(GetVal(d)) - y(GetCumVal(d))/2; })
			.delay(function(_,i) { return (i*wait_period+200)}); 


};
export default BarChartStacked1;