
import { Dashboard, DashboardPage, Key_Name, QueryVisuals } from "../../../type";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from 'react';
import "./css/DashboardSelect.css";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import { StartupContext } from "../../../App";


const DashboardSelect = () => {

    const { config, dispatcher, schema, clickstyle, user, paths } = useContext(StartupContext)
    interface hoverdetail {
        DType: string; DashKey: string; NCharts: number; NPages: number; detail: Key_Name[], url: string;
    }

    let baseStyle = { background: "linear-gradient(15deg, rgb(207, 251, 255) 10%, rgb(100, 100, 240) 90%)" }
    let selStyle = { background: "linear-gradient(15deg, rgb(230, 241, 225) 10%, rgb(200, 200, 240) 90%)" }

    let [dashboards, setDashboards] = useState<Dashboard[]>([])
    let [hoverdet, setHoverdet] = useState<hoverdetail>({
        DType: '', DashKey: '', NCharts: 0, NPages: 0, detail: [], url: ''
    })

    useEffect(() => {
        ImportAndSetStateGeneric(setDashboards, "", schema, paths.dashboard.dashnameview, user, config, { permissions: ['All'] })
    }, [])


    const DashHoverDetail = (dash: Dashboard) => {
        console.log(dash)
        if (hoverdet.DashKey !== dash.Key) {
            let detail: Key_Name[] = []
            if (hoverdet.DType === "Charts") {
                dash.visuals.forEach((vis: QueryVisuals) => {
                    detail.push({ Key: vis.VisKey, Name: vis.VisName })
                })
            } else if (hoverdet.DType === "Pages") {
                dash.pages.forEach((pg: DashboardPage) => {
                    detail.push({ Key: String(pg.PageNo), Name: pg.PageName })
                })
            }
            let url = "/dashboard/live/" + dash.UrlString
            setHoverdet({ ...hoverdet, DashKey: dash.Key, url: url, NCharts: dash.visuals.length, NPages: dash.pages.length, detail: detail })
        }
    }

    return (
        <div className="ndt_canvas">
            <div className="ndt_title2">Dashboard Selector</div>
            <div className="ndt_gridbox" id="dashsel_main" style={{ height: "700px" }}>
                <div className="ndt_innerbox">
                    <div style={{ height: "600px", overflowY: "scroll" }}>
                        {dashboards.length === 0 ? <div style={{ fontSize: "20px", margin: "10px" }}>No dashboards created!</div> : <div></div>}

                        {dashboards.map((dash: Dashboard, i: number) => {
                            return (<div key={i}>

                                <div className="nav-link active"

                                    onClick={_ => { DashHoverDetail(dash) }}
                                    aria-current="page" >
                                    <div className="dashsel_dbobj" style={dash.Key === hoverdet.DashKey ? selStyle : baseStyle}>
                                        <div style={{ fontSize: "20px" }}>{dash.Name}</div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                    {hoverdet.DashKey !== "" ?
                        <Link to={hoverdet.url}>
                            <button className="ndt_btn1">Go to Dashboard</button>
                        </Link>
                        : <div></div>}
                </div>
                <div className="ndt_innerbox">
                    <div>
                        <div style={{ fontSize: "18px" }}>Detail</div>
                        <button className="ndt_btn1" style={hoverdet.DType === "Pages" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setHoverdet({ ...hoverdet, DType: "Pages" }) }}>Pages</button>
                        <button className="ndt_btn1" style={hoverdet.DType === "Charts" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setHoverdet({ ...hoverdet, DType: "Charts" }) }}>Charts</button>
                    </div>
                    <div>{ }</div>

                </div>
            </div>
        </div >
    )
};
export default DashboardSelect;