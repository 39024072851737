import { useEffect, useState, useContext, useMemo } from "react";
import GenericBackendCall from "../functions/Import/GenericBackendCall";
import { QueryVisuals, ColorModel, Metric, QueryModel, queryvisualstate, FieldMap, BenchModel, Benchmarkset, ChartSeries } from "../type";
import { StartupContext } from "../App";
import CreateChart from "./CreateChart";
import GetQueryRunDateKey from "../functions/QueryFunctions/GetQueryRunDateKey";
import ChartIcon from "./ChartIcon";

import "./ChartConst.css"
import ImportAndSetStateGeneric from "../functions/Import/ImportAndSetStateGeneric";
import RunDateUpdate from "../functions/QueryFunctions/RunDateUpdate";
// import IsInObject from "../functions/Object Functions/IsInObject";
// import DateString from "../functions/Date Functions/DateString";
import GetObjectField from "../functions/Object Functions/GetObjectField";


const ChartConst = (props: any) => {
    //ref: querymodelviewrun, querymodelviewinspector, categorymodelai, datasetwalkthrough

    let svgid: string = props.svgid;
    let query: QueryModel = props.query;
    let setQuery: any = props.setQuery; //added 12.20.23
    let qryvis: QueryVisuals | null = props.qryvis; //recently added
    let vis_state: queryvisualstate[] = props.vis_state;
    let setVis_State: any = props.setVis_State;
    let stateIdx: number = props.stateIdx;
    let style: any = props.style;
    let rundatekey: string = props.rundatekey
    let size: number[] = props.size
    let rerun: number = props.rerun;


    const { config, schema, user, paths } = useContext(StartupContext)
    const context = useContext(StartupContext)

    const [appliedColorModel, setAppliedColorModel] = useState<ColorModel | null>(null)
    const [selRunDate, setSelRunDate] = useState<string>("")
    const [asofdate, setAsofDate] = useState<Date>(new Date())
    const [fields, setFields] = useState<FieldMap[] | null>(null)
    const [metrics, setMetrics] = useState<Metric[]>([])
    const [benchmodels, setBenchmodels] = useState<BenchModel[] | null>(null)
    const [appliedbenchSets, setAppliedBenchSets] = useState<Benchmarkset[]>([])
    //let fields = useSelector((state: any) => state.fieldmapping)

    useEffect(() => {
        ImportAndSetStateGeneric(setMetrics, "", schema, paths.query.metricview, user, config)
        ImportAndSetStateGeneric(setFields, "", schema, paths.fields.fieldretrieve, user, config, {})
        GenericBackendCall("", schema, paths.benchmark.benchmodelview, user, config, { querykeys: [query.ModelKey] }).then(bm => {
            setBenchmodels(bm)
        })
    }, [])

    useEffect(() => {
        if (qryvis) {
            PullBenchmarkSets()
        }
    }, [qryvis?.VisKey])

    const PullBenchmarkSets = async () => {
        //copied from querymodelviewinspector
        if (qryvis && qryvis.chartseries.length > 0) {
            let ckeys: string[] = []
            qryvis.chartseries.forEach((cs: ChartSeries) => {
                if (cs.BenchmarkModelName !== "") {
                    ckeys.push(cs.BenchmarkModelName)
                }
            })

            await GenericBackendCall("", schema, paths.benchmark.benchmarksetview, user, config, { modelkeys: ckeys, asofdate: asofdate }).then(d => {
                setAppliedBenchSets(d)
            })
        }
    }

    useEffect(() => {
        if (qryvis) {
            let rd = rundatekey
            if (!rundatekey || rundatekey === "") {
                rd = GetQueryRunDateKey(qryvis, query.rundates)
            }
            RunDateUpdate(rundatekey, schema, query, setQuery, config).then(_ => {
                setSelRunDate(rundatekey)
            })
            let aod = GetObjectField(rundatekey, query.rundates, "DateKey", "AsofDate")
            setAsofDate(aod)
        }
    }, [qryvis, rundatekey])


    useEffect(() => {
        if (qryvis && fields && benchmodels) {
            //console.log("const rerun")
            CreateChart(query, qryvis, appliedColorModel, selRunDate, fields, metrics, benchmodels, appliedbenchSets,
                "#" + svgid, size, "", vis_state, setVis_State, stateIdx, context)
        }
    }, [vis_state, qryvis, benchmodels, selRunDate, fields, size, rerun])

    useEffect(() => {
        if (qryvis) {
            let colpromise = GenericBackendCall(qryvis.ColorModelName, schema, paths.colorsets.colormodelview, user, config, "")
            colpromise.then(c => { setAppliedColorModel(c[0]) })
        }
    }, [qryvis])


    const chartmemo = useMemo(() => {
        if (qryvis) {
            return (<div id={svgid}
                key={qryvis.VisKey}
                className="ndt_chart"
                style={{ ...style, height: size[1] + 50, width: size[0] + 50 }}
            >
                <div style={{ display: 'flex' }}>
                    <div style={{ width: "95%" }}>{qryvis.VisName} {qryvis.ChartTitle}</div>
                    <ChartIcon iconID={"icon" + qryvis.VisKey} vis_states={vis_state} setVis_States={setVis_State} int={stateIdx} />
                </div>
                <div id={"chart" + qryvis.VisKey} ></div>
            </div >)
        } else {
            return <div></div>
        }
    }, [qryvis?.VisKey])

    // const ApplyBenchmarkSet = () => {
    //     //need to apply benchmark I guess, 2.14.2014
    //     let bms: Benchmarkset[] = []
    //     if (benchmodels && benchmodels.length > 0) {
    //         benchmodels.forEach((bm: BenchModel) => {
    //             if (qryvis && IsInObject(qryvis.chartseries, bm.ModelKey, "BenchModelName")) {
    //                 let dateasof = GetObjectField(rundatekey, query.rundates, "Key", "AsofDate")
    //                 console.log(dateasof)
    //                 bm.benchmarkset.forEach((bs: Benchmarkset) => {
    //                     if (DateString(bs.CurrDate) === DateString(dateasof))
    //                 })
    //             }
    //         })
    //     }

    // }

    return (<div>{chartmemo}</div>)

};
export default ChartConst