import { Emp_State } from "../../../../type";
import CompareObjects from "../../../../functions/CompareObjects";
import DateString from "../../../../functions/Date Functions/DateString";


const EmpStateDetail = (props: any) => {

    let prevState: Emp_State = props.prevState;
    let currState: Emp_State = props.currState;
    let entryno: number = props.entryno;

    const CurrentCard = () => {
        return (
            <div id="cardmain">
                <div id="cardheader" > Entry No {entryno}</div>
                <div className="cardtext">
                    Date Entered: {DateString(currState.Date)}
                </div>
                <div className="cardtext">
                    Job Code: {currState.JobCode}
                </div>
                <div className="cardtext">
                    Location: {currState.Status}
                </div>
                <div className="cardtext">
                    Department ID: {currState.DeptID}
                </div>
            </div>
        )
    };

    const PreviousCard = () => {
        if (prevState) {
            let differences = CompareObjects(prevState, currState)
            return (
                <div id="cardsecondary">
                    Differences:
                    {differences.map((key: any, i: number) => {
                        return (
                            <div key={i} className="3433">
                                {key[0]}: {key[1]} {"->"} {key[2]}
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            return (<div></div>)
        }
    };
    return (
        <div >
            <CurrentCard />
            <PreviousCard />
        </div>
    )
};
export default EmpStateDetail;