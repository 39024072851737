import { useEffect, useState, useContext, ChangeEvent } from 'react'
import ImportAndSetStateGeneric from '../../../functions/Import/ImportAndSetStateGeneric';
import { StartupContext } from '../../../App';
import { TrackerItem } from '../../../type';
import GetUserEmail from '../../../functions/User Functions/GetUserEmail';
import GenericDropdown from '../../../functions/Dropdown_Generic';
import GetUniquesFromObjectAttribute from '../../../functions/GetUniquesFromObjectAttribute';

import "./css/TrackerPage.css"
import GenericBackendCall from '../../../functions/Import/GenericBackendCall';
import DateTimeString from '../../../functions/Date Functions/DateTimeString';
import { Modal } from 'react-bootstrap';
import CreateUniqueUserMap from '../../../functions/User Functions/CreateUniqueUserMap';


const TrackerPage = () => {

    let { config, schema, user, allusers, paths } = useContext(StartupContext)

    let [trackerfull, setTrackerFull] = useState<TrackerItem[]>([])
    let [uniqueTVals, setUniqueTVals] = useState<any[]>([])
    let [userLookup, setUserLookup] = useState<any[]>([])
    let [selData, setSelData] = useState<TrackerItem[]>([])
    let [selDetail, setSelDetail] = useState<TrackerItem | null>(null)
    let [params, setParams] = useState<any>({ filtfield: "", filtval: "", isdetailvisible: false, notes: "" })

    useEffect(() => {
        ImportAndSetStateGeneric(setTrackerFull, "*", schema, paths.tracker.trackerimport, user, config, { "field": params.filtfield, "value": params.filtval })
    }, []);

    useEffect(() => {
        //unique dict of id-to-email, to prevent over-rendering of getuseremail

        setUserLookup(CreateUniqueUserMap(trackerfull, "User", allusers))

    }, [trackerfull]);

    useEffect(() => {
        ImportAndSetStateGeneric(setSelData, "*", schema, paths.tracker.trackerimport, user, config, { "field": params.filtfield, "value": params.filtval })
    }, [params.filtval]);

    useEffect(() => {
        let vals: any[] = GetUniquesFromObjectAttribute(trackerfull, params.filtfield)
        let dta: any[];

        if (params.filtfield == "User") {
            dta = vals.map((v: string) => { return { "Name": GetUserEmail(v[0], allusers) } })
        } else {
            dta = vals.map((v: string) => { return { "Name": v[0] } })
        }
        setUniqueTVals(dta)
    }, [params.filtfield]);

    const DeleteTrackerData = () => {
        GenericBackendCall("*", schema, paths.tracker.trackerdelete, user, config, { "field": params.filtfield, "value": params.filtval }, "tp_divdelete")
    }

    const SaveChanges = () => {
        if (selDetail) {
            console.log("changes")
            GenericBackendCall(String(selDetail.id), schema, paths.tracker.trackerupdate, user, config, { "notes": params.notes }, "tp_divchange")
        }
    }

    const ClickItem = (d: TrackerItem) => {
        setParams({ ...params, isdetailvisible: true })
        setSelDetail(d)
    }

    return (<div>
        <div>

            <div id="tp_header">
                <div className="ndt_title1">Tracker</div>
                <div id="tp_headeroptns" >
                    <div style={{ fontSize: "20px", marginTop: '5px' }}>Filter:</div>
                    <select style={{ marginLeft: '10px', marginRight: '10px', width: "160px" }} title="Filter By" onChange={(e: ChangeEvent<HTMLSelectElement>) => setParams({ ...params, filtfield: e.target.selectedOptions[0].attributes[0].value })}>
                        <option>Select Filter Field</option>
                        <option data-value="User">User</option>
                        <option data-value="ModelCategory">Data Type</option>
                        <option data-value="ModelName">Model</option>
                    </select>
                    {params.filtfield !== "" ?
                        <GenericDropdown
                            data={uniqueTVals}
                            change={(e: ChangeEvent<HTMLSelectElement>) => setParams({ ...params, filtval: e.target.selectedOptions[0].attributes[1].value })}
                            keycol="Name"
                            namecol="Name"
                            includeDefault={false}
                            promptstring="Select Value"
                            divID="tp_ddown1"

                        />
                        : <div></div>}
                    {selData.length > 0 ? <button onClick={_ => DeleteTrackerData()}>Delete Visible Data</button> : <div></div>}
                    <div id="tp_divdelete"></div>
                </div>
                showing {selData.length} of {trackerfull.length} rows
            </div>
        </div>
        {selData.length > 0 ?
            <div>
                <div className="tp_datarow">
                    <div className="tp_headercell">User</div>
                    <div className="tp_headercell">Data Type</div>
                    <div className="tp_headercell">Model Name</div>
                    <div className="tp_headercell">Date</div>
                    <div className="tp_headercell">Notes</div>
                </div>
                {
                    selData.map((d: TrackerItem, i: number) => {
                        let notesreduced = d.Notes.substring(0, 60)
                        d.Notes.length > 60 ? notesreduced = notesreduced + "..." : notesreduced = notesreduced
                        return (<div key={i} className="tp_datarow"
                            onClick={(_) => ClickItem(d)}>
                            <div className="tp_tblcell">{userLookup[Number(d.User)]}</div>
                            <div className="tp_tblcell">{d.ModelCategory} </div>
                            <div className="tp_tblcell">{String(d.ModelName)}</div>
                            <div className="tp_tblcell">{DateTimeString(d.Timestamp)} </div>
                            <div className="tp_tblcell">{notesreduced}</div>
                        </div>
                        )
                    })
                }
            </div>
            : <div></div>}

        <Modal show={params.isdetailvisible}>
            Item Notes
            {selDetail ?
                <div>
                    <textarea id="tp_mdl_text" defaultValue={selDetail?.Notes} onChange={e => { setParams({ ...params, notes: e.target.value }) }}></textarea>
                    <button onClick={_ => SaveChanges()}>Save</button>
                    <div id="tp_divchange"></div>
                </div>
                : <div></div>}
            <button className="closemodalbtn" onClick={_ => setParams({ ...params, isdetailvisible: false })}>Close</button>
        </Modal>

    </div >)
};
export default TrackerPage