
import "./css/StyleMainSelect.css"
import { useEffect, useState } from "react";
import "./css/StylePrimary.css"

const StyleMainSelect = (props: any) => {
    //top, primary select dropdown
    //ref: scenariobuilder,  connectionmanager, dashboardmanager, jobdescriptionsbase
    //statuschecker, 
    let baseComponent = props.base
    let deleteComponent = props.delete
    let deleteCondition: any | null = props.deleteCondition
    let highlight: boolean = props.highlight


    let [collapsed, setCollapsed] = useState<boolean>(true)
    //let [state, setState] =  useState<number>(0)
    const ChangeCollapse = () => {
        setCollapsed(!collapsed)
    }
    let statedisplay = "Close"
    if (collapsed) {
        if (highlight) {
            statedisplay = "Begin"
        } else {
            statedisplay = "Model"
        }
    }

    return (<div>
        <div className="ndt_stlmainselect" >
            <input type="checkbox" id="collapsible-checkbox" hidden />
            <div id="stlcollapsible-divS" >
                <div className="ndt_sideXborder">
                    <div className="ndt_sideXoffset">
                        <div className="ndt_sideXinner">
                            <label htmlFor="collapsible-checkbox"
                                className={highlight && collapsed ? "stltoggleLabel stlLabelS stlcollapseHighlight" : "stltoggleLabel stlLabelS"}
                                onClick={_ => ChangeCollapse()}>
                                {statedisplay}
                            </label>

                            <div style={{ display: 'flex' }}>
                                <div>{baseComponent}</div>
                                {deleteCondition ?
                                    <div>{deleteComponent}</div>
                                    : <div></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)

};
export default StyleMainSelect