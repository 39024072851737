import AxiosUpload from '../AxiosUpload';


const ColorModelUpload = async (modelname: string, schemakey: string, config: any, dispatcher: any, elementstring: string) => {

    let dataArray = new FormData();

    //configure filterdata, take from empgraphs' querymodel upload
    dataArray.append("modelname", modelname);
    dataArray.append("schema", schemakey);


    let elem = document.getElementById(elementstring);
    if (elem) { elem.innerHTML = "Uploading..." };

    //post
    await AxiosUpload(dataArray, "api/colorsets/colormodelpost", config, elem, "")

    return ("Ok")
};

export default ColorModelUpload;