import { useState, useEffect, useContext, ChangeEvent } from "react";
import { JobPosting, JobPostingRequirement, Applicant, ApplicantSkill, Resume, AppSkillValue, Application } from "../../../type";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import { StartupContext } from "../../../App";
import { Modal } from "react-bootstrap";
import GenericDropdown from "../../../functions/Dropdown_Generic";
import ChangeParamSelectorbyIndex from "../../../functions/ElementSelect/ChangeParamSelectorbyIndex";
import './css/JobPostingView.css'
import StringTrim from "../../../functions/String Functions/StringTrim";
import GetObjectValue from "../../../functions/Object Functions/GetObjectValue";
import ChangeSelectorbyIndex from "../../../functions/ElementSelect/ChangeSelectorbyIndex";
import GetObjectField from "../../../functions/Object Functions/GetObjectField";


const JobPostingView = () => {

    const { config, dispatcher, schema, appcolors, user, paths, clickstyle } = useContext(StartupContext)

    interface paramtype {
        view: string;
        requirementtype: string;
        postingfile: any | null;
        applfile: any | null;
        qualsfile: any | null;
        filename: string;
        asofdate: Date;
        postingname: string;
        req: string;
        desc: string;
        postingkey: string;
        selrequirement: string;
        resumeview: string;
        matchcount: number;
        issubmitpostingvisible: boolean;
        issubmitresumesvisible: boolean;
        issubmitnewpostingvisible: boolean;
        issubmitqualsvisible: boolean;
        isfileuplconvvisible: boolean;
        isdeletevisible: boolean;
        refresh: number;
    }

    let [jobpostings, setJobPostings] = useState<JobPosting[]>([])
    let [selJobPosting, setSelJobPosting] = useState<JobPosting | null>(null)
    let [keyparams, setKeyParams] = useState<paramtype>({
        view: '', postingfile: null, applfile: null, qualsfile: null, filename: '', asofdate: new Date(), requirementtype: '',
        postingkey: '', postingname: '', req: '', desc: '', selrequirement: '', resumeview: 'summary', matchcount: 0,
        issubmitpostingvisible: false, issubmitresumesvisible: false, issubmitqualsvisible: false,
        isdeletevisible: false, issubmitnewpostingvisible: false, isfileuplconvvisible: false, refresh: 0
    })
    let [applicants, setApplicants] = useState<Applicant[]>([])
    let [selApplicant, setSelApplicant] = useState<Applicant | null>(null)
    let [selApplication, setSelApplication] = useState<Application | null>(null)
    let [selFiles, setSelFiles] = useState<any[]>([])


    const styleOn = { backgroundColor: "rgb(70,130,50)", border: '1px solid blue', borderRadius: '10px', padding: "2px", paddingLeft: "5px" }
    const styleOff = { padding: "2px", paddingLeft: "5px" }
    let appstyleOn = { backgroundColor: "rgb(60,50,150)" }


    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams.refresh])

    useEffect(() => {
        GenericBackendCall('', schema, paths.application.jobpostingview, user, config, { type: "name" }).then(d => { console.log(d); setJobPostings(d) })
    }, [])

    useEffect(() => {
        if (keyparams.postingkey !== "" && keyparams.postingkey !== "_") {
            GenericBackendCall('', schema, paths.application.jobpostingview, user, config, { modelkeys: [keyparams.postingkey], type: "full" }).then(d => {
                if (d.length === 1) {
                    setSelJobPosting(d[0])
                    console.log(d[0])
                }
            })
            GenericBackendCall('', schema, paths.application.applicantview, user, config, { jobpostingkey: keyparams.postingkey }).then(d => {
                setApplicants(d)
            })
            setKeyParams({ ...keyparams, view: "noview" })
        } else if (keyparams.postingkey === "_") {
            setSelJobPosting(null)
            setApplicants([])
            setSelFiles([])
            setKeyParams({ ...keyparams, view: "new" })
        } else {
            setSelJobPosting(null)
            setApplicants([])
            setSelFiles([])
            setKeyParams({ ...keyparams, view: "" })
        }
    }, [keyparams.postingkey])


    useEffect(() => {
        console.log(selApplication)
    }, [selApplication?.AppKey])

    useEffect(() => {
        setSelApplication(null)
        console.log(selApplicant)
    }, [selApplicant])

    const FileLoad = (divID: string, paramstring: string) => {
        //let elem1: any = document.getElementById("jpv_filejobposting")
        //"jpv_fileappl"
        let elem: any = document.getElementById(divID)
        let dfile = elem.attributes[0].ownerElement?.files[0]
        setKeyParams({ ...keyparams, [paramstring]: dfile })

    }

    //-------------------------------------
    const ClickSaveNew = () => {
        GenericBackendCall("", schema, paths.application.jobpostingpost, user, config, {
            postingname: keyparams.postingname, req: keyparams.req, desc: keyparams.desc,
            asofdate: keyparams.asofdate
        }, 'jpv_uplnewdiv').then(_ => {
            setKeyParams({ ...keyparams, refresh: 99 })
        })
    }

    const ClickSubmitFile_JobPosting = () => {
        if (selJobPosting) {
            GenericBackendCall("", schema, paths.application.jobpostingrequirementspost, user, config, {
                jobpostkey: selJobPosting.PostingKey, data: keyparams.postingfile
            }, 'jpv_upldivpost').then(_ => {
                setKeyParams({ ...keyparams, refresh: 99 })
            })
        }
    }

    const ClickSubmitFile_Resume = () => {
        GenericBackendCall("", schema, paths.application.applicationpost, user, config, {
            postingkey: keyparams.postingkey, req: keyparams.req, desc: keyparams.desc,
            data: keyparams.applfile, asofdate: keyparams.asofdate
        }, 'jpv_upldivres').then(_ => {
            setKeyParams({ ...keyparams, refresh: 99 })
        })
    }


    const ClickSubmitFile_Qualifications = () => {
        GenericBackendCall("", schema, paths.application.qualificationspost, user, config, {
            postingkey: keyparams.postingkey, req: keyparams.req, desc: keyparams.desc,
            data: keyparams.qualsfile, asofdate: keyparams.asofdate
        }, 'jpv_upldivqual').then(_ => {
            setKeyParams({ ...keyparams, refresh: 99 })
        })
    }


    const ClickDelete = () => {
        if (keyparams.postingkey !== "") {
            GenericBackendCall(keyparams.postingkey, schema, paths.generics.genericdelete, user, config,
                { model: "JobPosting", field: "PostingKey" }, "jpv_deldiv").then(_ => {
                    setKeyParams({ ...keyparams, refresh: 99 })
                })
        }
    }

    const ClickUploadFiles = () => {
        if (keyparams.postingkey !== "") {
            GenericBackendCall(keyparams.postingkey, schema, paths.application.resumepost, user, config,
                { files: selFiles }, "jpv_convdiv").then(_ => { setKeyParams({ ...keyparams, refresh: 99 }) })
        }
    }
    //------------------------------------



    const SubViewPostingAppls = () => {
        return (
            <div id="jpv_viewgrid" style={{ height: "430px" }}>
                <div className="ndt_subinner" >
                    <div style={{ display: "flex" }} id="jpv_detailoptbox">
                        <div style={{ fontSize: "20px", marginRight: "10px", marginTop: '2px' }}>Job Posting Details</div>
                        <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, requirementtype: "Responsibility" }) }}>Responsibility</button>
                        <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, requirementtype: "Qualification" }) }}>Qualification</button>
                        <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, requirementtype: "Education" }) }}>Education</button>
                        <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, requirementtype: "Experience" }) }}>Experience</button>
                        <div style={{ margin: '5px', fontSize: "18px" }}>{keyparams.requirementtype}</div>
                    </div>
                    <div  >
                        <div style={{ marginTop: '5px', height: "350px", overflowY: "scroll" }}>
                            {selJobPosting?.requirements.map((req: JobPostingRequirement, i: number) => {
                                if (req.RequirementType === keyparams.requirementtype) {
                                    let useStyle = {}
                                    if (selApplication) {
                                        let isqual = GetObjectField(req.RequirementKey, selApplication.skills, "JobPostingRequirementName", "IsQualified")
                                        if (isqual) {
                                            useStyle = { backgroundColor: "rgb(40,90,50)" }
                                        } else {
                                            useStyle = { backgroundColor: "rgb(90,40,50)" }
                                        }

                                    }
                                    return (<div key={i} className="jpv_requireitm"
                                        style={keyparams.selrequirement === req.RequirementKey ? appstyleOn : useStyle}
                                        onClick={_ => { setKeyParams({ ...keyparams, selrequirement: req.RequirementKey }) }}>
                                        <div style={{ width: '80%' }}>{req.Description}</div>
                                        {req.RequirementType === "Experience" ?
                                            <div>Years Exp: {req.YearsExperience}</div> : <div></div>}
                                    </div>)
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div id="jpv_applbox" className="ndt_subinner" style={{ marginLeft: "10px" }}>
                    <div style={{ marginLeft: "10px", fontSize: "20px", height: "35px" }}>Applicants</div>

                    <div style={{ height: "350px", minWidth: "400px", width: '25%', overflowY: "scroll" }}>
                        {applicants.map((apl: Applicant, i: number) => {
                            if (apl.applications.length > 0) {
                                return (<div key={i} className="jpv_aplitm"
                                    style={selApplicant && selApplicant.id === apl.id ? clickstyle.btnSelected : clickstyle.btnUnselected}
                                    onClick={_ => { setSelApplicant(apl) }}>
                                    <div style={{ width: '210px', height: "30px", overflow: "hidden" }}>{apl.Name}</div>
                                    <div>{apl.applications[0].QualificationLevel} qualified</div>
                                </div>)
                            }
                        })}
                    </div>
                </div>

            </div>
        )
    }


    const SubViewSkills = () => {
        return (
            <div>
                {selApplicant ?
                    <div id="jpv_skillsmain">
                        <div style={{ margin: "5px" }}>
                            <GenericDropdown
                                data={selApplicant.applications}
                                keycol="AppKey"
                                namecol="AppKey"
                                change={(e: ChangeEvent<HTMLSelectElement>) => { if (selApplicant) { ChangeSelectorbyIndex(e, selApplicant.applications, setSelApplication, "", null) } }}
                                promptstring="Select Resume or show skills"
                                defaultstring="Show Skills"
                                includeDefault={true}
                                divID="jpv_applskillddown"
                                className="ndt_dropdown"
                            />
                        </div>
                        {selApplication ?
                            <div>
                                <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, resumeview: "summary" }) }}>Summary</button>
                                <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, resumeview: "text" }) }}>Resume Text</button>

                                {keyparams.resumeview === "summary" ?
                                    <div>
                                        <div style={{ fontSize: "19px" }}>Summary: {selApplication.QualificationLevel} Qualified</div>
                                        <div style={{ fontSize: "18px" }}>{selApplication.QualificationDesc}</div>
                                        <div style={{ backgroundColor: "rgb(10,10,10)", padding: '3px' }}>
                                            <div style={{ fontSize: "18px" }}>Requirement Script:</div>
                                            {selJobPosting?.requirements.map((req: JobPostingRequirement, i: number) => {
                                                if (req.RequirementKey === keyparams.selrequirement) {
                                                    return (<div key={i} >
                                                        <div >{req.Description}</div>
                                                    </div>)
                                                }
                                            })}
                                        </div>
                                        {keyparams.selrequirement !== "" ?
                                            <div className="ndt_subinner">{selApplication.skills.map((as: ApplicantSkill, j: number) => {

                                                if (as.JobPostingRequirementName === keyparams.selrequirement) {
                                                    if (as.IsQualified) {
                                                        return (<div key={j}>Match: {as.QualificationDesc}</div>)
                                                    } else {
                                                        return (<div key={j}>N/A</div>)
                                                    }

                                                }
                                            })}
                                            </div>
                                            : <div>Select Requirement</div>}
                                    </div>
                                    : <div>
                                        <div style={{ fontSize: "20px" }}>Experience:</div>
                                        <div style={{ height: "300px", overflowY: "scroll" }}>{selApplication.Experience}</div>
                                    </div>}
                            </div> :
                            <div>
                                <div style={{ marginLeft: "20px", fontSize: "20px" }}> - Skills ({selApplicant.Name}): </div>

                                <div id="jpv_skillsbox">

                                    <div id="jpv_skillssub" style={{ height: "350px", overflowY: "scroll" }}>
                                        {selApplicant.skillvalues.map((asv: AppSkillValue, i: number) => {
                                            return (<div key={i} >
                                                <div style={asv.IsRelevant ? styleOn : styleOff}>{asv.SkillName}</div>
                                            </div>)
                                        })}
                                    </div>
                                    <div style={{ marginLeft: "10px" }}>
                                        <div style={{ fontSize: "20px" }}> Overview: </div>
                                        <div style={{ height: "100px", overflowY: "scroll" }} >
                                            <div>{selApplicant?.applications[0].QualificationDesc}</div>
                                        </div>
                                        <div style={{ fontSize: "20px", marginTop: "10px" }}>Primary Skills</div>
                                        <div style={{ height: "200px", overflowY: "scroll" }} >
                                            {selApplicant?.skills.map((askl: ApplicantSkill, j: number) => {
                                                if (askl.SkillDesc !== "") {
                                                    return (<div key={j} className="jpv_aplskl">{askl.SkillName}: {askl.SkillDesc}</div>)
                                                }
                                            })}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        }
                    </div> : <div></div>
                }
            </div >
        )
    }

    const ViewImport = (props: any) => {

        let importtype = props.importtype
        let filename = props.filename
        let divID = props.divID
        let paramstring = props.paramstring;
        let count = props.count;

        return (<div style={{ height: "300px", padding: '30px' }} className="ndt_innerbox">
            <div style={{ fontSize: "19px", marginBottom: '5px' }}>
                Import {importtype}
            </div>
            <input type="file" id={divID} onChange={_ => FileLoad(divID, paramstring)} />
            {filename}
            <div>Uploaded: {count}</div>

            {GetObjectValue(keyparams, paramstring) ?
                <div style={{ marginTop: "10px" }}>
                    <button className="ndt_btn1" onClick={_ => {
                        if (importtype === "Job Posting") {
                            setKeyParams({ ...keyparams, issubmitpostingvisible: true })
                        } else if (importtype === "Applications") {
                            setKeyParams({ ...keyparams, issubmitresumesvisible: true })
                        } else if (importtype === "Qualifications") {
                            setKeyParams({ ...keyparams, issubmitqualsvisible: true })
                        }
                    }}>Submit File</button>
                </div> : <div></div>}

        </div>)
    }
    const ViewView = () => {

        return (<div>

            <div >
                <SubViewPostingAppls />
                <SubViewSkills />
            </div>
        </div>)
    }

    const JobPostingDropdown = () => {
        return (
            <GenericDropdown
                data={jobpostings}
                keycol="PostingKey"
                namecol="PostingName"
                change={(e: ChangeEvent<HTMLSelectElement>) => { ChangeParamSelectorbyIndex(e, jobpostings, keyparams, setKeyParams, "postingkey", "PostingKey", "_") }}
                promptstring="Select Job Posting"
                className="ndt_dropdown"
                includeDefault={true}
                divID="jpv_ddown"
                defaultstring="Create New Posting"
            />
        )
    }

    const SelFiles = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let allfiles: any[] = []
            Object.keys(e.target.files).map((k: any) => {
                if (e.target.files) {
                    allfiles.push(e.target.files[k])
                }
            })
            setSelFiles(allfiles)
        }
    }

    return (<div className="ndt_canvas">
        <div className="ndt_gridbox ndt_header">
            <div className="ndt_title2">Job Posting View</div>

            <div style={{ margin: "5px", display: "flex", fontSize: "19px" }}>
                <JobPostingDropdown />
                <div style={{ fontSize: "18px", minWidth: "350px", height: "30px", marginLeft: "10px", marginTop: '3px', overflow: "hidden" }}>
                    Selected: {StringTrim(selJobPosting?.PostingName, 150)}</div>
                <div style={{ marginLeft: "5px" }}>
                    {keyparams.postingkey.length > 2 ?
                        <button className="ndt_btn2" style={{ marginLeft: "5px" }}
                            onClick={_ => { setKeyParams({ ...keyparams, isdeletevisible: true }) }}>Delete</button>
                        : <div></div>}
                </div>

                {selJobPosting ?
                    <div style={{ marginLeft: "25%", display: "flex" }}>
                        <button id="jpvoptbtnimport" className="ndt_btnmain jpvoptbtn" style={keyparams.view === "import" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setKeyParams({ ...keyparams, view: "import" }) }}>Import</button>
                        <button id="jpvoptbtnview" className="ndt_btnmain jpvoptbtn" style={keyparams.view === "view" ? clickstyle.btnSelected : clickstyle.btnUnselected} onClick={_ => { setKeyParams({ ...keyparams, view: "view" }) }}>View</button>
                    </div>
                    : <div>

                    </div>}
            </div>
        </div>

        <div className="ndt_gridbox">
            <div style={{ marginBottom: "5px", display: "flex" }}>

            </div>
            {(() => {
                switch (keyparams.view) {
                    case 'import': return <div>
                        <div id="jpv_importgrid">
                            <ViewImport importtype={"Job Posting"} filename="" divID="jpv_filejobposting" paramstring="postingfile" count={selJobPosting?.requirementcount} />
                            <div className="ndt_innerbox" style={{ padding: '15px' }}>
                                <div style={{ "fontSize": "20px" }}>Import Resumes to conversion queue</div>
                                <input type="file" id="ctrl" multiple onChange={(e: ChangeEvent<HTMLInputElement>) => { SelFiles(e) }} />
                                <div>Pending: {selJobPosting?.PendingResumes}</div>
                                {selFiles.length > 0 ? <div>
                                    <div>Upload {selFiles.length} Files?</div>
                                    <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, isfileuplconvvisible: true }) }}>Upload</button>
                                </div> : <div></div>}
                            </div>

                            <ViewImport importtype={"Applications"} filename="" divID="jpv_fileappl" paramstring="applfile" count={selJobPosting?.applicationcount} />
                            <ViewImport importtype={"Qualifications"} filename="" divID="jpv_filequals" paramstring="qualsfile" count={selJobPosting?.qualificationcount} />

                        </div>
                    </div>;
                    case 'view': return <ViewView />;
                    case 'new': return <div>
                        <div style={{ fontSize: "20px" }}>Create New Posting Manually:  </div>
                        <div style={{ height: "150px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "150px" }}>Posting Title:</div>
                                <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, postingname: e.target.value }) }} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "150px" }}>Posting Req #:</div>
                                <input type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => { setKeyParams({ ...keyparams, req: e.target.value }) }} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "150px" }}>Description:</div>
                                <textarea style={{ width: "400px" }} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setKeyParams({ ...keyparams, desc: e.target.value }) }} />
                            </div>
                            {keyparams.postingname !== "" ?
                                <button className="ndt_btn1" onClick={_ => { setKeyParams({ ...keyparams, issubmitnewpostingvisible: true }) }}>Submit</button>
                                : <div></div>}

                        </div>
                    </div>
                    case 'noview': return <div>
                        Select View
                    </div>
                    default: return <div>
                        No Posting Selected
                    </div>;
                }
            })()}
        </div>

        {/* Submit Job Posting */}
        <Modal show={keyparams.issubmitnewpostingvisible} >
            <div className="ndt_modal" >
                <div>
                    <div className="ndt_modaltitle">Save New Job Posting?</div>

                    <div>{keyparams.postingname}</div>
                    <button className="ndt_btn1" onClick={_ => { ClickSaveNew() }}>Submit</button>
                    <div id="jpv_uplnewdiv"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issubmitnewpostingvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal >

        <Modal show={keyparams.issubmitpostingvisible} >
            <div className="ndt_modal" >
                <div>
                    <div className="ndt_modaltitle">Save Job Posting Data</div>

                    <button className="ndt_btn1" onClick={_ => { ClickSubmitFile_JobPosting() }}>Submit</button>
                    <div id="jpv_upldivpost"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issubmitpostingvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal >


        <Modal show={keyparams.issubmitqualsvisible} >
            <div className="ndt_modal" >
                <div>
                    <div className="ndt_modaltitle">Save Qualifications</div>

                    <button className="ndt_btn1" onClick={_ => { ClickSubmitFile_Qualifications() }}>Submit</button>
                    <div id="jpv_upldivqual"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issubmitqualsvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal >


        {/* Submit Resumes */}
        <Modal show={keyparams.issubmitresumesvisible} >
            <div className="ndt_modal">
                <div>
                    <div className="ndt_modaltitle">Save Applications To:</div>
                    <div style={{ height: "150px" }}>
                        <div id="jpv_postcard">
                            <div style={{ fontSize: "18px" }}>{selJobPosting?.PostingName}</div>
                            <div>{selJobPosting?.Requisition}</div>
                            <div>{selJobPosting && selJobPosting?.requirements.length > 0 ? <div>
                                {StringTrim(selJobPosting?.requirements[0].Description, 150)}
                            </div> : <div></div>}</div>
                        </div>
                    </div>
                    {selJobPosting ?
                        <button className="ndt_btn1" onClick={_ => { ClickSubmitFile_Resume() }}>Submit</button>
                        : <div></div>}
                    <div id="jpv_upldivres"></div>
                </div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issubmitresumesvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal >

        <Modal show={keyparams.isdeletevisible}>
            <div className="ndt_modal">
                <div>Delete Job Posting: {selJobPosting?.Requisition}: {selJobPosting?.PostingName}</div>
                <button className="ndt_btn2" onClick={_ => { ClickDelete() }}>Delete</button>
                <div id="jpv_deldiv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdeletevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

        <Modal show={keyparams.isfileuplconvvisible}>
            <div className="ndt_modal">
                <div>Upload Resume Files for conversion</div>
                <button className="ndt_btn1" onClick={_ => { ClickUploadFiles() }}>Upload</button>
                <div id="jpv_convdiv"></div>
                <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isfileuplconvvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
            </div>
        </Modal>

    </div>)
};
export default JobPostingView