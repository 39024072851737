import { QueryVisuals } from "../../type"
import ObjectSort from "../ObjectSort";
import ConvertBackendArryString from "../String Functions/ConvertBackendArryString";


const QueryVisualsPrepare = (qryvis: QueryVisuals, setfn: any) => {
    //ref dashboardview, querymodelviewvisual, querymodelviewrun, querymodelviewinspector
    let fltarr = ConvertBackendArryString(String(qryvis.FilterValues))
    let valarr = ConvertBackendArryString(String(qryvis.FilterValues))
    let newcs = ObjectSort(qryvis.chartseries, "MetricOrder")
    if (setfn) {
        setfn({ ...qryvis, chartseries: newcs, FilterValues: valarr, FilterFields: fltarr })
    }


    return { ...qryvis, chartseries: newcs, FilterValues: valarr, FilterFields: fltarr }

};
export default QueryVisualsPrepare;