import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/////////
const otherInit: any = {
	tutorial: false,
};


export const otherSlice = createSlice({
	name: 'other_action',
	initialState: otherInit,
	reducers: {
		values: (state, { payload }: PayloadAction<{
			tutorial: boolean
		}>) => {
			return {
				tutorial: payload.tutorial,
			}
		},

	}
});


//---manually export action creators
export const {
	values: otherActionCreator
} = otherSlice.actions;

