import RoundValue from "./RoundValue";


function CheckRoundedEquivalency(num1, num2, n_places=7){
        if (RoundValue(num1, n_places) - RoundValue(num2, n_places)==0){
                return true
        } else {
                return false;
        }
}


export default CheckRoundedEquivalency;