
const StaffingModelManager = () => {

    return (<div >
        Staffing Model Manager


        <div>
            Predict/Account for Absences

            Predict/Account for Turnover
        </div>
    </div>)
};
export default StaffingModelManager