import ForceBubble from "./ForceBubble";
import { QueryModel, ColorModel, queryvisualstate, QueryVisuals, BasicQuery, FieldMap, ChartSeries, Metric, Benchmarkset, Benchmark, BenchModel, Key_Name } from "../type";
import RunDateQueryParse from "../functions/QueryFunctions/RunDateQueryParse";
import CreateTableD3 from "./newfunctions/CreateTableD3";
import AssignColorScheme from "./AssignColorScheme";
import ChartPrimary from "./ChartPrimary";
import FilterDatabyField from "../functions/Filter Functions/FilterDataByField";
import GetObjectfromArray from "../functions/Array Functions/GetObjectfromArray";
import GetFieldAttrMCat from "../functions/FieldMap Functions/GetFieldAttrMCat";
import FilterData from "../functions/FilterData";
import SVGMouseFunctions from "./SVGMouseFunctions";
import PivotDataFrame from "../functions/PivotDataFrame";
import CreateColorScale from "./newfunctions/CreateColorScale";
import ConvertBackendArryString from "../functions/String Functions/ConvertBackendArryString";
import PivotArray from "../functions/PivotArray";
import ThreeDBarGraph from "./ThreeDBarGraph";
import FilterDataMultiple from "../functions/Filter Functions/FilterDataMultiple";
import GetHeaderNames from "./newfunctions/GetHeaderNames";
import ChartDelete from "./newfunctions/ChartDelete";



interface valformat {
    format: string; //blank or pct
    rounding: number;
}

const CreateChart = (query: QueryModel, qryvis: QueryVisuals, colormdl: ColorModel | null,
    rundatekey: string, fields: FieldMap[], metrics: Metric[], benchmodels: BenchModel[], benchmarksets: Benchmarkset[], svgid: string, size: number[], iconID: string,
    vis_state: queryvisualstate[], setVis_State: any, stateIdx: number, context: any) => {
    //ref:dashboardviewer, querymodelviewvisuals, chartconst, querymodelview3D

    //console.log(query)
    //console.log(rundatekey)
    //console.log(qryvis.chartseries)
    //console.log(qryvis)
    //console.log(qryvis.colors)
    //console.log(vis_state)

    let iconelem = document.getElementById(iconID);
    ChartDelete(svgid)


    let qrymetrics = ConvertBackendArryString(query.MetricOrder)

    let chartpars: string[] = ["Variable"]

    let groupexists = true;
    //let groupfieldname: any = query.GroupFieldName;
    let forcegrouping = false;
    let forcegroupingname = query.GroupFieldName;

    if (query.GroupFieldName === null) {
        groupexists = false
        //groupfieldname = null
        if (query.Metrics.length === 1) { //manufacture group, for coloring
            forcegroupingname = query.XFieldName
            forcegrouping = true;
        }
    } else {
        chartpars.push("Grouping")
    }

    let show = vis_state[stateIdx].show
    let yfield = "ValueCalc"
    if (iconelem) {
        if (show === "table") {
            iconelem.className = "fa fa-line-chart"
        } else {
            iconelem.className = "fa fa-table"
        }
    }

    if (query && rundatekey !== "") {
        let [simpledata, detaildata] = RunDateQueryParse(query, rundatekey)
        let [simpledatafilt_d, detaildatafilt_d] = ChartFilterDefault(simpledata, detaildata, qryvis.FilterValues)
        let [simpledatafilt, detaildatafilt] = ChartDataFilter(simpledatafilt_d, detaildatafilt_d, vis_state, fields, stateIdx)

        simpledatafilt = ChartDataCalculate(simpledatafilt, qryvis.chartseries, query, false, groupexists)
        detaildatafilt = ChartDataCalculate(detaildatafilt, qryvis.chartseries, query, true, groupexists)
        simpledatafilt = ApplyBenchmarksets(simpledatafilt, qryvis.chartseries, benchmarksets)


        if (forcegrouping) {
            // simpledatafilt = ForceGroup(simpledatafilt)
        }

        let formats: valformat[] = []
        let metrickeys: string[] = []

        let benchmarkidxs: number[] = []
        let includebenchmarks: Key_Name[] = []


        qryvis.chartseries.forEach((cs: ChartSeries) => {
            let fmt = ""
            if (cs.Calculation === "PctofTotal") {
                fmt = "pct"
            } else if (cs.LabelFormat === "Percent") {
                fmt = 'pct'
            }

            formats.push({ format: fmt, rounding: cs.LabelRounding })
            if (!cs.IsHidden) {
                metrickeys.push(cs.MetricName)
                if (cs.BenchmarkMode !== "None") {
                    benchmarkidxs.push(cs.MetricOrder)
                    includebenchmarks.push({ Key: cs.MetricName, Name: cs.BenchmarkModelName })
                    formats.push({ format: fmt, rounding: cs.LabelRounding })
                    // } else {
                    //     includebenchmarks.push("")
                    // }
                }
            }
        })

        //for table horizontal only rn
        const MatchMetricInt = (val: string) => {
            let int = 0
            if (val === "") {
                return 0
            } else {
                qrymetrics.forEach((m: string, i: number) => {
                    if (String(m) === String(val)) {
                        int = i + 1
                    }
                })
                return int
            }
        }

        let colors = AssignColorScheme(forcegroupingname, query, qryvis, colormdl, simpledatafilt)
        let [colorScale, secColorScale] = CreateColorScale(colors, qryvis.ColorGradientType)

        if (show === "table") {
            //chart table headers, instead of value1 value2 etc
            let cells: any[] = []

            if (qryvis.ChartType === "TableHorizontal") {
                let showdefault_int = MatchMetricInt(qryvis.ShowDefault)
                let newfilt = FilterData(simpledatafilt, "MetricNo", String(+showdefault_int - 1))
                let data1 = PivotDataFrame(newfilt, ['Variable'], "Grouping", yfield, true, includebenchmarks)

                if (vis_state[stateIdx].selectedmetric !== "") {
                    let showdefault_metrkey = qryvis.ShowDefault
                    showdefault_int = MatchMetricInt(vis_state[stateIdx].selectedmetric)
                    showdefault_metrkey = qryvis.ShowDefault
                }
                if (data1.length > 0) {
                    let [headernames, metridxmap] = GetHeaderNames(Object.keys(data1[0]), qryvis.ChartType, query, metrickeys, metrics, fields, benchmodels, includebenchmarks)
                    cells = CreateTableD3(data1, svgid, headernames, metrickeys, benchmarkidxs, metridxmap, formats, size, "TableHorizontal", colorScale, secColorScale, qryvis.ChartTitle, qryvis.TablePaginate, showdefault_int - 1)
                }
            } else {
                let data1 = PivotDataFrame(simpledatafilt, chartpars, "Metric", yfield, true, includebenchmarks)

                if (data1.length > 0) {
                    let [headernames, metridxmap] = GetHeaderNames(Object.keys(data1[0]), qryvis.ChartType, query, metrickeys, metrics, fields, benchmodels, includebenchmarks)
                    cells = CreateTableD3(data1, svgid, headernames, metrickeys, benchmarkidxs, metridxmap, formats, size, "Table", colorScale, secColorScale, qryvis.ChartTitle, qryvis.TablePaginate, -1)
                }
            }
            if (cells) {
                SVGMouseFunctions(cells, vis_state, setVis_State, stateIdx, simpledata, detaildata, context)
            } else {
                console.log("error - no table cells")
            }
        } else if (show === "bubble") {
            ForceBubble(simpledatafilt, "Variable", yfield, [query.XFieldName, "Count"], "#bar1", [600, 500])
        } else if (show === "chart3D") {
            let svgidc = svgid.replace("#", "") //doesn't use #, unlike other bar chart
            ThreeDBarGraph(simpledatafilt, qryvis.colors, svgidc)
        } else if (show === "chart") {
            //---create bar/line chart---
            ChartPrimary(query, qryvis, simpledatafilt, detaildatafilt, svgid, metrickeys, formats, colors, colorScale, secColorScale,
                size, vis_state, setVis_State, stateIdx, context)
            //if (dateSpread === "spread" && query.GroupFieldName !== "none") { ChartLegend(chartkeys, "#legend1", [400, 300]) }
        }
        return (simpledatafilt)
    }
    return ([])

};

export default CreateChart;


const ChartFilterDefault = (simpledata: BasicQuery[], detaildata: BasicQuery[], filtervalues: string[]) => {
    let simpledatafilt: BasicQuery[] = simpledata
    let detaildatafilt: BasicQuery[] = detaildata

    if (filtervalues.length > 1) {
        simpledatafilt = FilterDataMultiple(simpledata, ['FilterValue1', 'FilterValue2'], filtervalues, "and", false, true)
        detaildatafilt = FilterDataMultiple(detaildata, ['FilterValue1', 'FilterValue2'], filtervalues, "and", false, true)
    } else {
        simpledatafilt = FilterData(simpledatafilt, "FilterLevel", "All")
        detaildatafilt = FilterData(detaildatafilt, "FilterLevel", "All")
    }
    return [simpledatafilt, detaildatafilt]
}

const ChartDataFilter = (simpledata: BasicQuery[], detaildata: BasicQuery[], vis_state: queryvisualstate[], fields: FieldMap[], stateIdx: number) => {

    let simpledatafilt: BasicQuery[] = simpledata
    let detaildatafilt: BasicQuery[] = detaildata

    let flds = vis_state[stateIdx].filterfields.map((f: string) => { return GetFieldAttrMCat(GetObjectfromArray(fields, f, "Key")) })
    let vals = vis_state[stateIdx].filtervals

    let count: number = 0;
    vals.forEach((v: string) => {
        if (v !== "") {
            count += 1
        }
    })

    if (count > 0) {
        simpledatafilt = FilterDatabyField(simpledatafilt, ["FilterFieldName1", "FilterFieldName2"], ["FilterValue1", "FilterValue2"], flds, vals)
        detaildatafilt = FilterDatabyField(detaildatafilt, ["FilterFieldName1", "FilterFieldName2"], ["FilterValue1", "FilterValue2"], flds, vals)
    }
    return ([simpledatafilt, detaildatafilt])
}

const ChartDataCalculate = (data: BasicQuery[], chartseries: ChartSeries[], query: QueryModel, isdetail: boolean, isgroupcol: boolean) => {
    let return_simpledata: BasicQuery[] = []
    let fld2 = ""
    if (isdetail) { fld2 = "DetailField" } //not tested for detailfield

    chartseries.forEach((cs: ChartSeries) => {
        let fltdata = FilterData(data, "MetricNo", cs.MetricOrder)
        if (cs.Calculation === "PctofTotal") {
            let groupvals: any = {}
            let piv_simple = fltdata;


            if (isgroupcol) {
                piv_simple = PivotArray(fltdata, "Variable", fld2, "Value")
                piv_simple.forEach((prow: any) => {
                    groupvals[prow["Value"]] = prow['Sum']
                })
                fltdata.forEach((brow: BasicQuery) => {
                    brow.ValueCalc = brow.Value / Math.max(brow.Value, groupvals[brow.Variable])
                })
            } else {
                let maxval: number = 0
                fltdata.forEach((br: BasicQuery) => { maxval += +br.Value })
                if (maxval > 0) {
                    fltdata.forEach((br: BasicQuery) => { br.ValueCalc = +br.Value / maxval })
                }
            }

            if (!isdetail) {
                // console.log(piv_simple)
                // console.log(fltdata)
            }
        } else if (cs.Calculation === "Default") {
            fltdata.forEach((brow: BasicQuery) => {
                brow.ValueCalc = brow.Value
            })
        } else if (cs.Calculation === "Annualized") {
            let pl: number = PeriodLength(query.Period, query.IPeriod)
            fltdata = fltdata.map((brow: BasicQuery) => {
                return { ...brow, Value: brow.Value, ValueCalc: brow.Value * pl }
            })
        }
        return_simpledata = return_simpledata.concat(fltdata)

    })

    return return_simpledata
}

// const ForceGroup = (simpledatafilt: BasicQuery[]) => {
//     let returndata: BasicQuery[] = []

//     simpledatafilt.forEach((rw: BasicQuery) => {
//         returndata.push({ ...rw, Grouping: rw.Variable })
//     })
//     return returndata
// }

const PeriodLength = (periodstr: string, iperiod: number): number => {
    if (iperiod > 0) {
        if (periodstr === "Yearly") {
            return 1 / iperiod
        } else if (periodstr === "Monthly") {
            return 12 / iperiod
        } else if (periodstr === "Weekly") {
            return 52 / iperiod
        } else if (periodstr === "Daily") {
            return 365.25 / iperiod
        }
    } else {
        return 1
    }
    return 0
}

const ApplyBenchmarksets = (data: BasicQuery[], qrychartseries: ChartSeries[], benchmarksets: Benchmarkset[]) => {

    let newdata: BasicQuery[] = []

    data.forEach((d: BasicQuery, i: number) => {
        if (qrychartseries[d.MetricNo].BenchmarkModelName !== "") {
            let applied = false

            benchmarksets.forEach((bms: Benchmarkset) => {
                if (bms.BenchModelName === qrychartseries[d.MetricNo].BenchmarkModelName) {
                    let value = 0
                    bms.benchmark.forEach((bval: Benchmark, n: number) => {
                        let check1 = bval.XFieldValue === d.Variable
                        let check2 = bval.GroupFieldValue === d.Grouping
                        let check3 = bval.FilterValue1 === d.FilterValue1
                        let check4 = bval.FilterValue2 === d.FilterValue2
                        if (bval.XFieldValue === "") { check1 = true }
                        if (bval.GroupFieldValue === "") { check2 = true }
                        if (bval.FilterValue1 === "") { check3 = true }
                        if (bval.FilterValue2 === "") { check4 = true }

                        if (check1 && check2 && check3 && check4 && bval.Value) {
                            value = +bval.Value
                        }
                    })
                    applied = true
                    newdata.push({ ...d, Benchmark: value })
                }
            })
            if (!applied) { //failsafe for no loaded benchmarkset
                newdata.push(d)
            }
        } else {
            newdata.push(d)
        }
    })
    return newdata
}