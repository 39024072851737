import { logoutActionCreator } from "../../../../reducers/user_actions";
import { schemadeleteActionCreator } from "../../../../reducers/schema_actions";
import GenericBackendCall from "../../../../functions/Import/GenericBackendCall";
import { User } from "../../../../type";


const LogoutAuth = (dispatcher: any, schema: string, user: User, config: any, msgstring: string, setMsg: any) => {
    //ref: app, logout
    //authentication - move to separate file

    GenericBackendCall("", schema, "api/accounts/revoketokens", user, config, {}).then(_ => {
        dispatcher(logoutActionCreator({}));
        dispatcher(schemadeleteActionCreator([]))
        setMsg(msgstring);
    })

};
export default LogoutAuth;