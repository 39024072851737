interface valformat {
    format: string; //blank or pct
    rounding: number;
}

const FormatLabel = (value: number, format: valformat) => {
    //ref: createlabels, chartsample
    if (value && !isNaN(value)) {
        let dec = format.rounding
        if (format.format === "pct") {
            value = value * 100
        }

        let val: string = (Math.round(value * Math.pow(10, dec)) / Math.pow(10, dec)).toFixed(dec)

        if (value !== 0) {
            if (format.format === "pct") {
                return val + "%"
            } else {
                return String(val).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
            }
        } else {
            return ''
        }
    } else {
        return ''
    }
}
export default FormatLabel;
