

const CheckObjectKey = (object, key) =>{
    let boolval = false
    try {
        let y = object[key]
        if (y){
            boolval = true
        } else {
            boolval = false
        }
    }
    catch(e){
        let y = 1
    }

    return(boolval)
};
export default CheckObjectKey