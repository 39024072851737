import "./css/HelpPage.css"

const HelpPage = () => {



    return (<div className="ndt_canvas">

        <div className="">
            <div className="ndt_header ndt_gridbox">
                <div className="ndt_title2">Learn about the Nodiant App</div>
            </div>
            <div className="ndt_gridbox">
                <div style={{ fontSize: "22px" }}>Learn the Navigation Ribbon</div>
                <div className="ndt_innerbox" style={{ height: '700px', overflowY: "scroll" }}>

                    <div className="help_tabmain">
                        <div className="help_tabheader">Employee:</div>
                        <li>Uploaded Datasets become Models. These supply the "Employee State" table</li>
                        <li>View previously uploaded datasets through the Empl Model Viewer</li>
                        <li>View basic Employee table data via Empl Table</li>
                    </div>
                    <div className="help_tabmain">
                        <div className="help_tabheader">Sets:</div>
                        <li>Sets are companion data rules for filtering, excluding or creating grouped data</li>
                        <li>Rule Models (made up of sets) are for specific data cleansing, such as renaming specific values or removing values from a desired dataset</li>
                        <li>Filter models are for filtering data based on multiple criteria. Saved Filter Models prevent redundant filter criteria</li>
                        <li>Group sets are for binning columns of data into more manageable groups</li>
                        <li>Color sets are for labeling chart elements with a predetermined set of colors, for consistency between graphs</li>
                    </div>
                    <div className="help_tabmain">
                        <div className="help_tabheader">Tables:</div>
                        <li>Supporting data is housed in Tables. Department, Job Title and Positional data are necessary for the Employee Model</li>
                        <li>For table data to be read correctly, its Field metadata must be updated in the Fields manager</li>
                        <li>Upload and manage supplemental data in the Table Manager</li>
                    </div>
                    <div className="help_tabmain">
                        <div className="help_tabheader">Queries:</div>
                        <li>Create Queries and Metrics for data pivots and aggregation</li>
                        <li>Query Manager allows for queries to be assigned to Dashboards, inspected, edited visually or re-run</li>
                        <li>KPIs are created from queries by selecting an element</li>

                        <li>Schedules allow queries to be re-run periodically</li>
                        <li>Benchmarks are parallel datasets to compare against query values to determine company performance</li>
                    </div>
                    <div className="help_tabmain">
                        <div className="help_tabheader">Dashboards:</div>
                        <li>Dashboards are pages created to house multiple created graphs, tables, or KPI strings</li>
                        <li>KPI Strings are custom notes for a dashboard to display KPIs or other notes in text instead of visually</li>
                        <li>Leader pages are a simple dashboard for a Manager or Leadeer to track updated KPIs</li>
                    </div>
                    <div className="help_tabmain">
                        <div className="help_tabheader">Job Skills:</div>
                        <li>The primary unit of Workforce data is the job skill. This data drives certain higher-level analytics</li>
                        <li>Job Descriptions must be supplied for each Job Title, which can then be converted into a Category Model (ML)</li>
                    </div>
                    <div className="help_tabmain">
                        <div className="help_tabheader">Analytics:</div>
                        <li>The higher-level models driven by Network Graphs, Machine Learning or AI</li>
                        <li>Job Applications houses Requisition and Application information, uploaded in pdf/text and parsed by AI</li>
                        <li>SNA Graph is the visual representation of a workforce, which can be specified in the Manager</li>
                        <li>AI Model Manager allows for certain saved datasets to be analyzed, such as queries and KPIs</li>
                        <li>Scenario Builder is the primary function for Workforce Planning and Strategization</li>
                    </div>

                    <div className="help_tabmain">
                        <div className="help_tabheader">Info:</div>
                        <li>Schemas represent the data environment. Selecting a schema means selecting the universe that the data, sets and charts will live in.</li>
                        <li>Use the Status Checker to find the progress of the data in the selected Schema</li>
                        <li>Use the Example Data Walkthrough for a reference to uploading and managing data intake</li>
                    </div>
                </div>


            </div>
        </div>
    </div>)
}
export default HelpPage;