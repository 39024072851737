import * as d3 from 'd3';
import IsInVector from '../../../../functions/IsInVector';


const GraphNeighborhood = (graph, selectedNode, radius, isSphere) => {	

    
    console.log(graph)
    
    if (!isSphere){
        let sphereRadius = 10 * radius;
        let sphereNeighborNodes = [];
        let sphereNeighborIDs = [];
        let sphereNeighborLinks = [];
        let sphereNeighborNames= [];

        let sphere = graph
                .append("circle")
                .attr("class", "sphere1")
                .attr("r", sphereRadius)
                .attr("id","influenceSphere")
                .attr("fill", "black")
                .attr("fill-opacity",.2)
                .attr("cx",selectedNode.attributes.cx.value)
                .attr("cy",selectedNode.attributes.cy.value)
        sphere.lower() //drop behind link/node elements
    
    
    d3.selectAll(".circle1")._groups[0].forEach((nd)=> {
        let nodedist = Math.pow(Math.pow(nd.attributes.cx.value - selectedNode.attributes.cx.value,2)
                        +Math.pow(nd.attributes.cy.value - selectedNode.attributes.cy.value,2),.5)
        if (nodedist < sphereRadius){
            sphereNeighborNodes.push(nd);
            sphereNeighborIDs.push(nd.attributes.id.value);
            sphereNeighborNames.push(nd.attributes.name.value);
        }
    })

    d3.selectAll("line")._groups[0].forEach(lk => {
        if (IsInVector(sphereNeighborIDs,lk.attributes.source.value)===true &
            IsInVector(sphereNeighborIDs,lk.attributes.target.value)===true){
            sphereNeighborLinks.push(lk)
        }					
    })

    } else {
        graph.selectAll(".sphere1").remove()
    }
};
export default GraphNeighborhood