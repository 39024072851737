import { StartupContext } from "../../../App";
import { useContext, useMemo } from "react";
import FilterModelImport from "../../../data/Filtersets/FilterModel_Import";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import GenericsUniqueImport from "../../../data/Generics/GenericsUnique_Import";
import { FilterFieldInit, FilterModelInit, FilterSetInit } from "../../../InitTypes";
import FieldDropdown from "../fields/subfunctions/FieldDropdown";
import { FieldMap, FilterSet, FilterModel, FilterValue, TableModel } from "../../../type";
import { Modal } from "react-bootstrap";
import ImportAndSetState from "../../../functions/Import/ImportAndSetState";
import FieldsImport from "../../../data/Fields/Fields_Import";
import IsInObject from "../../../functions/Object Functions/IsInObject";
import GenericDropdown from "../../../functions/Dropdown_Generic";
//import ChangeElementCollectionState from "../../../functions/ElementSelect/ChangeElementCollectionState";
import DateString from "../../../functions/Date Functions/DateString";
import { useParams } from "react-router-dom";
import SerializeDataFrame from "../../../functions/Import/SerializeDataFrame";
import GenericBackendCall from "../../../functions/Import/GenericBackendCall";
import StringReformatforBackend from "../../../functions/String Functions/StringReformatforBackend";
import GetFieldName from "../../../functions/GetFieldName";
import GetObjectfromArray from "../../../functions/Array Functions/GetObjectfromArray";
import ModelCategoryDropdown from "../../../functions/Dropdowns/ModelCategoryDropdown";
import ImportAndSetStateGeneric from "../../../functions/Import/ImportAndSetStateGeneric";
import "./css/FiltersetsManage.css"


const FilterSetsManage = () => {


    let { id } = useParams()

    let { config, dispatcher, schema, paths, user, clickstyle } = useContext(StartupContext)
    //interface fielditmstype { field: string, values: string[] }
    interface params_type {
        currentfld: string; statechg: number; isdelvisible: boolean; issavevisible: boolean; refresh: number;
        grouptbls: string; modelcat: string;
    }
    const [keyparams, setKeyParams] = useState<params_type>({
        currentfld: '', statechg: 0, isdelvisible: false, issavevisible: false, refresh: 0, grouptbls: '', modelcat: ''
    })
    const [fldUniqueData, setFldUniqueData] = useState<any[]>([]);
    //const [fldUniqueStyle, setFldUniqueStyle] = useState<any[]>([]);
    const [fields, setFields] = useState<FieldMap[]>([])
    const [filtModels, setFiltModels] = useState<FilterModel[]>([]);
    const [selModel, setSelModel] = useState<FilterModel>(FilterModelInit[0]);
    const [usenewmodel, setUsenewmodel] = useState<boolean>(false)
    const [tables, setTables] = useState<TableModel[]>([])

    const newbtnOff = { boxShadow: "" };
    const newbtnOn = { backgroundColor: "rgb(50,50,200)", boxShadow: "0px 0px 8px yellow" };

    const [newbtnstyle, setNewbtnstyle] = useState<any>(newbtnOff);

    useEffect(() => {
        if (keyparams.refresh >= 100) { window.location.reload() }
    }, [keyparams])


    useEffect(() => {
        console.log('useeffect')
        ImportAndSetState(FieldsImport, setFields, "*", schema, config, dispatcher, false);
        ImportAndSetStateGeneric(setTables, "*", schema, paths.tables.tablemodelview, user, config, {})
        ReImport()

    }, []);

    useEffect(() => {
        if (filtModels.length > 0) {
            filtModels.map((fm: FilterModel) => {
                fm.fields.map((fs: FilterSet) => {
                    if (fs.Key === id) {
                        setSelModel(fm)
                    }
                })
            })
        }
    }, [filtModels])

    useEffect(() => {
        if (usenewmodel) {
            setNewbtnstyle(newbtnOn)
            let newinit = FilterModelInit[0]
            console.log(newinit)
            //newinit.fields = FilterSetInit
            setSelModel(newinit)
        } else {
            setNewbtnstyle(newbtnOff)
        }
    }, [usenewmodel]);

    useEffect(() => {
        if (keyparams.currentfld !== "") {
            let fldunique = GenericsUniqueImport([keyparams.currentfld], schema, fields, config, dispatcher);
            fldunique.then(d => {
                setFldUniqueData(SerializeDataFrame(d, ["X"]));
                //setFldUniqueStyle(Array.apply(null, Array(d.length)).map(_ => { return defaultstyle }))
                //HighlightExisting(keyparams.currentfld);
            })
        }
    }, [keyparams.currentfld])

    const ReImport = () => {
        console.log('reimport')
        ImportAndSetState(FilterModelImport, setFiltModels, "*", schema, config, dispatcher, false);
    };


    //after selecting itm to filter by
    const HighlightFldItm = (e: MouseEvent<HTMLDivElement>) => {

        let filtval = e.currentTarget.attributes[1].value;
        //let i = e.currentTarget.attributes[2].value;


        setKeyParams({ ...keyparams, statechg: keyparams.statechg + 1 })

        //has this field already been addressed in selmodel filteritems?
        let foundfld: boolean = false;
        let foundint: number = 0;
        selModel.fields.map((fltitm: FilterSet, i: number) => {
            if (fltitm.Field === keyparams.currentfld) {
                foundfld = true
                foundint = i
            }
        });

        let newfltval = { Key: '', FieldKey: keyparams.currentfld, Value: filtval }
        let fldfltcopy = Object.assign(selModel.fields); //copy - don't need to setstate when changing

        if (foundfld) {

            //already existing field
            if (!IsInObject(selModel.fields[foundint].values, filtval, "Value")) {
                fldfltcopy[foundint].values.push(newfltval)
                //setSelModel({ ...selModel, fields: fldfltcopy })
            } else {
                let found_idx: any = IsInObject(fldfltcopy[foundint].values, filtval, "Value", true)
                fldfltcopy[foundint].values.splice(found_idx, 1)
            }
        } else {

            if (fldfltcopy.length > 0 && fldfltcopy[0].Field === "") { //is this the first field?
                fldfltcopy = [{ Key: "", ModelKey: selModel.ModelKey, Field: keyparams.currentfld, values: [newfltval] }]
            } else {
                fldfltcopy.push({ Key: "", ModelKey: selModel.ModelKey, Field: keyparams.currentfld, values: [newfltval] })
            }

            setSelModel({ ...selModel, fields: fldfltcopy })
        }
    }

    //--Filtering--
    const FieldFilterSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        let fldkey = e.target.selectedOptions[0].attributes[0].value;
        setKeyParams({ ...keyparams, currentfld: fldkey })
    };

    //field filter functions
    const FieldUniqueValues = () => {
        //imported field data
        if (fldUniqueData.length > 0) {
            let usekey = Object.keys(fldUniqueData[0])[0] //x
            let fld = GetObjectfromArray(selModel.fields, keyparams.currentfld, "Field")
            let vals: any = []
            if (fld.length !== 0) {
                vals = fld.values
            }

            return (
                <div id="flduniquelist">
                    {fldUniqueData?.map((urow: any, i: number) => {
                        let itmstyle = {}
                        if (IsInObject(vals, urow[usekey], "Value")) {
                            itmstyle = { backgroundColor: "red" }
                        }

                        return (<div
                            id={"fltitm_" + String(urow[usekey])}
                            data-value={String(urow[usekey])}
                            data-int={String(i)}
                            className="fm_fldunique_itm"
                            key={i}
                            style={itmstyle}
                            onClick={(e: MouseEvent<HTMLDivElement>) => HighlightFldItm(e)}>
                            {urow[usekey]}
                        </div>)
                    })}
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
    let fldmemo = useMemo(() => { return FieldUniqueValues() }, [fldUniqueData, selModel, keyparams])

    const FilterModelSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        let val = e.target.value;
        filtModels.forEach((mdl: FilterModel) => {
            if (mdl.ModelKey === val) {
                setSelModel(mdl)
                setUsenewmodel(false); //use existing model
            }
        })
    }

    const DeleteModel = () => {
        let filtdelete = GenericBackendCall(selModel.ModelKey, schema, paths.generics.genericdelete,
            user, config, { model: "FilterModel", field: "ModelKey" }, "fm_divdel");
        setSelModel(FilterModelInit[0])
        filtdelete.then(_ => setKeyParams({ ...keyparams, refresh: 99 }))

    };

    const FltModelUpload = () => {
        let finalfldarry: FilterSet[] = []
        let uniqueFilterFlds: string[] = []
        selModel.fields.reverse().forEach(fld => {
            if (uniqueFilterFlds.indexOf(fld.Field) === -1) {
                uniqueFilterFlds.push(fld.Field);
                finalfldarry.push(fld)
            }
        });
        const fltfldsencoded: string[] = []
        selModel.fields.forEach((fld: FilterSet) => {
            fld.values.forEach((val: FilterValue) => {
                fltfldsencoded.push(fld.Field)
                fltfldsencoded.push(StringReformatforBackend(val.Value))
            })
        });

        let filtupload = GenericBackendCall(selModel.ModelKey, schema, paths.filtersets.filtermodelupload, user, config,
            { filterdata: fltfldsencoded, modelname: selModel.ModelName }, "fm_divupl")

        filtupload.then(_ => setKeyParams({ ...keyparams, refresh: 99 }))
    };

    const DeleteField = () => {
        let foundfld: number = -1
        selModel.fields.forEach((flt: FilterSet, i: number) => {
            if (flt.Field === keyparams.currentfld && keyparams.currentfld !== "") {
                foundfld = i
            }
        })
        if (foundfld >= 0) {
            let fldfltcopy = Object.assign(selModel.fields);
            fldfltcopy.splice(foundfld, 1)
            setSelModel({ ...selModel, fields: fldfltcopy })
        }
    }

    const ClickFilterSet = (flt: FilterSet) => {
        if (flt.Field !== "") {
            setKeyParams({ ...keyparams, currentfld: flt.Field })
        }
    }
    const ParseModel = () => {
        return (
            <div style={{ height: "600px", overflowY: "scroll" }}>
                <div id="filtmoddel_div"></div>
                <div id="fm_selmdl" className="ndt_innerbox">
                    <div id="fm_selmdlheader">
                        <div style={{ display: "flex", fontSize: "19px" }}>
                            <div style={{ width: "400px" }}>Filter Model Name: {selModel.ModelName}</div>
                            <div>Date Created: {DateString(selModel.DateCreated)}</div>
                        </div>

                    </div>

                    {selModel.fields.map((flt: FilterSet, i: number) => {
                        let usestyle = clickstyle.itmUnselected
                        if (flt.Field === keyparams.currentfld && flt.Field !== "") { usestyle = clickstyle.itmSelected }
                        return (
                            <div className="ndt_item fltmgitm" key={i} style={usestyle} onClick={_ => ClickFilterSet(flt)}>
                                {flt.Field === "" ? <div>Select a Field</div> :
                                    <div>{GetFieldName(fields, flt.Field, "Key", "FieldName")}</div>
                                }
                                <div>{ValuesString(flt.values)}</div>
                            </div>
                        )
                    })}
                    <div style={{ marginTop: "10px", height: "35px" }}>
                        {keyparams.currentfld !== "" ?
                            < button className="ndt_btn2" style={{ fontSize: "16px" }} onClick={_ => { DeleteField() }}>Delete Selected Field</button>
                            : <div></div>}
                    </div>
                </div>

            </div >
        )
    };

    const ValuesString = (valuesdf: FilterValue[]) => {
        let returnstr: string = ""
        valuesdf.forEach((val: FilterValue, i: number) => {
            if (i > 0) {
                returnstr = returnstr + ", " + val.Value
            } else { returnstr = val.Value }
        })
        return (returnstr)
    };

    return (
        <div className="ndt_canvas" id="filtercanvas">
            <div className="ndt_title2" id="fm_header">Manage Filtersets</div>

            <div id="fm_body" style={{ fontSize: "16px" }}>
                <div className="ndt_gridbox" id="filtexisting_canvas">
                    <div style={{ fontSize: "20px" }}> - Existing Models - </div>
                    <div style={{ display: "flex" }}>
                        <button style={newbtnstyle} className="ndt_btn1" onClick={_ => setUsenewmodel(true)}> Create New Model?</button>
                        <div style={{ marginLeft: "10px", marginRight: "10px", marginTop: '5px' }}> Or </div>
                        <GenericDropdown
                            data={filtModels}
                            change={(e: ChangeEvent<HTMLSelectElement>) => FilterModelSelect(e)}
                            keycol="ModelKey"
                            namecol="ModelName"
                            divID="fm_filtddown1"
                            className="ndt_dropdown"
                            promptstring="Select Existing Filter Model"
                        />
                        {selModel.ModelKey !== "" ? <button className="ndt_btn2" onClick={_ => setKeyParams({ ...keyparams, isdelvisible: true })}>Delete Filter Model</button> : <div></div>}

                    </div>

                    <ParseModel />
                    <button id="filt_finalizebtn" className="ndt_btn1" onClick={_ => setKeyParams({ ...keyparams, issavevisible: true })} > Finalize Filterset</button>
                </div>

                <div className="ndt_gridbox" id="filtcreate_canvas">
                    <div style={{ fontSize: "16px" }}>Select field to filter on: {GetFieldName(fields, keyparams.currentfld, "Key", "FieldName")}</div>
                    <ModelCategoryDropdown tables={tables} usetables={keyparams.grouptbls}
                        className="ndt_dropdown"
                        change={(e: ChangeEvent<HTMLSelectElement>) => {
                            setKeyParams({ ...keyparams, modelcat: e.target.selectedOptions[0].attributes[0].value })
                        }} />
                    <FieldDropdown divname="graphmodalfld_ddown"
                        change={(e: ChangeEvent<HTMLSelectElement>) => FieldFilterSelect(e)}
                        includecategory={false}
                        filterfield={["ModelCategory"]}
                        filtervalue={[keyparams.modelcat]}
                        className="ndt_dropdown"
                    />
                    <div style={{ fontSize: "19px" }}> Select Values</div>
                    {fldUniqueData.length > 0 ? <div>{fldmemo}</div> : <div></div>}
                </div>


                {/* Finalization modal*/}
                <Modal show={keyparams.issavevisible}>
                    <div className="ndt_modal">
                        {selModel.ModelKey === "" ? <div>
                            <div className="ndt_modaltitle">Add a model name</div>
                            <input type="text" name="modelname" onChange={e => setSelModel({ ...selModel, ModelName: e.target.value })} />
                        </div> :
                            <div> Resave model {selModel.ModelName}</div>}
                        <button className="ndt_btn1" style={{ width: "200px", marginTop: '5px' }} onClick={_ => FltModelUpload()} > Submit</button>
                        <div id="fm_divupl" style={{ height: '30px' }}></div>
                        <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, issavevisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                    </div>
                </Modal>

                <Modal show={keyparams.isdelvisible}>
                    <div className="ndt_modal">
                        <div style={{ fontSize: "18px" }}>Delete model {selModel.ModelName}?</div>
                        <button className="ndt_btn2" style={{ width: "200px" }} onClick={_ => DeleteModel()} > Delete </button>
                        <div id="fm_divdel"></div>
                        <button className="closemodalbtn" onClick={_ => setKeyParams({ ...keyparams, isdelvisible: false, refresh: keyparams.refresh + 1 })}>Close</button>
                    </div>
                </Modal>
            </div>
        </div>
    )
};
export default FilterSetsManage;