

const PeriodDropdown = (props: any) => {

    let change = props.change
    let className = props.className

    return (
        <select className={className} onChange={change}>
            <option data-value="" data-idx=''>Select Period</option>
            <option data-value="None" data-idx=''>None - Point in Time</option>
            <option data-value="Daily" data-idx='Days'>Days</option>
            <option data-value="Weekly" data-idx='Weeks'>Weeks</option>
            <option data-value="Monthly" data-idx='Months'>Months</option>
            <option data-value="Yearly" data-idx='Years'>Years</option>
        </select>
    )
};
export default PeriodDropdown;